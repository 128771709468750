import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { DataGridVirtualizedContentMode } from '@app/v2/shared/enums';
import { usePrevious } from '@app/v2/shared/hooks';
import { strictlyEqual } from '@app/v2/shared/helpers';
import { stringify } from '@app/v2/shared/utils';
import VirtualizedCache from '../virtualizedCache/virtualizedCache';
import type { DataGridVirtualizedProps, TableData, Header } from '../types';

export default function withVirtualizeCache(WrappedComponent) {
  return <Data extends TableData>(props: DataGridVirtualizedProps<Data>) => {
    const { contentMode = DataGridVirtualizedContentMode.Cache, columns, getRowCells } = props;

    const virtualizedCache = useRef<VirtualizedCache | null>(null);
    const prevColumns = usePrevious<Header<Data>[]>(columns);

    useEffect(() => {
      if (!strictlyEqual<DataGridVirtualizedContentMode>(contentMode, DataGridVirtualizedContentMode.Cache)) return null;
      if (virtualizedCache.current) return null;

      virtualizedCache.current = new VirtualizedCache();

      return () => {
        virtualizedCache.current = null;
      };
    }, [contentMode]);

    useEffect(() => {
      if (isEqual(prevColumns, columns) || !virtualizedCache.current) return;

      virtualizedCache.current.clenCache();
    }, [prevColumns, columns]);

    const itemContentMapper = useMemo<Record<DataGridVirtualizedContentMode, (index: number, rowData) => ReactNode>>(
      () => ({
        [DataGridVirtualizedContentMode.Simple]: (index, rowData) => getRowCells(index, rowData as Data),
        [DataGridVirtualizedContentMode.Cache]: (index, rowData) => {
          const currentId = stringify({ ...rowData, id: index });
          const dataFromCache = virtualizedCache.current.getFromCache(currentId);

          if (!dataFromCache) {
            const result = getRowCells(index, rowData as Data);
            virtualizedCache.current.setToCache({ id: currentId, item: result });

            return result;
          }

          return dataFromCache;
        },
      }),
      [getRowCells],
    );

    return <WrappedComponent itemContent={itemContentMapper[contentMode]} {...props} />;
  };
}
