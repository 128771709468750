import React from 'react';
import { Typography, Box } from '@mui/material';
import { PlayerStatus } from '@app/v2/shared/enums';
import LocatorControlService from '@app/modules/map-module/services/LocatorControlService';
import PlayerActions from './PlayerActions';

type Props = {
  locatorControlService: LocatorControlService;
  control: WeatherLocators.LocatorControl;
  playerState: PlayerStatus;
  currentTime: string;
};

const LocatorPlayer = ({ locatorControlService, control, currentTime, playerState }: Props) => {
  return (
    <Box sx={{ display: 'flex', maxWidth: '15.188rem', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
      <PlayerActions locatorControlService={locatorControlService} disabled={!control?.times?.length} playerState={playerState} />
      <Typography sx={{ fontSize: '1.5rem', marginLeft: '1rem' }}>{currentTime}</Typography>
    </Box>
  );
};

export default LocatorPlayer;
