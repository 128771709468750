const dialogExample = `
const handleOpenDialogExample = useDialog(CSDDialogExample);

<Button onClick={handleOpenDialogExample}>Open</Button>

// CSDDialogExample component //
<Dialog open>
  <DialogTitle>
      <Typography variant="h2">Title</Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        <IconButton>
          <Icon sx={{ color: ({ palette }) => palette.success.main }}>done</Icon>
        </IconButton>
        <IconButton onClick={() => close()}>
          <Icon>close</Icon>
        </IconButton>
      </Stack>
    </DialogTitle>
  <DialogContent sx={{ minHeight: '400px' }}>Some content</DialogContent>
  <DialogActions>
    <Stack direction="row" spacing={1}>
      <Button variant="outlined">
        <Icon>done</Icon>
      </Button>
      <Button variant="outlined" onClick={() => close()}>
        <Icon>close</Icon>
      </Button>
    </Stack>
  </DialogActions>
</Dialog>`;

export default dialogExample;
