type Coords = { x: number; y: number };

class DrawScoreboard {
  startX: number = 0;

  startY: number = 0;

  constructor(private readonly coords: Coords) {
    this.coords = { x: coords.x, y: coords.y };
    this.startX = coords.x;
    this.startY = coords.y;
  }

  // eslint-disable-next-line class-methods-use-this
  reset(context: CanvasRenderingContext2D, { background }: { background: string }): void {
    context.fillStyle = background;
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);
  }

  // eslint-disable-next-line class-methods-use-this
  drawCells(context: CanvasRenderingContext2D, { size, color }: { size: number; color: string }): void {
    for (let x = 0; x <= context.canvas.width; x += size) {
      context.moveTo(x, 0);
      context.lineTo(x, context.canvas.height);
    }

    for (let y = 0; y <= context.canvas.height; y += size) {
      context.moveTo(0, y);
      context.lineTo(context.canvas.width, y);
    }

    context.strokeStyle = color;
    context.stroke();
  }

  // eslint-disable-next-line class-methods-use-this
  drawSymbol(context: CanvasRenderingContext2D, symbol, { position, size, font, background, width, height }): void {
    const symbolCoords = { x: position.x, y: position.y };

    symbol &&
      symbol.forEach(pixelLine => {
        const startX = symbolCoords.x;

        pixelLine.forEach(pixel => {
          if (pixel && symbolCoords.x < this.startX + width * size && symbolCoords.y < this.startY + height * size) {
            context.fillStyle = pixel ? font.color : background;
            context.fillRect(symbolCoords.x, symbolCoords.y, (font.w / 5) * size, (font.h / 8) * size);
          }

          symbolCoords.x += (font.w / 5) * size;
        });

        symbolCoords.y += (font.h / 8) * size;
        symbolCoords.x = startX;
      });
  }

  // eslint-disable-next-line class-methods-use-this
  drawSign(context, { signFile, position, size }, callback): void {
    const image = new Image();

    image.onload = () => {
      context.drawImage(image, position.x, position.y, size.width, size.height);

      callback();
    };

    image.src = signFile;
  }

  drawBoard(context: CanvasRenderingContext2D, data, { size, intervals, font, background, margins, width, height, additionalMarginTop }): void {
    data.forEach((line, lineNumber) => {
      line &&
        line.forEach((symbol, symbolNumber) => {
          if (symbol) {
            this.drawSymbol(context, symbol, { position: this.coords, size, background, width, height, font });
          }
          this.coords.x = (symbolNumber + 1) * size * font.w + (symbolNumber + 1) * size * intervals.char + this.startX;
        });
      this.coords.x = this.startX;
      this.coords.y = (lineNumber + 1) * size * font.h + (lineNumber + 1) * size * intervals.line + (margins.top + additionalMarginTop) * size;
    });
  }
}

export default DrawScoreboard;
