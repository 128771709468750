import sha256 from 'crypto-js/sha256';
import { AccountSettings } from '@store/slices/account/types';
import { NO_DATA } from '@app/core/constants';
import { USER_LOGIN } from '@app/core/constants/auth';

export default function createChartAttributes(userAccountSettings: AccountSettings) {
  const { userId, userName, userEmail } = userAccountSettings;

  return {
    id: userId || NO_DATA,
    name: userName || JSON.parse(localStorage.getItem(USER_LOGIN)) || NO_DATA,
    email: userEmail || undefined,
    hash: sha256(userEmail, process.env.REACT_APP_TAWK_API_KEY).toString(),
  };
}
