/* eslint-disable no-console */
import React, { memo, MouseEvent, ReactNode, useState } from 'react';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import { Box, Icon, SxProps, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IncidentStatuses } from '@app/v2/shared/enums';
import { IncidentsTypeSettings } from '@app/v2/shared/configs';

type Props = {
  id: number | string;
  incidents: Common.Incidents;
  height?: string;
  bottom?: number;
  borderRadius?: string;
  withAnimation?: boolean;
  zIndex?: number;
  zIndexIcon?: number;
  scale?: number;
  isShowTooltip?: boolean;
  onClickWithoutAnimation?: Common.AnyFunction;
} & I18N.TranslationFunction<'incidents'>;

const IncidentReport = ({
  id,
  incidents,
  height,
  bottom,
  zIndex,
  zIndexIcon,
  borderRadius,
  withAnimation = true,
  isShowTooltip = true,
  scale = 1,
  onClickWithoutAnimation = () => {},
  t,
}: Props) => {
  const defaultAnimationState = {
    isActive: false,
    css: null,
  };

  const [animationStyles, setAnimationStyles] = useState<{ isActive: boolean; css: SxProps<Theme> }>(defaultAnimationState);

  const classes = useStyles({ height, bottom, borderRadius, zIndex, zIndexIcon, scale, withAnimation });

  if (typeof incidents !== 'object' || incidents === null) {
    console.error(`Invalid value of incidents for station with id - ${id}`);

    return null;
  }

  const getRenderData = (): ReactNode => {
    const { object } = incidents;

    if (!object) return null;

    if (isShowTooltip) {
      return (
        <Tooltip title={t(`status.${incidents?.type}`, { object: t(IncidentsTypeSettings[object]?.translation) })}>
          <Box className={classes.alertIconContainer}>
            <Icon>{IncidentsTypeSettings[object]?.icon}</Icon>
          </Box>
        </Tooltip>
      );
    }

    return (
      <Box className={classes.alertIconContainer}>
        <Icon>{IncidentsTypeSettings[object]?.icon}</Icon>
      </Box>
    );
  };

  const renderData = getRenderData();

  const onClick = (event: MouseEvent<HTMLDivElement>) => {
    if (!withAnimation) {
      onClickWithoutAnimation();
      return;
    }

    event.stopPropagation();

    setAnimationStyles(prev => {
      if (prev.isActive) return defaultAnimationState;

      return {
        isActive: true,
        css: {
          borderRadius: '2rem',
          height: `${3.75 * scale}rem`,
          width: '3.75rem',
          backgroundColor: 'transparent',
          right: `calc(50% - ${3.75 / 2}rem)`,
        },
      };
    });
  };

  return (
    <>
      {!!renderData && (
        <Box
          sx={{ ...animationStyles.css }}
          className={clsx(classes.alertMessage, incidents?.type === IncidentStatuses.Alert ? classes.bgAlert : classes.bgWarning)}
          onClick={onClick}
        >
          {renderData}
        </Box>
      )}
    </>
  );
};

const useStyles = makeStyles<Theme, Pick<Props, 'height' | 'bottom' | 'borderRadius' | 'zIndex' | 'zIndexIcon' | 'scale' | 'withAnimation'>>(
  theme => ({
    alertMessage: {
      position: 'absolute',
      right: 0,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
      backgroundColor: '#C17A0F66',
      borderRadius: ({ borderRadius }) => borderRadius || 'inherit',
      height: ({ height }) => height || '100%',
      bottom: ({ bottom }) => bottom || 0,
      transition: 'all 0.7s',
      cursor: ({ withAnimation }) => (withAnimation ? 'pointer' : 'default'),
      zIndex: ({ zIndex }) => zIndex || 'auto',
    },
    bgWarning: {
      background: '#C17A0F66',
    },
    bgAlert: {
      background: '#C10F0F66',
    },
    alertIconContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      transform: ({ scale }) => `scale(${scale})`,
      width: '3.75rem',
      height: '3.75rem',
      borderRadius: '50%',
      border: `0.25rem solid ${theme.palette.background.default}`,
      '& .MuiIcon-root': {
        fontSize: '2.25rem',
        color: theme.palette.background.default,
      },
      zIndex: ({ zIndexIcon }) => zIndexIcon || 'auto',
    },
  }),
);

export default withTranslation('incidents')(memo(IncidentReport));
