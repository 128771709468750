/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Profile, Notification } from '@app/v2/features/notifications/types';
import { ReducersNames } from '@store/constants';
import { Bulletin, MeteoBulletinGroup } from '@store/slices/subscriptions/types';

type State = {
  globalSubscriptions: Notification[];
  localSubscriptions: Profile[];
  meteoBulletinGroups: MeteoBulletinGroup[];
  places: Common.SelectorValues;
  roadParts: Common.SelectorValues;
  isListReload: boolean;
};

const initialState: State = {
  globalSubscriptions: [],
  localSubscriptions: [],
  meteoBulletinGroups: [],
  places: [],
  roadParts: [],
  isListReload: false,
};

const subscriptionsSlice = createSlice({
  name: ReducersNames.subscriptions,
  initialState,
  reducers: {
    fetchSubscriptions(state: State, { payload }: PayloadAction<any>) {
      return { ...state, ...payload };
    },
    mutateSubscriptions(state: State) {
      state.isListReload = true;
    },
    clearSubscriptions() {
      return initialState;
    },
    changeGlobalSubscriptionCheckbox(
      state: State,
      { payload }: PayloadAction<{ key: keyof Notification; keyValue: Notification[keyof Notification]; checked: boolean }>,
    ) {
      state.globalSubscriptions = [...state.globalSubscriptions].map((item: Notification) =>
        item[payload.key] === payload.keyValue ? { ...item, checked: payload.checked } : item,
      );
    },
    changeBulletinCheckbox(state: State, { payload }: PayloadAction<{ checked: boolean; groupId: number; elementId: number }>) {
      state.meteoBulletinGroups = [...state.meteoBulletinGroups].map((item: MeteoBulletinGroup) =>
        item.id === payload.groupId
          ? {
              ...item,
              bulletinElements: item.bulletinElements.map((el: Bulletin) => {
                if (el.id === payload.elementId) {
                  return { ...el, isChecked: payload.checked };
                }

                return el;
              }),
            }
          : item,
      );
    },
    changeBulletinGroupCheckbox(state: State, { payload }: PayloadAction<{ checked: boolean; id: number }>) {
      state.meteoBulletinGroups = [...state.meteoBulletinGroups].map((item: MeteoBulletinGroup) =>
        item.id === payload.id
          ? {
              ...item,
              bulletinElements: item.bulletinElements.map((el: Bulletin) => ({ ...el, isChecked: payload.checked })),
            }
          : item,
      );
    },
    toggleSubscriptionStatus(state: State, { payload }: PayloadAction<{ checked: boolean; id: number }>) {
      state.localSubscriptions = state.localSubscriptions.map((item: Profile) =>
        item.id === payload.id ? { ...item, isActive: payload.checked } : item,
      );
    },
    createLocalSubscription(state: State, { payload }: PayloadAction<Profile>) {
      state.localSubscriptions.push(payload);
    },
    updateLocalSubscription(state: State, { payload }: PayloadAction<Profile>) {
      state.localSubscriptions = state.localSubscriptions.map((profile: Profile) => (profile.id === payload.id ? payload : profile));
    },
    removeSubscription(state: State, { payload }: PayloadAction<number>) {
      state.localSubscriptions = state.localSubscriptions.filter(({ id }) => id !== payload);
    },
  },
});

export const subscriptionsActions = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
