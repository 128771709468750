import React from 'react';
import { Skeleton } from '@mui/material';

interface Props {
  width?: number;
  height?: number;
}

const CSDdButtonSkeleton = ({ width, height }: Props) => {
  return <Skeleton variant="rectangular" width={width ?? 160} height={height ?? 40} sx={{ borderRadius: '0.5rem' }} />;
};

export default CSDdButtonSkeleton;
