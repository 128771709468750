import React, { SVGTextElementAttributes } from 'react';
import { TEXT_FONT_SIZE } from '../constants';

type Props = {
  label: string;
} & SVGTextElementAttributes<SVGTextElement>;

const CSDSvgText = ({ label, ...other }: Props) => {
  return (
    <text fill="white" style={{ fontSize: TEXT_FONT_SIZE, fontWeight: 700 }} {...other}>
      {label}
    </text>
  );
};

export default CSDSvgText;
