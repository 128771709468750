import React from 'react';
import { Stack } from '@mui/material';
import { SidebarConfig } from '@app/v2/shared/configs';
import CSDSideBarListPoint from '@app/v2/entities/CSDSidebar/CSDSideBarListPoint';

const CSDSidebar = ({ onClose }: { onClose: Common.VoidCallBack }) => (
  <Stack sx={{ gap: '1rem', cursor: 'pointer' }}>
    <CSDSideBarListPoint onClose={onClose} sidebarConfigItems={SidebarConfig} />
  </Stack>
);

export default CSDSidebar;
