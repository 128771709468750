import React from 'react';
import { withTranslation } from 'react-i18next';
import { CSDInput } from '@app/modules/kit-module/shared/ui';
import { DEFAULT_INPUT_LENGTH, EMPTY_STRING } from '@app/v2/shared/constants';
import { isFunction } from '@app/v2/shared/helpers';

interface Props {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  placeholder?: string;
  isValid?: { input: boolean; setup: boolean };
  setIsValid: React.Dispatch<React.SetStateAction<{ input: boolean; setup: boolean }>>;
}

const CSDConstructorTitle = ({
  onChange,
  value,
  isValid,
  setIsValid,
  t,
  ...other
}: Props & I18N.TranslationFunction<'charts', 'meteoConstructor'>) => {
  const handleChange = (nextValue: string): void => {
    if (!isFunction(onChange)) return;
    onChange(nextValue);
  };

  return (
    <CSDInput
      error={!isValid.input}
      helperText={!isValid.input ? t('requiredField') : EMPTY_STRING}
      autoFocus
      fullWidth
      label={t('inputLabel')}
      placeholder={t('inputPlaceholder')}
      value={value}
      onFocus={() => setIsValid(prev => ({ ...prev, input: true }))}
      onChange={({ target }) => handleChange(target.value)}
      inputProps={{
        maxLength: DEFAULT_INPUT_LENGTH,
      }}
      {...other}
    />
  );
};

export default withTranslation('charts', { keyPrefix: 'meteoConstructor' })(CSDConstructorTitle);
