import { useEffect, useState } from 'react';
import LocatorControlService from '@app/modules/map-module/services/LocatorControlService';
import { PlayerStatus } from '@app/v2/shared/enums';

export default function useSubscribeLocatorControl(locatorControl: LocatorControlService): {
  control: WeatherLocators.LocatorControl;
  currentTime: string;
  playerState: PlayerStatus;
} {
  const [control, setControl] = useState<WeatherLocators.LocatorControl>(locatorControl.$control);
  const [currentTime, setCurrentTime] = useState<string>(locatorControl.$control.startTime);
  const [playerState, setPlayerState] = useState<PlayerStatus>(PlayerStatus.STOP);

  useEffect(() => {
    const subscriptionOnCurrentControl = locatorControl.control.subscribe(nextControlValue => {
      setControl(nextControlValue);
    });

    return () => {
      subscriptionOnCurrentControl.unsubscribe();
    };
  }, [locatorControl.control]);

  useEffect(() => {
    const subscriptionOnCurrentTime = locatorControl.currentTime.subscribe(nextTimeValue => {
      setCurrentTime(nextTimeValue);
    });

    return () => {
      subscriptionOnCurrentTime.unsubscribe();
    };
  }, [locatorControl.currentTime]);

  useEffect(() => {
    const subscription = locatorControl.state.subscribe(currentState => {
      setPlayerState(currentState);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [locatorControl]);

  return { control, currentTime, playerState };
}
