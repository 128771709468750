import React, { ReactNode } from 'react';
import { Stack, StackProps, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/styles';
import CloseDialogButton from '@app/components/close-dialog-button/CloseDialogButton';

interface Props {
  title: string;
  infoSetup?: { name: string; value: string | number }[];
  close: (data?: string) => void;
  TabsComponent?: ReactNode;
}

const DialogViewTitle = ({ title, infoSetup = [], close, TabsComponent }: Props) => {
  return (
    <StyledWrapper direction="row">
      <StyledInfoWrapper>
        <StyledLocationTypography variant="h2">{title}</StyledLocationTypography>

        <StyledInfoStack direction="row">
          {infoSetup.map(({ name, value }) => (
            <Typography key={name} component="span">
              {name}: {value}
            </Typography>
          ))}
        </StyledInfoStack>
      </StyledInfoWrapper>

      {TabsComponent && <TabWrapper direction="row">{TabsComponent}</TabWrapper>}
      <CloseDialogButton iconSize={36} callback={close} />
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Stack)<StackProps>({
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1.5rem 1.5rem 0rem 1.5rem',
  '& > button': {
    alignSelf: 'flex-start',
    flex: '1 1 0px',
    justifyContent: 'flex-end',
  },
  '& > div': {
    flex: '1 1 0px',
  },
});

const StyledInfoWrapper = styled(Stack)<StackProps>({
  flexDirection: 'row',
  gap: '1rem',
});

const StyledInfoStack = styled(Stack)<StackProps>({
  gap: '2.5rem',
});

const StyledLocationTypography = styled(Typography)<TypographyProps>({
  fontSize: '1.875rem',
  fontWeight: 700,
});

const TabWrapper = styled(Stack)<StackProps>({
  justifyContent: 'center',
  width: 'fit-content',
});

export default DialogViewTitle;
