import React from 'react';
import CSDWipeVideoCamera from './CSDWipeVideoCamera';
import CSDWipeVideoCameraBtn from './CSDWipeVideoCameraBtn';

interface Props {
  stationId: number;
  disabled?: boolean;
}

const CSDWipeVideoCameraFeature = ({ stationId, disabled }: Props) => {
  return <CSDWipeVideoCamera AnchorNode={CSDWipeVideoCameraBtn} id={stationId} disabled={disabled} />;
};

export default CSDWipeVideoCameraFeature;
