import React, { useMemo } from 'react';
import { Icon, IconProps, Tooltip } from '@mui/material';

type Props = {
  iconName?: string;
  notifications?: {
    iconName: string;
    tooltip?: string;
  }[];
} & IconProps;

const CSDIconMarkerWithTooltip = ({ notifications, iconName, ...iconProps }: Props) => {
  const mainIconData = useMemo(
    () => ({
      iconName: iconName || notifications[0]?.iconName,
      tooltip: !iconName && notifications.length === 1 ? notifications[0]?.tooltip : null,
    }),
    [iconName, notifications],
  );

  return (
    <Tooltip title={mainIconData.tooltip}>
      <Icon key={mainIconData.iconName} {...iconProps}>
        {mainIconData.iconName}
      </Icon>
    </Tooltip>
  );
};

export default CSDIconMarkerWithTooltip;
