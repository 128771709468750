import React, { useCallback, useState } from 'react';
import { Dialog, Skeleton } from '@mui/material';
import { useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { CSDScoreboardsViewer } from '@app/modules/kit-module/shared/ui';
import { DialogRef } from '@app/core/providers/DialogProvider';
import vmsNewQueries from '@app/clients/apollo/requests/queries/vmsNew';
import { getConfigItems, getNestedConfig, isFunction, vmsSelectorsNormalizer } from '@app/v2/shared/helpers';
import { emptyTemplatesValues } from '@app/v2/shared/constants';
import CSDTemplateMainForm from './CSDTemplateMainForm';
import messageTemplateSchema from '../../validationsSchemas/messageTemplateSchema';

interface Props {
  template: Scoreboards.TemplateContent;
  onUpdate: (nextTemplateValue: Scoreboards.TemplateContent) => void;
}

const CSDTemplateDialog = ({ template, onUpdate, close }: Props & DialogRef) => {
  const [configuration, setConfiguration] = useState<Scoreboards.NestedConfiguration | null>(null);

  const { data: configurationsSelectors } = useQuery<Scoreboards.ConfigurationsSelectorsResponse>(vmsNewQueries.configurationSelectors);
  const { data: signsSelectors } = useQuery<Scoreboards.SignsSelectorsResponse>(vmsNewQueries.signsSelector);

  const {
    data: configurationData,
    refetch: fetchConfigById,
    loading: configurationLoading,
  } = useQuery<Scoreboards.VMSConfigurationResponse, Scoreboards.VMSConfigurationVariables>(vmsNewQueries.configuration, {
    skip: !!configuration,
    variables: {
      id: template.configuration.id,
    },
  });

  const fetchConfiguration = useCallback(
    async (configurationId: number) => {
      if (!isFunction(fetchConfigById)) return null;

      const { data } = await fetchConfigById({ id: configurationId });

      if (!data) return null;

      setConfiguration(getNestedConfig(data?.vmsNew?.configuration));

      return {
        newConfiguration: data?.vmsNew?.configuration,
        newContent: getConfigItems(data?.vmsNew?.configuration).map(configItem => emptyTemplatesValues[configItem.type](configItem)) ?? [],
      };
    },
    [fetchConfigById],
  );

  return (
    <Dialog maxWidth="xl" open onClose={close}>
      {configurationLoading ? (
        <Skeleton variant="rectangular" width="100%" height="30rem" />
      ) : (
        <Formik<Scoreboards.TemplateContent>
          initialValues={template}
          validationSchema={messageTemplateSchema}
          onSubmit={nextValues => {
            close();
            onUpdate(nextValues);
          }}
        >
          <CSDTemplateMainForm
            onCloseDialog={close}
            fetchConfiguration={fetchConfiguration}
            signsSelectors={vmsSelectorsNormalizer(signsSelectors?.vmsNew?.selectors?.templates?.sign)}
            configuration={configuration ?? getNestedConfig(configurationData?.vmsNew?.configuration)}
            configurationsSelectors={vmsSelectorsNormalizer(configurationsSelectors?.vmsNew?.selectors?.configurations?.configurations)}
            templateViewer={CSDScoreboardsViewer}
          />
        </Formik>
      )}
    </Dialog>
  );
};

export default CSDTemplateDialog;
