import React from 'react';
import { useTranslation, KeyPrefix } from 'react-i18next';
import clsx from 'clsx';
import { Icon, Button, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import icons from '@app/assets/iconFont';
import { AutoPaginationPeriodsInSeconds } from '@app/v2/shared/enums';

const intervals: { value: AutoPaginationPeriodsInSeconds; label: KeyPrefix<'common'>; amount?: number }[] = [
  { value: AutoPaginationPeriodsInSeconds.fifteen, label: 'dates.periods.shortSeconds', amount: 15 },
  { value: AutoPaginationPeriodsInSeconds.thirty, label: 'dates.periods.shortSeconds', amount: 30 },
  { value: AutoPaginationPeriodsInSeconds.minute, label: 'dates.periods.oneMinuteShort' },
];

type Props = {
  period: number;
  onChangePeriod: (value: number) => () => void;
};

const CSDAutoPagination = ({ period, onChangePeriod }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {intervals.map(({ value, label, amount }) => (
        <Button
          key={value}
          className={clsx(classes.fontSizePreview, { active: period === value })}
          variant="outlined"
          {...(period === value && { variant: 'contained' })}
          size="small"
          endIcon={<Icon>{icons.interval}</Icon>}
          onClick={onChangePeriod(value)}
          data-testid={`auto-pagination-btn-${value}`}
        >
          {t(label, { amount })}
        </Button>
      ))}
    </Stack>
  );
};

const useStyles = makeStyles(() => ({
  fontSizePreview: {
    '&.MuiButton-root': {
      minWidth: 'auto',
      padding: '0 0.5rem',
      height: '1.75rem',
      fontSize: '0.75rem',
      '& .MuiButton-endIcon': {
        display: 'none',
      },
    },
    '&.active': {
      '& .MuiButton-endIcon': {
        display: 'inherit !important',
      },
    },
  },
}));

export default CSDAutoPagination;
