import DrawScoreboard from './DrawScoreboard';

export type PreviewSignProps = {
  pixelSize: number;
  content: any;
  charInterval?: number;
  startCoords: { x: number; y: number };
  linesInterval: number;
  itemSize: Scoreboards.Size;
  signValign: string | null;
};

const previewSign = (
  context: CanvasRenderingContext2D,
  { content, startCoords, pixelSize, itemSize: { height, width, margins }, signValign }: PreviewSignProps,
): Promise<void> => {
  const drawScoreboard = new DrawScoreboard(startCoords);

  const scoreboardHeight = context.canvas.height / pixelSize;

  const additionalMarginTop = {
    top: 0,
    middle: (scoreboardHeight - height) / 2,
    bottom: scoreboardHeight - height,
  };

  const signPosition = {
    x: (startCoords.x + margins.left) * pixelSize,
    y: (startCoords.y + margins.top + additionalMarginTop[signValign]) * pixelSize,
  };
  const signSize = {
    width: (width - margins.right - margins.left) * pixelSize,
    height: (height - margins.top - margins.bottom) * pixelSize,
  };

  return new Promise<void>(resolve => {
    drawScoreboard.drawSign(
      context,
      {
        signFile: content?.signFile,
        position: signPosition,
        size: signSize,
      },
      () => resolve(undefined),
    );
  });
};

export default previewSign;
