import React from 'react';
import CSDDownloadVideoSnapshot from './CSDDownloadVideoSnapshot';
import CSDDownloadVideoSnapshotBtn from './CSDDownloadVideoSnapshotBtn';

interface Props {
  imageUrl: string;
}

const CSDDownloadVideoSnapshotFeature = ({ imageUrl }: Props) => {
  return <CSDDownloadVideoSnapshot AnchorNode={CSDDownloadVideoSnapshotBtn} imageUrl={imageUrl} />;
};

export default CSDDownloadVideoSnapshotFeature;
