export enum LinksCellIcons {
  VIDEO = 'video',
  TRAFFIC = 'traffic',
  METEO = 'meteo',
  PLAN_GRAPH = 'chart',
}

export enum AlertStatusClasses {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  NotEnoughData = 'notEnoughData',
  Unavailable = 'unavailable',
}

export enum DataGridVirtualizedContentMode {
  Simple = 'simple',
  Cache = 'cache',
}

export enum TableMode {
  Scroll = 'scroll',
  Pagination = 'pagination',
}
