import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Divider, Box, Typography, LinearProgress } from '@mui/material';
import useNotificationProfileContext from '@app/v2/features/notificationProfile/context/useNotificationProfileContext';
import LocalTabForm from './LocalTabForm';
import ProfileManager from './profileManager/ProfileManager';

const LocalTab = ({ t }: WithTranslation<'notification', 'profiles'>) => {
  const classes = useStyles();
  const { isLoading } = useNotificationProfileContext();

  return (
    <Box className={classes.localTabContainer}>
      <Box>
        <Typography className={classes.profileTitle} variant="h4">
          {t('title')}
        </Typography>
        <ProfileManager />
      </Box>
      <Box className={classes.localDivider}>{isLoading ? <LinearProgress /> : <Divider />}</Box>
      <LocalTabForm />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  localTabContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  profileTitle: {
    '&.MuiTypography-root': {
      margin: '1rem 0 0.5rem 0',
    },
  },
  localDivider: {
    margin: '1rem 0',
  },
}));

export default withTranslation('notification', { keyPrefix: 'profiles' })(LocalTab);
