enum MapCardControlTabs {
  Settings = 'settings',
  Weather = 'weather',
  RoadObjects = 'roadObjects',
  LocatorImages = 'locatorImages',
  Gis = 'gis',
  Layers = 'layers',
  Notes = 'notes',
}

export default MapCardControlTabs;
