import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileMapSettingLabels, RoadObjectsTypes } from '@app/v2/shared/enums';
import { Profile, CheckBoxes } from '@app/core/types/profiles/map';
import DataLoadingTypes from '@app/modules/map-module/enums/DataLoadingTypes';
import client from '@app/clients/apollo/client';
import { showApolloErrors, showError } from '@app/core/utils/notifications';
import useMapDataLoading from '@app/core/hooks/useMapDataLoading';
import ErrorMessages from '@app/core/constants/errorsMessages';
import useActions from '@app/core/hooks/customReduxHooks/useActions';
import mutateMapSettingProfiles from '@store/thunks/map/profiles';
import { useAppDispatch } from '@app/v2/shared/hooks';
import profileQueries from '@app/clients/apollo/requests/queries/profiles';
import LayerTypes from '@app/modules/map-module/enums/LayerTypes';
import { MAP_PROFILE_YUP_SCHEME } from '@app/v2/shared/constants';
import { isBoolean } from '@app/v2/shared/helpers';

// no longer use layer OSM
const removeOSMLayerFromUsersSettings = (mapSettingProfiles: Profile[]): Profile[] => {
  return mapSettingProfiles.map(setting => ({
    ...setting,
    items: {
      ...setting.items,
      [ProfileMapSettingLabels.ACTIVE_LAYER]: {
        ...setting.items[ProfileMapSettingLabels.ACTIVE_LAYER],
        settings:
          setting.items[ProfileMapSettingLabels.ACTIVE_LAYER].settings === 'OSM'
            ? LayerTypes.YANDEX_MAP
            : setting.items[ProfileMapSettingLabels.ACTIVE_LAYER].settings,
      },
    },
  }));
};

// TODO: remove when backend is typified map profiles
const setRoadObjectMeteoByDefault = (mapSettingProfiles: Profile[]): Profile[] => {
  return mapSettingProfiles.map(setting => {
    const roadObjectMeteoParamsFirstValue =
      setting.items[ProfileMapSettingLabels.ROAD_OBJECTS].settings?.[RoadObjectsTypes.METEO_PARAMS_FIRST]?.checked;
    const roadObjectMeteoParamsSecondValue =
      setting.items[ProfileMapSettingLabels.ROAD_OBJECTS].settings?.[RoadObjectsTypes.METEO_PARAMS_SECOND]?.checked;

    return {
      ...setting,
      items: {
        ...setting.items,
        [ProfileMapSettingLabels.ROAD_OBJECTS]: {
          ...setting.items[ProfileMapSettingLabels.ROAD_OBJECTS],
          settings: {
            ...(setting.items[ProfileMapSettingLabels.ROAD_OBJECTS].settings as CheckBoxes),
            [RoadObjectsTypes.METEO_PARAMS_FIRST]: {
              checked: isBoolean(roadObjectMeteoParamsFirstValue) ? roadObjectMeteoParamsFirstValue : true,
            },
            [RoadObjectsTypes.METEO_PARAMS_SECOND]: {
              checked: isBoolean(roadObjectMeteoParamsSecondValue) ? roadObjectMeteoParamsSecondValue : true,
            },
          },
        },
      },
    };
  });
};

const useLoadingProfiles = (): boolean => {
  const dispatch = useAppDispatch();
  const { setActiveMapProfile, fetchMapProfilesSuccess } = useActions();
  const { t } = useTranslation('common', { keyPrefix: 'errors' });
  const [loadings, setDataMapLoading] = useMapDataLoading();

  useEffect(() => {
    const setProfiles = (mapProfiles: Profile[]) => {
      const validProfiles = setRoadObjectMeteoByDefault(mapProfiles?.filter(profile => MAP_PROFILE_YUP_SCHEME.isValidSync(profile)));
      const defaultProfile = validProfiles?.find(profile => profile.isDefault);

      if (!!mapProfiles && mapProfiles.length !== validProfiles.length) {
        dispatch(mutateMapSettingProfiles(validProfiles, true));
        return;
      }

      setActiveMapProfile(defaultProfile);
      fetchMapProfilesSuccess(validProfiles);
    };

    const fetchProfiles = () => {
      setDataMapLoading(DataLoadingTypes.PROFILES, true);
      client
        .query<{ mapSettingProfiles: Profile[] }>({ query: profileQueries.mapProfiles })
        .then(({ data, errors }) => {
          if (errors?.length) {
            showApolloErrors(errors);
          }

          if (data?.mapSettingProfiles) {
            setProfiles(removeOSMLayerFromUsersSettings(data?.mapSettingProfiles));
          }
        })
        .catch(() => {
          showError(ErrorMessages.PROFILE_DATA_LOADING);
        })
        .finally(() => setDataMapLoading(DataLoadingTypes.PROFILES, false));
    };

    fetchProfiles();
  }, [fetchMapProfilesSuccess, setActiveMapProfile, setDataMapLoading, dispatch, t]);

  return loadings.PROFILES.load;
};
export default useLoadingProfiles;
