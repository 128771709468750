enum StaticObjectTypes {
  MILESTONE = 'MILESTONE',
  BRIDGE = 'BRIDGE',
  VIADUCT = 'VIADUCT',
  TUNNEL = 'TUNNEL',
  RAIL_CROSS = 'RAIL_CROSS',
  PEDESTRIAN_CROSS = 'PEDESTRIAN_CROSS',
  FLYOVER = 'FLYOVER',
  PIPE = 'PIPE',
  SAND_BASE = 'SAND_BASE',
  PROD_BASE = 'PROD_BASE',
  ASPHALT_PLANT = 'ASPHALT_PLANT',
}

export default StaticObjectTypes;
