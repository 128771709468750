import React, { FocusEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { Form, useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import { Box, Icon, Theme, Typography } from '@mui/material';
import clsx from 'clsx';
import icons from '@app/assets/iconFont';
import { DateComponentType } from '@app/components/datepicker/Datepicker';
import NoteFiles from '@app/components/common/files/NoteFiles';
import RoadAddress, { Values } from '@app/modules/map-module/components/map/notes/form/RoadAddress';
import { MapNoteInput } from '@app/modules/map-module/components/map/notes/types';
import { CSDCheckbox, CSDDataPickerAntd, CSDInput, CSDInputStandard, CSDSelect } from '@app/modules/kit-module/shared/ui';
import PRIVACY from '@app/core/constants/visibility';
import { sliceLatitudeAndLongitude } from '@app/v2/shared/helpers';
import { MESSAGE_TEXT_LIMIT, TITLE_TEXT_LIMIT, DATE_FORMAT } from '@app/v2/shared/constants';
import { NOTE_IMAGE_UPLOAD_URL } from '@app/core/constants';
import { AddressType } from '@app/v2/shared/enums';

const MapNoteForm = () => {
  const classes = useStyles();
  const { t } = useTranslation('map', { keyPrefix: 'notes.form' });
  const { setFieldValue, values, getFieldMeta, errors, handleChange, touched, handleBlur } = useFormikContext<
    MapNoteInput & { roadAddress?: string }
  >();

  const [visibility, setVisibility] = useState<string>(values.visibility);
  const [validValues, setValidValues] = useState<Values>({
    roadId: values.roadId,
    latitude: values.place?.latitude,
    longitude: values.place?.longitude,
    kilometer: values.place?.kilometer,
    meter: values.place?.meter,
  });

  const minDate: moment.Moment = useMemo(() => moment().subtract(1, 'days'), []);
  const maxDate: moment.Moment = useMemo(() => moment().endOf('day'), []);

  const debounceSetValidValues = useRef(
    debounce((condition, v) => {
      if (condition) {
        setValidValues(v);
      }
    }, 800),
  );

  useEffect(() => {
    const longitude = getFieldMeta<number>('place.longitude');
    const latitude = getFieldMeta<number>('place.latitude');
    const roadId = getFieldMeta<number>('roadId');
    const kilometer = getFieldMeta<number>('place.kilometer');
    const meter = getFieldMeta<number>('place.meter');

    debounceSetValidValues.current(!longitude.error && !latitude.error, {
      roadId: roadId.value,
      latitude: latitude.value,
      longitude: longitude.value,
      kilometer: kilometer.value,
      meter: meter.value,
    });
  }, [errors, getFieldMeta]);

  const {
    place: { latitude, longitude },
  } = values;

  const handleDateChange = (date: moment.Moment) => {
    setFieldValue('dateTime', moment(date).format(DATE_FORMAT.WITH_TIME_ZONE));
  };

  const handleOnBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>, key: keyof MapNoteInput) => {
    handleBlur(event);
  };

  const handleComponentSelection = useCallback(
    (component: AddressType) => {
      setFieldValue('isAddress', component === AddressType.Coordinates);
    },
    [setFieldValue],
  );

  return (
    <Form>
      <Grid container spacing={2} columns={8} p={2} alignItems="top">
        <Grid item xs={5.3}>
          <CSDInputStandard
            name="title"
            placeholder={t('titlePlaceholder')}
            label={t('title')}
            value={values.title}
            onChange={handleChange}
            error={touched.title && Boolean(errors.title)}
            helperText={touched.title ? errors.title : ''}
            onBlur={event => {
              handleOnBlur(event, 'title');
            }}
            maxLength={TITLE_TEXT_LIMIT}
          />
        </Grid>
        <Grid item xs={2.7} alignItems="flex-end" display="flex">
          <CSDDataPickerAntd
            type={DateComponentType.datetime}
            format={DATE_FORMAT.FORMAT_RU_POINT_FULL_DATE_AND_UNFULL_TIME}
            value={moment(values.dateTime)}
            minDate={minDate}
            maxDate={maxDate}
            ampm={false}
            onChange={handleDateChange}
            showTime
            status={touched.dateTime && Boolean(errors.dateTime) ? 'error' : undefined}
            helperText={touched.dateTime ? errors.dateTime : ''}
          />
        </Grid>
        <Grid xs={8}>
          <Typography variant="subtitle2" className={classes.selectableBoxHeader}>
            {t('addressType')}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box
            onClick={() => handleComponentSelection(AddressType.RoadAddress)}
            className={clsx(classes.selectableBox, {
              [classes.selected]: !values.isAddress,
              [classes.unselected]: values.isAddress,
            })}
          >
            <Box sx={{ paddingBottom: '0.5rem' }}>
              <CSDCheckbox checked={!values.isAddress} size="small" label={t('nearestObject')} sx={{ padding: '0 0.5rem 0 0' }} />
            </Box>
            <RoadAddress values={validValues} />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            onClick={() => handleComponentSelection(AddressType.Coordinates)}
            className={clsx(classes.selectableBox, {
              [classes.selected]: values.isAddress,
              [classes.unselected]: !values.isAddress,
            })}
          >
            <Box sx={{ paddingBottom: '0.5rem' }}>
              <CSDCheckbox checked={values.isAddress} size="small" label={t('fullAddress')} sx={{ padding: '0 0.5rem 0 0' }} />
            </Box>
            <Typography variant="body1" className={classes.coordinatesFont}>
              <Icon className={classes.coordinatesIcon}>{icons.pin}</Icon>
              {values.address || `${sliceLatitudeAndLongitude(latitude)}, ${sliceLatitudeAndLongitude(longitude)}`}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <CSDInput
            name="message"
            label={t('messageLabel')}
            placeholder={t('message')}
            multiline
            rows={6}
            value={values.message}
            onChange={handleChange}
            error={touched.message && Boolean(errors.message)}
            helperText={touched.message ? errors.message : ''}
            onBlur={event => {
              handleOnBlur(event, 'message');
            }}
            maxLength={MESSAGE_TEXT_LIMIT}
          />
        </Grid>

        <Grid item xs={8}>
          <CSDSelect
            label={t('noteVisibility')}
            id="visibility"
            name="visibility"
            value={visibility}
            onChange={e => {
              setVisibility(e.target.value as string);
              setFieldValue('visibility', e.target.value);
            }}
            className={classes.designSelect}
            items={PRIVACY.map(item => ({ ...item, label: t(`visibilitySelect.${item.label}`) }))}
            error={touched.visibility && Boolean(errors.visibility)}
          />
        </Grid>
        <NoteFiles uploadPath={NOTE_IMAGE_UPLOAD_URL} photoField={values.photos} />
      </Grid>
    </Form>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  coordinatesFont: {
    '&&': {
      color: theme.palette.info.main,
      lineHeight: '1.5rem',
    },
  },
  coordinatesIcon: {
    '&&': {
      verticalAlign: 'middle',
      color: theme.palette.primary.main,
      marginRight: '0.1rem',
    },
  },
  designSelect: {
    width: '100%',
    color: theme.palette.primary.main,
  },
  selectableBoxHeader: {
    '&&': {
      fontSize: '0.875rem',
      lineHeight: '0.875rem',
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
  },
  selectableBox: {
    padding: theme.spacing(2),
    cursor: 'pointer',
    border: `0.063rem solid`,
    borderRadius: '1rem',
    transition: 'box-shadow 0.1s ease-in-out, border-color 0.1s ease-in-out',
  },
  selected: {
    borderColor: theme.palette.primary.main,
    boxShadow: `0 0.25rem 0.5rem ${theme.palette.grey[400]}`,
  },
  unselected: {
    borderColor: theme.palette.grey[300],
    '&:hover': {
      boxShadow: `0 0.25rem 0.5rem ${theme.palette.grey[400]}`,
    },
  },
}));

export default MapNoteForm;
