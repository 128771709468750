import * as Yup from 'yup';
import moment from 'moment';
import { DATE_FORMAT } from '@app/v2/shared/constants';
import { i18n } from '@app/v2/shared/localization';

export const dateValidator = Yup.string()
  .test('date', i18n.t('validation:wrongDate'), value => {
    const date = moment(value, DATE_FORMAT.API_DATE_FORMAT);

    if (date.isValid()) {
      return date.format(DATE_FORMAT.API_DATE_FORMAT) === value;
    }

    return false;
  })
  .nullable();

export const dateValidatorNotes = Yup.string()
  .test('date', i18n.t('validation:wrongDate'), value => {
    const date = moment(value, DATE_FORMAT.WITH_TIME_ZONE);

    if (date.isValid()) {
      return date.format(DATE_FORMAT.WITH_TIME_ZONE) === value;
    }

    return false;
  })
  .nullable();

export const positiveNumberValidator = Yup.number().nullable().min(0).integer();

export const temperature = Yup.number()
  .min(-60, i18n.t('validation:temperatureValue', { amount: -60 }))
  .max(60, i18n.t('validation:temperatureValue', { amount: 60 }))
  .nullable();

export const kilometers = Yup.number().min(0).max(2000).nullable().integer();

export const meters = Yup.number().min(0).max(999).nullable().integer();
