import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps, Icon } from '@mui/material';
import { useMutation } from '@apollo/client';
import icons from '@app/assets/iconFont';
import { DataTestIds } from '@app/v2/shared/enums';
import reportMutations from '@app/clients/apollo/requests/mutations/reports';
import { addFeatureActions } from '@app/v2/shared/utils';
import { showSuccess } from '@app/core/utils/notifications';

interface Props extends Common.FeaturesActions {
  options: Reports.Options;
  btnProps?: ButtonProps;
}

const CSDReportToEmailBtn = ({ options, btnProps: { disabled, ...other }, ...featuresActions }: Props) => {
  const { t } = useTranslation('reports');

  const [createReport, { loading }] = useMutation<Reports.CreateReportResponse, Reports.Options>(reportMutations.create);

  const handleCreateReport = () => {
    if (!options) return;

    addFeatureActions(
      createReport({ variables: options }).then(({ data }) => {
        showSuccess(data.reportsMutation.create.message);
      }),
      featuresActions,
    );
  };

  return (
    <Button
      startIcon={<Icon>{icons.mail}</Icon>}
      onClick={handleCreateReport}
      disabled={disabled || loading}
      data-testid={DataTestIds.ReportCreateBtn}
      {...other}
    >
      {t('actions.createReport')}
    </Button>
  );
};

export default CSDReportToEmailBtn;
