import React, { useCallback, useEffect, useState } from 'react';
import { Polyline, Popup } from 'react-leaflet';
import { makeStyles } from '@mui/styles';
import { LatLngTuple } from 'leaflet';
import CircularProgress from '@app/components/common/circular-progress/CircularProgress';
import client from '@app/clients/apollo/client';
import { InfoRoadSegment, RoadSegment } from '@app/modules/map-module/interfaces/RoadSegment';
import DataLoadingTypes from '@app/modules/map-module/enums/DataLoadingTypes';
import useMapDataLoading from '@app/core/hooks/useMapDataLoading';
import roadQueries from '@app/clients/apollo/requests/queries/roads';
import { useActions, useAppDispatch, useAppSelector, useNewFilters } from '@app/v2/shared/hooks';

const RoadMapping = () => {
  const isChecked = useAppSelector(state => state.map.settings.ROAD_MAPPING.checked);
  const activeProfile = useAppSelector(state => state.map.activeProfile);
  const [, setDataMapLoading] = useMapDataLoading();
  const dispatch = useAppDispatch();
  const { setMapRoadSegmentsCoordinates } = useActions();
  const [roadSegments, setRoadSegments] = useState<RoadSegment[]>([]);
  const { filters } = useNewFilters();
  const [infoRoadSegment, setInfoRoadSegment] = useState<{ [key: string]: InfoRoadSegment }>({});

  const setActiveMapLocationCoordinates = useCallback(
    (arr: LatLngTuple[]) => {
      dispatch(setMapRoadSegmentsCoordinates(arr));
    },
    [dispatch, setMapRoadSegmentsCoordinates],
  );

  const getRoadSegmentsCoordinates = useCallback((data: RoadSegment[]): LatLngTuple[] => {
    const coordinates = [];
    data.forEach(item => {
      item.points.forEach(({ lat, lng }) => coordinates.push([lat, lng]));
    });
    return coordinates;
  }, []);

  const getRoadSegments = useCallback(() => {
    setDataMapLoading(DataLoadingTypes.ROAD_MAPPING, true);
    client
      .query<{ roadSegments: RoadSegment[] }>({
        query: roadQueries.roadSegments,
        variables: { roadIds: filters?.roads || [], organizationIds: filters?.organizations || [] },
      })
      .then(res => {
        if (res?.data) {
          setRoadSegments(res.data.roadSegments);
          setActiveMapLocationCoordinates(getRoadSegmentsCoordinates(res.data.roadSegments));
        }
      })
      .finally(() => {
        setDataMapLoading(DataLoadingTypes.ROAD_MAPPING, false);
      });
  }, [getRoadSegmentsCoordinates, filters, setActiveMapLocationCoordinates, setDataMapLoading]);

  useEffect(() => {
    if (isChecked) {
      getRoadSegments();
    } else {
      setActiveMapLocationCoordinates([]);
      setRoadSegments([]);
    }
  }, [getRoadSegments, isChecked, setActiveMapLocationCoordinates, activeProfile]);

  const loadInfoRoad = (roadId: number) => {
    // FIXME добавить запрос на бэк когда повится информация по дороге
    setInfoRoadSegment({
      ...infoRoadSegment,
      ...{
        [roadId]: {
          orgName: '13 ФКУ "Поволжуправтодор" ("Большая Волга")',
          partners: ['ОАО "Московские дороги"', 'ФКУ ЦУКС ГУ МЧС России по Пензенской области', 'ООО "АВТОДОРПРИБОР"'],
        },
      },
    });
  };

  // TODO будет использовать когда повится информация по дороге
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onClick = (roadId: number) => {
    if (!infoRoadSegment[roadId]) {
      loadInfoRoad(roadId);
    }
  };

  const classes = useStyles();

  // TODO будет использовать когда повится информация по дороге
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getPopup = useCallback(
    (roadId: number) => {
      return (
        <Popup>
          {infoRoadSegment[roadId] ? (
            <div>
              <h2 className={classes.h2}>{infoRoadSegment[roadId].orgName}</h2>
              <ol>
                {infoRoadSegment[roadId].partners.map(partner => (
                  <li key={partner}>{partner}</li>
                ))}
              </ol>
            </div>
          ) : (
            <CircularProgress />
          )}
        </Popup>
      );
    },
    [classes.h2, infoRoadSegment],
  );

  return (
    <>
      {roadSegments.map(({ color, roadPartId, points, roadId }) => (
        <Polyline key={roadPartId} color={color} positions={points} />
      ))}
    </>
  );
};

const useStyles = makeStyles(() => ({
  h2: {
    fontWeight: 700,
  },
}));

export default RoadMapping;
