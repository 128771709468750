import icons from '@app/assets/iconFont';

const cloudinessConfig: StaticConfigs.CloudinessItem[] = [
  {
    name: 'cloudiness.sunny',
    iconName: icons.clearly,
    values: [0],
  },
  {
    name: 'cloudiness.mostlySunny',
    iconName: icons.cloudThree,
    values: [1, 2, 3],
  },
  {
    name: 'cloudiness.partlyCloudy',
    iconName: icons.cloudTwo,
    values: [4, 5],
  },
  {
    name: 'cloudiness.cloudyDay',
    iconName: icons.cloudOne,
    values: [6, 7, 8, 9],
  },
  {
    iconName: icons.cloudFour,
    name: 'cloudiness.cloudy',
    values: [10],
  },
];

export default cloudinessConfig;
