import FETCH_NOTE from './fetchNote.graphql';
import FETCH_NOTE_ROAD_ADDRESS from './fetchNoteRoadAddress.graphql';
import FETCH_NOTES from './fetchNotes.graphql';
import FETCH_MAP_NOTES from './fetchMapNotes.graphql';

const mapNoteQueries = {
  note: FETCH_NOTE,
  noteRoadAdress: FETCH_NOTE_ROAD_ADDRESS,
  notes: FETCH_NOTES,
  mapNotes: FETCH_MAP_NOTES,
};

export default mapNoteQueries;
