export enum ReportsSections {
  Common = 'common',
  Services = 'services',
}

export enum ReportType {
  Meteo = 'meteo',
  Video = 'video',
  Traffic = 'traffic',
  Locator = 'locator',
  ServiceMeteo = 'serviceMeteo',
  ServiceVideo = 'serviceVideo',
  ServiceTraffic = 'serviceTraffic',
}

export enum ReportsGroups {
  Main = 'main',
  Additional = 'additional',
}

export enum ReportSetupComponentsTypes {
  CheckBox = 'checkBox',
  Button = 'button',
  IconButton = 'iconButton',
  Select = 'select',
}

export enum ReportDatesComponentsTypes {
  FullInterval = 'fullInterval',
  YearsInterval = 'yearsInterval',
  Date = 'date',
  Month = 'month',
  Year = 'year',
  Universal = 'universal',
  FiveMinutes = 'fiveMinutes',
}

export enum ReportsPreviewMod {
  Last24Hours = 'last_24h',
  Default = 'default',
}

export enum TrafficReportPeriods {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum TrafficReportDirections {
  FORWARD = 'forward',
  BACKWARD = 'backward',
  BOTH = 'both',
}

export enum ReportFormat {
  PDF = 'pdf',
  XLSX = 'xlsx',
}

export enum ResponseDataType {
  Meteo = 'meteoStations',
  Traffic = 'trafficStations',
  Video = 'videoStations',
}

/** Meteo */

export enum MeteoReportsSubGroups {
  ExtremeValues = 'extremeValues',
  ObservationsHistory = 'observationsHistory',
}

export enum MeteoReportKeys {
  EXTREME_VALUES = 'extreme_values',
  OBSERVATIONS_HISTORY = 'observations_history',
  // MALFUNCTIONS_BY_ORGANIZATIONS_FDA = 'malfunctions_by_organizations_meteo_fda', // Report type not use
}

export enum MeteoReportPayload {
  TAir = 'isAirTemp',
  TRoad = 'isRoadTemp',
  TRoad2 = 'isRoadTemp2',
  TUnderRoad = 'isDeepRoadTemp',
  TUnderRoad2 = 'isDeepRoadTemp2',
  TDewPoint = 'isDewPoint',

  TFreezingPoint = 'isFreezingPoint',
  TFreezingPoint2 = 'isFreezingPoint2',
  PMGP = 'isPMGP',
  PMGP2 = 'isPMGP2',
  PMG = 'isPMG',
  PMG2 = 'isPMG2',

  WindSpeed = 'isWindSpeed',
  WindDirection = 'isWindDirectionGrad',
  WindDirGr = 'isWindDirection1',

  PrecipitationIntensity = 'isPrecipitationIntensity',
  PrecipitationInstant = 'isPrecipitationInstant',
  PrecipitationType = 'isPrecipitationType',
  WaterHeight = 'isWaterHeight',
  StatusDamp = 'isRoadState',
  StatusDamp2 = 'isRoadState2',

  Humidity = 'isHumidity',
  Pressure = 'isPressure',
  Visibility = 'isVisibility',
  SkyState = 'isSkyState',
}

/** Traffic */

export enum TrafficReportsSubGroups {
  Universal = 'universal',
  ODM = 'odm',
  Years = 'years',
  Months = 'months',
  Day = 'day',
  Hours = 'hours',
  FiveMinutes = 'fiveMinutes',
  TotalQuantity = 'totalQuantity',
}

export enum TrafficReportKeys {
  ODMYear = 'odm_year',
  ODMYearConversion = 'odm_year_conversion',
  ODMMonth = 'odm_month',
  ODMMonthConversion = 'odm_month_conversion',

  Universal = 'traffic_universal',

  Intensity = 'traffic_intens',
  IntensityDirection = 'traffic_intens_direction',
  IntensityHour = 'traffic_intens_hour',
  IntensityHourSpeed = 'traffic_intens_hour_speed',
  IntensityHourSpeedFiveMinutes = 'traffic_intens_hour_speed_five_minute',

  Period = 'traffic_period',

  DailySummaryByHour = 'day_sum_hour',
  AverageIntensityByHour = 'traffic_avg_hour_day',

  ConsolidatedYearDaysAndHours = 'traffic_sum_year_day_hour',
  ConsolidatedYearDaysAndHoursWithGroups = 'traffic_sum_year_day_hour_group',
  ConsolidatedYear = 'traffic_year',
  ConsolidatedYears = 'traffic_sum_year',
  AverageValuesYears = 'year_day_avg',

  Thunderous = 'traffic_truck',

  ConsolidatedMonth = 'traffic_sum_month',
  ConsolidatedGrowthMonth = 'traffic_sum_month_compare',
  ConsolidatedMaxMonth = 'traffic_sum_month_max',
}

export enum TrafficReportPayload {
  FullDay = 'isFullDay',
  MMZPoint = 'isMMZPoint',
  NotMMZPoint = 'isNotMMZPoint',
  TotalRange = 'isTotalRange',
  Direction = 'direction',
  Periods = 'period',
}

/** Locator */

export enum LocatorReportKeys {
  REPORT_PERIOD = 'locator_analyze_period',
  REPORT_MONTH = 'locator_analyze_month',
  REPORT_YEAR = 'locator_analyze_year',
}

export enum LocatorReportPayload {
  FullData = 'isFullData',
  IsLocator = 'isLocator',
  IsAdms = 'isAdms',
}

export enum LocatorReportsSubGroups {
  Months = 'months',
  Years = 'years',
  Period = 'period',
}

/** Meteo services */

export enum MeteoServicesReportKeys {
  MALFUNCTIONS_BY_ORGS = 'malfunctions_by_organizations_meteo',
  RO_CALENDAR = 'ro_calendar_meteo',
  DIAGNOSTIC_SENSORS = 'diagnostic_sensors',
}

export enum MeteoServicesReportsSubGroups {
  Malfunctions = 'malfunctions',
  RO = 'ro',
  DiagnosticSensors = 'diagnosticSensors',
}

/** Video services */

export enum VideoServicesReportKeys {
  MALFUNCTIONS_BY_ORGS = 'malfunctions_by_organizations_video',
  RO_CALENDAR = 'ro_calendar_video',
}

export enum VideoServicesReportsSubGroups {
  Malfunctions = 'malfunctions',
  RO = 'ro',
}

/** Traffic services */

export enum TrafficServicesReportKeys {
  MALFUNCTIONS_BY_ORGS = 'malfunctions_by_organizations_traffic',
  RO_CALENDAR = 'traffic_ro_calendar',
}

export enum TrafficServicesReportsSubGroups {
  Malfunctions = 'malfunctions',
  RO = 'ro',
}
