/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReducersNames } from '@store/constants';
import { LocalStorageKeys } from '@app/v2/shared/enums';
import { StartPageItem } from './types';

type State = {
  isNotificationOpen: boolean;
  isSidebarOpen: boolean;
  isHeaderRollUp: boolean;
  isVideoWall: boolean;
  isListingLoaded: boolean;
  isDisablePagination: boolean;
  roadsOnPage: number[];
  startPages: StartPageItem[];
  startPagesLoading: boolean;
  currentFooterHeight: number;
};

export const initialState: State = {
  isNotificationOpen: false,
  isSidebarOpen: false,
  isVideoWall: false,
  isListingLoaded: false,
  isDisablePagination: false,
  startPagesLoading: false,
  isHeaderRollUp: JSON.parse(localStorage.getItem(LocalStorageKeys.HeaderStatus)) || false,
  roadsOnPage: [],
  startPages: [],
  currentFooterHeight: 0,
};

const systemSlice = createSlice({
  name: ReducersNames.system,
  initialState,
  reducers: {
    changeNotificationStatus: (state: State, { payload }: PayloadAction<{ status: boolean }>) => {
      state.isNotificationOpen = payload.status;
    },
    changeSidebarStatus: (state: State, { payload }: PayloadAction<{ status: boolean }>) => {
      state.isSidebarOpen = payload.status;
    },
    changeHeaderStatus: (state: State, { payload }: PayloadAction<{ status: boolean }>) => {
      state.isHeaderRollUp = payload.status;
    },
    changeVideoWallStatus: (state: State, { payload }: PayloadAction<{ status: boolean }>) => {
      state.isVideoWall = payload.status;
    },
    addRoadsToTrack: (state: State, { payload }: PayloadAction<{ roadIds: number[] }>) => {
      state.roadsOnPage = payload.roadIds;
    },
    setStartPagesLoading(state: State, { payload }: PayloadAction<boolean>) {
      state.startPagesLoading = payload;
    },
    setStartPages: (state: State, { payload: { startPage } }: PayloadAction<{ startPage: StartPageItem[] }>) => {
      if (!Array.isArray(startPage)) return;

      const preparedURLofPage = (page: StartPageItem): StartPageItem => {
        if (page.path.endsWith('/')) return { ...page, path: page.path.slice(0, -1) };
        return page;
      };

      state.startPages = startPage.map(preparedURLofPage);
    },
    setCurrentFooterHeight: (state: State, { payload }: PayloadAction<{ height: number }>) => {
      state.currentFooterHeight = payload.height;
    },
    setPaginationStatus: (state: State, { payload }: PayloadAction<{ status: boolean }>) => {
      state.isDisablePagination = payload.status;
    },
    toggleIsListingLoaded: (state: State, { payload }: PayloadAction<{ status: boolean }>) => {
      state.isListingLoaded = payload.status;
    },
  },
});

export const systemActions = systemSlice.actions;

export default systemSlice.reducer;
