import { useCallback } from 'react';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import usePagination from '@app/core/source/hooks/pagination/usePagination';
import { LISTING_DEFAULT_PAGE_LIMIT, LISTING_DEFAULT_PAGE_NUMBER } from '@app/core/constants';

export default function usePaginationSearch(): [
  Common.Pagination,
  (prev: Partial<Common.Pagination> | ((pagination: Partial<Common.Pagination>) => Partial<Common.Pagination>)) => void,
] {
  const [pagination] = usePagination();

  const [, setParamsPage] = useQueryParams({
    page: withDefault(NumberParam, LISTING_DEFAULT_PAGE_NUMBER),
    limit: withDefault(NumberParam, LISTING_DEFAULT_PAGE_LIMIT),
  });

  const setSearchPage = useCallback(
    (newPage: Partial<Common.Pagination> | ((pagination: Partial<Common.Pagination>) => Partial<Common.Pagination>)) => {
      const paginationPage = ({ page, limit }: Partial<Common.Pagination>) => ({
        page: page > LISTING_DEFAULT_PAGE_NUMBER ? page : undefined,
        limit: limit !== LISTING_DEFAULT_PAGE_LIMIT ? limit : undefined,
      });

      if (typeof newPage === 'object') {
        setParamsPage(paginationPage(newPage));
      }

      if (typeof newPage === 'function') {
        setParamsPage(prev => {
          return paginationPage(newPage({ ...prev, total: pagination.total }));
        });
      }
    },
    [setParamsPage, pagination.total],
  );

  return [pagination, setSearchPage];
}
