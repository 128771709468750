import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Theme } from '@mui/material/styles';
import { Formik, FormikProps } from 'formik';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import { DialogRef } from '@app/core/providers/DialogProvider';
import MapNoteForm from '@app/modules/map-module/components/map/notes/form/MapNoteForm';
import Schema from '@app/modules/map-module/components/map/notes/data/Schema';
import { showError } from '@app/core/utils/notifications';
import ListingMapComponent from '@app/modules/map-module/components/map-notes-listing/ListingMapComponent';
import { MapNoteInput } from '@app/modules/map-module/components/map/notes/types';
import defaultNoteValues from '@app/modules/map-module/components/map/notes/data/Values';
import mapNoteMutations from '@app/clients/apollo/requests/mutations/notes';
import { useActions, useAppDispatch, useAppSelector, useDialog } from '@app/v2/shared/hooks';
import { CSDConfirmDialog } from '@app/modules/kit-module/shared/ui';

const ListingAddNoteDialog = ({ close }: DialogRef) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { setMapNotesCursor } = useActions();
  const openConfirmDialog = useDialog(CSDConfirmDialog);
  const formik = useRef<FormikProps<MapNoteInput & { roadAddress: any }>>(null);
  const { t } = useTranslation('map', { keyPrefix: 'notes' });
  const [confirmResult, setConfirmResult] = useState<boolean>(false);
  const [confirmCallback, setConfirmCallback] = useState<() => void>(() => {});
  const steps = useMemo(() => [t('steps.first'), t('steps.second'), t('steps.third')], [t]);
  const { setIsReloadNoteListing } = useActions();

  const [position, setPosition] = useState<{
    latitude: number;
    longitude: number;
    address?: string;
  }>(null);

  const [activeStep, setActiveStep] = useState<number>(0);

  const isPositionLoaded = useMemo(() => Boolean(position), [position]);

  const mapNotesCursor = useAppSelector(state => state.map.mapNotesCursor);

  const completed = useMemo(() => Boolean(activeStep === steps.length - 1), [activeStep, steps]);

  const handleConfirmClose = useCallback(() => {
    const confirmDialogRef = openConfirmDialog({ question: t('form.confirmationQuestion') });
    confirmDialogRef.beforeClose.subscribe((confirmClose: boolean) => {
      setConfirmResult(confirmClose);
    });
  }, [openConfirmDialog, t]);

  const handleClose = useCallback(() => {
    if (formik.current && formik.current.dirty) {
      setConfirmCallback(() => close);
      handleConfirmClose();
    } else {
      close();
    }
  }, [close, handleConfirmClose]);

  const handleNext = useCallback(() => {
    switch (activeStep) {
      case 0:
        if (mapNotesCursor) {
          dispatch(setMapNotesCursor(false));
          setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
        break;
      case 1:
        formik.current.setValues({ ...formik.current.values, roadAddress: undefined });
        formik.current.submitForm();
        break;
    }
  }, [activeStep, dispatch, mapNotesCursor, setMapNotesCursor]);

  const handleBackAction = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    switch (activeStep) {
      case 1:
        dispatch(setMapNotesCursor(true));
        setPosition(null);
        break;
    }
  }, [activeStep, dispatch, setMapNotesCursor]);

  const handleBack = useCallback(() => {
    if (formik.current && formik.current.dirty) {
      setConfirmCallback(() => handleBackAction);
      handleConfirmClose();
    } else {
      handleBackAction();
    }
  }, [handleConfirmClose, handleBackAction]);

  const handleReset = useCallback(() => {
    setActiveStep(0);
    setPosition(null);
  }, []);

  useEffect(() => {
    if (!activeStep) {
      dispatch(setMapNotesCursor(true));
    }
    return () => {
      dispatch(setMapNotesCursor(false));
    };
  }, [activeStep, dispatch, setMapNotesCursor]);

  useEffect(() => {
    if (confirmResult) {
      confirmCallback();
    }
  }, [confirmResult, confirmCallback]);

  const [createNote] = useMutation(mapNoteMutations.create);

  const onSubmit = useCallback(
    async (v: MapNoteInput) => {
      const data = Schema.validateSync(v);

      try {
        await createNote({ variables: { data } });
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      } catch (e) {
        if (e instanceof Error) {
          showError(e.message);
        } else {
          throw e;
        }
      }
    },
    [createNote],
  );

  const getStepContent = useCallback(
    (stepIndex: number) => {
      switch (stepIndex) {
        case 0:
          return <ListingMapComponent isPositionLoaded={isPositionLoaded} position={position} setPosition={setPosition} />;
        case 1:
          return (
            <Formik
              innerRef={formik}
              initialValues={{
                ...defaultNoteValues,
                place: { ...defaultNoteValues.place, latitude: position.latitude, longitude: position.longitude },
                address: position.address,
              }}
              onSubmit={onSubmit}
              validationSchema={Schema}
            >
              <MapNoteForm />
            </Formik>
          );
        default:
          return (
            <Box className={classes.complete}>
              <Typography variant="h5">{t('successMessage')}</Typography>
            </Box>
          );
      }
    },
    [isPositionLoaded, position, classes.complete, onSubmit, t],
  );

  const handleReloadListing = () => {
    setIsReloadNoteListing(true);
    handleClose();
  };

  return (
    <>
      <Dialog open fullWidth maxWidth="xs" onClose={handleClose}>
        <DialogTitle className={classes.title}>
          <Typography variant="h6">{t('createNotes')}</Typography>
          <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
            {steps.map((label, index) => (
              <Step key={label} completed={index === activeStep - 1 || completed}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          {getStepContent(activeStep)}
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button disabled={activeStep === 0} onClick={completed ? handleReloadListing : handleBack} className={classes.backButton}>
            {completed ? t('updateListing') : t('back')}
          </Button>
          <Tooltip
            className={classes.tooltip}
            title={t('chooseLocation')}
            aria-label="position"
            disableHoverListener={isPositionLoaded}
            placement="top"
            arrow
          >
            <span>
              <Button variant="outlined" color="primary" onClick={completed ? handleReset : handleNext} disabled={!isPositionLoaded}>
                {completed ? t('createNew') : t('further')}
              </Button>
            </span>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '1rem 1.5rem 0',
    backgroundColor: '#FFFFFF',
    zIndex: 1,
  },
  stepper: {
    flexBasis: '70%',
  },
  content: {
    height: '65vh',
  },
  actions: {
    zIndex: 1,
    backgroundColor: '#FFFFFF',
    marginTop: '1.375rem',
  },
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  tooltip: {
    fontSize: '0.9375rem',
  },
  complete: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '2.5rem 0',
  },
}));

export default ListingAddNoteDialog;
