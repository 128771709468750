import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TITLE } from '@app/core/constants';

export default function useTitle(value): void {
  const { t } = useTranslation('constants');

  useEffect(() => {
    document.title = `${value} - ${t(TITLE)}`;
  }, [value, t]);
}
