import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';

type Props = {
  isDisabledSetDefault?: boolean;
  onSetDefault: Common.AnyFunction;
  isDisabledRemove?: boolean;
  onRemove: Common.AnyFunction;
};

const ButtonsProfilePanel = ({ isDisabledRemove, isDisabledSetDefault, onSetDefault, onRemove }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('common', { keyPrefix: 'profiles.buttons' });

  return (
    <div className={classes.tabPanelActions}>
      <Button disabled={isDisabledRemove} variant="outlined" color="secondary" startIcon={<DeleteIcon />} onClick={onRemove}>
        {t('delete')}
      </Button>
      <Button variant="outlined" disabled={isDisabledSetDefault} color="primary" onClick={onSetDefault}>
        {t('default')}
      </Button>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  tabPanelActions: {
    flex: '0 0 auto',
    display: 'flex',
    padding: '8px 0',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default ButtonsProfilePanel;
