import { useTranslation } from 'react-i18next';
import { TrafficChartType } from '@app/core/types/charts/traffic';
import { COLORS } from '@theme/constants';
import { prepareXAxisParameters, prepareSeriesParameters } from './chartOptionsPreparationFns';

interface Parameters {
  data: Traffic.ChartByLines;
  isViewDataLabels: boolean;
}

export default function useTrafficChartByLinesOptions({ data, isViewDataLabels }: Parameters) {
  const { t: chartsT } = useTranslation('charts');
  const { t: parametersT } = useTranslation('parameters');
  const { t: commonT } = useTranslation('common');

  return {
    legend: {
      padding: 0,
    },
    accessibility: {
      enabled: false,
    },
    chart: {
      type: TrafficChartType.Column,
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      outside: true,
      shared: true,
      distance: 32,
      backgroundColor: COLORS.white,
    },
    title: {
      text: chartsT('traffic.trafficByLines'),
      margin: 8,
      style: {
        color: 'black',
        fontSize: '20px',
        fontWeight: '700',
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        borderWidth: 0,
        borderRadius: 2,
        dataLabels: {
          enabled: isViewDataLabels,
          color: 'black',
          align: 'right',
          x: 40,
        },
      },
    },
    yAxis: {
      stackLabels: {
        enabled: true,
      },
      gridLineWidth: 0,
      labels: {
        enabled: false,
      },
      title: {
        enabled: false,
      },
    },
    xAxis: prepareXAxisParameters({ data, parametersT }),
    series: prepareSeriesParameters({ data, parametersT, commonT }),
  };
}
