import { StationsTabsType } from '@app/v2/widgets/postDialog/types';

type Params = {
  ids: number[];
  prefixTabLabel: string;
};

export default function prepareStationsTabs({ ids, prefixTabLabel }: Params): StationsTabsType[] {
  if (Array.isArray(ids) && ids.length) {
    return ids.map((id, index) => ({ id, label: `${prefixTabLabel} ${index + 1}` }));
  }

  return [];
}
