import Yup from '@app/core/validation/locale';

const messageTemplateSchema = Yup.object().shape({
  title: Yup.string().required(),
  content: Yup.object().shape({
    configuration: Yup.object().shape({
      id: Yup.number(),
      title: Yup.string(),
    }),
  }),
});

export default messageTemplateSchema;
