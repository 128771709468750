enum Pages {
  Index = 'index',
  Meteo = 'meteo',
  Traffic = 'traffic',
  Map = 'map',
  Video = 'video',
  VideoWaterLevelControl = 'videoWaterLevelControl',
  MapNotes = 'mapNotes',
  MeteoManagement = 'meteoManagement',
  TrafficManagement = 'trafficManagement',
  ScoreboardsAndSigns = 'scoreboardsAndSigns',
  MeteoReports = 'meteoReports',
  ServiceMeteoReports = 'serviceMeteoReports',
  VideoReports = 'videoReports',
  ServiceVideoReports = 'serviceVideoReports',
  VideoManagement = 'videoManagement',
  TrafficReports = 'trafficReports',
  ServiceTrafficReports = 'serviceTrafficReports',
  ScoreboardConfiguration = 'scoreboardConfiguration',
  ScoreboardDevices = 'scoreboardDevices',
  ScoreboardTemplates = 'scoreboardTemplates',
  ScoreboardSigns = 'scoreboardSigns',
  ScoreboardPlaylists = 'playlistsSigns',
  Favorites = 'favorites',
  LogAccident = 'logAccident',
  LogDuty = 'logDuty',
  LogEmergencySituationFacility = 'logEmergencySituationFacility',
  LogEmergencySituationBuilding = 'logEmergencySituationBuilding',
  LogEmergencySituation = 'logEmergencySituation',
  LogBreaks = 'logBreaks',
  LogTrafficRestriction = 'logTrafficRestriction',
  LogForcesAttracting = 'logForcesAttracting',
  LogFloodEffects = 'logFloodEffects',
  LogHazard = 'logHazard',
  GuidesOrganizations = 'guidesOrganizations',
  GuidesUsers = 'guidesUsers',
  GuidesRoads = 'guidesRoads',
  GuidesRoadParts = 'guidesRoadParts',
  GuidesRoadPassport = 'guidesRoadPassport',
  GuidesUnitsOfMeasurement = 'guidesUnitsOfMeasurement',
  GuidesMonitoringObjects = 'guidesMonitoringObjects',
  GuidesManagementObjects = 'guidesManagementObjects',
  Notifications = 'notifications',
  CityWeatherForecast = 'cityWeatherForecast',
  SOPS = 'SOPS',
  AdaptationToClimateChange = 'adaptationToClimateChange',
  LocatorAnalyze = 'locatorAnalyze',
  ECOMonitoring = 'ecoMonitoring',
  StationDetails = 'stationDetails',
  Reports = 'reports',
  AccidentNews = 'accidentNews',
  Incidents = 'incidents',
  FederalRoads = 'federalRoads',
  Dashboard = 'dashboard',
  Monitor = 'monitor',
  SituationCenter = 'situationCenter',
  RoadManagementOrgan = 'roadManagementOrgan',
}

export default Pages;
