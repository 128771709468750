import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, ButtonProps, Divider, Stack, StackProps, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Moment } from 'moment/moment';
import { RangeValue } from 'rc-picker/es/interface';
import { Variant } from '@mui/material/styles/createTypography';
import { isFunction } from '@app/v2/shared/helpers';
import { ENTER_TOOLTIP_DELAY } from '@app/v2/shared/constants';
import CSDDataRangePickerAntd from './CSDDataRangePickerAntd';
import type { BtnsSetup, RangeProps } from './types';

interface Props {
  hidden?: boolean;
  withEndDivider?: boolean;
  withStartDivider?: boolean;
  disableAllBtns?: boolean;
  btnsSetup: BtnsSetup[];
  labelVariant?: Variant;
}

const CSDDataRangePickerAntdWithBtns = ({
  btnsSetup = [],
  disableAllBtns,
  onChange,
  value,
  withStartDivider = false,
  withEndDivider = false,
  hidden = false,
  t,
  ...props
}: Props & RangeProps & I18N.TranslationFunction<'common'>) => {
  const [dates, setDates] = useState<RangeValue<Moment>>(value);

  const handleClick = (cb: () => void): void => {
    if (!isFunction(cb)) return;
    cb();
  };

  const handleSetDates = useCallback(
    (open: boolean) => {
      const [dateFrom, dateTo] = dates;
      if (!open && isFunction(onChange) && dateFrom && dateTo) {
        onChange(dates, [dateFrom.format(), dateTo.format()]);
      }
    },
    [dates, onChange],
  );

  useEffect(() => {
    setDates(value);
  }, [value]);

  return (
    <>
      {!hidden && (
        <StyledStack alignItems="flex-end" gap="1rem">
          {withStartDivider && <Divider orientation="vertical" flexItem />}

          <CSDDataRangePickerAntd
            onOpenChange={handleSetDates}
            onChange={([nextDateFrom, nextDateTo]) => setDates([nextDateFrom, nextDateTo])}
            value={dates}
            {...props}
          />

          {!!btnsSetup.length && (
            <StyledStack alignItems="center" gap="0.5rem">
              {btnsSetup.map(({ label, action, isActive, disabled, tooltipTitle }) => (
                <Tooltip key={label} title={tooltipTitle} enterDelay={ENTER_TOOLTIP_DELAY}>
                  <span>
                    <StyledBtn
                      variant={isActive ? 'contained' : 'outlined'}
                      disabled={disableAllBtns || disabled}
                      onClick={() => handleClick(action)}
                    >
                      {t(label)}
                    </StyledBtn>
                  </span>
                </Tooltip>
              ))}
            </StyledStack>
          )}

          {withEndDivider && <Divider orientation="vertical" flexItem />}
        </StyledStack>
      )}
    </>
  );
};

const StyledStack = styled(Stack)<StackProps>({
  flexDirection: 'row',
  justifyContent: 'flex-start',
});

const StyledBtn = styled(Button)<ButtonProps>({
  height: '1.75rem',
  padding: '0 0.5rem',
  minWidth: 0,
  fontSize: '0.875rem',
});

export default withTranslation('common')(CSDDataRangePickerAntdWithBtns);
