import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseDialog from '@mui/material/Dialog';
import { DialogTitle, Grid, Icon, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import icons from '@app/assets/iconFont';
import { DialogRef } from '@app/core/providers/DialogProvider';
import CircularProgress from '@app/components/common/circular-progress/CircularProgress';
import { progressSize } from '@app/v2/shared/constants';

type Props<DialogData> = {
  handleSubmit: (event) => void;
  title: string;
  loading?: boolean;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  submitText?: string;
  cancelText?: string;
  submitIcon?: React.ReactNode;
  cancelIcon?: React.ReactNode;
  children?: React.ReactElement;
  dataLoading?: boolean;
  onDelete?: (event) => void;
} & DialogRef<DialogData>;

const Dialog = <DialogData,>({
  close,
  handleSubmit,
  title,
  fullWidth = false,
  maxWidth = 'xs',
  submitText,
  cancelText,
  loading = false,
  submitIcon = <Icon>{icons.done}</Icon>,
  cancelIcon = <Icon>{icons.trash}</Icon>,
  dataLoading = false,
  children,
  onDelete,
}: Props<DialogData>) => {
  const classes = useStyles();

  const { t } = useTranslation('dialogs', { keyPrefix: 'common.buttons' });

  return (
    <BaseDialog open maxWidth={maxWidth} fullWidth={fullWidth} scroll="paper" onClose={close}>
      <DialogTitle className={classes.title}>
        <Typography variant="h6">{title}</Typography>
        <IconButton onClick={() => close()}>
          <Icon className={classes.cancel}>{icons.close}</Icon>
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={false} className={dataLoading ? classes.loading : classes.content}>
        {children}
      </DialogContent>
      <DialogActions className={classes.actionPanel}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            startIcon={loading ? <CircularProgress size={progressSize} /> : submitIcon}
            className={classes.save}
            onClick={handleSubmit}
          >
            {submitText || t('save')}
          </Button>
          <Button
            variant="outlined"
            startIcon={cancelIcon}
            onClick={event => {
              if (onDelete) {
                onDelete(event);
              } else {
                close();
              }
            }}
            className={classes.delete}
          >
            {cancelText || t('cancel')}
          </Button>
        </Grid>
      </DialogActions>
    </BaseDialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  progress: {
    height: '0.5rem',
  },
  content: {},
  actionPanel: {
    marginTop: '0.625rem !important',
  },
  loading: {
    position: 'relative',
    overflow: 'hidden',
    '&:after': {
      content: '""',
      position: 'absolute',
      display: 'flex',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#fff',
      opacity: 0.4,
      zIndex: 100,
    },
  },
  save: {
    left: '0.625rem',
  },
  delete: {
    right: '0.625rem',
  },
  cancel: {
    color: theme.palette.info.main,
    fontSize: '2rem !important',
  },
}));

export default Dialog;
