import { unionWith } from 'lodash';
import moment from 'moment';
import { MessageType, WsCommand } from '@app/v2/shared/enums';
import { CANCEL_NOTIFICATION_STATUS, NOTIFICATION_LIMIT } from '@app/core/constants/notification';
import { DATE_PERIOD } from './date';

export const RESPONSE_TIME = DATE_PERIOD.MINUTE;
export const SEND_TIME = DATE_PERIOD.MINUTE / 2;
export const SOCKET_STATUS_READY = 1;

export const WsMessages = {
  [WsCommand.PING_MESSAGE]: {
    command: WsCommand.PING_MESSAGE,
  },
  [WsCommand.GET_START_PACKET]: {
    command: WsCommand.GET_START_PACKET,
    payload: {
      filters: {
        roadIds: [],
        organizationIds: [],
        placeIds: [],
      },
    },
  },
};

export const initialWebSocketContextValue: WS.ContextValue['data'] = {
  [MessageType.Alert]: [],
  [MessageType.Bulletin]: [],
  [MessageType.Accident]: [],
};

const groupCommonCompare = ({ id }, { id: newId }) => id === newId;

export const groupRules = {
  [MessageType.Alert]: (prev: Notifications.AlertModel[], next: Notifications.AlertModel[]) =>
    unionWith(next.reverse(), prev, groupCommonCompare)
      .filter(({ status }) => status !== CANCEL_NOTIFICATION_STATUS)
      .slice(0, NOTIFICATION_LIMIT),
  [MessageType.Bulletin]: (prev: Notifications.BulletinModel[], next: Notifications.BulletinModel[]) =>
    unionWith(next.reverse(), prev, groupCommonCompare)
      .filter(({ status }) => status !== CANCEL_NOTIFICATION_STATUS)
      .slice(0, NOTIFICATION_LIMIT),
  [MessageType.Accident]: (prev: AccidentNews.Model[], next: AccidentNews.Model[]) =>
    [...unionWith(prev, next, groupCommonCompare)].sort((first, second) => (moment(first.pubDate).isBefore(moment(second.pubDate)) ? 1 : -1)),
};
