import React from 'react';
import { Box, Icon, InputAdornment, Stack } from '@mui/material';
import { CSDInput, CSDInputStandard, CSDSeparateInput } from '@app/modules/kit-module/shared/ui';
import {
  disabledInput,
  disabledMultilineInput,
  disabledStandardInput,
  input,
  inputWithEndIcon,
  inputWithError,
  inputWithStartIcon,
  multilineInput,
  multilineInputWithError,
  separateInput,
  standardInput,
  standardInputWithIcon,
} from './example';
import CSDKitDocBlock from '../../../shared/ui/CSDKitDocBlock/CSDKitDocBlock';

const CSDTextFields = () => {
  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="Input" codeExample={input}>
        <Box sx={{ width: '300px' }}>
          <CSDInput label="Вопрос" placeholder="2-я бауманская д.5" />
        </Box>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Input with error" codeExample={inputWithError}>
        <Box sx={{ width: '300px' }}>
          <CSDInput label="Вопрос" defaultValue="2-я бауманская д.5" helperText="Что-то пошло не так" error />
        </Box>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Input disabled" codeExample={disabledInput}>
        <Box sx={{ width: '300px' }}>
          <CSDInput label="Вопрос" defaultValue="2-я бауманская д.5" disabled />
        </Box>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Input with start icon" codeExample={inputWithStartIcon}>
        <Box sx={{ width: '300px' }}>
          <CSDInput
            label="Вопрос"
            defaultValue="2-я бауманская д.5"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Input with end icon" codeExample={inputWithEndIcon}>
        <Box sx={{ width: '300px' }}>
          <CSDInput
            label="Вопрос"
            defaultValue="2-я бауманская д.5"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon>search</Icon>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Input standard" codeExample={standardInput}>
        <Box sx={{ width: '300px' }}>
          <CSDInputStandard label="Вопрос" defaultValue="2-я бауманская д.5" />
        </Box>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Input standard disabled" codeExample={disabledStandardInput}>
        <Box sx={{ width: '300px' }}>
          <CSDInputStandard label="Вопрос" defaultValue="2-я бауманская д.5" disabled />
        </Box>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Input standard with icon" codeExample={standardInputWithIcon}>
        <Box sx={{ width: '300px' }}>
          <CSDInputStandard label="Вопрос" icon="search" defaultValue="2-я бауманская д.5" />
        </Box>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Input multiple" codeExample={multilineInput}>
        <Box sx={{ width: '300px' }}>
          <CSDInput label="Вопрос" multiline rows={4} defaultValue="2-я бауманская д.5" />
        </Box>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Input multiple disabled" codeExample={disabledMultilineInput}>
        <Box sx={{ width: '300px' }}>
          <CSDInput label="Вопрос" multiline rows={4} defaultValue="2-я бауманская д.5" disabled />
        </Box>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Input multiple with error" codeExample={multilineInputWithError}>
        <Box sx={{ width: '300px' }}>
          <CSDInput label="Вопрос" multiline rows={4} defaultValue="2-я бауманская д.5" error helperText="Что-то пошло не так!" />
        </Box>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="SeparateInput" codeExample={separateInput}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CSDSeparateInput />
        </Box>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="SeparateInput with error" codeExample={separateInput}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CSDSeparateInput isError errorMessage="Что-то пошло не так!" />
        </Box>
      </CSDKitDocBlock>
    </Stack>
  );
};

export default CSDTextFields;
