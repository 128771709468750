import React, { ElementType, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@mui/material';

type Props = {
  title: string;
  message: string;
  address?: string;
  roadAddress?: string;
  isAddress?: boolean;
  date?: string;
  time?: string;
  photo?: any;
  organization?: string;
  user?: string;
  addressIcon?: string;
  roadIcon?: string;
  dateIcon?: string;
  timeIcon?: string;
  messageClassName?: string;
  buttonClassName?: string;
  visibilityBox?: string;
  UpdateNoteButtonComponent?: ElementType;
  updateNoteButtonProps?: any;
  openNodeInfoDialog: Function;
};

const MessageWithReadMore = ({
  messageClassName,
  buttonClassName,
  message,
  title,
  address,
  roadAddress,
  isAddress,
  date,
  time,
  photo,
  organization,
  user,
  addressIcon,
  roadIcon,
  dateIcon,
  timeIcon,
  visibilityBox,
  UpdateNoteButtonComponent,
  updateNoteButtonProps,
  openNodeInfoDialog,
}: Props) => {
  const { t } = useTranslation('map', { keyPrefix: 'notes' });

  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
  const messageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (messageRef.current) {
        const isOverflow = messageRef.current.scrollHeight > messageRef.current.clientHeight;
        setIsOverflowing(isOverflow);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [message]);

  const handleOpenDialog = () => {
    openNodeInfoDialog({
      title,
      address,
      roadAddress,
      isAddress,
      date,
      time,
      photo,
      organization,
      user,
      addressIcon,
      roadIcon,
      dateIcon,
      timeIcon,
      visibilityBox,
      UpdateNoteButtonComponent,
      updateNoteButtonProps,
      message,
    });
  };

  return (
    <div>
      <div ref={messageRef} className={messageClassName}>
        {message}
      </div>
      <div style={{ minHeight: '1.125rem' }}>
        {isOverflowing && (
          <Link component="button" className={buttonClassName} onClick={() => handleOpenDialog()}>
            {t('explore')}
          </Link>
        )}
      </div>
    </div>
  );
};

export default MessageWithReadMore;
