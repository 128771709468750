import { useCallback } from 'react';
import { DialogTabsTypes } from '@app/v2/shared/enums';
import useDialog from './useDialog';

type Return<OpenDialogFn> = {
  openSignDialog: OpenDialogFn;
  openMeteoDialog: OpenDialogFn;
  openVideoDialog: OpenDialogFn;
  openTrafficDialog: OpenDialogFn;
  openPlaylistsDialog: OpenDialogFn;
  openWaterLevelDialog: OpenDialogFn;
  openScoreboardMessagesDialog: OpenDialogFn;
};

export default function usePostDialog(postDialogComponent): Return<Common.OpenPostDialogType> {
  const openPostDialog = useDialog<
    any,
    {
      placeId: number;
      stationId: number;
      stationType: DialogTabsTypes;
      video: number[];
    },
    any
  >(postDialogComponent);

  const openMeteoDialog = useCallback(
    (placeId: number, stationId?: number, video?: number[]) =>
      openPostDialog({ stationId, stationType: DialogTabsTypes.METEO_CONTROL, placeId, video }),
    [openPostDialog],
  );

  const openVideoDialog = useCallback(
    (placeId: number, stationId?: number, video?: number[]) => openPostDialog({ stationId, stationType: DialogTabsTypes.VIDEO, placeId, video }),
    [openPostDialog],
  );

  const openTrafficDialog = useCallback(
    (placeId: number, stationId?: number, video?: number[]) => openPostDialog({ stationId, stationType: DialogTabsTypes.TRAFFIC, placeId, video }),
    [openPostDialog],
  );

  const openScoreboardMessagesDialog = useCallback(
    (placeId: number, stationId?: number, video?: number[]) =>
      openPostDialog({ stationId, stationType: DialogTabsTypes.MESSAGE_STATIONS, placeId, video }),
    [openPostDialog],
  );

  const openSignDialog = useCallback(
    (placeId: number, stationId?: number, video?: number[]) =>
      openPostDialog({ stationId, stationType: DialogTabsTypes.SIGN_STATIONS, placeId, video }),
    [openPostDialog],
  );

  const openPlaylistsDialog = useCallback(
    (placeId: number, stationId?: number, video?: number[]) => openPostDialog({ stationId, stationType: DialogTabsTypes.PLAYLIST, placeId, video }),
    [openPostDialog],
  );

  const openWaterLevelDialog = useCallback(
    (placeId: number, stationId?: number, video?: number[]) =>
      openPostDialog({ stationId, stationType: DialogTabsTypes.WATER_LEVEL_CONTROL, placeId, video }),
    [openPostDialog],
  );

  return {
    openMeteoDialog,
    openVideoDialog,
    openTrafficDialog,
    openScoreboardMessagesDialog,
    openSignDialog,
    openPlaylistsDialog,
    openWaterLevelDialog,
  };
}
