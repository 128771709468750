import * as Yup from 'yup';
import { kilometers, meters } from '@app/modules/log-module/common/validation/validators';
import { MapNoteInput } from '@app/modules/map-module/components/map/notes/types';
import PRIVACY from '@app/core/constants/visibility';

export default Yup.object().shape<MapNoteInput & { roadAddress?: any }>({
  title: Yup.string().nullable().required(),
  id: Yup.number().nullable(),
  dateTime: Yup.string().nullable().required(),
  roadId: Yup.number().nullable().when('isAddress', {
    is: false,
    then: Yup.number().required(),
    otherwise: Yup.number().nullable(),
  }),
  message: Yup.string().nullable().required(),
  photos: Yup.array().of(Yup.string()).nullable(),
  place: Yup.object().shape({
    id: Yup.number().nullable(),
    latitude: Yup.number().nullable().required(),
    longitude: Yup.number().nullable().required(),
    kilometer: kilometers.optional().nullable(),
    meter: meters.optional().nullable(),
  }),
  visibility: Yup.string()
    .oneOf(PRIVACY.map(item => item.value))
    .required(),
  roadAddress: Yup.string().optional().nullable(),
  address: Yup.string().optional().nullable(),
  isAddress: Yup.boolean(),
});
