import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, Typography, Button, Icon } from '@mui/material';
import { logoutAllOtherSessions } from '@store/thunks/auth/auth-thunks';
import ConfirmDialog from '@app/components/dialogs/confirm-dialog/ConfirmDialog';
import LicenseDialog from '@app/components/dialogs/license-dialog/LicenseDialog';
import { USER_MANUAL_URL } from '@app/core/constants';
import icons from '@app/assets/iconFont';
import { useAppDispatch, useCheckAccess, useDialog } from '@app/v2/shared/hooks';
import { UsersAccess } from '@app/v2/shared/enums';
import PlaceReportActions from './PlaceReportActions';

type Props = {
  onSave: () => void;
} & I18N.TranslationFunction<'dialogs', 'accountSettings.tabs.profile'>;

const FormActions = ({ onSave, t }: Props) => {
  const dispatch = useAppDispatch();
  const openDialog = useDialog(LicenseDialog);
  const openConfirmDialog = useDialog(ConfirmDialog);
  const { accessChecker } = useCheckAccess();

  const closeSessions = (): void => {
    openConfirmDialog({ question: t('questions.closeQuestion') }).beforeClose.subscribe(async (answerToQuestion: boolean): Promise<void> => {
      answerToQuestion && dispatch(logoutAllOtherSessions());
    });
  };

  return (
    <>
      <Grid item xs={12} spacing={1} container justifyContent="space-between">
        <Grid item xs={12}>
          <Typography variant="h2" sx={{ marginBottom: '1rem' }}>
            {t('sections.usefulDocuments')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button variant="outlined" fullWidth onClick={openDialog}>
            {t('buttons.termsOfUse')}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant="outlined" fullWidth onClick={() => window.open(USER_MANUAL_URL)}>
            {t('buttons.userGuide')}
          </Button>
        </Grid>
        {accessChecker([UsersAccess.BUTTON_ASINOVSKIY_REPORT]) && <PlaceReportActions />}
      </Grid>
      <Grid item xs={12} spacing={1} container justifyContent="space-between" alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h2" sx={{ marginBottom: '1rem' }}>
            {t('sections.sessionManagement')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button variant="outlined" fullWidth onClick={closeSessions}>
            {t('buttons.endAllSessions')}
          </Button>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <Button fullWidth onClick={onSave} startIcon={<Icon>{icons.save}</Icon>}>
            {t('buttons.save')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default withTranslation('dialogs', { keyPrefix: 'accountSettings.tabs.profile' })(FormActions);
