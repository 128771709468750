/** Notification messages */

enum NotificationMessages {
  SUCCESS_RESEND_MESSAGE = 'successResendCode',
  SUCCESS_SAVE = 'successSave',
  SUCCESS_DATA_LOADED = 'successDateLoaded',
  ALL_SESSIONS_CLOSED = 'allSessionsClosed',
  IMPROVE_OUR_SERVICE = 'improveOurService',
}

export default NotificationMessages;
