import * as React from 'react';
import { Box, Stack, Icon, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

type AdditionalStyleProps = {
  disabled?: boolean;
};

type Props = {
  label: string;
  icon?: string;
  isActive?: boolean;
  disabled?: boolean;
  informationList?: { key: string; value: string }[];
  onClick?: () => void;
};

const SelectableTabItem = ({ label, icon, isActive = false, disabled = false, onClick = () => {}, informationList = [] }: Props) => {
  const classes = useStyles({ disabled });

  return (
    <Box className={classes.container}>
      <Box className={clsx(classes.meteoColumnItems, isActive && 'active')} onClick={onClick}>
        <Icon>{icon}</Icon>
        <span className={classes.meteoColumnItemsText}>{label}</span>
      </Box>
      {!!informationList.length && (
        <Stack className={classes.meteoInformationList}>
          {informationList.map(info => (
            <span key={info.key} className={clsx(classes.meteoInformationText, isActive && 'active')}>
              {info.value}
            </span>
          ))}
        </Stack>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    pointerEvents: ({ disabled }: AdditionalStyleProps) => (disabled ? 'none' : 'auto'),
    marginBottom: '1rem',
  },
  meteoColumnItems: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.text.t2,
    '&.active': {
      color: theme.palette.primary.main,
      '& > span:last-child': {
        color: theme.palette.text.primary,
      },
      '& .MuiSvgIcon-root': {
        // the same as ${theme.palette.primary.main} (#072F9C)
        filter: 'brightness(0) saturate(100%) invert(12%) sepia(88%) saturate(3713%) hue-rotate(226deg) brightness(91%) contrast(102%)',
      },
    },
    '& .MuiSvgIcon-root': {
      // the same as ${theme.palette.text.t2} (#747474)
      filter: 'brightness(0) saturate(100%) invert(46%) sepia(9%) saturate(0%) hue-rotate(206deg) brightness(96%) contrast(84%)',
      fontSize: '1.5rem',
    },
  },
  meteoColumnItemsText: {
    fontSize: '0.875rem',
    marginLeft: '0.625rem',
  },
  meteoInformationList: {
    width: '100%',
  },
  meteoInformationText: {
    fontSize: '0.7rem',
    marginLeft: '2.125rem',
    color: theme.palette.text.t2,
    '&.active': {
      color: theme.palette.text.t1,
    },
  },
}));

export default SelectableTabItem;
