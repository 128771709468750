import { useCallback, useMemo, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import filterMutations from '@app/clients/apollo/requests/mutations/filters';
import filterQueries from '@app/clients/apollo/requests/queries/filters';
import { FiltersKeys, FiltersTypes } from '@app/v2/shared/enums';
import useActions from '../reactRedux/useActions';
import useAppSelector from '../reactRedux/useAppSelector';

export default function useNewUserSetsManager() {
  const [loading, setLoading] = useState<boolean>(false);

  const userSets = useAppSelector(state => state.newUserSets.usersSets, shallowEqual);
  const { setUserSets } = useActions();

  const activeUserSet = useMemo(() => userSets?.find(({ isActive }) => Boolean(isActive)), [userSets]);

  const { refetch } = useQuery<{ filters: Filters.SetResponse[] }>(filterQueries.filterSets, {
    fetchPolicy: 'cache-first',
    skip: true,
  });

  const [deletePresetFilter, { loading: deleteLoading }] = useMutation<Common.MutationResponse, Filters.DeleteSetVariables>(filterMutations.delete);
  const [createPresetFilter, { loading: createLoading }] = useMutation<Common.MutationResponse, Filters.CreateSetVariables>(filterMutations.create);
  const [updatePresetFilter, { loading: updateLoading }] = useMutation<Common.MutationResponse, Filters.UpdateSetVariables>(filterMutations.update);

  const handleCreateSet = useCallback(
    (newSet: Omit<Filters.Set, 'isActive' | 'value'>): Promise<Partial<Filters.Filter>> => {
      return createPresetFilter({
        variables: {
          label: newSet.label,
          isActive: true,
          roads: newSet?.roads ?? [],
          organizations: newSet?.organizations ?? [],
          places: newSet?.places ?? [],
        },
      })
        .then(refetch)
        .then(userSetsNormalizer)
        .then(({ sets, nextFiltersValues }) => {
          setUserSets(sets);
          return nextFiltersValues;
        });
    },
    [createPresetFilter, refetch, setUserSets],
  );

  const handleUpdateSet = useCallback(
    (setId: number, nextValue: Omit<Partial<Filters.Set>, 'value'>): Promise<Filters.Filter> => {
      return updatePresetFilter({
        variables: {
          id: setId,
          label: nextValue.label,
          isActive: nextValue.isActive,
          orgIds: nextValue.organizations,
          roadIds: nextValue.roads,
          placeIds: nextValue.places,
        },
      })
        .then(refetch)
        .then(userSetsNormalizer)
        .then(({ sets, nextFiltersValues }) => {
          setUserSets(sets);
          return nextFiltersValues;
        });
    },
    [refetch, setUserSets, updatePresetFilter],
  );

  const handleDeleteSet = useCallback(
    (setId: number) => {
      return deletePresetFilter({
        variables: {
          id: setId,
        },
      })
        .then(refetch)
        .then(userSetsNormalizer)
        .then(({ sets }) => setUserSets(sets));
    },
    [deletePresetFilter, refetch, setUserSets],
  );

  const fetchUserSets = useCallback((): Promise<Filters.UserSetsData> => {
    if (userSets || loading) return new Promise<Filters.UserSetsData>(() => {});

    setLoading(true);

    return refetch()
      .then(userSetsNormalizer)
      .then(data => {
        setUserSets(data.sets);
        return data;
      })
      .finally(() => setLoading(false));
  }, [userSets, loading, refetch, setUserSets]);

  return {
    activeUserSet,
    userSets: userSets ?? [],
    fetchUserSets,
    handleCreateSet,
    handleUpdateSet,
    handleDeleteSet,
    updateSetsLoading: deleteLoading || createLoading || updateLoading,
    setsLoading: loading,
  };
}

function userSetsNormalizer({ data }: ApolloQueryResult<{ filters: Filters.SetResponse[] }>): Filters.UserSetsData {
  const flatFilterValues = ({ value }): number => Number(value);

  const nextSets: Filters.Set[] = data.filters?.map(filter => ({
    ...filter,
    roads: filter.roads.map(flatFilterValues),
    organizations: filter.organizations.map(flatFilterValues),
    places: filter.places.map(flatFilterValues),
  }));

  const nextActiveUseSet = nextSets?.find(({ isActive }) => isActive);

  return {
    sets: nextSets,
    isActive: !!nextActiveUseSet,
    nextFiltersValues: {
      [FiltersKeys.Organizations]: {
        type: FiltersTypes.Array,
        value: nextActiveUseSet?.organizations ?? [],
      },
      [FiltersKeys.Roads]: {
        type: FiltersTypes.Array,
        value: nextActiveUseSet?.roads ?? [],
      },
      [FiltersKeys.Places]: {
        type: FiltersTypes.Array,
        value: nextActiveUseSet?.places ?? [],
      },
    },
  };
}
