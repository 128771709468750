import { TFunction } from 'react-i18next';
import parameters from '@app/core/constants/parameters/parameters';

export default function prepareSeriesParameters({
  data,
  parametersT,
  commonT,
}: {
  data: Traffic.ChartByLines;
  parametersT: TFunction<'parameters'>;
  commonT: TFunction<'common'>;
}) {
  if (!data) return [];

  const getSeriesData = () => {
    return [...(data?.lines?.content?.forward ?? []), ...(data?.lines?.content?.backward ?? [])].reduce((acc, item) => {
      Object.entries(item)
        .filter(([key]) => key !== 'occupancy')
        .forEach(([key, value]) => {
          if (!acc[key]) acc[key] = [value];
          else acc[key].push(value);
        });

      return acc;
    }, {} as Record<keyof Omit<Traffic.ChartData, 'forward' | 'backward'>, number[]>);
  };

  return Object.entries(getSeriesData()).map(([key, dataValue]) => {
    const {
      label: name,
      chartOptions: { lineColor: color },
      unitsOptions: { value },
    } = parameters[key];

    return {
      data: dataValue,
      name: parametersT(name),
      tooltip: { valueSuffix: ` ${commonT(value)}` },
      color,
    };
  });
}
