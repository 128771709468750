import useProfileContext from '@app/core/context/profile/useProfileContext';

export default function useProfileColumns<T extends Table.TableData>(columns: Table.Header<T>[]): Table.Header<T>[] {
  const { activeProfile } = useProfileContext();

  const activeProfileColumns: string[] = [];

  activeProfile?.items.forEach(item => {
    item.checked && activeProfileColumns.push(item.key);
  });

  return columns.filter(column => activeProfileColumns.includes(column.field));
}
