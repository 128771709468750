export const basicChip = `<Chip label="Пулково" />
<Chip label="Калуга" variant="outlined" />`;
export const clickableChip = `<Chip label="Clickable" onClick={() => {}} />
<Chip label="Clickable" variant="outlined" onClick={() => {}} />`;
export const deletableChip = `<Chip label="Deletable" onDelete={() => {}} />
<Chip label="Deletable" variant="outlined" onDelete={() => {}} />`;
export const clickableDeletebleChip = `<Chip
  label="Clickable Deletable"
  onClick={() => {}}
  onDelete={() => {}}
/>
<Chip
  label="Clickable Deletable"
  variant="outlined"
  onClick={() => {}}
  onDelete={() => {}}
/>`;
export const clickableLinkChip = `<Chip label="Clickable Link" component="a" href="#" clickable />
<Chip
  label="Clickable Link"
  component="a"
  href="#"
  variant="outlined"
  clickable
/>`;
export const customDeleteIconChip = `<Chip
  label="Custom delete icon"
  onClick={() => {}}
  onDelete={() => {}}
  deleteIcon={<DoneIcon />}
/>
<Chip
  label="Custom delete icon"
  onClick={() => {}}
  onDelete={() => {}}
  deleteIcon={<DeleteIcon />}
  variant="outlined"
/>`;
export const avatarIconChip = `<Chip avatar={<Avatar>M</Avatar>} label="Avatar" />
<Chip icon={<FaceIcon />} label="With Icon" variant="outlined"/>`;
export const sizesChip = `<Chip label="Small" size="small" />
<Chip label="Small" size="small" variant="outlined" />`;
export const colorChip = `<Chip icon={<FaceIcon />} label="primary" color="primary" />
<Chip label="primary" color="primary" />`;
export const disabledChip = `<Chip icon={<FaceIcon />} label="primary" color="primary" />`;
