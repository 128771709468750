import React, { useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { Divider, Stack, StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import icons from '@app/assets/iconFont';
import { strictlyEqual } from '@app/v2/shared/helpers';
import { DATE_PERIOD, DATE_FORMAT } from '@app/v2/shared/constants';
import useDateWorker from '@app/core/hooks/useDateWorker';
import { useTimePeriods, useTrafficChart } from '@app/v2/shared/hooks';
import { TrafficChartDirection, TrafficChartInterval, TrafficChartType, TrafficViewType } from '@app/core/types/charts/traffic';
import { CSDButtonsBlock, CSDDataRangePickerAntdWithBtns, CSDReportsButton, CSDSwitcher } from '@app/modules/kit-module/shared/ui';
import { ExecutionType } from '@app/v2/shared/enums';

interface Props {
  close?: Common.VoidCallBack;
}

const TrafficTabSettingsPanel = ({ close, t: tCharts }: Props & I18N.TranslationFunction<'charts'>) => {
  const { now, weekAgo, yesterday } = useTimePeriods();

  const {
    trafficChartData,
    handleChangeTrafficChartDateTo,
    handleChangeTrafficChartDirection,
    handleChangeTrafficChartInterval,
    handleChangeTrafficChartType,
    handleChangeTrafficChartViewType,
    handleToggleIsViewAdditionalChart,
    handleChangeTrafficChartDates,
    handleSetOrClearInitialDates,
  } = useTrafficChart();

  useEffect(() => {
    handleSetOrClearInitialDates(ExecutionType.Set);
    return () => handleSetOrClearInitialDates(ExecutionType.Clear);
  }, [handleSetOrClearInitialDates]);

  const { addHandler, removeHandler } = useDateWorker();

  useEffect(() => {
    const handler = () => handleChangeTrafficChartDateTo({ dateTo: moment().endOf('day') });
    addHandler(handler);

    return () => removeHandler(handler);
  }, [addHandler, handleChangeTrafficChartDateTo, removeHandler]);

  const { viewTypeConfig, typeChartConfig, intervalConfig, directionConfig } = useMemo(() => {
    const { typeChart, interval, direction, dateFrom, typeView, dateTo } = trafficChartData;

    return {
      viewTypeConfig: [
        {
          label: tCharts('traffic.byClass'),
          onClick: () => handleChangeTrafficChartViewType({ typeView: TrafficViewType.BY_CLASS }),
          isActive: strictlyEqual(typeView, TrafficViewType.BY_CLASS),
        },
        {
          label: tCharts('traffic.byDirections'),
          onClick: () => handleChangeTrafficChartViewType({ typeView: TrafficViewType.BY_DIRECTIONS }),
          isActive: strictlyEqual(typeView, TrafficViewType.BY_DIRECTIONS),
        },
      ],
      typeChartConfig: [
        {
          icon: icons.chart,
          onClick: () => handleChangeTrafficChartType({ typeChart: TrafficChartType.Column }),
          isActive: strictlyEqual(typeChart, TrafficChartType.Column),
          tooltip: tCharts('traffic.diagram'),
        },
        {
          icon: icons.lineGraph,
          onClick: () => handleChangeTrafficChartType({ typeChart: TrafficChartType.Spline }),
          isActive: strictlyEqual(typeChart, TrafficChartType.Spline),
          tooltip: tCharts('traffic.lineChart'),
        },
      ],
      intervalConfig: [
        {
          label: tCharts('traffic.hour'),
          onClick: () => handleChangeTrafficChartInterval({ interval: TrafficChartInterval.Hour }),
          isActive: strictlyEqual(interval, TrafficChartInterval.Hour),
          disabled: dateTo?.diff(dateFrom) - DATE_PERIOD.HOUR < 0,
        },
        {
          label: tCharts('traffic.day'),
          onClick: () => handleChangeTrafficChartInterval({ interval: TrafficChartInterval.Day }),
          isActive: strictlyEqual(interval, TrafficChartInterval.Day),
          disabled: dateTo?.diff(dateFrom) - DATE_PERIOD.DAY < 0,
        },
        {
          label: tCharts('traffic.week'),
          onClick: () => handleChangeTrafficChartInterval({ interval: TrafficChartInterval.Week }),
          isActive: strictlyEqual(interval, TrafficChartInterval.Week),
          disabled: dateTo?.diff(dateFrom) - DATE_PERIOD.WEEK < 0,
        },
        {
          label: tCharts('traffic.month'),
          onClick: () => handleChangeTrafficChartInterval({ interval: TrafficChartInterval.Month }),
          isActive: strictlyEqual(interval, TrafficChartInterval.Month),
          disabled: dateTo?.diff(dateFrom) - DATE_PERIOD.MONTH < 0,
        },
      ],
      directionConfig: [
        {
          icon: icons.both,
          onClick: () => handleChangeTrafficChartDirection({ direction: TrafficChartDirection.Both }),
          isActive: strictlyEqual(direction, TrafficChartDirection.Both),
          tooltip: tCharts('traffic.twice'),
        },
        {
          icon: icons.straight,
          onClick: () => handleChangeTrafficChartDirection({ direction: TrafficChartDirection.Forward }),
          isActive: strictlyEqual(direction, TrafficChartDirection.Forward),
          tooltip: tCharts('traffic.forward'),
        },
        {
          icon: icons.reverse,
          onClick: () => handleChangeTrafficChartDirection({ direction: TrafficChartDirection.Backward }),
          isActive: strictlyEqual(direction, TrafficChartDirection.Backward),
          tooltip: tCharts('traffic.backward'),
        },
      ],
    };
  }, [
    trafficChartData,
    handleChangeTrafficChartDirection,
    handleChangeTrafficChartInterval,
    handleChangeTrafficChartType,
    handleChangeTrafficChartViewType,
    tCharts,
  ]);

  return (
    <StyledWrapper direction="row" position="relative">
      <Stack direction="row">
        <CSDDataRangePickerAntdWithBtns
          label={tCharts('date')}
          value={[trafficChartData?.dateFrom, trafficChartData?.dateTo]}
          disabledDate={current => current && moment().endOf('day').isBefore(current)}
          onChange={([nextDateFrom, nextDateTo]) => handleChangeTrafficChartDates([nextDateFrom.startOf('day'), nextDateTo.endOf('day')])}
          btnsSetup={[
            {
              label: 'dates.day',
              action: () => handleChangeTrafficChartDates([yesterday, now]),
              isActive:
                strictlyEqual(trafficChartData.dateFrom?.format(DATE_FORMAT.FORMAT_EN), yesterday.format(DATE_FORMAT.FORMAT_EN)) &&
                strictlyEqual(trafficChartData.dateTo?.format(DATE_FORMAT.FORMAT_EN), now.format(DATE_FORMAT.FORMAT_EN)),
            },
            {
              label: 'dates.week',
              action: () => handleChangeTrafficChartDates([weekAgo, now]),
              isActive:
                strictlyEqual(trafficChartData.dateFrom?.format(DATE_FORMAT.FORMAT_EN), weekAgo.format(DATE_FORMAT.FORMAT_EN)) &&
                strictlyEqual(trafficChartData.dateTo?.format(DATE_FORMAT.FORMAT_EN), now.format(DATE_FORMAT.FORMAT_EN)),
            },
          ]}
        />

        <Divider orientation="vertical" flexItem />

        <CSDButtonsBlock title={tCharts('traffic.displayType')} btnsConfig={viewTypeConfig} />

        <Divider orientation="vertical" flexItem />

        <CSDButtonsBlock title={tCharts('traffic.chartType')} btnsConfig={typeChartConfig} />

        <Divider orientation="vertical" flexItem />

        <CSDButtonsBlock title={tCharts('traffic.interval')} btnsConfig={intervalConfig} />

        <Divider orientation="vertical" flexItem />

        <CSDButtonsBlock title={tCharts('traffic.trafficDirection')} btnsConfig={directionConfig} />

        <Divider orientation="vertical" flexItem />

        <CSDSwitcher
          label={tCharts('traffic.lines')}
          size="small"
          checked={trafficChartData.isViewAdditionalChart}
          onChange={(_, value) => handleToggleIsViewAdditionalChart({ isViewAdditionalChart: value })}
        />
      </Stack>

      <CSDReportsButton additionalClickAction={close} />
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Stack)<StackProps>({
  width: '100%',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  padding: '1rem 1.5rem',
  boxShadow: '0px 5px 5px 0px rgba(52, 52, 52, 0.20)',
  zIndex: 1,

  '& > div': {
    alignItems: 'flex-end',
    justifyContent: 'flex-start',

    '& > hr': {
      margin: '0 1rem',
    },

    '& > label': {
      marginBottom: '0.4rem',
    },
  },
});

export default withTranslation('charts')(TrafficTabSettingsPanel);
