/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReducersNames } from '@store/constants';
import { AllFilters } from '@app/v2/shared/constants';
import { FiltersKeys } from '@app/v2/shared/enums';

interface State {
  filters: Filters.Filter;
  isEnabled: boolean;
}

const initialState: State = {
  filters: {},
  isEnabled: false,
};

const newFiltersSlice = createSlice({
  name: ReducersNames.newFilters,
  initialState,
  reducers: {
    addFilter(state: State, { payload }: PayloadAction<Filters.Filter>) {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    addFilters(state, { payload }: PayloadAction<Filters.Filter>) {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    setFilter<Key extends FiltersKeys>(
      state: State,
      { payload: { key, payload } }: PayloadAction<{ key: Key; payload: Partial<Filters.FiltersValues[Key]> }>,
    ) {
      state.filters[key] = {
        ...state.filters[key],
        ...payload,
      };
    },
    setFilters(state: State, { payload }: PayloadAction<Filters.Filter>) {
      Object.entries(payload).forEach(([key, value]) => {
        state.filters[key] = {
          ...state.filters[key],
          ...value,
        };
      });
    },
    resetFilter<Key extends FiltersKeys>(state: State, { payload: { key } }: PayloadAction<{ key: Key }>) {
      state.filters[key] = AllFilters[key];
    },
    resetFilters(state: State) {
      state.filters = Object.keys(state.filters).reduce<Filters.Filter>((acc, filterName) => {
        acc[filterName] = AllFilters[filterName];
        return acc;
      }, {});
    },
    toggleFiltersEnabledState(state: State, { payload }: PayloadAction<boolean>) {
      state.isEnabled = payload;
    },
    removeFilter(state: State, { payload: { key } }: PayloadAction<{ key: FiltersKeys }>) {
      delete state.filters[key];
    },
    removeFilters(state: State, { payload }: PayloadAction<FiltersKeys[]>) {
      payload.forEach(key => {
        delete state.filters[key];
      });
    },
  },
});

export const newFilterActions = newFiltersSlice.actions;

export default newFiltersSlice.reducer;
