import React from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, IconButton, Tooltip } from '@mui/material';
import { useMutation } from '@apollo/client';
import icons from '@app/assets/iconFont';
import accidentNewsMutations from '@app/clients/apollo/requests/mutations/accidentNews';
import { addFeatureActions } from '@app/v2/shared/utils';
import { useDialog } from '@app/v2/shared/hooks';
import { CSDConfirmDialog } from '@app/modules/kit-module/shared/ui';

interface Props extends Common.FeaturesActions, I18N.TranslationFunction<'accidentNews'> {
  newsId: number;
}

const CSDHide = ({ newsId, t, ...featuresActions }: Props) => {
  const openConfirmDialog = useDialog(CSDConfirmDialog);

  const [onHide, { loading }] = useMutation<Common.MutationResponse, AccidentNews.UpdateFavoritesStatusVariables>(accidentNewsMutations.hide);

  const handleHide = () => {
    openConfirmDialog({ question: t('hide.confirmation.question') }).beforeClose.subscribe(async (answerToQuestion: boolean) => {
      if (!answerToQuestion) return;

      addFeatureActions(
        onHide({
          variables: {
            newsId,
          },
        }),
        featuresActions,
      );
    });
  };

  return (
    <Tooltip title={t('hide.btn.tooltip')}>
      <IconButton onClick={handleHide} disabled={loading}>
        <Icon color="error">{icons.noeye}</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default withTranslation('accidentNews')(CSDHide);
