import React, { useMemo } from 'react';
import { KeyPrefix, useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import groupBy from 'lodash/groupBy';
import parameters from '@app/core/constants/parameters/parameters';
import { LinearChartData } from '@app/core/types/charts/linear';
import arrowBase64 from '@app/assets/arrowBase64';
import getHumanizedAddress from '@app/core/helpers/getHumanizedAddress';
import { PointType } from '@app/core/types/charts/PointType';
import { CHART_EXPORT_HEIGHT, CHART_EXPORT_WIDTH, CHART_TYPES, menuItemDefinitions } from '@app/core/constants/chart';
import exportChartFilename from '@app/core/helpers/exportChartFilename';
import NoChartData from '@app/components/charts/common/components/NoChartData';
import preparedMenuItemDefinitions from '@app/components/charts/common/helpers/preparedMenuItemDefinitions';
import { turnCircleInOppositeDirection } from '@app/core/constants/windDirections';
import { isNotNullAndUndefined } from '@app/v2/shared/helpers';

const keys = ['tAir', 'tRoad', 'tUnderroad', 'humidity', 'tFreezingPoint', 'precipitationIntensity', 'windGusts', 'windSpeed', 'salineConcentration'];

type Props = {
  data: LinearChartData;
  profile: Profiles.CommonProfile;
};

const LinearChart = (props: Props) => {
  const {
    data: { content, places, titleFull },
    profile,
  } = props;

  const { t } = useTranslation('parameters');
  const { t: chartsT } = useTranslation('charts');
  const { t: commonT } = useTranslation('common');

  const itemsProfile = useMemo<{ [key: string]: boolean }>(() => {
    return Object.fromEntries(profile ? profile.items.map(({ checked, key }) => [key, checked]) : keys.map(key => [key, true]));
  }, [profile]);

  /**
   * Группировка метео-параметров по единицам измерения.
   * */

  const axisGroups = useMemo(() => {
    const params = keys.map(key => parameters[key]);
    return groupBy(params, ({ unitsOptions: { key } }) => key);
  }, []);

  /**
   * Функция формирования данных для оси Y
   * params - нет
   * return - массив объектов формата yAxis (формат описан в API Highcharts)
   * */

  const chartYAxis = useMemo(() => {
    return Object.keys(axisGroups).map((groupKey, index) => {
      const {
        unitsOptions: { value, label },
        chartOptions: { axisColor, tickPositions },
      } = axisGroups[groupKey][0];

      return {
        height: '90%',
        labels: {
          format: `{value} ${commonT(value)}`,
          style: {
            color: axisColor,
          },
        },
        title: {
          text: !tickPositions ? null : t(label),
          style: {
            color: axisColor,
          },
        },
        opposite: index % 2 === 0,
        tickPositions,
        id: groupKey,
      };
    });
  }, [axisGroups, commonT, t]);

  /**
   * Формирование данных для оси Y параметра windDirGr
   * params - нет
   * return - массив c объектом формата yAxis (формат описан в API Highcharts)
   * */

  const windDirGrYAxis = useMemo(() => {
    return [
      {
        height: '6%',
        top: '92%',
        title: false,
        max: 1,
        id: 'windDirGr',
        visible: false,
      },
    ];
  }, []);

  /**
   * Формирование данных для параметра windDirGr
   * params - нет
   * return - массив с объектом формата serie (формат описан в API Highcharts)
   * */

  const windDirGrSerie = useMemo(() => {
    if (!content) return [];

    const {
      label,
      unitsOptions: { key, value },
      chartOptions: { type, visible, lineColor },
    } = parameters.windDirGr;

    return [
      {
        name: t(label),
        type,
        yAxis: key,
        visible,
        data: content.windDirGr.map((val, index) => {
          const isShowMarker = isNotNullAndUndefined(val) && !!content.windSpeed[index];

          return {
            y: 1,
            marker: {
              symbol: isShowMarker && `url(${arrowBase64(turnCircleInOppositeDirection(val))})`,
            },
            tooltipConfig: {
              value: val,
              units: commonT(value),
            },
          };
        }),
        tooltip: {
          pointFormatter(this: PointType) {
            const {
              tooltipConfig: { value: valueTooltip, units },
              series: { name },
            } = this;

            if (!isNotNullAndUndefined(valueTooltip)) return null;

            return `<span>●</span> ${t(name as KeyPrefix<'parameters'>)}: <b>${valueTooltip}${units}</b><br/>`;
          },
        },
        color: lineColor,
      },
    ];
  }, [t, content, commonT]);

  /**
   * Функция формирования данных, отрисовываемых на графике (series)
   * params - нет
   * return - массив объектов формата series (формат описан в API Highcharts)
   * */

  const createChartSeries = useMemo(() => {
    if (content) {
      return keys.map(key => ({
        name: t(parameters[key].label),
        data: content[key],
        yAxis: parameters[key].unitsOptions.key,
        visible: itemsProfile[key],
        type: parameters[key].chartOptions.type,
        color: parameters[key].chartOptions.lineColor,
        tooltip: {
          valueSuffix: ` ${commonT(parameters[key].unitsOptions.value)}`,
        },
      }));
    }

    return [];
  }, [commonT, content, itemsProfile, t]);

  /**
   * Функция формирования данных для оси Х
   * params - нет
   * return - массив адресов станций формата categories (формат описан в API Highcharts)
   * */

  const xAxisCategories = useMemo(() => {
    if (places && places.length) {
      return places.map(({ address, meters }) => getHumanizedAddress(commonT('units.kilometers'), address, meters));
    }

    return [];
  }, [commonT, places]);

  /**
   * Объект options - конфигурация графика
   * */

  const options = useMemo(
    () => ({
      exporting: {
        menuItemDefinitions: preparedMenuItemDefinitions({ data: menuItemDefinitions, t: chartsT }),
        buttons: {
          contextButton: {
            menuItems: ['viewFullscreen', 'separator', 'printChart', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
          },
        },
        filename: exportChartFilename(chartsT(CHART_TYPES.LINEAR), titleFull),
        sourceWidth: CHART_EXPORT_WIDTH,
        sourceHeight: CHART_EXPORT_HEIGHT,
        scale: 1,
      },
      chart: {
        zoomType: 'x',
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      yAxis: [...chartYAxis, ...windDirGrYAxis],
      xAxis: {
        categories: xAxisCategories,
      },
      tooltip: {
        shared: true,
        distance: 32,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      series: [...createChartSeries, ...windDirGrSerie],
    }),
    [chartsT, createChartSeries, windDirGrSerie, xAxisCategories, chartYAxis, windDirGrYAxis, titleFull],
  );

  return (
    <>
      {content && places.length ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          containerProps={{
            style: {
              height: 'calc(100% - 64px)',
            },
          }}
        />
      ) : (
        <NoChartData />
      )}
    </>
  );
};

export default LinearChart;
