import React, { ReactNode } from 'react';
import moment from 'moment';
import { Icon } from '@mui/material';
import { isNumber, isString } from '@app/v2/shared/helpers/valueCheckTypes';
import icons from '@app/assets/iconFont';

export function formatToDegrees(value: string | number): ReactNode {
  if (isString(value) || isNumber(value)) {
    return `${String(value)}°`;
  }

  return <Icon>{icons.noData}</Icon>;
}

export function formatToPercent(value: string | number): ReactNode {
  if (isString(value) || isNumber(value)) {
    return `${String(value)}%`;
  }

  return <Icon>{icons.noData}</Icon>;
}

export function formatToHours(value: number): ReactNode {
  if (isNumber(value)) {
    const duration = moment.duration(value * 1000).asHours();

    return duration > 0 ? duration.toFixed(1) : 0;
  }

  return <Icon>{icons.noData}</Icon>;
}
