import React from 'react';
import { Box } from '@mui/material';
import { CSDTableSkeleton } from '@app/v2/shared/ui/skeletons';

const CSDLoadingListing = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <CSDTableSkeleton />
    </Box>
  );
};

export default CSDLoadingListing;
