import { useCallback, useEffect } from 'react';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { LISTING_DEFAULT_PAGE_LIMIT, LISTING_DEFAULT_PAGE_NUMBER } from '@app/core/constants';
import useActions from '@app/core/hooks/customReduxHooks/useActions';

export default function useURLPaginationParams() {
  const { setQueryPaginationParams } = useActions();

  const setFilters = useCallback((pagination: { page: number; limit: number }) => setQueryPaginationParams(pagination), [setQueryPaginationParams]);
  const [queryFilter] = useQueryParams({
    page: withDefault(NumberParam, LISTING_DEFAULT_PAGE_NUMBER),
    limit: withDefault(NumberParam, LISTING_DEFAULT_PAGE_LIMIT),
  });

  useEffect(() => {
    const { page, limit } = queryFilter;

    setFilters({ page, limit });

    return () => {
      setFilters({ page: 1, limit: LISTING_DEFAULT_PAGE_LIMIT });
    };
  }, [queryFilter, setFilters]);
}
