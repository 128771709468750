import CREATE_FILTER from './create.graphql';
import DELETE_FILTER from './delete.graphql';
import UPDATE_FILTER from './update.graphql';

const filterMutations = {
  create: CREATE_FILTER,
  delete: DELETE_FILTER,
  update: UPDATE_FILTER,
};

export default filterMutations;
