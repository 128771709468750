import React, { memo, ReactNode, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Button, Chip, Icon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import { compareCommonFilters, strictlyEqual } from '@app/v2/shared/helpers';
import { addFeatureActions } from '@app/v2/shared/utils';
import { useNewFilters, useNewUserSetsManager, useNewUserSetsViewManager, useDialog } from '@app/v2/shared/hooks';
import icons from '@app/assets/iconFont';
import { DataTestIds, FiltersKeys, PresetsBtnsView, ViewMode } from '@app/v2/shared/enums';
import { EMPTY_STRING } from '@app/v2/shared/constants';
import { useFilterContext } from '@app/v2/shared/contexts';
import { CSDConfirmDialog, CSDHorizontalSlick } from '@app/modules/kit-module/shared/ui';
import CSDPresetFiltersBtn from './CSDPresetFiltersBtn';
import CSDUserSetTitle from './CSDUserSetTitle';
import CSDPresetActions from './CSDPresetActions';
import CSDPresetCreateActions from './CSDPresetCreateActions';
import userPresetValidationSchema from '../data/validationSchema';

interface Props<Action> extends I18N.TranslationFunction<'filters'> {
  featureActions: Partial<Record<'create' | 'update' | 'delete', Action>>;
  withActions: boolean;
  withSorting: boolean;
  swipe: PresetsBtnsView;
}

const CSDPresetFilters = ({
  withActions = false,
  withSorting = false,
  swipe = PresetsBtnsView.Default,
  t,
  featureActions,
}: Partial<Props<Common.FeaturesActions>>) => {
  const classes = useStyles();

  const openConfirmDialog = useDialog(CSDConfirmDialog);

  const [loadingUserSet, setLoadingUserSet] = useState<number>(null);
  const [presets, setPresets] = useState<Filters.Set[]>([]);

  const { filters } = useNewFilters();
  const { filtersValues, setFilters } = useFilterContext();
  const { userSets, activeUserSet, handleUpdateSet, handleDeleteSet, handleCreateSet } = useNewUserSetsManager();
  const { mode, isCreateMode, setUserSetMode } = useNewUserSetsViewManager();

  const roadsAndOrgsAndPlacesFiltersValues = {
    roads: filtersValues?.roads ?? [],
    organizations: filtersValues?.organizations ?? [],
    places: filtersValues?.places ?? [],
  };

  useEffect(() => {
    if (userSets.length) {
      setPresets(
        withSorting && activeUserSet ? [activeUserSet, ...userSets.filter(({ value }) => !strictlyEqual(value, activeUserSet.value))] : userSets,
      );
    }
  }, [withSorting, activeUserSet, userSets]);

  const formik = useFormik<Pick<Filters.Set, 'label'>>({
    onSubmit: null,
    initialValues: { label: activeUserSet?.label ?? EMPTY_STRING },
    validationSchema: userPresetValidationSchema(userSets),
  });

  const withConfirm = (cb: Common.AnyFunction, question: string = null) => {
    openConfirmDialog({ question: question ?? t('questions.leaveSaveMode') }).beforeClose.subscribe(answer => {
      if (!answer) return;
      cb();
    });
  };

  const handleUpdateUserSet = () => {
    addFeatureActions(
      handleUpdateSet(activeUserSet.value, {
        ...activeUserSet,
        ...roadsAndOrgsAndPlacesFiltersValues,
        label: formik.values.label,
      }),
      featureActions?.update ?? {},
    );
  };

  const handleUpdateUserSetStatus = (userSet: Filters.Set) => {
    addFeatureActions(
      handleUpdateSet(userSet.value, {
        ...userSet,
        isActive: !userSet.isActive,
      }).then(nextFilterValue => {
        formik.setFieldValue('label', !userSet.isActive ? userSet.label : EMPTY_STRING);
        setLoadingUserSet(null);
        return nextFilterValue;
      }),
      featureActions?.update ?? {},
    );
  };

  const handleResetActiveUserSet = () => {
    if (!activeUserSet) return;

    addFeatureActions(
      handleUpdateSet(activeUserSet.value, {
        ...activeUserSet,
        isActive: false,
      }),
      featureActions?.update ?? {},
    );
  };

  const handleDeleteActiveUserSet = () => {
    addFeatureActions(handleDeleteSet(activeUserSet.value), featureActions?.delete ?? {});
  };

  const handleCreateUserSet = () => {
    addFeatureActions(
      handleCreateSet({
        ...activeUserSet,
        ...roadsAndOrgsAndPlacesFiltersValues,
        label: formik.values.label,
      }).then(nextFilterValue => {
        setUserSetMode(ViewMode.Default);
        return nextFilterValue;
      }),
      featureActions?.create ?? {},
    );
  };

  const { isActiveAndLocalIsEqual, isLabelsEqual } = useMemo(
    () => ({
      isActiveAndLocalIsEqual: compareCommonFilters(filtersValues, activeUserSet),
      isLabelsEqual: strictlyEqual(activeUserSet?.label, formik.values.label),
    }),
    [activeUserSet, filtersValues, formik.values.label],
  );

  const Actions: Record<ViewMode, ReactNode> = {
    [ViewMode.Create]: (
      <CSDPresetCreateActions
        hidden={withActions}
        onCansel={() => {
          withConfirm(() => {
            setUserSetMode(ViewMode.Default);
            formik.resetForm({ values: { label: activeUserSet?.label ?? EMPTY_STRING } });
            setFilters({
              [FiltersKeys.Roads]: filters?.roads,
              [FiltersKeys.Organizations]: filters?.organizations,
              [FiltersKeys.Places]: filters?.places,
            });
          });
        }}
        onSave={() => {
          formik.setTouched({ label: true });
          if (formik.errors.label) return;
          handleCreateUserSet();
        }}
      />
    ),
    [ViewMode.Default]: (
      <CSDPresetActions
        hidden={activeUserSet && withActions}
        disabledReset={isActiveAndLocalIsEqual && isLabelsEqual}
        onReset={() => {
          formik.resetForm({ values: { label: activeUserSet?.label ?? EMPTY_STRING } });
          setFilters({
            [FiltersKeys.Roads]: filters?.roads,
            [FiltersKeys.Organizations]: filters?.organizations,
            [FiltersKeys.Places]: filters?.places,
          });
        }}
        disabledRemove={!activeUserSet}
        disabledSave={!!formik.errors.label || (isActiveAndLocalIsEqual && isLabelsEqual)}
        onRemove={handleDeleteActiveUserSet}
        onSaveChanges={handleUpdateUserSet}
      />
    ),
  };

  const AddNewUserSetBtn: Record<ViewMode, ReactNode> = {
    [ViewMode.Create]: <Button>{formik.values.label || t('set.new')}</Button>,
    [ViewMode.Default]: (
      <Button
        className="addBtn"
        variant="outlined"
        onClick={() => {
          formik.setFieldValue('label', EMPTY_STRING);
          setUserSetMode(ViewMode.Create);
        }}
      >
        <Icon>{icons.plus}</Icon>
      </Button>
    ),
  };

  const PresetsBtns: Record<PresetsBtnsView, ReactNode> = {
    [PresetsBtnsView.Default]: (
      <>
        {presets.map(userSet => (
          <CSDPresetFiltersBtn
            key={userSet.value}
            userSet={userSet}
            setLoadingUserSet={setLoadingUserSet}
            isActiveAndLocalIsEqual={isActiveAndLocalIsEqual}
            handleUpdateUserSetStatus={handleUpdateUserSetStatus}
            isLoading={strictlyEqual(userSet.value, loadingUserSet)}
          />
        ))}
      </>
    ),
    [PresetsBtnsView.Swiper]: (
      <CSDHorizontalSlick>
        {presets.map(userSet => (
          <CSDPresetFiltersBtn
            key={userSet.value}
            userSet={userSet}
            setLoadingUserSet={setLoadingUserSet}
            isActiveAndLocalIsEqual={isActiveAndLocalIsEqual}
            handleUpdateUserSetStatus={handleUpdateUserSetStatus}
            isLoading={strictlyEqual(userSet.value, loadingUserSet)}
          />
        ))}
      </CSDHorizontalSlick>
    ),
  };

  return (
    <Box className={classes.wrapper}>
      <Box className="presetListWithActions">
        <Box className="presetList">
          {!presets.length && <Chip variant="outlined" label={t('set.noSavedFilters')} />}

          {withActions && !!presets.length && (
            <Button
              sx={{ minWidth: '15rem' }}
              onClick={handleResetActiveUserSet}
              data-testid={DataTestIds.FiltersWithoutSavedBtn}
              variant={activeUserSet ? 'outlined' : 'contained'}
            >
              {t('set.actions.withoutPresets')}
            </Button>
          )}

          {!!presets.length && PresetsBtns[swipe]}

          {withActions && AddNewUserSetBtn[mode]}
        </Box>

        <Box className="actionsBtns">{Actions[mode]}</Box>
      </Box>

      {withActions && (activeUserSet || isCreateMode) && <CSDUserSetTitle formikValues={formik} />}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: '1rem',

    '& .addBtn': {
      minWidth: '2.75rem',
    },

    '& .presetListWithActions': {
      gap: '0.5rem',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },

    '& .presetList': {
      gap: '0.5rem',
      display: 'flex',
      flexWrap: 'wrap',
    },

    '& .presetActions': {
      gap: '0.5rem',
      display: 'flex',
    },

    '& .userSetTitle': {
      minWidth: '24rem',
    },
  },
}));

export default withTranslation('filters')(memo(CSDPresetFilters));
