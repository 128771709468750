import React, { ChangeEvent, useState, useMemo, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { Box, Button, Divider, Typography, IconButton, Icon, InputAdornment, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { boxShadowLight } from '@theme/styles';
import { alertTypeValue } from '@app/core/constants/notification';
import preparedSelectValues from '@app/core/helpers/preparedSelectValues';
import { CSDInput } from '@app/modules/kit-module/shared/ui';
import { MainPaths } from '@app/v2/shared/enums';
import icons from '@app/assets/iconFont';
import { DEBOUNCE_INTERVAL } from '@app/store/constants';
import useSearchQuery from '@app/core/hooks/useSearchQuery';
import type { FiltersValue } from '../types';

type Props = {
  setFiltersValue: (p: (prev: FiltersValue) => FiltersValue) => void;
  filtersValue: { alertType: string; placeValue: string };
  onClose?: () => void;
  onSettings: () => void;
};

const CSDNotificationsHeaderWithFilters = ({ filtersValue: { placeValue, alertType }, setFiltersValue, onSettings, onClose }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('notification');
  const { t: commonT } = useTranslation('common', { keyPrefix: 'buttons' });
  const [search, setSearch] = useState<string>(placeValue);
  const searchQuery = useSearchQuery();

  const placeValueHandler = useMemo(
    () =>
      debounce((value: string): void => {
        setFiltersValue(prev => ({ ...prev, placeValue: value }));
      }, DEBOUNCE_INTERVAL),
    [setFiltersValue],
  );

  const handleChangeSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setSearch(value);
      placeValueHandler(value);
    },
    [placeValueHandler],
  );

  const clearSearch = useCallback(() => {
    setFiltersValue(prev => ({ ...prev, placeValue: '' }));
    setSearch('');
  }, [setFiltersValue]);

  return (
    <Box component="div" className={classes.notificationFiltersWrapper}>
      <Box className={classes.notificationHeader}>
        <Typography variant="h2">{t('modalTitle')}</Typography>
        {onClose && (
          <IconButton onClick={onClose}>
            <Icon>{icons.close}</Icon>
          </IconButton>
        )}
      </Box>
      <Box sx={{ marginBottom: '1.5rem' }}>
        <Typography variant="h4">{t('filter.eventAddress')}</Typography>
        <Box className={classes.notificationTypes} sx={{ justifyContent: 'space-between' }}>
          <CSDInput
            className={classes.eventAddressInput}
            autoComplete="off"
            placeholder={'М-10 "Россия"'}
            value={search}
            onChange={handleChangeSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>{icons.search}</Icon>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={clearSearch}>
                    <Icon>{icons.close}</Icon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Divider orientation="vertical" flexItem />
          <Button className={classes.navNotificationButton} variant="outlined" startIcon={<Icon>{icons.gear}</Icon>} onClick={onSettings}>
            {commonT('settings')}
          </Button>
          <NavLink to={{ pathname: MainPaths.Notifications, search: searchQuery }}>
            <Button className={classes.navNotificationButton} variant="outlined" startIcon={<Icon>{icons.archive}</Icon>} onClick={onClose}>
              {commonT('warningArchive')}
            </Button>
          </NavLink>
        </Box>
      </Box>
      <Box>
        <Typography variant="h4">{t('filter.notificationType')}</Typography>
        <Box className={classes.notificationTypes}>
          {preparedSelectValues({ values: alertTypeValue, t }).map(({ label, value, color }) => (
            <Button
              key={label}
              className={classes.notificationTypeButton}
              variant={alertType === value ? 'contained' : 'outlined'}
              startIcon={<Icon sx={{ color: `${color} !important` }}>{icons.dot}</Icon>}
              onClick={() => setFiltersValue(prev => ({ ...prev, alertType: prev.alertType !== value ? value : '' }))}
            >
              {label}
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  notificationFiltersWrapper: {
    padding: '1.5rem 1.5rem 1rem 1.5rem',
    zIndex: 999,
    backgroundColor: '#fff',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: boxShadowLight,
  },
  notificationHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.875rem',
  },
  notificationTypes: {
    display: 'flex',
    marginTop: '0.5rem',
  },
  notificationTypeButton: {
    '&.MuiButton-root': {
      minWidth: 'auto',
      padding: '0.5rem',
      fontSize: '0.875rem',
      height: '2rem',
      marginRight: '0.5rem',
    },
    '& .MuiButton-startIcon': {
      marginRight: '0.25rem',
    },
  },
  navNotificationButton: {
    '&.MuiButton-root': {
      minWidth: 'auto',
      padding: '0.5rem',
    },
  },
  eventAddressInput: {
    '& .MuiInputBase-input': {
      height: '1.5rem',
      width: '13.5rem',
    },

    '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd .MuiIcon-root': {
      color: theme.palette.text.t1,
    },
  },
}));

export default CSDNotificationsHeaderWithFilters;
