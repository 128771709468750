import moment from 'moment';
import { isString } from '@app/v2/shared/helpers';
import { TrafficChartType } from '@app/core/types/charts/traffic';

export default function prepareXAxisParameters({ dates, type }: { dates: string[]; type: TrafficChartType }) {
  const xAxisCategories = (formatOfTime: string) => {
    if (!Array.isArray(dates)) return [];
    return dates.map(date => moment(date).parseZone().format(formatOfTime));
  };

  const xAxisParametersByType: Record<TrafficChartType, () => Record<string, any>> = {
    [TrafficChartType.Column]: () => {
      const MAX_DATES_FOR_STYLED = 45;

      return {
        tickWidth: 2,
        lineWidth: 10,
        tickmarkPlacement: 'on',
        tickColor: 'black',
        categories: xAxisCategories('DD.MM HH:mm'),
        labels: {
          useHTML: true,
          formatter(formatterObject) {
            if (!formatterObject?.value || !isString(formatterObject?.value)) return null;

            const [firstPart, secondPart] = formatterObject.value.split(' ');

            const content =
              dates.length > MAX_DATES_FOR_STYLED
                ? `
                   <span style="color: black; font-size: 12px; font-weight: 400">${firstPart} ${secondPart}</span>
                  `
                : ` 
                   <span style="color: black; font-size: 14px; font-weight: 700">${firstPart}</span>
                   <span style="color: black; font-size: 12px; font-weight: 400">${secondPart}</span>
                  `;

            return `<div style="display: flex; flex-direction: column; align-items: center">${content}</div>`;
          },
        },
      };
    },
    [TrafficChartType.Spline]: () => {
      return [
        {
          type: 'datetime',
          categories: xAxisCategories('DD.MM HH:mm'),
          scrollbar: {
            enabled: false,
          },
          minorGridLineColor: '#B5B8BE',
          tickColor: 'black',
          tickInterval: 1,
          tickWidth: 1,
          labels: {
            formatter({ value }) {
              const [, secondPart] = value.split(' ');

              return `<span style="font-weight: 400; font-size: 12px; color: black">${secondPart}</span>`;
            },
          },
        },
        {
          type: 'datetime',
          linkedTo: 0,
          opposite: true,
          tickWidth: 1,
          tickColor: 'black',
          labels: {
            formatter({ value }) {
              return `<span style="font-weight: 700; font-size: 16px; color: black">${value}</span>`;
            },
          },
          categories: xAxisCategories('DD.MM'),
          tickPositioner(this: any): number[] {
            const unique = this.categories.filter((value: string, index: number, array: string[]) => {
              return array.indexOf(value) === index;
            });

            const pos = unique.map((i: string) => this.categories.indexOf(i));

            pos.push(this.categories.length);

            return pos;
          },
        },
      ];
    },
  };

  return xAxisParametersByType[type]();
}
