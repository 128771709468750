/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReducersNames } from '@store/constants';

const loaderSlice = createSlice({
  name: ReducersNames.loader,
  initialState: false,
  reducers: {
    setLoaderStatus(state: boolean, { payload }: PayloadAction<boolean>) {
      return payload;
    },
  },
});

export const loaderActions = loaderSlice.actions;

export default loaderSlice.reducer;
