import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { DataTestIds } from '@app/v2/shared/enums';

interface Props extends I18N.TranslationFunction<'filters'> {
  hiddenReset: boolean;
  onReset: Common.VoidCallBack;
  onApply: Common.VoidCallBack;
  disabledReset: boolean;
}

const CSDFiltersDefaultActions = ({ onApply, onReset, disabledReset, hiddenReset, t }: Props) => {
  return (
    <>
      {!hiddenReset && (
        <Button variant="error" disabled={disabledReset} onClick={onReset} data-testid={DataTestIds.FiltersResetBtn}>
          {t('actions.reset')}
        </Button>
      )}
      <Button onClick={onApply} data-testid={DataTestIds.FiltersApplyBtn}>
        {t('actions.apply')}
      </Button>
    </>
  );
};

export default withTranslation('filters')(CSDFiltersDefaultActions);
