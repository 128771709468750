import React from 'react';
import { Box, Typography } from '@mui/material';

const CSDDefaultKit = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '95vh' }}>
      <Typography variant="h1" align="center">
        Добро пожаловать на страницу UI Kit
      </Typography>
      <Typography variant="h2" align="center">
        Центра Управления Содержанием Автомобильных дорог
      </Typography>
    </Box>
  );
};

export default CSDDefaultKit;
