import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Icon } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface Props {
  icon: string;
  iconColor?: string;
  linkProps: LinkProps;
}

const CSDLinkIconButton = ({ icon, iconColor, linkProps, ...rest }: Props, ref) => {
  const classes = useStyles();

  return (
    <Link ref={ref} {...rest} className={classes.link} {...linkProps}>
      <Icon sx={{ color: iconColor }}>{icon}</Icon>
    </Link>
  );
};

const useStyles = makeStyles({
  link: {
    display: 'inline-flex',
    textDecoration: 'none',
    color: 'inherit',
  },
});

export default React.forwardRef(CSDLinkIconButton);
