import React, { useCallback } from 'react';
import { Box, Typography, Theme, Slider, Icon, IconButton, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import icons from '@app/assets/iconFont';
import { convertPxToRem } from '@app/v2/shared/helpers';
import useAngleSliderStyles from './useAngleSliderStyles';
import { SliderSettingsProp } from '../constants';

const MIN_LINKS_SIZE = 14;
const MAX_LINKS_SIZE = 50;

type AdditionalStyleProps = {
  value: number;
  disabled?: boolean;
};

type Props = {
  value: number;
  disabled?: boolean;
  onChangeSlider: (field: SliderSettingsProp, value: number | number[]) => void;
};

const CSDLinksSize = ({ value, disabled, onChangeSlider }: Props) => {
  const classes = useStyles({ value: convertPxToRem(value), disabled });
  const sliderStyles = useAngleSliderStyles();

  const handleChangeSlider = useCallback(
    (e: Event, currentValue: number | number[]): void => {
      onChangeSlider(SliderSettingsProp.LINKS_SIZE, currentValue);
    },
    [onChangeSlider],
  );

  return (
    <Box className={classes.buttonsSizeContainer}>
      <Typography className={classes.sizeValue}>{value}</Typography>
      <Slider
        sx={{ width: '11rem' }}
        className={sliderStyles.slider}
        value={value}
        min={MIN_LINKS_SIZE}
        max={MAX_LINKS_SIZE}
        disabled={disabled}
        step={1}
        valueLabelDisplay="off"
        onChange={handleChangeSlider}
        name="fontSize"
      />
      <Box className={classes.exampleContainer}>
        <Box className={classes.cellActionsBar}>
          <Stack spacing={1} direction="row">
            <IconButton aria-label="meteo">
              <Icon>{icons.sky}</Icon>
            </IconButton>
            <IconButton aria-label="video">
              <Icon>{icons.camera}</Icon>
            </IconButton>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme, AdditionalStyleProps>(theme => {
  const getOpacityBasedOnDisabled = ({ disabled }: Pick<AdditionalStyleProps, 'disabled'>): number => (disabled ? 0.7 : 1);

  return {
    buttonsSizeContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    sizeValue: {
      '&.MuiTypography-root': {
        ...theme.typography.h4,
        opacity: getOpacityBasedOnDisabled,
      },
    },
    exampleContainer: {
      position: 'relative',
      width: '5.75rem',
      height: '100%',
    },
    cellActionsBar: {
      display: 'flex',
      position: 'absolute',
      left: 0,
      bottom: 0,
      background: theme.palette.text.t2,
      padding: '0.563rem 1.063rem',
      color: theme.palette.text.t6,
      '& .MuiIcon-root': {
        fontSize: ({ value }) => `${value / 2}rem`,
      },
      '& .MuiIconButton-root': {
        cursor: 'unset',
      },
      borderRadius: '0 0.5rem 0 0',
      height: ({ value }) => `${value}rem`,
      opacity: getOpacityBasedOnDisabled,
    },
  };
});

export default CSDLinksSize;
