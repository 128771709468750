/* eslint-disable react/no-unused-prop-types */
import previewMessage, { PreviewMessageProps } from './previewMessage';
import previewSign, { PreviewSignProps } from './previeSign';

type Props = {
  content: any;
  charsInterval: number;
  linesInterval: number;
  pixelSize: number;
  startCoords: { x: number; y: number };
  font: { h: number; w: number; color: string };
  background: string;
  itemSize: Scoreboards.Size;
  signValign: string | null;
  messageValign: string | null;
};

const ContentFactory = {
  message: (context: CanvasRenderingContext2D, props: PreviewMessageProps) => previewMessage(context, props),
  sign: (context: CanvasRenderingContext2D, props: PreviewSignProps) => previewSign(context, props),
};

const scoreboardItemPreview = (contextCanvas: CanvasRenderingContext2D, props: Props) => {
  const { content, font, background } = props;

  return Array.isArray(content) ? ContentFactory.message(contextCanvas, { ...props, font, background }) : ContentFactory.sign(contextCanvas, props);
};

export default scoreboardItemPreview;
