import React, { memo, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import ru from 'antd/es/date-picker/locale/ru_RU';
import en from 'antd/es/date-picker/locale/en_US';
import { PickerLocale, RangePickerTimeProps } from 'antd/es/date-picker/generatePicker';
import { useAppSelector } from '@app/v2/shared/hooks';
import 'moment/locale/ru';
import 'moment/locale/en-au';
import 'moment/locale/es';
import { Languages } from '@app/v2/shared/enums';
import DatePicker from './CSDDataPickerMomentCastomizationAntd';
import { RangeProps } from '../types';

moment.locale(Languages.RU.toLowerCase());

const CSDDataRangePickerRootAntd = ({ value = null, ...props }: RangeProps & { popupClassName?: string }) => {
  const [currentLocal, setCurrentLocale] = useState<PickerLocale>(ru);
  const { RangePicker } = DatePicker;

  const locale = useAppSelector(state => state.account.settings.locale);

  useEffect(() => {
    switch (locale.toUpperCase()) {
      case Languages.RU:
        moment.locale('ru');
        setCurrentLocale(ru);
        break;
      case Languages.EN:
        moment.locale('en-au');
        setCurrentLocale(en);
        break;
      default:
        moment.locale('ru');
        setCurrentLocale(ru);
    }
  }, [locale]);

  return (
    <RangePicker allowClear={false} locale={currentLocal} value={value} changeOnBlur {...(props as Omit<RangePickerTimeProps<Moment>, 'picker'>)} />
  );
};

export default memo(CSDDataRangePickerRootAntd);
