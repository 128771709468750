import { TFunction } from 'react-i18next';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import {
  alertIsExist,
  createAlertDescription,
  createAlertShortDescription,
  createTimeMessageAboutClosestEvent,
  createTwoColumnsLayoutForYAxis,
  formPlaceName,
  getEventWidth,
} from '../../common/helpers';
import { colors } from '../../common/constants/planChart';
import type { CustomGanttPointOptionsObject } from '../../common/types';
import type { AlertRoadPlan, PlaceRoadPlan } from '../types';

export default function preparePlaces(
  places: PlaceRoadPlan[],
  alerts: AlertRoadPlan[],
  t: TFunction<'common', 'units'>,
  chartsT: TFunction<'charts'>,
): CustomGanttPointOptionsObject[] {
  if (!places && !places.length) return [];

  const previouslyPreparedPlaces: CustomGanttPointOptionsObject[] = places.map(({ id: placeId, address, meters, alert }) => ({
    id: String(placeId),
    placeId,
    name: formPlaceName(address, meters, t('kilometers')),
    start: alertIsExist(alert) ? moment(alert.startTs).valueOf() : null,
    end: alertIsExist(alert) ? moment(alert.endTs).valueOf() : null,
    milestone: alertIsExist(alert) && moment(alert?.startTs).isSame(alert?.endTs) && true,
    collapsed: true,
    opacity: 1,
    description: alertIsExist(alert) ? createAlertDescription(alert.message, chartsT) : '',
    shortDescription: '',
    color: alertIsExist(alert) ? colors[alert.rank] : undefined,
    sort: address,
  }));

  const previouslyPreparedAlerts: CustomGanttPointOptionsObject[] = alerts.map(({ placeId, from, to, rank, message, id, place, type, data }) => ({
    id: `${id}-sub`,
    placeId,
    name: formPlaceName(place.kilometer, place.meter, t('kilometers')),
    start: moment(from).valueOf(),
    end: moment(to).valueOf(),
    milestone: moment(from).isSame(to) && true,
    description: createAlertDescription(message, chartsT) || '',
    shortDescription: createAlertShortDescription(data, rank, type.alias, chartsT),
    color: colors[rank],
    sort: place.kilometer,
  }));

  const groups: { [key: number]: CustomGanttPointOptionsObject[] } = groupBy([...previouslyPreparedPlaces, ...previouslyPreparedAlerts], 'placeId');

  return Object.entries(groups)
    .map(([_, group]) => {
      return group.map(item => ({
        ...item,
        name: createTwoColumnsLayoutForYAxis(
          { data: item.name, className: 'yaxis-column-text-address' },
          { data: createTimeMessageAboutClosestEvent(group, chartsT), className: 'yaxis-column-text-time' },
        ),
        pointWidth: getEventWidth(group, item.start, item.end, item.placeId),
      }));
    })
    .flat()
    .sort((a, b) => a.sort - b.sort);
}
