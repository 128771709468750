import React, { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Stack, StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { EMPTY_STRING, playlistsSizes } from '@app/v2/shared/constants';
import { CSDInput } from '@app/modules/kit-module/shared/ui';
import { FiltersKeys } from '@app/v2/shared/enums';
import TemplateSizeFilter from './TemplateSizeFilter';

const TemplatesFilter = ({ filters, onChange }: Filters.AdditionalFiltersProps) => {
  const { playlistTitle, playlistWidth, playlistHeight } = filters;
  const { t } = useTranslation('filters');

  const handleChangePlaylistTitles = (event: ChangeEvent<HTMLInputElement>): void => {
    onChange(FiltersKeys.PlaylistTitle, event.target.value);
  };

  const slidersConfig = useMemo<Filters.SlidersConfigItem[]>(() => {
    const handleChangeDeviceHeight = (value: number[]): void => {
      onChange(FiltersKeys.PlaylistHeight, value.map(Number));
    };

    const handleChangeDeviceWidth = (value: number[]): void => {
      onChange(FiltersKeys.PlaylistWidth, value);
    };

    return [
      {
        label: t('additional.height'),
        onChange: handleChangeDeviceHeight,
        values: playlistHeight || playlistsSizes.height.initialValue,
        maxValue: playlistsSizes.height.maxValue,
      },
      {
        label: t('additional.width'),
        onChange: handleChangeDeviceWidth,
        values: playlistWidth || playlistsSizes.width.initialValue,
        maxValue: playlistsSizes.width.maxValue,
      },
    ];
  }, [t, playlistHeight, playlistWidth, onChange]);

  return (
    <WrapperStack>
      <CSDInput
        label={t('additional.templateMessage.label')}
        placeholder={t('additional.enterTemplateMessage.placeholder')}
        variant="outlined"
        value={playlistTitle || EMPTY_STRING}
        onChange={handleChangePlaylistTitles}
      />

      {slidersConfig.map(({ label, ...other }) => (
        <React.Fragment key={label}>
          <Divider orientation="vertical" flexItem />
          <TemplateSizeFilter key={label} label={label} {...other} />
        </React.Fragment>
      ))}
    </WrapperStack>
  );
};

const WrapperStack = styled(Stack)<StackProps>({
  flexDirection: 'row',
  gap: '1.5rem',
  width: '100%',

  '& > div': {
    flex: 1,
  },
});

export default TemplatesFilter;
