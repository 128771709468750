import React, { Dispatch } from 'react';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { isUnknownDirection, strictlyEqual } from '@app/v2/shared/helpers';
import { ENTER_TOOLTIP_DELAY } from '@app/v2/shared/constants';
import { VMSDeviceDirections } from '@app/v2/shared/enums';
import { linearGradient } from '@theme/styles';

interface Props {
  descriptions: [string, string][];
  currentDirection: VMSDeviceDirections;
  setDirection: Dispatch<VMSDeviceDirections>;
}

const CSDTPIDeviceDescriptions = ({ descriptions, currentDirection, setDirection }: Props) => {
  const classes = useStyles();

  return (
    <>
      {!!descriptions.length && !isUnknownDirection(currentDirection) && (
        <Stack direction="row" className={classes.descriptions}>
          {descriptions.map(([direction, description]) => (
            <Tooltip key={direction} title={description} enterDelay={ENTER_TOOLTIP_DELAY}>
              {description && (
                <Box
                  className={clsx(classes.direction, { active: strictlyEqual(direction, currentDirection) })}
                  onClick={() => setDirection(direction as VMSDeviceDirections)}
                >
                  <Typography variant="body1">{description}</Typography>
                </Box>
              )}
            </Tooltip>
          ))}
        </Stack>
      )}
    </>
  );
};

const useStyles = makeStyles<Theme>(({ palette }) => ({
  descriptions: {
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'space-between',

    '& .active': {
      background: linearGradient,

      '& p': {
        color: palette.common.white,
      },
    },
  },
  direction: {
    zIndex: 1,
    borderTop: 'none',
    maxWidth: '6rem',
    padding: '0.2rem 0.5rem',
    border: '1px solid black',
    borderRadius: '0 0 0.5rem 0.5rem',
    backgroundColor: palette.common.white,
    cursor: 'pointer',

    '& > p': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
}));

export default CSDTPIDeviceDescriptions;
