import { TrafficChartType } from '@app/core/types/charts/traffic';
import { isNotNullAndUndefined, strictlyEqual } from '@app/v2/shared/helpers';

export default function prepareYAxisParameters({ type }: { type: TrafficChartType }) {
  return {
    plotLines: [
      {
        color: 'black',
        width: 1,
        value: 0,
        zIndex: 5,
      },
    ],
    min: 0,
    distance: 5,
    gridLineColor: '#8D909A',
    showFirstLabel: true,
    showLastLabel: true,
    labels: {
      useHTML: true,
      formatter(formatterObject) {
        if (!isNotNullAndUndefined(formatterObject?.value)) return null;

        const lastConsulateValue = Number(formatterObject.value) / 1000;
        const lastValue = lastConsulateValue > 1 ? `${lastConsulateValue}k` : formatterObject.value;

        if (formatterObject.isFirst) return `<span style="color: black; font-size: 16px;">${formatterObject.value}</span>`;
        if (formatterObject.isLast) return `<span style="color: black; font-size: 16px;">${lastValue}</span>`;
        return null;
      },
    },
    title: {
      text: null,
    },
    stackLabels: {
      enabled: strictlyEqual(type, TrafficChartType.Column),
      style: {
        fontWeight: 'bold',
        color: 'black',
        fontSize: '14px',
      },
    },
  };
}
