import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { ListItem, ListItemText, Tooltip } from '@mui/material';
import { useSystem } from '@app/v2/shared/hooks';
import useSearchQuery from '@app/core/hooks/useSearchQuery';

interface Props extends I18N.TranslationFunction<'sidebar'> {
  onClose: Common.VoidCallBack;
  item: Omit<StaticConfigs.Sidebar, 'children'>;
}

const SideBarListItem = ({ item, onClose, t }: Props) => {
  const classes = useStyles();
  const { handleChangeSidebarStatus } = useSystem();

  const search = useSearchQuery();

  return (
    <Tooltip title={t(item?.tooltip)} disableHoverListener={!item.tooltip}>
      <ListItem id={item.key.toString()} key={item.key.toString()} data-testid={item?.dataTestId}>
        <NavLink
          exact
          to={{ pathname: item.url.toString(), search }}
          onClick={() => {
            handleChangeSidebarStatus(false);
            onClose();
          }}
          className={classes.itemNavLink}
          activeClassName={classes.activeLink}
        >
          <ListItemText primary={t(item.label)} />
        </NavLink>
      </ListItem>
    </Tooltip>
  );
};

const useStyles = makeStyles({
  itemNavLink: {
    width: '100%',
    color: 'rgba(0, 0, 0, .87)',
    textDecoration: 'none',
  },
  activeLink: {
    '& > div > span': {
      fontWeight: 'bold',
    },
  },
});

export default withTranslation('sidebar')(memo(SideBarListItem));
