import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { mapActions } from '@app/store/slices/map';
import { authActions } from '@app/store/slices/auth';
import { accountActions } from '@app/store/slices/account';
import { chatActions } from '@app/store/slices/chat';
import { filterActions } from '@app/store/slices/filters';
import { loaderActions } from '@app/store/slices/loader';
import { subscriptionsActions } from '@app/store/slices/subscriptions';
import { viewTableElementsActions } from '@app/store/slices/viewTableElements';
import { meteoChartActions } from '@app/store/slices/meteoChart/meteoChartSlice';
import { waterLevelChartActions } from '@app/store/slices/waterLevelChart/waterLevelChartSlice';
import { trafficChartActions } from '@app/store/slices/trafficChart/trafficChartSlice';
import { meteoChartConstructorActions } from '@app/store/slices/meteoChartConstructor/meteoChartConstructorSlice';
import { newFilterActions } from '@app/store/slices/newFilters/newFiltersSlice';
import { newUserSetsActions } from '@app/store/slices/newUserSets/newUserSetsSlice';
import { favoritesLayoutsActions } from '@app/store/slices/favoritesLayouts/favoritesLayoutsSlice';
import { systemActions } from '@app/store/slices/system';
import { videoPostActions } from '@store/slices/videoPost/videoPostSlice';
import { sortingActions } from '@store/slices/sorting';
import useAppDispatch from './useAppDispatch';

const allActions = {
  ...authActions,
  ...accountActions,
  ...chatActions,
  ...filterActions,
  ...loaderActions,
  ...mapActions,
  ...subscriptionsActions,
  ...systemActions,
  ...viewTableElementsActions,
  ...meteoChartActions,
  ...meteoChartConstructorActions,
  ...waterLevelChartActions,
  ...trafficChartActions,
  ...videoPostActions,
  ...newFilterActions,
  ...newUserSetsActions,
  ...favoritesLayoutsActions,
  ...sortingActions,
};

const useActions = () => {
  const dispatch = useAppDispatch();

  return useMemo(() => {
    return bindActionCreators(allActions, dispatch);
  }, [dispatch]);
};

export default useActions;
