import moment from 'moment/moment';
import Highcharts, { XAxisOptions } from 'highcharts';
import { DATE_FORMAT, DATE_PERIOD } from '@app/v2/shared/constants';
import { strictlyEqual } from '@app/v2/shared/helpers';

interface XAxisParameters {
  dates: string[];
  isFixed: boolean;
  currentIndex: number;
  convertDataToMomentWithOffeset: (date: moment.MomentInput) => moment.Moment;
}

export default function prepareXAxisParameters({
  dates,
  isFixed,
  currentIndex,
  convertDataToMomentWithOffeset,
}: XAxisParameters): Highcharts.XAxisOptions[] {
  const minDate = convertDataToMomentWithOffeset(dates[0]);
  const maxDate = convertDataToMomentWithOffeset(dates[dates.length - 1]);
  const isMaxDateToday = strictlyEqual<string>(maxDate.format('yyyyMMDD'), moment().format('yyyyMMDD'));

  const commonSettings: Partial<XAxisOptions> = {
    min: minDate.startOf('day').valueOf(),
    max: isMaxDateToday ? maxDate.valueOf() : maxDate.endOf('day').valueOf(),
    type: 'datetime',
    startOnTick: true,
    showFirstLabel: true,
  };

  const crosshair: Pick<Highcharts.XAxisOptions, 'crosshair'> = !isFixed ? { crosshair: { color: '#EE555780', dashStyle: 'Solid', width: 3 } } : {};

  const plotLines: Pick<Highcharts.XAxisOptions, 'plotLines'> = isFixed
    ? {
        plotLines: [
          {
            color: '#3245AF',
            dashStyle: 'Solid',
            width: 3,
            value: currentIndex,
            zIndex: 10,
          },
        ],
      }
    : {};

  return [
    {
      tickInterval: 2 * DATE_PERIOD.HOUR,
      minRange: DATE_PERIOD.HOUR,
      ...commonSettings,
      ...crosshair,
      ...plotLines,
      labels: {
        formatter({ value }) {
          return `<span style="font-weight: 400; font-size: 12px; color: black">${convertDataToMomentWithOffeset(value).format(
            DATE_FORMAT.FORMAT_RU_HOURS,
          )}</span>`;
        },
      },
    },
    {
      opposite: true,
      tickInterval: DATE_PERIOD.DAY,
      ...commonSettings,
      labels: {
        formatter({ value }) {
          return `<span style="font-weight: 700; font-size: 16px; color: black">${convertDataToMomentWithOffeset(value).format('DD.MM')}</span>`;
        },
      },
    },
  ];
}
