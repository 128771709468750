export default function prepareYAxisParameters() {
  return {
    stackLabels: {
      enabled: true,
    },
    gridLineWidth: 0,
    labels: {
      enabled: false,
    },
    title: {
      enabled: false,
    },
  };
}
