import { useCallback } from 'react';

type StorageActions = {
  getItem: (key: string) => string | null;
  setItem: <SetValue>(key: string, value: SetValue) => void;
  removeItem: (key: string) => void;
  removeItems: (keys: string[]) => void;
  clear: () => void;
};

export default function useLocalStorage(): StorageActions {
  const getItem = useCallback((key: string): string | null | undefined => {
    const item = localStorage.getItem(key);

    if (item === null) return null;

    return item;
  }, []);

  const setItem = useCallback(<SetValue>(key: string, value: SetValue): void => {
    localStorage.setItem(key, JSON.stringify(value));
  }, []);

  const removeItem = useCallback((key: string): void => {
    localStorage.removeItem(key);
  }, []);

  const removeItems = useCallback((keys: string[]): void => {
    keys.forEach(key => localStorage.removeItem(key));
  }, []);

  const clear = (): void => {
    localStorage.clear();
  };

  return { getItem, setItem, removeItem, removeItems, clear };
}
