import * as React from 'react';
import { ReactNode } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@app/components/common/circular-progress/CircularProgress';

type Props = {
  loading: boolean;
  message?: string;
  height?: string;
  overflow?: string;
  width?: number | string;
} & { children?: ReactNode };

const WrapperProgress = ({ loading, message, height = 'calc(100%)', children, overflow = 'visible', width = '100%' }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperProgress} style={{ height, overflow, width }}>
      {children}
      {loading && (
        <div className={classes.progress}>
          <CircularProgress color="primary" />
          {message && <Typography>{message}</Typography>}
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapperProgress: {
    position: 'relative',
  },
  progress: {
    width: '100%',
    height: 'calc(100%)',
    position: 'absolute',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF7F',
    zIndex: 1,

    '& > p': {
      marginTop: '2rem',
    },
  },
}));

export default WrapperProgress;
