import { KeyPrefix } from 'react-i18next';
import { i18n } from '@app/v2/shared/localization';
import { DeviceType } from '@app/v2/shared/enums';

/** Configuration item fields */

export const NUM_LINES = 'num-lines';
export const NUM_CHARS_PER_LINE = 'num-chars-per-line';

/** Formik field path */

export const ITEMS_FIELD = 'configuration.items';
export const PLAYLIST_ITEMS = 'playlist';

/** Preview */

export const DEFAULT_TEMPLATE_WIDTH = 256;
export const DEFAULT_TEMPLATE_HEIGHT = 96;
export const DEFAULT_SIGN_HEIGHT = 48;
export const COMPRESSION_RATIO = 0.6;
export const PIXEL_SIZE = 3;

/** Default autoBox values */

export const autoBoxContent: { text: KeyPrefix<'scoreboard'>; align: 'center' }[] = [
  { text: 'common.constants.autoBoxContent1', align: 'center' },
  { text: 'common.constants.autoBoxContent2', align: 'center' },
  { text: 'common.constants.autoBoxContent3', align: 'center' },
];

/** Common */

export const stationTypeTranslation: Record<DeviceType, string> = {
  [DeviceType.Message]: i18n.t('scoreboard:common.constants.txtbox'),
  [DeviceType.Sign]: i18n.t('scoreboard:common.constants.sgnlst'),
  [DeviceType.Azpi]: i18n.t('scoreboard:common.constants.autobox'),
};
