enum DataLoadingTypes {
  ROAD_MAPPING = 'ROAD_MAPPING',
  ROAD_OBJECTS = 'ROAD_OBJECTS',
  STATIC_OBJECTS = 'STATIC_OBJECTS',
  WEATHER_RADARS = 'WEATHER_RADARS',
  WEATHER_RADARS_IMAGES = 'WEATHER_RADARS_IMAGES',
  PROFILES = 'PROFILES',
  NOTES = 'NOTES',
}

export default DataLoadingTypes;
