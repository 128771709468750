import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { useTemporarySetTableMode } from '@app/v2/shared/hooks';
import { HeaderCell } from '@app/modules/log-module/common/types/ListingTypes';
import { CSDDataGrid } from '@app/modules/kit-module/shared/ui';
import { NOTES_GRID_OVERSCAN, FULL_SCREEN } from '@app/v2/shared/constants';
import ListingCard from './listing/ListingCard';

type Props<T> = {
  headers: HeaderCell[];
  listingData: T[];
  handleVirtualizedScroll: (index: number) => void;
};

const ListingNotesCards = <T,>({ listingData, headers, handleVirtualizedScroll }: Props<T>) => {
  const renderCard = (index: number, rowData: { [key: string]: any }) => <ListingCard headers={headers} row={rowData} />;

  const classes = useStyles();

  useTemporarySetTableMode();

  return (
    <CSDDataGrid
      style={FULL_SCREEN}
      itemClassName={classes.itemContainerGrid}
      listClassName={classes.containerItemGrid}
      overscan={NOTES_GRID_OVERSCAN}
      data={listingData}
      endReached={handleVirtualizedScroll}
      render={renderCard}
    />
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  containerItemGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(275px, 1fr))',
    gap: theme.spacing(1),
    width: '100%',
  },
  itemContainerGrid: {
    boxSizing: 'border-box',
    width: '100%',
  },
}));

export default ListingNotesCards;
