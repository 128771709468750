import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { ButtonProps } from '@mui/material';
import videoQueries from '@app/clients/apollo/requests/queries/video';
import { isFunction, isNotNullAndUndefined } from '@app/v2/shared/helpers';
import { showSuccess, showError } from '@app/core/utils/notifications';

interface Props {
  id: number;
  onResult?: (url: string) => void;
  disabled?: boolean;
  AnchorNode: FC<
    ButtonProps & {
      handleClick: Common.VoidCallBack;
      loading?: boolean;
    }
  >;
}

const CSDUpdateVideoCamera = ({ AnchorNode, onResult, id, disabled }: Props) => {
  const { t } = useTranslation('features', { keyPrefix: 'updateVideoCamera' });

  const [loading, setLoading] = useState<boolean>(false);

  const { refetch } = useQuery<Video.CurrentVideoSnapShotResponse, Video.CurrentVideoSnapShotVariables>(videoQueries.currentVideoStationSnapshot, {
    skip: true,
  });

  const handleUpdateCamera = () => {
    if (!isNotNullAndUndefined(id) || !isFunction(refetch)) return;

    setLoading(true);

    refetch({ id })
      .then(({ data }) => {
        if (isFunction(onResult)) onResult(data?.videoStationLived?.image);
        showSuccess(data?.videoStationLived?.message ?? t('success'));
      })
      .catch(() => showError(t('error')))
      .finally(() => setLoading(false));
  };

  return <AnchorNode handleClick={handleUpdateCamera} loading={loading} disabled={disabled} />;
};

export default CSDUpdateVideoCamera;
