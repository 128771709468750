import React, { useCallback } from 'react';
import { useTranslation, KeyPrefix } from 'react-i18next';
import { Grid } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import StaticObjectTypes from '@app/modules/map-module/enums/StaticObjectTypes';
import { ObjectMapState } from '@store/slices/map';
import {
  AsphaltPlantsIcon,
  BridgesIcon,
  FlyoversIcon,
  Milestones,
  OverPassesIcon,
  PedestrianCrossesIcon,
  PipesIcon,
  ProdBasesIcon,
  RailCrossesIcon,
  SandBasesIcon,
  TunnelsIcon,
} from '@app/assets/map/gis';
import { isDefaultMapProfile } from '@app/v2/shared/helpers';
import useMapProfileContext from '@app/v2/features/mapProfiles/hooks/useMapProfileContext';
import { ProfileMapSettingLabels } from '@app/v2/shared/enums';
import SelectableTabItem from '../../components/SelectableTabItem';

const gisObjects: { label: KeyPrefix<'map'>; key: StaticObjectTypes; icon }[] = [
  {
    label: 'mapCardControl.gis.milestone',
    key: StaticObjectTypes.MILESTONE,
    icon: <SvgIcon htmlColor="red" color="inherit" component={Milestones} />,
  },
  { label: 'mapCardControl.gis.bridge', key: StaticObjectTypes.BRIDGE, icon: <SvgIcon color="inherit" component={BridgesIcon} /> },
  { label: 'mapCardControl.gis.viaduct', key: StaticObjectTypes.VIADUCT, icon: <SvgIcon color="inherit" component={OverPassesIcon} /> },
  { label: 'mapCardControl.gis.tunnel', key: StaticObjectTypes.TUNNEL, icon: <SvgIcon color="inherit" component={TunnelsIcon} /> },
  { label: 'mapCardControl.gis.railCross', key: StaticObjectTypes.RAIL_CROSS, icon: <SvgIcon color="inherit" component={RailCrossesIcon} /> },
  {
    label: 'mapCardControl.gis.pedestrianCross',
    key: StaticObjectTypes.PEDESTRIAN_CROSS,
    icon: <SvgIcon color="inherit" component={PedestrianCrossesIcon} />,
  },
  { label: 'mapCardControl.gis.flyover', key: StaticObjectTypes.FLYOVER, icon: <SvgIcon color="inherit" component={FlyoversIcon} /> },
  { label: 'mapCardControl.gis.pipe', key: StaticObjectTypes.PIPE, icon: <SvgIcon color="inherit" component={PipesIcon} /> },
  { label: 'mapCardControl.gis.sandBase', key: StaticObjectTypes.SAND_BASE, icon: <SvgIcon color="inherit" component={SandBasesIcon} /> },
  { label: 'mapCardControl.gis.prodBase', key: StaticObjectTypes.PROD_BASE, icon: <SvgIcon color="inherit" component={ProdBasesIcon} /> },
  { label: 'mapCardControl.gis.asphaltPlan', key: StaticObjectTypes.ASPHALT_PLANT, icon: <SvgIcon color="inherit" component={AsphaltPlantsIcon} /> },
];

const GisControl = () => {
  const { t } = useTranslation('map');
  const {
    currentActiveProfile,
    profileData: { gis },
    handleChangeProfileCheckbox,
  } = useMapProfileContext();

  const setGisObject = useCallback(
    (state: ObjectMapState) => handleChangeProfileCheckbox(ProfileMapSettingLabels.GIS, state.key, state.checked),
    [handleChangeProfileCheckbox],
  );

  return (
    <Grid container>
      {gisObjects.map(({ label, key, icon }) => (
        <Grid item xs={6} key={key}>
          <SelectableTabItem
            disabled={isDefaultMapProfile(currentActiveProfile)}
            label={t(label) as string}
            icon={icon}
            isActive={gis[key].checked}
            onClick={() => setGisObject({ key, checked: !gis[key].checked })}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default GisControl;
