import { useCallback } from 'react';
import useAppDispatch from '@app/core/hooks/customReduxHooks/useAppDispatch';
import useAppSelector from '@app/core/hooks/customReduxHooks/useAppSelector';
import useActions from '@app/core/hooks/customReduxHooks/useActions';

export default function useSliderValue(): [number, (i: number) => void] {
  const dispatch = useAppDispatch();
  const { setSliderValue: setSliderValueAction } = useActions();

  const setSliderValue = useCallback(
    (date: number) => {
      dispatch(setSliderValueAction(date));
    },
    [dispatch, setSliderValueAction],
  );

  const currentSliderValue = useAppSelector(state => state.map.weather.currentSliderValue);

  return [currentSliderValue, setSliderValue];
}
