import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { findPageByPathName } from '@app/v2/shared/utils';

export default function useCurrentPage(): Common.PagesItem {
  const [currentPage, setCurrentPage] = useState<Common.PagesItem>(null);

  const history = useHistory();

  useEffect(() => {
    if (currentPage) return;
    setCurrentPage(findPageByPathName(history.location.pathname));
  }, [currentPage, history.location.pathname]);

  useEffect(() => {
    const unSubscribe = history.listen(({ pathname }) => {
      const nextCurrentPage = findPageByPathName(pathname);

      setCurrentPage(prevCurrentPage => {
        if (!isEqual(prevCurrentPage, nextCurrentPage)) return nextCurrentPage;
        return prevCurrentPage;
      });
    });

    return unSubscribe;
  }, [history]);

  return currentPage;
}
