export const basicTabs = `<Tabs value={value} onChange={handleChange} aria-label={aria-lable}>
  ...
  <Tab label={label} id={id} aria-controls={aria-controls} {...props}/>
  ...
</Tabs>`;
export const customTabsWithIcons = `<Tabs value={value} onChange={handleChange} aria-label={aria-lable}>
  ...
  <Tab
    icon={Icon}
    sx={{boxShadow: '0px -2px 5px 0px #34343433', marginTop: '5px', minWidth: 195}}
    label={label}
    id={id}
    aria-controls={aria-controls}
  />
  ...
</Tabs>`;
export const csdTab = `<Tabs value={value} onChange={handleChange} aria-label={aria-lable}>
  ...
  <CSDTab
    icon={Icon}
    sx={{boxShadow: '0px -2px 5px 0px #34343433', marginTop: '5px', minWidth: 195}}
    label={label}
    id={id}
    aria-controls={aria-controls}
  />
  ...
</Tabs>`;
