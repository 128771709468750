import React, { memo, PropsWithChildren, useRef, useEffect, useCallback } from 'react';
import Slider from 'react-slick';
import { Box } from '@mui/material';
import debounce from 'lodash/debounce';
import { isFunction, isNumber } from '@app/v2/shared/helpers';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Events } from '@app/v2/shared/enums';
import { useEventListener } from '@app/v2/shared/hooks';

type Props = {
  index?: number;
  beforeChange?: (newIndex: number) => void;
  slidesToShow?: number;
  slidesToScroll?: number;
  isScrollWheelEvent?: boolean;
};

const CSDHorizontalSlick = ({
  children,
  beforeChange,
  index,
  slidesToShow = 1,
  slidesToScroll = 1,
  isScrollWheelEvent = true,
}: PropsWithChildren<Props>) => {
  const debounceSlickGoTo = useRef((i: number) => {});
  const wrapperRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef(null);

  const setRef = (node): void => {
    if (!sliderRef?.current) {
      sliderRef.current = node;

      const slickGoTo = sliderRef.current?.slickGoTo;
      if (isFunction(slickGoTo)) debounceSlickGoTo.current = debounce(slickGoTo, 200);
    }
  };

  useEffect(() => {
    if (isFunction(debounceSlickGoTo?.current) && isNumber(index)) {
      debounceSlickGoTo.current(index);
    }
  }, [index]);

  const onWheel = useCallback(
    (event: WheelEvent): void => {
      if (!isScrollWheelEvent) return;

      event.preventDefault();
      event.stopPropagation();

      if (event.deltaY > 0) sliderRef?.current.slickNext();
      else sliderRef?.current.slickPrev();
    },
    [isScrollWheelEvent],
  );

  useEventListener(Events.Wheel, onWheel, wrapperRef.current);

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    variableWidth: true,
    vertical: false,
    adaptiveHeight: true,
    slidesToScroll,
    slidesToShow,
    beforeChange: (_, nextIndex: number) => isFunction(beforeChange) && beforeChange(nextIndex),
  };

  return (
    <Box
      ref={wrapperRef}
      sx={{
        width: 'inherit',
        '& .slick-slide': {
          marginRight: '0.5rem',
        },
      }}
    >
      <Slider ref={setRef} {...settings}>
        {children}
      </Slider>
    </Box>
  );
};

export default memo(CSDHorizontalSlick);
