import React, { useRef, memo } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Button } from '@mui/material';
import { ButtonTypeMap } from '@mui/material/Button/Button';
import { makeStyles } from '@mui/styles';
import { isFunction } from '@app/v2/shared/helpers';
import { DataTestIds } from '@app/v2/shared/enums';

interface Props {
  label: string;
  linkProps: LinkProps;
  buttonProps?: Pick<ButtonTypeMap, 'props'>['props'];
  dataTestId?: DataTestIds;
}

const CSDLinkButton = ({ label, buttonProps, linkProps, dataTestId }: Props) => {
  const classes = useStyles();
  const linkRef = useRef<HTMLAnchorElement | null>(null);

  const handleBtnClick = () => {
    if (linkRef.current && isFunction(linkRef.current.click)) {
      linkRef.current.click();
    }
  };

  return (
    <Button onClick={handleBtnClick} data-testid={dataTestId} {...buttonProps}>
      <Link innerRef={linkRef} className={classes.link} {...linkProps} />
      {label}
    </Button>
  );
};

const useStyles = makeStyles({
  link: {
    display: 'none',
    textDecoration: 'none',
    color: 'inherit',
  },
});

export default memo(CSDLinkButton);
