import { MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { Viewport } from 'react-leaflet';
import flatten from 'lodash/flatten';
import { ProfileMapSettingLabels } from '@app/v2/shared/enums';
import useMapDataLoading from '@app/core/hooks/useMapDataLoading';
import { Item } from '@app/core/types/profiles/map';
import useAppSelector from '@app/core/hooks/customReduxHooks/useAppSelector';
import useAppDispatch from '@app/core/hooks/customReduxHooks/useAppDispatch';
import useActions from '@app/core/hooks/customReduxHooks/useActions';

const MIN_LAT = -90;
const MAX_LAT = 90;

const MIN_LNG = -180;
const MAX_LNG = 180;

const useViewportMap = (map: MutableRefObject<L.Map>) => {
  const dispatch = useAppDispatch();
  const { setViewport } = useActions();

  const [loadings] = useMapDataLoading();
  const { profiles, activeProfile, coordinates: mapCoordinates } = useAppSelector(state => state.map);

  const isSetViewport = useRef(false);

  const isProfilesLoad = useMemo<boolean>(() => {
    return loadings.PROFILES.load;
  }, [loadings.PROFILES.load]);

  const isLoadingMapData = useMemo(() => {
    return Object.values(loadings).some(item => item.load);
  }, [loadings]);

  useEffect(() => {
    if (!!profiles && (activeProfile || (!isProfilesLoad && !profiles?.length))) {
      isSetViewport.current = true;
    }
  }, [activeProfile, isProfilesLoad, profiles]);

  useEffect(() => {
    const fitBoundsCoordinates = () => {
      const coordinates = flatten(Object.values(mapCoordinates));
      if (coordinates?.length) {
        const coordinatesFilter = coordinates.filter(([lat, lng]) => lat >= MIN_LAT && lat <= MAX_LAT && lng >= MIN_LNG && lng <= MAX_LNG);
        map.current.fitBounds(coordinatesFilter);
      }
    };

    const setNewViewport = (viewport: Viewport) => {
      dispatch(setViewport(viewport));
    };
    const isViewport = (items: { [key in ProfileMapSettingLabels]?: Item } = {}, settings: any): settings is Viewport => {
      if (typeof items === 'object' && items !== null) {
        return Object.hasOwnProperty.call(items, 'viewport');
      }

      return false;
    };

    if (map.current && isSetViewport.current && !isLoadingMapData) {
      isSetViewport.current = false;
      const viewportParams = activeProfile?.items?.viewport;
      if (isViewport(activeProfile?.items, viewportParams?.settings)) {
        viewportParams?.checked ? setNewViewport(viewportParams.settings) : fitBoundsCoordinates();
      }
    }
  }, [mapCoordinates, activeProfile, map, dispatch, isLoadingMapData, setViewport]);
};

export default useViewportMap;
