import React, { useState } from 'react';
import { Stack, Button } from '@mui/material';
import { CSDSnackbar } from '@app/modules/kit-module/shared/ui';
import { errorSnackbar, successSnackbar, infoSnackbar } from './example';
import CSDKitDocBlock from '../../../shared/ui/CSDKitDocBlock/CSDKitDocBlock';

enum Snackbars {
  Error = 'error',
  Success = 'success',
  Info = 'info',
}

const CSDSnackbars = () => {
  const [isOpen, setIsOpen] = useState<Record<Snackbars, boolean>>({
    error: false,
    success: false,
    info: false,
  });

  const handleOpen = (key: Snackbars): void => {
    setIsOpen(prev => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="Snackbar error" codeExample={errorSnackbar}>
        <Button onClick={() => handleOpen(Snackbars.Error)}>Открыть</Button>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Snackbar success" codeExample={successSnackbar}>
        <Button onClick={() => handleOpen(Snackbars.Success)}>Открыть</Button>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Snackbar info" codeExample={infoSnackbar}>
        <Button onClick={() => handleOpen(Snackbars.Info)}>Открыть</Button>
      </CSDKitDocBlock>

      {isOpen.error && <CSDSnackbar message="Сообщение об ошибке" actionButtonTitle="Открыть" severity={Snackbars.Error} />}
      {isOpen.success && <CSDSnackbar message="Сообщение об успехе" actionButtonTitle="Открыть" severity={Snackbars.Success} />}
      {isOpen.info && <CSDSnackbar message="Нейтральное сообщение" actionButtonTitle="Открыть" severity={Snackbars.Info} />}
    </Stack>
  );
};

export default CSDSnackbars;
