import React from 'react';
import { styled } from '@mui/styles';
import { Box, BoxProps } from '@mui/material';
import { getSkeletonItems } from '@app/v2/shared/helpers';
import { CSDButtonSkeleton } from '@app/modules/kit-module/shared/ui';
import { CSDUpdateVideoCameraBtn, CSDWipeVideoCameraBtn, CSDDownloadVideoSnapshotBtn } from '@app/v2/features';
import { UsersAccess } from '@app/v2/shared/enums';
import { useCheckAccess } from '@app/v2/shared/hooks';

interface Props {
  stationId: number;
  onChangeLiveSnapShotURL: (url: string) => void;
  loading: boolean;
  isAnimation: boolean;
  imageUrl: string;
  stationProperties: {
    isLive: boolean;
    isStream: boolean;
    isWiper: boolean;
  };
}

const CSDVideoActionPanel = ({ stationId, onChangeLiveSnapShotURL, loading, imageUrl, stationProperties, isAnimation }: Props) => {
  const { accessChecker } = useCheckAccess();
  const { isLive, isWiper } = stationProperties;

  return (
    <StyledActionsBlock>
      {loading ? (
        getSkeletonItems(3).map(id => <CSDButtonSkeleton key={id} height={50} />)
      ) : (
        <>
          {accessChecker([UsersAccess.BUTTON_VIDEO_LIVE]) && isLive && (
            <CSDUpdateVideoCameraBtn disabled={isAnimation} stationId={stationId} onResult={onChangeLiveSnapShotURL} />
          )}
          {accessChecker([UsersAccess.BUTTON_VIDEO_CLEAR]) && isWiper && <CSDWipeVideoCameraBtn stationId={stationId} disabled={isAnimation} />}
          {imageUrl && <CSDDownloadVideoSnapshotBtn imageUrl={imageUrl} />}
        </>
      )}
    </StyledActionsBlock>
  );
};

export default CSDVideoActionPanel;

const StyledActionsBlock = styled(Box)<BoxProps>({
  display: 'flex',
  flexDirection: 'row',
  gap: '1rem',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
});
