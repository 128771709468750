/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReducersNames } from '@store/constants';

type State = {
  meteoChartProfiles: Meteo.ChartProfile[];
  meteoChartActiveProfile: Meteo.ChartProfile | null;
};

export const initialState: State = {
  meteoChartProfiles: [],
  meteoChartActiveProfile: null,
};

const meteoChartConstructorSlice = createSlice({
  name: ReducersNames.meteoChartConstructor,
  initialState,
  reducers: {
    setMeteoChartProfiles(state: State, { payload }: PayloadAction<{ nextValue: Meteo.ChartProfile[] }>) {
      state.meteoChartProfiles = payload.nextValue;
    },
    setMeteoChartActiveProfile(state: State, { payload }: PayloadAction<{ nextActiveProfileId: number }>) {
      state.meteoChartActiveProfile = state.meteoChartProfiles.find(({ id }) => id === payload.nextActiveProfileId);
    },
  },
});

export const meteoChartConstructorActions = meteoChartConstructorSlice.actions;

export default meteoChartConstructorSlice.reducer;
