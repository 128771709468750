import { useCallback } from 'react';
import { LISTING_DEFAULT_PAGE_NUMBER } from '@app/core/constants';
import useAppSelector from '@app/core/hooks/customReduxHooks/useAppSelector';
import useActions from '@app/core/hooks/customReduxHooks/useActions';
import { TableMode } from '@app/v2/shared/enums';

export default function useTableMode(): [TableMode, (m: TableMode, pagination?: Partial<Common.Pagination>) => void] {
  const { setPagination, setTableMode: setTableModeAction } = useActions();

  const setTableMode = useCallback(
    (mode: TableMode, pagination: Partial<Common.Pagination>) => {
      setTableModeAction(mode);

      const newPagination = pagination ? { ...pagination } : { page: LISTING_DEFAULT_PAGE_NUMBER };

      setPagination(newPagination);
    },
    [setTableModeAction, setPagination],
  );

  const tableMode = useAppSelector(state => state.filters.mode);

  return [tableMode, setTableMode];
}
