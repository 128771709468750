import { useCallback } from 'react';
import { strictlyEqual } from '@app/v2/shared/helpers';
import useAppSelector from './reactRedux/useAppSelector';
import useLayoutConfig from './useLayoutConfig';
import useActions from './reactRedux/useActions';
import useLocalStorage from './useLocalStorage';
import LocalStorageKeys from '../enums/LocalStorageKeys';

export default function useSystem() {
  const {
    changeHeaderStatus,
    changeSidebarStatus,
    changeNotificationStatus,
    toggleIsListingLoaded,
    changeVideoWallStatus,
    setCurrentFooterHeight,
    setPaginationStatus,
  } = useActions();

  const {
    headerConfig: { isViewVideoWall },
  } = useLayoutConfig();

  const { setItem } = useLocalStorage();

  const isHeaderRollUp = useAppSelector(state => state.system.isHeaderRollUp);
  const isVideoWall = useAppSelector(state => state.system.isVideoWall);
  const isNotificationOpen = useAppSelector(state => state.system.isNotificationOpen);
  const isSidebarOpen = useAppSelector(state => state.system.isSidebarOpen);
  const currentFooterHeight = useAppSelector(state => state.system.currentFooterHeight);
  const isDisablePagination = useAppSelector(state => state.system.isDisablePagination);
  const isListingLoaded = useAppSelector(state => state.system.isListingLoaded);

  const handleChangeHeaderStatus = useCallback(
    (status: boolean) => {
      setItem(LocalStorageKeys.HeaderStatus, status);
      changeHeaderStatus({ status });
    },
    [changeHeaderStatus, setItem],
  );

  const handleChangeVideoWallStatus = useCallback((status: boolean) => changeVideoWallStatus({ status }), [changeVideoWallStatus]);

  const handleChangeNotificationStatus = useCallback((status: boolean) => changeNotificationStatus({ status }), [changeNotificationStatus]);

  const handleChangeSidebarStatus = useCallback((status: boolean) => changeSidebarStatus({ status }), [changeSidebarStatus]);

  const handleSetPaginationStatus = useCallback((status: boolean) => setPaginationStatus({ status }), [setPaginationStatus]);

  const handleSetCurrentFooterHeight = useCallback(
    (height: number) => {
      if (strictlyEqual<number>(currentFooterHeight, height)) return;
      setCurrentFooterHeight({ height });
    },
    [currentFooterHeight, setCurrentFooterHeight],
  );

  return {
    // isViewVideoWall && isVideoWall -> switcher filter will hide and roll down. (temporary solution, need to split logic)
    isHeaderRollUp: isViewVideoWall && isVideoWall ? false : isHeaderRollUp,
    isNotificationOpen,
    isSidebarOpen,
    isVideoWall,
    currentFooterHeight,
    isDisablePagination,
    handleChangeHeaderStatus,
    handleChangeNotificationStatus,
    handleChangeSidebarStatus,
    handleChangeVideoWallStatus,
    handleSetCurrentFooterHeight,
    handleSetPaginationStatus,
    toggleIsListingLoaded,
    isListingLoaded,
  };
}
