import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { IconButton, Icon, Stack, Typography, CircularProgress } from '@mui/material';
import { useQuery } from '@apollo/client';
import accountQueries from '@app/clients/apollo/requests/queries/account';
import icons from '@app/assets/iconFont';
import DocumentsTable from './DocumentsTable';
import { BillingDocument, BillingResponse } from '../../types';

type Props = {
  close: () => void;
} & I18N.TranslationFunction<'dialogs', 'accountSettings.tabs.documents'>;

const AccountDocumentsTabPanel = ({ close = () => {}, t }: Props) => {
  const { data, loading } = useQuery<BillingResponse>(accountQueries.billing);
  const [listingData, setListingData] = React.useState<BillingDocument[]>([]);

  React.useEffect(() => {
    if (data?.billing?.documents && data?.billing?.documents.length) {
      setListingData(data.billing.documents);
    }
  }, [data]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h2">{t('title')}</Typography>
        <IconButton onClick={close}>
          <Icon>{icons.close}</Icon>
        </IconButton>
      </Stack>
      <Stack height="100%" overflow="auto">
        {(loading && (
          <Stack height="100%" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        )) || <DocumentsTable listingData={listingData} />}
      </Stack>
    </>
  );
};

export default withTranslation('dialogs', { keyPrefix: 'accountSettings.tabs.documents' })(AccountDocumentsTabPanel);
