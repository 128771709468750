import moment from 'moment/moment';
import { DATE_FORMAT, EMPTY_STRING } from '@app/v2/shared/constants';

export const formInitialValue: Scoreboards.Template = {
  duration: 60,
  dateFrom: moment().format(DATE_FORMAT.API_DATE_FORMAT),
  dateTo: moment().format(DATE_FORMAT.API_DATE_FORMAT),
  startTime: moment().startOf('day').format(DATE_FORMAT.FORMAT_RU_HOURS),
  endTime: moment().endOf('day').format(DATE_FORMAT.FORMAT_RU_HOURS),
  template: {
    id: null,
    title: EMPTY_STRING,
    contents: null,
    configuration: {
      id: null,
      title: EMPTY_STRING,
      configuration: null,
      organizationId: null,
      color: EMPTY_STRING,
      type: EMPTY_STRING,
      size: { height: 0, width: 0 },
    },
  },
};

export const values: Omit<Scoreboards.PlaylistModel, 'id'> = {
  title: '',
  organizationId: null,
  isGroup: false,
  playlist: [{ ...formInitialValue }],
};
