import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { formInitialValue } from '@app/modules/scoreboard-module/playlists/form/values';

type Props = {
  push: Common.AnyFunction;
  playlist: Scoreboards.Template[];
  lastConfigId: number | null;
};

const AddFormButton = ({ push, playlist, lastConfigId }: Props) => {
  const { t } = useTranslation('scoreboard');

  const setStartFormValues = () => {
    return {
      ...formInitialValue,
      template: {
        ...formInitialValue.template,
        configuration: {
          ...formInitialValue.template.configuration,
          id: lastConfigId || '',
        },
      },
    };
  };

  return (
    <Button variant="outlined" color="primary" onClick={() => push(setStartFormValues())}>
      {t('configuration.actions.addForm')}
    </Button>
  );
};

export default AddFormButton;
