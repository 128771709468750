import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { CSDDataRangePickerAntdWithBtns } from '@app/modules/kit-module/shared/ui';
import { DATE_FORMAT } from '@app/v2/shared/constants';
import { strictlyEqual } from '@app/v2/shared/helpers';
import { FiltersKeys } from '@app/v2/shared/enums';
import { useTimePeriods } from '@app/v2/shared/hooks';
import { formatEn } from '@app/core/helpers/dateFormat';

interface Props extends Filters.AdditionalFiltersProps {
  initialDateFrom?: string;
  initialDateTo?: string;
}

const FilterDates = ({ filters, onChange, initialDateTo, initialDateFrom }: Props) => {
  const { dateTo, dateFrom } = filters;

  const { t } = useTranslation('common', { keyPrefix: 'dates' });

  const handleChange = useCallback(
    ([nextDateFrom, nextDateTo]: [Moment, Moment]) => {
      onChange(FiltersKeys.DateFrom, formatEn(nextDateFrom));
      onChange(FiltersKeys.DateTo, formatEn(nextDateTo));
    },
    [onChange],
  );

  useEffect(() => {
    if (!dateFrom && initialDateFrom) onChange(FiltersKeys.DateFrom, formatEn(moment(initialDateFrom)));
    if (!dateTo && initialDateTo) onChange(FiltersKeys.DateTo, formatEn(moment(initialDateTo)));
  }, [dateFrom, dateTo, initialDateFrom, initialDateTo, onChange]);

  const { now, weekAgo, yesterday } = useTimePeriods();

  const { weekActive, yesterdayActive } = useMemo(() => {
    const valueToString = (value: Moment): string => {
      if (!value) return null;
      return formatEn(value);
    };

    const nowToString = valueToString(now);
    const weekAgoToString = valueToString(weekAgo);
    const yesterdayToString = valueToString(yesterday);
    const dateToToString = valueToString(moment(dateTo));
    const dateFromToString = valueToString(moment(dateFrom));

    return {
      yesterdayActive: strictlyEqual<string>(yesterdayToString, dateFromToString) && strictlyEqual<string>(dateToToString, nowToString),
      weekActive: strictlyEqual<string>(dateFromToString, weekAgoToString) && strictlyEqual<string>(dateToToString, nowToString),
    };
  }, [dateFrom, dateTo, now, weekAgo, yesterday]);

  return (
    <CSDDataRangePickerAntdWithBtns
      label={t('date')}
      labelVariant="subtitle2"
      value={[dateFrom ? moment(dateFrom) : null, dateTo ? moment(dateTo) : null]}
      onChange={handleChange}
      format={DATE_FORMAT.FORMAT_RU_POINT}
      disabledDate={current => current && moment().isBefore(current)}
      btnsSetup={[
        {
          label: 'dates.yesterday',
          isActive: yesterdayActive,
          action: () => handleChange([yesterday, now]),
        },
        {
          label: 'dates.week',
          isActive: weekActive,
          action: () => handleChange([weekAgo, now]),
        },
      ]}
    />
  );
};

export default memo(FilterDates);
