/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReducersNames } from '@store/constants';
import { ViewMode } from '@app/v2/shared/enums';

interface State {
  usersSets: Filters.Set[];
  mode: ViewMode;
}

const initialState: State = {
  usersSets: null,
  mode: ViewMode.Default,
};

const newUserSetsSlice = createSlice({
  name: ReducersNames.newUsersSets,
  initialState,
  reducers: {
    setUserSets(state: State, { payload }: PayloadAction<Filters.Set[]>) {
      state.usersSets = payload;
    },
    setUserSetMode(state: State, { payload }: PayloadAction<ViewMode>) {
      state.mode = payload;
    },
  },
});

export const newUserSetsActions = newUserSetsSlice.actions;

export default newUserSetsSlice.reducer;
