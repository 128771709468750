import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';

interface Props extends I18N.TranslationFunction<'filters'> {
  hidden: boolean;
  onCansel: Common.VoidCallBack;
  onSave: Common.VoidCallBack;
}

const CSDPresetCreateActions = ({ hidden, onCansel, onSave, t }: Props) => {
  return (
    <>
      {hidden && (
        <Box className="presetActions">
          <Button variant="outlined" onClick={onCansel}>
            {t('actions.cancel')}
          </Button>
          <Button onClick={onSave}>{t('actions.save')}</Button>
        </Box>
      )}
    </>
  );
};

export default withTranslation('filters')(memo(CSDPresetCreateActions));
