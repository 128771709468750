export enum WeatherLayers {
  PRECIP = 'Осадки',
  SUM_PRECIP = 'Суммарные осадки',
  WEATHER_HAZARDS = 'Опасные явления',
  WIND_DIRECTION = 'Направление ветра',
  WIND_VELOCITY = 'Скорость ветра',
  CLOUD_TOP = 'Верхняя граница облачности',
  NONE = '',
}

export enum WeatherLayersTooltips {
  PRECIP = 'weatherOptions.precipitation',
  SUM_PRECIP = 'weatherOptions.sumPrecipitation',
  WEATHER_HAZARDS = 'weatherOptions.weatherHazards',
  WIND_DIRECTION = 'weatherOptions.windDirection',
  WIND_VELOCITY = 'weatherOptions.windVelocity',
  CLOUD_TOP = 'weatherOptions.cloudTop',
  NONE = '',
}
