import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DISPLAYED_IMAGES_SLICE_END, DISPLAYED_IMAGES_SLICE_START, ENTER_TOOLTIP_DELAY, FULL_WIDTH } from '@app/v2/shared/constants';
import CSDSnapshot from '@app/v2/shared/ui/custom/CSDSnapshot/CSDSnapshot';
import CSDImageTooltip from '@app/v2/shared/ui/custom/CSDImageTooltip/CSDImageTooltip';

type Props = {
  images: string[];
  sliced?: boolean;
  tooltipDelay?: number;
  isWrapping?: boolean;
};

type StyleProps = {
  isWrapping?: boolean;
  sliced?: boolean;
};

const NoteImagesViewer = ({ images, sliced, tooltipDelay = ENTER_TOOLTIP_DELAY, isWrapping = false }: Props) => {
  const { t } = useTranslation('map', { keyPrefix: 'notes' });

  const classes = useStyles({ isWrapping, sliced });

  if (images.length === 0) {
    return <>{t('no')}</>;
  }
  const displayedImages = sliced ? images.slice(DISPLAYED_IMAGES_SLICE_START, DISPLAYED_IMAGES_SLICE_END) : images;

  return (
    <Box
      data-testid="note-images-viewer-box"
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      width={FULL_WIDTH}
      className={classes.containerStyle}
    >
      {displayedImages.map(image => (
        <CSDImageTooltip
          key={`image-tooltip-${image}`}
          placement="left-end"
          title={<CSDSnapshot url={`${image}`} />}
          enterDelay={tooltipDelay}
          hidden={false}
        >
          <IconButton key={image}>
            <img src={image} alt="" className={classes.imagesStyle} />
          </IconButton>
        </CSDImageTooltip>
      ))}
    </Box>
  );
};

const useStyles = makeStyles<Theme, StyleProps>({
  containerStyle: ({ isWrapping }) => ({
    flexWrap: isWrapping ? 'wrap' : 'nowrap',
    gap: 'clamp(0.5rem, 25%, 1rem)',
  }),
  imagesStyle: ({ sliced }) => ({
    width: sliced ? '2.875rem' : '3.5rem',
    height: sliced ? '2.875rem' : '3.5rem',
    objectFit: 'cover',
    borderRadius: '0.625rem',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  }),
});

export default NoteImagesViewer;
