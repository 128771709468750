import React, { memo, MutableRefObject, ReactNode, forwardRef } from 'react';
import { FixedSizeList } from 'react-window';
import { Skeleton, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import clsx from 'clsx';
import { strictlyEqual } from '@app/v2/shared/helpers';
import { Events } from '@app/v2/shared/enums';
import { getScrollStyles } from '@theme/themeSettings/componentSettings';
import { useEventListener } from '@app/v2/shared/hooks';
import CSDSnapShot from './CSDSnapShot';
import { SNAPSHOT_CARD_WIDTH, SNAPSHOT_CARD_HEIGHT } from './constants';

enum Mode {
  Simple,
  Virtual,
}

interface Virtualization {
  listRef: MutableRefObject<FixedSizeList>;
  containerWidth: number;
}

interface Props {
  virtualization?: Virtualization;
  stationId: number;
  setOfSnapShots: Video.Snapshot[];
  activeSnapShot: Video.Snapshot;
  onChangeActiveSnapShot?: (snapShot: Video.Snapshot) => void;
  loading: boolean;
}

const CSDSnapShotsPanel = (
  { virtualization, stationId, setOfSnapShots = [], activeSnapShot, onChangeActiveSnapShot, loading }: Props,
  containerRef,
) => {
  const classes = useStyles({ activeSnapShot });

  const viewMode = virtualization ? Mode.Virtual : Mode.Simple;

  const handleWheel = event => {
    const displacementCoefficient = 30;

    event.preventDefault();
    containerRef?.current?.scrollBy({
      left: event?.deltaY < 0 ? -displacementCoefficient : displacementCoefficient,
    });
  };

  useEventListener(Events.Wheel, handleWheel, containerRef?.current);

  const content: Record<Mode, ReactNode> = {
    [Mode.Simple]: (
      <Stack ref={containerRef} direction="row" height="10.725rem" className={clsx(classes.simpleContainer, classes.simpleContainer)}>
        {!!setOfSnapShots?.length &&
          setOfSnapShots.map((snapShot, index) => (
            <CSDSnapShot
              key={snapShot.url}
              isFirst={!index}
              snapShot={snapShot}
              stationId={stationId}
              onClick={onChangeActiveSnapShot}
              isLast={strictlyEqual(setOfSnapShots.length, index + 1)}
              isActive={strictlyEqual(activeSnapShot?.url, snapShot.url)}
            />
          ))}
      </Stack>
    ),
    [Mode.Virtual]: (
      <FixedSizeList
        layout="horizontal"
        outerRef={containerRef}
        itemData={setOfSnapShots}
        height={SNAPSHOT_CARD_HEIGHT}
        ref={virtualization?.listRef}
        itemSize={SNAPSHOT_CARD_WIDTH}
        className={classes.scroll}
        itemCount={setOfSnapShots.length}
        width={virtualization?.containerWidth}
      >
        {({ index, style }) => (
          <CSDSnapShot
            styles={style}
            isFirst={!index}
            stationId={stationId}
            key={setOfSnapShots[index]?.url}
            snapShot={setOfSnapShots[index]}
            onClick={onChangeActiveSnapShot}
            isLast={strictlyEqual(setOfSnapShots.length, index + 1)}
            isActive={strictlyEqual(activeSnapShot?.url, setOfSnapShots[index]?.url)}
          />
        )}
      </FixedSizeList>
    ),
  };

  return (
    <>{loading ? <Skeleton variant="rectangular" width="100%" height="10.725rem" sx={{ borderRadius: '0.5rem' }} /> : <>{content[viewMode]}</>}</>
  );
};

const useStyles = makeStyles<Theme, { activeSnapShot: Video.Snapshot }>(({ palette: { mode, text } }) => ({
  simpleContainer: {
    overflow: 'auto',
    width: '100%',
  },
  scroll: {
    ...getScrollStyles(mode, text),
  },
}));

export default memo(forwardRef<HTMLDivElement, Props>(CSDSnapShotsPanel));
