import React, { memo } from 'react';
import { Box, Icon, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import icons from '@app/assets/iconFont';

const PlaylistFormActionPanel = ({
  id,
  onRemove,
  onSwapToTop,
  fieldsLength,
  onSwapToBottom,
  isOnePlaylistItem,
}: Pick<Scoreboards.PlaylistsFormProps, 'id' | 'fieldsLength' | 'onRemove' | 'onSwapToTop' | 'onSwapToBottom' | 'isOnePlaylistItem'>) => {
  const classes = useStyles();

  return (
    <Box className={classes.actions}>
      {!isOnePlaylistItem && id + 1 < fieldsLength && (
        <IconButton onClick={onSwapToBottom}>
          <Icon>{icons.downArrow}</Icon>
        </IconButton>
      )}

      {!isOnePlaylistItem && !!id && (
        <IconButton onClick={onSwapToTop}>
          <Icon>{icons.upArrow}</Icon>
        </IconButton>
      )}

      <IconButton onClick={onRemove}>
        <Icon>{icons.close}</Icon>
      </IconButton>
    </Box>
  );
};

const useStyles = makeStyles<Theme>({
  actions: {
    gap: '0.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export default memo(PlaylistFormActionPanel);
