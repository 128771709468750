import React from 'react';
import { Tabs, Tab, Box, Icon, Stack, Typography } from '@mui/material';
import { CSDTab } from '@app/modules/kit-module/shared/ui';
import { basicTabs, customTabsWithIcons, csdTab } from './example';
import CSDKitDocBlock from '../../../shared/ui/CSDKitDocBlock/CSDKitDocBlock';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CsdTabs = () => {
  const [bacisValue, setBasicValue] = React.useState<number>(0);
  const [customValue, setCustomValue] = React.useState<number>(0);
  const [csdTabValue, setCSDTabValue] = React.useState<number>(0);
  const handleChangeBasic = (event: React.SyntheticEvent, newValue: number) => {
    setBasicValue(newValue);
  };
  const handleChangeCustom = (event: React.SyntheticEvent, newValue: number) => {
    setCustomValue(newValue);
  };
  const handleChangeCsd = (event: React.SyntheticEvent, newValue: number) => {
    setCSDTabValue(newValue);
  };
  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="Custom tabs with Icons" codeExample={basicTabs}>
        <Box>
          <Tabs value={bacisValue} onChange={handleChangeBasic} aria-label="basic tabs example">
            <Tab label="Метеоданные" {...a11yProps(0)} />
            <Tab label="Изменения погоды" {...a11yProps(1)} />
            <Tab label="Метеолокаторы" {...a11yProps(2)} />
            <Tab label="Карта" {...a11yProps(3)} />
            <Tab label="Заметки" {...a11yProps(4)} />
          </Tabs>
        </Box>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Custom tabs with Icons and sx styles" codeExample={customTabsWithIcons}>
        <Box sx={{ width: '100%' }}>
          <Box>
            <Tabs value={customValue} onChange={handleChangeCustom} aria-label="custom tabs example">
              <Tab
                icon={<Icon>data</Icon>}
                sx={{ boxShadow: '0px -2px 5px 0px #34343433', marginTop: '5px', minWidth: 195 }}
                label="Метеоданные"
                {...a11yProps(0)}
              />
              <Tab
                icon={<Icon>play</Icon>}
                sx={{ boxShadow: '0px -2px 5px 0px #34343433', marginTop: '5px', minWidth: 195 }}
                label="Изменения погоды"
                {...a11yProps(1)}
              />
              <Tab
                icon={<Icon>radar</Icon>}
                sx={{ boxShadow: '0px -2px 5px 0px #34343433', marginTop: '5px', minWidth: 195 }}
                label="Метеолокаторы"
                {...a11yProps(2)}
              />
              <Tab
                icon={<Icon>map</Icon>}
                sx={{ boxShadow: '0px -2px 5px 0px #34343433', marginTop: '5px', minWidth: 195 }}
                label="Карта"
                {...a11yProps(3)}
              />
              <Tab
                icon={<Icon>comment</Icon>}
                sx={{ boxShadow: '0px -2px 5px 0px #34343433', marginTop: '5px', minWidth: 195 }}
                label="Заметки"
                {...a11yProps(4)}
              />
            </Tabs>
          </Box>
          <Box sx={{ boxShadow: '0px -3px 5px 0px #34343433' }}>
            <TabPanel value={customValue} index={0}>
              Метеоданные
            </TabPanel>
            <TabPanel value={customValue} index={1}>
              Изменения погоды
            </TabPanel>
            <TabPanel value={customValue} index={2}>
              Метеостанции
            </TabPanel>
            <TabPanel value={customValue} index={3}>
              Обозначения карты
            </TabPanel>
            <TabPanel value={customValue} index={4}>
              Заметки
            </TabPanel>
          </Box>
        </Box>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Customized Tab (CSD)" codeExample={csdTab}>
        <Box sx={{ width: '100%' }}>
          <Box>
            <Tabs value={csdTabValue} onChange={handleChangeCsd} aria-label="csd tabs example">
              <CSDTab
                icon={<Icon>data</Icon>}
                sx={{ boxShadow: '0px -2px 5px 0px #34343433', marginTop: '5px', minWidth: 195 }}
                label="Метеоданные"
                {...a11yProps(0)}
              />
              <CSDTab
                icon={<Icon>play</Icon>}
                sx={{ boxShadow: '0px -2px 5px 0px #34343433', marginTop: '5px', minWidth: 195 }}
                label="Изменения погоды"
                {...a11yProps(1)}
              />
              <CSDTab
                icon={<Icon>radar</Icon>}
                sx={{ boxShadow: '0px -2px 5px 0px #34343433', marginTop: '5px', minWidth: 195 }}
                label="Метеолокаторы"
                {...a11yProps(2)}
              />
              <CSDTab
                icon={<Icon>map</Icon>}
                sx={{ boxShadow: '0px -2px 5px 0px #34343433', marginTop: '5px', minWidth: 195 }}
                label="Карта"
                {...a11yProps(3)}
              />
              <CSDTab
                icon={<Icon>comment</Icon>}
                sx={{ boxShadow: '0px -2px 5px 0px #34343433', marginTop: '5px', minWidth: 195 }}
                label="Заметки"
                {...a11yProps(4)}
              />
            </Tabs>
          </Box>
        </Box>
      </CSDKitDocBlock>
    </Stack>
  );
};

export default CsdTabs;
