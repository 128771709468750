export const datePickerAntdExample = `<CSDDataPickerAntd />`;
export const datePickerAntdWithTimeExample = `<CSDDataPickerAntd showTime />`;

export const datePickerRangeAntdExample = `<CSDDataRangePickerAntd />`;
export const datePickerRangeAntdWithTimeExample = `<CSDDataRangePickerAntd showTime />`;

export const datePickersAntdWithLabelExample = `<CSDDataRangePickerAntd label="Дата" />
<CSDDataRangePickerAntd label="Период даты" />`;

export const datePickerRangeAntdWithBtnsExample = `<CSDDataRangePickerAntdWithBtns
  label="Дата"
  btnsSetup={[
    {
      label: 'Сегодня-завтра',
      isActive: true,
      action: () => {},
    },
    {
      label: 'Вчера',
      isActive: false,
      action: () => {},
    },
    {
      label: 'Неделя',
      isActive: false,
      action: () => {},
    },
  ]}
/>`;
