import { ReactNode } from 'react';

const VIRTUALIZE_LISTING_DEFAULT_CACHE_LIMIT = 200;

class VirtualizedCache {
  private data: Map<string, ReactNode> = new Map();

  private readonly cacheLimit: number;

  constructor(cacheLimit?: number) {
    this.cacheLimit = cacheLimit || VIRTUALIZE_LISTING_DEFAULT_CACHE_LIMIT;
  }

  public getFromCache(id: string): ReactNode | null {
    if (!this.data.has(id)) return null;

    return this.data.get(id);
  }

  public setToCache({ id, item }: { id: string; item: ReactNode }): void {
    if (this.data.size >= this.cacheLimit) this.clenCache();

    if (this.data.has(id)) return;

    this.data.set(id, item);
  }

  public getCache(): Map<string, ReactNode> {
    return this.data;
  }

  public clenCache(): void {
    if (!this.data.size) return;

    this.data.clear();
  }
}

export default VirtualizedCache;
