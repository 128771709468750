import { TFunction } from 'react-i18next';
import moment from 'moment/moment';
import Highcharts, { XAxisOptions } from 'highcharts';
import { Moment } from 'moment';
import { DATE_FORMAT, DATE_PERIOD } from '@app/v2/shared/constants';
import { COLORS } from '@theme/constants';
import { STEP_OFFSET, offsetKeys } from '../constants';

interface XAxisParameters {
  dates: string[];
  keys: string[];
  datePrognosisType: Meteo.PrognosisType;
  chartsT: TFunction<'charts'>;
  isFixed: boolean;
  currentIndex: number;
  convertDataToMomentWithOffeset: Common.MomentInputCb;
}

export default function prepareXAxisParameters({
  dates,
  datePrognosisType,
  chartsT,
  isFixed,
  keys,
  currentIndex,
  convertDataToMomentWithOffeset,
}: XAxisParameters): Highcharts.XAxisOptions[] {
  const minDate = convertDataToMomentWithOffeset(dates[0]);
  const maxDate = convertDataToMomentWithOffeset(dates[dates.length - 1]);

  const commonSettings: Partial<XAxisOptions> = {
    min: minDate.valueOf(),
    max: maxDate.valueOf(),
    type: 'datetime',
    startOnTick: false,
    showFirstLabel: true,
  };

  const plotBand = (key: keyof Meteo.PrognosisType) => {
    const data = datePrognosisType[key];

    if (!data || !data.length) return {};

    const fromValueIndex = dates.findIndex(v => moment(v).isSame(data[0]));
    const to = dates.find(v => moment(v).isSame(data[data.length - 1]));

    const fromValueForecast = convertDataToMomentWithOffeset(dates[fromValueIndex - 1]).valueOf();
    const fromValueRoadcast = convertDataToMomentWithOffeset(dates[fromValueIndex]).valueOf();
    const toValue = convertDataToMomentWithOffeset(to).valueOf();

    const commonOptions = {
      rotation: 90,
      useHTML: true,
      align: 'right',
      x: -30,
      style: {
        fontSize: '13px',
      },
    };

    const result: Record<keyof Meteo.PrognosisType, Record<string, unknown>> = {
      forecast: {
        from: fromValueForecast,
        to: toValue,
        label: {
          text: chartsT('meteo.forecast'),
          x: -30,
          y: 60,
          ...commonOptions,
        },
      },
      roadcast: {
        from: fromValueRoadcast,
        to: toValue,
        color: '#e6ebe5',
        label: {
          text: chartsT('meteo.specializedForecast'),
          y: 95,
          ...commonOptions,
        },
      },
    };

    return result[key];
  };

  const crosshair: Pick<Highcharts.XAxisOptions, 'crosshair'> = !isFixed
    ? { crosshair: { color: COLORS.secondaryMain, dashStyle: 'Solid', width: 3 } }
    : {};

  const plotLines: Pick<Highcharts.XAxisOptions, 'plotLines'> = isFixed
    ? {
        plotLines: [
          {
            color: COLORS.secondaryMain,
            dashStyle: 'Solid',
            width: 3,
            value: convertDataToMomentWithOffeset(dates[currentIndex])?.valueOf(),
            zIndex: 10,
          },
        ],
      }
    : {};

  const settings = calculateTicksSettings(maxDate, minDate);

  return [
    {
      tickInterval: settings.bottomValue,
      minRange: DATE_PERIOD.HOUR,
      tickColor: COLORS.black,
      plotBands: [plotBand('forecast'), plotBand('roadcast')],
      ...commonSettings,
      ...crosshair,
      ...plotLines,
      offset: offsetKeys.filter(key => keys.includes(key)).length * STEP_OFFSET,
      labels: {
        formatter({ value }) {
          return `<span style="font-weight: 400; font-size: 12px; color: black">${convertDataToMomentWithOffeset(value).format(
            settings.bottomValueFormat,
          )}</span>`;
        },
      },
    },
    {
      opposite: true,
      tickInterval: settings.topValue,
      ...commonSettings,
      labels: {
        align: 'left',
        step: 1,
        formatter({ value }) {
          return `<span style="font-weight: 700; font-size: 16px; color: black">${convertDataToMomentWithOffeset(value).format(
            settings.topValueFormat,
          )}</span>`;
        },
      },
    },
  ];
}

interface TickSettings {
  duration: number;
  topValue: number;
  topValueFormat: string;
  bottomValue: number;
  bottomValueFormat: string;
}

export function calculateTicksSettings(maxDate: Moment, minDate: Moment): TickSettings {
  const defaultSettings: TickSettings = {
    duration: 0,
    topValue: DATE_PERIOD.DAY,
    topValueFormat: 'DD.MM',
    bottomValue: 2 * DATE_PERIOD.HOUR,
    bottomValueFormat: DATE_FORMAT.FORMAT_RU_HOURS,
  };

  if (!maxDate || !minDate) return defaultSettings;

  const diffDuration = moment.duration(maxDate.diff(minDate));

  const isMoreThenHalfAYear = Math.floor(diffDuration.asMonths()) > 6;

  const tickIntervalMapper: Record<'year' | 'month' | 'day', TickSettings> = {
    year: {
      duration: diffDuration.asYears(),
      topValue: DATE_PERIOD.YEAR,
      topValueFormat: 'YYYY',
      bottomValue: DATE_PERIOD.MONTH,
      bottomValueFormat: 'MM',
    },
    month: {
      duration: diffDuration.asMonths(),
      topValue: DATE_PERIOD.MONTH,
      topValueFormat: 'MM.YY',
      bottomValue: isMoreThenHalfAYear ? DATE_PERIOD.WEEK : DATE_PERIOD.DAY,
      bottomValueFormat: isMoreThenHalfAYear ? 'WW' : 'DD',
    },
    day: {
      duration: diffDuration.asDays(),
      topValue: DATE_PERIOD.DAY,
      topValueFormat: 'DD.MM',
      bottomValue: 2 * DATE_PERIOD.HOUR,
      bottomValueFormat: DATE_FORMAT.FORMAT_RU_HOURS,
    },
  };

  const settings = Object.values(tickIntervalMapper).find(({ duration }) => duration > 1);

  return settings ?? defaultSettings;
}
