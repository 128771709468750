import React from 'react';
import { useHistory } from 'react-router-dom';
import { CSDIconPanelPaper } from '@app/modules/kit-module/shared/ui';
import { configSettingsButtonArr } from '@app/v2/shared/constants';
import { useCheckAccess } from '@app/v2/shared/hooks';
import useSearchQuery from '@app/core/hooks/useSearchQuery';
import CSDScoreboardLinkItem from './CSDScoreboardLinkItem';

const CSDScoreboardLinks = () => {
  const history = useHistory();
  const { accessChecker } = useCheckAccess();
  const search = useSearchQuery();

  return (
    <CSDIconPanelPaper>
      {configSettingsButtonArr
        .filter(({ perimeters }) => accessChecker(perimeters))
        .map(link => (
          <CSDScoreboardLinkItem
            key={link.path}
            link={link}
            onChangePage={() => history.push({ pathname: link.path, search })}
            currentPath={history.location.pathname}
          />
        ))}
    </CSDIconPanelPaper>
  );
};

export default CSDScoreboardLinks;
