import React from 'react';
import { useTranslation } from 'react-i18next';
import { CSDChipsBlock } from '@app/modules/kit-module/shared/ui';
import icons from '@app/assets/iconFont';
import { FiltersWarningsConfig } from '@app/v2/shared/configs';
import { FiltersAlertStatus, FiltersKeys } from '@app/v2/shared/enums';

const CSDFilterTypeOfWarning = ({ filters, currentPage, onChange }: Filters.AdditionalFiltersProps) => {
  const { t } = useTranslation('filters');

  const handleChange = (nextValue: FiltersAlertStatus) => {
    const nextValues = filters?.alertStatus?.includes(nextValue)
      ? filters?.alertStatus.filter(currentValue => nextValue !== currentValue)
      : [...filters?.alertStatus, nextValue];

    onChange(FiltersKeys.AlertStatus, nextValues);
  };

  return (
    <CSDChipsBlock
      size="small"
      titleVariant="subtitle2"
      title={t('typeOfWarning.label')}
      chipsConfig={FiltersWarningsConfig.filter(({ hiddenForPages }) => !hiddenForPages?.includes(currentPage?.name)).map(
        ({ label, value, color, dataTestId }) => ({
          id: label,
          label,
          onClick: () => handleChange(value),
          isActive: filters?.alertStatus?.includes(value),
          icon: icons.dot,
          iconColor: color,
          dataTestId,
        }),
      )}
    />
  );
};

export default CSDFilterTypeOfWarning;
