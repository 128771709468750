export const avatarsImages = `<Avatar alt="Remy Sharp" src="https://picsum.photos/200/300?random=1" />
<Avatar alt="Travis Howard" src="https://picsum.photos/200/300?random=2" />
<Avatar alt="Cindy Baker" src="https://picsum.photos/200/300?random=3" />`;
export const letterAvatars = `<Avatar>H</Avatar>
<Avatar sx={{ bgcolor: deepOrange[500] }}>N</Avatar>
<Avatar sx={{ bgcolor: deepPurple[500] }}>OP</Avatar>`;
export const sizesAvatars = `<Avatar 
  src="https://picsum.photos/200/300?random=1"
  sx={{ bgcolor: deepOrange[500], width: 24, height: 24 }}
/>
<Avatar src="https://picsum.photos/200/300?random=1" sx={{ bgcolor: deepOrange[500] }}/>
<Avatar src="https://picsum.photos/200/300?random=1"
  sx={{ bgcolor: deepOrange[500], width: 56, height: 56  }}
/>`;
export const iconAvatars = `<Avatar>
  <FolderIcon />
</Avatar>
<Avatar sx={{ bgcolor: pink[500] }}>
  <PageviewIcon />
</Avatar>
<Avatar sx={{ bgcolor: green[500] }}>
  <AssignmentIcon />
</Avatar>`;
export const avatarVariants = `<Avatar sx={{ bgcolor: deepOrange[500] }} variant="square">
  N
</Avatar>
<Avatar sx={{ bgcolor: green[500] }} variant="rounded">
  <AssignmentIcon />
</Avatar>`;
export const avatarGroups = `<AvatarGroup max={4}>
  <Avatar alt="1 Test" src="https://picsum.photos/200/300?random=1" />
  <Avatar alt="2 Test" src="https://picsum.photos/200/300?random=2" />
  <Avatar alt="3 Test" src="https://picsum.photos/200/300?random=3" />
  <Avatar alt="4 Test" src="https://picsum.photos/200/300?random=4" />
  <Avatar alt="5 Test" src="https://picsum.photos/200/300?random=5" />
</AvatarGroup>`;
export const avatarGroupsWithTotal = `<AvatarGroup total={24}>
  <Avatar alt="1 Test" src="https://picsum.photos/200/300?random=1" />
  <Avatar alt="2 Test" src="https://picsum.photos/200/300?random=2" />
  <Avatar alt="3 Test" src="https://picsum.photos/200/300?random=3" />
  <Avatar alt="4 Test" src="https://picsum.photos/200/300?random=4" />
</AvatarGroup>`;
