import React, { MouseEvent, useMemo } from 'react';
import { Box, Icon, Badge, BoxProps, BadgeProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Polygon } from '@app/assets/system';
import { boxShadowLight } from '@theme/styles';
import icons from '@app/assets/iconFont';
import { AlertStatuses, DataTestIds } from '@app/v2/shared/enums';
import { MarkerColors } from '@app/v2/shared/constants';
import CSDMapMarkerNotifications from './CSDMapMarkerNotifications';
import CSDIconMarkerWithTooltip from '../CSDIconMarkerWithTooltip';

const WrapperStyledBox = styled(Box)<BoxProps>({
  minHeight: '45px',
  ':hover': {
    '& > div': {
      display: 'flex',
    },
  },
});

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: '0.188rem 0.313rem',
  borderRadius: '0.5rem',
  zIndex: 300,
  position: 'relative',
  boxShadow: boxShadowLight,
  width: 'fit-content',
}));

const StyledBadge = styled(Badge)<BadgeProps>({
  '& .MuiBadge-badge': {
    minWidth: '10px',
    height: '10px',
    fontSize: '9px',
    padding: 0,
    top: '5px',
    right: '2px',
  },
});

type Props = {
  iconName?: string;
  badgeColor?: string;
  markerType?: AlertStatuses;
  className?: string;
  notifications?: {
    iconName: string;
    tooltip?: string;
  }[];
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
};

const CSDMapMarkerBasic = ({ iconName, notifications = [], badgeColor = '#439BFF', markerType, className, onClick = () => {} }: Props) => {
  const currentAlertStatus = useMemo(() => MarkerColors[markerType], [markerType]);
  const badgeContent = useMemo(() => (notifications.length > 1 ? notifications.length : null), [notifications]);

  return (
    <div>
      {notifications.length ? (
        <>
          <WrapperStyledBox
            sx={{
              '& > svg > path': ({ palette: { common } }) => ({
                fill: currentAlertStatus?.background || common.white,
                stroke: currentAlertStatus?.background || common.black,
              }),
            }}
            className={className}
            onClick={onClick}
            data-testid={DataTestIds.MapMarkerBasicAdvanceActionButton}
          >
            <StyledBox
              sx={{
                backgroundColor: ({ palette: { common } }) => currentAlertStatus?.background || common.white,
                border: currentAlertStatus ? 'none' : '0.063rem solid black',
              }}
            >
              <StyledBadge
                badgeContent={badgeContent}
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: badgeColor,
                    zIndex: 10,
                  },
                }}
              >
                <CSDIconMarkerWithTooltip
                  iconName={iconName}
                  notifications={notifications}
                  sx={{ color: ({ palette: { common } }) => currentAlertStatus?.text || common.black }}
                />
              </StyledBadge>
            </StyledBox>
            {notifications.length > 1 && <CSDMapMarkerNotifications notifications={notifications} statusColor={currentAlertStatus?.background} />}
            <Polygon style={{ position: 'absolute', top: '1.75rem', left: '0.563rem' }} />
          </WrapperStyledBox>
        </>
      ) : (
        <Icon
          className={className}
          data-testid={DataTestIds.MapMarkerBasicSimpleActionButton}
          sx={{ color: currentAlertStatus?.background }}
          onClick={onClick}
        >
          {icons.pinErr}
        </Icon>
      )}
    </div>
  );
};

export default CSDMapMarkerBasic;
