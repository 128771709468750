import React from 'react';
import { Box } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DataTestIds } from '@app/v2/shared/enums';
import CSDMeteoChartTooltip from './CSDMeteoChartTooltip';

interface Props<Options> {
  options: Options;
  className?: string;
  classes?: Record<string, unknown>;
  chartsData?: Omit<Meteo.ChartPointParameters, 'chartId'>;
  onCloseTooltip?: Common.VoidCallBack;
  callback?: Highcharts.ChartCallbackFunction;
  openDialog?: Common.VoidCallBack;
}

const CSDMeteoChart = <Options extends Record<string, unknown>>({
  options,
  classes,
  className,
  chartsData,
  onCloseTooltip,
  callback,
  openDialog,
}: Props<Options>) => {
  return (
    <Box
      data-testid={DataTestIds.MeteoChartContainer}
      className={className}
      sx={{
        height: '100%',
        width: '100%',
        position: 'relative',
        ...classes,
      }}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={{ ...options, accessibility: { enabled: false } }}
        callback={callback}
        immutable
        containerProps={{
          style: {
            height: '100%',
          },
        }}
      />
      {chartsData?.isFixed && (
        <CSDMeteoChartTooltip {...chartsData} isFixedOnForecast={chartsData?.isFixedOnForecast} openDialog={openDialog} onClose={onCloseTooltip} />
      )}
    </Box>
  );
};

export default CSDMeteoChart;
