import React from 'react';
import CSDUpdateConstructorBtn from './CSDUpdateMeteoChartBtn';
import CSDConstructorPopover from '../CSDConstructorPopover/CSDConstructorPopover';

interface Props {
  profileId: string;
  chartId: string;
  chartsSetup: Meteo.ChartsSetup;
}

const CSDCreateMeteoChartSetup = (props: Props) => {
  return <CSDConstructorPopover<HTMLButtonElement> AnchorNode={CSDUpdateConstructorBtn} {...props} />;
};

export default CSDCreateMeteoChartSetup;
