import { FORECAST_ALIASES } from '@app/v2/features/notifications/constants';
import { Notification } from '@app/v2/features/notifications/types';
import splitArrayByCondition from '@app/v2/shared/helpers/splitArrayByCondition';

export const getSplitGlobalSetting = (data: Notification[]): { forecastSettings: Notification[]; nowSettings: Notification[] } => {
  const { itemsWithCondition: forecastSettings, otherItems: nowSettings } = splitArrayByCondition(data, item =>
    FORECAST_ALIASES.includes(item.alias),
  );
  return { forecastSettings, nowSettings };
};
// remove this line when some other helpers will added
export default getSplitGlobalSetting;
