import FETCH_ROADS from './fetchRoads.graphql';
import FETCH_ROAD_PARTS from './fetchRoadParts.graphql';
import FETCH_ROAD_SEGMENTS from './fetchRoadSegments.graphql';
import FETCH_PLAN_GRAPH_ROADS from './fetchPlanGraphRoads.graphql';
import FETCH_ROAD_SEGMENTS_SELECTIONS from './fetchRoadSegmentsSelections.graphql';

const roadQueries = {
  roads: FETCH_ROADS,
  roadParts: FETCH_ROAD_PARTS,
  roadSegments: FETCH_ROAD_SEGMENTS,
  roadPlanGraph: FETCH_PLAN_GRAPH_ROADS,
  roadSegmentsSelections: FETCH_ROAD_SEGMENTS_SELECTIONS,
};

export default roadQueries;
