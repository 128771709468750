import { createContext } from 'react';
import { NotificationProfilesContext } from '../types';

const NotificationProfileContext = createContext<NotificationProfilesContext>({
  currentProfile: null,
  profiles: [],
  isEdit: false,
  isLoading: false,
  error: null,
  setCurrentProfile: () => {},
  toggleProfileStatus: () => {},
  changeFormField: () => {},
  changeTitle: () => {},
  changeCheckbox: () => {},
  resetChanges: () => {},
  removeProfile: () => {},
  addNewProfile: () => {},
  saveProfile: () => {},
  saveChanges: () => {},
  reset: () => {},
});

export default NotificationProfileContext;
