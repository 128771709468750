import { i18n } from '@app/v2/shared/localization';
import { ChartsProfilesTypes, MeteoChartProfileType, ProfilesTypes } from '@app/v2/shared/enums';
import { getDefaultProfileSettings, getDefaultMeteoChartProfileSettings } from '../helpers/profiles';

export const INITIAL_PROFILE_ID = 0;
export const MAX_PROFILES_AMOUNT = 6;

export const INITIAL_PROFILES: Partial<Record<ProfilesTypes, Profiles.CommonProfile>> = {
  [ProfilesTypes.meteo]: {
    id: INITIAL_PROFILE_ID,
    isDefault: true,
    items: getDefaultProfileSettings(ProfilesTypes.meteo),
    description: i18n.t('constants:initialProfiles.description'),
    label: i18n.t('constants:initialProfiles.dispatcher'),
  },
  [ProfilesTypes.traffic]: {
    id: INITIAL_PROFILE_ID,
    isDefault: true,
    items: getDefaultProfileSettings(ProfilesTypes.traffic),
    description: i18n.t('constants:initialProfiles.description'),
    label: i18n.t('constants:initialProfiles.dispatcher'),
  },
  [ProfilesTypes.linearGraph]: {
    id: INITIAL_PROFILE_ID,
    isDefault: true,
    items: getDefaultProfileSettings(ProfilesTypes.linearGraph),
    description: i18n.t('constants:initialProfiles.description'),
    label: i18n.t('constants:initialProfiles.defaultProfile'),
  },
  [ProfilesTypes.adaptationToClimateChange]: {
    id: INITIAL_PROFILE_ID,
    isDefault: true,
    items: getDefaultProfileSettings(ProfilesTypes.adaptationToClimateChange),
    description: i18n.t('constants:initialProfiles.description'),
    label: i18n.t('constants:initialProfiles.dispatcher'),
  },
  [ProfilesTypes.sops]: {
    id: INITIAL_PROFILE_ID,
    isDefault: true,
    items: getDefaultProfileSettings(ProfilesTypes.sops),
    description: i18n.t('constants:initialProfiles.description'),
    label: i18n.t('constants:initialProfiles.dispatcher'),
  },
  [ProfilesTypes.stationDetails]: {
    id: INITIAL_PROFILE_ID,
    isDefault: true,
    items: getDefaultProfileSettings(ProfilesTypes.stationDetails),
    description: i18n.t('constants:initialProfiles.description'),
    label: i18n.t('constants:initialProfiles.dispatcher'),
  },
};

export const INITIAL_CHARTS_PROFILES: Record<ChartsProfilesTypes, Meteo.ChartProfile> = {
  [ChartsProfilesTypes.meteo]: {
    id: -1,
    name: i18n.t('constants:initialProfiles.dispatcher'),
    type: MeteoChartProfileType.Default,
    items: getDefaultMeteoChartProfileSettings(),
  },
};
