import React, { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Skeleton } from '@mui/material';
import Hls from 'hls.js';
import { makeStyles } from '@mui/styles';
import { useQuery } from '@apollo/client';
import { showErrorWithoutCodes } from '@app/core/utils/notifications';
import videoQueries from '@app/clients/apollo/requests/queries/video';
import { useVideoPost } from '@app/v2/shared/hooks';

interface Props extends I18N.TranslationFunction<'common'> {
  stationId?: number;
}

const VideoTabVideoContent = ({ stationId, t }: Props) => {
  const classes = useStyles();

  const { handleChangeViewMode } = useVideoPost();

  const { data, loading } = useQuery(videoQueries.videoStreamUri, {
    variables: {
      videoStationId: stationId,
    },
    onError() {
      handleChangeViewMode();
      showErrorWithoutCodes(t('errors.FAILED_CONNECT_CAMERA'));
    },
  });

  useEffect(() => () => handleChangeViewMode(), [handleChangeViewMode]);

  const playerRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (data && !data?.videoStreamUri) {
      handleChangeViewMode();
      showErrorWithoutCodes(t('errors.FAILED_CONNECT_CAMERA'));
    }

    if (Hls.isSupported() && data?.videoStreamUri) {
      const hls = new Hls({
        backBufferLength: 60,
        maxBufferLength: 5,
      });

      hls.attachMedia(playerRef.current);
      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        hls.loadSource(data?.videoStreamUri);
        hls.on(Hls.Events.MANIFEST_PARSED, () => playerRef.current.play());
      });

      hls.on(Hls.Events.ERROR, (event, errorData) => {
        if (errorData.fatal) {
          handleChangeViewMode();
          showErrorWithoutCodes(t('errors.UNABLE_PLAY_VIDEO'));
        }
      });
    }
  }, [handleChangeViewMode, data, t]);

  return (
    <Box className={classes.videoWrapper}>
      {loading ? (
        <Skeleton variant="rectangular" height="100%" width="100%" />
      ) : (
        <video ref={playerRef} controls width="100%" height="100%">
          <track kind="captions" />
          {t('notificationMessages.videoNotSupportYourBrowser')}
        </video>
      )}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  videoWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100% - 6rem)',
  },
}));

export default withTranslation('common')(VideoTabVideoContent);
