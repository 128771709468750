import { useEffect, useRef } from 'react';
import { Events } from '@app/v2/shared/enums';

export default function useEventListener(eventName: Events, handler: (event) => void, element: any = window): void {
  const savedHandler = useRef<(event) => void>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener = (event: Event) => {
      const handlerRef = savedHandler.current;

      if (handlerRef && typeof handlerRef === 'function') {
        handlerRef(event);
      }
    };

    element.addEventListener(eventName, eventListener);

    // eslint-disable-next-line consistent-return
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}
