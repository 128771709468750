import { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import vmsNewQueries from '@app/clients/apollo/requests/queries/vmsNew';
import { filterSelectorsBySize, vmsSelectorsNormalizer } from '@app/v2/shared/helpers';
import otherQueries from '@app/clients/apollo/requests/queries/others';

export default function useVMSSelectors(data?: Common.Size) {
  const { data: configurationsSelectors } = useQuery<Scoreboards.ConfigurationsSelectorsResponse>(vmsNewQueries.configurationSelectors);
  const { data: organizationSelectors } = useQuery<{ organizations: Common.SelectorValues }>(otherQueries.organizations);

  const { refetch } = useQuery<Scoreboards.TemplatesSelectorsResponse>(vmsNewQueries.templatesSelectors, { skip: true });

  const fetchTemplatesSelectors = useCallback(
    async (configId: string, orgId: string) => {
      const { data: refetchData } = await refetch({
        configurationIds: configId ? [Number(configId)] : [],
        organizationId: orgId ? Number(orgId) : null,
      });

      return vmsSelectorsNormalizer(refetchData?.vmsNew.selectors?.templates?.message);
    },
    [refetch],
  );

  return {
    fetchTemplatesSelectors,
    configurationSelectors: filterSelectorsBySize(configurationsSelectors?.vmsNew?.selectors?.configurations?.configurations, data),
    organizationSelectors: organizationSelectors?.organizations ?? [],
  };
}
