import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from '@mui/material';
import { useMutation } from '@apollo/client';
import { Moment } from 'moment';
import client from '@app/clients/axios/client';
import icons from '@app/assets/iconFont';
import vmsNewMutations from '@app/clients/apollo/requests/mutations/vmsNew';
import { DATE_FORMAT } from '@app/v2/shared/constants';
import ErrorMessages from '@app/core/constants/errorsMessages';
import { showError } from '@app/core/utils/notifications';
import { mainConfig } from '@app/v2/shared/configs';

const EXTENSION = 'xlsx';
const BLOB_TYPE = 'application/vnd.ms-excel;charset=UTF-8';

interface Props {
  dateTo: Moment;
  dateFrom: Moment;
  stationId: number;
  disabled: boolean;
}

const SaveHistory = ({ stationId, dateFrom, dateTo, disabled }: Props) => {
  const { t } = useTranslation('scoreboard');

  const linkHref = useRef<string | null>(null);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(linkHref.current);
      linkHref.current = null;
    };
  }, []);

  const [createOPIReport, { data }] = useMutation(vmsNewMutations.stationLogReport.create);

  useEffect(() => {
    if (!stationId) return;

    createOPIReport({
      variables: {
        dateFrom: dateFrom?.format(DATE_FORMAT.FORMAT_EN),
        dateTo: dateTo?.format(DATE_FORMAT.FORMAT_EN),
        stationId,
      },
    });
  }, [dateFrom, dateTo, stationId, createOPIReport]);

  const createReportHandler = async () => {
    const path = data?.vmsNew?.stationLogReport?.create?.message.replace('/', '') || '';

    client
      .get(`${mainConfig.links.downloadHost}${path}`, { responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: BLOB_TYPE }));
        linkHref.current = url;

        const link = document.createElement('a');
        const uuid = Date.now();
        link.href = url;
        link.download = `opi_history_${uuid}.${EXTENSION}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(_ => {
        showError(ErrorMessages.FILE_NOT_DOWNLOADED);
      });
  };

  return (
    <Button startIcon={<Icon>{icons.download}</Icon>} onClick={createReportHandler} disabled={disabled || !stationId}>
      {t('loggerListing.save.btn')}
    </Button>
  );
};

export default SaveHistory;
