import React, { useCallback, useMemo, useRef } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import useTableStyles from '@app/core/hooks/useTableStyles';
import { HeaderCell, HeaderCellRoot, HeaderCellTwo, HeaderCellValue } from '@app/modules/log-module/common/types/ListingTypes';

type Props = {
  headers: HeaderCell[];
  topShift?: number;
  backgroundHeaderColor?: string;
  colorHeader?: string;
  isNoWrapSecondRowCell?: boolean;
};

export function isRootCell(item: HeaderCell): item is HeaderCellRoot {
  return Object.hasOwnProperty.call(item, 'children');
}

const ListingHeader = ({ headers, topShift, backgroundHeaderColor = '#3f51b5 !important', colorHeader, isNoWrapSecondRowCell = true }: Props) => {
  const secondHeaderRowRef = useRef(null);

  const isTwoRow = useMemo(() => headers.some(header => isRootCell(header)), [headers]);
  const classes = useTableStyles({ backgroundHeaderColor, colorHeader });
  const shift = useMemo(
    () => (secondHeaderRowRef?.current?.clientHeight ? topShift - secondHeaderRowRef?.current?.clientHeight : topShift),
    [topShift],
  );

  const getUniqueKey = useCallback((label, index) => `${label}_${index}`, []);

  const preparedHeaders: [HeaderCellTwo[], HeaderCellValue[]] = useMemo(() => {
    if (isTwoRow) {
      return [
        headers.map((item: HeaderCell) => {
          return {
            label: item.label,
            shortLabel: item.shortLabel,
            align: item.align,
            colSpan: isRootCell(item) ? item.children.length : 1,
            rowSpan: isRootCell(item) ? 1 : 2,
            width: item.width,
            minWidth: item.minWidth,
          };
        }),
        headers
          .filter(item => isRootCell(item))
          .map(item => (isRootCell(item) ? item.children : []))
          .flat(),
      ];
    }

    return [[], []];
  }, [headers, isTwoRow]);

  return isTwoRow ? (
    <>
      <TableRow>
        {preparedHeaders[0].map(({ label, shortLabel, align = 'center', colSpan, rowSpan, width, minWidth }, index) => (
          <TableCell
            key={getUniqueKey(label, index)}
            colSpan={colSpan}
            rowSpan={rowSpan}
            style={{ width, minWidth }}
            classes={{ root: classes.headerColumn }}
          >
            <Typography variant="body2" align={align}>
              {shortLabel || label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>

      <TableRow ref={secondHeaderRowRef}>
        {preparedHeaders[1].map(({ label, shortLabel, align = 'center', width, minWidth }, index) => (
          <TableCell key={getUniqueKey(label, index)} style={{ width, minWidth, top: shift }} classes={{ root: classes.headerColumn }}>
            <Typography noWrap={isNoWrapSecondRowCell} variant="body2" align={align}>
              {shortLabel || label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </>
  ) : (
    <TableRow>
      {headers.map(({ label, shortLabel, align = 'center', width, minWidth }, index) => (
        <TableCell key={getUniqueKey(label, index)} style={{ width, minWidth }} classes={{ root: classes.headerColumn }}>
          <Typography noWrap variant="body2" align={align}>
            {shortLabel || label}
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default ListingHeader;
