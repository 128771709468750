import React from 'react';
import { KeyPrefix, useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NotesTypes from '@app/modules/map-module/enums/NotesTypes';
import { CSDSwitcher } from '@app/modules/kit-module/shared/ui';
import useMapProfileContext from '@app/v2/features/mapProfiles/hooks/useMapProfileContext';
import { ProfileMapSettingLabels } from '@app/v2/shared/enums';
import { useActions, useAppSelector } from '@app/v2/shared/hooks';
import { isDefaultMapProfile } from '@app/v2/shared/helpers';
import MapNotesListing from '@app/modules/map-module/components/map-notes-listing/MapNotesListing';
import MapProfilesManager from '../components/MapProfilesManager';
import DisabledWrapperWithTooltip from '../components/DisabledWrapperWithTooltip';

export const notesData: { label: KeyPrefix<'map'>; key: NotesTypes.ALL }[] = [{ label: 'mapCardControl.notes.showAllNotes', key: NotesTypes.ALL }];

const NotesPanel = ({ hideProfileActions }: { hideProfileActions: boolean }) => {
  const classes = useStyles();
  const { t } = useTranslation('map');
  const { setMapNotesCursor, setIsFooterOpen } = useActions();
  const {
    currentActiveProfile,
    profileData: { notes },
    handleChangeProfileCheckbox,
  } = useMapProfileContext();

  const mapNotesCursor = useAppSelector(state => state.map.mapNotesCursor);

  const mapNotesCursorHandler = () => {
    if (mapNotesCursor) {
      setMapNotesCursor(false);
    } else {
      setIsFooterOpen(false);
      setMapNotesCursor(true);
    }
  };

  return (
    <>
      <MapProfilesManager hideProfileActions={hideProfileActions} />
      <DisabledWrapperWithTooltip disabled={isDefaultMapProfile(currentActiveProfile)}>
        <Box className={classes.notesContainer}>
          <Button variant="outlined" color="primary" onClick={() => mapNotesCursorHandler()}>
            {!mapNotesCursor ? t('mapCardControl.buttons.createNote') : t('mapCardControl.buttons.close')}
          </Button>
          <Box className={classes.notesDataContainer}>
            {notesData.map(({ key, label }) => (
              <Box className={classes.notesSwitchContainer} key={key}>
                <CSDSwitcher
                  label={t(label) as string}
                  checked={notes[key].checked}
                  onChange={(_, checked) => handleChangeProfileCheckbox(ProfileMapSettingLabels.NOTES, key, checked)}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </DisabledWrapperWithTooltip>
      <MapNotesListing isHorizontal />
    </>
  );
};

const useStyles = makeStyles(() => ({
  notesContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  notesDataContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginLeft: '2rem',
  },
  notesSwitchContainer: {
    marginRight: '1rem',
  },
}));

export default NotesPanel;
