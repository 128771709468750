import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogTitle } from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import vmsNewQueries from '@app/clients/apollo/requests/queries/vmsNew';
import { CSDDialogActions, CSDInput, CSDSelect } from '@app/modules/kit-module/shared/ui';
import { DialogRef } from '@app/core/providers/DialogProvider';
import { EMPTY_STRING, MAX_SECONDS_OF_MANUAL_MODE_AZPI } from '@app/v2/shared/constants';
import Yup from '@app/core/validation/locale';
import { addFeatureActions } from '@app/v2/shared/utils';
import { filterSelectorsBySize } from '@app/v2/shared/helpers';

const ValuesSchema = Yup.object<Scoreboards.SetManualModeForm>().shape({
  seconds: Yup.string().required(),
  playListId: Yup.string().required(),
});

type Props = Scoreboards.SetManualModeDialogProps & DialogRef;

const CSDSetManualModeDialog = ({ close, playlistId, updateLoading, addPlaylistToStation, stationId, deviceSize, ...featuresActions }: Props) => {
  const classes = useStyles();

  const { t } = useTranslation('scoreboard');

  const { data, loading: selectorsLoading } = useQuery<Scoreboards.PlaylistsSelectorsResponse>(vmsNewQueries.playlistsSelectors);

  const { values, handleChange, validateForm, errors } = useFormik<Scoreboards.SetManualModeForm>({
    initialValues: {
      seconds: EMPTY_STRING,
      playListId: playlistId,
    },
    onSubmit: () => {},
    validationSchema: ValuesSchema,
  });

  const handleSetManualMode = async () => {
    const validationResult = await validateForm();

    if (Object.values(validationResult ?? {}).length) return;

    addFeatureActions(
      addPlaylistToStation({
        variables: {
          stationId,
          playlistId: Number(values.playListId),
          seconds: Number(values.seconds),
        },
      }).then(() => close()),
      featuresActions,
    );
  };

  return (
    <Dialog open maxWidth="sm">
      <DialogTitle>{t('singsAndScoreboard.manual.dialog.title')}</DialogTitle>

      <Box className={classes.content}>
        <CSDSelect
          name="playListId"
          onChange={handleChange}
          value={values.playListId}
          disabled={selectorsLoading}
          label={t('singsAndScoreboard.manual.dialog.form.playlist')}
          items={filterSelectorsBySize(data?.vmsNew?.selectors?.playlists, deviceSize)}
          error={!!errors?.playListId}
          helperText={errors?.playListId ?? EMPTY_STRING}
        />

        <Box className={classes.inputWrapper}>
          <CSDInput
            fullWidth
            type="number"
            name="seconds"
            value={values.seconds}
            error={!!errors?.seconds}
            helperText={errors?.seconds ?? EMPTY_STRING}
            onChange={event => {
              if (Number(event.target.value) >= MAX_SECONDS_OF_MANUAL_MODE_AZPI) return;
              handleChange(event);
            }}
            label={t('singsAndScoreboard.manual.dialog.form.label.seconds')}
            placeholder={t('singsAndScoreboard.manual.dialog.form.placeholder.seconds')}
          />

          {values.seconds && <Box className={classes.durationView}>{moment.duration(values.seconds, 'seconds').humanize()}</Box>}
        </Box>
      </Box>

      <CSDDialogActions doneHandler={handleSetManualMode} closeHandler={close} disabled={updateLoading} />
    </Dialog>
  );
};

const useStyles = makeStyles({
  content: {
    gap: '1rem',
    display: 'flex',
    paddingBottom: '2rem',
    flexDirection: 'column',
  },
  inputWrapper: {
    width: '100%',
    position: 'relative',
  },
  durationView: {
    top: '2.4rem',
    right: '1rem',
    position: 'absolute',
  },
});

export default CSDSetManualModeDialog;
