import React, { memo, useMemo } from 'react';
import { Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CSDFilters, CSDPresetFilters, CSDRoadsSearch } from '@app/v2/features';
import { useLayoutConfig, useNewFilters, useSystem } from '@app/v2/shared/hooks';
import { CSDPaginationSearch, CSDReportsButton } from '@app/modules/kit-module/shared/ui';
import { PresetsBtnsView } from '@app/v2/shared/enums';
import CSDHeaderRoadsSegments from './CSDHeaderRoadsSegments';

const CSDHeaderFilterSection = () => {
  const {
    headerConfig: { isViewReportBtn, isViewRoadsSegments, isViewFiltersPresets, isViewPagination },
  } = useLayoutConfig();

  const classes = useStyles();

  const { isHeaderRollUp } = useSystem();
  const { setFilters, resetFilters, setFiltersToURL } = useNewFilters();

  const featureActions = useMemo(
    () => ({
      delete: {
        success: resetFilters,
      },
      create: {
        success: nextFilters => {
          setFilters(nextFilters as Filters.Filter);
          setFiltersToURL(nextFilters as Filters.Filter);
        },
      },
      update: {
        success: nextFilters => {
          setFilters(nextFilters as Filters.Filter);
          setFiltersToURL(nextFilters as Filters.Filter);
        },
      },
    }),
    [resetFilters, setFilters, setFiltersToURL],
  );

  return (
    <Box className={classes.main}>
      <CSDRoadsSearch />

      <Box className={classes.wrapper}>
        {isViewPagination && isHeaderRollUp && (
          <Box className="pagination">
            <CSDPaginationSearch />
          </Box>
        )}

        {isViewRoadsSegments ? (
          <>
            <CSDHeaderRoadsSegments />
            <Divider orientation="vertical" flexItem className="divider" />
          </>
        ) : (
          <Box className="segmentsContainer" />
        )}

        <Box className="filters">
          <CSDFilters userPresets={<CSDPresetFilters withActions featureActions={featureActions} />} />

          {isViewFiltersPresets && <CSDPresetFilters withSorting featureActions={featureActions} swipe={PresetsBtnsView.Swiper} />}
        </Box>
      </Box>

      {isViewReportBtn && (
        <>
          <Divider orientation="vertical" flexItem className="divider" />

          <Box className="report">
            <CSDReportsButton />
          </Box>
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  main: {
    gap: '0.5rem',
    display: 'flex',

    '& .divider': {
      marginLeft: '-0.5rem',
      marginRight: '0.5rem',
    },

    '& .report': {
      alignSelf: 'end',
      paddingBottom: '0.5rem',
    },
  },
  wrapper: {
    flex: 1,
    width: 0,
    gap: '0.5rem',
    display: 'flex',
    alignItems: 'flex-end',

    '& > .segmentsContainer': {
      flex: 2.5,
      overflow: 'hidden',
      paddingBottom: '0.5rem',
    },

    '& > .pagination': {
      paddingBottom: '0.5rem',
    },

    '& > .filters': {
      flex: 1,
      display: 'flex',
      gap: '0.5rem',
      overflow: 'hidden',
      paddingBottom: '0.5rem',
      height: '100%',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
  },
});

export default memo(CSDHeaderFilterSection);
