import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import { Box, Button, Icon, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { CSDAttentionSvgIcon } from '@app/modules/kit-module/shared/ui';
import { useAppDispatch } from '@app/v2/shared/hooks';
import icons from '@app/assets/iconFont';
import ConfirmDialog from '@app/components/dialogs/confirm-dialog/ConfirmDialog';
import { logout } from '@store/thunks/auth/auth-thunks';
import useDialog from '@app/core/hooks/useDialog';
import accountQueries from '@app/clients/apollo/requests/queries/account';
import AccountSettingsTabPanel from './settings/AccountSettingsTabPanel';
import AccountDocumentsTabPanel from './documents/AccountDocumentsTabPanel';
import { TabNames, TabType } from '../types';

type Props = {
  close?: () => void;
} & I18N.TranslationFunction<'dialogs'>;

const AccountTabs = ({ close = () => {}, t }: Props) => {
  const dispatch = useAppDispatch();
  const openConfirmDialog = useDialog(ConfirmDialog);
  const classes = useStyles();

  const { data } = useQuery<{ billing: { isUnpaid: boolean } }>(accountQueries.unpaid);

  const tabs: Array<TabType> = React.useMemo(
    () => [
      {
        id: 0,
        name: TabNames.settings,
        label: 'accountSettings.tabs.profile.title',
        component: AccountSettingsTabPanel,
        icon: <Icon>{icons.person}</Icon>,
      },
      {
        id: 1,
        name: TabNames.documents,
        label: 'accountSettings.tabs.documents.title',
        component: AccountDocumentsTabPanel,
        icon: data?.billing?.isUnpaid ? <CSDAttentionSvgIcon isAnimated /> : <Icon>{icons.document}</Icon>,
      },
    ],
    [data],
  );

  const [activeTab, setActiveTab] = React.useState<number>(tabs[0].id);

  const handleOnChange = React.useCallback(
    (newValue: number) => {
      setActiveTab(newValue);
    },
    [setActiveTab],
  );

  const handleAccountExit = React.useCallback(() => {
    openConfirmDialog({ question: t('accountSettings.questions.logoutQuestion') }).beforeClose.subscribe(
      async (answerToQuestion: boolean): Promise<void> => {
        answerToQuestion && dispatch(logout());
      },
    );
  }, [openConfirmDialog, dispatch, t]);

  return (
    <Stack direction="row" width="100%" height="100%" overflow="hidden">
      <Stack direction="column" spacing={4} className={classes.sidebar}>
        <Typography variant="h2">{t('accountSettings.CSDtitle')}</Typography>

        <Box className="navBtns">
          {tabs.map(({ label, icon, name, id }, index) => (
            <Button
              key={id}
              fullWidth
              variant="text"
              startIcon={icon}
              className={clsx('navBtn', index === activeTab && 'navBtnActive')}
              onClick={() => handleOnChange(index)}
            >
              {t(label)}
            </Button>
          ))}
        </Box>

        <Button variant="text" fullWidth onClick={handleAccountExit} startIcon={<Icon>{icons.exit}</Icon>} className="exitBtn">
          {t('accountSettings.buttons.exit')}
        </Button>
      </Stack>

      <Stack direction="column" spacing={4} className={classes.content}>
        {tabs.map(({ component: Component, id }, index) => {
          return activeTab === index ? <Component key={id} close={close} /> : null;
        })}
      </Stack>
    </Stack>
  );
};

export default withTranslation('dialogs')(AccountTabs);

const useStyles = makeStyles({
  sidebar: {
    flexShrink: 0,
    minWidth: '10rem',
    maxWidth: '10rem',
    paddingRight: '1rem',

    '& .navBtns': {
      gap: '0.5rem',
      display: 'flex',
      flexDirection: 'column',
    },

    '& .navBtn': {
      color: '#ccc',
      borderRadius: 0,
      fontWeight: 400,
      paddingBottom: 0,
      justifyContent: 'flex-start',
      borderBottom: '1px solid #ccc',

      '&:hover': {
        boxShadow: 'none',
        background: 'transparent',
      },
    },

    '& .exitBtn': {
      color: 'black',
      borderRadius: 0,
      paddingBottom: 0,
      justifyContent: 'flex-start',
      borderBottom: '1px solid black',

      '&:hover': {
        boxShadow: 'none',
        background: 'transparent',
      },
    },

    '& .navBtnActive': {
      borderBottom: '2px solid black',
      fontWeight: 500,
      color: 'black',
    },
  },
  content: {
    flexGrow: 1,
    paddingLeft: '1rem',
    borderLeft: '2px solid #ccc',
  },
});
