import isEqual from 'lodash/isEqual';
import { CardsViewMode, ConfigurationTypes, VMSDeviceDirections, VMSTemplatesTypes } from '@app/v2/shared/enums';

export function vmsSelectorsNormalizer(selectors: Scoreboards.Selector[]): Common.SelectorValues {
  if (!selectors) return [];

  return selectors.map(({ key, value }) => ({ label: value, value: key }));
}

export function getConfigItems(config: Scoreboards.Configuration): Scoreboards.ConfigurationItem[] {
  if (!config) return [];

  return config?.configuration?.items ?? [];
}

export function getNestedConfig(config: Scoreboards.Configuration): Scoreboards.NestedConfiguration {
  if (!config) return null;

  return config?.configuration;
}

export function isUnknownDirection(direction: VMSDeviceDirections): boolean {
  return direction === VMSDeviceDirections.Unknown;
}

export function isBackwardDirection(direction: VMSDeviceDirections): boolean {
  return direction === VMSDeviceDirections.Backward;
}

export function isBackwardOrForwardDirection(direction: VMSDeviceDirections): boolean {
  return direction === VMSDeviceDirections.Backward || direction === VMSDeviceDirections.Forward;
}

export function isValidDataForGrouping(data: Scoreboards.VMSStation[]): boolean {
  return data.every(({ direction }) => isBackwardOrForwardDirection(direction));
}

export function getFirstStationFromGroup(data: Record<VMSDeviceDirections, Scoreboards.VMSStation[]>): Scoreboards.VMSStation {
  return Object.values(data)[0][0];
}

export function getDirectionFromGroup(data: Record<VMSDeviceDirections, Scoreboards.VMSStation[]>): VMSDeviceDirections {
  return Object.keys(data)[0] as VMSDeviceDirections;
}

export function filterStationsByType(data: Scoreboards.VMSStation[], type: VMSTemplatesTypes) {
  if (type === VMSTemplatesTypes.Sign) return data.filter(station => [type, VMSTemplatesTypes.Azpi].includes(station.type));
  return data.filter(station => station.type === type);
}

export function addIdsToTemplates(templates: Scoreboards.Template[]): (Scoreboards.Template & { id: number })[] {
  if (!templates) return [];
  return templates.map((template, index) => ({ ...template, id: index }));
}

export function addIdsAndViewModeToTemplates(templates: Scoreboards.Template[]): Scoreboards.TemplateWithModeAndId[] {
  if (!templates) return [];
  return templates.map((template, index) => ({ ...template, id: index, mode: CardsViewMode.View }));
}

export const VMSStationsValidator = (stations: Scoreboards.VMSStation[] = []): Scoreboards.VMSStation[] => {
  return stations.filter(station => Object.hasOwn(station, 'device'));
};

export function filterSelectorsBySize(selectors: Scoreboards.Selector[], deviceSizeValues?: Common.Size) {
  if (!selectors || !selectors.length) return [];

  if (!deviceSizeValues) return selectors.map(({ key, value }) => ({ label: value, value: key }));

  return (
    selectors
      .filter(({ size }) => isEqual(size, deviceSizeValues))
      .map(({ value, key }) => ({
        label: value,
        value: key,
      })) ?? []
  );
}

export function isAZPITemplateType(type: VMSTemplatesTypes) {
  return type === VMSTemplatesTypes.Azpi;
}

export function isMessageCard(type: VMSTemplatesTypes | ConfigurationTypes) {
  return [VMSTemplatesTypes.Message, ConfigurationTypes.Txtbox].includes(type);
}

export function isSignCard(type: VMSTemplatesTypes | ConfigurationTypes) {
  return [VMSTemplatesTypes.Sign, VMSTemplatesTypes.Azpi, ConfigurationTypes.Sgnlst].includes(type);
}

export function getAutoType(data: Scoreboards.TemplateWithModeAndId) {
  return data?.template?.configuration?.configuration?.items?.some(item =>
    [ConfigurationTypes.Txtbox, ConfigurationTypes.Autobox].includes(item?.type),
  )
    ? VMSTemplatesTypes.Message
    : VMSTemplatesTypes.Sign;
}

export const vmsHelpers = {
  configurationSizesToInt(size: string) {
    const [width, height] = size.split('*');

    return { height: +height, width: +width };
  },
  configurationSizesToStr(size: { height: number; width: number }) {
    return `${size.width}*${size.height}`;
  },
  calculateSizeOfConfigurations(configuration: Scoreboards.NestedConfiguration) {
    return configuration.items.reduce(
      (acc, { height, width }) => {
        if (height > acc.height) acc.height += height;
        acc.width += width;

        return acc;
      },
      { height: 0, width: 0 },
    );
  },
  compareCommonConfigurationItemsSizeAndConfigurationSize(
    configurationSize: { height: number; width: number },
    commonConfigurationItemsSize: { height: number; width: number },
  ) {
    return configurationSize.height >= commonConfigurationItemsSize.height && configurationSize.width >= commonConfigurationItemsSize.width;
  },
};
