import { HttpLink } from '@apollo/client';
import { MockLink } from '@apollo/client/testing';
import middleware from '@app/clients/apollo/middleware';
import tokenStorage from '@app/clients/apollo/tokenStorage';
import mockData from '@app/modules/map-module/data/dataMock';
import { mainConfig } from '@app/v2/shared/configs';

export const httpLink = new HttpLink({
  uri: operation => `${mainConfig.api.baseUrl}${operation.operationName || ''}`,
});

export const mockLink = new MockLink(mockData);

export const authMiddleware = middleware(operation => {
  if (tokenStorage.isExistAuthToken) {
    operation.setContext(() => ({
      headers: {
        authorization: `Bearer ${tokenStorage.get()}`,
      },
    }));
  }

  return operation;
});
