import { useCallback, useEffect, useRef } from 'react';
import { showError } from '@app/core/utils/notifications';
// @ts-ignore
import UpdateBuildWorker from '@app/workers/updateBuild.worker';

type Response = {
  worker: Worker;
  startWorker: () => void;
  stopWorker: () => void;
};

const useUpdateBuildWorker = (): Response => {
  const worker = useRef<Worker>();

  useEffect(() => {
    worker.current = new UpdateBuildWorker();

    const onmessage = ({ data }: MessageEvent): void => {
      if (data === 'reload') {
        window.location.reload();
      }
    };

    const onerror = (error: ErrorEvent): void => showError(error.message);

    worker.current.addEventListener('message', onmessage);
    worker.current.addEventListener('error', onerror);

    return () => {
      worker.current.removeEventListener('message', onmessage);
      worker.current.removeEventListener('error', onerror);
      worker.current = undefined;
    };
  }, []);

  const startWorker = useCallback((): void => {
    worker.current?.postMessage('start');
  }, []);

  const stopWorker = useCallback((): void => {
    worker.current?.terminate();
  }, []);

  return { worker: worker.current, startWorker, stopWorker };
};

export default useUpdateBuildWorker;
