import React, { useEffect, useState } from 'react';
import 'moment/locale/ru';
import moment from 'moment';
import ru from 'antd/es/date-picker/locale/ru_RU';
import en from 'antd/es/date-picker/locale/en_US';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import { useAppSelector } from '@app/v2/shared/hooks';
import { Languages } from '@app/v2/shared/enums';
import DatePicker from './CSDDataPickerMomentCastomizationAntd';

moment.locale(Languages.RU.toLowerCase());

const CSDDataPickerRootAntd = props => {
  const [currentLocal, setCurrentLocale] = useState<PickerLocale>(ru);

  const locale = useAppSelector(state => state.account.settings.locale);

  useEffect(() => {
    switch (locale.toUpperCase()) {
      case Languages.RU:
        moment.locale('ru');
        setCurrentLocale(ru);
        break;
      case Languages.EN:
        moment.locale('en-au');
        setCurrentLocale(en);
        break;
      default:
        moment.locale('ru');
        setCurrentLocale(ru);
    }
  }, [locale]);

  return <DatePicker allowClear={false} locale={currentLocal} {...props} />;
};

export default CSDDataPickerRootAntd;
