import React, { memo, ReactNode } from 'react';
import { styled } from '@mui/styles';
import { Box, BoxProps } from '@mui/material';
import { DATE_PERIOD } from '@app/v2/shared/constants';
import { VideoStationMode } from '@app/v2/shared/enums';
import { useVideoPost } from '@app/v2/shared/hooks';
import { LAYOUT_FLEX_GAP } from './constants';
import VideoTabImageContent from './VideoTabImageContent';
import VideoTabVideoContent from './VideoTabVideoContent';

interface Props {
  stationId: number;
  animationIntervalValue?: number;
  roadLines?: Common.RoadPointLines;
  videoStations: Video.VideoPlaceStation[];
  directionTitles: Common.RoadDirectionTitles;
  setActiveStationID: (value: number | ((prev: number) => number)) => void;
}

const VideoTabContent = ({
  stationId,
  animationIntervalValue = DATE_PERIOD.SECOND,
  roadLines,
  videoStations,
  directionTitles,
  setActiveStationID,
}: Props) => {
  const { contentMode } = useVideoPost();

  const content: Record<VideoStationMode, ReactNode> = {
    [VideoStationMode.IMAGE_MODE]: (
      <VideoTabImageContent
        stationId={stationId}
        animationIntervalValue={animationIntervalValue}
        roadLines={roadLines}
        videoStations={videoStations}
        directionTitles={directionTitles}
        setActiveStationID={setActiveStationID}
      />
    ),
    [VideoStationMode.VIDEO_MODE]: <VideoTabVideoContent stationId={stationId} />,
  };

  return <StyledContainer>{content[contentMode]}</StyledContainer>;
};

export default memo(VideoTabContent);

const StyledContainer = styled(Box)<BoxProps>({
  width: '100%',
  height: '100%',
  display: 'flex',
  padding: '2rem',
  alignItems: 'center',
  gap: LAYOUT_FLEX_GAP,
  flexDirection: 'column',
  justifyContent: 'space-between',
});
