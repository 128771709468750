import { StateMapLoadingObjectKeys } from '@store/slices/map/MapState';

export const changeCheckboxState = <T>(itemState: T, { key, checked }): T => {
  return { ...itemState, [key]: { ...itemState[key], checked } };
};

export const changeLoadingMapState = <T>(itemState: T, { type, status }): T => {
  return { ...itemState, [type]: { ...itemState[type], load: status } };
};

export const getInitialLoadingStateByTypes = <T>(types: T): StateMapLoadingObjectKeys<T> => {
  return Object.fromEntries(Object.values(types).map(item => [item, { load: false }]));
};
