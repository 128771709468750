import { useEffect, useState } from 'react';
import ymaps from 'ymaps';
import { showError } from '@app/core/utils/notifications';
import ErrorMessages from '@app/core/constants/errorsMessages';

const useLoadingYandexMap = (): boolean => {
  const [isYandexMapLoad, setIsYandexMapLoad] = useState<boolean>(true);

  useEffect(() => {
    setIsYandexMapLoad(true);
    ymaps
      .load('//api-maps.yandex.ru/2.1/?load=package.map&lang=ru-RU')
      .catch(() => {
        showError(ErrorMessages.YANDEX_MAP_LOADING);
      })
      .finally(() => {
        setIsYandexMapLoad(false);
      });
  }, []);

  return isYandexMapLoad;
};

export default useLoadingYandexMap;
