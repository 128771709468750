import React, { memo, useMemo } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { Stack, StackProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { formatEnFull } from '@app/core/helpers/dateFormat';
import { isFunction } from '@app/v2/shared/helpers';
import CSDDatepicker from '../CSDDatePickers/CSDDatePicker';

interface Props {
  dateFrom: Moment;
  dateTo: Moment;
  onChangeDateFrom: (dateFrom: Moment) => void;
  onChangeDateTo: (dateTo: Moment) => void;
  title?: string;
}

const CSDPeriodDates = ({
  dateFrom,
  dateTo,
  onChangeDateTo,
  onChangeDateFrom,
  title,
  t,
}: Props & I18N.TranslationFunction<'common', 'dates.periods'>) => {
  const { t: meteoChartT } = useTranslation('charts', { keyPrefix: 'meteo' });

  const { maxDateTo, maxDateFrom, minDateTo } = useMemo(
    () => ({
      maxDateFrom: moment(dateTo).endOf('day'),
      minDateTo: moment(dateFrom).startOf('day'),
      maxDateTo: moment().endOf('day'),
    }),
    [dateFrom, dateTo],
  );

  const handleChangeDateFrom = (date: Moment): void => {
    if (!isFunction(onChangeDateFrom)) return;

    onChangeDateFrom(date.startOf('day'));
  };

  const handleChangeDateTo = (date: Moment): void => {
    if (!isFunction(onChangeDateTo)) return;

    onChangeDateTo(date.endOf('day'));
  };

  return (
    <StyledCommonWrapper>
      <Typography component="span" fontWeight={500} fontSize="0.875rem">
        {title}
      </Typography>

      <StyledItemsWrapper direction="row">
        <CSDDatepicker
          picker={{
            value: formatEnFull(dateFrom),
            maxDate: maxDateFrom,
            onChange: handleChangeDateFrom,
          }}
          textField={{
            label: t('periodFrom'),
            variant: 'outlined',
          }}
        />
        <CSDDatepicker
          picker={{
            value: formatEnFull(dateTo),
            minDate: minDateTo,
            maxDate: maxDateTo,
            onChange: handleChangeDateTo,
          }}
          textField={{
            label: t('periodTo'),
            variant: 'outlined',
            helperText: moment(dateTo).isAfter(moment().endOf('day')) && meteoChartT('validationMessage'),
          }}
        />
      </StyledItemsWrapper>
    </StyledCommonWrapper>
  );
};

const StyledCommonWrapper = styled(Stack)<StackProps>({
  gap: '0.5rem',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
});

const StyledItemsWrapper = styled(Stack)<StackProps>({
  gap: '1rem',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

export default withTranslation('common', { keyPrefix: 'dates.periods' })(memo(CSDPeriodDates));
