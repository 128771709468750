import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, IconButton, Tooltip } from '@mui/material';
import { useMutation } from '@apollo/client';
import icons from '@app/assets/iconFont';
import videoAccidentsMutations from '@app/clients/apollo/requests/mutations/videoAccidents';
import { addFeatureActions } from '@app/v2/shared/utils';
import { showSuccess } from '@app/core/utils/notifications';

interface Props extends Common.FeaturesActions {
  id: number;
}

const CSDAddVideoAccidentToFavorites = ({ id, ...featureActions }: Props) => {
  const { t } = useTranslation('incidents');

  const [add, { loading }] = useMutation<VideoAccident.AddToFavouriteResponse, VideoAccident.UpdateFavouriteStatusVariables>(
    videoAccidentsMutations.addToFavorites,
    {
      onCompleted({ videoAccidentFavorites }) {
        const { message } = videoAccidentFavorites.add;

        showSuccess(message);
      },
    },
  );

  const handleAdd = () => {
    if (!id) throw new Error('Id is required for mutation!');

    addFeatureActions(
      add({
        variables: {
          dataId: id,
        },
      }),
      featureActions,
    );
  };

  return (
    <Tooltip title={t('actions.addToFavorites')}>
      <IconButton onClick={handleAdd} disabled={loading}>
        <Icon>{icons.starOutline}</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default CSDAddVideoAccidentToFavorites;
