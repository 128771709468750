import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Checkbox from '@app/components/common/checkbox/Checkbox';
import parameters from '@app/core/constants/parameters/parameters';
import ButtonsProfilePanel from '@app/components/common/buttons-profile-panel/ButtonsProfilePanel';

type Props = {
  profile: Profiles.CommonProfile;
  isDisabledRemove: boolean;
  onChangeGroupCheckbox: (value: boolean) => void;
  onChangeCheckbox: (key: string, value: boolean) => void;
  onSetDefaultProfile: Common.AnyFunction;
  onRemoveProfile: Common.AnyFunction;
};

const ProfileTabPanel = (props: Props) => {
  const {
    profile,
    isDisabledRemove,
    onChangeGroupCheckbox = () => {},
    onChangeCheckbox = () => {},
    onSetDefaultProfile = () => {},
    onRemoveProfile = () => {},
  } = props;

  const classes = useStyles();
  const { t } = useTranslation('dialogs', { keyPrefix: 'profileSettings.tabPanel' });
  const { t: parametersT } = useTranslation('parameters');

  const groupChecked = useMemo(() => {
    return profile.items.every(({ checked }) => checked);
  }, [profile.items]);

  const setIndeterminate = useCallback((): boolean => {
    const checked = profile.items.filter(item => item.checked);
    return Boolean(checked.length && checked.length < profile.items.length);
  }, [profile.items]);

  return (
    <Grid container spacing={1} classes={{ root: classes.rootGridContainer }}>
      <Grid container item xs={12} spacing={2}>
        <Grid container item xs={3} direction="column" spacing={1}>
          <Grid item>
            <Checkbox label={t('selectAll')} checked={groupChecked} onChange={onChangeGroupCheckbox} indeterminate={setIndeterminate()} />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>{profile.description}</Grid>
        </Grid>
        <Grid container item xs={9}>
          {profile.items.map(item => (
            <Grid item key={item.key} xs={6}>
              <Checkbox
                label={parameters[item.key] ? parametersT(parameters[item.key].label) : t('unknownParameter')}
                checked={item.checked}
                onChange={(value: boolean) => onChangeCheckbox(item.key, value)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ButtonsProfilePanel
          isDisabledSetDefault={profile.isDefault}
          onSetDefault={onSetDefaultProfile}
          isDisabledRemove={isDisabledRemove}
          onRemove={onRemoveProfile}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  rootGridContainer: {
    margin: '16px -4px',
  },
  description: {
    padding: '9px 0',
    wordBreak: 'break-word',
  },
}));

export default ProfileTabPanel;
