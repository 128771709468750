import React, { memo, useEffect, useRef } from 'react';
import CanvasToSVG from 'canvas2svg';
import { GRID_LINE_COLOR, PIXEL_SIZE } from '@app/v2/shared/constants';
import DrawScoreboard from './DrawScoreboard';

const CSDScoreboardsViewerSVG = ({
  background,
  isDrawCells,
  scoreboardWidth,
  compressionRatio,
  scoreboardHeight,
  prepareRender,
}: Scoreboards.ViewerProps) => {
  const svg = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const drawScoreboard = new DrawScoreboard({ x: 0, y: 0 });

    const canvas = new CanvasToSVG({
      width: scoreboardWidth * PIXEL_SIZE,
      height: scoreboardHeight * PIXEL_SIZE,
    });

    const context = canvas;

    drawScoreboard.reset(context, { background });

    Promise.all(prepareRender(context)).then(() => {
      if (isDrawCells) drawScoreboard.drawCells(context, { size: PIXEL_SIZE / compressionRatio, color: GRID_LINE_COLOR });
    });

    if (svg.current) {
      if (svg.current.hasChildNodes()) {
        svg.current.removeChild(svg.current.lastChild);
      }
      const convertedSVG = canvas.getSvg();
      convertedSVG.setAttribute('height', '100%');
      convertedSVG.setAttribute('width', '100%');
      convertedSVG.setAttribute('viewBox', `0 0 ${canvas.width} ${canvas.height}`);
      convertedSVG.setAttribute('preserveAspectRatio', 'none');

      svg.current.appendChild(convertedSVG);
    }
  }, [prepareRender, background, compressionRatio, isDrawCells, scoreboardHeight, scoreboardWidth]);

  return <div ref={svg} style={{ height: '100%', width: '100%' }} />;
};

export default memo(CSDScoreboardsViewerSVG);
