import { useCallback, useEffect, useRef } from 'react';
import { MESSAGE, START, UPDATE_DATE } from '@app/workers/constants';
// @ts-ignore
import DateWorker from '@app/workers/date.worker';

export default function useDateWorker(): {
  addHandler: (cb: Common.AnyFunction) => void;
  removeHandler: (cb: Common.AnyFunction) => void;
} {
  const changeDataHandlers = useRef<Set<Common.AnyFunction>>(new Set());
  const worker = useRef<Worker>();

  useEffect(() => {
    worker.current = new DateWorker();

    const onmessage = ({ data }: MessageEvent): void => {
      if (data === UPDATE_DATE) {
        Array.from(changeDataHandlers.current.values()).forEach(fn => fn());
      }
    };

    worker.current?.postMessage(START);
    worker.current?.addEventListener(MESSAGE, onmessage);

    return () => {
      worker.current?.removeEventListener(MESSAGE, onmessage);
      worker.current?.terminate();
      worker.current = undefined;
    };
  }, [changeDataHandlers]);

  const addEventListener = useCallback((cb: Common.AnyFunction) => {
    if (typeof cb === 'function') {
      changeDataHandlers.current.add(cb);
    }
  }, []);

  const removeEventListener = useCallback((cb: Common.AnyFunction) => {
    if (typeof cb === 'function') {
      changeDataHandlers.current.delete(cb);
    }
  }, []);

  return { addHandler: addEventListener, removeHandler: removeEventListener };
}
