import React from 'react';
import { Icon, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import icons from '@app/assets/iconFont';
import { boxShadowLight, linearGradient } from '@theme/styles';
import { useDialog } from '@app/v2/shared/hooks';
import { DialogData } from '@app/components/common/dialog/types/DialogData';
import { DialogRef } from '@app/core/providers/DialogProvider';
import { EMPTY_STRING } from '@app/v2/shared/constants';

interface Props<DataItem> extends Common.FeaturesActions {
  uiSettings?: Partial<{ icon: string; tooltipTitle: string }>;
  dialogData: DialogData<DataItem>;
  disabled?: boolean;
  dialog: React.FC<{ item: DataItem; dialogData: DialogData<DataItem> } & DialogRef>;
}

const CSDCreateScoreboardEntity = <DataItem,>({ uiSettings, dialogData, dialog, disabled, ...featureActions }: Props<DataItem>) => {
  const classes = useStyles();

  const openDialog = useDialog<{ dialogData: DialogData<DataItem> }>(dialog);

  const handleCreate = () => {
    openDialog({ dialogData: { ...dialogData, featureActions } });
  };

  return (
    <Tooltip title={uiSettings?.tooltipTitle ?? EMPTY_STRING} placement="left">
      <IconButton disabled={disabled} className={classes.main} onClick={handleCreate}>
        <Icon sx={{ color: 'white' }}>{uiSettings?.icon ?? icons.plus}</Icon>
      </IconButton>
    </Tooltip>
  );
};

const useStyles = makeStyles({
  main: {
    '&.MuiButtonBase-root': {
      position: 'fixed',
      bottom: '3rem',
      right: '3rem',
      borderRadius: '80%',
      padding: '1rem',
      background: linearGradient,
      boxShadow: boxShadowLight,
      opacity: 0.8,
      transition: 'opacity 150ms linear',

      '&:hover': {
        opacity: 1,
      },
    },
  },
});

export default CSDCreateScoreboardEntity;
