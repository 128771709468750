import React from 'react';
import { Button } from '@mui/material';
import useDialog from '@app/core/hooks/useDialog';
import CSDDialogExample from '@app/modules/kit-module/shared/ui/CSDDialogExample/CSDDialogExample';
import dialogExample from './example';
import CSDKitDocBlock from '../../../shared/ui/CSDKitDocBlock/CSDKitDocBlock';

const CSDDialogs = () => {
  const handleOpenDialogExample = useDialog(CSDDialogExample);

  return (
    <CSDKitDocBlock title="Simple dialog" codeExample={dialogExample}>
      <Button onClick={handleOpenDialogExample}>Открыть</Button>
    </CSDKitDocBlock>
  );
};

export default CSDDialogs;
