import React, { ReactNode } from 'react';
import TemplateParagraphsForm from '@app/modules/scoreboard-module/templates/form/TemplateParagraphsForm';
import TemplateSignForm from '@app/modules/scoreboard-module/templates/form/TemplateSignForm';
import { ConfigurationTypes } from '@app/v2/shared/enums';

type Props = {
  type: ConfigurationTypes;
  options: Scoreboards.Options;
};

const ParameterSettingForm = ({ type, options }: Props) => {
  const formMapper: Record<ConfigurationTypes, ReactNode> = {
    [ConfigurationTypes.Txtbox]: <TemplateParagraphsForm {...options} />,
    [ConfigurationTypes.Autobox]: <TemplateParagraphsForm {...options} />,
    [ConfigurationTypes.Sgnlst]: <TemplateSignForm {...options} />,
  };

  return <>{formMapper[type]}</>;
};

export default ParameterSettingForm;
