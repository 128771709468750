import React from 'react';
import { Route } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { KeyPrefix, useTranslation, withTranslation } from 'react-i18next';
import { FormikErrors, FormikHandlers, FormikTouched } from 'formik';
import { Checkbox, FormControlLabel, Grid, MenuItem } from '@mui/material';
import UTC from '@app/core/constants/utc';
import { AccountSettings as AccountSettingsType } from '@store/slices/account/types';
import { StartPageItem } from '@store/slices/system/types';
import { CSDInputStandard, CSDSelectsStandard } from '@app/modules/kit-module/shared/ui';
import { LanguagesSelectors } from '@app/v2/shared/localization';
import icons from '@app/assets/iconFont';
import FormActions from './FormActions';

type Props = {
  values: AccountSettingsType;
  touched: FormikTouched<AccountSettingsType>;
  errors: FormikErrors<AccountSettingsType>;
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
  handleSubmit: FormikHandlers['handleSubmit'];
  startPages: StartPageItem[];
  closeWindow: () => void;
} & I18N.TranslationFunction<'dialogs', 'accountSettings.tabs.profile.form'>;

const Form = ({ values, touched, errors, handleChange, handleBlur, handleSubmit, closeWindow, startPages = [], t }: Props) => {
  const { t: routerT } = useTranslation('router');

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sx={{ paddingTop: '0rem !important' }}>
        <CSDInputStandard
          id="userName"
          name="userName"
          value={values.userName ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.userName ? errors.userName : ''}
          error={touched.userName && Boolean(errors.userName)}
          label={t('userName')}
          fullWidth
          hideLabel
          autoComplete="off"
          size="small"
          icon={icons.person}
        />
      </Grid>
      <Grid item xs={6}>
        <CSDInputStandard
          id="userEmail"
          name="userEmail"
          value={values.userEmail ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.userEmail ? errors.userEmail : ''}
          error={touched.userEmail && Boolean(errors.userEmail)}
          label={t('userEmail')}
          fullWidth
          autoComplete="off"
          size="small"
          icon={icons.mail}
        />
      </Grid>
      <Grid item xs={6}>
        <InputMask mask="+7 (999) 999-99-99" value={values.userPhone} alwaysShowMask onChange={handleChange} onBlur={handleBlur}>
          {inputProps => (
            <CSDInputStandard
              {...inputProps}
              fullWidth
              id="userPhone"
              name="userPhone"
              helperText={touched.userPhone ? errors.userPhone : ''}
              error={touched.userPhone && Boolean(errors.userPhone)}
              label={t('userPhone')}
              autoComplete="off"
              size="small"
              icon={icons.phone}
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={6}>
        <CSDInputStandard
          id="userPosition"
          name="userPosition"
          value={values.userPosition ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.userPosition ? errors.userPosition : ''}
          error={touched.userPosition && Boolean(errors.userPosition)}
          label={t('userPosition')}
          fullWidth
          autoComplete="off"
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <CSDSelectsStandard
          id="locale"
          name="locale"
          value={values.locale?.toUpperCase() ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('locale')}
        >
          {LanguagesSelectors.map(locale => (
            <MenuItem key={locale.value} value={String(locale.value).toUpperCase()}>
              {locale.label}
            </MenuItem>
          ))}
        </CSDSelectsStandard>
      </Grid>
      <Grid item xs={12}>
        <CSDSelectsStandard
          id="startPage"
          name="startPage"
          value={values.startPage ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('startPage')}
          disabled={!startPages.length}
          hideLabel
          icon={icons.start}
        >
          {startPages.map(startPage => (
            <MenuItem key={startPage.value} value={startPage.value}>
              <Route>{routerT(startPage.value as KeyPrefix<'router'>)}</Route>
            </MenuItem>
          ))}
        </CSDSelectsStandard>
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              id="timezoneInsteadCheckbox"
              name="timezoneInsteadCheckbox"
              checked={values.timezoneInsteadCheckbox}
              onChange={handleChange}
              onBlur={handleBlur}
              size="small"
            />
          }
          label={t('timezoneInsteadCheckbox')}
        />
      </Grid>
      <Grid item xs={6}>
        <CSDSelectsStandard
          id="utc"
          name="utc"
          value={values.utc ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          label="UTC"
          disabled={!values.timezoneInsteadCheckbox}
        >
          {UTC.map(utc => (
            <MenuItem key={utc.value} value={utc.value}>
              {utc.label}
            </MenuItem>
          ))}
        </CSDSelectsStandard>
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '0rem !important' }}>
        <FormControlLabel
          control={
            <Checkbox
              id="isRemoveFromOrganizationList"
              name="isRemoveFromOrganizationList"
              checked={values.isRemoveFromOrganizationList}
              onChange={handleChange}
              onBlur={handleBlur}
              size="small"
            />
          }
          label={t('isRemoveFromOrganizationList')}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '0rem !important' }}>
        <FormControlLabel
          control={
            <Checkbox
              id="isNotReceiveWeatherReports"
              name="isNotReceiveWeatherReports"
              checked={values.isNotReceiveWeatherReports}
              onChange={handleChange}
              onBlur={handleBlur}
              size="small"
            />
          }
          label={t('isNotReceiveWeatherReports')}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '0rem !important' }}>
        <FormControlLabel
          control={
            <Checkbox
              id="isMeteoAlertPush"
              name="isMeteoAlertPush"
              checked={values.isMeteoAlertPush}
              onChange={handleChange}
              onBlur={handleBlur}
              size="small"
            />
          }
          label={t('isMeteoAlertPush')}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '0rem !important' }}>
        <FormControlLabel
          control={
            <Checkbox
              id="isMeteoBulletinPush"
              name="isMeteoBulletinPush"
              checked={values.isMeteoBulletinPush}
              onChange={handleChange}
              onBlur={handleBlur}
              size="small"
            />
          }
          label={t('isMeteoBulletinPush')}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '0rem !important' }}>
        <FormControlLabel
          control={
            <Checkbox
              id="isClockDisabled"
              name="isClockDisabled"
              size="small"
              checked={values.isClockDisabled}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          }
          label={t('isClockDisabled')}
        />
      </Grid>
      <FormActions onSave={handleSubmit} />
    </Grid>
  );
};

export default withTranslation('dialogs', { keyPrefix: 'accountSettings.tabs.profile.form' })(Form);
