import icons from '@app/assets/iconFont';

const windDirections: StaticConfigs.WindDirectionItem[] = [
  {
    label: 'windDirections.northern',
    iconName: icons.northern,
    start: 0,
    end: 22.5,
  },
  {
    label: 'windDirections.northeastern',
    iconName: icons.northeastern,
    start: 22.6,
    end: 67.4,
  },
  {
    label: 'windDirections.eastern',
    iconName: icons.eastern,
    start: 67.5,
    end: 112.5,
  },
  {
    label: 'windDirections.southeastern',
    iconName: icons.southeastern,
    start: 112.6,
    end: 157.4,
  },
  {
    label: 'windDirections.south',
    iconName: icons.south,
    start: 157.5,
    end: 202.5,
  },
  {
    label: 'windDirections.southwestern',
    iconName: icons.southwestern,
    start: 202.6,
    end: 247.4,
  },
  {
    label: 'windDirections.western',
    iconName: icons.western,
    start: 247.5,
    end: 292.5,
  },
  {
    label: 'windDirections.northwestern',
    iconName: icons.northwestern,
    start: 292.6,
    end: 337.4,
  },
  {
    label: 'windDirections.northern',
    iconName: icons.northern,
    start: 337.5,
    end: 360,
  },
];

export default windDirections;
