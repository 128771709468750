import { KeyPrefix } from 'react-i18next';

export const CANCEL_NOTIFICATION_STATUS = 'cancel';
export const NOTIFICATION_LIMIT = 999;
export const NOTIFICATION_FILTERS = 'notificationFilters';
export const NOTIFICATION_FILTERS_INITIAL_STATE = { alertType: 0, placeValue: '' };

export const colorIndicators = {
  danger: '#F63836',
  alert: '#F63836',
  warning: '#F1CE14',
};

export const alertTypeValue: { value: number; label: KeyPrefix<'notification'>; color: string }[] = [
  { value: 1, label: 'filter.danger', color: colorIndicators.danger },
  { value: 2, label: 'filter.attention', color: colorIndicators.warning },
  { value: 3, label: 'filter.storm', color: colorIndicators.warning },
  { value: 4, label: 'filter.stormForecast', color: colorIndicators.warning },
];
