import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { CSDEmptySearch } from '@app/v2/shared/ui/custom';

const CSDEmptyListingByFiltersListing = () => {
  const { t } = useTranslation('common', { keyPrefix: 'notFound' });

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <CSDEmptySearch message={t('emptyListingByFilters')} />
    </Box>
  );
};

export default CSDEmptyListingByFiltersListing;
