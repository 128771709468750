export const basicBadge = `<Badge badgeContent={4} color="primary">
  <MailIcon color="action" />
</Badge>`;
export const basicColor = `<Badge badgeContent={4} color="secondary">
  <MailIcon color="action" />
</Badge>
<Badge badgeContent={4} color="success">
  <MailIcon color="action" />
</Badge>`;
export const customBadge = `<CustomizedBadges badgeContent={5} color="secondary">
  <Stack spacing={2} direction="row">
    <MailIcon color="action" />
    <MailIcon color="action" />
    <MailIcon color="action" />
  </Stack>
</CustomizedBadges>`;
export const maximumPropBadge = `<Badge color="secondary" badgeContent={99}>
  <MailIcon color="action" />
</Badge>
<Badge color="secondary" badgeContent={100}>
  <MailIcon color="action" />
</Badge>
<Badge color="secondary" badgeContent={1000} max={999}>
  <MailIcon color="action" />
</Badge>`;
export const dotBadge = `<Badge color="secondary" variant="dot">
  <MailIcon />
</Badge>`;
