import React from 'react';
import { withTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Button, CardActions, CardActionsProps, Stack } from '@mui/material';
import { useAppSelector } from '@app/v2/shared/hooks';
import { PasswordRecoveryStatuses } from '@app/v2/shared/enums';
import InProgressActions from './InProgressActions';

const FormActions = ({ t }: I18N.TranslationFunction<'auth'>) => {
  const passwordRecoveryStatus = useAppSelector(state => state.auth.passwordRecoveryStatus);

  return (
    <StyledCardActions>
      <Stack direction="column" width="100%">
        {passwordRecoveryStatus === PasswordRecoveryStatuses.REQUEST ? (
          <InProgressActions />
        ) : (
          <Button type="submit" color="primary" fullWidth size="large">
            {t('passwordRecovery.cardActions.sendMessage')}
          </Button>
        )}
      </Stack>
    </StyledCardActions>
  );
};

const StyledCardActions = styled(CardActions)<CardActionsProps>({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 1.5rem',
  width: '100%',
  margin: 0,
  marginBottom: '1.5rem',
});

export default withTranslation('auth')(FormActions);
