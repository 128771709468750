import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, Icon, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DialogRef } from '@app/core/providers/DialogProvider';
import icons from '@app/assets/iconFont';

interface Props {
  question: string;
}

const ConfirmDialog = ({ question, close }: Props & DialogRef<boolean>) => {
  const classes = useStyles();
  const { t } = useTranslation('dialogs', { keyPrefix: 'common.buttons' });

  return (
    <Dialog open maxWidth="xs" fullWidth scroll="paper" onClose={() => close()}>
      <DialogContent>
        <Typography align="center" variant="subtitle1">
          {question}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button size="small" fullWidth variant="outlined" color="primary" startIcon={<Icon>{icons.done}</Icon>} onClick={() => close(true)}>
          {t('yes')}
        </Button>
        <Button size="small" fullWidth variant="outlined" color="secondary" startIcon={<Icon>{icons.close}</Icon>} onClick={() => close(false)}>
          {t('no')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 36,
    margin: '2rem 0px 0px 0px',
    padding: '0px 24px',
  },
}));

export default ConfirmDialog;
