/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment, { Moment } from 'moment';
import { ReducersNames } from '@store/constants';

interface State {
  dateFrom: Moment;
  dateTo: Moment;
  chartsData: WLC.ChartPointParameters;
}

const initialCartValue: WLC.ChartPointParameters = {
  currentValueIndex: 0,
  data: [],
  date: null,
  isFixed: false,
  coords: { y: 0, x: 0 },
  dialogData: { snapshotUrl: null, kilometer: null, meter: null, title: null, dateTime: null },
};

export const initialState: State = {
  dateFrom: moment().add(-1, 'days').startOf('day'),
  dateTo: moment().endOf('day'),
  chartsData: initialCartValue,
};

const waterLevelChartSlice = createSlice({
  name: ReducersNames.waterLevelChart,
  initialState,
  reducers: {
    setWaterLevelDateFrom(state: State, { payload }: PayloadAction<{ value: Moment }>) {
      state.dateFrom = payload.value;
    },
    setWaterLevelDateTo(state: State, { payload }: PayloadAction<{ value: Moment }>) {
      state.dateTo = payload.value;
    },
    setWaterLevelInitialDates(state: State) {
      state.dateFrom = moment().add(-1, 'days').startOf('day');
      state.dateTo = moment().endOf('day');
    },
    setWaterLevelIsFixed(state: State, { payload }: PayloadAction<{ value: boolean }>) {
      state.chartsData.isFixed = payload.value;
    },
    setWaterLevelChartsParameters(state: State, { payload }: PayloadAction<WLC.ChartPointParameters>) {
      state.chartsData = payload;
    },
  },
});

export const waterLevelChartActions = waterLevelChartSlice.actions;

export default waterLevelChartSlice.reducer;
