import React, { memo, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Button, Divider, Stack, StackProps } from '@mui/material';
import moment from 'moment/moment';
import { CSDDataRangePickerAntdWithBtns, CSDSwitcher } from '@app/modules/kit-module/shared/ui';
import { UsersAccess, VideoStationMode } from '@app/v2/shared/enums';
import { useCheckAccess, useTimePeriods, useVideoPost } from '@app/v2/shared/hooks';
import { strictlyEqual } from '@app/v2/shared/helpers';
import { DATE_FORMAT } from '@app/v2/shared/constants';

const VideoTabSettingsPanel = ({ t }: I18N.TranslationFunction<'dialogs', 'post'>) => {
  const { accessChecker } = useCheckAccess();
  const {
    dateFrom,
    dateTo,
    isAnimation,
    contentMode,
    snapShots,
    stationProperties,
    handleSetDate,
    handleToggleAnimation,
    handleSetInitDates,
    handleChangeViewMode,
  } = useVideoPost();
  const { now, weekAgo, yesterday } = useTimePeriods();

  useEffect(() => handleSetInitDates(), [handleSetInitDates]);

  return (
    <StyledWrapper direction="row">
      <CSDDataRangePickerAntdWithBtns
        label={t('tab.video.date')}
        format={DATE_FORMAT.FORMAT_RU_POINT}
        value={[dateFrom, dateTo]}
        disabled={!accessChecker([UsersAccess.BUTTON_VIDEO_POPUP_DATAPICKER]) || isAnimation}
        onChange={([nextDateFrom, nextDateTo]) => handleSetDate({ dateFrom: nextDateFrom, dateTo: nextDateTo })}
        disabledDate={current => current && moment().endOf('day').isBefore(current)}
        tooltipTitle={isAnimation && t('tab.video.tooltip')}
        btnsSetup={[
          {
            label: 'dates.day',
            action: () => handleSetDate({ dateFrom: yesterday, dateTo: now }),
            disabled: !accessChecker([UsersAccess.BUTTON_VIDEO_POPUP_DATAPICKER]) || isAnimation,
            tooltipTitle: isAnimation && t('tab.video.tooltip'),
            isActive:
              strictlyEqual(dateFrom.format('yyyyMMDD'), yesterday.format('yyyyMMDD')) &&
              strictlyEqual(dateTo.format('yyyyMMDD'), now.format('yyyyMMDD')),
          },
          {
            label: 'dates.week',
            action: () => handleSetDate({ dateFrom: weekAgo, dateTo: now }),
            disabled: !accessChecker([UsersAccess.BUTTON_VIDEO_POPUP_DATAPICKER]) || isAnimation,
            tooltipTitle: isAnimation && t('tab.video.tooltip'),
            isActive:
              strictlyEqual(dateFrom.format('yyyyMMDD'), weekAgo.format('yyyyMMDD')) &&
              strictlyEqual(dateTo.format('yyyyMMDD'), now.format('yyyyMMDD')),
          },
        ]}
      />

      <Divider orientation="vertical" flexItem />

      <CSDSwitcher
        label={t('tab.video.animation')}
        checked={isAnimation}
        disabled={snapShots.length <= 1}
        onChange={(_, checked) => handleToggleAnimation({ isAnimation: checked })}
      />

      <Divider orientation="vertical" flexItem />

      {stationProperties?.isStream && (
        <Button onClick={() => handleChangeViewMode()}>{t(contentMode === VideoStationMode.VIDEO_MODE ? 'buttons.photo' : 'buttons.video')}</Button>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Stack)<StackProps>({
  width: '100%',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  padding: '1rem 1.5rem',
  gap: '1rem',
  boxShadow: '0px 5px 5px 0px rgba(52, 52, 52, 0.20)',
  zIndex: 1,

  '& > div': {
    '& > hr': {
      margin: '0 1rem',
    },
  },
});

export default withTranslation('dialogs', { keyPrefix: 'post' })(memo(VideoTabSettingsPanel));
