import React, { forwardRef, SyntheticEvent } from 'react';
import MuiAutocomplete from '@mui/lab/Autocomplete';
import { createFilterOptions } from '@mui/material';
import { isFunction } from '@app/v2/shared/helpers';
import { mainConfig } from '@app/v2/shared/configs';

const CSDRootAutocomplete = <Option,>(props: any, ref) => {
  const { id, filterSelectedOptions = true, setValue, dataTestId, ...other } = props;

  const onHandleChange = (event: SyntheticEvent, newValue: Option): void => {
    if (setValue && isFunction(setValue)) {
      setValue(event, newValue);
    }
  };

  const filterOptions = createFilterOptions<Option>({
    limit: mainConfig.autocompleteOptionsLimit,
  });

  return (
    <MuiAutocomplete<Option>
      ref={ref}
      filterSelectedOptions={filterSelectedOptions}
      onChange={onHandleChange}
      filterOptions={filterOptions}
      data-testid={dataTestId}
      {...other}
    />
  );
};

export default forwardRef(CSDRootAutocomplete) as typeof CSDRootAutocomplete;
