import React, { useEffect, memo } from 'react';
import { useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Card } from '@mui/material';
import { styled } from '@mui/styles';
import { useAppDispatch, useActions } from '@app/v2/shared/hooks';
import { PasswordRecoveryStatuses } from '@app/v2/shared/enums';
import { passwordRecoveryStepTwo } from '@store/thunks/auth/auth-thunks';
import { ValidationSchemeState, validationSchema } from './validationSchema';
import FormContent from './FormContent/FormContent';
import FormActions from './FormAction/FormAction';
import FormHeader from '../common/FormHeader';

const PasswordRecoveryLink = ({ t }: I18N.TranslationFunction<'auth', 'passwordRecovery'>) => {
  const { recoveryCode } = useParams<{ recoveryCode: string }>();
  const { changePasswordRecoveryStatus } = useActions();

  const dispatch = useAppDispatch();

  useEffect(() => {
    changePasswordRecoveryStatus(PasswordRecoveryStatuses.RECOVERY);
  }, [changePasswordRecoveryStatus]);

  const onHandleSubmit = ({ password }: ValidationSchemeState): void => {
    dispatch(passwordRecoveryStepTwo({ password, code: recoveryCode }));
  };

  return (
    <Formik<ValidationSchemeState>
      initialValues={{ password: '', passwordRepeat: '' }}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={onHandleSubmit}
    >
      <Form>
        <StyledCard elevation={3}>
          <FormHeader title={t('cardTitleLink')} />
          <FormContent />
          <FormActions />
        </StyledCard>
      </Form>
    </Formik>
  );
};

const StyledCard = styled(Card)({
  '&.MuiPaper-root': {
    width: '18.25rem',
    minHeight: '17rem',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '0 !important',

    '& span.MuiCardHeader-title': {
      textAlign: 'center',
      fontSize: '1.25rem',
      lineHeight: '1.25rem',
      padding: '1rem',
    },
  },
});

export default withTranslation('auth', { keyPrefix: 'passwordRecovery' })(memo(PasswordRecoveryLink));
