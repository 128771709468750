import { useCallback, useMemo } from 'react';
import { useQueryParams } from 'use-query-params';
import { AmountCounters, LISTING_DEFAULT_PAGE_NUMBER, URLParametersMapper, URLSetterChecker } from '@app/v2/shared/constants';
import useActions from '../reactRedux/useActions';
import useAppSelector from '../reactRedux/useAppSelector';
import { FiltersKeys } from '../../enums';

const mainFilters = [FiltersKeys.Organizations, FiltersKeys.Roads, FiltersKeys.Places];
export default function useNewFilters() {
  const filters = useAppSelector(state => state.newFilters.filters);
  const isEnabled = useAppSelector(state => state.newFilters.isEnabled);

  const { addFilter, setFilter, setFilters, addFilters, toggleFiltersEnabledState, resetFilters, removeFilters } = useActions();

  const [, setParamsFilter] = useQueryParams(
    Object.entries(filters).reduce<Record<string, any>>((acc, [filterName, filter]) => {
      acc[filterName] = URLParametersMapper[filter.type];
      return acc;
    }, {}),
  );

  const isCountedType = (type: FiltersKeys): boolean => mainFilters.includes(type);

  const { filtersValues, filtersAmount, isFiltersExist } = useMemo<Filters.Data>(
    () =>
      Object.entries(filters).reduce<Filters.Data>(
        (acc, [filterName, filter]) => {
          acc.filtersValues[filterName] = filter.value;
          acc.isFiltersExist = acc.isFiltersExist || (mainFilters.includes(filterName as FiltersKeys) && URLSetterChecker[filter.type](filter.value));
          if (isCountedType(filterName as FiltersKeys)) {
            acc.filtersAmount += AmountCounters[filter.type](filter.value);
          }

          return acc;
        },
        { filtersValues: {}, filtersAmount: 0, isFiltersExist: false },
      ),
    [filters],
  );

  const resetCurrentFilters = useCallback((currentParameters = {}) => {
    return Object.keys(currentParameters).reduce<Record<string, undefined>>((acc, key) => {
      acc[key] = undefined;
      return acc;
    }, {});
  }, []);

  const handleResetFilters = useCallback(() => {
    setParamsFilter(currentParameters => ({
      page: LISTING_DEFAULT_PAGE_NUMBER,
      ...resetCurrentFilters(currentParameters),
    }));

    resetFilters();
  }, [resetFilters, setParamsFilter, resetCurrentFilters]);

  const removeEmptyFilters = useCallback((value = {}) => {
    const result = { ...value };
    for (const key in result) {
      if (Array.isArray(result[key]) && result[key].length === 0) {
        delete result[key];
      }
    }
    return result;
  }, []);

  const setFiltersToURL = useCallback(
    (nextValue: Filters.Filter = {}) => {
      const adjustedNewParams = removeEmptyFilters({
        page: LISTING_DEFAULT_PAGE_NUMBER,
        ...Object.entries({ ...filters, ...nextValue }).reduce<Filters.Values>((acc, [filterName, filter]) => {
          if (URLSetterChecker[filter.type](filter.value)) acc[filterName] = filter.value;
          else acc[filterName] = undefined;

          return acc;
        }, {}),
      });

      setParamsFilter(currentParameters => ({
        page: LISTING_DEFAULT_PAGE_NUMBER,
        ...resetCurrentFilters(currentParameters),
        ...adjustedNewParams,
      }));
    },
    [filters, setParamsFilter, removeEmptyFilters, resetCurrentFilters],
  );

  return {
    isFiltersExist,
    isEnabled,
    filters: filtersValues,
    addFilter,
    addFilters,
    setFilter,
    setFilters,
    toggleFiltersEnabledState,
    resetFilters: handleResetFilters,
    filtersAmount,
    setFiltersToURL,
    removeFilters,
  };
}
