import React, { ReactNode } from 'react';
import { TextFieldProps } from '@mui/material';
import { FastField } from 'formik';
import { EMPTY_STRING } from '@app/v2/shared/constants';
import CSDInput from '../CSDInput/CSDInput';

interface Props {
  path: string;
  label?: ReactNode;
  maxLength?: number;
  textFieldProps?: TextFieldProps;
}

const CSDInputFastField = ({ path, textFieldProps = {}, ...other }: Props) => {
  return (
    <FastField name={path}>
      {({ field, meta }) => (
        <CSDInput
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          helperText={meta.touched ? meta.error : EMPTY_STRING}
          error={meta.touched && Boolean(meta.error)}
          fullWidth={textFieldProps?.fullWidth ?? true}
          {...textFieldProps}
          {...other}
        />
      )}
    </FastField>
  );
};

export default CSDInputFastField;
