import React, {
  createContext,
  forwardRef,
  HTMLAttributes,
  memo,
  ReactChild,
  useRef,
  useState,
  ReactNode,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { Box, Typography, Tooltip } from '@mui/material';
import { AutocompleteProps } from '@app/modules/kit-module/shared/ui/CSDAutocomplete/types';
import CSDAutocomplete from './CSDAutocomplete';

const LIST_BOX_PADDING = 8; // px
const LIST_BOX_ITEM_HEIGHT = 26.3; // px

const RenderRow = ({ data, index, style }: ListChildComponentProps) => {
  const typographyRef = useRef<HTMLLIElement>(null);
  const [isOpenTooltip, setIsOpenTooltip] = useState<boolean>(false);

  const dataSet = data[index];

  const [props, option, showFullLabelTooltip] = dataSet;

  const inlineStyle = {
    ...style,
    top: (style.top as number) + LIST_BOX_PADDING,
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'block',
  };

  const handleOpenTooltip = useCallback(() => {
    const element = typographyRef?.current;
    if (!element) return;
    if (element.offsetWidth < element.scrollWidth) {
      setIsOpenTooltip(true);
    }
  }, []);

  return (
    <Tooltip
      title={showFullLabelTooltip ? option.label : null}
      open={isOpenTooltip}
      onOpen={handleOpenTooltip}
      onClose={() => setIsOpenTooltip(false)}
    >
      <Typography ref={typographyRef} component="li" {...props} noWrap style={inlineStyle}>
        {option.label}
      </Typography>
    </Tooltip>
  );
};

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// eslint-disable-next-line react/prop-types
const ListboxComponent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(({ children, ...other }, ref) => {
  const itemData: ReactChild[] = [];

  // eslint-disable-next-line react/prop-types
  (children as ReactChild[]).forEach((item: ReactChild & { children?: React.ReactChild[] }) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const height = useMemo(() => itemData.length * LIST_BOX_ITEM_HEIGHT + 2 * LIST_BOX_PADDING, [itemData.length]);
  const memoRow = memo(RenderRow);

  return (
    <Box
      ref={ref}
      sx={{
        '& > div > ul': {
          margin: 0,
        },
      }}
    >
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          width="100%"
          height={height}
          overscanCount={5}
          itemData={itemData}
          innerElementType="ul"
          itemCount={itemData.length}
          itemSize={LIST_BOX_ITEM_HEIGHT}
          outerElementType={OuterElementType}
        >
          {memoRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </Box>
  );
});

const CSDAutocompleteVirtualize = <Option extends Common.SelectorValue, Multiple extends boolean = true>({
  id,
  label,
  disabled,
  inputProps,
  autocompleteProps,
  showFullLabelTooltip = false,
}: AutocompleteProps<Option, Multiple>) => {
  return (
    <CSDAutocomplete
      id={id}
      label={label}
      disabled={disabled}
      showFullLabelTooltip={showFullLabelTooltip}
      autocompleteProps={{
        ...autocompleteProps,
        ListboxComponent,
        renderOption: (props, option: Option) => [props, option, showFullLabelTooltip] as ReactNode,
      }}
      inputProps={inputProps}
    />
  );
};

export default CSDAutocompleteVirtualize;
