import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, IconButton, Tooltip } from '@mui/material';
import icons from '@app/assets/iconFont';

interface Props<AnchorType> {
  onClick: (event: MouseEvent<AnchorType>) => void;
}

const CSDUpdateMeteoChart = <AnchorType extends HTMLButtonElement>({ onClick }: Props<AnchorType>) => {
  const { t } = useTranslation('charts', { keyPrefix: 'meteoConstructor' });

  return (
    <Tooltip title={t('updateChart')}>
      <IconButton onClick={onClick}>
        <Icon>{icons.edit}</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default CSDUpdateMeteoChart;
