export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

/** Delay settings */
export const DEBOUNCE_INTERVAL = 200;
export const DEBOUNCE_SCROLL_INTERVAL = 2000;
export const DEBOUNCE_FETCH_INTERVAL = 500;
export const DEBOUNCE_CLICK_INTERVAL = 200;

export enum ReducersNames {
  account = 'account',
  auth = 'auth',
  chat = 'chat',
  filters = 'filters',
  newFilters = 'newFilters',
  newUsersSets = 'newUsersSets',
  loader = 'loader',
  map = 'map',
  report = 'report',
  subscriptions = 'subscriptions',
  viewTableElements = 'viewTableElements',
  system = 'system',
  meteoChart = 'meteoChart',
  meteoChartConstructor = 'meteoChartConstructor',
  trafficChart = 'trafficChart',
  scoreboardMessages = 'scoreboardMessages',
  waterLevelChart = 'waterLevelChart',
  videoPostTab = 'videoPostTab',
  favoritesLayouts = 'favoritesLayouts',
  federalRoads = 'federalRoads',
  sorting = 'sorting',
  dailyReport = 'dailyReport',
}
