import { SortStatus } from '@app/v2/shared/enums';
import useAppSelector from './reactRedux/useAppSelector';
import useActions from './reactRedux/useActions';

export default function useSorting() {
  const sortingValue = useAppSelector(state => state.sorting.sortingValue);

  const { setSortingValue } = useActions();

  const onSorting = (key: string, value: SortStatus) => {
    setSortingValue({ key, value });
  };

  return { onSorting, sortingValue };
}
