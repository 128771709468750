import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import MapNoteForm from '@app/modules/map-module/components/map/notes/form/MapNoteForm';
import Schema from '@app/modules/map-module/components/map/notes/data/Schema';
import UpdateNoteDialog from '@app/modules/map-module/components/dialogs/UpdateNoteDialog';
import mapNoteQueries from '@app/clients/apollo/requests/queries/notes';
import mapNoteMutations from '@app/clients/apollo/requests/mutations/notes';
import { useActions, useShowTime, useTitle } from '@app/v2/shared/hooks';
import { sliceLatitudeAndLongitude, meterZeroes, isNumber } from '@app/v2/shared/helpers';
import useDialog from '@app/core/hooks/useDialog';
import NodeInfoDialog from '@app/components/notes/dialogs/NodeInfoDialog';
import generateHeaders from '../helpers/generateHeaders';

type Props = {
  closePopperHandler?: Function;
};

const useGenerateHeaders = ({ closePopperHandler }: Props) => {
  const { t } = useTranslation('map', { keyPrefix: 'notes' });
  const { t: unitsT } = useTranslation('common', { keyPrefix: 'units' });

  useTitle(t('listingTitle'));

  const classes = useStyles();
  const { showTime } = useShowTime();
  const { setIsReloadNoteListing } = useActions();

  const formatAddress = useCallback((address: string, latitude: number, longitude: number) => {
    return address || `${sliceLatitudeAndLongitude(latitude)}, ${sliceLatitudeAndLongitude(longitude)}`;
  }, []);

  const formatRoad = useCallback(
    (kilometer: number, meter?: number) => {
      if (isNumber(kilometer) && isNumber(meter)) {
        return `, ${unitsT('kilometers')} ${kilometer}+${meterZeroes(meter)}`;
      }
      if (isNumber(kilometer)) {
        return `, ${unitsT('kilometers')} ${kilometer}`;
      }
      return '';
    },
    [unitsT],
  );

  const refetchNotes = useCallback(() => setIsReloadNoteListing(true), [setIsReloadNoteListing]);

  const updateNoteButtonProps = useMemo(
    () => ({
      dialogData: {
        component: MapNoteForm,
        schema: Schema,
        title: t('updateNotes'),
        mutation: mapNoteMutations.update,
      },
      query: mapNoteQueries.note,
      dialog: UpdateNoteDialog,
      onUpdate: refetchNotes,
    }),
    [t, refetchNotes],
  );

  const openNodeInfoDialog = useDialog(NodeInfoDialog);

  const headers = useMemo(
    () =>
      generateHeaders({
        t,
        showTime,
        classes,
        formatAddress,
        formatRoad,
        updateNoteButtonProps,
        onReset: refetchNotes,
        openNodeInfoDialog,
        closePopperHandler,
      }),
    [t, showTime, classes, formatAddress, formatRoad, updateNoteButtonProps, refetchNotes, openNodeInfoDialog, closePopperHandler],
  );

  return headers;
};

const useStyles = makeStyles<Theme>(theme => ({
  icon: {
    '&&': {
      verticalAlign: 'text-top',
      marginRight: '0.1rem',
      color: theme.palette.primary.main,
      fontSize: '1rem',
    },
  },
  titleText: {
    '&&': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      cursor: 'default',
      fontWeight: 'bold',
      fontSize: '1.1rem',
    },
  },
  buttonContainer: {
    '&&': {
      display: 'flex',
      gap: theme.spacing(1),
      flexShrink: 0,
    },
  },
  titleContainer: {
    '&&': {
      minWidth: 0,
      flexShrink: 1,
    },
  },
  messageText: {
    '&&': {
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      height: '2.2rem',
      position: 'relative',
    },
  },
  readMoreButton: {
    '&&': {
      display: 'block',
      color: theme.palette.info.main,
      textAlign: 'center',
      cursor: 'pointer',
    },
  },
  visibilityBox: {
    '&&': {
      paddingTop: '0.5rem',
      borderRadius: '0.375rem',
      border: '0.05rem solid',
    },
  },
  oneLineOrganizationUser: {
    '&&': {
      color: theme.palette.grey[600],
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: '0.8rem',
    },
  },
  oneLineAddresses: {
    '&&': {
      color: theme.palette.info.main,
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  oneLineDates: {
    '&&': {
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  iconWithText: {
    '&&': {
      display: 'flex',
      alignItems: 'top',
    },
  },
  imageContainer: {
    '&&': {
      height: '3.6rem',
    },
  },
}));

export default useGenerateHeaders;
