import React from 'react';
import { Stack, Icon, IconButton, Divider, IconButtonProps, IconProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledIconButton = styled(IconButton)<IconButtonProps>(() => ({
  padding: '0.75rem 0.531rem',
}));

const StyledIcon = styled(Icon)<IconProps>(
  ({
    theme: {
      palette: { common },
    },
  }) => ({
    color: common.black,
  }),
);

const CSDZoomControl = () => {
  return (
    <Stack borderRadius="1rem" bgcolor={({ palette: { common } }) => common.white} direction="column" spacing={0}>
      <StyledIconButton>
        <StyledIcon>plus</StyledIcon>
      </StyledIconButton>
      <Divider
        sx={{
          borderColor: ({ palette: { text } }) => text.t4,
        }}
      />
      <StyledIconButton>
        <StyledIcon>minus</StyledIcon>
      </StyledIconButton>
    </Stack>
  );
};

export default CSDZoomControl;
