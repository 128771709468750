import { Viewport } from 'react-leaflet';
import { object, string, number, boolean, array, lazy } from 'yup';
import { i18n } from '@app/v2/shared/localization';
import { Profile, Item, Settings } from '@app/core/types/profiles/map';
import { RoadObjectsTypes, ProfileMapSettingLabels } from '@app/v2/shared/enums';
import StaticObjectTypes from '@app/modules/map-module/enums/StaticObjectTypes';
import ZoomMarker from '@app/modules/map-module/interfaces/ZoomMarker';
import NotesTypes from '@app/modules/map-module/enums/NotesTypes';
import LayerTypes from '@app/modules/map-module/enums/LayerTypes';
import SettingTypes from '@app/modules/map-module/enums/SettingTypes';

export const DEFAULT_VIEWPORT: Viewport = { center: [55.7558, 37.6173], zoom: 13 };

export const DEFAULT_MARKER_ZOOMS: ZoomMarker = {
  firstLimit: 9,
  secondLimit: 15,
};

export const INITIAL_MAP_PROFILE_ID = 0;
export const MAX_MAP_PROFILES_AMOUNT = 6;

export const DEFAULT_MAP_PROFILE_ITEMS: { [key in ProfileMapSettingLabels]: Item<Settings> } = {
  [ProfileMapSettingLabels.SETTINGS]: {
    key: 'settings',
    checked: true,
    settings: {
      [SettingTypes.LOCATOR_SYNC]: {
        checked: false,
      },
      [SettingTypes.ROAD_MAPPING]: {
        checked: false,
      },
    },
  },
  [ProfileMapSettingLabels.ROAD_OBJECTS]: {
    key: 'roadObjects',
    checked: true,
    settings: {
      [RoadObjectsTypes.VIDEO]: {
        checked: true,
      },
      [RoadObjectsTypes.TRAFFIC]: {
        checked: true,
      },
      [RoadObjectsTypes.METEO_PARAMS_SECOND]: {
        checked: true,
      },
      [RoadObjectsTypes.ROAD_ADDRESS]: {
        checked: false,
      },
      [RoadObjectsTypes.METEO_PARAMS_FIRST]: {
        checked: true,
      },
      [RoadObjectsTypes.SIGN_STATIONS]: {
        checked: true,
      },
      [RoadObjectsTypes.MESSAGE_STATIONS]: {
        checked: true,
      },
    },
  },
  [ProfileMapSettingLabels.WEATHER_RADARS]: {
    key: 'locatorImages',
    checked: true,
    settings: {},
  },
  [ProfileMapSettingLabels.GIS]: {
    key: 'gis',
    checked: true,
    settings: {
      [StaticObjectTypes.PIPE]: {
        checked: false,
      },
      [StaticObjectTypes.BRIDGE]: {
        checked: false,
      },
      [StaticObjectTypes.TUNNEL]: {
        checked: false,
      },
      [StaticObjectTypes.FLYOVER]: {
        checked: false,
      },
      [StaticObjectTypes.VIADUCT]: {
        checked: false,
      },
      [StaticObjectTypes.MILESTONE]: {
        checked: false,
      },
      [StaticObjectTypes.PROD_BASE]: {
        checked: false,
      },
      [StaticObjectTypes.SAND_BASE]: {
        checked: false,
      },
      [StaticObjectTypes.RAIL_CROSS]: {
        checked: false,
      },
      [StaticObjectTypes.ASPHALT_PLANT]: {
        checked: false,
      },
      [StaticObjectTypes.PEDESTRIAN_CROSS]: {
        checked: false,
      },
    },
  },
  [ProfileMapSettingLabels.ACTIVE_LAYER]: {
    key: 'activeLayer',
    checked: true,
    settings: LayerTypes.YANDEX_MAP,
  },
  [ProfileMapSettingLabels.MAP_LOCATION]: {
    key: 'viewport',
    checked: true,
    settings: DEFAULT_VIEWPORT,
  },
  [ProfileMapSettingLabels.NOTES]: {
    key: 'notes',
    checked: true,
    settings: {
      [NotesTypes.ALL]: {
        checked: false,
      },
    },
  },
  [ProfileMapSettingLabels.MARKER_ZOOMS]: {
    key: 'markerZooms',
    checked: true,
    settings: DEFAULT_MARKER_ZOOMS,
  },
};

export const INITIAL_MAP_PROFILE: Profile = {
  id: INITIAL_MAP_PROFILE_ID,
  label: i18n.t('constants:initialProfiles.dispatcher'),
  isDefault: false,
  items: DEFAULT_MAP_PROFILE_ITEMS,
};

const getValidateSchemeForCheckboxField = (fields: { [key: string]: string }) =>
  object({
    key: string(),
    checked: boolean(),
    settings: object({
      ...Object.values(fields).reduce((res, key) => {
        res[key] = object({
          checked: boolean(),
        });
        return res;
      }, {}),
    }),
  })
    .default(undefined)
    .required();

export const MAP_PROFILE_YUP_SCHEME = object({
  id: number().nullable(),
  label: string().nullable(),
  isDefault: boolean(),
  items: object({
    [ProfileMapSettingLabels.SETTINGS]: getValidateSchemeForCheckboxField(SettingTypes),
    [ProfileMapSettingLabels.ROAD_OBJECTS]: getValidateSchemeForCheckboxField(RoadObjectsTypes),
    [ProfileMapSettingLabels.GIS]: getValidateSchemeForCheckboxField(StaticObjectTypes),
    [ProfileMapSettingLabels.ACTIVE_LAYER]: object({
      key: string(),
      checked: boolean(),
      settings: string(),
    }).required(),
    [ProfileMapSettingLabels.NOTES]: object({
      key: string(),
      checked: boolean(),
      settings: getValidateSchemeForCheckboxField(NotesTypes),
    }),
    [ProfileMapSettingLabels.MAP_LOCATION]: object({
      key: string(),
      checked: boolean(),
      settings: object({
        center: array().of(number()),
        zoom: number(),
      }),
    })
      .default(undefined)
      .required(),
    [ProfileMapSettingLabels.WEATHER_RADARS]: object({
      key: string(),
      checked: boolean(),
      settings: lazy(value => (Array.isArray(value) ? array().max(0) : object())),
    })
      .default(undefined)
      .required(),
    [ProfileMapSettingLabels.MARKER_ZOOMS]: object({
      key: string(),
      checked: boolean(),
      settings: object({
        firstLimit: number(),
        secondLimit: number(),
      }),
    }).default(undefined),
  }),
});
