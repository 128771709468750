import { useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import useAppSelector from '@app/core/hooks/customReduxHooks/useAppSelector';
import useActions from '@app/core/hooks/customReduxHooks/useActions';

export default function usePagination(): [Common.Pagination, (pagination: Partial<Common.Pagination>) => void] {
  const { setPagination: setPaginationAction } = useActions();

  const setPagination = useCallback(
    (pagination: Partial<Common.Pagination>) => {
      setPaginationAction(pagination);
    },
    [setPaginationAction],
  );

  const pagination = useAppSelector(state => state.filters.pagination, isEqual);

  return [pagination, setPagination];
}
