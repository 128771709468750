/** Errors messages */

enum ErrorMessages {
  COMMON_ERROR_MESSAGE = 'commonError',
  LOGIN_FAILURE = 'loginFailure',
  WRONG_DATA = 'wrongData',
  REQUEST_LIMIT_EXCEEDED = 'forbidden',
  INCORRECT_UPDATING = 'incorrectUpdating',
  FETCH_DATA = 'fetchData',
  FILE_NOT_UPLOADED = 'fileNotLoaded',
  FILE_NOT_DOWNLOADED = 'fileNotDownloaded',
  PROFILE_DATA_LOADING = 'profileDataLoading',
  INVALID_ACTIVE_PROFILE = 'invalidActiveProfile',
  INSUFFICIENT_PERMISSIONS = 'insufficientPermissions',
  TIME_ENDED = 'timeEnded',
  LAYER_LOADING = 'layerLoading',
  YANDEX_MAP_LOADING = 'yandexMapLoading',
}

export default ErrorMessages;
