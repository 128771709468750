import { KitSidebarItem } from './types';
import { KitComponents, KitRoutes } from '../../types/kitComponents';

const KitSidebarConfig: KitSidebarItem[] = [
  { path: KitRoutes.inputs, label: KitComponents.Inputs, isGroupTitle: true },
  { path: KitRoutes.buttons, label: KitComponents.Buttons },
  { path: KitRoutes.checkboxes, label: KitComponents.Checkboxes },
  { path: KitRoutes.switches, label: KitComponents.Switches },
  { path: KitRoutes.sliders, label: KitComponents.Sliders },
  { path: KitRoutes.autocompletes, label: KitComponents.Autocompletes },
  { path: KitRoutes.selects, label: KitComponents.Selects },
  { path: KitRoutes.textFields, label: KitComponents.TextFields },
  { path: KitRoutes.dataDisplay, label: KitComponents.DataDisplay, isGroupTitle: true },
  { path: KitRoutes.avatars, label: KitComponents.Avatars },
  { path: KitRoutes.badges, label: KitComponents.Badges },
  { path: KitRoutes.chips, label: KitComponents.Chips },
  { path: KitRoutes.dividers, label: KitComponents.Dividers },
  { path: KitRoutes.typographies, label: KitComponents.Typographies },
  { path: KitRoutes.tables, label: KitComponents.Tables },
  { path: KitRoutes.tooltips, label: KitComponents.Tooltips },
  { path: KitRoutes.feedback, label: KitComponents.Feedback, isGroupTitle: true },
  { path: KitRoutes.alerts, label: KitComponents.Alerts },
  { path: KitRoutes.snackbar, label: KitComponents.Snackbars },
  { path: KitRoutes.dialogs, label: KitComponents.Dialogs },
  { path: KitRoutes.surfaces, label: KitComponents.Surfaces, isGroupTitle: true },
  { path: KitRoutes.cards, label: KitComponents.Cards },
  { path: KitRoutes.appBar, label: KitComponents.AppBar },
  { path: KitRoutes.navigation, label: KitComponents.Navigation, isGroupTitle: true },
  { path: KitRoutes.tabs, label: KitComponents.Tabs },
  { path: KitRoutes.other, label: KitComponents.Other, isGroupTitle: true },
  { path: KitRoutes.notFromMUIDocs, label: KitComponents.NotFromMUIDocs },
  { path: KitRoutes.icons, label: KitComponents.Icons },
  { path: KitRoutes.datePickersMUI, label: KitComponents.DatePickersMUI },
  { path: KitRoutes.datePickersAntd, label: KitComponents.DatePickersAntd },
  { path: KitRoutes.pieCharts, label: KitComponents.PieCharts },
  { path: KitRoutes.dynamicRoadInterface, label: KitComponents.DynamicRoadInterface },
];

export default KitSidebarConfig;
