import React from 'react';
import { FastField } from 'formik';
import { AutocompleteProps } from '@app/modules/kit-module/shared/ui/CSDAutocomplete/types';
import { EMPTY_STRING } from '@app/v2/shared/constants';
import CSDAutocompleteVirtualize from '../CSDAutocomplete/CSDAutocompleteVirtualize';

interface Props {
  path: string;
  disabled?: boolean;
  autocompleteProps: Partial<AutocompleteProps<any, any>>;
}

const CSDAutocompleteFastField = ({ path, disabled, autocompleteProps }: Props) => {
  return (
    <FastField name={path} shouldUpdate={(current, next) => current?.disabled === next?.disabled}>
      {({ field, meta, form }) => {
        return (
          <CSDAutocompleteVirtualize<Common.SelectorValue, false>
            id={field.name}
            label={autocompleteProps.label}
            disabled={disabled || !autocompleteProps.autocompleteProps?.options?.length}
            autocompleteProps={{
              value: { value: field.value },
              setValue: async (_, newValue) => {
                await form.setFieldValue(field.name, newValue?.value ?? EMPTY_STRING);
              },
              ...autocompleteProps?.autocompleteProps,
            }}
            inputProps={{
              ...autocompleteProps?.inputProps,
              disabled,
              name: field.name,
              error: meta.touched && Boolean(meta.error),
              helperText: meta.touched && Boolean(meta.error) ? meta.error : EMPTY_STRING,
            }}
          />
        );
      }}
    </FastField>
  );
};

export default CSDAutocompleteFastField;
