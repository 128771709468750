import React, { HTMLAttributes, useCallback, useMemo } from 'react';
import { Box, Chip, ChipProps, CircularProgress, Icon, InputAdornment, TextField, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AutocompleteRenderGetTagProps, AutocompleteRenderInputParams, AutocompleteRenderGroupParams } from '@mui/material/Autocomplete/Autocomplete';
import icons from '@app/assets/iconFont';
import { typedMemo } from '@app/v2/shared/utils';
import { EMPTY_STRING } from '@app/v2/shared/constants';
import CSDRootAutocomplete from './CSDRootAutocomplete';
import type { AutocompleteProps } from './types';

const CSDAutocomplete = <Option extends Common.SelectorValue, Multiple extends boolean = true>({
  id,
  label,
  disabled,
  showFullLabelTooltip,
  inputProps,
  fixedOptions,
  autocompleteProps,
  textFieldStyle,
}: AutocompleteProps<Option, Multiple>) => {
  const renderInput = useMemo(
    () =>
      (params: AutocompleteRenderInputParams): JSX.Element => {
        const { InputProps } = params;

        return (
          <TextField
            {...params}
            size="small"
            disabled={disabled}
            InputProps={{
              ...InputProps,
              startAdornment: InputProps?.startAdornment ?? (
                <InputAdornment sx={{ margin: 0 }} position="start">
                  <Icon>{icons.search}</Icon>
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {/* eslint-disable-next-line react/prop-types */}
                  {autocompleteProps?.loading ? <CircularProgress sx={{ marginRight: '0.5rem' }} size={20} /> : null}
                  {InputProps.endAdornment}
                </>
              ),
              sx: { ...textFieldStyle },
            }}
            {...inputProps}
          />
        );
      },
    [autocompleteProps?.loading, disabled, inputProps, textFieldStyle],
  );
  const renderGroup = useMemo(
    () =>
      (params: AutocompleteRenderGroupParams): JSX.Element => {
        const { key, group, children } = params;

        return group ? (
          <li key={key}>
            <div style={{ padding: '0.5rem 0.5rem 0.1rem 1rem', fontWeight: 500, fontSize: '1rem' }}>{group}</div>
            <ul style={{ padding: '0 1rem' }}>{children}</ul>
          </li>
        ) : (
          <ul key={key} style={{ padding: 0 }}>
            {children}
          </ul>
        );
      },
    [],
  );

  const renderTags = useMemo(
    () =>
      (
        tagValue: Option[],
        getTagProps: AutocompleteRenderGetTagProps,
        { options }: AutocompleteProps<Option, Multiple>['autocompleteProps'],
      ): JSX.Element[] => {
        return tagValue.map((option: Option, index: number): JSX.Element => {
          const isFixedOption = fixedOptions?.some(({ value }) => value === option?.value);
          return (
            <StyledChip
              key={option?.value}
              variant="outlined"
              deleteIcon={<Icon>{icons.close}</Icon>}
              label={autocompleteProps.loading ? '...' : option?.label ?? options.find(({ value }) => value === option?.value)?.label}
              {...getTagProps({ index })}
              disabled={isFixedOption}
              style={{ ...inputProps.chipStyle }}
            />
          );
        });
      },
    [autocompleteProps.loading, fixedOptions, inputProps.chipStyle],
  );

  const defaultRenderOptions = useMemo(
    () =>
      (renderProps: HTMLAttributes<HTMLElement>, { value, label: defaultLabel }: Option) =>
        (
          <Tooltip title={showFullLabelTooltip ? defaultLabel : null} key={value}>
            <li {...renderProps}>{defaultLabel}</li>
          </Tooltip>
        ),
    [showFullLabelTooltip],
  );

  const getOptionLabelDefault = useCallback(
    (option: Option): string => option?.label ?? autocompleteProps.options.find(({ value }) => value === option?.value)?.label ?? EMPTY_STRING,
    [autocompleteProps.options],
  );
  const isOptionEqualToValueDefault = useCallback((a: Option, b: Option): boolean => a?.value === b?.value, []);

  return (
    <Box sx={{ width: inputProps?.fullWidth && '100%' }}>
      {!!label && (
        <Typography variant="subtitle2" sx={{ marginBottom: '0.5rem', fontSize: '0.875rem' }}>
          {label}
        </Typography>
      )}

      <CSDRootAutocomplete<Option>
        id={id}
        renderInput={renderInput}
        getOptionLabel={getOptionLabelDefault}
        isOptionEqualToValue={isOptionEqualToValueDefault}
        renderOption={defaultRenderOptions}
        renderGroup={renderGroup}
        renderTags={renderTags}
        disabled={disabled}
        {...autocompleteProps}
      />
    </Box>
  );
};

const StyledChip = styled(Chip)<ChipProps>({
  '& .MuiChip-label': {
    padding: 0,
    marginRight: '0.5rem',
  },
  '& .MuiChip-deleteIcon': {
    color: 'inherit',
    margin: 0,
    fontSize: '1rem',
  },
});

export default typedMemo(CSDAutocomplete);
