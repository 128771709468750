import { useLayoutEffect, useState } from 'react';

export type ElementSize = { height: number; width: number };

export default function useNodeSize(ref): ElementSize {
  const refNode = ref.current;
  const [{ height, width }, setSize] = useState<ElementSize>({ height: 0, width: 0 });

  useLayoutEffect(() => {
    if (refNode) {
      const updateSize = () => setSize({ height: Math.ceil(refNode?.clientHeight), width: Math.ceil(refNode?.clientWidth) });

      window.addEventListener('resize', updateSize);
      updateSize();

      return () => window.removeEventListener('resize', updateSize);
    }

    return undefined;
  }, [ref, refNode]);

  return { height, width };
}
