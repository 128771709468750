import React, { MouseEvent } from 'react';
import { Box, BoxProps, Icon, Skeleton, Stack, StackProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import isFunction from '@app/core/helpers/isFunction';

const StyledBox = styled(Box)<BoxProps>({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  minHeight: '4.3rem',
  paddingBottom: '0.5rem',
});

const StyledStack = styled(Stack)<StackProps>({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  borderRadius: '0.5rem',
  padding: '0.4rem',
  gap: '0.5rem',
  cursor: 'inherit',
});

const StyledSkeletonLabel = styled(Skeleton)({
  height: '1rem',
  borderRadius: '0.5rem',
});

const StyledSkeletonInput = styled(Skeleton)({
  height: '2.5rem',
  marginRight: '0.5rem',
  marginTop: '0.5rem',
  borderRadius: '0.5rem',
});

type Props = {
  label: string;
  icon: string;
  placeholder: string;
  isLoading?: boolean;
  disabled?: boolean;
  width?: string;
  onClick?: Common.AnyFunction;
};

const CSDFakeInput = ({ label, icon, placeholder, disabled, isLoading, width = '300px', onClick }: Props) => {
  const handleClick = (event: MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    if (isFunction(onClick) && !disabled) {
      onClick(event);
    }
  };

  return (
    <StyledBox onClick={handleClick}>
      {isLoading ? (
        <StyledSkeletonLabel variant="rectangular" width={`${label?.length * 0.3}rem`} />
      ) : (
        <Typography
          sx={{
            fontSize: '0.875rem',
            cursor: 'default',
            marginBottom: ({ spacing }) => spacing(1),
            color: ({ palette: { text } }) => disabled && text.t5,
          }}
          variant="subtitle2"
        >
          {label}
        </Typography>
      )}

      {isLoading ? (
        <StyledSkeletonInput variant="rectangular" width={width} />
      ) : (
        <StyledStack
          sx={{
            width,
            border: ({ palette: { primary, text } }) => `1px solid ${disabled ? text.t5 : primary.main}`,
            ':hover': {
              outline: ({ palette: { primary } }) => !disabled && `1px solid ${primary.main}`,
            },
          }}
        >
          <Icon sx={{ color: ({ palette: { text } }) => (disabled ? text.t5 : text.t3) }}>{icon}</Icon>
          <Typography sx={{ color: ({ palette: { text } }) => (disabled ? text.t5 : text.t3) }} fontSize="0.875rem">
            {placeholder}
          </Typography>
        </StyledStack>
      )}
    </StyledBox>
  );
};

export default CSDFakeInput;
