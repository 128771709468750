import React, { memo, useMemo } from 'react';
import { KeyPrefix, withTranslation } from 'react-i18next';
import clsx from 'clsx';
import groupBy from 'lodash/groupBy';
import intersection from 'lodash/intersection';
import { Icon, Stack, StackProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { styled } from '@mui/material/styles';
import { getScrollStyles } from '@theme/themeSettings/componentSettings';
import { CSDSwitcher } from '@app/modules/kit-module/shared/ui';
import { strictlyEqual, isDefaultProfile } from '@app/v2/shared/helpers';
import { Groups } from '@app/v2/shared/enums';

type Props = {
  activeProfile: Profiles.CommonProfile;
  setupParameters: Meteo.ParametersSetupItemValue[];
  onChange: (key: string) => void;
  onGroupChange: (groupLabel: keyof typeof Groups, nextValue: boolean) => void;
} & I18N.TranslationFunction<'parameters'>;

const groupOrders: { [key: string]: number } = {
  [Groups.Temperature]: 1,
  [Groups.Precipitation]: 2,
  [Groups.Wind]: 3,
  [Groups.DeicingAgents]: 4,
  [Groups.Common]: 5,
  [Groups.TransportTypes]: 6,
  [Groups.DPGTemperature]: 7,
  [Groups.Other]: 8,
};

const CSDTableDataSetupParameters = ({ activeProfile, setupParameters, onChange, onGroupChange, t }: Props) => {
  const groupedSetups = useMemo<Record<Groups, Meteo.ParametersSetupItemValue[]>>(
    () => groupBy(setupParameters, item => item.groupRelation),
    [setupParameters],
  );

  const sortedGroupSetups = useMemo(
    () =>
      Object.entries(groupedSetups)
        .map(([title, items]) => ({
          title,
          items,
          order: groupOrders[title],
        }))
        .sort((a, b) => a.order - b.order),
    [groupedSetups],
  );

  const getItemsGroupStatus = (groupKey: keyof typeof Groups): boolean => {
    const currentGroupLabels = groupedSetups[groupKey].map(({ label }) => label);
    const activeItemsKeys = activeProfile.items.filter(({ checked }) => checked).map(({ key }) => key);

    return Boolean(intersection(currentGroupLabels, activeItemsKeys).length);
  };

  const getItemStatus = (key: string): boolean => {
    const item = activeProfile.items.find(i => strictlyEqual<string>(i.key, key));

    return item.checked;
  };

  const getIconColor = (theme: Theme, key: string): string => {
    return getItemStatus(key) ? theme.palette.primary.main : theme.palette.text.t3;
  };

  const getLabelColor = (theme: Theme, key: string): string => {
    return !getItemStatus(key) && theme.palette.text.t3;
  };

  return (
    <Wrapper>
      {sortedGroupSetups.map(({ title, items }) => (
        <Group key={title}>
          <CSDSwitcher
            checked={getItemsGroupStatus(title as keyof typeof Groups)}
            onChange={(_, checked) => onGroupChange(title as keyof typeof Groups, checked)}
            label={t(title as KeyPrefix<'parameters'>)}
            disabled={isDefaultProfile(activeProfile)}
            labelStyles={{
              marginBottom: '0.5rem',
              lineHeight: '1.4rem',
              fontSize: '1.25rem',
              fontWeight: 700,
              margin: 0,
            }}
          />

          <GroupItems>
            {Array.isArray(items) &&
              items.map(item => (
                <GroupItem key={item.label} className={clsx({ disabled: isDefaultProfile(activeProfile) })} onClick={() => onChange(item.label)}>
                  <Icon sx={{ color: theme => getIconColor(theme, item.label), marginRight: '0.5rem' }}>{item.icon}</Icon>
                  <Typography sx={{ color: theme => getLabelColor(theme, item.label) }}>{t(item.label)}</Typography>
                </GroupItem>
              ))}
          </GroupItems>
        </Group>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled(Stack)<StackProps>(
  ({
    theme: {
      palette: { mode, text },
    },
  }) => ({
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '1.5rem',
    marginTop: '1rem',
    paddingBottom: '1rem',
    overflowX: 'auto',
    overflowY: 'hidden',
    ...getScrollStyles(mode, text),
  }),
);

const Group = styled(Stack)<StackProps>({
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '1rem',
  minWidth: '18.75rem',
});

const GroupItems = styled(Stack)<StackProps>({
  flexDirection: 'column',
  gap: '1rem',
  maxHeight: '15.5rem',
  flexWrap: 'wrap',
});

const GroupItem = styled(Stack)<StackProps>({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '0.5rem',
  cursor: 'pointer',
  '&.disabled': {
    pointerEvents: 'none',
    cursor: 'default',
  },
});

export default withTranslation('parameters')(memo(CSDTableDataSetupParameters));
