import { KeyPrefix } from 'react-i18next';

export enum TabNames {
  settings = 'SETTINGS',
  documents = 'DOCUMENTS',
}

export type TabType = {
  id: number;
  name: TabNames;
  label: KeyPrefix<'dialogs'>;
  component;
  icon?: JSX.Element;
};

export type BillingDocument = {
  customer: string;
  dueDebtDate: string;
  executor: string;
  status: number;
  title: string;
};

export type BillingDocumentTableData = {
  id: number;
  customer: string;
  dueDebtDate: string;
  executor: string;
  status: JSX.Element | string;
  title: string;
  bgColor: string;
};

export type BillingResponse = {
  billing: {
    documents: BillingDocument[];
    isUnpaid: boolean;
  };
};
