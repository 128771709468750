import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { Stack, StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTimePeriods, useWaterLevelChart } from '@app/v2/shared/hooks';
import { CSDDataRangePickerAntdWithBtns } from '@app/modules/kit-module/shared/ui';
import { strictlyEqual } from '@app/v2/shared/helpers';
import { DATE_FORMAT } from '@app/v2/shared/constants';

const WaterLevelChartSettingsPanel = ({ t }: I18N.TranslationFunction<'charts'>) => {
  const { dateTo, dateFrom, handleSetDateTo, handleSetDateFrom, handleSetInitDates } = useWaterLevelChart();

  const { now, weekAgo, yesterday } = useTimePeriods();

  const handleChangeDates = ([nextDateFrom, nextDateTo]: [Moment, Moment]): void => {
    handleSetDateFrom(nextDateFrom);
    handleSetDateTo(nextDateTo);
  };

  useEffect(() => {
    handleSetInitDates();
  }, [handleSetInitDates]);

  return (
    <StyledWrapper direction="row">
      <StyledMainActions direction="row">
        <CSDDataRangePickerAntdWithBtns
          label={t('settingsPanel.labelDate')}
          value={[dateFrom, dateTo]}
          format={DATE_FORMAT.FORMAT_RU_POINT}
          onChange={([nextDateFrom, nextDateTo]) => handleChangeDates([nextDateFrom.startOf('day'), nextDateTo.endOf('day')])}
          disabledDate={current => current && moment().endOf('day').isBefore(current)}
          btnsSetup={[
            {
              label: 'dates.day',
              action: () => handleChangeDates([yesterday.startOf('day'), now.endOf('day')]),
              isActive: strictlyEqual(dateFrom.day(), yesterday.day()) && strictlyEqual(dateTo.day(), now.day()),
            },
            {
              label: 'dates.week',
              action: () => handleChangeDates([weekAgo.startOf('day'), now.endOf('day')]),
              isActive: strictlyEqual(dateFrom.day(), weekAgo.day()) && strictlyEqual(dateTo.day(), now.day()),
            },
          ]}
        />
      </StyledMainActions>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Stack)<StackProps>({
  width: '100%',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  padding: '1rem 1.5rem',
  boxShadow: '0px 5px 5px 0px rgba(52, 52, 52, 0.20)',
});

const StyledMainActions = styled(Stack)<StackProps>({
  width: '90%',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  gap: '1rem',
});

export default withTranslation('charts')(WaterLevelChartSettingsPanel);
