import { MeterLength } from '../enums';

export default function meterZeroes(meter: number): string {
  const meterString = meter.toString();
  switch (meterString.length) {
    case MeterLength.OneDigit:
      return `00${meterString}`;
    case MeterLength.TwoDigits:
      return `0${meterString}`;
    case MeterLength.ThreeDigits:
      return meterString;
    case MeterLength.FourDigits:
      return meterString;
    default:
      throw new Error('Invalid meter length');
  }
}
