const preparePhone = (phone: string) => {
  if (!phone) return null;
  const emptyPhoneValues = { value: '', length: 1 };

  const phoneNumbers = phone.replace(/[^0-9]/g, '');

  if (phoneNumbers.length === emptyPhoneValues.length) {
    return emptyPhoneValues.value;
  }
  return `+${phoneNumbers}`;
};

export default preparePhone;
