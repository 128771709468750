import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { useMutation } from '@apollo/client';
import Dialog from '@app/components/common/dialog/Dialog';
import { DialogRef } from '@app/core/providers/DialogProvider';
import { showError } from '@app/core/utils/notifications';
import notificationMutations from '@app/clients/apollo/requests/mutations/notifications';
import { useWSContext } from '@app/v2/shared/contexts';

type Props = {
  id: number;
} & DialogRef;

const NotificationRejectDialog = ({ close, id, beforeClose }: Props) => {
  const { hideNotification } = useWSContext();
  const { t } = useTranslation('dialogs', { keyPrefix: 'notificationReject' });
  const [reason, setReason] = useState<string>('');
  const [isReasonFieldHasEmpty, setIsReasonFieldHasEmpty] = useState<boolean>(false);

  const [rejectMutation, { loading }] = useMutation(notificationMutations.reject);
  const commentRef = useRef(null);

  useEffect(() => {
    if (reason.length && isReasonFieldHasEmpty) {
      setIsReasonFieldHasEmpty(false);
    }
  }, [reason, isReasonFieldHasEmpty]);

  const handleSubmit = async () => {
    if (!reason?.length) {
      setIsReasonFieldHasEmpty(true);
      return;
    }
    await rejectMutation({
      variables: {
        id,
        reason,
        comment: commentRef?.current?.value || '',
      },
    })
      .then(() => {
        hideNotification(id);
        close();
      })
      .catch(e => showError(e.message));
  };

  return (
    <Dialog loading={loading} handleSubmit={() => handleSubmit()} title={t('title')} maxWidth="sm" fullWidth close={close} beforeClose={beforeClose}>
      <>
        <TextField
          size="small"
          error={isReasonFieldHasEmpty}
          helperText={isReasonFieldHasEmpty ? t('error.required') : null}
          required
          fullWidth
          label={t('rejectReason')}
          variant="outlined"
          margin="dense"
          onChange={e => setReason(e.target.value)}
          placeholder={t('rejectReason')}
        />
        <TextField
          inputRef={commentRef}
          size="small"
          fullWidth
          multiline
          label={t('comment')}
          variant="outlined"
          rows={4}
          margin="dense"
          placeholder={t('comment')}
        />
      </>
    </Dialog>
  );
};
export default NotificationRejectDialog;
