import React from 'react';
import { Stack } from '@mui/material';
import { createEmptyArray } from '@app/v2/shared/utils';
import { DataTestIds } from '@app/v2/shared/enums';
import CSDMessageField from './CSDMessageField';

const CSDMessageFields = ({ numLines, ...other }: Scoreboards.TemplateFieldsOptions) => {
  return (
    <Stack sx={{ width: '100%' }} data-testid={DataTestIds.TemplateMessageFields}>
      {createEmptyArray(numLines)
        .map((_, index) => ({ field: CSDMessageField, key: index }))
        .map(({ field: Field, key }, fieldIndex) => (
          <Field key={key} fieldIndex={fieldIndex} {...other} />
        ))}
    </Stack>
  );
};

export default CSDMessageFields;
