import React from 'react';
import { Box, BoxProps, Icon, IconButton, Stack, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import icons from '@app/assets/iconFont';
import { isFunction } from '@app/v2/shared/helpers';

type Props = WLC.ChartPointParameters & {
  onClose: Common.VoidCallBack;
  openDialog?: Common.VoidCallBack;
};

const CSDWaterLevelTooltip = ({ date, coords, data, isFixed, onClose, openDialog }: Props) => {
  return (
    <Wrapper sx={{ left: coords.x, top: coords.y }}>
      <Header>
        <span>{date}</span>

        <Box>
          <IconButton onClick={openDialog}>
            <Icon>{icons.camera}</Icon>
          </IconButton>
          <IconButton
            onClick={() => {
              if (!isFixed || !isFunction(onClose)) return;
              onClose();
            }}
          >
            <Icon>{icons.close}</Icon>
          </IconButton>
        </Box>
      </Header>
      <Point>
        {!!data.length &&
          data.map(({ icon, value, valueSuffix, name }) => (
            <Tooltip title={name} key={`${name}${valueSuffix}`}>
              <Stack direction="row" gap="0.5rem" alignItems="center" justifyContent="flex-star">
                <Icon>{icons[icon]}</Icon>
                {value}
                {valueSuffix}
              </Stack>
            </Tooltip>
          ))}
      </Point>
    </Wrapper>
  );
};

export default CSDWaterLevelTooltip;

const Wrapper = styled(Box)<BoxProps>({
  position: 'absolute',
  maxWidth: '15rem',
  minWidth: '15rem',
  backgroundColor: '#6F6E6E',
  borderRadius: '5px',
  borderColor: 'transparent',
  opacity: 0.8,
  color: '#fff',
  padding: '0.35rem 0.5rem 0.35rem 0.5rem',
});

const Header = styled(Box)<BoxProps>({
  color: '#ffffff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '0.5rem 0.5rem 0.25rem 0.5rem',
  fontSize: '1rem',
  fontWeight: '700',
  borderBottom: '1px solid white',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '0.25rem',
  },
});

const Point = styled(Box)<BoxProps>({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  rowGap: '0.5rem',
  columnGap: '0.5rem',
  fontSize: '1rem',
  padding: '0.4rem 0.3rem 0.5rem 0.3rem',
  flexWrap: 'wrap',
  maxHeight: '22rem',
});
