import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonProps, Icon } from '@mui/material';
import { DataTestIds, MainPaths, ReportsSections, ReportType } from '@app/v2/shared/enums';
import icons from '@app/assets/iconFont';
import { allReports } from '@app/v2/shared/constants';
import useSearchQuery from '@app/core/hooks/useSearchQuery';
import CSDLinkButton from '../CSDLinkButton/CSDLinkButton';

const possiblePaths: Partial<Record<MainPaths, string>> = {
  [MainPaths.Meteo]: allReports[ReportsSections.Common][ReportType.Meteo][0].url,
  [MainPaths.Traffic]: allReports[ReportsSections.Common][ReportType.Traffic][0].url,
  [MainPaths.LocatorAnalysis]: allReports[ReportsSections.Common][ReportType.Locator][0].url,
  [MainPaths.MeteoManagement]: allReports[ReportsSections.Services][ReportType.ServiceMeteo][0].url,
  [MainPaths.TrafficManagement]: allReports[ReportsSections.Services][ReportType.ServiceTraffic][0].url,
  [MainPaths.VideoManagement]: allReports[ReportsSections.Services][ReportType.ServiceVideo][0].url,
};

interface Props extends ButtonProps {
  additionalClickAction?: Common.VoidCallBack;
}

const CSDReportsButton = ({ additionalClickAction, ...btnsProps }: Props) => {
  const { t } = useTranslation('common');

  const search = useSearchQuery();

  return (
    <CSDLinkButton
      label={t('buttons.reports')}
      dataTestId={DataTestIds.ReportListingLinkBtn}
      buttonProps={{
        startIcon: <Icon>{icons.download}</Icon>,
        ...btnsProps,
      }}
      linkProps={{
        onClick: additionalClickAction,
        to: ({ pathname }) => {
          const current = Object.keys(possiblePaths).find(path => pathname === path);
          return { pathname: possiblePaths[current], search };
        },
      }}
    />
  );
};

export default memo(CSDReportsButton);
