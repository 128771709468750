import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Box } from '@mui/material';
import useNotificationProfileContext from '@app/v2/features/notificationProfile/context/useNotificationProfileContext';

const CSDNotificationSettingsActions = ({ t }: I18N.TranslationFunction<'dialogs', 'common.buttons'>) => {
  const { saveChanges, isLoading } = useNotificationProfileContext();

  return (
    <Box>
      <Button disabled={isLoading} variant="contained" color="primary" onClick={saveChanges}>
        {t('apply')}
      </Button>
    </Box>
  );
};

export default withTranslation('dialogs', { keyPrefix: 'common.buttons' })(CSDNotificationSettingsActions);
