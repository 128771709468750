import { colorMap, Colors, colorList } from '@app/v2/shared/constants';

export const getBackgroundColorForMarker = (visibilityParam: string) => {
  return (
    colorMap[visibilityParam] || {
      backgroundColor: Colors.BLACK,
      backgroundTriangleColor: Colors.BLACK_TRIANGLE,
      backgroundShadeColor: Colors.BLACK_SHADE,
    }
  );
};

export const getBackgroundColorForListing = (visibilityParam: string) => {
  return (
    colorList[visibilityParam] || {
      backgroundColor: Colors.BLACK,
    }
  );
};
