export default function roadsToRoadsSegments(roads: Common.RoadPartItem[] = []): Common.RoadsSegments {
  return roads.map(roadPart => ({
    id: roadPart.value,
    code: roadPart.code,
    title: roadPart.label,
    titleFull: roadPart.label,
    isMeteoLinearGraph: roadPart.isMeteoLinearGraph,
    isMeteoRoadPlanGraph: roadPart.isMeteoRoadPlanGraph,
  }));
}
