export enum PublicPath {
  Auth = '/auth',
  Confirmation = '/confirmation',
  Recovery = '/password-recovery',
  PasswordRecovery = '/password-recovery/:recoveryCode',
}

export enum MainPaths {
  Index = '/index',
  Meteo = '/meteo',
  Traffic = '/traffic',
  Map = '/map',
  Video = '/video',
  VideoWaterLevelControl = '/video-water-level-control',
  MapNotes = '/map-notes',
  MeteoManagement = '/services/meteoManagement',
  TrafficManagement = '/services/trafficManagement',
  ScoreboardsAndSigns = '/scoreboard/scoreboard_and_signs_v2',
  Reports = '/reports/:reportSection/:reportGroup/:reportType',
  ReportsServices = '/reports/services/:reportGroup/:reportType',
  MeteoReports = '/meteo/reports/:id',
  ServiceMeteoReports = '/services/meteo/reports/:id',
  VideoReports = '/video/reports/:id',
  ServiceVideoReports = '/services/video/reports/:id',
  VideoManagement = '/services/videoManagement',
  TrafficReports = '/traffic/reports/:id',
  ServiceTrafficReports = '/services/traffic/reports/:id',
  ScoreboardConfiguration = '/scoreboard/configuration',
  ScoreboardDevices = '/scoreboard/devices',
  ScoreboardTemplates = '/scoreboard/templates',
  ScoreboardSigns = '/scoreboard/signs',
  ScoreboardPlaylists = '/scoreboard/playlists',
  Favorites = '/favorites',
  LogAccident = '/logs/accident',
  LogDuty = '/logs/duty',
  LogEmergencySituationFacility = '/logs/emergency-situation-facility',
  LogEmergencySituationBuilding = '/logs/emergency-situation-building',
  LogEmergencySituation = '/logs/emergency-situation',
  LogBreaks = '/logs/breaks',
  LogTrafficRestriction = '/logs/traffic-restriction',
  LogForcesAttracting = '/logs/forces-attracting',
  LogFloodEffects = '/logs/flood-effects',
  LogHazard = '/logs/hazard',
  GuidesOrganizations = '/guides/organizations',
  GuidesUsers = '/guides/users',
  GuidesRoads = '/guides/roads',
  Notifications = '/notifications',
  CityWeatherForecast = '/specGidroMed/cityWeatherForecast',
  SOPS = '/SOPS',
  AdaptationToClimateChange = '/adaptation-to-climate-change',
  LocatorAnalysis = '/locator-analysis',
  ECOMonitoring = '/eco-monitoring',
  StationDetails = '/station-details',
  AccidentNews = '/accidentNews',
  Incidents = '/incidents',
  FederalRoads = '/federal-roads',
  Dashboard = '/dashboard',
  Monitor = '/monitor',
  SituationCenter = '/situation-center',
  RoadManagementOrgan = '/road-management-organ',
}
