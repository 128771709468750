import React, { memo } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import { boxShadowLight } from '@theme/styles';
import { useCheckAccess } from '@app/v2/shared/hooks';
import { UsersAccess } from '@app/v2/shared/enums';
import PlaylistFormActionPanel from './PlaylistFormActionPanel';
import PlaylistForm from '../PlaylistForm/PlaylistForm';

const PlaylistsFormWithActionPanel = ({
  id,
  fieldsLength,
  onRemove,
  onSwapToTop,
  onSwapToBottom,
  isOnePlaylistItem,
  ...other
}: Scoreboards.PlaylistsFormProps) => {
  const classes = useStyles();
  const { accessChecker } = useCheckAccess();

  return (
    <Box className={classes.fieldsWrapper}>
      {accessChecker([UsersAccess.VMS_LIMIT_EDIT, UsersAccess.VMS_FULL_EDIT]) && (
        <PlaylistFormActionPanel
          id={id}
          onRemove={onRemove}
          onSwapToTop={onSwapToTop}
          fieldsLength={fieldsLength}
          onSwapToBottom={onSwapToBottom}
          isOnePlaylistItem={isOnePlaylistItem}
        />
      )}

      <PlaylistForm id={id} fieldsLength={fieldsLength} isOnePlaylistItem={isOnePlaylistItem} {...other} />
    </Box>
  );
};

const useStyles = makeStyles<Theme>(({ palette }) => ({
  fieldsWrapper: {
    gap: '1rem',
    width: '100%',
    display: 'flex',
    padding: '1rem',
    borderRadius: '1rem',
    flexDirection: 'column',
    boxShadow: boxShadowLight,
    border: `1px solid ${palette.text.t5}`,

    '& .templateView': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '& .alertMessage': {
      width: '100%',
    },
  },
}));

export default memo(PlaylistsFormWithActionPanel);
