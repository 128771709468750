import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, Popover, Slider, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import icons from '@app/assets/iconFont';
import { usePopover } from '@app/v2/shared/hooks';
import { createEmptyArray } from '@app/v2/shared/utils';

interface Props {
  startTime: string;
  endTime: string;
  amountOfSliderValues?: [number, number];
  handleChange: (nextStartTime: string, nextEndTime: string) => void;
}

const CSDTimePiker = ({ startTime, endTime, amountOfSliderValues = [0, 24], handleChange, t }: Props & I18N.TranslationFunction<'components'>) => {
  const classes = useStyles();

  const { isOpen, openPopover, closePopover, anchorEl } = usePopover();

  const [sliderValue, setSliderValue] = useState<number[]>(amountOfSliderValues);

  const valuesMapper = createEmptyArray(amountOfSliderValues[1] + 1).reduce((acc, item, index) => {
    acc[index] = `${index}:00`;
    return acc;
  }, {});

  return (
    <>
      <Icon sx={{ cursor: 'pointer' }} onClick={openPopover}>
        {icons.time}
      </Icon>
      <Stack sx={{ cursor: 'pointer' }} onClick={openPopover}>
        <Typography variant="body2">{startTime}</Typography>
        <Typography variant="body2">{endTime}</Typography>
      </Stack>

      <Popover
        open={isOpen}
        slotProps={{
          paper: {
            className: classes.popoverPaper,
          },
        }}
        onMouseDown={e => e.stopPropagation()}
        onClose={closePopover}
        anchorEl={anchorEl}
      >
        <Typography variant="body2">{t('timePiker.chooseTime')}</Typography>
        <Slider
          sx={{ display: 'block' }}
          value={sliderValue}
          max={amountOfSliderValues[1]}
          valueLabelFormat={value => valuesMapper[value]}
          valueLabelDisplay="on"
          onChange={(_, nextValue) => {
            setSliderValue(nextValue as number[]);
            handleChange(valuesMapper[nextValue[0]], valuesMapper[nextValue[1]]);
          }}
        />
      </Popover>
    </>
  );
};

const useStyles = makeStyles<Theme>({
  popoverPaper: {
    '&.MuiPopover-paper': {
      padding: '1rem',
      minWidth: '17rem',
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      gap: '1rem',
      justifyContent: 'space-between',

      '& .MuiSlider-valueLabel': {
        top: 0,
        fontSize: '0.75rem',
      },
    },
  },
});

export default withTranslation('components')(CSDTimePiker);
