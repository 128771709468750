import React from 'react';
import { Box, Slider, Stack } from '@mui/material';
import { rangeSlider, slider } from './example';
import CSDKitDocBlock from '../../../shared/ui/CSDKitDocBlock/CSDKitDocBlock';

const CSDSliders = () => {
  const [value, setValue] = React.useState<number[]>([20, 37]);

  const handleChange = (_, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="Slider" codeExample={slider}>
        <Box sx={{ width: '300px' }}>
          <Slider valueLabelDisplay="on" />
        </Box>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Range slider" codeExample={rangeSlider}>
        <Box sx={{ width: '300px' }}>
          <Slider value={value} onChange={handleChange} />
        </Box>
      </CSDKitDocBlock>
    </Stack>
  );
};

export default CSDSliders;
