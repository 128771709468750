import { useCallback } from 'react';
import moment from 'moment';
import { Pages } from '@app/v2/shared/enums';
import useAppSelector from './reactRedux/useAppSelector';
import { isDateWithTimeZone, isEqualTimeZones } from '../utils';
import { getTimeZone, strictlyEqual } from '../helpers';
import { NO_DATA, DATE_FORMAT } from '../constants';

type FormatTimeZoneTypes = Pages | 'all';

interface ShowTimeOptions {
  format: string;
  listingType: FormatTimeZoneTypes;
  parseZoneFormat: string;
}

interface ShowTimeParams
  extends Partial<{
    value: string;
    options: Partial<ShowTimeOptions>;
  }> {}

interface ShowTime {
  now: (options?: Partial<ShowTimeOptions>) => string;
  showTime: ({ value, options }?: ShowTimeParams) => string;
}

const timeZoneFormats: Partial<Record<FormatTimeZoneTypes, string>> = {
  [Pages.Traffic]: ' UTC(Z)',
  all: 'Z',
};

const getOptions = (userOptions: Partial<ShowTimeOptions>): ShowTimeOptions => {
  return {
    listingType: userOptions?.listingType ?? 'all',
    format: userOptions?.format ?? DATE_FORMAT.FORMAT_RU_FULL,
    parseZoneFormat: userOptions?.parseZoneFormat ?? DATE_FORMAT.WITH_TIME_ZONE,
  };
};

const addZoneFormat = (value: string, zoneFormat) => `${value}${zoneFormat}`;

export default function useShowTime(): ShowTime {
  const isWithTimeZoneOutput = useAppSelector(state => state.account.settings.timezoneInsteadCheckbox);
  const utc = useAppSelector(state => state.account.settings.utc);

  const showTime = useCallback(
    ({ value = moment().toISOString(), options = {} }: ShowTimeParams = {}): string => {
      if (!value) return NO_DATA;

      const { format, listingType, parseZoneFormat } = getOptions(options);

      const timeWithTimeZone = moment.parseZone(value, parseZoneFormat).format(addZoneFormat(format, timeZoneFormats[listingType]));
      const timeWithoutTimeZone = moment(value).utcOffset(value).format(format);

      const differentOutput: Partial<Record<Pages, string>> = {
        [Pages.Traffic]: strictlyEqual<string>(getTimeZone(value), utc) ? timeWithoutTimeZone : timeWithTimeZone,
      };

      if (differentOutput?.[listingType] && isWithTimeZoneOutput) return differentOutput[listingType];

      if (!isDateWithTimeZone(value)) {
        return !isWithTimeZoneOutput
          ? moment.utc(value).format(addZoneFormat(format, timeZoneFormats[listingType]))
          : moment.utc(value).format(format);
      }

      if (isEqualTimeZones(value)) return timeWithoutTimeZone;
      if (!isWithTimeZoneOutput) return timeWithTimeZone;

      return timeWithoutTimeZone;
    },
    [isWithTimeZoneOutput, utc],
  );

  const now = useCallback(
    ({ format = DATE_FORMAT.FORMAT_RU_FULL }: Partial<ShowTimeOptions> = {}): string => {
      if (isWithTimeZoneOutput && utc) return moment().utcOffset(utc).format(format);
      return moment().format(format);
    },
    [isWithTimeZoneOutput, utc],
  );

  return {
    now,
    showTime,
  };
}
