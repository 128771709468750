import React from 'react';
import { MessageType } from '@app/v2/shared/enums';
import CSDNotificationAlertCard from './CSDNotificationAlertCard';
import CSDNotificationBulletinCard from './CSDNotificationBulletinCard';

interface Props {
  notification: Notifications.Models;
}

const NotificationPanelListItem = ({ notification }: Props) => {
  const renderMapper: Notifications.RenderMapper = {
    [MessageType.Bulletin]: (notificationItem: Notifications.BulletinModel) => <CSDNotificationBulletinCard notificationItem={notificationItem} />,
    [MessageType.Alert]: (notificationItem: Notifications.AlertModel) => <CSDNotificationAlertCard notificationItem={notificationItem} />,
  };

  return <>{renderMapper[notification.type](notification)}</>;
};

export default NotificationPanelListItem;
