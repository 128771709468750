import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, IconButton, Tooltip } from '@mui/material';
import { useMutation } from '@apollo/client';
import icons from '@app/assets/iconFont';
import { addFeatureActions } from '@app/v2/shared/utils';
import favouriteMutations from '@app/clients/apollo/requests/mutations/favorites';
import { FavoritesStationTypes, UpdateFavoritesActions } from '@app/v2/shared/enums';

interface UpdateFavoritesVariables {
  action: UpdateFavoritesActions;
  placeId?: number;
  type: FavoritesStationTypes;
  stationIds?: number[];
}

interface Props extends Common.FeaturesActions, I18N.TranslationFunction<'components', 'favoriteButton'> {
  currentStatus: boolean;
  variables: Omit<UpdateFavoritesVariables, 'action'>;
}

const CSDFavoritesBtn = ({ currentStatus, variables, t, ...featuresActions }: Props) => {
  const { icon, tooltipTitle, action } = useMemo(
    () => ({
      tooltipTitle: currentStatus ? t('delete') : t('add'),
      icon: currentStatus ? icons.starFill : icons.starOutline,
      action: currentStatus ? UpdateFavoritesActions.Remove : UpdateFavoritesActions.Add,
    }),
    [currentStatus, t],
  );

  const [updateFavoritesStatus, { loading }] = useMutation<Common.MutationResponse, UpdateFavoritesVariables>(favouriteMutations.update);

  const updateFavoritesStatusHandler = () => {
    addFeatureActions(
      updateFavoritesStatus({
        variables: {
          ...variables,
          action,
        },
      }),
      featuresActions,
    );
  };

  return (
    <Tooltip title={tooltipTitle}>
      <IconButton disabled={loading} onClick={updateFavoritesStatusHandler}>
        <Icon>{icon}</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default withTranslation('components', { keyPrefix: 'favoriteButton' })(CSDFavoritesBtn);
