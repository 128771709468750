import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Drawer } from '@mui/material';
import getHumanizedAddress from '@app/core/helpers/getHumanizedAddress';
import { NOTIFICATION_FILTERS, NOTIFICATION_FILTERS_INITIAL_STATE } from '@app/core/constants/notification';
import { useActions, useAppSelector } from '@app/v2/shared/hooks';
import { useWSContext } from '@app/v2/shared/contexts';
import { MessageType } from '@app/v2/shared/enums';
import CSDNotificationsHeaderWithFilters from './CSDNotificationsHeaderWithFilters';
import CSDNotificationSettings from './notificationSettings/CSDNotificationSettings';
import CSDNotificationsList from './CSDNotificationsList';
import Bulletins from '../enums';
import type { FiltersValue } from '../types';

const filterKeysByType: Notifications.FilterKeysMapperNew = {
  [MessageType.Alert]: (notification: Notifications.AlertModel) => ({
    ...notification,
    filterKey: notification.meta.rankId,
  }),
  [MessageType.Bulletin]: (notification: Notifications.BulletinModel) => ({
    ...notification,
    filterKey: notification.bulletin !== Bulletins.RGMNotice ? Bulletins.RGMForecastFourth : notification.bulletin,
  }),
};

const CSDNotifications = ({ t }: I18N.TranslationFunction<'common'>) => {
  const {
    data: { alert: alertsData, bulletin: bulletinsData },
  } = useWSContext();

  const isOpen = useAppSelector(({ system }) => system.isNotificationOpen);
  const { changeNotificationStatus } = useActions();
  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);

  const onClose = useCallback(() => {
    changeNotificationStatus({ status: !isOpen });
    setIsSettingsOpen(false);
  }, [isOpen, changeNotificationStatus]);

  const handleToggleSettings = useCallback(() => setIsSettingsOpen(prev => !prev), []);

  const [filtersValue, setFiltersValue] = useState<FiltersValue>(
    JSON.parse(localStorage.getItem(NOTIFICATION_FILTERS)) || NOTIFICATION_FILTERS_INITIAL_STATE,
  );

  const filteredNotifications = useMemo(() => {
    const notifications: Notifications.Models[] = [...alertsData, ...bulletinsData];

    return notifications
      .map((notificationModel: Notifications.Models): Notifications.ModelsWithFilters => filterKeysByType[notificationModel.type](notificationModel))
      .filter(notification => (filtersValue?.alertType ? notification?.filterKey === Number(filtersValue?.alertType) : notification))
      .filter(notification => {
        if (notification.type === MessageType.Alert) {
          if (!notification.meta.isActive) return false;
          const notificationTitle = `${getHumanizedAddress(t('units.kilometers'), notification?.place?.kilometer, notification?.place?.meter)}, ${
            notification?.place?.road?.title
          }`;

          if (filtersValue.placeValue.length) {
            return notificationTitle.toLowerCase().includes(filtersValue.placeValue.toLowerCase());
          }
        }

        return true;
      });
  }, [alertsData, bulletinsData, filtersValue?.alertType, filtersValue.placeValue, t]);

  useEffect(() => {
    localStorage.setItem(NOTIFICATION_FILTERS, JSON.stringify(filtersValue));

    return () => localStorage.removeItem(NOTIFICATION_FILTERS);
  }, [filtersValue]);

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right" PaperProps={{ sx: { flexDirection: 'row', overflow: 'hidden' } }}>
      <CSDNotificationSettings isOpen={isSettingsOpen} onClose={handleToggleSettings} />

      <Box flexDirection="column">
        <CSDNotificationsHeaderWithFilters
          filtersValue={filtersValue}
          setFiltersValue={setFiltersValue}
          onClose={onClose}
          onSettings={handleToggleSettings}
        />

        <CSDNotificationsList filteredNotifications={filteredNotifications} />
      </Box>
    </Drawer>
  );
};

export default withTranslation('common')(CSDNotifications);
