import { i18n } from '@app/v2/shared/localization';
import { DataTestIds, FiltersAlertStatus, FiltersReactions, Pages } from '@app/v2/shared/enums';

export const FiltersWarningsConfig: StaticConfigs.TypeOfWarningItem[] = [
  {
    label: i18n.t('filters:typeOfWarning.danger'),
    value: FiltersAlertStatus.Alert,
    color: 'red',
    dataTestId: DataTestIds.FiltersAlertStatusDangerBtn,
  },
  {
    label: i18n.t('filters:typeOfWarning.attention'),
    value: FiltersAlertStatus.Warning,
    color: 'yellow',
    dataTestId: DataTestIds.FiltersAlertStatusAttentionBtn,
  },
  {
    label: i18n.t('filters:typeOfWarning.all'),
    value: FiltersAlertStatus.Notice,
    color: 'green',
    hiddenForPages: [Pages.Notifications],
    dataTestId: DataTestIds.FiltersAlertStatusNoWarningBtn,
  },
];

export const FilterReactionsConfig: StaticConfigs.TypeOfReactionItem[] = [
  {
    label: i18n.t('filters:reaction.none'),
    value: FiltersReactions.None,
    dataTestId: DataTestIds.FiltersReactionStatusNoneBtn,
  },
  {
    label: i18n.t('filters:reaction.all'),
    value: FiltersReactions.All,
    dataTestId: DataTestIds.FiltersReactionStatusAllBtn,
  },
  {
    label: i18n.t('filters:reaction.accept'),
    value: FiltersReactions.Accept,
    dataTestId: DataTestIds.FiltersReactionStatusAcceptBtn,
  },
  {
    label: i18n.t('filters:reaction.reject'),
    value: FiltersReactions.Reject,
    dataTestId: DataTestIds.FiltersReactionStatusRejectBtn,
  },
];
