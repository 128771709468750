import React from 'react';
import CSDCreateMeteoChartBtn from './CSDCreateMeteoChartBtn';
import CSDConstructorPopover from '../CSDConstructorPopover/CSDConstructorPopover';

interface Props {
  profileId: string;
  disabled?: boolean;
  chartsSetup: Meteo.ChartsSetup;
}

const CSDCreateMeteoChart = (props: Props) => {
  return <CSDConstructorPopover<HTMLButtonElement> AnchorNode={CSDCreateMeteoChartBtn} {...props} />;
};

export default CSDCreateMeteoChart;
