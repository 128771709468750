import React, { useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import { useMutation } from '@apollo/client';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { DialogRef } from '@app/core/providers/DialogProvider';
import { DialogData } from '@app/components/common/dialog/types/DialogData';
import { addFeatureActions } from '@app/v2/shared/utils';
import { CSDDialogActions } from '@app/modules/kit-module/shared/ui';
import { showApolloErrors } from '@app/core/utils/notifications';

interface Props extends DialogRef {
  dialogData: DialogData<Omit<Scoreboards.TemplateModel, 'id'>>;
}

const CreateTemplatesDialog = ({ close, beforeClose, dialogData }: Props) => {
  const { component, title, values, schema, mutation, featureActions } = dialogData;

  const formik = useRef<FormikProps<Omit<Scoreboards.TemplateModel, 'id'>>>();
  const [createTemplate, { loading }] = useMutation(mutation, {
    onError: error => {
      showApolloErrors(error.graphQLErrors);
    },
  });

  const onSubmit = (formValues: Omit<Scoreboards.TemplateModel, 'id'>) => {
    const {
      title: templateTitle,
      organizationId,
      isCommon,
      content: { contents, configuration },
    }: any = schema.validateSync(formValues);

    addFeatureActions(
      createTemplate({
        variables: {
          title: templateTitle,
          isCommon,
          organizationId: organizationId ? Number(organizationId) : null,
          template: {
            content: {
              contents,
              configurationId: configuration.id,
              configurationTitle: configuration.title,
            },
          },
        },
      }).then(response => {
        close(response.data.id);

        return response;
      }),
      featureActions,
    );
  };

  return (
    <Dialog open maxWidth="lg" fullWidth>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <Formik innerRef={formik} initialValues={values} validationSchema={schema} onSubmit={onSubmit} component={component} />
      </DialogContent>

      <CSDDialogActions
        disabled={loading}
        doneHandler={() => {
          formik.current.handleSubmit();
        }}
        closeHandler={() => {
          close();
        }}
      />
    </Dialog>
  );
};

export default CreateTemplatesDialog;
