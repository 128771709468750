import React from 'react';
import { Badge, Stack, Icon } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import { CSDBadge as CSDOpenCloseBudges } from '@app/modules/kit-module/shared/ui';
import { basicBadge, basicColor, customBadge, maximumPropBadge, dotBadge } from './example';
import CSDKitDocBlock from '../../../shared/ui/CSDKitDocBlock/CSDKitDocBlock';

const CsdBadges = () => {
  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="Basic badge" codeExample={basicBadge}>
        <Badge badgeContent={4} color="primary">
          <MailIcon color="action" />
        </Badge>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Basic color" codeExample={basicColor}>
        <Stack spacing={2} direction="row">
          <Badge badgeContent={4} color="secondary">
            <MailIcon color="action" />
          </Badge>
          <Badge badgeContent={4} color="success">
            <MailIcon color="action" />
          </Badge>
        </Stack>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Custom badge" codeExample={customBadge}>
        <Stack spacing={2} direction="row">
          <CSDOpenCloseBudges max={4}>
            <Icon>slippery</Icon>
            <Icon>temperature-change</Icon>
            <Icon>wind</Icon>
            <Icon>mixed-rainfall</Icon>
            <Icon>slippery-2</Icon>
            <Icon>liquid-precipitation</Icon>
            <Icon>skid</Icon>
          </CSDOpenCloseBudges>
        </Stack>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Maximum value" codeExample={maximumPropBadge}>
        <Stack spacing={2} direction="row">
          <Badge color="secondary" badgeContent={99}>
            <MailIcon color="action" />
          </Badge>
          <Badge color="secondary" badgeContent={100}>
            <MailIcon color="action" />
          </Badge>
          <Badge color="secondary" badgeContent={1000} max={999}>
            <MailIcon color="action" />
          </Badge>
        </Stack>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Dot badge" codeExample={dotBadge}>
        <Badge color="secondary" variant="dot">
          <MailIcon color="action" />
        </Badge>
      </CSDKitDocBlock>
    </Stack>
  );
};

export default CsdBadges;
