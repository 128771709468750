import React from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Button, CardActions, CardActionsProps } from '@mui/material';
import { useActions, useAppSelector } from '@app/v2/shared/hooks';
import { PasswordRecoveryStatuses, PublicPath } from '@app/v2/shared/enums';

const FormAction = ({ t }: I18N.TranslationFunction<'auth', 'passwordRecovery.cardActions'>) => {
  const passwordRecoveryStatus = useAppSelector(state => state.auth.passwordRecoveryStatus);
  const { push } = useHistory();
  const { changePasswordRecoveryStatus } = useActions();

  const handleRedirect = () => {
    changePasswordRecoveryStatus(null);
    push(PublicPath.Auth);
  };

  return (
    <StyledCardActions>
      {[PasswordRecoveryStatuses.SUCCESS, PasswordRecoveryStatuses.INVALID].includes(passwordRecoveryStatus) ? (
        <Button color="primary" fullWidth size="large" onClick={handleRedirect}>
          {t('toAuthPage')}
        </Button>
      ) : (
        <Button type="submit" color="primary" fullWidth size="large">
          {t('resetPassword')}
        </Button>
      )}
    </StyledCardActions>
  );
};

const StyledCardActions = styled(CardActions)<CardActionsProps>({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 1.5rem',
  width: '100%',
  margin: 0,
  marginBottom: '1.5rem',
});

export default withTranslation('auth', { keyPrefix: 'passwordRecovery.cardActions' })(FormAction);
