import React from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import icons from '@app/assets/iconFont';
import { DataTestIds } from '@app/v2/shared/enums';

interface Props {
  onEdit: Common.VoidCallBack;
}

const CSDTPICardEdit = ({ onEdit, t }: Props & I18N.TranslationFunction<'scoreboard'>) => {
  const classes = useStyles();

  return (
    <Tooltip title={t('playlists.actions.update')}>
      <IconButton className={classes.edit} onClick={onEdit} data-testid={DataTestIds.VMSCardEditButton}>
        <Icon>{icons.edit}</Icon>
      </IconButton>
    </Tooltip>
  );
};

const useStyles = makeStyles({
  edit: {
    '&.MuiButtonBase-root': {
      position: 'absolute',
      top: '1rem',
      right: '1rem',

      '& .custom-icon': {
        '&.MuiIcon-root': {
          fontSize: '1.5rem',
        },
      },
    },
  },
});

export default withTranslation('scoreboard')(CSDTPICardEdit);
