import React, { ReactNode } from 'react';
import { SelectProps } from '@mui/material';
import { FastField } from 'formik';
import CSDSelect from '../CSDSelect/CSDSelect';

interface Props {
  path: string;
  label?: ReactNode;
  items: Common.SelectorValues;
  selectProps?: SelectProps & { withEmptyValue?: boolean };
}

const CSDSelectFastField = ({ path, items, label, selectProps = {} }: Props) => {
  return (
    <FastField name={path} shouldUpdate={(current, next) => current?.disabled === next?.disabled}>
      {({ field, meta }) => {
        return (
          <CSDSelect
            items={items}
            label={label}
            value={field.value}
            name={field.name}
            onChange={field.onChange}
            error={meta.touched && Boolean(meta.error)}
            fullWidth={selectProps?.fullWidth ?? true}
            helperText={meta.error}
            {...selectProps}
          />
        );
      }}
    </FastField>
  );
};

export default CSDSelectFastField;
