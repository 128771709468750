/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReducersNames } from '@store/constants';
import { SortStatus } from '@app/v2/shared/enums';

interface State {
  sortingValue: Common.Sorting;
}

export const initialState: State = {
  sortingValue: { key: '', value: SortStatus.None },
};

const sortingSlice = createSlice({
  name: ReducersNames.sorting,
  initialState,
  reducers: {
    setSortingValue(state: State, { payload }: PayloadAction<{ key: string; value: SortStatus }>) {
      state.sortingValue = { key: payload.key, value: payload.value };
    },
  },
});

export const sortingActions = sortingSlice.actions;

export default sortingSlice.reducer;
