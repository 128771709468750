import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import fetchSignsSelector from '@app/clients/apollo/requests/queries/vmsNew/fetchSignsSelector.graphql';
import SignContext from '@app/modules/scoreboard-module/contexts/sign/SignContext';
import { ResponseSelectors } from '@app/modules/scoreboard-module/contexts/types';

type Props = {
  children: JSX.Element;
};

const SignContextProvider = ({ children }: Props) => {
  const { data: signsSelectorsData, loading: loadingSigns } = useQuery(fetchSignsSelector);

  const [signsSelectors, setSignsSelectors] = useState<ResponseSelectors>([]);

  const loading = useMemo(() => loadingSigns, [loadingSigns]);

  useEffect(() => {
    if (!loading) {
      setSignsSelectors(signsSelectorsData.vmsNew.selectors.templates.sign);
    }
  }, [loading, signsSelectorsData]);

  return <SignContext.Provider value={{ signsSelectors }}>{children}</SignContext.Provider>;
};

export default SignContextProvider;
