import { StationTypes, UsersAccess } from '@app/v2/shared/enums';
import icons from '@app/assets/iconFont';
import { i18n } from '@app/v2/shared/localization';

const favoritesLayouts: StaticConfigs.FavoritesLayoutsItem[] = [
  {
    icon: icons.sky,
    tooltipTitle: i18n.t('favorites:meteo'),
    type: StationTypes.Meteo,
  },
  {
    icon: icons.traffic,
    tooltipTitle: i18n.t('favorites:traffic'),
    type: StationTypes.Traffic,
  },
  {
    icon: icons.deviationRain,
    tooltipTitle: i18n.t('favorites:sops'),
    type: StationTypes.Sops,
  },
  {
    icon: icons.tpi,
    tooltipTitle: i18n.t('favorites:TPI'),
    type: StationTypes.Vms,
    perimeters: [UsersAccess.VMS_FULL_EDIT, UsersAccess.VMS_LIMIT_EDIT, UsersAccess.VMS_VIEW_ONLY],
  },
];

export default favoritesLayouts;
