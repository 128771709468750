import React, { memo, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { Box, Stack } from '@mui/material';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { CSDButtonSkeleton, CSDHorizontalSlick, CSDRoadSegment } from '@app/modules/kit-module/shared/ui';
import { getSkeletonItems } from '@app/v2/shared/helpers';
import { useAppSelector, useFilterStationType, useNewFilters, useSystem } from '@app/v2/shared/hooks';
import roadQueries from '@app/clients/apollo/requests/queries/roads';
import { linearGradient } from '@theme/styles';

const CSDHeaderRoadsSegments = () => {
  const classes = useStyles();

  const { filters } = useNewFilters();
  const { stationType } = useFilterStationType();
  const { isListingLoaded } = useSystem();

  const { data, loading } = useQuery<Common.RoadPlanGraphsResponse, Common.RoadPlanGraphsVar>(roadQueries.roadPlanGraph, {
    skip: !isListingLoaded,
    variables: {
      // endpoint with pagination. Trick to fetch all data from server
      limit: 1000000,
      page: 1,
      roadIds: filters?.roads ?? [],
      organizationIds: filters?.organizations ?? [],
      placeIds: filters?.places ?? [],
      stationType,
    },
    fetchPolicy: 'cache-first',
  });

  const [roadSegments, setRoadSegments] = useState<Common.RoadsSegmentState>({});

  useEffect(() => {
    setRoadSegments(
      data?.planGraphRoads?.data.reduce<Common.RoadsSegmentState>((acc, planGraphRoadsData, index) => {
        acc[`roadId-${planGraphRoadsData.id}`] = {
          ...planGraphRoadsData,
          index,
        };

        return acc;
      }, {}) ?? {},
    );
  }, [data]);

  const [mainRoad, ...otherRoads] = useAppSelector(state => state.system.roadsOnPage, shallowEqual);

  return (
    <Box className="segmentsContainer">
      {!loading ? (
        <CSDHorizontalSlick index={roadSegments[`roadId-${mainRoad}`]?.index}>
          {Object.values(roadSegments).map(roadSegment => (
            <CSDRoadSegment
              key={roadSegment.id}
              roadSegment={roadSegment}
              roadSegmentClasses={clsx({
                [classes.main]: roadSegment.id === mainRoad,
                [classes.additional]: otherRoads.includes(roadSegment.id),
              })}
            />
          ))}
        </CSDHorizontalSlick>
      ) : (
        <Stack direction="row" gap="0.5rem">
          {getSkeletonItems(5).map(item => (
            <CSDButtonSkeleton key={item} />
          ))}
        </Stack>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  main: {
    background: linearGradient,
    color: 'white',
    border: '1px solid white',
  },
  additional: {
    background: '#3245AF',
    color: 'white',
    border: '1px solid white',
  },
});

export default memo(CSDHeaderRoadsSegments);
