import React from 'react';
import { Stack } from '@mui/material';
import { CSDDynamicRoadInterface } from '@app/modules/kit-module/shared/ui';
import { CSDStationItem } from '@app/modules/kit-module/shared/ui/CSDDynamicRoadInterface/types';
import { dynamicRoadInterfaceExample, dynamicRoadInterfaceMultiExample } from './example';
import CSDKitDocBlock from '../../../shared/ui/CSDKitDocBlock/CSDKitDocBlock';

const mockStations: CSDStationItem[] = [
  {
    position: 'top',
    direction: 'forward',
    stationId: 1,
    isExpired: true,
  },
  {
    position: 'top',
    direction: 'backward',
    stationId: 5,
    isExpired: false,
  },
  {
    position: null,
    direction: null,
    stationId: 6,
    isExpired: false,
  },
  {
    position: null,
    direction: 'backward',
    stationId: 8,
    isExpired: false,
  },
  {
    position: 'middle',
    direction: 'backward',
    stationId: 7,
    isExpired: false,
  },
  {
    position: 'middle',
    direction: 'backward',
    stationId: 2,
    isExpired: false,
  },
  {
    position: 'bottom',
    direction: null,
    stationId: 3,
    isExpired: false,
  },
];

const backwartTitle = 'Москва';
const forwardTitle = 'до транспортной развязки на пересечении а/д А-107 "ММК" и а/д М-11 "Москва-СПб"';
const time = '12:30:46';

const CSDDynamicRoadInterfaceMUI = () => {
  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="DynamicRoadInterface" codeExample={dynamicRoadInterfaceExample}>
        <CSDDynamicRoadInterface
          activeStationId={2}
          stations={mockStations}
          forward={1}
          backward={1}
          backwardRoadTitle={backwartTitle}
          forwardRoadTitle={forwardTitle}
          time={time}
          width="1000px"
          height="600px"
        />
      </CSDKitDocBlock>
      <CSDKitDocBlock title="DynamicRoadInterface with multi roads" codeExample={dynamicRoadInterfaceMultiExample}>
        <CSDDynamicRoadInterface
          activeStationId={1}
          stations={mockStations}
          forward={6}
          backward={6}
          backwardRoadTitle={backwartTitle}
          forwardRoadTitle={forwardTitle}
          time={time}
          width="1000px"
          height="600px"
        />
      </CSDKitDocBlock>
    </Stack>
  );
};

export default CSDDynamicRoadInterfaceMUI;
