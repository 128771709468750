import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Theme, Stack, Typography } from '@mui/material';
import CSDIconButton from '@app/modules/kit-module/shared/ui/CSDIconButton/CSDIconButton';
import usePaginationSearch from '@app/core/source/hooks/pagination/usePaginationSearch';
import { useSystem } from '@app/v2/shared/hooks';
import icons from '@app/assets/iconFont';

interface PaginationInformation extends Common.Pagination {
  totalPages: number;
  isLast: boolean;
  isFirst: boolean;
  from: number;
  to: number;
}

const getPaginationInformation = (pagination: Common.Pagination): PaginationInformation => {
  const { page, limit, total } = pagination;

  const totalPages = Math.ceil(total / limit);
  const isLast = page === totalPages;
  const isFirst = page === 1;
  const from = Math.max((page - 1) * limit + 1, 1);
  const to = Math.min(page * limit, total);

  return {
    ...pagination,
    totalPages,
    isLast,
    isFirst,
    from,
    to,
  };
};

const CSDPaginationSearch = () => {
  const classes = useStyles();
  const { isDisablePagination } = useSystem();
  const [pagination, setPagination] = usePaginationSearch();
  const { t } = useTranslation('video');
  const { isLast, isFirst, from, to, totalPages, page, total } = getPaginationInformation(pagination);

  const handlePageChange = (nextPage: number) => {
    if (isDisablePagination) return;
    setPagination(prev => {
      return {
        ...prev,
        page: nextPage,
      };
    });
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <CSDIconButton
        icon={icons.leftleft}
        className={classes.actionButton}
        disabled={isDisablePagination || isFirst}
        onClick={() => handlePageChange(1)}
      />
      <CSDIconButton
        icon={icons.left}
        className={classes.actionButton}
        disabled={isDisablePagination || isFirst}
        onClick={() => handlePageChange(page - 1)}
      />
      <Typography variant="h5">
        {`${from}-${to} `}
        <span className={classes.secondaryText}>{`${t('pagination.from')} ${total}`}</span>
      </Typography>
      <CSDIconButton
        icon={icons.right}
        className={classes.actionButton}
        disabled={isDisablePagination || isLast}
        onClick={() => handlePageChange(page + 1)}
      />
      <CSDIconButton
        icon={icons.rightright}
        className={classes.actionButton}
        disabled={isDisablePagination || isLast}
        onClick={() => handlePageChange(totalPages)}
      />
    </Stack>
  );
};

const useStyles = makeStyles<Theme>(({ palette: { text }, typography }) => ({
  actionButton: {
    '&.MuiIconButton-root': {
      border: `1px solid ${text.t1}`,
      borderRadius: '0.5rem',
      padding: '0.25rem 0.5rem',
    },
  },
  secondaryText: {
    color: text.t3,
    fontWeight: typography.fontWeightRegular,
  },
}));

export default CSDPaginationSearch;
