// eslint-disable-next-line import/prefer-default-export
export enum StationTypes {
  Meteo = 'meteo',
  Video = 'video',
  Traffic = 'traffic',
  Vms = 'vms',
  Map = 'map',
  DPGT = 'dpgt',
  Sops = 'sops',
  Eco = 'eco',
  VWLC = 'waterLevel',
}
