/* eslint-disable react/prop-types */
import * as React from 'react';
import { Badge, BadgeProps, Box, Icon, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  cursor: 'pointer',
  alignItems: 'center',
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.error.main,
    position: 'initial',
    transform: 'unset',
    transition: 'unset',
    '&.MuiBadge-invisible': {
      display: 'none',
    },
  },
}));

interface Props extends BadgeProps {
  children: React.ReactElement | React.ReactElement[];
  max: number;
  onClick?: () => void;
}

const CSDOpenCloseBudges = ({ children, onClick, color = 'error', max, ...props }: Props) => {
  const ref = React.useRef<HTMLElement>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const shortRepresentation = Array.isArray(children) ? children.slice(0, max) : children;
  const badgeContent = Array.isArray(children) ? children.length - max : null;
  const isInvisible = badgeContent <= 0 || isOpen;

  React.useEffect(() => {
    const checkIfClickedOutside = e => {
      if (isOpen && ref.current && !ref.current?.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, [isOpen]);

  return (
    <StyledBadge ref={ref} {...props} color={color} invisible={isInvisible} badgeContent={badgeContent} onClick={() => setIsOpen(!isOpen)}>
      <Box display="flex" alignItems="center">
        <Stack spacing={1} direction="row">
          {isOpen ? children : shortRepresentation}
        </Stack>
        {!isInvisible && <Icon>plus-small</Icon>}
      </Box>
    </StyledBadge>
  );
};

export default CSDOpenCloseBudges;
