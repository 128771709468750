import { useCallback } from 'react';
import useActions from './reactRedux/useActions';
import useAppDispatch from './reactRedux/useAppDispatch';
import useAppSelector from './reactRedux/useAppSelector';

interface ToggleChatStatusReturn {
  isChatOpen: boolean;
  handleChangeChatStatus: (status: boolean) => void;
  amountOfUnreadMessages: number;
  handleSetAmountOfUnreadMessages: (amount: number) => void;
}

export default function useChat(): ToggleChatStatusReturn {
  const dispatch = useAppDispatch();
  const isChatOpen = useAppSelector(state => state.chat.isChatOpen);
  const amountOfUnreadMessages = useAppSelector(state => state.chat.amountOfUnreadMessages);

  const { toggleChatStatus, setAmountOfUnreadMessages } = useActions();

  const handleChangeChatStatus = useCallback(
    (status: boolean): void => {
      dispatch(toggleChatStatus({ isChatOpen: status }));
    },
    [dispatch, toggleChatStatus],
  );

  const handleSetAmountOfUnreadMessages = useCallback(
    (amount: number): void => {
      dispatch(setAmountOfUnreadMessages({ amount }));
    },
    [dispatch, setAmountOfUnreadMessages],
  );

  return {
    isChatOpen,
    handleChangeChatStatus,
    amountOfUnreadMessages,
    handleSetAmountOfUnreadMessages,
  };
}
