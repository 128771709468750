import * as React from 'react';
import Grid from '@mui/material/Grid';
import { Box, Icon, Typography, Tooltip } from '@mui/material';
import icons from '@app/assets/iconFont';
import UpdateNoteButton from '@app/modules/map-module/components/map/notes/common/actions/UpdateNoteButton';
import { MapNoteType } from '@app/modules/map-module/components/map/notes/types';
import { FULL_WIDTH, DATE_FORMAT } from '@app/v2/shared/constants';
import EllipsisTooltip from '@app/components/notes/EllipsisTooltip';
import MessageWithReadMore from '@app/components/notes/MessageWithReadMore';
import { getBackgroundColorForListing } from '@app/v2/shared/helpers';
import NoteImagesViewerWithAdditionalImagesAmount from '@app/components/notes/NoteImagesViewerWithAdditionalImagesAmount';
import NoteHeader from '../NoteHeader';

const generateHeaders = (deps: {
  t: Function;
  showTime: any;
  classes: any;
  formatAddress: any;
  formatRoad: any;
  updateNoteButtonProps: any;
  onReset: any;
  openNodeInfoDialog: Function;
  closePopperHandler?: Function;
}) => {
  const { t, showTime, classes, formatAddress, formatRoad, updateNoteButtonProps, openNodeInfoDialog } = deps;

  return [
    {
      value: values => <NoteHeader deps={deps} values={values} />,
      label: '',
      width: FULL_WIDTH,
    },
    {
      value: note => (
        <Grid container pb={1}>
          <MessageWithReadMore
            message={note.message}
            title={note.title}
            address={formatAddress(note.address, note.place.latitude, note.place.longitude)}
            roadAddress={note.isAddress ? note.address : `${note.road.titleFull} ${formatRoad(note.place.kilometer, note.place.meter)}`}
            isAddress={note.isAddress}
            date={showTime({ value: note.dateTime, options: { format: DATE_FORMAT.FORMAT_RU_POINT } })}
            time={showTime({ value: note.dateTime, options: { format: DATE_FORMAT.FORMAT_RU_HOURS } })}
            photo={note.photos}
            organization={note.organization.name}
            user={note.user.name}
            addressIcon={icons.pin}
            roadIcon={icons.road}
            dateIcon={icons.calender}
            timeIcon={icons.time}
            messageClassName={classes.messageText}
            buttonClassName={classes.readMoreButton}
            visibilityBox={note.visibility}
            UpdateNoteButtonComponent={UpdateNoteButton}
            updateNoteButtonProps={{
              ...updateNoteButtonProps,
              dialogData: { ...updateNoteButtonProps.dialogData, note },
              id: note.id,
            }}
            openNodeInfoDialog={openNodeInfoDialog}
          />
        </Grid>
      ),
      label: '',
      width: FULL_WIDTH,
    },
    {
      value: ({ place: { kilometer, meter, latitude, longitude }, road, address, isAddress }: MapNoteType) => (
        <Grid container className={classes.header} justifyContent="flex-start">
          {isAddress ? (
            <Grid item className={classes.iconWithText}>
              <Icon className={classes.icon}>{icons.pin}</Icon>
              <Tooltip title={address} placement="left">
                <Typography variant="body2" className={classes.oneLineAddresses}>
                  {address || `${latitude}, ${longitude}`}
                </Typography>
              </Tooltip>
            </Grid>
          ) : (
            <Grid item className={classes.iconWithText}>
              <Icon className={classes.icon}>{icons.road}</Icon>
              <Tooltip title={isAddress ? address : `${road.titleFull || t('no-title')}${formatRoad(kilometer, meter)}`} placement="right">
                <Typography variant="body2" className={classes.oneLineAddresses}>
                  {`${road.code || t('no-code')}${formatRoad(kilometer, meter)}`}
                </Typography>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      ),
      label: '',
      width: FULL_WIDTH,
    },
    {
      value: note => (
        <Grid container justifyContent="flex-start" spacing={1}>
          <Grid item className={classes.iconWithText}>
            <Icon className={classes.icon}>{icons.calender}</Icon>
            <Typography variant="body2" className={classes.oneLineDates}>
              {showTime({
                value: note.dateTime,
                options: {
                  format: DATE_FORMAT.FORMAT_RU_POINT,
                },
              })}
            </Typography>
          </Grid>
          <Grid item className={classes.iconWithText}>
            <Icon className={classes.icon}>{icons.time}</Icon>
            <Typography variant="body2" className={classes.oneLineDates}>
              {showTime({
                value: note.dateTime,
                options: {
                  format: DATE_FORMAT.FORMAT_RU_HOURS,
                },
              })}
            </Typography>
          </Grid>
        </Grid>
      ),
      label: '',
      width: FULL_WIDTH,
    },
    {
      value: note => {
        return (
          <Grid container justifyContent="center" alignItems="center" className={classes.imageContainer}>
            <NoteImagesViewerWithAdditionalImagesAmount
              images={note.photos}
              title={note.title}
              address={formatAddress(note.address, note.place.latitude, note.place.longitude)}
              roadAddress={note.isAddress ? note.address : `${note.road.titleFull} ${formatRoad(note.place.kilometer, note.place.meter)}`}
              date={showTime({ value: note.dateTime, options: { format: DATE_FORMAT.FORMAT_RU_POINT } })}
              time={showTime({ value: note.dateTime, options: { format: DATE_FORMAT.FORMAT_RU_HOURS } })}
              photo={note.photos}
              organization={note.organization.name}
              user={note.user.name}
              addressIcon={icons.pin}
              roadIcon={icons.road}
              dateIcon={icons.calender}
              timeIcon={icons.time}
              visibilityBox={note.visibility}
              updateNoteButtonProps={{
                ...updateNoteButtonProps,
                dialogData: { ...updateNoteButtonProps.dialogData, note },
                id: note.id,
              }}
              message={note.message}
              className={classes.readMoreButton}
              UpdateNoteButtonComponent={UpdateNoteButton}
            />
          </Grid>
        );
      },
      label: '',
      width: FULL_WIDTH,
    },
    {
      value: note => (
        <Grid container direction="column">
          <EllipsisTooltip className={classes.oneLineOrganizationUser} text={note.user.name} placement="bottom" />
          <EllipsisTooltip className={classes.oneLineOrganizationUser} text={note.organization.name} placement="bottom" />
        </Grid>
      ),
      label: '',
      width: FULL_WIDTH,
    },
    {
      value: note => (
        <Grid container pt={1}>
          <Tooltip title={`${t('form.noteVisibility')}: ${t(`form.visibilitySelect.${note.visibility}` as any)}`} placement="bottom">
            <Box width={FULL_WIDTH} sx={{ bgcolor: getBackgroundColorForListing(note.visibility) }} className={classes.visibilityBox} />
          </Tooltip>
        </Grid>
      ),
      label: '',
      width: FULL_WIDTH,
    },
  ];
};

export default generateHeaders;
