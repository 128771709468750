import React, { useCallback } from 'react';
import { useTranslation, KeyPrefix } from 'react-i18next';
import { Box, Theme, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CSDSwitcher } from '@app/modules/kit-module/shared/ui';
import useMapProfileContext from '@app/v2/features/mapProfiles/hooks/useMapProfileContext';
import { ProfileMapSettingsCheckboxLabels } from '@app/v2/features/mapProfiles/types';
import { ProfileMapSettingLabels } from '@app/v2/shared/enums';
import { isDefaultMapProfile } from '@app/v2/shared/helpers';
import { useCheckAccess } from '@app/v2/shared/hooks';
import SelectableTabItem from '../components/SelectableTabItem';
import meteoTabConfiguration, { prepareDataWithColumnPosition } from '../../configurations/meteoTab';
import MapProfilesManager from '../components/MapProfilesManager';
import DisabledWrapperWithTooltip from '../components/DisabledWrapperWithTooltip';

const MeteoPanel = ({ hideProfileActions }: { hideProfileActions: boolean }) => {
  const classes = useStyles();
  const { t } = useTranslation('map');
  const { profileData, currentActiveProfile, handleChangeProfileCheckbox, handleSelectAllSettingByKeys } = useMapProfileContext();

  const { accessChecker } = useCheckAccess();

  const isSomeRoadObjectSelected = meteoTabConfiguration
    .find(({ key }) => key === ProfileMapSettingLabels.ROAD_OBJECTS)
    .data.some(({ key }) => profileData?.[ProfileMapSettingLabels.ROAD_OBJECTS][key]?.checked);

  const handleSelectAllRoadObjects = useCallback(() => {
    const keys = meteoTabConfiguration.find(({ key }) => key === ProfileMapSettingLabels.ROAD_OBJECTS).data.map(({ key }) => key);
    handleSelectAllSettingByKeys(ProfileMapSettingLabels.ROAD_OBJECTS, keys, !isSomeRoadObjectSelected);
  }, [handleSelectAllSettingByKeys, isSomeRoadObjectSelected]);

  return (
    <Box className={classes.meteoContainer}>
      <MapProfilesManager hideProfileActions={hideProfileActions} />
      <DisabledWrapperWithTooltip disabled={isDefaultMapProfile(currentActiveProfile)}>
        <Box className={classes.meteoContainerItems}>
          {profileData &&
            meteoTabConfiguration.map(({ group, data, key }) => {
              const meteoPanelData = profileData[key];
              const prepatedColumns = prepareDataWithColumnPosition(
                data.filter(({ perimeters }) => (Array.isArray(perimeters) ? accessChecker(perimeters) : true)),
              );
              const isDisabled = isDefaultMapProfile(currentActiveProfile);

              return (
                <Box key={group} className={classes.meteoItem}>
                  <CSDSwitcher
                    disabled={isDisabled}
                    label={t(group) as string}
                    checked={isSomeRoadObjectSelected}
                    onChange={handleSelectAllRoadObjects}
                  />
                  <Grid container spacing={0} sx={{ marginTop: '1rem', maxWidth: '600px' }}>
                    {prepatedColumns.map(([keyColumn, columnData]) => (
                      <Grid item xs={6} key={keyColumn}>
                        {columnData.map(({ key: dataKey, icon, label, listOfIncludes }) => (
                          <SelectableTabItem
                            key={dataKey}
                            label={t(label) as string}
                            icon={icon}
                            disabled={isDisabled}
                            isActive={meteoPanelData[dataKey]?.checked}
                            informationList={listOfIncludes?.map(infoKey => ({
                              key: infoKey,
                              value: t(infoKey as KeyPrefix<'map'>) as string,
                            }))}
                            onClick={() =>
                              handleChangeProfileCheckbox(key as ProfileMapSettingsCheckboxLabels, dataKey, !meteoPanelData[dataKey]?.checked)
                            }
                          />
                        ))}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              );
            })}
        </Box>
      </DisabledWrapperWithTooltip>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  meteoContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  meteoContainerItems: {
    display: 'flex',
    width: '100%',
  },
  meteoItem: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '1.56rem',
    alignItems: 'flex-start',
    width: '100%',
  },
  meteoColumnItems: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '1rem',
    color: theme.palette.text.t2,
    '&.active': {
      color: theme.palette.primary.main,
      '& > span:last-child': {
        color: theme.palette.text.primary,
      },
    },
  },
  meteoColumnItemsText: {
    fontSize: '0.875rem',
    marginLeft: '0.625rem',
  },
}));

export default MeteoPanel;
