enum ProfileMapSettingLabels {
  SETTINGS = 'settings',
  ROAD_OBJECTS = 'roadObjects',
  WEATHER_RADARS = 'locatorImages',
  GIS = 'gis',
  ACTIVE_LAYER = 'activeLayer',
  MAP_LOCATION = 'viewport',
  NOTES = 'notes',
  MARKER_ZOOMS = 'markerZooms',
}

export default ProfileMapSettingLabels;
