import React, { useState, useEffect, useCallback, useRef } from 'react';
import clsx from 'clsx';
import { Box, Icon, IconButton, TableCellProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BASE_COLUMN_WIDTH } from '@app/v2/shared/constants';
import CSDTableCell, { HighlightedCell } from './CSDTableCell';

interface Props extends HighlightedCell, TableCellProps {
  label?: string | JSX.Element;
  description?: string;
  className?: string;
  children: React.ReactNode;
}

const CSDOpenCloseTableCell = ({ label = 'Open', description, children, className, rowIndex, columnIndex, ...cellProps }: Props) => {
  const contentRef = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [contentWidth, setContentWidth] = useState<number>(0);
  const classes = useStyles({ isOpen, contentWidth });

  useEffect(() => {
    setContentWidth(contentRef?.current?.clientWidth);

    const checkIfClickedOutside = e => {
      if (isOpen && contentRef.current && !contentRef.current?.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, [isOpen, contentRef?.current?.clientWidth]);

  const handleOpenClose = useCallback(
    e => {
      if (isOpen) {
        e.preventDefault();
        e.stopPropagation();
      }
      setIsOpen(!isOpen);
    },
    [isOpen, setIsOpen],
  );

  return (
    <CSDTableCell
      align="center"
      className={clsx(classes.cellContainer, className)}
      onClick={() => setIsOpen(true)}
      rowIndex={rowIndex}
      columnIndex={columnIndex}
      {...cellProps}
    >
      <Box className={clsx(classes.cellElement, { [classes.activeCell]: isOpen })}>
        <Box className={classes.cellContent} ref={contentRef}>
          {isOpen ? children : label}
          <IconButton aria-label="Open-Close" size="small" color="inherit" sx={{ marginLeft: '0.3rem' }} onClick={handleOpenClose}>
            <Icon>{isOpen ? 'left' : 'right'}</Icon>
          </IconButton>
        </Box>
      </Box>
    </CSDTableCell>
  );
};

const useStyles = makeStyles<Theme, { isOpen: boolean; contentWidth: number }>(theme => ({
  cellContainer: {
    position: 'relative',
    boxSizing: 'border-box',
    height: '100%',
  },
  cellElement: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 0,
    margin: 0,
    height: '100%',
    width: '100%',
  },
  cellContent: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: ({ isOpen }) => isOpen && '1rem',
    margin: 0,
    height: '100%',
  },
  activeCell: {
    width: ({ isOpen, contentWidth }) => (isOpen ? contentWidth : BASE_COLUMN_WIDTH),
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    background: 'linear-gradient(94.5deg, #072F9C -20.35%, #0F79DF 109.04%)',
    color: '#fff',
    zIndex: 3,
    transition: ({ isOpen }) =>
      isOpen
        ? theme.transitions.create(['width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          })
        : theme.transitions.create(['width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
    animation: `$scaleEffect 150ms`,
    '& .Mui-disabled': {
      animation: `$scaleEffect 150ms`,
    },
  },
  '@keyframes scaleEffect': {
    '0%': {
      color: 'transparent',
    },
    '90%': {
      color: 'transparent',
    },
  },
}));

export default CSDOpenCloseTableCell;
