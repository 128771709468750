import React from 'react';
import { Stack } from '@mui/material';
import { CSDDataPickerAntd, CSDDataRangePickerAntd, CSDDataRangePickerAntdWithBtns } from '@app/modules/kit-module/shared/ui';
import {
  datePickerAntdExample,
  datePickerAntdWithTimeExample,
  datePickerRangeAntdExample,
  datePickerRangeAntdWithBtnsExample,
  datePickerRangeAntdWithTimeExample,
  datePickersAntdWithLabelExample,
} from './example';
import CSDKitDocBlock from '../../../shared/ui/CSDKitDocBlock/CSDKitDocBlock';

const CSDDatePickersAntd = () => {
  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="Date picker antd" codeExample={datePickerAntdExample}>
        <CSDDataPickerAntd />
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Date picker antd with time" codeExample={datePickerAntdWithTimeExample}>
        <CSDDataPickerAntd showTime />
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Date range picker antd" codeExample={datePickerRangeAntdExample}>
        <CSDDataRangePickerAntd />
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Date range picker antd with time" codeExample={datePickerRangeAntdWithTimeExample}>
        <CSDDataRangePickerAntd showTime />
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Date pickers antd with label" codeExample={datePickersAntdWithLabelExample}>
        <Stack direction="column" spacing={3}>
          <CSDDataPickerAntd label="Дата" />

          <CSDDataRangePickerAntd label="Период даты" />
        </Stack>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Date picker antd with range btns" codeExample={datePickerRangeAntdWithBtnsExample}>
        <CSDDataRangePickerAntdWithBtns
          label="Дата"
          btnsSetup={[
            {
              label: 'dates.todayTomorrow',
              isActive: true,
              action: () => {},
            },
            {
              label: 'dates.yesterday',
              isActive: false,
              action: () => {},
            },
            {
              label: 'dates.week',
              isActive: false,
              action: () => {},
            },
          ]}
        />
      </CSDKitDocBlock>
    </Stack>
  );
};

export default CSDDatePickersAntd;
