import * as React from 'react';
import { Slider, SliderProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { linearGradient } from '@app/core/theme/styles';

const CSDSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-marked': {
    position: 'relative',
  },
  '& .MuiSlider-mark': {
    height: '1.531rem',
    width: '1px',
    color: theme.palette.text.t1,
    top: '19.5px',
    '&.MuiSlider-markActive': {
      backgroundColor: theme.palette.text.t1,
    },
  },
  '& .MuiSlider-thumb': {
    borderRadius: 'unset',
    height: '2.375rem',
    width: '0.813rem',
    top: '2px',
  },
  '& .MuiSlider-valueLabel': {
    top: '12px',
    color: theme.palette.text.t1,
    borderRadius: '10px',
    zIndex: 1,
    '& > span': {
      width: 'initial',
      height: 'initial',
      padding: '5px 10px',
      transform: 'initial',
      borderRadius: '10px',
      fontSize: '0.85rem',
      '& span': {
        transform: 'initial',
      },
    },
  },
  '& .MuiSlider-track': {
    height: '0.938rem',
    background: linearGradient,
    borderRadius: 'unset',
  },
  '& .MuiSlider-markLabel': {
    top: '35px',
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: 'normal',
    color: theme.palette.text.t1,
  },
  '& .MuiSlider-rail': {
    height: '0.938rem',
    background: '#DBD2FF',
  },
}));

const StyledSlider = (props: SliderProps) => {
  return <CSDSlider {...props} />;
};

export default StyledSlider;
