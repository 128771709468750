import { createContext } from 'react';
import { initialWebSocketContextValue } from '@app/v2/shared/constants';

const WebSocketContext = createContext<WS.ContextValue>({
  hideNotification: () => {},
  updatePartOfData: () => {},
  data: initialWebSocketContextValue,
  isConnected: false,
  isWSLoading: false,
});

export default WebSocketContext;
