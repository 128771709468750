import React, { ReactNode } from 'react';
import { Popper, Theme, PopperPlacementType, IconButton, Icon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isFunction } from '@app/v2/shared/helpers';
import icons from '@app/assets/iconFont';

type Props = {
  anchorEl: HTMLDivElement;
  component: ReactNode;
  placement?: PopperPlacementType;
  onClose?: Common.AnyFunction;
};

const CSDMapMarkerPopover = ({ anchorEl, component, placement = 'bottom', onClose }: Props) => {
  const classes = useStyles();
  const open = Boolean(anchorEl);
  const showCloseBtn = isFunction(onClose);

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      className={classes.popper}
      disablePortal
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, -16],
          },
        },
        {
          name: 'flip',
          enabled: false,
        },
        {
          name: 'preventOverflow',
          enabled: false,
        },
      ]}
      popperOptions={{
        strategy: 'fixed',
      }}
    >
      {showCloseBtn && (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <Icon>{icons.close}</Icon>
        </IconButton>
      )}
      {component}
    </Popper>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  closeButton: {
    '&.MuiIconButton-root': {
      position: 'absolute',
      background: 'white',
      border: `1px solid ${theme.palette.text.t5}`,
      top: '-0.7rem',
      right: '-0.7rem',
      zIndex: 5000,
    },
  },
  popper: {
    position: 'relative',
    top: '-1rem',
    '& .MuiPaper-root': {
      borderRadius: 0,
      boxShadow: 'unset',
    },
  },
}));

export default CSDMapMarkerPopover;
