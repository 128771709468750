import React from 'react';
import { styled } from '@mui/styles';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

const CSDImageTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '42rem',
    padding: 0,
    backgroundColor: 'transparent',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
});

export default CSDImageTooltip;
