import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { CSDSwitcher, CSDTrafficChart } from '@app/modules/kit-module/shared/ui';
import useTrafficChartByLinesOptions from './useTrafficChartByLinesOptions';

interface Props {
  data: Traffic.ChartByLines;
}

const CSDTrafficChartByLines = ({ data, t }: Props & WithTranslation<'charts', 'traffic'>) => {
  const classes = useStyles();

  const [isViewDataLabels, setIsViewDataLabels] = useState<boolean>(false);

  const options = useTrafficChartByLinesOptions({ data, isViewDataLabels });

  return (
    <>
      <CSDTrafficChart className={classes.chart} options={options} />
      <CSDSwitcher label={t('columnData')} size="small" checked={isViewDataLabels} onChange={(_, checked) => setIsViewDataLabels(checked)} />
    </>
  );
};

const useStyles = makeStyles({
  chart: {
    borderRadius: '0.5rem',
    border: '2px solid black',
    padding: '1rem 1.5rem 0 1.5rem',
  },
});

export default withTranslation('charts', { keyPrefix: 'traffic' })(CSDTrafficChartByLines);
