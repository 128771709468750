import React, { useMemo, ReactNode } from 'react';
import { IconButton, Icon, Stack } from '@mui/material';
import { Stop } from '@mui/icons-material';
import { PlayerStatus } from '@app/v2/shared/enums';
import LocatorControlService from '@app/modules/map-module/services/LocatorControlService';
import icons from '@app/assets/iconFont';

type Props = {
  locatorControlService: LocatorControlService;
  playerState: PlayerStatus;
  disabled?: boolean;
};

const PlayerActions = ({ locatorControlService, playerState, disabled }: Props) => {
  const tabs: { ariaLabel: string; icon: ReactNode; disabled?: boolean; click?: () => void }[] = useMemo(() => {
    return [
      {
        ariaLabel: 'locator-play-button',
        icon: <Icon sx={{ fontSize: '3.75rem' }}>{icons.player}</Icon>,
        disabled: playerState === PlayerStatus.PLAY,
        click: () => locatorControlService.play(),
      },
      {
        ariaLabel: 'locator-pause-button',
        icon: <Icon sx={{ fontSize: '2.25rem' }}>{icons.pause}</Icon>,
        disabled: playerState !== PlayerStatus.PLAY,
        click: () => locatorControlService.pause(),
      },
      {
        ariaLabel: 'locator-stop-button',
        icon: <Stop sx={{ fontSize: '2.25rem' }} />,
        disabled: playerState !== PlayerStatus.PLAY,
        click: () => locatorControlService.stop(),
      },
    ];
  }, [playerState, locatorControlService]);

  return (
    <Stack direction="row" spacing={1}>
      {tabs.map(({ ariaLabel, icon, click, disabled: buttonDisabled }) => (
        <IconButton key={ariaLabel} disabled={disabled || buttonDisabled} aria-label={ariaLabel} onClick={click}>
          {icon}
        </IconButton>
      ))}
    </Stack>
  );
};

export default PlayerActions;
