import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import { useCheckAccess, useDialog } from '@app/v2/shared/hooks';
import vmsNewMutations from '@app/clients/apollo/requests/mutations/vmsNew';
import vmsNewQueries from '@app/clients/apollo/requests/queries/vmsNew';
import { EMPTY_STRING } from '@app/v2/shared/constants';
import { UsersAccess } from '@app/v2/shared/enums';
import CSDSetManualModeDialog from './CSDSetManualModeDialog';

const CSDSetManualMode = ({ iconSize, iconColor, stationId, ...props }: Scoreboards.SetManualModeProps) => {
  const { accessChecker } = useCheckAccess();

  const { refetch: fetchStation, loading: stationLoading } = useQuery<Scoreboards.VMSStationResponse, Scoreboards.VMSStationVariables>(
    vmsNewQueries.stations,
    {
      skip: true,
    },
  );

  const [addPlaylistToStation, { loading }] = useMutation<Common.MutationResponse, Scoreboards.AddPlaylistToStationVariables>(
    vmsNewMutations.management.addPlaylistToStation,
  );

  const { t } = useTranslation('scoreboard');

  const openDialog = useDialog<Scoreboards.SetManualModeDialogProps>(CSDSetManualModeDialog);

  const handleClick = async () => {
    const { data } = await fetchStation({ stationIds: [stationId] });

    const playlistId = data.vmsNew.stations?.data?.[0]?.playlist_id;

    openDialog({
      stationId,
      addPlaylistToStation,
      updateLoading: loading,
      playlistId: playlistId ? String(playlistId) : EMPTY_STRING,
      ...props,
    });
  };

  return (
    <Tooltip title={t('singsAndScoreboard.manual.tooltip')}>
      <IconButton
        onClick={handleClick}
        disabled={stationLoading || loading || !accessChecker([UsersAccess.VMS_FULL_EDIT, UsersAccess.VMS_LIMIT_EDIT])}
      >
        <SyncLockIcon
          sx={{
            fontSize: iconSize ?? '3rem',
            color: iconColor ?? 'white',
            opacity: 0.5,
            transform: 'scale(0.75)',

            '&:hover': {
              opacity: 1,
              transform: 'scale(1)',
              transition: 'all 0.3s ease',
            },
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default CSDSetManualMode;
