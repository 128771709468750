import React, { memo, useRef, SyntheticEvent } from 'react';
import { withTranslation } from 'react-i18next';
import { Box, BoxProps, Skeleton } from '@mui/material';
import { styled } from '@mui/styles';
import { isFunction } from '@app/v2/shared/helpers';
import { CSDCenteredBox } from '@app/modules/kit-module/shared/ui';
import { CSDIncidentReport } from '@app/v2/shared/ui/custom';

interface Props {
  url: string;
  stationId: number;
  loading?: boolean;
  clientHeight: number;
  incidents: Common.Incidents;
  setImageRatio?: (value: number) => void;
}

const CSDMainSnapShot = ({
  url,
  loading,
  stationId,
  incidents,
  clientHeight,
  setImageRatio,
  t,
}: Props & I18N.TranslationFunction<'common', 'notificationMessages'>) => {
  const img = useRef<HTMLImageElement>(null);

  const onLoad = (event: SyntheticEvent<HTMLImageElement>) => {
    if (isFunction(setImageRatio)) {
      const { naturalWidth, naturalHeight } = event.currentTarget;
      setImageRatio(naturalWidth > naturalHeight ? naturalWidth / naturalHeight : naturalHeight / naturalWidth);
    }
  };

  if (!url && !loading) return <CSDCenteredBox sx={{ width: '50%', height: 'auto' }}>{t('noData')}</CSDCenteredBox>;

  if (loading) return <Skeleton variant="rectangular" width="50%" height={clientHeight} sx={{ borderRadius: '0.5rem' }} />;

  return (
    <StyledSnapShotWrapper>
      <img ref={img} src={url} alt="VideoSnapshot" style={{ height: clientHeight, borderRadius: '0.5rem' }} onLoad={onLoad} />
      <CSDIncidentReport id={stationId} incidents={incidents} />
    </StyledSnapShotWrapper>
  );
};

export default withTranslation('common', { keyPrefix: 'notificationMessages' })(memo(CSDMainSnapShot));

const StyledSnapShotWrapper = styled(Box)<BoxProps>({
  position: 'relative',
  transition: 'all 0.3s ease-in-out',
  borderRadius: '0.5rem',
  '& > img': {
    height: '100%',
  },
});
