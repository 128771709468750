import moment from 'moment';
import client from '@app/clients/apollo/client';
import tokenStorage, { AUTH_STORAGE_KEY, REFRESH_STORAGE_KEY, REMEMBER_STORAGE_KEY } from '@app/clients/apollo/tokenStorage';
import { EXPIRES_IN, EXPIRES_IN_TIMESTAMP, INCOMPLETE_EMAIL, REFRESH_IN, removeConfirmKeys } from '@app/core/constants/auth';
import { DATE_FORMAT } from '@app/v2/shared/constants';
import ErrorMessages from '@app/core/constants/errorsMessages';
import StatusCodes from '@app/core/constants/statusCodes';
import NotificationMessages from '@app/core/constants/notificationMessages';
import authQueries from '@app/clients/apollo/requests/queries/auth';
import getNotifyTranslationMessage from '@app/core/helpers/getNotifyTranslationMessage';
import getStatusCodes from '@app/core/utils/apollo';
import { PasswordRecoveryStatuses, PublicPath } from '@app/v2/shared/enums';
import { showApolloErrors, showError, showSuccess } from '@app/core/utils/notifications';
import { AppDispatch } from '@app/store';
import { authActions } from '@app/store/slices/auth';
import { loaderActions } from '@app/store/slices/loader';
import { isFunction, strictlyEqual } from '@app/v2/shared/helpers';

const goToAuth = (
  dispatch: AppDispatch,
  {
    errorMessage,
    redirectCb,
  }: Partial<{
    errorMessage: string;
    redirectCb: (path: string) => void;
  }>,
): void => {
  tokenStorage.remove(REFRESH_STORAGE_KEY);
  removeConfirmKeys.forEach(key => localStorage.removeItem(key));
  dispatch(authActions.changeConfirmStatus({ status: false }));
  errorMessage && showError(errorMessage);
  isFunction(redirectCb) && redirectCb(PublicPath.Auth);
};

export const login =
  ({ login: name, password }: Auth.AuthParameters) =>
  async (dispatch: AppDispatch) => {
    dispatch(loaderActions.setLoaderStatus(true));

    try {
      const { data, errors } = await client.query<Auth.LoginResponse, Auth.AuthVar>({
        query: authQueries.login,
        variables: {
          login: name,
          password,
        },
      });

      dispatch(authActions.setAuthErrorMessage({ status: false }));

      if (data?.login?.session) {
        const { accessToken, authenticateToken, incompleteEmail, refreshIn, expiresIn } = data.login.session;

        if (!accessToken && authenticateToken) {
          tokenStorage.set(REFRESH_STORAGE_KEY, authenticateToken);
          localStorage.setItem(INCOMPLETE_EMAIL, incompleteEmail);
          localStorage.setItem(EXPIRES_IN, String(expiresIn));
          localStorage.setItem(REFRESH_IN, String(refreshIn));
          localStorage.setItem(EXPIRES_IN_TIMESTAMP, moment().add(expiresIn, 'seconds').format(DATE_FORMAT.FORMAT_RU_TIME));
          dispatch(authActions.changeConfirmStatus({ status: true }));
        }

        if (accessToken && !authenticateToken) {
          tokenStorage.set(AUTH_STORAGE_KEY, accessToken);

          const { data: currentSessionData, errors: currentSessionErrors } = await client.query({
            query: authQueries.getCurrentSessionRememberToken,
          });

          if (currentSessionData?.getCurrentSessionRememberToken) {
            tokenStorage.set(REMEMBER_STORAGE_KEY, currentSessionData.getCurrentSessionRememberToken);
          }

          if (currentSessionErrors?.length) showApolloErrors(currentSessionErrors);

          dispatch(authActions.loginSuccess({ token: accessToken }));
        }

        if (accessToken && authenticateToken) {
          dispatch(authActions.logout());
          showError(ErrorMessages.COMMON_ERROR_MESSAGE);
        }

        return true;
      }

      if (errors?.length) {
        dispatch(
          authActions.setAuthErrorMessage({
            status: true,
            errorMessage: ErrorMessages.WRONG_DATA,
          }),
        );
      }
    } catch (error) {
      dispatch(authActions.logout());
      showError(ErrorMessages.COMMON_ERROR_MESSAGE);
    } finally {
      dispatch(loaderActions.setLoaderStatus(false));
    }

    return false;
  };

export const confirmLogin =
  ({ authenticateToken, code, redirectCb }: Auth.ConfirmParams & { redirectCb: (path: string) => void }) =>
  async (dispatch: AppDispatch) => {
    dispatch(loaderActions.setLoaderStatus(true));

    try {
      const { data, errors } = await client.query<Auth.ConfirmLoginResponse, Auth.ConfirmVar>({
        query: authQueries.confirmLogin,
        variables: { authenticateToken, code },
      });

      if (data?.login?.twoFactor) {
        const { accessToken, rememberToken } = data.login.twoFactor;

        if (accessToken && rememberToken) {
          dispatch(authActions.changeConfirmStatus({ status: false }));
          tokenStorage.set(AUTH_STORAGE_KEY, accessToken);
          tokenStorage.set(REMEMBER_STORAGE_KEY, rememberToken);
          tokenStorage.remove(REFRESH_STORAGE_KEY);
          removeConfirmKeys.forEach(key => localStorage.removeItem(key));
          dispatch(authActions.loginSuccess({ token: accessToken }));
          window.location.reload();
        }

        if (!accessToken) {
          showError(ErrorMessages.COMMON_ERROR_MESSAGE);
        }

        return true;
      }

      if (errors?.length) {
        dispatch(authActions.setConfirmationError({ status: true }));
        showApolloErrors(errors);
      }

      if (errors?.length && getStatusCodes(errors).includes(StatusCodes.TOO_MANY_REQUEST_429)) {
        goToAuth(dispatch, { redirectCb });
      }
    } catch (error) {
      dispatch(authActions.logout());
      showError(ErrorMessages.COMMON_ERROR_MESSAGE);
    } finally {
      dispatch(loaderActions.setLoaderStatus(false));
    }

    return false;
  };

export const resendCode =
  ({ token, redirectCb }: Auth.ResendCodeParams & { redirectCb: (path: string) => void }) =>
  async (dispatch: AppDispatch) => {
    dispatch(loaderActions.setLoaderStatus(true));

    try {
      const { data, errors } = await client.query<Auth.ResendCodeResponse, Auth.ResendCodeVar>({
        query: authQueries.resendConfirmCode,
        variables: { authenticateToken: token },
      });

      if (data?.login?.resendCode) {
        const { authenticateToken } = data.login.resendCode;

        if (authenticateToken) {
          tokenStorage.set(REFRESH_STORAGE_KEY, authenticateToken);
          showSuccess(getNotifyTranslationMessage(NotificationMessages.SUCCESS_RESEND_MESSAGE));
        }
      }

      if (errors?.length) showApolloErrors(errors);

      if (errors?.length && getStatusCodes(errors).includes(StatusCodes.TOO_MANY_REQUEST_429)) {
        goToAuth(dispatch, { redirectCb });
      }

      return true;
    } catch (error) {
      dispatch(authActions.logout());
      showError(ErrorMessages.COMMON_ERROR_MESSAGE);
    } finally {
      dispatch(loaderActions.setLoaderStatus(false));
    }
    return false;
  };

export const logoutAllOtherSessions = () => async (dispatch: AppDispatch) => {
  dispatch(loaderActions.setLoaderStatus(true));

  try {
    const { data, errors } = await client.query<Auth.LogoutAllOtherSessionsResponse>({
      query: authQueries.logoutAllSessions,
    });

    if (strictlyEqual<number>(data?.logoutAllOtherSessions?.status, StatusCodes.UPDATE_SUCCESSFUL_204)) {
      showSuccess(getNotifyTranslationMessage(NotificationMessages.ALL_SESSIONS_CLOSED));
    }

    if (errors?.length) {
      showApolloErrors(errors);
    }

    return true;
  } catch (error) {
    showError(ErrorMessages.COMMON_ERROR_MESSAGE);
  } finally {
    dispatch(loaderActions.setLoaderStatus(false));
  }

  return false;
};

export const logout =
  (reloadWindow = true) =>
  async dispatch => {
    await client.query({ query: authQueries.logout });
    tokenStorage.remove();
    tokenStorage.remove(REMEMBER_STORAGE_KEY);

    dispatch(authActions.logout());
    if (reloadWindow) {
      window.location.reload();
    }
  };

export const passwordRecoveryStepOne =
  ({ login: userLogin }: Auth.PasswordRecoveryStepOneParams) =>
  async (dispatch: AppDispatch) => {
    dispatch(loaderActions.setLoaderStatus(true));

    try {
      const { data, errors } = await client.query<Auth.PasswordRecoveryStepOneResponse, Auth.PasswordRecoveryStepOneVar>({
        query: authQueries.passwordRecoveryStepOne,
        variables: {
          login: userLogin,
          domain: window.location.host,
        },
      });

      if (strictlyEqual<number>(data?.passwordChange?.getCode?.status, StatusCodes.SUCCESSFUL_200)) {
        dispatch(authActions.changePasswordRecoveryStatus(PasswordRecoveryStatuses.REQUEST));
      }

      if (errors?.length) {
        showApolloErrors(errors);
      }
      return true;
    } catch (error) {
      dispatch(authActions.logout());
      showError(ErrorMessages.COMMON_ERROR_MESSAGE);
    } finally {
      dispatch(loaderActions.setLoaderStatus(false));
    }

    return false;
  };

export const passwordRecoveryStepTwo =
  ({ password, code }: Auth.PasswordRecoveryStepTwoParams) =>
  async (dispatch: AppDispatch) => {
    dispatch(loaderActions.setLoaderStatus(true));

    try {
      const { data, errors } = await client.query<Auth.PasswordRecoveryStepTwoResponse, Auth.PasswordRecoveryStepTwoVar>({
        query: authQueries.passwordRecoveryStepTwo,
        variables: { password, code },
      });

      if (strictlyEqual<number>(data?.passwordChange?.change?.status, StatusCodes.SUCCESSFUL_200)) {
        if (tokenStorage.isExistAuthToken) await dispatch(logout(false));
        dispatch(authActions.changePasswordRecoveryStatus(PasswordRecoveryStatuses.SUCCESS));
      }
      // invalid/expired link case
      if (strictlyEqual<number>(data?.passwordChange?.change?.status, StatusCodes.NOT_FOUND)) {
        if (tokenStorage.isExistAuthToken) await dispatch(logout(false));
        dispatch(authActions.changePasswordRecoveryStatus(PasswordRecoveryStatuses.INVALID));
      }

      if (errors?.length) {
        showApolloErrors(errors);
      }
      return true;
    } catch (error) {
      dispatch(authActions.logout());
      showError(ErrorMessages.COMMON_ERROR_MESSAGE);
    } finally {
      dispatch(loaderActions.setLoaderStatus(false));
    }

    return false;
  };
