import { useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import { INITIAL_CHARTS_PROFILES } from '@app/v2/shared/constants';
import { ChartsProfilesTypes, MeteoChartProfileType } from '@app/v2/shared/enums';
import { strictlyEqual } from '@app/v2/shared/helpers';
import useAppSelector from '../reactRedux/useAppSelector';
import useActions from '../reactRedux/useActions';
import useLocalStorage from '../useLocalStorage';
import LocalStorageKeys from '../../enums/LocalStorageKeys';

interface MeteoChartProfiles {
  meteoChartProfiles: Meteo.ChartProfile[];
  meteoChartActiveProfile: Meteo.ChartProfile;
  meteoChartActiveProfileId: string | null;
  meteoChartsIds: string[];
  isMeteoChartSetupsExist: boolean;
  isActiveProfileCustom: boolean;
  amountOfCharts: number;
  handleSetMeteoChartProfiles: (nextProfile: Meteo.ChartProfile[]) => void;
  handleSetMeteoChartActiveProfiles: (nextActiveProfileId: number) => void;
  handleGetMeteoChartById: (chartId: string) => Meteo.ChartProfileItem | null;
}

export default function useMeteoChartProfiles(): MeteoChartProfiles {
  const { setItem, getItem } = useLocalStorage();

  const { meteoChartProfiles, meteoChartActiveProfile, meteoChartsIds } = useAppSelector(
    state => ({
      meteoChartProfiles: state.meteoChartConstructor.meteoChartProfiles,
      meteoChartActiveProfile: state.meteoChartConstructor.meteoChartActiveProfile,
      meteoChartsIds: state.meteoChartConstructor?.meteoChartActiveProfile?.items?.map(({ id }) => String(id)) ?? [],
    }),
    isEqual,
  );

  const { setMeteoChartProfiles, setMeteoChartActiveProfile } = useActions();

  const handleSetMeteoChartProfiles = useCallback(
    (nextValue: Meteo.ChartProfile[]) => {
      const nextValueVithdefaultProfile = [INITIAL_CHARTS_PROFILES[ChartsProfilesTypes.meteo], ...nextValue];

      setMeteoChartProfiles({ nextValue: nextValueVithdefaultProfile });

      const activeProfileIdFromLS = getItem(LocalStorageKeys.ActiveMeteoChartProfileId);
      const isActiveProfileIdFromLSValid =
        activeProfileIdFromLS && nextValueVithdefaultProfile.map(({ id }) => id).includes(Number(activeProfileIdFromLS));
      const nextActiveProfileId = isActiveProfileIdFromLSValid ? Number(activeProfileIdFromLS) : nextValueVithdefaultProfile?.[0]?.id;

      setMeteoChartActiveProfile({ nextActiveProfileId });
    },
    [setMeteoChartProfiles, setMeteoChartActiveProfile, getItem],
  );

  const handleSetMeteoChartActiveProfiles = useCallback(
    (nextActiveProfileId: number) => {
      setItem(LocalStorageKeys.ActiveMeteoChartProfileId, nextActiveProfileId);
      setMeteoChartActiveProfile({ nextActiveProfileId });
    },
    [setMeteoChartActiveProfile, setItem],
  );

  const handleGetMeteoChartById = useCallback(
    (chartId: string): Meteo.ChartProfileItem | null => {
      if (!meteoChartActiveProfile) return null;
      return meteoChartActiveProfile.items.find(({ id }) => String(id) === chartId);
    },
    [meteoChartActiveProfile],
  );

  return {
    meteoChartProfiles,
    meteoChartActiveProfile,
    meteoChartsIds,
    meteoChartActiveProfileId: meteoChartActiveProfile?.id ? String(meteoChartActiveProfile.id) : null,
    isMeteoChartSetupsExist: Boolean(meteoChartActiveProfile?.items?.length),
    isActiveProfileCustom: strictlyEqual(meteoChartActiveProfile?.type, MeteoChartProfileType.Custom),
    amountOfCharts: meteoChartActiveProfile?.items?.length,
    handleSetMeteoChartProfiles,
    handleSetMeteoChartActiveProfiles,
    handleGetMeteoChartById,
  };
}
