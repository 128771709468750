import React, { MutableRefObject, useCallback, useMemo } from 'react';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import { Box, Icon } from '@mui/material';
import icons from '@app/assets/iconFont';
import { getBackgroundColorForMarker } from '@app/v2/shared/helpers';

type Props = {
  id: string | number;
  title: string;
  items: { id: number }[];
  onClick: (item: { id: number }) => void;
  visibility?: string;
  iconRef?: MutableRefObject<HTMLDivElement>;
};

const BadgeMenuIconMap = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const { id, title, items, onClick, visibility, iconRef } = props;

  const classes = useStyles();

  const { backgroundColor, backgroundTriangleColor, backgroundShadeColor } = getBackgroundColorForMarker(visibility);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (items.length > 1) {
        setAnchorEl(event.currentTarget);
      } else {
        onClick(items[0]);
      }
    },
    [items, onClick],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMenuItemClick = useCallback(
    index => {
      handleClose();
      onClick(items[index]);
    },
    [handleClose, items, onClick],
  );

  const badgeContent = useMemo(() => (items.length > 1 ? items.length : 0), [items]);

  return (
    <div className={classes.position}>
      <Badge badgeContent={badgeContent}>
        <Box
          component="span"
          aria-controls={`menu-${id}`}
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => handleClick(event)}
          className={classes.circle}
          sx={{ bgcolor: backgroundColor, '&:hover': { bgcolor: backgroundShadeColor } }}
          ref={iconRef}
        >
          <Icon className={classes.icon}> {icons.comment} </Icon>
        </Box>
        <Box component="span" className={classes.traingle} sx={{ color: backgroundTriangleColor }} />
      </Badge>
      <Menu id={`menu-${id}`} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {items.map(({ id: itemId }, index) => (
          <MenuItem key={itemId} onClick={() => handleMenuItemClick(index)}>
            {title} {index + 1}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const useStyles = makeStyles({
  circle: {
    position: 'relative',
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '50%',
    zIndex: 2,
  },
  traingle: {
    width: 0,
    height: 0,
    borderLeft: '0.625rem solid transparent',
    borderRight: '0.625rem solid transparent',
    borderTop: '1.5625rem solid',
    borderBottomLeftRadius: '2.5rem',
    borderBottomRightRadius: '2.5rem',
    position: 'absolute',
    backgroundAttachment: 'fixed',
    top: '30px',
    left: '10px',
    zIndex: 1,
  },
  icon: {
    fontSize: '1.5rem',
    color: 'white',
    position: 'absolute',
    top: '10px',
    left: '8px',
  },
  position: {
    position: 'relative',
    right: '19px',
    bottom: '52px',
  },
});

export default BadgeMenuIconMap;
