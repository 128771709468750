import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { CSDInput } from '@app/modules/kit-module/shared/ui';
import { EMPTY_STRING } from '@app/v2/shared/constants';

interface Props extends I18N.TranslationFunction<'filters'> {
  formikValues: ReturnType<typeof useFormik<Pick<Filters.Set, 'label'>>>;
}

const CSDUserSetTitle = ({ formikValues, t }: Props) => {
  const { values, errors, touched, handleChange, handleBlur } = formikValues;

  return (
    <CSDInput
      id="label"
      className="userSetTitle"
      placeholder={t('set.label.placeholder')}
      variant="standard"
      helperText={touched?.label ? errors?.label : EMPTY_STRING}
      error={errors?.label && touched?.label}
      value={values.label}
      onBlur={handleBlur}
      onChange={handleChange}
      maxLength={20}
    />
  );
};

export default withTranslation('filters')(memo(CSDUserSetTitle));
