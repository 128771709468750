import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { useQuery } from '@apollo/client';
import { Dialog, DialogContent, DialogContentProps, DialogProps, DialogTitle, DialogTitleProps, Typography } from '@mui/material';
import { DialogRef } from '@app/core/providers/DialogProvider';
import WrapperProgress from '@app/components/common/wrapper-progress/WrapperProgress';
import CloseDialogButton from '@app/components/close-dialog-button/CloseDialogButton';
import meteoQueries from '@app/clients/apollo/requests/queries/meteo';
import { useNewFilters } from '@app/v2/shared/hooks';
import RoadPlanChart from './chart/RoadPlanChart';
import type { RoadPlanGraphResponse, RoadPlanGraphVariables } from './types';

export type Props = {
  roadId: number;
  title: string;
} & DialogRef;

const RoadPlanDialog = ({ roadId, title, close }: Props) => {
  const { t } = useTranslation('charts');

  const contentDialogRef = useRef<HTMLDivElement | null>(null);
  const { filters } = useNewFilters();
  const organizationIds = filters?.organizations ?? [];

  const { data, loading } = useQuery<RoadPlanGraphResponse, RoadPlanGraphVariables>(meteoQueries.meteoRoadPlanGraph, {
    variables: {
      roadId,
      organizationIds,
    },
  });

  return (
    <StyledDialog fullWidth open fullScreen onClose={() => close('close')}>
      <StyledDialogTitle>
        <Typography fontSize={30} variant="h6">
          {title}
        </Typography>
        <CloseDialogButton callback={close} iconSize={30} />
      </StyledDialogTitle>
      <StyledDialogContent ref={contentDialogRef}>
        <WrapperProgress loading={loading} message={t('message')}>
          {!!data?.meteoRoadPlanGraph && <RoadPlanChart data={data.meteoRoadPlanGraph} chartHeight={contentDialogRef.current?.clientHeight} />}
        </WrapperProgress>
      </StyledDialogContent>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)<DialogProps>({
  height: '100%',
  top: 0,

  '& .MuiDialog-container': {
    height: '100%',
  },

  '& .MuiDialog-paper': {
    borderRadius: '0rem 0rem 0.5rem 0.5rem',
    padding: '0rem',
  },
});

const StyledDialogTitle = styled(DialogTitle)<DialogTitleProps>({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0px 5px 5px rgba(52, 52, 52, 0.2)',
  padding: '1.5rem',
});

const StyledDialogContent = styled(DialogContent)<DialogContentProps>({
  height: '100%',
  overflow: 'auto',
  marginTop: '1.5rem',
});

export default RoadPlanDialog;
