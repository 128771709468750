import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Grid, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { DialogProvider } from '@app/core/providers/DialogProvider';
import KitRouterConfig from '@app/modules/kit-module/entities/configs/kitRouterConfig';
import CSDKitSidebar from './entities/ui/CSDKitSidebar/CSDKitSidebar';
import KitSidebarConfig from './entities/configs/kitSidebarConfig';

const KitModule = () => {
  const classes = useStyles();

  return (
    <DialogProvider>
      <>
        <Grid container spacing={1} className={classes.container}>
          <Grid item xs={2} className={classes.nav}>
            <CSDKitSidebar items={KitSidebarConfig} />
          </Grid>
          <Grid item xs={10} className={classes.contentWrapper}>
            <Container className={classes.content}>
              <Switch>
                {KitRouterConfig.map(({ label, path, component }) => (
                  <Route key={label} path={path} component={component} exact />
                ))}
              </Switch>
            </Container>
          </Grid>
        </Grid>
      </>
    </DialogProvider>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100vh',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,
  },
  nav: {
    borderRight: '1px solid black',
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100vh',
  },
  contentWrapper: {
    paddingTop: '10px',
    alignSelf: 'start',
    justifySelf: 'center',
    overflowY: 'auto',
    height: '100vh',
  },
  content: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
}));

export default KitModule;
