import React, { ReactNode, useState, useEffect, useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
import { Profile, FormFieldTypes, Notification, ProfilesMap } from '@app/v2/features/notifications/types';
import { i18n } from '@app/v2/shared/localization';
import { useAppSelector, useAppDispatch } from '@app/v2/shared/hooks';
import { INITIAL_NOTIFICATION_PROFILE, INITIAL_NOTIFICATION_PROFILE_ID } from '@app/v2/shared/constants';
import { mutateSubscriptions } from '@store/thunks/subscriptions/subscriptions-thunks';
import { convertSubscriptionsArrayToMap, convertSubscriptionsMapToArray } from '@app/v2/features/notificationProfile/helpers';
import NotificationProfileContext from './NotificationProfileContext';

type Props = {
  children: ReactNode;
};

const NotificationProfileProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const { localSubscriptions, globalSubscriptions, meteoBulletinGroups } = useAppSelector(state => state.subscriptions);

  const [currentProfile, setCurrentProfile] = useState<Profile>(null);
  const [profiles, setProfiles] = useState<ProfilesMap>(new Map());
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [error, setError] = useState<string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const mapLocalSubscribtion = useMemo(() => convertSubscriptionsArrayToMap(localSubscriptions), [localSubscriptions]);

  useEffect(() => {
    const isProfileCreated = profiles.size < mapLocalSubscribtion.size;

    if (isProfileCreated) {
      mapLocalSubscribtion.forEach((value, key) => {
        if (!profiles.has(key)) {
          setCurrentProfile(value);
        }
      });
    }
    if (!currentProfile) {
      setCurrentProfile(mapLocalSubscribtion.size && mapLocalSubscribtion.entries().next()?.value[1]);
    }
  }, [profiles, mapLocalSubscribtion, currentProfile]);

  useEffect(() => {
    setProfiles(mapLocalSubscribtion);
  }, [mapLocalSubscribtion]);

  useEffect(() => {
    if (currentProfile) {
      const storedProfile = mapLocalSubscribtion.get(currentProfile.id);
      const isProfileEdit = !isEqual(
        currentProfile,
        currentProfile.id === INITIAL_NOTIFICATION_PROFILE_ID ? INITIAL_NOTIFICATION_PROFILE : storedProfile,
      );
      setIsEdit(isProfileEdit);
    }
  }, [profiles, mapLocalSubscribtion, currentProfile]);

  const addNewProfile = useCallback(() => {
    setCurrentProfile(INITIAL_NOTIFICATION_PROFILE);
  }, []);

  const changeFormField = useCallback((data: Common.SelectorValues, type: FormFieldTypes) => {
    setCurrentProfile(prev => ({
      ...prev,
      [type]: data.map(({ value }) => value),
    }));
  }, []);

  const getErrorMessage = useCallback(
    (profileId: number, title: string): string => {
      const existingProfileNames = localSubscriptions.reduce((res, { id, title: profileTitle }) => {
        if (id !== profileId) {
          res.push(profileTitle);
        }
        return res;
      }, []);

      switch (true) {
        case (!title || !title.length) && isEdit: {
          return 'notification:profiles.error.requiredSubscriptionLabel';
        }
        case existingProfileNames.includes(title) && isEdit: {
          return 'notification:profiles.error.subscriptionIsExist';
        }
        default: {
          return null;
        }
      }
    },
    [isEdit, localSubscriptions],
  );

  const checkTitleValidate = useCallback(
    (profileId: number, title: string): boolean => {
      const errorMessage = getErrorMessage(profileId, title);
      if (errorMessage) {
        setError(i18n.t(errorMessage));
        return false;
      }
      if (error) {
        setError(null);
      }
      return true;
    },
    [error, getErrorMessage],
  );

  const changeTitle = useCallback(
    (id: number, newTitle: string) => {
      setCurrentProfile(prev => ({
        ...prev,
        title: newTitle,
      }));
      checkTitleValidate(id, newTitle);
    },
    [checkTitleValidate],
  );

  const changeCheckbox = useCallback(
    ({ key, keyValue, checked }: { key: keyof Notification; keyValue: Notification[keyof Notification]; checked: boolean }) => {
      setCurrentProfile({
        ...currentProfile,
        notifications: currentProfile.notifications.map(notification =>
          notification[key] === keyValue
            ? {
                ...notification,
                checked,
              }
            : notification,
        ),
      });
    },
    [currentProfile],
  );

  const removeProfile = useCallback(
    async (id: number) => {
      setIsLoading(true);

      const profilesForSave = new Map(mapLocalSubscribtion);
      profilesForSave.delete(id);

      await dispatch(mutateSubscriptions(globalSubscriptions, convertSubscriptionsMapToArray(profilesForSave), meteoBulletinGroups));

      setCurrentProfile(null);

      setIsLoading(false);
    },
    [dispatch, globalSubscriptions, mapLocalSubscribtion, meteoBulletinGroups],
  );

  const saveProfile = useCallback(
    async (newProfile: Profile) => {
      const isTitleValid = checkTitleValidate(newProfile.id, newProfile.title);

      if (isTitleValid) {
        setIsLoading(true);

        const profilesMapForSave = new Map(profiles).set(newProfile.id, newProfile);

        await dispatch(mutateSubscriptions(globalSubscriptions, convertSubscriptionsMapToArray(profilesMapForSave), meteoBulletinGroups));

        setIsLoading(false);
      }
    },
    [dispatch, checkTitleValidate, globalSubscriptions, profiles, meteoBulletinGroups],
  );

  const saveChanges = useCallback(async () => {
    setIsLoading(true);

    await dispatch(mutateSubscriptions(globalSubscriptions, localSubscriptions, meteoBulletinGroups));

    setIsLoading(false);
  }, [dispatch, globalSubscriptions, localSubscriptions, meteoBulletinGroups]);

  const resetChanges = useCallback(
    (id: number) => {
      const profileWithoutChanges = mapLocalSubscribtion.get(id);
      setError(null);
      setProfiles(mapLocalSubscribtion);
      setCurrentProfile(id === INITIAL_NOTIFICATION_PROFILE_ID ? INITIAL_NOTIFICATION_PROFILE : profileWithoutChanges);
    },
    [mapLocalSubscribtion],
  );

  const reset = useCallback(() => {
    setError(null);
    setCurrentProfile(null);
  }, []);

  const toggleProfileStatus = useCallback(
    async (checked: boolean, id: number) => {
      const changedProfile = { ...profiles.get(id), isActive: checked };
      await saveProfile(changedProfile);
      if (currentProfile.id === id) {
        setCurrentProfile(changedProfile);
      }
    },
    [profiles, currentProfile, saveProfile],
  );

  return (
    <NotificationProfileContext.Provider
      value={{
        currentProfile,
        profiles: convertSubscriptionsMapToArray(profiles),
        isEdit,
        isLoading,
        error,
        setCurrentProfile,
        toggleProfileStatus,
        changeFormField,
        changeTitle,
        changeCheckbox,
        resetChanges,
        removeProfile,
        addNewProfile,
        saveProfile,
        saveChanges,
        reset,
      }}
    >
      {children}
    </NotificationProfileContext.Provider>
  );
};

export default NotificationProfileProvider;
