import React, { CSSProperties, MouseEvent, useMemo } from 'react';
import { Box, BoxProps, Icon, Stack, StackProps, Tooltip, Typography, TypographyProps, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { isFunction } from '@app/v2/shared/helpers';
import icons from '@app/assets/iconFont';
import RoadPlanDialog from '@app/components/charts/road-plan-dialog/RoadPlanDialog';
import LinearDialog from '@app/components/charts/linear-dialog/LinearDialog';
import { useDialog } from '@app/v2/shared/hooks';
import { ENTER_TOOLTIP_DELAY } from '@app/v2/shared/constants';

type Props = {
  roadSegment: Common.RoadsSegment;
  disabled?: boolean;
  roadSegmentClasses?: any;
  roadSegmentStyles?: CSSProperties;
  onRoadSegmentClick?: (roadSegmentId: number) => void;
  tooltipProps?: Partial<TooltipProps>;
};

const CSDRoadSegment = ({ roadSegment, disabled, roadSegmentStyles, roadSegmentClasses, onRoadSegmentClick, tooltipProps }: Props) => {
  const openRoadPlanDialog = useDialog<Common.OpenDialogData>(RoadPlanDialog);
  const openRoadLinearDialog = useDialog<Common.OpenDialogData>(LinearDialog);

  const chartsIcons = useMemo<Common.RoadsChartsIcons>(() => {
    const result = [];

    if (roadSegment.isMeteoLinearGraph) {
      result.push({
        name: icons.lineGraph,
        onIconClick: openRoadLinearDialog,
      });
    }

    if (roadSegment.isMeteoRoadPlanGraph) {
      result.push({
        name: icons.schedule,
        onIconClick: openRoadPlanDialog,
      });
    }

    return result;
  }, [openRoadLinearDialog, openRoadPlanDialog, roadSegment]);

  const handleRoadSegmentClick = (event: MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();

    if (isFunction(onRoadSegmentClick) && !disabled) {
      onRoadSegmentClick(roadSegment.id);
    }
  };

  const handleClick = (event: MouseEvent<HTMLSpanElement>, onOpenDialog: Common.OpenDialogHandler): void => {
    event.stopPropagation();

    if (isFunction(onOpenDialog) && !disabled) {
      const { title, id } = roadSegment;
      onOpenDialog({ roadId: id, title });
    }
  };

  const getRoadSegmentBlockWidth = (): string => {
    const spaceForIcons = chartsIcons.length * 30;

    return `calc(100% - ${spaceForIcons}px)`;
  };

  return (
    <StyledBox sx={{ ...roadSegmentStyles }} className={clsx({ [roadSegmentClasses]: roadSegmentClasses })} onClick={handleRoadSegmentClick}>
      <Tooltip title={roadSegment.title} enterDelay={ENTER_TOOLTIP_DELAY} {...tooltipProps}>
        <StyledWrapperStack>
          <StyledTitlesStack width={getRoadSegmentBlockWidth()}>
            <StyledTypography>{roadSegment?.code || <Icon>{icons.noData}</Icon>}</StyledTypography>
            <StyledRoadTitleBox>{roadSegment.title}</StyledRoadTitleBox>
          </StyledTitlesStack>

          {!!chartsIcons.length &&
            chartsIcons.map(({ name, onIconClick }) => (
              <Icon key={name} onClick={(event: MouseEvent<HTMLSpanElement>) => handleClick(event, onIconClick)}>
                {name}
              </Icon>
            ))}
        </StyledWrapperStack>
      </Tooltip>
    </StyledBox>
  );
};

const StyledWrapperStack = styled(Stack)<StackProps>({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  minWidth: '6rem',
});

const StyledTitlesStack = styled(Stack)<StackProps>({
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
});

const StyledBox = styled(Box)<BoxProps>(({ theme: { palette } }) => ({
  maxHeight: '40px',
  minHeight: '40px',
  cursor: 'pointer',
  padding: '4px 1rem',
  borderRadius: '0.5rem',
  border: `1px solid ${palette.common.black}`,
  display: 'block',
}));

const StyledRoadTitleBox = styled(Box)<BoxProps>({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: '100%',
  fontSize: '0.75rem',
});

const StyledTypography = styled(Typography)<TypographyProps>({
  fontSize: '1rem',
  color: 'inherit',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: '100%',

  '& .MuiIcon-root': {
    fontSize: '0.5rem',
  },
});

export default CSDRoadSegment;
