import { BASE_COLS, BASE_ROWS, MODE, DEFAULT_FONT_SIZE, DEFAULT_LINKS_SIZE } from '@app/modules/video-wall-module/constants/constants';

export const WATER_LEVEL_CONTROL_DEFAULT_STATE = {
  rows: BASE_ROWS,
  cols: BASE_COLS,
  mode: MODE.rows,
  isShowLinks: true,
  fontSize: DEFAULT_FONT_SIZE,
  linksSize: DEFAULT_LINKS_SIZE,
  autoPagination: null,
};

export const WLC_METEODATA_ROUNDED_SIGN = 2;

export const MAX_COLS_FOR_FULL_TAB_LABEL = 5;
