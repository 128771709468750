import { TFuncKey } from 'react-i18next';
import { IncidentObjects } from '@app/v2/shared/enums';
import icons from '@app/assets/iconFont';

const IncidentsTypeSettings: Record<IncidentObjects, { translation: TFuncKey<'incidents'>; icon: string }> = {
  [IncidentObjects.Motorbike]: {
    translation: 'types.motorbike',
    icon: icons.motorbike,
  },
  [IncidentObjects.Car]: {
    translation: 'types.car',
    icon: icons.passenger,
  },
  [IncidentObjects.CarTrailer]: {
    translation: 'types.carTrailer',
    icon: icons.carTrailer,
  },
  [IncidentObjects.SmallTruck]: {
    translation: 'types.smallTruck',
    icon: icons.smallTruck,
  },
  [IncidentObjects.RoadTrain]: {
    translation: 'types.roadTrain',
    icon: icons.roadTrain,
  },
  [IncidentObjects.Bus]: {
    translation: 'types.bus',
    icon: icons.bus,
  },
  [IncidentObjects.Person]: {
    translation: 'types.person',
    icon: icons.walker,
  },
  [IncidentObjects.Cyclist]: {
    translation: 'types.cyclist',
    icon: icons.cyclist,
  },
  [IncidentObjects.KickScooter]: {
    translation: 'types.kickScooter',
    icon: icons.kickScooter,
  },
};

export default IncidentsTypeSettings;
