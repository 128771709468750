enum Events {
  Keydown = 'keydown',
  Mousewheel = 'mousewheel',
  Wheel = 'wheel',
  Click = 'click',
  DOMMouseScroll = 'DOMMouseScroll',
  MouseMove = 'mousemove',
  MouseLeave = 'mouseleave',
  Resize = 'resize',
}

export default Events;
