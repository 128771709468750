import React, { useRef } from 'react';
import { withTranslation } from 'react-i18next';
import Highcharts, { ExportingMimeTypeValue } from 'highcharts';
import { Button, Chip, Icon, Popover, Stack } from '@mui/material';
import icons from '@app/assets/iconFont';
import { usePopover } from '@app/v2/shared/hooks';
import { isFunction } from '@app/v2/shared/helpers';
import { PlanGraphData } from './types';
import { drawYAxisBackground } from '../common/helpers';
import { prepareExportingForPlanChart } from '../common/fnForPrepareCharts/planChart';

type Props = {
  planGraph: PlanGraphData;
  nameOfPlace: string;
  styles?: Record<string, unknown>;
} & I18N.TranslationFunction<'charts'>;

const ExportChartBtn = ({ styles, planGraph, nameOfPlace, t }: Props) => {
  const { anchorEl, closePopover, openPopover, isOpen } = usePopover<HTMLButtonElement>();
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handlePrint = () => {
    const [chart] = Highcharts.charts.filter(Boolean);
    if (!isFunction(chart.print)) return;

    chart.print();
    closePopover();
  };

  const handleExport = (type: ExportingMimeTypeValue): void => {
    const [chart] = Highcharts.charts.filter(Boolean);

    if (!isFunction(chart.exportChart)) return;

    chart.update({
      exporting: prepareExportingForPlanChart(chart, nameOfPlace, planGraph, t),
    });

    chart.exportChart({ type }, {});

    chart.update({
      chart: {
        events: {
          redraw(this) {
            drawYAxisBackground(this);
          },
        },
      },
    });
    closePopover();
  };

  const actionListConfig = [
    {
      label: t('menuDefinitions.printChart'),
      onClick: handlePrint,
    },
    {
      label: t('menuDefinitions.downloadPNG'),
      onClick: () => handleExport('image/png'),
    },
    {
      label: t('menuDefinitions.downloadJPEG'),
      onClick: () => handleExport('image/jpeg'),
    },
    {
      label: t('menuDefinitions.downloadPDF'),
      onClick: () => handleExport('application/pdf'),
    },
    {
      label: t('menuDefinitions.downloadSVG'),
      onClick: () => handleExport('image/svg+xml'),
    },
  ];

  return (
    <>
      <Button ref={buttonRef} sx={styles} startIcon={<Icon sx={{ fontSize: '1.5rem !important' }}>{icons.download}</Icon>} onClick={openPopover}>
        {t('export')}
      </Button>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            background: 'white',
          },
        }}
        marginThreshold={0}
      >
        <Stack direction="column" alignItems="center" gap="1rem" padding="1rem">
          {actionListConfig.map(({ label, onClick }) => (
            <Chip key={label} sx={{ width: '100%' }} label={label} variant="outlined" onClick={onClick} />
          ))}
        </Stack>
      </Popover>
    </>
  );
};

export default withTranslation('charts')(ExportChartBtn);
