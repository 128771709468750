import React from 'react';
import { CSDDynamicRoadInterface, CSDCenteredBox } from '@app/modules/kit-module/shared/ui';
import PositionType from '@app/modules/kit-module/shared/ui/CSDDynamicRoadInterface/enums/PositionType';
import RoadDirectionType from '@app/modules/kit-module/shared/ui/CSDDynamicRoadInterface/enums/RoadDirectionType';
import { CSDStationItem } from '@app/modules/kit-module/shared/ui/CSDDynamicRoadInterface/types';

type Props = {
  stationId: number;
  roadLines: Common.RoadPointLines;
  videoStations: Video.VideoPlaceStation[];
  directionTitles: Common.RoadDirectionTitles;
  handleChangeStation: (value: number) => void;
};

enum ApiPositionTypes {
  Left = 'Слева',
  Right = 'Справа',
  Center = 'По центру',
  Undefined = 'Не определено',
}

const CSDRoadWithCameras = ({ stationId, roadLines: { forward = 1, backward = 1 }, videoStations, directionTitles, handleChangeStation }: Props) => {
  const prepareStations = ({ id, position, direction, isExpired }: Video.VideoPlaceStation): CSDStationItem => {
    const positionMapper = {
      [ApiPositionTypes.Left]: PositionType.top,
      [ApiPositionTypes.Right]: PositionType.bottom,
      [ApiPositionTypes.Center]: PositionType.middle,
    };

    return {
      stationId: id,
      direction: RoadDirectionType[direction] ?? null,
      isExpired,
      position: positionMapper[position] ?? null,
      onChange: activeStationId => handleChangeStation(Number(activeStationId)),
    };
  };

  const preparedStations = videoStations.map(prepareStations);

  return (
    <CSDCenteredBox
      sx={{
        borderRadius: '0.5rem',
        margin: 0,
      }}
    >
      <CSDDynamicRoadInterface
        forward={forward}
        backward={backward}
        forwardRoadTitle={directionTitles.forward}
        backwardRoadTitle={directionTitles.backward}
        activeStationId={stationId}
        stations={preparedStations}
      />
    </CSDCenteredBox>
  );
};

export default CSDRoadWithCameras;
