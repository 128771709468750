import React, { ReactNode, useMemo } from 'react';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { linearGradient, boxShadowLight, boxShadowDark } from '@app/core/theme/styles';
import isLightTheme from '@app/core/helpers/isLightTheme';

type Props = {
  variant?: 'contained' | 'outlined';
  size?: 'small' | 'normal';
  backgroundColor?: string;
  children: ReactNode;
};

const CSDTub = ({ variant = 'contained', size = 'normal', backgroundColor, children }: Props) => {
  const isNormalSize = useMemo(() => size === 'normal', [size]);
  const isContained = useMemo(() => variant === 'contained', [variant]);

  const StyledBox = styled(Box)<BoxProps>(
    ({
      theme: {
        palette: { mode, common },
      },
    }) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '0.5rem',
      gap: '0.5rem',
      width: 'min-content',
      maxHeight: isNormalSize ? '2.5rem' : '1.75rem',
      padding: isNormalSize ? '0.5rem 1rem' : '0.375rem 0.5rem',
      background: !backgroundColor && isContained ? linearGradient : 'transparent',
      backgroundColor,
      border: !isContained && `0.063rem solid ${isLightTheme(mode) ? common.black : common.white}`,

      ':hover': {
        boxShadow: isLightTheme(mode) ? boxShadowLight : boxShadowDark,
      },

      '& .MuiIcon-root': {
        color: !isLightTheme(mode) && common.white,
        fontSize: isNormalSize ? '1.5rem' : '1rem',
      },

      '& .MuiTypography-root': {
        fontSize: isNormalSize ? '1rem' : '0.75rem',
      },

      '& *': {
        cursor: 'pointer',
        color: isContained ? common.white : common.black,
      },
    }),
  );

  return <StyledBox>{children}</StyledBox>;
};

export default CSDTub;
