/* eslint-disable */
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { groupBy, isEqual } from 'lodash';
import { useAppSelector, useNewFilters } from '@app/v2/shared/hooks';
import { isJSON } from '@app/v2/shared/helpers';
import tokenStorage from '@app/clients/apollo/tokenStorage';
import { groupRules, initialWebSocketContextValue, RESPONSE_TIME, SEND_TIME, SOCKET_STATUS_READY, WsMessages } from '@app/v2/shared/constants';
import { MessageType, WsCommand } from '@app/v2/shared/enums';
import WebSocketContext from './WebSocketContext';
import { mainConfig } from '@app/v2/shared/configs';

const WebSocketProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isWSLoading, setIsWSLoading] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);

  const [wsData, setWSData] = useState<WS.ContextValue['data']>(initialWebSocketContextValue);
  const [wsMessages, setMessages] = useState<typeof WsMessages>(WsMessages);

  const socketRef = useRef<WebSocket>(null);
  const socketSend = useRef(null);
  const socketReconnect = useRef(null);

  const { isAuth } = useAppSelector(state => state.auth);
  const { filters } = useNewFilters();

  const nextWSFiltersValue = useMemo(
    () => ({ organizationIds: filters?.organizations, roadIds: filters?.roads, placeIds: filters?.places }),
    [filters],
  );

  const onOpen = useCallback(() => {
    if (socketRef?.current?.readyState === SOCKET_STATUS_READY) {
      socketRef?.current.send(JSON.stringify(wsMessages[WsCommand.GET_START_PACKET]));
    }

    socketSend.current = setInterval(() => {
      socketRef?.current?.send(JSON.stringify(wsMessages[WsCommand.PING_MESSAGE]));
    }, SEND_TIME);
  }, [wsMessages]);

  const onMessage = useCallback(({ isTrusted, data }) => {
    if (!isTrusted || !isJSON(data)) return;

    const messages: WS.Response = JSON.parse(data);

    if (!Array.isArray(messages) || !messages.length) {
      setIsWSLoading(false);
    } else {
      const groupMessagesByType = groupBy(messages, 'type');

      setWSData(prev =>
        Object.entries(prev).reduce<WS.ContextValue['data']>(
          (acc, [type, value]) => {
            acc[type] = groupRules[type](value, groupMessagesByType[type] ?? []);
            return acc;
          },
          { ...initialWebSocketContextValue },
        ),
      );

      setIsWSLoading(false);
    }
  }, []);

  const onClose = useCallback(() => {
    socketReconnect.current = setInterval(() => {
      onCreateChannel();
    }, RESPONSE_TIME);
  }, []);

  const onCreateChannel = useCallback(() => {
    socketRef.current = null;
    clearInterval(socketSend.current);
    clearInterval(socketReconnect.current);

    const wsURL = `${mainConfig.api.socketUrl}${tokenStorage.get()}`;

    if (isAuth) {
      socketRef.current = new WebSocket(wsURL);
      socketRef.current.onopen = onOpen;
      socketRef.current.onmessage = onMessage;
      socketRef.current.onclose = onClose;
    }
  }, [onOpen, onMessage, onClose, isAuth]);

  const onUpdate = useCallback(() => {
    setIsWSLoading(true);

    setMessages({
      [WsCommand.PING_MESSAGE]: {
        command: WsCommand.PING_MESSAGE,
      },
      [WsCommand.GET_START_PACKET]: {
        command: WsCommand.GET_START_PACKET,
        payload: {
          filters: nextWSFiltersValue,
        },
      },
    });
  }, [nextWSFiltersValue]);

  useEffect(() => {
    if (!socketRef.current && isAuth) onCreateChannel();

    if (!isAuth) {
      socketRef.current = null;
      clearInterval(socketSend.current);
      clearInterval(socketReconnect.current);
    }
  }, [onCreateChannel, isAuth]);

  useEffect(() => {
    if (socketRef?.current?.readyState === SOCKET_STATUS_READY) {
      setIsConnected(true);
      socketRef?.current.send(JSON.stringify(wsMessages[WsCommand.GET_START_PACKET]));
    }
  }, [wsMessages, socketRef?.current?.readyState]);

  useEffect(() => {
    if (!isEqual(wsMessages[WsCommand.GET_START_PACKET].payload.filters, nextWSFiltersValue)) {
      setWSData(initialWebSocketContextValue);
      onUpdate();
    }
  }, [onUpdate, nextWSFiltersValue]);

  const updatePartOfData = useCallback(
    (type: MessageType) => {
      onUpdate();
      setWSData(prev => ({ ...prev, [type]: [] }));
    },
    [onUpdate],
  );

  const hideNotification = useCallback((notificationId: number): void => {
    setWSData(prev => ({
      ...prev,
      [MessageType.Alert]: prev.alert.filter(({ id }) => id !== notificationId),
      [MessageType.Bulletin]: prev.bulletin.filter(({ id }) => id !== notificationId),
    }));
  }, []);

  return (
    <WebSocketContext.Provider
      value={{
        isConnected,
        isWSLoading,
        hideNotification,
        updatePartOfData,
        data: {
          ...wsData,
        },
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
