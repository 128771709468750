import React, { useState } from 'react';
import { Icon, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import clsx from 'clsx';
import { strictlyEqual } from '@app/v2/shared/helpers';

interface Props {
  link: StaticConfigs.ScoreboardFastLinkItem;
  onChangePage: Common.VoidCallBack;
  currentPath: string;
}

const CSDScoreboardLinkItem = ({ link, onChangePage, currentPath }: Props) => {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);

  return (
    <Tooltip open={open} leaveDelay={0} title={link.title}>
      <IconButton
        onMouseOver={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        onClick={() => {
          setOpen(false);
          onChangePage();
        }}
      >
        <Icon className={clsx({ [classes.activeLink]: strictlyEqual(link.path, currentPath) })}>{link.icon}</Icon>
      </IconButton>
    </Tooltip>
  );
};

const useStyles = makeStyles(({ palette }: Theme) => ({
  activeLink: {
    color: palette.primary.main,
    cursor: 'default',
    transform: 'scale(1.2)',
  },
}));

export default CSDScoreboardLinkItem;
