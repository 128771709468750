export const sizeButtons = `<Button size="large">Отчёт</Button>
<Button>Отчёт</Button>
<Button size="small">Отчёт</Button>`;

export const buttonsWithIcon = `<Button size="large" startIcon={<Icon>save</Icon>}>Отчёт</Button>
<Button startIcon={<Icon>save</Icon>}>Отчёт</Button>
<Button size="small" startIcon={<Icon>save</Icon>}>Отчёт</Button>`;

export const disabledButtons = `<Button size="large" disabled>Отчёт</Button>
<Button disabled>Отчёт</Button>
<Button size="small" disabled>Отчёт</Button>`;

export const outlinedButtons = `<Button size="large" variant="outlined">Отчёт</Button>
<Button variant="outlined">Отчёт</Button>
<Button size="small" variant="outlined">Отчёт</Button>`;

export const outlinedButtonsWithIcon = `<Button variant="outlined" size="large" startIcon={<Icon>save</Icon>}>Отчёт</Button>
<Button variant="outlined" startIcon={<Icon>save</Icon>}>Отчёт</Button>
<Button variant="outlined" size="small" startIcon={<Icon>save</Icon>}>Отчёт</Button>`;

export const outlinedDisabledButtons = `<Button size="large" variant="outlined" disabled>Отчёт</Button>
<Button variant="outlined" disabled>Отчёт</Button>
<Button size="small" variant="outlined" disabled>Отчёт</Button>`;
