import { XAxisOptions } from 'highcharts';
import moment from 'moment';
import { DATE_PERIOD } from '@app/v2/shared/constants';
import { xAxisBorderColor, xAxisLabelsColor } from '../../constants/planChart';

export default function prepareXAxisForPlanChart(): XAxisOptions[] {
  const commonSettings: Partial<XAxisOptions> = {
    min: moment().valueOf(),
    max: moment().add(48, 'hours').valueOf(),
    type: 'datetime',
    minRange: DATE_PERIOD.HOUR,
    grid: {
      cellHeight: 34,
      borderColor: xAxisBorderColor,
      borderWidth: 2,
    },
    className: 'highcharts-xaxis-labels-custom',
    labels: {
      align: 'center',
      style: {
        color: xAxisLabelsColor,
      },
    },
  };

  return [
    {
      tickInterval: DATE_PERIOD.HOUR,
      ...commonSettings,
      labels: {
        ...commonSettings.labels,
        format: '{value: %H}',
      },
    },
    {
      tickInterval: DATE_PERIOD.DAY,
      ...commonSettings,
    },
  ];
}
