// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Mwha05nDJlMYzLlEDfGg{display:flex;flex-direction:column;justify-content:space-between;align-items:center;margin:1.5rem;gap:1.5rem}.Mwha05nDJlMYzLlEDfGg>h6{margin-bottom:.313rem}.Mwha05nDJlMYzLlEDfGg .Fwi9vbI0DKjwkz0K2glM{text-align:center;color:gray;margin:1rem 0;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/modules/auth-module/components/two-factorAuthenticationForm/FormContent/confirmationFormContent.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,6BAAA,CACA,kBAAA,CACA,aAAA,CACA,UAAA,CAEA,yBACE,qBAAA,CAGF,4CACE,iBAAA,CACA,UAAA,CACA,aAAA,CACA,cAAA","sourcesContent":[".cardContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  margin: 1.5rem;\n  gap: 1.5rem;\n\n  & > h6 {\n    margin-bottom: 0.313rem;\n  }\n\n  & .changeEmailMessage {\n    text-align: center;\n    color: gray;\n    margin: 1rem 0;\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": "Mwha05nDJlMYzLlEDfGg",
	"changeEmailMessage": "Fwi9vbI0DKjwkz0K2glM"
};
export default ___CSS_LOADER_EXPORT___;
