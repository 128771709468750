import { TFunction } from 'react-i18next';
import Highcharts, { AxisLabelsFormatterContextObject } from 'highcharts';
import groupBy from 'lodash/groupBy';
import { createEmptyArray } from '@app/v2/shared/utils';
import parameters from '@app/core/constants/parameters/parameters';
import { offsetKeys, parametersWithoutLabels } from '../constants';

interface AdditionalParamsYAxisOptions {
  unit: string;
}

interface YAxisParameters {
  commonT: TFunction<'common'>;
  content: Partial<Meteo.ChartContent>;
  arrOfSetups: Meteo.ChartsSetupParameter[];
}

const differentCases: Record<string, (yAxis: Highcharts.YAxisOptions) => Highcharts.YAxisOptions> = {
  windDirGr: yAxis => ({ ...yAxis, ...getWindDirGrYAxisConfig() }),
  statusDamp: yAxis => ({ ...yAxis, ...getRoadYAxisConfig() }),
  statusDamp2: yAxis => ({ ...yAxis, ...getRoadYAxisConfig() }),
  cars: yAxis => ({ ...yAxis, ...getRoadYAxisConfig() }),
};

export function prepareYAxisParameters({ arrOfSetups = [], content, commonT }: YAxisParameters): Highcharts.YAxisOptions[] {
  if (!arrOfSetups.length || !content) return [];

  let isRenderStringAddedToOptions = false;

  const offsetValues: string[] = createEmptyArray(offsetKeys.filter(key => arrOfSetups.map(({ label }) => label).includes(key)).length).map(
    (_, index) => (!index ? '100%' : `${100 + 20 * index}%`),
  );

  const result: Highcharts.YAxisOptions[] = arrOfSetups.map(setups => {
    const { label, settings } = setups;

    const {
      unitsOptions: { key },
    } = parameters[label];

    const labelSettings: Highcharts.YAxisOptions & AdditionalParamsYAxisOptions = {
      unit: commonT(settings.unit) as string,
      top: offsetKeys.includes(label) ? offsetValues.shift() : null,
      id: key,
      title: null,
      height: '100%',
      showFirstLabel: true,
      showLastLabel: true,
      labels: { enabled: false },
    };

    if (!isRenderStringAddedToOptions && !parametersWithoutLabels.includes(label)) {
      labelSettings.labels = {
        align: 'left',
        useHTML: true,
        zIndex: 100,
        x: -50,
        style: { width: 38, fontSize: '12px', color: 'black' },
        formatter,
      };

      isRenderStringAddedToOptions = true;
    }

    return labelSettings;
  });

  return result.map(yAxis => {
    if (!differentCases[yAxis.id]) return yAxis;
    return differentCases[yAxis.id](yAxis);
  });
}

export function formatter({ isLast, isFirst, chart }: AxisLabelsFormatterContextObject): string {
  const groupedLabels = groupBy(
    chart.yAxis.filter(({ userOptions: { id } }) => !parametersWithoutLabels.includes(id)),
    'userOptions.unit',
  );

  const getLabelsForRender = (compare: 'max' | 'min') =>
    Object.entries<(Highcharts.Axis & AdditionalParamsYAxisOptions)[]>(groupedLabels)
      .filter(([unit]) => Boolean(unit))
      .map(([unit, values]) => `<span style='white-space: nowrap'>${Math[compare](...values.map(valueItem => valueItem[compare]))}${unit}</span>`);

  if (isLast) return getLabelsForRender('max').join(' ');
  if (isFirst) return getLabelsForRender('min').join(' ');

  return null;
}

function getRoadYAxisConfig(): Highcharts.YAxisOptions {
  const commonParams = { color: '#888888', width: 1.5 };

  return {
    gridLineColor: 'white',
    height: '16%',
    max: 1,
    plotLines: [
      { value: 0, zIndex: 5, ...commonParams },
      { value: 1.5, dashStyle: 'LongDash', zIndex: 1, ...commonParams },
      { value: 3, zIndex: 5, ...commonParams },
    ],
  };
}

function getWindDirGrYAxisConfig(): Highcharts.YAxisOptions {
  return {
    height: '10%',
    max: 1.4,
    gridLineColor: 'white',
  };
}
