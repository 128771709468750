import icons from '@app/assets/iconFont';
import { Groups, ProfilesTypes } from '../enums';

const MeteoParametersSetup: Partial<Meteo.ParametersSetups> = {
  [ProfilesTypes.meteo]: {
    tAir: {
      label: 'tAir',
      shortLabel: 'tAirShort',
      icon: icons.tempAir,
      groupRelation: Groups.Temperature,
    },
    tUnderroad: {
      label: 'tUnderroad',
      shortLabel: 'tUnderroadShort',
      icon: icons.tempBelowSurface,
      groupRelation: Groups.Temperature,
    },
    tUnderroad2: {
      label: 'tUnderroad2',
      shortLabel: 'tUnderroad2Short',
      icon: icons.tempBelowSurfaceTwo,
      groupRelation: Groups.Temperature,
    },
    tRoad: {
      label: 'tRoad',
      shortLabel: 'tRoadShort',
      icon: icons.tempSurface,
      groupRelation: Groups.Temperature,
    },
    tRoad2: {
      label: 'tRoad2',
      shortLabel: 'tRoad2Short',
      icon: icons.tempSurfaceTwo,
      groupRelation: Groups.Temperature,
    },
    tDewPoint: {
      label: 'tDewPoint',
      shortLabel: 'tDewPointShort',
      icon: icons.tempDewPoint,
      groupRelation: Groups.Temperature,
    },

    precipitationIntensity: {
      label: 'precipitationIntensity',
      shortLabel: 'precipitationIntensityShort',
      icon: icons.precipitationIntensity,
      groupRelation: Groups.Precipitation,
    },
    precipitationInstant: {
      label: 'precipitationInstant',
      icon: icons.sumRain,
      groupRelation: Groups.Precipitation,
    },
    precipitationType: {
      label: 'precipitationType',
      icon: icons.typeRain,
      groupRelation: Groups.Precipitation,
      dependence: 'precipitationIntensity',
    },
    waterHeight: {
      label: 'waterHeight',
      shortLabel: 'waterHeightShort',
      icon: icons.layerThickness,
      groupRelation: Groups.Precipitation,
    },
    statusDamp: {
      label: 'statusDamp',
      shortLabel: 'statusDampShort',
      icon: icons.surfaceConditionOne,
      groupRelation: Groups.Precipitation,
    },
    statusDamp2: {
      label: 'statusDamp2',
      icon: icons.surfaceConditionTwo,
      groupRelation: Groups.Precipitation,
    },

    visibility: {
      label: 'visibility',
      icon: icons.visibility,
      groupRelation: Groups.Other,
    },
    pressure: {
      label: 'pressure',
      shortLabel: 'pressureShort',
      icon: icons.pressure,
      groupRelation: Groups.Other,
    },
    humidity: {
      label: 'humidity',
      icon: icons.humidOne,
      groupRelation: Groups.Other,
    },
    cloudiness: {
      label: 'cloudiness',
      shortLabel: 'cloudinessShort',
      icon: icons.sky,
      groupRelation: Groups.Other,
    },

    windSpeed: {
      label: 'windSpeed',
      icon: icons.windSpeed,
      groupRelation: Groups.Wind,
    },
    windGusts: {
      label: 'windGusts',
      icon: icons.windGusts,
      groupRelation: Groups.Wind,
    },
    windDirection: {
      label: 'windDirection',
      icon: icons.windDirection,
      groupRelation: Groups.Wind,
    },
    windDirGr: {
      label: 'windDirGr',
      icon: icons.windDirectionDeg,
      groupRelation: Groups.Wind,
      dependence: 'windSpeed',
    },

    tFreezingPoint: {
      label: 'tFreezingPoint',
      shortLabel: 'tFreezingPointShort',
      icon: icons.tempFreezing,
      groupRelation: Groups.DeicingAgents,
    },
    tFreezingPoint2: {
      label: 'tFreezingPoint2',
      icon: icons.tempFreezingTwo,
      groupRelation: Groups.DeicingAgents,
    },
    salineConcentration: {
      label: 'salineConcentration',
      icon: icons.deicingAgentsOne,
      groupRelation: Groups.DeicingAgents,
    },
    salineConcentration2: {
      label: 'salineConcentration2',
      icon: icons.deicingAgentsTwo,
      groupRelation: Groups.DeicingAgents,
    },
  },
  [ProfilesTypes.traffic]: {
    trCars: {
      label: 'trCars',
      shortLabel: 'trCarsShort',
      icon: icons.passenger,
      groupRelation: Groups.TransportTypes,
    },
    trSmallTrucks: {
      label: 'trSmallTrucks',
      shortLabel: 'trSmallTrucksShort',
      icon: icons.smallCargo,
      groupRelation: Groups.TransportTypes,
    },
    trTrucks: {
      label: 'trTrucks',
      shortLabel: 'trTrucksShort',
      icon: icons.cargo,
      groupRelation: Groups.TransportTypes,
    },
    trBigTrucks: {
      label: 'trBigTrucks',
      shortLabel: 'trBigTrucksShort',
      icon: icons.bigCargo,
      groupRelation: Groups.TransportTypes,
    },
    trRoadTrains: {
      label: 'trRoadTrains',
      shortLabel: 'trRoadTrainsShort',
      icon: icons.roadTrain,
      groupRelation: Groups.TransportTypes,
    },
    trBuses: {
      label: 'trBuses',
      icon: icons.bus,
      groupRelation: Groups.TransportTypes,
    },
    trMotorcycles: {
      label: 'trMotorcycles',
      icon: icons.motorbike,
      groupRelation: Groups.TransportTypes,
    },

    trTotalVehicles: {
      label: 'trTotalVehicles',
      shortLabel: 'trTotalVehiclesShort',
      icon: icons.traffic,
      groupRelation: Groups.Common,
    },
    trSpeed: {
      label: 'trSpeed',
      icon: icons.speed,
      groupRelation: Groups.Common,
    },
    trOccupancy: {
      label: 'trOccupancy',
      icon: icons.workload,
      groupRelation: Groups.Common,
    },
    trGap: {
      label: 'trGap',
      icon: icons.distance,
      groupRelation: Groups.Common,
    },
  },
  [ProfilesTypes.adaptationToClimateChange]: {
    dpgT01: {
      label: 'dpgT01',
      icon: icons.passenger,
      groupRelation: Groups.DPGTemperature,
    },
    dpgT02: {
      label: 'dpgT02',
      icon: icons.passenger,
      groupRelation: Groups.DPGTemperature,
    },
    dpgT03: {
      label: 'dpgT03',
      icon: icons.passenger,
      groupRelation: Groups.DPGTemperature,
    },
    dpgT04: {
      label: 'dpgT04',
      icon: icons.passenger,
      groupRelation: Groups.DPGTemperature,
    },
    dpgT05: {
      label: 'dpgT05',
      icon: icons.passenger,
      groupRelation: Groups.DPGTemperature,
    },
    dpgT06: {
      label: 'dpgT06',
      icon: icons.passenger,
      groupRelation: Groups.DPGTemperature,
    },
    dpgT07: {
      label: 'dpgT07',
      icon: icons.passenger,
      groupRelation: Groups.DPGTemperature,
    },
    dpgT08: {
      label: 'dpgT08',
      icon: icons.passenger,
      groupRelation: Groups.DPGTemperature,
    },
    dpgT09: {
      label: 'dpgT09',
      icon: icons.passenger,
      groupRelation: Groups.DPGTemperature,
    },
    dpgT10: {
      label: 'dpgT10',
      icon: icons.passenger,
      groupRelation: Groups.DPGTemperature,
    },
    dpgT11: {
      label: 'dpgT11',
      icon: icons.passenger,
      groupRelation: Groups.DPGTemperature,
    },
    dpgT12: {
      label: 'dpgT12',
      icon: icons.passenger,
      groupRelation: Groups.DPGTemperature,
    },
    dpgT13: {
      label: 'dpgT13',
      icon: icons.passenger,
      groupRelation: Groups.DPGTemperature,
    },
    dpgT14: {
      label: 'dpgT14',
      icon: icons.passenger,
      groupRelation: Groups.DPGTemperature,
    },
    dpgT15: {
      label: 'dpgT15',
      icon: icons.passenger,
      groupRelation: Groups.DPGTemperature,
    },
  },
  [ProfilesTypes.stationDetails]: {
    tAir: {
      label: 'tAir',
      shortLabel: 'tAirShort',
      icon: icons.tempAir,
      groupRelation: Groups.Temperature,
    },
    tRoad: {
      label: 'tRoad',
      shortLabel: 'tRoadShort',
      icon: icons.tempSurface,
      groupRelation: Groups.Temperature,
    },
    tDewPoint: {
      label: 'tDewPoint',
      shortLabel: 'tDewPointShort',
      icon: icons.tempDewPoint,
      groupRelation: Groups.Temperature,
    },
    tUnderroad: {
      label: 'tUnderroad',
      shortLabel: 'tUnderroadShort',
      icon: icons.tempBelowSurface,
      groupRelation: Groups.Temperature,
    },
    rainIntensity: {
      label: 'rainIntensity',
      shortLabel: 'rainIntensityShort',
      icon: icons.deviationRain,
      groupRelation: Groups.Precipitation,
    },
    snowIntensity: {
      label: 'snowIntensity',
      shortLabel: 'snowIntensityShort',
      icon: icons.deviationSnow,
      groupRelation: Groups.Precipitation,
    },
    windSpeed: {
      label: 'windSpeed',
      icon: icons.windSpeed,
      groupRelation: Groups.Wind,
    },
    humidity: {
      label: 'humidity',
      icon: icons.humidOne,
      groupRelation: Groups.Other,
    },
  },
  [ProfilesTypes.sops]: {
    tAir: {
      label: 'tAir',
      shortLabel: 'tAirShort',
      icon: icons.tempAir,
      groupRelation: Groups.Temperature,
    },
    tRoad: {
      label: 'tRoad',
      shortLabel: 'tRoadShort',
      icon: icons.tempSurface,
      groupRelation: Groups.Temperature,
    },
    tFreezingPoint: {
      label: 'tFreezingPoint',
      shortLabel: 'tFreezingPointShort',
      icon: icons.tempFreezing,
      groupRelation: Groups.DeicingAgents,
    },
    humidity: {
      label: 'humidity',
      icon: icons.humidOne,
      groupRelation: Groups.Other,
    },
    tDewPoint: {
      label: 'tDewPoint',
      shortLabel: 'tDewPointShort',
      icon: icons.tempDewPoint,
      groupRelation: Groups.Temperature,
    },
    precipitationType: {
      label: 'precipitationType',
      icon: icons.typeRain,
      groupRelation: Groups.Precipitation,
    },
    precipitationIntensity: {
      label: 'precipitationIntensity',
      shortLabel: 'precipitationIntensityShort',
      icon: icons.precipitationIntensity,
      groupRelation: Groups.Precipitation,
    },
    precipitationInstant: {
      label: 'precipitationInstant',
      icon: icons.sumRain,
      groupRelation: Groups.Precipitation,
    },
    statusDamp: {
      label: 'statusDamp',
      shortLabel: 'statusDampShort',
      icon: icons.surfaceCondition,
      groupRelation: Groups.Precipitation,
    },
    waterHeight: {
      label: 'waterHeight',
      shortLabel: 'waterHeightShort',
      icon: icons.layerThickness,
      groupRelation: Groups.Precipitation,
    },
    windDirGr: {
      label: 'windDirGr',
      icon: icons.windDirectionDeg,
      groupRelation: Groups.Wind,
    },
    windSpeed: {
      label: 'windSpeed',
      icon: icons.windSpeed,
      groupRelation: Groups.Wind,
    },
    windGusts: {
      label: 'windGusts',
      icon: icons.windGusts,
      groupRelation: Groups.Wind,
    },
    visibility: {
      label: 'visibility',
      icon: icons.visibility,
      groupRelation: Groups.Other,
    },
    pressure: {
      label: 'pressure',
      shortLabel: 'pressureShort',
      icon: icons.pressure,
      groupRelation: Groups.Other,
    },
    salineConcentration: {
      label: 'salineConcentration',
      icon: icons.deicingAgents,
      groupRelation: Groups.DeicingAgents,
    },
    tUnderroad: {
      label: 'tUnderroad',
      shortLabel: 'tUnderroadShort',
      icon: icons.tempBelowSurface,
      groupRelation: Groups.Temperature,
    },
    cloudiness: {
      label: 'cloudiness',
      shortLabel: 'cloudinessShort',
      icon: icons.sky,
      groupRelation: Groups.Other,
    },
    progRecCnctr: {
      label: 'progRecCnctr',
      icon: icons.chemicals,
      groupRelation: Groups.DeicingAgents,
    },
  },
};

export default MeteoParametersSetup;
