import React, { ChangeEvent, memo, MutableRefObject, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import icons from '@app/assets/iconFont';
import { CSDInputStandard } from '@app/modules/kit-module/shared/ui';
import { DEFAULT_INPUT_LENGTH, EMPTY_STRING } from '@app/v2/shared/constants';
import useProfileContext from '@app/core/context/profile/useProfileContext';

type Props = {
  inputRef: MutableRefObject<HTMLDivElement>;
  activeProfile: Profiles.CommonProfile;
  onChange: (nextLabel: string) => void;
} & I18N.TranslationFunction<'profiles'>;

const CSDTableDataProfileNameInput = ({ activeProfile, onChange, inputRef, t }: Props) => {
  const { setError, error } = useProfileContext();

  const [touched, setTouched] = useState<boolean>(false);

  useEffect(() => {
    if (!activeProfile?.label && touched) {
      setError(t('errors.requiredInput'));
    }
  }, [activeProfile, setError, touched, t]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    onChange(value.trim());
  };

  return (
    <CSDInputStandard
      inputRef={inputRef}
      label={t('title')}
      maxLength={DEFAULT_INPUT_LENGTH}
      icon={icons.edit}
      error={!!error}
      helperText={!!error && error}
      value={activeProfile?.label || EMPTY_STRING}
      onChange={handleOnChange}
      onBlur={() => setTouched(true)}
      placeholder={t('placeholder.typeNewProfile')}
      sx={{ maxWidth: '28.125rem' }}
    />
  );
};

export default withTranslation('profiles')(memo(CSDTableDataProfileNameInput));
