import React from 'react';
import { withTranslation } from 'react-i18next';
import { CSDIconPanelPaper, CSDSwitcher } from '@app/modules/kit-module/shared/ui';
import { useSystem } from '@app/v2/shared/hooks';
import { DataTestIds } from '@app/v2/shared/enums';

type Props = {
  switcherLabel?: string;
} & I18N.TranslationFunction<'filters'>;

const CSDHeaderVideoWallSwitcher = ({ switcherLabel, t }: Props) => {
  const { handleChangeVideoWallStatus, isVideoWall } = useSystem();
  return (
    <CSDIconPanelPaper>
      <CSDSwitcher
        dataTestId={DataTestIds.VideoWallSwitcher}
        checked={isVideoWall}
        size="small"
        label={switcherLabel || t('video')}
        onChange={(_, checked) => {
          handleChangeVideoWallStatus(checked);
        }}
      />
    </CSDIconPanelPaper>
  );
};

export default withTranslation('filters')(CSDHeaderVideoWallSwitcher);
