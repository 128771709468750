import React from 'react';
import { Button, Icon, Stack } from '@mui/material';
import { sizeButtons, outlinedButtons, outlinedButtonsWithIcon, buttonsWithIcon, disabledButtons, outlinedDisabledButtons } from './example';
import CSDKitDocBlock from '../../../shared/ui/CSDKitDocBlock/CSDKitDocBlock';

const CSDButtons = () => {
  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="Size buttons" codeExample={sizeButtons}>
        <>
          <Button size="large">Отчёт</Button>
          <Button>Отчёт</Button>
          <Button size="small">Отчёт</Button>
        </>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Buttons with icon" codeExample={buttonsWithIcon}>
        <>
          <Button size="large" startIcon={<Icon>save</Icon>}>
            Отчёт
          </Button>
          <Button startIcon={<Icon>save</Icon>}>Отчёт</Button>
          <Button size="small" startIcon={<Icon>save</Icon>}>
            Отчёт
          </Button>
        </>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Disabled buttons" codeExample={disabledButtons}>
        <>
          <Button size="large" disabled>
            Отчёт
          </Button>
          <Button disabled>Отчёт</Button>
          <Button size="small" disabled>
            Отчёт
          </Button>
        </>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Size outlined buttons" codeExample={outlinedButtons}>
        <>
          <Button size="large" variant="outlined">
            Отчёт
          </Button>
          <Button variant="outlined">Отчёт</Button>
          <Button size="small" variant="outlined">
            Отчёт
          </Button>
        </>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Outlined buttons with icon" codeExample={outlinedButtonsWithIcon}>
        <>
          <Button size="large" variant="outlined" startIcon={<Icon>save</Icon>}>
            Отчёт
          </Button>
          <Button variant="outlined" startIcon={<Icon>save</Icon>}>
            Отчёт
          </Button>
          <Button size="small" variant="outlined" startIcon={<Icon>save</Icon>}>
            Отчёт
          </Button>
        </>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Outlined disabled buttons" codeExample={outlinedDisabledButtons}>
        <>
          <Button variant="outlined" size="large" disabled>
            Отчёт
          </Button>
          <Button variant="outlined" disabled>
            Отчёт
          </Button>
          <Button variant="outlined" size="small" disabled>
            Отчёт
          </Button>
        </>
      </CSDKitDocBlock>
    </Stack>
  );
};

export default CSDButtons;
