import { useMemo } from 'react';
import get from 'lodash/get';
import { isRootCell } from '@app/modules/log-module/common/listing/ListingHeader';
import { HeaderCellValue } from '@app/modules/log-module/common/types/ListingTypes';

export default function usePreparedHeaders(headers = []) {
  const isTwoRow = useMemo(() => headers.some(header => isRootCell(header)), [headers]);

  const prepared: HeaderCellValue[] = useMemo<HeaderCellValue[]>(() => {
    if (isTwoRow) {
      return headers.map(item => (isRootCell(item) ? item.children : item)).flat();
    }

    return headers as HeaderCellValue<{}>[];
  }, [headers, isTwoRow]);

  const getText = (data: any, { value }: HeaderCellValue | any) => {
    const text = typeof value === 'function' ? value(data) : get(data, value, '-');

    if (text === null || text === undefined) {
      return '-';
    }

    return text;
  };

  return { prepared, isTwoRow, getText };
}
