import React, { useCallback } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { typedMemo } from '@app/v2/shared/utils';
import withScrollSpeedLimit from './HOCs/withScrollSpeedLimit';
import withVirtualizeCache from './HOCs/withVirtualizeCache';
import type { DataGridVirtualizedProps, TableData, WithVirtualizeCache } from './types';
import createTableRowClassList from './helpers';
import CSDTableCell from '../../CSDTableCells/CSDTableCell';
import CSDTableEmptyCell from '../../CSDTableCells/CSDTableEmptyCell';
import CSDTable, { UNHIGHLIGHTED_ROW_INDEX } from '../../CSDTable/CSDTable';

const DataGridVirtualized = <T extends TableData>({
  columns,
  headerRows,
  tableData,
  getRowCells,
  itemContent,
  highlightMode,
  ...tableProps
}: DataGridVirtualizedProps<T> & WithVirtualizeCache) => {
  const tableHeaderRows = useCallback(
    () =>
      Object.entries(headerRows).map(([key, headerColumns]) => (
        <TableRow key={`data-grid-table-head-${key}`}>
          {headerColumns.map(({ field, headerName, renderHead, tooltip, thProps, columnIndex }) => {
            const header = renderHead || headerName || field;
            return (
              <CSDTableCell
                key={`data-grid-table-head-${field}`}
                label={tooltip}
                rowIndex={UNHIGHLIGHTED_ROW_INDEX}
                columnIndex={columnIndex}
                {...thProps}
              >
                {header}
              </CSDTableCell>
            );
          })}
        </TableRow>
      )),
    [headerRows],
  );

  return (
    <TableVirtuoso
      style={{ height: 400 }}
      totalCount={tableData.length}
      data={tableData}
      components={{
        Table: props => <CSDTable {...props} highlightMode={highlightMode} />,
        Scroller: TableContainer,
        TableHead,
        TableRow: ({ item: _item, ...props }: any) => <TableRow className={createTableRowClassList(_item)} {...props} />,
        TableBody,
        EmptyPlaceholder: () => (
          <TableBody>
            <TableRow>
              <CSDTableEmptyCell colSpan={Object.values(headerRows)[0].length} />
            </TableRow>
          </TableBody>
        ),
      }}
      fixedHeaderContent={tableHeaderRows}
      itemContent={itemContent}
      {...tableProps}
    />
  );
};

export default withScrollSpeedLimit(withVirtualizeCache(typedMemo(DataGridVirtualized)));
