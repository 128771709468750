import React from 'react';
import TemplateMessageForm from '@app/modules/scoreboard-module/templates/form/TemplateMessageForm';
import SignContextProvider from '@app/modules/scoreboard-module/contexts/sign/SignContextProvider';
import ConfigurationContextProvider from '@app/modules/scoreboard-module/contexts/configuration/ConfigurationContextProvider';

const TemplateMessageFormRenderless = () => {
  return (
    <ConfigurationContextProvider>
      <SignContextProvider>
        <TemplateMessageForm />
      </SignContextProvider>
    </ConfigurationContextProvider>
  );
};

export default React.memo(TemplateMessageFormRenderless);
