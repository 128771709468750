import React from 'react';
import CSDUpdateVideoCamera from './CSDUpdateVideoCamera';
import CSDUpdateVideoCameraBtn from './CSDUpdateVideoCameraBtn';

interface Props {
  stationId: number;
  onResult?: (url: string) => void;
  disabled?: boolean;
}

const CSDUpdateVideoCameraFeature = ({ stationId, ...other }: Props) => {
  return <CSDUpdateVideoCamera AnchorNode={CSDUpdateVideoCameraBtn} id={stationId} {...other} />;
};

export default CSDUpdateVideoCameraFeature;
