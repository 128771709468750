export enum Groups {
  Temperature = 'temperature',
  Precipitation = 'precipitation',
  Other = 'other',
  Wind = 'wind',
  DeicingAgents = 'deicingAgents',
  TransportTypes = 'transportTypes',
  Common = 'common',
  DPGTemperature = 'DPGTemperature',
}

export enum MeteoParameters {
  TAir = 'tAir',
  TUnderRoad = 'tUnderroad',
  TUnderRoad2 = 'tUnderroad2',
  TRoad = 'tRoad',
  TRoad2 = 'tRoad2',
  TDewPoint = 'tDewPoint',

  PrecipitationIntensity = 'precipitationIntensity',
  PrecipitationInstant = 'precipitationInstant',
  PrecipitationType = 'precipitationType',
  WaterHeight = 'waterHeight',
  WaterLevel = 'waterLevel',
  WaterHeight2 = 'waterHeight2',
  StatusDamp = 'statusDamp',
  StatusDamp2 = 'statusDamp2',

  Visibility = 'visibility',
  Pressure = 'pressure',
  Humidity = 'humidity',
  Cloudiness = 'cloudiness',

  WindDirGr = 'windDirGr',
  WindSpeed = 'windSpeed',
  WindGusts = 'windGusts',
  WindDirection = 'windDirection',

  TFreezingPoint = 'tFreezingPoint',
  TFreezingPoint2 = 'tFreezingPoint2',
  SalineConcentration = 'salineConcentration',
  SalineConcentration2 = 'salineConcentration2',

  Cars = 'cars',
}

export enum MeteoDPG {
  D01 = 'dpgT01',
  D02 = 'dpgT02',
  D03 = 'dpgT03',
  D04 = 'dpgT04',
  D05 = 'dpgT05',
  D06 = 'dpgT06',
  D07 = 'dpgT07',
  D08 = 'dpgT08',
  D09 = 'dpgT09',
  D10 = 'dpgT10',
  D11 = 'dpgT11',
  D12 = 'dpgT12',
  D13 = 'dpgT13',
  D14 = 'dpgT14',
  D15 = 'dpgT15',
}

export enum MeteoParametersUnist {
  DegreesCelsius = '℃',
  Meters = 'м',
}
