import axios from 'axios';
import tokenStorage from '@app/clients/apollo/tokenStorage';

const client = axios.create();

client.interceptors.request.use(
  config => {
    return { ...config, headers: { authorization: `Bearer ${tokenStorage.get()}`, ...config.headers } };
  },
  e => Promise.reject(e),
);

export default client;
