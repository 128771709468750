export enum IncidentObjects {
  Motorbike = 'motorbike',
  Car = 'car',
  CarTrailer = 'car-trailer',
  SmallTruck = 'small-truck',
  RoadTrain = 'road-train',
  Bus = 'bus',
  Person = 'person',
  Cyclist = 'cyclist',
  KickScooter = 'kick-scooter',
}
export enum IncidentStatuses {
  Warning = 'OBJECT_IN_ZONE',
  Alert = 'WRONG_DIRECTION_IN_ZONE',
}
