import { Subject } from 'rxjs';
import isEqual from 'lodash/isEqual';
import BaseManager from '@app/core/source/BaseManager';
import { SortStatus } from '@app/v2/shared/enums';

// eslint-disable-next-line import/prefer-default-export
export class SortingManager extends BaseManager<Common.Sorting> {
  private changeSubject = new Subject();

  change = this.changeSubject.asObservable();

  constructor() {
    super({ key: '', value: SortStatus.None });
  }

  next(data: Common.Sorting) {
    if (!isEqual(this.value, data)) {
      super.next(data);

      this.changeSubject.next(data);
    }
  }
}
