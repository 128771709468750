export const input = `<CSDInput label="Вопрос" defaultValue="2-я бауманская д.5" />`;

export const separateInput = `<SeparateInput />`;

export const inputWithError = `<CSDInput 
  label="Вопрос" 
  defaultValue="2-я бауманская д.5" 
  helperText="Что-то пошло не так" 
  error 
/>`;

export const disabledInput = `<CSDInput label="Вопрос" defaultValue="2-я бауманская д.5" disabled />`;

export const standardInput = `<CSDInputStandard label="Вопрос" defaultValue="2-я бауманская д.5" />`;

export const disabledStandardInput = `<CSDInputStandard label="Вопрос" defaultValue="2-я бауманская д.5" disabled />`;

export const standardInputWithIcon = `<CSDInputStandard label="Вопрос" icon="search" defaultValue="2-я бауманская д.5" />`;

export const multilineInput = `<CSDInput 
  label="Вопрос" 
  multiline 
  rows={4} 
  defaultValue="2-я бауманская д.5"
/>`;

export const disabledMultilineInput = `<CSDInput 
  label="Вопрос" 
  multiline 
  rows={4} 
  defaultValue="2-я бауманская д.5" 
  disabled 
/>`;

export const multilineInputWithError = `<CSDInput 
    label="Вопрос" 
    multiline 
    rows={4} 
    defaultValue="2-я бауманская д.5" 
    error 
    helperText="Что-то пошло не так!"
 />`;

export const inputWithStartIcon = `<CSDInput
    label="Вопрос"
    defaultValue="2-я бауманская д.5"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Icon>search</Icon>
        </InputAdornment>
      ),
    }}
/>`;

export const inputWithEndIcon = `<CSDInput
    label="Вопрос"
    defaultValue="2-я бауманская д.5"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <Icon>search</Icon>
        </InputAdornment>
      ),
    }}
/>`;
