import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { useAppSelector } from '../../../hooks';

const NotFound = () => {
  const classes = useStyles();
  const startPage = useAppSelector(state => state.account.settings.startPage);

  const { t } = useTranslation('common', { keyPrefix: 'notFound' });

  return (
    <div className={classes.page}>
      <h1>
        <div className={classes.errorText}>Не найдено: error 404</div>
        <div className={classes.errorText}>
          <a style={{ textDecoration: 'none' }} href={startPage}>
            {' '}
            {t('content')}
          </a>
        </div>
      </h1>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
    fontWeight: 500,
    fontSize: 20,
  },
  errorText: {
    textAlign: 'center',
  },
}));

export default NotFound;
