import React, { forwardRef, useEffect, useMemo } from 'react';
import moment from 'moment/moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TextField } from '@mui/material';
import 'moment/locale/ru';
import { useAppSelector } from '@app/v2/shared/hooks';
import { Languages } from '@app/v2/shared/enums';

export enum DateComponentType {
  datetime = 'datetime',
  keyboard = 'keyboard',
  default = 'default',
}

const COMPONENT_MAP = {
  [DateComponentType.datetime]: DateTimePicker,
  [DateComponentType.keyboard]: DesktopDatePicker,
  [DateComponentType.default]: DesktopDatePicker,
};

moment.locale(Languages.RU.toLowerCase());

const Datepicker = ({ pickOnly = false, dataTestId, ...props }: any, ref) => {
  const { format = 'DD/MM/YYYY', type = DateComponentType.keyboard, size = 'small', variant = 'standard', margin = 'none', fullWidth = true } = props;
  const { locale } = useAppSelector(state => state.account.settings);

  const DateComponent = useMemo(() => COMPONENT_MAP[type], [type]);

  useEffect(() => {
    moment.locale(locale?.toLocaleLowerCase() || Languages.RU.toLowerCase());
  }, [locale]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale.toLocaleLowerCase()}>
      <div ref={ref}>
        <DateComponent
          showToolbar
          inputFormat={format}
          renderInput={params => (
            <TextField
              size={size}
              margin={margin}
              variant={variant}
              fullWidth={fullWidth}
              data-testid={dataTestId}
              {...params}
              inputProps={{ ...params.inputProps, readOnly: pickOnly }}
            />
          )}
          {...props}
        />
      </div>
    </LocalizationProvider>
  );
};

export default forwardRef(Datepicker);
