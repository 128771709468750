/* eslint-disable no-param-reassign */
import { Moment } from 'moment/moment';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReducersNames } from '@store/constants';
import { TrafficChartDirection, TrafficChartInterval, TrafficChartType, TrafficViewType } from '@app/core/types/charts/traffic';

export interface TrafficChartInitialState {
  dateFrom: Moment | null;
  dateTo: Moment | null;
  direction: TrafficChartDirection;
  interval: TrafficChartInterval;
  typeChart: TrafficChartType;
  typeView: TrafficViewType;
  isViewAdditionalChart: boolean;
}

const initialState: TrafficChartInitialState = {
  dateFrom: null,
  dateTo: null,
  direction: TrafficChartDirection.Both,
  interval: TrafficChartInterval.Day,
  typeChart: TrafficChartType.Column,
  typeView: TrafficViewType.BY_CLASS,
  isViewAdditionalChart: false,
};

const trafficChartSlice = createSlice({
  name: ReducersNames.trafficChart,
  initialState,
  reducers: {
    setTrafficChartDateFrom(state: TrafficChartInitialState, { payload }: PayloadAction<Pick<TrafficChartInitialState, 'dateFrom'>>) {
      state.dateFrom = payload.dateFrom;
    },
    setTrafficChartDateTo(state: TrafficChartInitialState, { payload }: PayloadAction<Pick<TrafficChartInitialState, 'dateTo'>>) {
      state.dateTo = payload.dateTo;
    },
    setTrafficChartDates(state: TrafficChartInitialState, { payload }: PayloadAction<Pick<TrafficChartInitialState, 'dateTo' | 'dateFrom'>>) {
      state.dateFrom = payload.dateFrom;
      state.dateTo = payload.dateTo;
    },
    setTrafficChartDirection(state: TrafficChartInitialState, { payload }: PayloadAction<Pick<TrafficChartInitialState, 'direction'>>) {
      state.direction = payload.direction;
    },
    setTrafficChartInterval(state: TrafficChartInitialState, { payload }: PayloadAction<Pick<TrafficChartInitialState, 'interval'>>) {
      state.interval = payload.interval;
    },
    setTrafficChartType(state: TrafficChartInitialState, { payload }: PayloadAction<Pick<TrafficChartInitialState, 'typeChart'>>) {
      state.typeChart = payload.typeChart;
    },
    setTrafficChartViewType(state: TrafficChartInitialState, { payload }: PayloadAction<Pick<TrafficChartInitialState, 'typeView'>>) {
      state.typeView = payload.typeView;
    },
    toggleIsViewAdditionalChart(
      state: TrafficChartInitialState,
      { payload }: PayloadAction<Pick<TrafficChartInitialState, 'isViewAdditionalChart'>>,
    ) {
      state.isViewAdditionalChart = payload.isViewAdditionalChart;
    },
  },
});

export const trafficChartActions = trafficChartSlice.actions;

export default trafficChartSlice.reducer;
