import React, { useMemo } from 'react';
import { Grid, Icon, IconButton } from '@mui/material';
import DeleteButton from '@app/components/delete-table-row-by-id/DeleteButton';
import UpdateNoteButton from '@app/modules/map-module/components/map/notes/common/actions/UpdateNoteButton';
import EllipsisTooltip from '@app/components/notes/EllipsisTooltip';
import icons from '@app/assets/iconFont';
import mapNoteMutations from '@app/clients/apollo/requests/mutations/notes';
import { MapNoteType } from '@app/modules/map-module/components/map/notes/types';
import { useAccount, useCheckAccess } from '@app/v2/shared/hooks';
import { UsersAccess } from '@app/v2/shared/enums';

type Props = {
  deps: {
    t: Function;
    showTime: any;
    classes: any;
    formatAddress: any;
    formatRoad: any;
    updateNoteButtonProps: any;
    onReset: any;
    openNodeInfoDialog: Function;
    closePopperHandler?: Function;
  };
  values: MapNoteType;
};

const NoteHeader = ({ deps, values }: Props) => {
  const { classes, updateNoteButtonProps, onReset, closePopperHandler } = deps;

  const { settings } = useAccount();
  const { accessChecker } = useCheckAccess();
  const isUserRoot = useMemo(() => accessChecker([UsersAccess.ROOT]), [accessChecker]);
  const isCreator = settings.userId === values.user.id;

  const isUserCanEdit = isUserRoot || isCreator;
  const isUserCanDelete = isUserCanEdit;

  return (
    <Grid container alignItems="center" justifyContent="space-between" pb={1} wrap="nowrap">
      <Grid item xs className={classes.titleContainer}>
        <EllipsisTooltip className={classes.titleText} text={values.title} placement="top" />
      </Grid>
      <Grid item className={classes.buttonContainer}>
        {isUserCanEdit && (
          <UpdateNoteButton
            {...updateNoteButtonProps}
            id={values.id}
            onUpdate={() => {
              typeof updateNoteButtonProps.onUpdate() === 'function' && updateNoteButtonProps.onUpdate();
              typeof closePopperHandler === 'function' && closePopperHandler();
            }}
            dialogData={{
              ...updateNoteButtonProps.dialogData,
              values,
              closePopperHandler,
            }}
          />
        )}
        {isUserCanDelete && <DeleteButton id={values.id} query={mapNoteMutations.delete} onDelete={onReset} />}
        {closePopperHandler && (
          <IconButton onClick={() => closePopperHandler()} edge={false}>
            <Icon color="action" sx={{ fontSize: 24, '&:hover': { color: '#3245af' } }}>
              {icons.close}
            </Icon>
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default NoteHeader;
