import moment, { Moment } from 'moment';
import { REPORT_DAY_SHIFT, DEFAULT_DATE_HOURS, DEFAULT_DATE_MINUTES } from '../constants';

const getDailyDateCfg = (
  date: string,
): {
  minDateWithTime: Moment;
  maxDateWithTime: Moment;
} => {
  if (!date) {
    return {
      minDateWithTime: null,
      maxDateWithTime: null,
    };
  }
  const startDateWithTime = moment(date).set({ hour: DEFAULT_DATE_HOURS, minute: DEFAULT_DATE_MINUTES });
  const endDateWithTime = startDateWithTime.clone().add(REPORT_DAY_SHIFT, 'day');

  return {
    minDateWithTime: startDateWithTime,
    maxDateWithTime: endDateWithTime,
  };
};

export default getDailyDateCfg;
