import sentryProvider from '@app/core/logger/providers/SentryProvider';
import { SentryError } from '@app/core/logger/interfaces/Errors';

export interface LoggerInterface {
  init: Function;
  registerError: <Extra>(params: SentryError<Extra>) => void;
  createReduxEnhancer?: (enhancerOptions: any) => any;
}

export class Logger implements LoggerInterface {
  protected provider;

  constructor(provider: LoggerInterface) {
    this.provider = provider;
  }

  init() {
    this.provider.init();
  }

  registerError<Extra>(error: SentryError<Extra>) {
    this.provider.registerError(error);
  }

  createReduxEnhancer(enhancerOptions = {}) {
    if (this.provider.createReduxEnhancer) {
      return this.provider.createReduxEnhancer(enhancerOptions);
    }
    return null;
  }
}

const logger = new Logger(sentryProvider);

export default logger;
