import moment, { Moment } from 'moment';

interface TimePeriods {
  now: Moment;
  yesterday: Moment;
  weekAgo: Moment;
}

export default function useTimePeriods(): TimePeriods {
  return {
    now: moment(),
    yesterday: moment().subtract(1, 'day'),
    weekAgo: moment().subtract(1, 'week'),
  };
}
