import { interval, merge } from 'rxjs';
import { debounce as rxDebounce } from 'rxjs/operators';
import { debounce } from 'lodash';
import { PaginationManager } from '@app/core/source/manages/Pagination';
import TableElementsManager from '@app/core/source/manages/TabelElements';
import DataManager from '@app/core/source/manages/Data';
import BaseDataSource from '@app/core/source/BaseDataSource';
import CommonFiltersManager from '@app/core/source/manages/CommonFiltersManager';
import { DEBOUNCE_FETCH_INTERVAL, DEBOUNCE_INTERVAL, DEBOUNCE_SCROLL_INTERVAL } from '@app/store/constants';
import { strictlyEqual } from '@app/v2/shared/helpers';
import { TableMode } from '@app/v2/shared/enums';
import { showInfo } from '@app/core/utils/notifications';
import { SortingManager } from '@app/core/source/manages/Sorting';
import DataSourceUtils from './DataSourceUtils';

export default abstract class DataSource<T extends Common.BaseStation> extends BaseDataSource {
  utils = new DataSourceUtils<T>();

  pagination = new PaginationManager();

  sorting = new SortingManager();

  data = new DataManager<T>();

  currentTableElementsIds = new TableElementsManager();

  filters = new CommonFiltersManager<Filters.Values>();

  isInit = false;

  protected step = 0;

  protected subscribers = [this.pagination.change, this.filters.change, this.sorting.change];

  abstract getData<Params extends Common.Params>(options: Common.GetDataOptions<Params>): Promise<Common.ResponseDataStation<T>>;

  public init(initFilters: Filters.Values = null) {
    if (this.isInit) return;

    this.loading.next(true);

    this.isInit = true;

    this.subscribeSorting();
    this.subscribePagination();
    this.subscribeCurrentTableElements();

    this.filters.next(initFilters);
  }

  public getReloadAction(currentTableMode: TableMode, updateIds: number[] = []) {
    const updateActionsByTableMode = {
      [TableMode.Pagination]: this.load.bind(this),
      [TableMode.Scroll]: async () => {
        const isAlertStatusFilterExist = !!this.filters.value?.alertStatus?.length;

        if (!isAlertStatusFilterExist) return this.currentTableElementsIds.next(updateIds);

        if (this.pagination.value.page === 1) await this.load();
        else this.resetDataAndSetPageToDefaultValue();

        return null;
      },
    };

    return updateActionsByTableMode[currentTableMode];
  }

  load() {
    return this.fetch();
  }

  protected subscribePagination() {
    merge(...this.subscribers)
      .pipe(
        rxDebounce(() => {
          this.step += 1;
          return interval(this.step > 1 ? DEBOUNCE_INTERVAL : 0);
        }),
      )
      .subscribe(() => this.fetch.apply(this));
  }

  protected subscribeSorting() {
    merge(...this.subscribers)
      .pipe(
        rxDebounce(() => {
          this.step += 1;
          return interval(this.step > 1 ? DEBOUNCE_INTERVAL : 0);
        }),
      )
      .subscribe(() => this.fetch.apply(this));
  }

  protected subscribeCurrentTableElements() {
    this.currentTableElementsIds
      .pipe(
        rxDebounce(() => {
          return interval(DEBOUNCE_SCROLL_INTERVAL);
        }),
      )
      .subscribe(() => this.fetchScrollingDataWithId());
  }

  private debounceFetch = debounce(
    async (paginationProps: Partial<Common.Pagination>, filters: Filters.Values, sorting: Common.Sorting, params, fetchWithIds: boolean = false) => {
      try {
        const { data: rawData, total } = await this.getData<{ profileFields: Array<string>; ids: Array<number>; id: number }>({
          pagination: paginationProps,
          filters: this.utils.normalizeFiltersToApi(filters),
          sorting,
          params,
        });

        const currentTime = Date.now();
        const data = rawData.map(item => ({ ...item, [Symbol.for('timestamp')]: currentTime }));

        if (fetchWithIds) {
          const nextDataIds = data.map(({ id }) => id);

          const nextData = this.data.value.data.map(item => {
            if (!nextDataIds.includes(item.id)) return item;
            return data.find(({ id }) => strictlyEqual<number>(id, item.id));
          });

          this.data.next({
            data: nextData,
            total: this.data.value.total,
          });
        } else {
          this.data.next({ data, total });
        }
      } finally {
        this.loading.next(false);
      }
    },
    DEBOUNCE_FETCH_INTERVAL,
  );

  protected async fetch(profileFields?: string[]) {
    const filters = this.getFilters();
    const paginationProps = this.getPaginationProps();
    const sortingProps = this.getSortingProps();

    this.loading.next(true);
    await this.debounceFetch(paginationProps, filters, sortingProps, { profileFields });
  }

  protected async fetchScrollingDataWithId(profileFields?: string[]) {
    const ids = this.getTableElementsIds();
    const filters = this.getFilters();
    const paginationProps = this.getPaginationProps();
    const sortingProps = this.getSortingProps();

    if (!ids.length) return;

    await this.debounceFetch(paginationProps, filters, sortingProps, { profileFields, ids }, true);
  }

  protected getTableElementsIds() {
    const currentIds: Array<number> = this.currentTableElementsIds.value;
    return [...currentIds];
  }

  protected getFilters(): Partial<Filters.BaseModal> {
    if (!this.filters) return undefined;

    return this.filters.value;
  }

  protected getPaginationProps(): Partial<Common.Pagination> {
    const { page, limit } = this.pagination.value;

    return { page, limit };
  }

  protected getSortingProps(): Common.Sorting {
    return this.sorting.value;
  }

  private resetDataAndSetPageToDefaultValue() {
    this.data.next({ data: [], total: 0 });
    this.pagination.next({ ...this.pagination.value, page: 1 });
    showInfo('Данные обновлены, вы были перенаправлены на первую страницу');
  }
}
