import React, { useCallback, useContext } from 'react';
import { DialogContext, DialogRef } from '@app/core/providers/DialogProvider';

export default function useDialog<OpenData, CloseData = any, Props = any>(
  dialog: React.FC<OpenData & Props & DialogRef<CloseData>>,
): (data?: OpenData) => DialogRef<CloseData> {
  const { open: openDialog } = useContext(DialogContext);
  return useCallback<(data?: OpenData) => DialogRef<CloseData>>(
    (data?: OpenData) => openDialog<Props, OpenData, CloseData>(dialog, data),
    [dialog, openDialog],
  );
}
