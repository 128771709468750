/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, useFormikContext } from 'formik';
import { Box } from '@mui/material';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import ParameterSettingForm from '@app/modules/scoreboard-module/templates/form/ParameterSettingForm';
import vmsNewQueries from '@app/clients/apollo/requests/queries/vmsNew';
import { CSDAutocompleteFastField, CSDCheckbox, CSDInputFastField } from '@app/modules/kit-module/shared/ui';
import { EMPTY_STRING } from '@app/v2/shared/constants';
import { boxShadowLight } from '@theme/styles';
import { getScrollStyles } from '@theme/themeSettings/componentSettings';
import { ConfigurationTypes, UsersAccess } from '@app/v2/shared/enums';
import { useCheckAccess } from '@app/v2/shared/hooks';
import { autoBoxContent, NUM_CHARS_PER_LINE, NUM_LINES } from '../../common/constants';
import Scoreboard from '../../common/components/preview/Scoreboard';
import useVMSSelectors from '../../common/hooks/useVMSSelectors';

const PATH = 'content';

const TemplateMessageForm = () => {
  const classes = useStyles();

  const { accessChecker } = useCheckAccess();

  const { values, setFieldValue, getFieldProps, resetForm } = useFormikContext<Omit<Scoreboards.TemplateModel, 'id'>>();

  const { t } = useTranslation('scoreboard');

  const { organizationSelectors, configurationSelectors } = useVMSSelectors();

  const [currentId, setCurrentId] = useState<number | null>(null);
  const [chosenConfiguration, setChosenConfiguration] = useState<Scoreboards.Configuration>(null);

  const { refetch } = useQuery(vmsNewQueries.configuration, { skip: true });

  useEffect(() => {
    if (!values.content?.configuration?.id) {
      setChosenConfiguration(null);
      resetForm({ values });
    }
  }, [values?.content?.configuration?.id, resetForm]);

  useEffect(() => {
    if (values?.content?.configuration?.id) {
      refetch({ id: values?.content?.configuration?.id }).then(({ data: { vmsNew } }) => {
        setChosenConfiguration(vmsNew.configuration);
      });
    }
  }, [refetch, values?.content?.configuration?.id]);

  useEffect(() => {
    if (chosenConfiguration) {
      setFieldValue(`${PATH}.configuration`, chosenConfiguration);
    }
  }, [chosenConfiguration, chosenConfiguration?.title, setFieldValue]);

  useEffect(() => {
    setCurrentId(getFieldProps('id').value);
  }, [getFieldProps]);

  const contentsValuesMapper = useMemo(
    () => ({
      [ConfigurationTypes.Txtbox]: item => new Array(+item[NUM_LINES]).fill({ text: '', align: 'center' }),
      [ConfigurationTypes.Autobox]: () => autoBoxContent.map(i => ({ ...i, text: t(i.text) as string })),
      [ConfigurationTypes.Sgnlst]: () => ({ signId: null }),
    }),
    [t],
  );

  useEffect(() => {
    if (currentId === undefined && chosenConfiguration) {
      const configurationItems = chosenConfiguration?.configuration?.items || [];

      setFieldValue(
        `${PATH}.contents`,
        configurationItems.map(item => {
          return contentsValuesMapper[item.type](item);
        }),
      );
    }
  }, [contentsValuesMapper, currentId, setFieldValue, chosenConfiguration?.configuration, chosenConfiguration]);

  const [isViewPreview] = useMemo(
    () => values?.content?.contents.map(c => (Array.isArray(c) ? c.some(item => item?.text?.length !== 0) : Boolean(c?.signId))).filter(Boolean),
    [values?.content?.contents],
  );

  return (
    <Form className={classes.form}>
      <Box className={classes.fieldsWrapper}>
        <CSDAutocompleteFastField
          path={`${PATH}.configuration.id`}
          disabled={!accessChecker([UsersAccess.VMS_FULL_EDIT])}
          autocompleteProps={{
            inputProps: {
              placeholder: t('playlists.form.placeholder.chooseConfiguration'),
              disabled: !configurationSelectors.length,
            },
            label: t('templates.form.chooseConfiguration'),
            autocompleteProps: {
              multiple: false,
              options: configurationSelectors ?? [],
            },
          }}
        />

        <CSDAutocompleteFastField
          path="organizationId"
          disabled={values.isCommon}
          autocompleteProps={{
            inputProps: {
              placeholder: values.isCommon ? t('playlists.form.message') : t('playlists.form.placeholder.chooseOrganization'),
            },
            label: t('templates.form.organization'),
            autocompleteProps: {
              multiple: false,
              options: organizationSelectors ?? [],
            },
          }}
        />

        {accessChecker([UsersAccess.VMS_FULL_EDIT]) && (
          <CSDCheckbox
            size="small"
            onChange={async (_, checked) => {
              if (checked) await setFieldValue('organizationId', EMPTY_STRING);
              await setFieldValue('isCommon', checked);
            }}
            checked={values.isCommon}
            label={t('templates.form.isCommon')}
            name="isCommon"
          />
        )}
      </Box>

      {!!values?.content?.configuration?.id && (
        <Box className={classes.fieldsWrapper}>
          <CSDInputFastField path="title" label={t('templates.form.templateTitle')} />

          {chosenConfiguration?.configuration.items.map((item, contentIndex) => (
            <ParameterSettingForm
              key={contentIndex}
              type={item.type}
              options={{
                numLines: +item[NUM_LINES],
                contentIndex,
                numCharactersPerLine: +item[NUM_CHARS_PER_LINE],
              }}
            />
          ))}
        </Box>
      )}

      {!!isViewPreview && (
        <Box sx={{ alignItems: 'center' }} className={classes.fieldsWrapper}>
          {!!values?.content?.contents?.length && <Scoreboard template={values?.content} isListing={false} />}
        </Box>
      )}
    </Form>
  );
};

const useStyles = makeStyles<Theme>(({ palette }) => ({
  form: {
    gap: '1rem',
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    paddingBottom: '1rem',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 20rem)',
    ...getScrollStyles(palette.mode, palette.text),
  },
  fieldsWrapper: {
    gap: '1rem',
    width: '100%',
    display: 'flex',
    borderRadius: '1rem',
    position: 'relative',
    flexDirection: 'column',
    boxShadow: boxShadowLight,
    padding: '1rem 1rem 1rem 1rem',
    border: `1px solid ${palette.text.t5}`,
  },
}));

export default React.memo(TemplateMessageForm);
