import { useEffect, useState } from 'react';
import { useQueryParam } from 'use-query-params';
import { CommaArrayParam } from '@app/v2/shared/constants';

export default function useSearchQuery() {
  const [search, setSearch] = useState<string>('organizations&roads&places');

  const [organizations] = useQueryParam('organizations', CommaArrayParam);
  const [roads] = useQueryParam('roads', CommaArrayParam);
  const [places] = useQueryParam('places', CommaArrayParam);

  useEffect(() => {
    if (organizations === null && roads === null && places === null) {
      setSearch('organizations&roads&places');
    } else {
      const current = new URLSearchParams();

      organizations?.length && current.set('organizations', organizations.join(','));
      roads?.length && current.set('roads', roads.join(','));
      places?.length && current.set('places', places.join(','));
      current.sort();

      setSearch(current.toString());
    }
  }, [roads, organizations, places]);

  return search;
}
