import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { CardContent, Typography } from '@mui/material';
import { INCOMPLETE_EMAIL } from '@app/core/constants/auth';
import useLocalStorage from '@app/core/hooks/useLocalStorage';
import { CSDSeparateInput } from '@app/modules/kit-module/shared/ui';
import { strictlyEqual } from '@app/v2/shared/helpers';
import { EMPTY_STRING } from '@app/v2/shared/constants';
import styles from './confirmationFormContent.module.scss';

type Props = {
  setValue: (value: string) => void;
  isConfirmationError: boolean;
  confirmationErrorMessage: string;
} & I18N.TranslationFunction<'auth', 'confirmation.form'>;

const FormContent = ({ setValue, isConfirmationError, confirmationErrorMessage, t }: Props) => {
  const { getItem } = useLocalStorage();

  return (
    <CardContent className={styles.cardContainer}>
      <Typography align="center" variant="body2">
        {`${t('confirmationMessage')} ${getItem(INCOMPLETE_EMAIL)}`}
      </Typography>

      <CSDSeparateInput
        checker={v => /^[0-9]+$/.test(v) || strictlyEqual<string>(v, EMPTY_STRING)}
        isError={isConfirmationError}
        errorMessage={confirmationErrorMessage}
        onChange={setValue}
      />
    </CardContent>
  );
};

export default withTranslation('auth', { keyPrefix: 'confirmation.form' })(memo(FormContent));
