import { GlobalSettingTypes } from '@app/v2/features/notifications/types';

export const FORECAST_ALIASES = [
  'faZeroCrossingToMinus',
  'faZeroCrossingToPlus',
  'faQuickCooling',
  'faLongSnow',
  'faIceCrustedGround',
  'faStrongWind',
  'faZeroCrossCooling',
  'faPackedSnow',
  'faLongRain',
  'faSleet',
  'faLongRainWithSnow',
  'faLightLooseSnow',
  'faExtremeRain',
  'faExtremeWind',
  'faHeavyLooseSnow',
  'faExtremeLowTemp',
  'faExtremeHighTemp',
  'faExtremeRainWithSnow',
  'faExtremeSnow',
];

export const GLOBAL_SETTING_TYPES: { [key: string]: GlobalSettingTypes } = {
  NOW: {
    label: 'globalTypes.now',
    value: 0,
  },
  FORECAST: {
    label: 'globalTypes.forecast',
    value: 1,
  },
};

export const ALERT_NOTIFICATION_RANK = 1;

export enum NOTIFICATION_SETTINGS_TABS {
  GLOBAL = 'GLOBAL',
  LOCAL = 'LOCAL',
  BULLETINS = 'BULLETINS',
}
