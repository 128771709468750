import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Grid, Button, DialogActions, DialogContent, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

const Form = ({ errors, touched, handleChange, handleBlur, handleSubmit, onClose }: any) => {
  const classes = useStyles();
  const { t: formT } = useTranslation('dialogs', { keyPrefix: 'createProfile.form' });
  const { t: buttonsT } = useTranslation('dialogs', { keyPrefix: 'common.buttons' });

  return (
    <>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="label"
              name="label"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.label ? errors.label : ''}
              error={touched.label && Boolean(errors.label)}
              label={formT('label')}
              fullWidth
              autoComplete="off"
              variant="outlined"
              className={classes.field}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="description"
              name="description"
              onChange={handleChange}
              onBlur={handleBlur}
              label={formT('description')}
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              className={classes.field}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" startIcon={<SaveIcon />} onClick={handleSubmit}>
          {buttonsT('save')}
        </Button>
        <Button variant="outlined" color="secondary" startIcon={<CancelIcon />} onClick={onClose}>
          {buttonsT('cancel')}
        </Button>
      </DialogActions>
    </>
  );
};

const useStyles = makeStyles(() => ({
  field: {
    marginBottom: 16,
  },
}));

export default Form;
