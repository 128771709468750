export enum CardsViewMode {
  View,
  Edit,
}

export enum ConfigurationTypes {
  Txtbox = 'txtbox',
  Sgnlst = 'sgnlst',
  Autobox = 'autobox',
}

export enum MessageStringAlign {
  Center = 'center',
  Left = 'left',
  Right = 'right',
}

export enum VMSTemplatesTypes {
  Message = 'message',
  Sign = 'sign',
  Azpi = 'azpi',
}

export enum ViewTypes {
  Img = 'img',
  Svg = 'svg',
  Canvas = 'canvas',
}

export enum VMSDeviceDirections {
  Forward = 'forward',
  Backward = 'backward',
  Unknown = 'unknown',
}

export enum VMSDeviceTypeOfSupport {
  Center = 'center',
  Left = 'left',
  Right = 'right',
}

export enum DeviceType {
  Message = 'message',
  Sign = 'sign',
  Azpi = 'azpi',
}

export enum VMSEntityTypes {
  Configuration = 'configuration',
  Device = 'device',
  Playlist = 'playlist',
  Sign = 'templates.sign',
  Template = 'template',
}

export enum AZPIMode {
  Active,
  Deactive,
}

export enum TPIConfigurationVolumeLabelValues {
  C = 'C',
  D = 'D',
  E = 'E',
}

export enum TPIConfigurationWordWrapValues {
  Nowrap = 'nowrap',
  Normal = 'normal',
  WordBreak = 'word-break',
}

export enum TPIConfigurationFonts {
  Small = '5x8',
  Medium = '9x16',
  Large = '25x40',
}

export enum TPIConfigurationConnectionType {
  TCP = 'tcp',
  UDP = 'udp',
}
