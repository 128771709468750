import { COLLAPSED_INFO_BADGE, UNCOLLAPSED_INFO_BADGE } from '@app/core/theme/constants';

function getHeaderInfoWrapperWidth(isOpen: boolean): string {
  switch (true) {
    case isOpen:
      return `calc(100% - ${UNCOLLAPSED_INFO_BADGE})`;
    case !isOpen:
      return `calc(100% - ${COLLAPSED_INFO_BADGE})`;
    default:
      return 'calc(100%)';
  }
}

export default getHeaderInfoWrapperWidth;
