import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import useDateWorker from '@app/core/hooks/useDateWorker';
import { useCheckAccess, useMeteoChart, useMeteoChartProfiles, useTimePeriods } from '@app/v2/shared/hooks';
import { CSDButtonsBlock, CSDChipsBlock, CSDDataRangePickerAntdWithBtns, CSDExportChartBtn } from '@app/modules/kit-module/shared/ui';
import { CSDCreateMeteoChartBtn, CSDCreateMeteoChartProfileBtn, CSDDeleteMeteoChartProfileBtn } from '@app/v2/features';
import { UsersAccess, ExecutionType, MeteoChartViewTypes } from '@app/v2/shared/enums';
import { strictlyEqual } from '@app/v2/shared/helpers';
import { formatEn, formatRuFull } from '@app/core/helpers/dateFormat';
import { MAX_PROFILES_AMOUNT } from '@app/v2/shared/constants';

interface Props {
  title: string;
  infoSetup: { name: string; value: string | number }[];
}

const MeteoChartSettingsPanel = ({ title, infoSetup }: Props) => {
  const { t: tCharts } = useTranslation('charts');
  const { t: tCommon } = useTranslation('common');

  const {
    amountsOfPoints,
    viewMode,
    isForecast,
    dateTo,
    dateFrom,
    chartsSetup,
    handleChangeDateTo,
    handleChangeDates,
    handleSetViewMode,
    handleSetOrClearInitialDates,
  } = useMeteoChart();

  const { isActiveProfileCustom, meteoChartActiveProfileId, meteoChartProfiles, amountOfCharts, handleSetMeteoChartActiveProfiles } =
    useMeteoChartProfiles();

  const { addHandler, removeHandler } = useDateWorker();
  const { accessChecker } = useCheckAccess();

  const { now, weekAgo, yesterday } = useTimePeriods();

  useEffect(() => {
    handleSetOrClearInitialDates(ExecutionType.Set);
    return () => handleSetOrClearInitialDates(ExecutionType.Clear);
  }, [handleSetOrClearInitialDates]);

  useEffect(() => {
    const handler = () => handleChangeDateTo(now);

    if (isForecast) addHandler(handler);

    return () => removeHandler(handler);
  }, [addHandler, isForecast, removeHandler, handleChangeDateTo, now]);

  const tooltipValues: Partial<Record<MeteoChartViewTypes, string>> = {
    [MeteoChartViewTypes.Forecast]: tCharts('meteo.tooltipValueWithForecast'),
    [MeteoChartViewTypes.HistoryAndForecast]: tCharts('meteo.tooltipValueWithHistoryAndForecast'),
  };

  return (
    <StyledWrapper direction="row">
      <StyledMainActions direction="row">
        <CSDChipsBlock
          withEndDivider
          title={tCharts('meteoConstructor.profiles')}
          size="small"
          addChip={
            <CSDCreateMeteoChartProfileBtn
              buttonProps={{
                sx: { minWidth: '1rem', height: 'auto', padding: '0.25rem 0.5rem' },
                size: 'small',
              }}
            />
          }
          isAddView={meteoChartProfiles.length < MAX_PROFILES_AMOUNT}
          chipsConfig={meteoChartProfiles.map(({ id, name }) => ({
            id,
            label: name,
            onClick: () => handleSetMeteoChartActiveProfiles(id),
            isActive: strictlyEqual(meteoChartActiveProfileId, String(id)),
            actionsNodes: isActiveProfileCustom ? [<CSDDeleteMeteoChartProfileBtn profileId={id} />] : [],
          }))}
        />

        <CSDDataRangePickerAntdWithBtns
          label={tCharts('date')}
          hidden={!amountOfCharts}
          withEndDivider
          disableAllBtns={strictlyEqual(viewMode, MeteoChartViewTypes.Forecast)}
          tooltipTitle={tooltipValues[viewMode]}
          value={[dateFrom, dateTo]}
          onChange={([nextDateFrom, nextDateTo]) => handleChangeDates([nextDateFrom.startOf('day'), nextDateTo.endOf('day')])}
          disabledDate={current => current && now.endOf('day').isBefore(current)}
          disabled={[strictlyEqual(viewMode, MeteoChartViewTypes.Forecast), isForecast && strictlyEqual(formatEn(dateTo), formatEn(now))]}
          btnsSetup={[
            {
              label: 'dates.day',
              action: () => handleChangeDates([yesterday, now]),
              tooltipTitle: strictlyEqual(viewMode, MeteoChartViewTypes.Forecast) && tooltipValues[viewMode],
              isActive: strictlyEqual(formatEn(dateFrom), formatEn(yesterday)) && strictlyEqual(formatEn(dateTo), formatEn(now)),
            },
            {
              label: 'dates.week',
              action: () => handleChangeDates([weekAgo, now]),
              tooltipTitle: strictlyEqual(viewMode, MeteoChartViewTypes.Forecast) && tooltipValues[viewMode],
              isActive: strictlyEqual(formatEn(dateFrom), formatEn(weekAgo)) && strictlyEqual(formatEn(dateTo), formatEn(now)),
            },
          ]}
        />

        <CSDButtonsBlock
          hidden={!amountOfCharts}
          withEndDivider
          title={tCharts('meteo.data')}
          disableAll={!accessChecker([UsersAccess.METEO_ROADCAST_AND_FORECAST])}
          btnsConfig={[
            {
              label: tCharts('meteo.historyData'),
              onClick: () => handleSetViewMode(MeteoChartViewTypes.History),
              isActive: strictlyEqual(viewMode, MeteoChartViewTypes.History),
            },
            {
              label: tCharts('meteo.historyDataWithForecast'),
              onClick: () => handleSetViewMode(MeteoChartViewTypes.HistoryAndForecast),
              isActive: strictlyEqual(viewMode, MeteoChartViewTypes.HistoryAndForecast),
            },
            {
              label: tCharts('meteo.forecast'),
              onClick: () => handleSetViewMode(MeteoChartViewTypes.Forecast),
              isActive: strictlyEqual(viewMode, MeteoChartViewTypes.Forecast),
            },
          ]}
        />

        <CSDCreateMeteoChartBtn profileId={meteoChartActiveProfileId} chartsSetup={chartsSetup} disabled={!isActiveProfileCustom} />
      </StyledMainActions>

      {!!amountOfCharts && (
        <CSDExportChartBtn
          disabled={amountsOfPoints > 5000}
          title={title}
          infoSetup={[
            ...infoSetup,
            { name: tCommon('dates.periods.dateFrom'), value: formatRuFull(dateFrom) },
            { name: tCommon('dates.periods.dateTo'), value: formatRuFull(dateTo) },
          ]}
          configurations={{
            filename: `meteo-charts-${formatRuFull(now)}`,
            printed: false,
            exportFormats: ['image/png', 'image/jpeg', 'image/svg+xml', 'application/pdf'],
          }}
        />
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Stack)<StackProps>({
  width: '100%',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  padding: '1rem 1.5rem',
  boxShadow: '0px 5px 5px 0px rgba(52, 52, 52, 0.20)',
  zIndex: 1,
});

const StyledMainActions = styled(Stack)<StackProps>({
  width: '90%',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  gap: '1rem',
});

export default MeteoChartSettingsPanel;
