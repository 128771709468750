import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useQuery } from '@apollo/client';
import { DialogRef } from '@app/core/providers/DialogProvider';
import { LinearChartData } from '@app/core/types/charts/linear';
import WrapperProgress from '@app/components/common/wrapper-progress/WrapperProgress';
import meteoQueries from '@app/clients/apollo/requests/queries/meteo';
import { ProfilesTypes } from '@app/v2/shared/enums';
import { useNewFilters, useProfiles } from '@app/v2/shared/hooks';
import LinearChart from './linear-chart/LinearChart';
import LinearChartSettingsPanel from './linear-chart-settings-panel/LinearChartSettingsPanel';

type Props = {
  roadId: number;
  title: string;
} & DialogRef;

const LinearDialog = ({ roadId, title, close }: Props) => {
  const classes = useStyles();
  const { profiles, saveProfiles, loadingProfiles } = useProfiles(ProfilesTypes.linearGraph);

  const { filters } = useNewFilters();

  const organizationIds = filters?.organizations ?? [];

  const [activeProfile, setActiveProfile] = useState<Profiles.CommonProfile>(null);

  const { loading = true, data: { meteoLinearGraph } = {} } = useQuery<{ meteoLinearGraph: LinearChartData }>(meteoQueries.meteoLinearGraph, {
    variables: { roadId, organizationIds },
  });

  useEffect(() => {
    setActiveProfile(profiles.find(profile => profile.isDefault) || null);
  }, [setActiveProfile, profiles]);

  const isShowLinerChart = useMemo(() => {
    return !!meteoLinearGraph && !loadingProfiles && ((profiles.length && activeProfile) || !profiles.length);
  }, [meteoLinearGraph, loadingProfiles, profiles, activeProfile]);

  return (
    <Dialog fullWidth open maxWidth="xl" onClose={() => close('onClose')}>
      <DialogTitle classes={{ root: classes.rootTitle }}>
        <Typography variant="h6">{title}</Typography>
        <IconButton onClick={() => close('icon')}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers classes={{ root: classes.rootContent }}>
        <WrapperProgress loading={loading || loadingProfiles}>
          <LinearChartSettingsPanel
            activeProfile={activeProfile}
            profiles={profiles}
            saveProfiles={saveProfiles}
            onChangeProfile={setActiveProfile}
          />
          {isShowLinerChart && <LinearChart data={meteoLinearGraph} profile={activeProfile} />}
        </WrapperProgress>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  rootTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rootContent: {
    height: '80vh',
  },
}));

export default LinearDialog;
