export const AUTH_TOKEN_KEY = 'authToken';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const REMEMBER_TOKEN_KEY = 'rememberToken';

export const AUTH_STORAGE_KEY = 'authKey';
export const REFRESH_STORAGE_KEY = 'refreshKey';
export const REMEMBER_STORAGE_KEY = 'rememberKey';

type StorageKeys = typeof AUTH_STORAGE_KEY | typeof REFRESH_STORAGE_KEY | typeof REMEMBER_STORAGE_KEY;

export interface TokenOptions {
  storage?: Storage;
  authKey?: string;
  refreshKey?: string;
  rememberKey?: string;
}

class TokenStorage {
  protected storage: Storage;

  protected authKey: string;

  protected refreshKey: string;

  protected rememberKey: string;

  constructor({
    storage = localStorage,
    authKey = AUTH_TOKEN_KEY,
    refreshKey = REFRESH_TOKEN_KEY,
    rememberKey = REMEMBER_TOKEN_KEY,
  }: TokenOptions = {}) {
    this.storage = storage;
    this.authKey = authKey;
    this.refreshKey = refreshKey;
    this.rememberKey = rememberKey;
  }

  get isExistAuthToken(): boolean {
    return !!this.get(AUTH_STORAGE_KEY);
  }

  get isExistRefreshToken(): boolean {
    return !!this.get(REFRESH_STORAGE_KEY);
  }

  get isExistRememberToken(): boolean {
    return !!this.get(REMEMBER_STORAGE_KEY);
  }

  get(key: StorageKeys = AUTH_STORAGE_KEY): string {
    return this.storage.getItem(this[key]);
  }

  set(key: StorageKeys = AUTH_STORAGE_KEY, token: string): void {
    this.storage.setItem(this[key], token);
  }

  remove(key: StorageKeys = AUTH_STORAGE_KEY): void {
    this.storage.removeItem(this[key]);
  }
}

const tokenStorage = new TokenStorage();

export default tokenStorage;
