import { TFunction } from 'react-i18next';
import moment from 'moment';
import {
  createAlertDescription,
  createTwoColumnsLayoutForYAxis,
  createTimeMessageAboutEvent,
  createAlertShortDescription,
} from '../../common/helpers';
import { colors, pointWidth } from '../../common/constants/planChart';
import type { AlertRoadPlan } from '../types';
import type { CustomGanttPointOptionsObject } from '../../common/types';

export default function prepareAlerts(alerts: AlertRoadPlan[], chartsT: TFunction<'charts'>): CustomGanttPointOptionsObject[] {
  if (!alerts && !alerts.length) return [];

  const result: CustomGanttPointOptionsObject[] = [];

  alerts.forEach(alert => {
    const { message, id, from, to, rank, placeId, type, data } = alert;

    result.push({
      id: String(id),
      name: createTwoColumnsLayoutForYAxis(
        { data: type.title, className: 'yaxis-column-text-alert' },
        { data: createTimeMessageAboutEvent(from, to, chartsT), className: 'yaxis-column-text-time' },
      ),
      milestone: moment(from).isSame(to) && true,
      description: createAlertDescription(message, chartsT),
      shortDescription: createAlertShortDescription(data, rank, type.alias, chartsT),
      parent: String(placeId),
      start: moment(from).valueOf(),
      end: moment(to).valueOf(),
      color: colors[rank],
      pointWidth,
    });
  });

  return result;
}
