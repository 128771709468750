/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReducersNames } from '@store/constants';

export enum FederalRoadsEntityTypes {
  Road = 'road',
  RoadSegment = 'roadSegment',
}

interface FederalRoadsState {
  selectedEntity: string | null;
  entityType: FederalRoadsEntityTypes | null;
}

const initialState: FederalRoadsState = {
  selectedEntity: null,
  entityType: null,
};

const federalRoadsSlice = createSlice({
  name: ReducersNames.federalRoads,
  initialState,
  reducers: {
    selectEntity(state: FederalRoadsState, { payload }: PayloadAction<{ entityId: string; type: FederalRoadsEntityTypes }>) {
      state.entityType = payload.type;
      state.selectedEntity = payload.entityId;
    },
  },
});

export const federalRoadsActions = federalRoadsSlice.actions;

export default federalRoadsSlice.reducer;
