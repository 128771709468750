/** Storage field names */

export const INCOMPLETE_EMAIL = 'incompleteEmail';
export const REFRESH_IN = 'refreshIn';
export const EXPIRES_IN = 'expiresIn';
export const EXPIRES_IN_TIMESTAMP = 'expiresInTimeStamp';
export const USER_LOGIN = 'userLogin';
export const CONFIRMATION_COUNTDOWN_VALUE = 'confirmationCountdownValue';
export const PASSWORD_RECOVERY_COUNTDOWN_VALUE = 'passwordRecoveryCountdownValue';

export const removeConfirmKeys = [
  INCOMPLETE_EMAIL,
  USER_LOGIN,
  REFRESH_IN,
  EXPIRES_IN,
  CONFIRMATION_COUNTDOWN_VALUE,
  PASSWORD_RECOVERY_COUNTDOWN_VALUE,
  EXPIRES_IN_TIMESTAMP,
];
