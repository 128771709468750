import React from 'react';
import { withTranslation } from 'react-i18next';
import { Skeleton, SkeletonProps, Stack, StackProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { PlanGraphData } from './types';

type Props = {
  planGraph: PlanGraphData;
  loading: boolean;
  nameOfPlace: string;
} & I18N.TranslationFunction<'charts'>;

const PlanDialogHeader = ({ planGraph, loading, nameOfPlace, t }: Props) => {
  return (
    <StyledWrapperStack>
      {loading ? (
        <Skeleton variant="rectangular" width="13rem" height="1.875rem" sx={{ borderRadius: '0.5rem' }} />
      ) : (
        <Typography fontWeight="bold" fontSize="1.875rem">
          {nameOfPlace}
        </Typography>
      )}

      <StyledInfoStack>
        <StyledInfoItemStack>
          <Typography>ID:</Typography>
          <Typography>{loading ? <StyledInfoItemSkeleton /> : planGraph?.stationId}</Typography>
        </StyledInfoItemStack>
        <StyledInfoItemStack>
          <Typography>{t('road')}:</Typography>
          <Typography>{loading ? <StyledInfoItemSkeleton /> : planGraph?.road?.code}</Typography>
        </StyledInfoItemStack>
        <StyledInfoItemStack>
          <Typography>{t('owner')}:</Typography>
          <Typography>{loading ? <StyledInfoItemSkeleton /> : planGraph?.owner}</Typography>
        </StyledInfoItemStack>
      </StyledInfoStack>
    </StyledWrapperStack>
  );
};

const StyledWrapperStack = styled(Stack)<StackProps>({
  flexDirection: 'column',
  gap: '1rem',
});

const StyledInfoStack = styled(Stack)<StackProps>({
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '3.125rem',
});

const StyledInfoItemStack = styled(Stack)<StackProps>({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '0.5rem',
});

const StyledInfoItemSkeleton = styled(Skeleton)<SkeletonProps>({
  variant: 'rectangular',
  width: '7rem',
  height: '1rem',
  borderRadius: '0.5rem',
});

export default withTranslation('charts')(PlanDialogHeader);
