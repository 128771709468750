import { useLayoutEffect, useState } from 'react';
import { Events } from '@app/v2/shared/enums';

export default function useWindowSize() {
  const [{ height, width }, setSize] = useState<Common.Size>({
    height: 0,
    width: 0,
  });

  useLayoutEffect(() => {
    const resizeListenerHandler = () =>
      setSize({
        height: Math.ceil(window.innerHeight),
        width: Math.ceil(window.innerWidth),
      });

    window.addEventListener(Events.Resize, resizeListenerHandler);

    return () => window.removeEventListener(Events.Resize, resizeListenerHandler);
  }, []);

  return {
    height,
    width,
  };
}
