import React, { ReactNode, CSSProperties, forwardRef } from 'react';
import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  children: ReactNode;
  sx?: CSSProperties;
}

const CSDCenteredBox = ({ children, sx, ...other }: Props, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
};

export default forwardRef(CSDCenteredBox);
