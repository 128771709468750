import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import clsx from 'clsx';
import { boxShadowBorder } from '@theme/styles';
import CSDAccidentNewsCardHeader from './CSDAccidentNewsCardHeader';
import CSDAccidentNewsCardContent from './CSDAccidentNewsCardContent';

interface Props {
  news: AccidentNews.Model;
  hide?: ReactNode;
  addToFavorites?: ReactNode;
  removeFromFavorites?: ReactNode;
}

const CSDAccidentNewsCard = ({ news, hide, removeFromFavorites, addToFavorites }: Props) => {
  const classes = useStyles({ isFavorites: news.isFavorites });

  return (
    <Box className={clsx(classes.card, 'accidentNewsCard')}>
      <CSDAccidentNewsCardHeader title={news.title} hide={hide} addToFavorites={addToFavorites} removeFromFavorites={removeFromFavorites} />

      <CSDAccidentNewsCardContent link={news.link} date={news.pubDate} />
    </Box>
  );
};

const useStyles = makeStyles<Theme, Pick<AccidentNews.Model, 'isFavorites'>>(({ palette }) => ({
  card: {
    height: '8rem',
    padding: '1rem',
    display: 'flex',
    minWidth: '27rem',
    borderRadius: '0.5rem',
    flexDirection: 'column',
    border: '1px solid black',
    justifyContent: 'space-between',
    borderTopColor: ({ isFavorites }) => (isFavorites ? '#f57c00' : palette.primary.main),
    boxShadow: ({ isFavorites }) => boxShadowBorder(isFavorites ? '#f57c00' : palette.primary.main),
    transition: 'box-shadow 0.5s, borderColor 0.5s',

    '&:hover': {
      boxShadow: '0 16px 20px rgb(0 0 0 / 30%)',
      borderColor: ({ isFavorites }) => (isFavorites ? '#f57c00' : palette.primary.main),
    },

    '& > .cardHeader': {
      gap: '0.5rem',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',

      '& > .cardHeaderActions': {
        gap: '0.5rem',
        display: 'flex',
      },
    },

    '& > .cardContent': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',

      '& > .accidentNewsDate': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
      },
    },
  },
}));

export default CSDAccidentNewsCard;
