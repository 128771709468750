import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { prepareStationsTabs } from '@app/v2/shared/helpers';
import { CSDRevertTabs } from '@app/modules/kit-module/shared/ui';
import TrafficChartSettingsPanel from './TrafficTabSettingsPanel';
import TrafficTabContent from './TrafficTabContent';
import { StationsProps } from '../../../types';

const TrafficTab = ({
  stationId,
  activeStationId,
  activeStationID,
  listOfStationsIdOfActiveTab: trafficIds,
  setActiveStationID,
  title,
  infoSetup,
  close,
}: StationsProps) => {
  const { t } = useTranslation('dialogs', { keyPrefix: 'post' });

  const { stationTabs, startActiveId } = useMemo(
    () => ({
      stationTabs: prepareStationsTabs({ ids: trafficIds, prefixTabLabel: t('station') }),
      startActiveId: activeStationId || trafficIds[0],
    }),
    [trafficIds, t, activeStationId],
  );

  useEffect(() => {
    setActiveStationID(startActiveId);
  }, [startActiveId, setActiveStationID]);

  return (
    <>
      <TrafficChartSettingsPanel close={close} />

      {stationTabs.length > 1 && (
        <CSDRevertTabs
          tabs={stationTabs}
          activeTabId={activeStationID}
          onChange={id => {
            setActiveStationID(Number(id));
          }}
        />
      )}

      <TrafficTabContent title={title} infoSetup={infoSetup} activeStationId={activeStationID} startActiveId={startActiveId} />
    </>
  );
};

export default TrafficTab;
