import { PlaceActiveAlert } from '@app/v2/shared/types';
import { FiltersKeys } from '@app/v2/shared/enums';
import { alertStatusApiFormats } from '@app/v2/shared/constants';

export default class DataSourceUtils<Station extends Common.BaseStation> {
  public mergeStationsAndActiveAlerts(stations: Station[], alerts: PlaceActiveAlert[]) {
    const implementActiveAlerts = (station: Station): Station => {
      const alertElement = alerts.find(({ placeId }) => station?.place?.id === placeId);

      return {
        ...station,
        activeAlerts: alertElement?.activeAlerts ?? [],
      };
    };

    return stations.map(implementActiveAlerts);
  }

  public normalizeFiltersToApi(filters: Filters.Values): Filters.Values {
    return {
      ...filters,
      [FiltersKeys.AlertStatus]: filters?.alertStatus?.map(value => alertStatusApiFormats[value]),
    };
  }
}
