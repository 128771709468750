import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Icon, Tab, Tabs, TabsProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { usePrepareContentFromMUIIcons } from '@app/v2/shared/hooks';
import { FooterTabs } from '@app/v2/shared/enums';
import { TabItem } from '../../types/TabItem';

interface OpenCloseSettings {
  isTabsOpen: boolean;
  position: { top?: string; right?: string; bottom?: string; left?: string };
  size: { width: string; height: string };
}

type Props = {
  tabs: Omit<TabItem, 'Panel'>[];
  currentValue: FooterTabs | false;
  onTabChange: (e: React.SyntheticEvent, newValue: FooterTabs) => void;
  onTabSecondClick: (value: FooterTabs) => () => void;
  indicationIconsSettings?: OpenCloseSettings;
  isOnlyIconLabel: boolean;
} & WithTranslation<'footer', 'tabs'>;

const TabBar = ({ tabs = [], currentValue, onTabChange, onTabSecondClick, indicationIconsSettings, isOnlyIconLabel, t }: Props) => {
  const iconsContent = usePrepareContentFromMUIIcons([
    { name: 'Open', Icon: KeyboardArrowDown },
    { name: 'Close', Icon: KeyboardArrowUp },
  ]);

  return (
    <StyledTabs value={currentValue} onChange={onTabChange} aria-label="custom tabs example">
      {tabs.map(({ name, icon }: Omit<TabItem, 'Panel'>) => (
        <Tab
          key={name}
          id={`footer-tab-id-${name}`}
          label={!isOnlyIconLabel ? t(name) : ''}
          value={name}
          icon={<Icon>{icon}</Icon>}
          sx={{
            boxShadow: '0rem -0.125rem 0.313rem 0rem #34343433',
            clipPath: 'inset(-0.313rem -0.313rem 0rem -0.313rem)',
            marginTop: '0.313rem',
            maxWidth: 'auto',
            ...(!isOnlyIconLabel
              ? { paddingRight: '1.75rem', paddingLeft: '1.75rem', minWidth: 195 }
              : { paddingLeft: 0, paddingRight: '1rem', minWidth: 60 }),

            '&.MuiTab-root.Mui-selected::after': {
              position: 'absolute',
              content: indicationIconsSettings?.isTabsOpen ? iconsContent.Open : iconsContent.Close,
              ...indicationIconsSettings?.position,
              ...indicationIconsSettings?.size,
            },
          }}
          onClick={onTabSecondClick(name)}
        />
      ))}
    </StyledTabs>
  );
};

const StyledTabs = styled(Tabs)<TabsProps>({
  position: 'relative',
  '& .MuiTabs-flexContainer	': {
    position: 'fixed',
    marginTop: '-34px',
  },
});

export default withTranslation('footer', { keyPrefix: 'tabs' })(TabBar);
