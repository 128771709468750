import React from 'react';
import { Stack, MenuItem, Box } from '@mui/material';
import { CSDSelect, CSDSelectsStandard } from '@app/modules/kit-module/shared/ui';
import { select, selectWithError, disabledSelect, standardSelect, standardSelectWithIcon } from './example';
import CSDKitDocBlock from '../../../shared/ui/CSDKitDocBlock/CSDKitDocBlock';

const selectItems = [
  { label: 'Ten', value: 10 },
  { label: 'Twenty', value: 20 },
  { label: 'Thirty', value: 30 },
];

const CSDSelects = () => {
  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="Select" codeExample={select}>
        <Box sx={{ width: '300px' }}>
          <CSDSelect label="Вопрос" items={selectItems} />
        </Box>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Select with error" codeExample={selectWithError}>
        <Box sx={{ width: '300px' }}>
          <CSDSelect label="Вопрос" error items={selectItems} />
        </Box>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Select disabled" codeExample={disabledSelect}>
        <Box sx={{ width: '300px' }}>
          <CSDSelect label="Вопрос" disabled items={selectItems} />
        </Box>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Select standard" codeExample={standardSelect}>
        <Box sx={{ width: '300px' }}>
          <CSDSelectsStandard label="Вопрос">
            {selectItems.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </CSDSelectsStandard>
        </Box>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Select standard with icon" codeExample={standardSelectWithIcon}>
        <Box sx={{ width: '300px' }}>
          <CSDSelectsStandard label="Вопрос" icon="search">
            {selectItems.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </CSDSelectsStandard>
        </Box>
      </CSDKitDocBlock>
    </Stack>
  );
};

export default CSDSelects;
