import React from 'react';
import WhiteNoise from '@app/assets/white_noise.png';
import {
  LINEAR_GRADIENT_BLACK_ID,
  LINEAR_GRADIENT_GREEN_ID,
  IMG_WHITE_NOISE_PATTERN_ID,
  LINEAR_GRADIENT_BLUE_ID,
  ARROW_ELEMENT_ID,
} from '../constants';

const SvgDefs = () => {
  return (
    <defs>
      <marker
        id={ARROW_ELEMENT_ID}
        viewBox="0 0 10 10"
        refX="4"
        refY="5"
        markerUnits="strokeWidth"
        markerWidth="10px"
        markerHeight="10px"
        orient="auto"
      >
        <path d="M 2 3 L 7 5 L 2 7 z" fill="#fff" stroke="#fff" />
      </marker>
      <linearGradient
        xmlns="http://www.w3.org/2000/svg"
        id={LINEAR_GRADIENT_GREEN_ID}
        x1="364.052"
        y1="490.197"
        x2="333.635"
        y2="-177.53"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#025F00" />
        <stop offset="1" stopColor="#059102" />
      </linearGradient>
      <linearGradient xmlns="http://www.w3.org/2000/svg" id={LINEAR_GRADIENT_BLUE_ID} gradientUnits="objectBoundingBox">
        <stop stopColor="#072F9C" />
        <stop offset="1" stopColor="#0F79DF" />
      </linearGradient>
      <linearGradient
        xmlns="http://www.w3.org/2000/svg"
        id={LINEAR_GRADIENT_BLACK_ID}
        x1="347.775"
        y1="-11.1096"
        x2="478.593"
        y2="355.943"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3F3F3F" />
        <stop offset="1" />
      </linearGradient>
      <pattern id={IMG_WHITE_NOISE_PATTERN_ID} patternUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#imgWhiteNoise" />
      </pattern>
      <image xmlns="http://www.w3.org/2000/svg" id="imgWhiteNoise" width="1920" height="1080" xlinkHref={WhiteNoise} />
    </defs>
  );
};

export default SvgDefs;
