import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

const NoChartData = () => {
  const { t: chartsT } = useTranslation('charts');

  return (
    <Box height="calc(100%)" display="flex" justifyContent="center" alignItems="center">
      <Typography variant="h6">{chartsT('noData')}</Typography>
    </Box>
  );
};

export default NoChartData;
