import React from 'react';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { CardContent, CardHeader, CardProps, Icon, Link, LinkProps, Stack, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { removeConfirmKeys } from '@app/core/constants/auth';
import { REFRESH_TOKEN_KEY } from '@app/clients/apollo/tokenStorage';
import icons from '@app/assets/iconFont';
import { useActions, useAppDispatch, useChat, useLocalStorage } from '@app/v2/shared/hooks';
import { PublicPath } from '@app/v2/shared/enums';

const RecoveryMessage = ({ t }: I18N.TranslationFunction<'auth'>) => {
  const dispatch = useAppDispatch();
  const { changeConfirmStatus } = useActions();
  const { isChatOpen, handleChangeChatStatus } = useChat();

  const { push } = useHistory();
  const { removeItems } = useLocalStorage();

  const handleSupportLinkClick = () => {
    handleChangeChatStatus(!isChatOpen);
  };

  const goToLogin = () => {
    dispatch(changeConfirmStatus({ status: false }));
    removeItems([...removeConfirmKeys, REFRESH_TOKEN_KEY]);
    push(PublicPath.Auth);
  };

  return (
    <>
      <StyledCardHeader
        title={
          <Stack direction="row" alignItems="center" justifyContent="flex-start" gap="1.75rem" width="100%">
            <StyledLink onClick={goToLogin}>
              <Icon
                sx={{
                  fontSize: '1.25rem',
                  color: 'black',
                }}
              >
                {icons.leftArrow}
              </Icon>
            </StyledLink>
          </Stack>
        }
      />
      <StyledCardContent>
        <Typography>
          {t('confirmation.recovery.messagePart1')}
          <br />
          <Typography>
            {t('confirmation.recovery.messagePart2')}
            <Typography
              component="span"
              fontSize="0.9rem"
              lineHeight="1.5rem"
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleSupportLinkClick}
            >
              {t('login.cardContent.techSupportLink')}
            </Typography>
            <br />
            {t('confirmation.recovery.messagePart3')}
          </Typography>
        </Typography>
      </StyledCardContent>
    </>
  );
};

const StyledCardHeader = styled(CardHeader)<CardProps>({
  '&.MuiCardHeader-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 0.313rem 0.313rem rgba(52, 52, 52, 0.2)',
    width: '100%',
    padding: '1rem',
    margin: 0,
  },
});

const StyledCardContent = styled(CardContent)<CardProps>({
  '&.MuiCardContent-root': {
    margin: '1.5rem',
    textAlign: 'center',

    '& .MuiTypography-root': {
      fontSize: '0.9rem',
      lineHeight: '1.5rem',
    },
  },
});

const StyledLink = styled(Link)<LinkProps>({
  cursor: 'pointer',
  color: '#000000',
});

export default withTranslation('auth')(RecoveryMessage);
