import React, { ReactNode, useMemo, useRef } from 'react';
import { MenuItem, Select as MUISelect, SelectProps, Typography, Box, FormHelperText, Tooltip } from '@mui/material';
import isNil from 'lodash/isNil';
import { DataTestIds } from '@app/v2/shared/enums';
import { EMPTY_STRING } from '@app/v2/shared/constants';
import { i18n } from '@app/v2/shared/localization';

interface Props<ItemsType> {
  label?: ReactNode;
  items?: ItemsType[];
  dataTestId?: DataTestIds;
  withEmptyValue?: boolean;
  withTooltips?: boolean;
  handleChange?: (value: string) => void;
  disabledItem?: (item: ItemsType) => boolean;
  helperText?: string;
  placeholder?: string;
  onlyFieldDisabled?: boolean;
}

const CSDSelect = <ItemsType extends Common.SelectorValue = Common.SelectorValue>({
  label,
  items,
  handleChange = () => {},
  defaultValue,
  dataTestId,
  disabledItem,
  withEmptyValue = false,
  withTooltips = false,
  helperText = EMPTY_STRING,
  placeholder,
  onlyFieldDisabled,
  ...selectProps
}: Props<ItemsType> & SelectProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const menuItems = useMemo(
    () =>
      (withEmptyValue ? [...items, { value: EMPTY_STRING, label: i18n.t('common:select.notSelected') } as ItemsType] : items).map(item => (
        <MenuItem key={item.value} value={item.value} disabled={disabledItem?.(item)}>
          {withTooltips ? (
            <Tooltip title={item.label}>
              <span
                style={{
                  display: 'block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {item.label}
              </span>
            </Tooltip>
          ) : (
            item.label
          )}
        </MenuItem>
      )),
    [disabledItem, items, withEmptyValue, withTooltips],
  );

  const placeholderMenuItem = () => (
    <MenuItem disabled value={null} sx={{ padding: '4px 0' }}>
      {placeholder}
    </MenuItem>
  );

  return (
    <Box ref={ref} sx={{ width: '100%', display: 'flex', flexDirection: 'column' }} data-testud={dataTestId}>
      {!!label && (
        <Typography
          component="span"
          sx={{
            fontSize: '0.875rem',
            color: ({ palette }) => selectProps?.disabled && palette.text.t3,
            marginBottom: ({ spacing }) => spacing(1),
          }}
          variant="subtitle2"
        >
          {label}
        </Typography>
      )}

      <MUISelect
        displayEmpty
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: '30rem',
              '& .MuiList-root': {
                maxWidth: selectProps?.style?.width ?? ref.current?.clientWidth ?? '100%',
                '& li.MuiButtonBase-root.MuiMenuItem-root': {},
              },
            },
          },
        }}
        onChange={event => {
          event.stopPropagation();
          handleChange(event.target.value as string);
        }}
        renderValue={isNil(selectProps.value) && placeholder ? placeholderMenuItem : undefined}
        disabled={selectProps.disabled || onlyFieldDisabled}
        sx={{
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: ({ palette: { text } }) => (onlyFieldDisabled ? text.primary : text.secondary),
          },
        }}
        {...selectProps}
      >
        {menuItems}
      </MUISelect>

      {helperText && selectProps?.error && <FormHelperText error={selectProps?.error}>{helperText}</FormHelperText>}
    </Box>
  );
};

export default CSDSelect;
