import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import groupBy from 'lodash/groupBy';
import { Box, Icon, IconButton, Paper, Skeleton, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import { CSDTPIDevice } from '@app/v2/entities';
import vmsNewQueries from '@app/clients/apollo/requests/queries/vmsNew';
import { getHumanizedAddressWithoutUnit } from '@app/v2/shared/utils';
import {
  filterStationsByType,
  getDirectionFromGroup,
  getFirstStationFromGroup,
  isFunction,
  isUnknownDirection,
  isValidDataForGrouping,
  VMSStationsValidator,
} from '@app/v2/shared/helpers';
import { CSDChangeTemplateStatus, CSDSetManualMode } from '@app/v2/features';
import { DataTestIds, VMSDeviceDirections, VMSDeviceTypeOfSupport, VMSTemplatesTypes } from '@app/v2/shared/enums';
import { showErrorWithoutCodes } from '@app/core/utils/notifications';
import icons from '@app/assets/iconFont';
import CSDMapTPIHeader from './CSDMapTpiHeader';
import CSDMapTPIContent from './CSDMapTPIContent';
import CSDStationsSwitcher from './CSDStationsSwitcher';

interface Props extends I18N.TranslationFunction<'common', 'errors'> {
  modalData: { placeId: number; type: VMSTemplatesTypes; organizationName: string };
  onClose: Common.VoidCallBack;
}

const CSDMapTPIModal = ({ modalData, onClose, t }: Props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(true);
  const [currentDirection, setCurrentDirection] = useState<VMSDeviceDirections>(VMSDeviceDirections.Unknown);
  const [placeData, setPlaceData] = useState<Partial<Record<VMSDeviceDirections, Scoreboards.VMSStation[]>>>({});
  const [vmsStation, setVMSStation] = useState<Scoreboards.VMSStation>(null);

  const { refetch } = useQuery<Scoreboards.VMSStationResponse, Scoreboards.VMSStationVariables>(vmsNewQueries.stations, {
    skip: true,
  });

  const resetState = useCallback(() => {
    setPlaceData({});
    setVMSStation(null);
    setCurrentDirection(VMSDeviceDirections.Unknown);
  }, []);

  const fetchPlaceData = useCallback(() => {
    if (!isFunction(refetch)) return;

    refetch({ placeIds: [modalData.placeId] })
      .then(({ data }) => {
        if (!data?.vmsNew?.stations?.data?.length) return [];
        return VMSStationsValidator(data.vmsNew.stations.data);
      })
      .then(vmsStationsByPlace => {
        if (!vmsStationsByPlace.length) {
          resetState();
          return;
        }

        if (isValidDataForGrouping(vmsStationsByPlace)) {
          const groupedStationsByDirections: Record<VMSDeviceDirections, Scoreboards.VMSStation[]> = groupBy(vmsStationsByPlace, 'direction');

          setPlaceData(groupedStationsByDirections);
          setVMSStation(getFirstStationFromGroup(groupedStationsByDirections));
          setCurrentDirection(getDirectionFromGroup(groupedStationsByDirections));
        } else {
          setPlaceData({ [VMSDeviceDirections.Unknown]: filterStationsByType(vmsStationsByPlace, modalData.type) });
          setVMSStation(filterStationsByType(vmsStationsByPlace, modalData.type)?.[0]);
          setCurrentDirection(VMSDeviceDirections.Unknown);
        }
      })
      .catch(() => showErrorWithoutCodes(`${t('NOT_FOUND')}`))
      .finally(() => setLoading(false));
  }, [modalData, refetch, resetState, t]);

  useEffect(() => setVMSStation(placeData?.[currentDirection]?.[0]), [currentDirection, placeData]);
  useEffect(() => fetchPlaceData(), [fetchPlaceData]);
  useEffect(() => () => onClose(), [onClose]);

  const handleChangeDirection = useCallback(
    (nextDirection: VMSDeviceDirections) => {
      if (!placeData?.[nextDirection]?.[0]) return;
      setCurrentDirection(nextDirection);
    },
    [placeData],
  );
  return (
    <Paper className={classes.paper} data-testid={DataTestIds.MapTpiModal}>
      {loading && <Skeleton variant="rectangular" className={classes.paperSkeleton} />}

      {!loading && !Object.values(placeData).filter(data => !!data.length).length && (
        <Box className={classes.noData}>
          <IconButton className="noDataClose" onClick={onClose}>
            <Icon>{icons.close}</Icon>
          </IconButton>
          <Typography variant="h6">{t('notDataForView')}</Typography>
        </Box>
      )}

      {!!Object.entries(placeData).length && vmsStation && (
        <>
          <CSDMapTPIHeader
            onClose={onClose}
            loading={loading}
            title={vmsStation?.place?.road?.title}
            organizationTitle={modalData.organizationName}
            coords={getHumanizedAddressWithoutUnit(vmsStation?.place?.address, vmsStation?.place?.meters)}
            stationsSwitcher={
              <CSDStationsSwitcher
                stations={placeData?.[currentDirection]}
                activeId={vmsStation.id}
                setActive={setVMSStation}
                setDirection={handleChangeDirection}
                currentDirection={currentDirection}
              />
            }
          />

          <Box className={classes.deviceWrapper}>
            <CSDTPIDevice
              isChangeable
              fetchStation={fetchPlaceData}
              activeId={vmsStation.id}
              currentDirection={currentDirection}
              stations={isUnknownDirection(currentDirection) ? [vmsStation] : placeData?.[currentDirection]}
              descriptions={Object.entries(vmsStation?.place?.road?.directionTitles ?? {}).filter(([direction]) => placeData?.[direction])}
              deviceType={vmsStation?.position ?? VMSDeviceTypeOfSupport.Center}
              updateTemplateStatus={CSDChangeTemplateStatus}
              setDirection={handleChangeDirection}
              setActive={setVMSStation}
              setManualMode={CSDSetManualMode}
            />
          </Box>

          <CSDMapTPIContent stationId={vmsStation.id} device={vmsStation.device} fetchStation={fetchPlaceData} />
        </>
      )}
    </Paper>
  );
};

const useStyles = makeStyles({
  paper: {
    zIndex: '401',
    left: '2rem',
    top: '10rem',
    gap: '1.5rem',
    display: 'flex',
    cursor: 'default',
    position: 'absolute',
    flexDirection: 'column',
    border: `1px solid black`,
    maxWidth: '40.75rem !important',
    minWidth: '40.75rem !important',
    padding: '2rem 0 1rem 0 !important',

    '&.MuiPaper-root': {
      borderRadius: '1rem',
      backgroundColor: 'white',
    },
  },
  paperSkeleton: {
    margin: '0 2rem',
    height: '39.75rem',
    marginBottom: '1rem',
    minHeight: '39.75rem',
    borderRadius: '0.5rem',
    minWidth: '36.75rem',
  },
  deviceWrapper: {
    padding: '0 2rem',
    alignSelf: 'center',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noData: {
    position: 'relative',
    display: 'flex',
    height: '39.75rem',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',

    '& .noDataClose': {
      position: 'absolute',
      top: '-0.5rem',
      right: '1.5rem',
    },
  },
});

export default withTranslation('common', { keyPrefix: 'errors' })(CSDMapTPIModal);
