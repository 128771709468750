import React from 'react';
import { withTranslation } from 'react-i18next';
import { TableCell, Typography } from '@mui/material';

interface Props extends I18N.TranslationFunction<'common'> {
  colSpan: number;
}

const CSDTableEmptyCell = ({ colSpan, t }: Props) => {
  return (
    <TableCell sx={{ height: '2.5rem' }} colSpan={colSpan} align="center">
      <Typography>{t('errors.NOT_FOUND')}</Typography>
    </TableCell>
  );
};

export default withTranslation('common')(CSDTableEmptyCell);
