import React, { useMemo, memo } from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, Stack, StackProps, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import icons from '@app/assets/iconFont';
import { BASE_COLUMN_WIDTH } from '@app/v2/shared/constants';
import { ActiveAlerts } from '@app/v2/shared/enums';
import { activeAlertStatusMapper } from '@app/v2/shared/helpers';
import CSDTableCell, { HighlightedCell } from '../CSDTableCells/CSDTableCell';
import CSDAttentionSvgIcon from '../CSDAttentionSvgIcon/CSDAttentionSvgIcon';

const StyledStack = styled(Stack)<StackProps>({
  flexDirection: 'row',
  gap: '0.5rem',
  alignItems: 'center',
  justifyContent: 'center',
});

type Props = {
  notifications: ActiveAlerts[] | null;
  isUnpaid?: boolean;
} & HighlightedCell &
  I18N.TranslationFunction<'constants'>;

const CSDNotificationTableCell = ({ notifications, rowIndex, columnIndex, isUnpaid = false, t }: Props) => {
  const content = useMemo(() => {
    switch (true) {
      case isUnpaid:
        return (
          <Tooltip title={t('alert.unpaid')}>
            <CSDAttentionSvgIcon />
          </Tooltip>
        );

      case !notifications:
        return <Icon>{icons.close}</Icon>;

      case Array.isArray(notifications) && !notifications.length:
        return <Icon color="success">{icons.done}</Icon>;

      default:
        return notifications.map(notification => (
          <Tooltip key={notification} title={t(activeAlertStatusMapper[notification].tooltip)}>
            <Icon color="error" key={notification}>
              {activeAlertStatusMapper[notification].iconName}
            </Icon>
          </Tooltip>
        ));
    }
  }, [isUnpaid, notifications, t]);

  return (
    <CSDTableCell rowIndex={rowIndex} columnIndex={columnIndex} align="center" sx={{ width: BASE_COLUMN_WIDTH }}>
      <StyledStack>{content}</StyledStack>
    </CSDTableCell>
  );
};

export default withTranslation('constants')(memo(CSDNotificationTableCell));
