import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactComponent as Cloudy } from '@app/assets/map/roadObjects/markers/cloudy_precipitations.svg';

const CSDMapSkeleton = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <Box className={classes.main}>
      <Box className={classes.animationContainer}>
        <Box className={classes.container}>
          <Cloudy className={classes.cloudy} />
        </Box>
        <Typography sx={{ marginTop: '1rem' }} variant="h6">
          {t('notificationMessages.loading')}
        </Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    background: 'white',
  },
  animationContainer: {
    textAlign: 'center',
    width: 400,
    height: 200,
  },
  container: {
    position: 'relative',
    width: 400,
    height: 70,
    overflow: 'hidden',
  },
  cloudy: {
    transform: 'matrix(-1, 0, 0, 1, 0, 0)',
    width: '6rem',
    height: '6rem',
    position: 'absolute',
    left: '-60px',
    top: 'calc(50% - 45px)',
    fill: 'black',
    '& > circle': {
      fill: 'transparent',
      stroke: 'transparent',
    },
    animation: '$fly 3s linear infinite',
  },
  '@keyframes fly': {
    '0%': {
      left: '-60px',
    },
    '100%': {
      left: '100%',
    },
  },
}));

export default CSDMapSkeleton;
