export enum PlayerStatus {
  PLAY = 'PLAY',
  STOP = 'STOP',
  PAUSE = 'PAUSE',
}

export enum PlayerTimeRangeActions {
  HOUR = 'hour',
  TWO_HOUR = 'two_hour',
  DAY = 'day',
}
