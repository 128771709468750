import { useEffect } from 'react';
import { LocalStorageKeys } from '@app/v2/shared/enums';
import useLocalStorage from './useLocalStorage';
import useSystem from './useSystem';

export default function useTemporaryRollUpHeader() {
  const { setItem, getItem, removeItem } = useLocalStorage();
  const { isHeaderRollUp, handleChangeHeaderStatus } = useSystem();

  useEffect(() => {
    if (!isHeaderRollUp) return;
    setItem(LocalStorageKeys.ReportsHeaderStatus, isHeaderRollUp);

    handleChangeHeaderStatus(false);
  }, [handleChangeHeaderStatus, isHeaderRollUp, setItem]);

  useEffect(
    () => () => {
      const prevValueOfHeader = JSON.parse(getItem(LocalStorageKeys.ReportsHeaderStatus));

      handleChangeHeaderStatus(prevValueOfHeader ?? false);
      removeItem(LocalStorageKeys.ReportsHeaderStatus);
    },
    [getItem, handleChangeHeaderStatus, removeItem],
  );
}
