import { useCallback, useContext, useEffect, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import WeatherLocatorsContext from '@app/modules/map-module/context/WeatherLocators/WeatherLocatorsContext';
import { WeatherLayers } from '@app/v2/shared/enums/WeatherLayers';
import { useAppSelector } from '@app/v2/shared/hooks';

export default function useWeatherLocatorContext(id?: string) {
  const {
    activeLocatorsIds,
    contextValue,
    globalService,
    addLayer,
    changeDate,
    changeTimesRange,
    removeLayer,
    addService,
    removeService,
    changeIsLayerErrorStatus,
    clearLocators,
  } = useContext(WeatherLocatorsContext);

  const locator = useAppSelector(state => state.map.locators[id], isEqual);

  const service = useMemo(() => contextValue?.[id]?.service, [contextValue, id]);

  const changeLayer = useCallback(
    (layer: '' | WeatherLayers) => {
      if (layer) {
        if (!service) {
          addService(id, locator);
        } else {
          service.updateCurrentTime();
        }
        addLayer(id, layer);
      } else {
        removeLayer(id);

        if (service) {
          service.destroy();
          removeService(id);
        }
      }
    },
    [addLayer, addService, id, locator, removeLayer, removeService, service],
  );

  const handleLayerError = useCallback(
    (status: boolean): void => {
      changeIsLayerErrorStatus(id, status);
    },
    [changeIsLayerErrorStatus, id],
  );

  useEffect(() => {
    if (service) {
      service.updateControl();
    }
  }, [service]);

  return {
    contextValue: id ? contextValue[id] : null,
    activeLocatorsIds,
    globalService,
    allContext: contextValue,
    changeLayer,
    changeDate,
    changeTimesRange,
    handleLayerError,
    changeIsLayerErrorStatus,
    clearLocators,
  };
}
