export const simpleCard = `<Card>
  <CardHeader title="Title" />
    <CardContent>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquam at aut 
      commodi, consectetur cum dignissimos dolore dolores dolorum, eaque eius esse est,
      eveniet ex explicabo labore minus quo sunt?
    </CardContent>
  <CardActions>
    <Button>
      ОК
    </Button>
  </CardActions>
</Card>`;

export const cardWithAlert = `<Card>
  <CardHeader title="Title" />
    <CSDAlert severity="success" contentAlign="center">
      Успех
    </CSDAlert>
    <CardContent>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquam at aut 
      commodi, consectetur cum dignissimos dolore dolores dolorum, eaque eius esse est,
      eveniet ex explicabo labore minus quo sunt?
    </CardContent>
  <CardActions>
    <Button>
      ОК
    </Button>
  </CardActions>
</Card>`;

export const cardWithInputs = `<Card>
  <CardHeader title="Title" />
  <CardContent>
    <CSDInput label="Lorem ipsum" />
    <CSDInput label="Lorem ipsum" />
  </CardContent>
  <CardActions>
    <Button>ОК</Button>
  </CardActions>
</Card>`;
