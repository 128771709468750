import React, { useMemo } from 'react';
import { Tabs, Tab, Icon } from '@mui/material';
import { UsersAccess } from '@app/v2/shared/enums';

const TabsBar = <T extends { id: number | string; view?: UsersAccess[]; icon?: string }>(props: {
  value: T;
  items: T[];
  orientation?: 'horizontal' | 'vertical';
  indicatorColor?: 'secondary' | 'primary';
  textColor?: 'secondary' | 'primary' | 'inherit';
  variant?: 'standard' | 'scrollable' | 'fullWidth';
  scrollButtons?: 'auto';
  classesTabs?: Partial<any>;
  classesTab?: Partial<any>;
  tabProps: (item: T) => { [key: string]: any };
  onChange?: (item: T) => void;
  onChangeIndex?: (index: number) => void;
}) => {
  const {
    items,
    value,
    orientation = 'horizontal',
    indicatorColor = 'primary',
    textColor = 'primary',
    variant = 'scrollable',
    scrollButtons = 'auto',
    tabProps,
    classesTab,
    classesTabs,
    onChangeIndex: onChangeIndexActiveTab = () => {},
    onChange: onChangeActiveTab = () => {},
  } = props;

  const currentTab = useMemo(() => {
    if (value) {
      const newCurrentTab = items.findIndex(({ id }) => value.id === id);
      return newCurrentTab !== -1 ? newCurrentTab : 0;
    }
    return 0;
  }, [value, items]);

  const onChange = (index: number) => {
    onChangeActiveTab(items[index]);
    onChangeIndexActiveTab(index);
  };

  return (
    <Tabs
      value={currentTab}
      orientation={orientation}
      indicatorColor={indicatorColor}
      textColor={textColor}
      variant={variant}
      sx={classesTabs}
      scrollButtons={scrollButtons}
      onChange={(e, v) => onChange(v)}
    >
      {items.map((item, index) => (
        <Tab sx={classesTab} icon={<Icon>{item?.icon}</Icon>} key={item.id} value={index} {...tabProps(item)} />
      ))}
    </Tabs>
  );
};

export default TabsBar;
