export enum KitComponents {
  Default = 'Default',
  Inputs = 'INPUTS',
  Autocompletes = 'Autocompletes',
  Selects = 'Selects',
  TextFields = 'TextFields',
  Buttons = 'Buttons',
  Checkboxes = 'Checkboxes',
  Sliders = 'Sliders',
  Switches = 'Switches',
  Avatars = 'Avatars',
  Badges = 'Badges',
  Chips = 'Chips',
  Dividers = 'Dividers',
  Tooltips = 'Tooltips',
  Tables = 'Tables',
  Typographies = 'Typographies',
  DataDisplay = 'DATA DISPLAY',
  Feedback = 'FEEDBACK',
  Alerts = 'Alerts',
  Snackbars = 'Snackbars',
  Dialogs = 'Dialogs',
  Surfaces = 'SURFACES',
  Cards = 'Card',
  AppBar = 'AppBar',
  Navigation = 'NAVIGATION',
  Tabs = 'Tabs',
  Other = 'OTHER',
  NotFromMUIDocs = 'NotFromMUIDocs',
  Icons = 'Icons',
  DatePickersMUI = 'DatePickersMUI',
  DatePickersAntd = 'DatePickersAntd',
  PieCharts = 'PieCharts',
  DynamicRoadInterface = 'DynamicRoadInterface',
}

export enum KitRoutes {
  default = '/kit',
  inputs = '/inputs',
  autocompletes = '/kit/autocompletes',
  selects = '/kit/selects',
  textFields = '/kit/textFields',
  buttons = '/kit/buttons',
  checkboxes = '/kit/checkboxes',
  sliders = '/kit/sliders',
  switches = '/kit/switches',
  dataDisplay = '/dataDisplay',
  avatars = '/kit/avatars',
  badges = '/kit/badges',
  chips = '/kit/chips',
  dividers = '/kit/dividers',
  tables = '/kit/tables',
  tooltips = '/kit/tooltips',
  typographies = '/kit/typographies',
  feedback = '/feedback',
  alerts = '/kit/alerts',
  snackbar = '/kit/snackbar',
  dialogs = '/kit/dialogs',
  surfaces = '/surfaces',
  cards = '/kit/cards',
  appBar = '/kit/appBar',
  navigation = '/navigation',
  tabs = '/kit/tabs',
  other = '/other',
  notFromMUIDocs = '/kit/notFromMUIDocs',
  icons = '/kit/icons',
  datePickersMUI = '/kit/datePickersMUI',
  datePickersAntd = '/kit/datePickersAntd',
  pieCharts = '/kit/pieCharts',
  dynamicRoadInterface = '/kit/dynamicRoadInterface',
}
