import React from 'react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ALERT_NOTIFICATION_RANK } from '../constants';

interface Props {
  notificationItem: Notifications.BulletinModel;
}

const CSDNotificationBulletinCard = ({ notificationItem }: Props) => {
  const classes = useStyles({ rank: notificationItem.bulletin });

  return (
    <Box className={classes.notificationListItem}>
      {notificationItem.message.split('\n').map(str => (
        <p>{str}</p>
      ))}
    </Box>
  );
};

const useStyles = makeStyles<Theme, { rank: number }>(theme => ({
  notificationListItem: {
    borderWidth: '5px 1px 1px 1px',
    borderColor: ({ rank }) => (rank === ALERT_NOTIFICATION_RANK ? theme.palette.technicalColors.red : theme.palette.technicalColors.yellow),
    borderStyle: 'solid',
    borderRadius: '0.5rem',
    padding: '1rem 1.5rem',
    width: 'calc(630px - 3rem)',
    margin: 'auto',
    backgroundColor: theme.palette.background.first,
  },
}));

export default CSDNotificationBulletinCard;
