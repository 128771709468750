import { MapZooms } from '@app/v2/shared/enums';
import { MAX_MAP_ZOOM, MIN_MAP_ZOOM } from '@app/core/constants/map';

export default function mapZoomMapper({ zoom, firstLimit, secondLimit }: { zoom: number; firstLimit: number; secondLimit: number }): MapZooms {
  switch (true) {
    case zoom <= firstLimit && firstLimit !== MIN_MAP_ZOOM:
      return MapZooms.Minimal;
    case zoom <= secondLimit:
      return MapZooms.Middle;
    case zoom <= MAX_MAP_ZOOM:
      return MapZooms.Maximum;
    default:
      return MapZooms.Minimal;
  }
}
