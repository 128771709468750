// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/loginModule_bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IG8g0nBoAnhGHRNd2tdg{position:relative;height:100vh;width:100%;background-color:#fafafa;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover}", "",{"version":3,"sources":["webpack://./src/modules/auth-module/containers/passwordRecoveryLink/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,UAAA,CACA,wBAAA,CACA,wDAAA,CACA,2BAAA,CACA,qBAAA","sourcesContent":[".authWrapper {\n  position: relative;\n  height: 100vh;\n  width: 100%;\n  background-color: #fafafa;\n  background-image: url('../../../../assets/loginModule_bg.png');\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authWrapper": "IG8g0nBoAnhGHRNd2tdg"
};
export default ___CSS_LOADER_EXPORT___;
