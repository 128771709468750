import React, { memo, useEffect, useCallback, useState, useMemo, useRef } from 'react';
import type { FixedSizeList } from 'react-window';
import { useQuery } from '@apollo/client';
import { styled } from '@mui/styles';
import { Box, BoxProps } from '@mui/material';
import { useVideoPost } from '@app/v2/shared/hooks';
import { formatAPI } from '@app/core/helpers/dateFormat';
import videoQueries from '@app/clients/apollo/requests/queries/video';
import { CSDMainSnapShot, CSDRoadWithCameras, CSDSnapShotsPanel, CSDVideoActionPanel } from '@app/v2/entities';
import { DATE_PERIOD } from '@app/v2/shared/constants';
import useNodeSize from '@app/core/hooks/useNodeSize';
import { prepareResponseData, calculatePartsSizes } from './helpers';
import { LAYOUT_FLEX_GAP, MINIMUM_ASPECT_RATIO } from './constants';
import DynamicLayoutManager from './DynamicLayoutManager';

interface Props extends Pick<Common.BaseRoad, 'directionTitles'> {
  stationId: number;
  animationIntervalValue?: number;
  roadLines?: Common.RoadPointLines;
  videoStations: Video.VideoPlaceStation[];
  directionTitles: Common.RoadDirectionTitles;
  setActiveStationID: (value: number | ((prev: number) => number)) => void;
}

const VideoTabImageContent = ({
  stationId,
  animationIntervalValue = DATE_PERIOD.SECOND,
  roadLines,
  videoStations,
  directionTitles,
  setActiveStationID,
}: Props) => {
  const {
    dateFrom,
    dateTo,
    snapShots,
    isAnimation,
    activeSnapShot,
    liveSnapShotURL,
    activeSnapShotIndex,
    stationProperties,
    handleSetSnapshot,
    handleSetProperties,
    handleSetActiveSnapshot,
    handleChangeLiveSnapShotURL,
    handleChangeActiveSnapshotIndex,
    handleToggleAnimation,
    handleResetVideoPostState,
  } = useVideoPost();

  const { loading } = useQuery<Video.VideoStationDetailsResponse, Video.VideoStationDetailsVariables>(videoQueries.videoStationDetails, {
    variables: {
      stationId,
      dateFrom: formatAPI(dateFrom.startOf('day')),
      dateTo: formatAPI(dateTo.endOf('day')),
    },
    onCompleted: data => {
      handleResetVideoPostState();

      if (!data?.videoStationDetails) return;

      const result = prepareResponseData(data);

      handleSetProperties({ stationProperties: { isLive: result?.isLive, isStream: result?.isStream, isWiper: result?.isWiper } });
      handleSetSnapshot({ snapShots: result?.snapShots });
    },
  });

  const tabContentRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<FixedSizeList | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { width, height } = useNodeSize(tabContentRef);
  const { height: currentSnapShotsPartHeight } = useNodeSize(containerRef);

  const [imgRatio, setImgRatio] = useState<number>(null);

  const currentUrl = useMemo(() => liveSnapShotURL ?? activeSnapShot?.url, [liveSnapShotURL, activeSnapShot?.url]);

  const isRatioLessThenMinimum = useMemo(() => !!currentUrl && imgRatio <= MINIMUM_ASPECT_RATIO, [imgRatio, currentUrl]);

  useEffect(() => {
    if (!isAnimation) return undefined;

    const intervalId = setInterval(() => {
      handleChangeActiveSnapshotIndex({ activeSnapShotIndex: activeSnapShotIndex + 1 });
    }, animationIntervalValue);

    return () => {
      clearInterval(intervalId);
      return undefined;
    };
  }, [isAnimation, activeSnapShotIndex, handleChangeActiveSnapshotIndex, animationIntervalValue]);

  useEffect(() => {
    if (activeSnapShotIndex) {
      listRef.current?.scrollToItem(activeSnapShotIndex);
    }
  }, [activeSnapShotIndex]);

  useEffect(() => {
    if (!currentUrl && !loading) {
      setImgRatio(null);
    }
  }, [loading, currentUrl]);

  useEffect(
    () => () => {
      handleToggleAnimation({ isAnimation: false });
      handleResetVideoPostState();
      setImgRatio(null);
    },
    [handleToggleAnimation, handleResetVideoPostState],
  );

  const handleChangeStation = useCallback(
    (nextStationId: number) => {
      if (stationId === nextStationId) return;
      setActiveStationID(nextStationId);
    },
    [stationId, setActiveStationID],
  );

  const { snapShotsPanelWidth, mainSnapShotHeight, camerasBlockHeight } = useMemo(
    () =>
      calculatePartsSizes({
        containerWidth: width,
        containerHeight: height,
        snapShotsPartHeight: currentSnapShotsPartHeight,
        imgRatio,
      }),
    [width, height, currentSnapShotsPartHeight, imgRatio],
  );

  return (
    <StyledContentBlock ref={tabContentRef} flexWrap={isRatioLessThenMinimum ? 'nowrap' : 'wrap'}>
      <CSDMainSnapShot
        loading={loading}
        stationId={stationId}
        clientHeight={mainSnapShotHeight}
        url={currentUrl}
        incidents={activeSnapShot?.incidents}
        setImageRatio={setImgRatio}
      />
      <DynamicLayoutManager isRatioLessThenMinimum={isRatioLessThenMinimum}>
        <StyledCamerasBlock height={camerasBlockHeight}>
          <CSDRoadWithCameras
            handleChangeStation={handleChangeStation}
            stationId={stationId}
            roadLines={roadLines}
            videoStations={videoStations}
            directionTitles={directionTitles}
          />

          <CSDVideoActionPanel
            stationId={stationId}
            stationProperties={stationProperties}
            isAnimation={isAnimation}
            loading={loading}
            imageUrl={currentUrl}
            onChangeLiveSnapShotURL={url => handleChangeLiveSnapShotURL({ liveSnapShotURL: url })}
          />
        </StyledCamerasBlock>
        <CSDSnapShotsPanel
          virtualization={{
            listRef,
            containerWidth: snapShotsPanelWidth,
          }}
          ref={containerRef}
          stationId={stationId}
          setOfSnapShots={snapShots}
          activeSnapShot={activeSnapShot}
          onChangeActiveSnapShot={snapShot => handleSetActiveSnapshot({ activeSnapShot: snapShot })}
          loading={loading}
        />
      </DynamicLayoutManager>
    </StyledContentBlock>
  );
};

export default memo(VideoTabImageContent);

const StyledContentBlock = styled(Box)<BoxProps>({
  flexGrow: 1,
  width: '100%',
  flexShrink: 1,
  display: 'flex',
  flexDirection: 'row',
  gap: LAYOUT_FLEX_GAP,
});

const StyledCamerasBlock = styled(Box)<BoxProps>({
  flexGrow: 1,
  display: 'flex',
  gap: LAYOUT_FLEX_GAP,
  flexDirection: 'column',
});
