import React, { memo, useMemo, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import { Box, Theme, Chip, Icon, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import WarningIcon from '@mui/icons-material/Warning';
import icons from '@app/assets/iconFont';
import { WeatherLayers, WeatherLayersTooltips } from '@app/v2/shared/enums/WeatherLayers';
import useWeatherLocatorContext from '@app/modules/map-module/context/WeatherLocators/useWeatherLocatorContext';
import useMapProfileContext from '@app/v2/features/mapProfiles/hooks/useMapProfileContext';

type Props = {
  locator: WeatherLocators.Locator;
  isActive: boolean;
  onClick?: (id: number) => void;
} & I18N.TranslationFunction<'common'>;

const getIconByLayer = (layer: keyof typeof WeatherLayers): string => {
  const iconsByLayer = {
    PRECIP: icons.rainfall,
    WEATHER_HAZARDS: icons.attention,
    SUM_PRECIP: icons.sumRain,
    WIND_DIRECTION: icons.windDirection,
    WIND_VELOCITY: icons.windSpeed,
    CLOUD_TOP: icons.windSpeed,
  };
  return iconsByLayer[layer];
};

const LocatorButtonWithActions = ({ locator, isActive, onClick, t }: Props) => {
  const classes = useStyles();
  const { contextValue, changeLayer } = useWeatherLocatorContext(String(locator.id));
  const { handleChangeLocators } = useMapProfileContext();
  const layer = useMemo(() => (contextValue?.layer ? contextValue.layer : WeatherLayers.NONE), [contextValue]);

  const setLayer = useCallback(
    (id: number, newLayerValue: WeatherLayers): void => {
      if (layer === newLayerValue) {
        handleChangeLocators(id, null);
        changeLayer('');
        return;
      }
      handleChangeLocators(id, newLayerValue);
      changeLayer(newLayerValue);
    },
    [changeLayer, layer, handleChangeLocators],
  );

  const getLocatorActions = useCallback(() => {
    if (contextValue?.isWarning) {
      return (
        <Tooltip title={t('notificationMessages.notGlobalModeLocator')}>
          <WarningIcon fontSize="small" color="warning" />
        </Tooltip>
      );
    }
    return locator.layers?.map(({ name }) => (
      <Tooltip title={t(WeatherLayersTooltips[name])} key={name}>
        <IconButton
          color="inherit"
          size="small"
          className={clsx({
            activeLocator: isActive,
            activeLayer: layer === name,
          })}
          disabled={contextValue?.isWarning}
          aria-label={WeatherLayers[name]}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setLayer(locator.id, name as WeatherLayers);
          }}
        >
          <Icon>{getIconByLayer(name as keyof typeof WeatherLayers)}</Icon>
        </IconButton>
      </Tooltip>
    ));
  }, [contextValue?.isWarning, isActive, setLayer, locator.layers, locator.id, layer, t]);

  return (
    <Chip
      key={locator.id}
      label={locator.title}
      variant="outlined"
      className={clsx({
        [classes.locatorDisabled]: contextValue?.isWarning,
        [classes.activetedLocator]: !isActive && locator.layers.find(({ name }) => name === layer),
      })}
      avatar={
        <>
          {contextValue?.layerError && layer && !contextValue?.isWarning && (
            <Tooltip sx={{ marginLeft: '0.5rem' }} title={t('notificationMessages.notServerConnection')}>
              <WarningIcon fontSize="small" color="error" />
            </Tooltip>
          )}
          {contextValue?.emptyTimes && layer && (
            <Tooltip sx={{ marginLeft: '0.5rem' }} title={t('notificationMessages.notTimeForLocator')}>
              <WarningIcon fontSize="small" color="error" />
            </Tooltip>
          )}
        </>
      }
      onClick={() => onClick(locator.id)}
      {...(isActive && {
        variant: 'filled',
        color: 'primary',
      })}
      onDelete={() => {}}
      deleteIcon={<Box className={classes.actionsContainer}>{getLocatorActions()}</Box>}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  locatorDisabled: {
    pointerEvents: 'none',
    '&.MuiChip-root': {
      borderColor: 'rgba(189, 189, 189, 0.38)',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '&:active': {
        boxShadow: 'unset !important',
      },
    },
    '& .MuiChip-deleteIcon': {
      pointerEvents: 'auto',
    },
    '& .MuiChip-label': {
      opacity: 0.38,
    },
  },
  activetedLocator: {
    '&.MuiChip-root': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiIconButton-root': {
      color: theme.palette.text.t6,
      opacity: 0.5,
      display: 'none',
      marginLeft: '0.5rem',
      '&.activeLayer': {
        display: 'inline-flex',
        color: theme.palette.primary.main,
        opacity: 1,
        '&:hover': {
          color: theme.palette.text.t1,
        },
        '&.activeLocator': {
          color: theme.palette.text.t6,
          opacity: 1,
        },
      },
      '&.activeLocator': {
        display: 'inline-flex',
      },
      '&:hover': {
        color: theme.palette.text.t6,
        opacity: 1,
      },
    },
  },
}));

export default memo(withTranslation('common')(LocatorButtonWithActions), isEqual);
