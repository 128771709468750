import React, { useEffect, useState } from 'react';
import { Snackbar, Alert, Icon, Button, Stack, Typography, Slide } from '@mui/material';
import { SnackbarProps } from '@mui/material/Snackbar/Snackbar';
import { SlideProps } from '@mui/material/Slide';
import { AlertColor } from '@mui/material/Alert/Alert';

type TransitionProps = Omit<SlideProps, 'direction'>;

type Props = {
  message: string;
  actionButtonTitle?: string;
  severity: AlertColor;
} & SnackbarProps;

const CSDSnackbar = ({ actionButtonTitle, message, severity, ...snackbarProps }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(false);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Snackbar
      open={isOpen}
      variant={severity}
      TransitionComponent={(props: TransitionProps) => <Slide {...props} direction="left" />}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      {...snackbarProps}
    >
      <Alert icon={<Icon>attention</Icon>} onClose={() => setIsOpen(false)} severity={severity} sx={{ width: '100%' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
          <Typography
            sx={{
              lineHeight: '19.2px',
              fontSize: ({ typography }) => typography.fontSize,
              fontWeight: ({ typography }) => typography.fontWeightBold,
              color: ({ palette: { text } }) => text.t6,
            }}
          >
            {message}
          </Typography>
          {actionButtonTitle && (
            <Button
              sx={{
                fontSize: '0.75rem',
                borderColor: 'transparent',
                color: ({ palette: { text } }) => text.t6,
                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                ':hover': {
                  borderColor: 'transparent',
                  backgroundColor: 'rgba(255, 255, 255, 0.4)',
                  boxShadow: 'none',
                },
              }}
              size="small"
              variant="outlined"
            >
              {actionButtonTitle}
            </Button>
          )}
        </Stack>
      </Alert>
    </Snackbar>
  );
};

export default CSDSnackbar;
