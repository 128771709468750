import React from 'react';
import { withTranslation } from 'react-i18next';
import { IconButton, Icon, Tooltip } from '@mui/material';
import { useMutation } from '@apollo/client';
import icons from '@app/assets/iconFont';
import accidentNewsMutations from '@app/clients/apollo/requests/mutations/accidentNews';
import { addFeatureActions } from '@app/v2/shared/utils';

interface Props extends Common.FeaturesActions, I18N.TranslationFunction<'accidentNews'> {
  newsId: number;
}

const CSDAddToFavorites = ({ newsId, t, ...featuresActions }: Props) => {
  const [onAdd, { loading }] = useMutation<Common.MutationResponse, AccidentNews.UpdateFavoritesStatusVariables>(
    accidentNewsMutations.addToFavorites,
  );

  const handleAddToFavorites = () => {
    addFeatureActions(onAdd({ variables: { newsId } }), featuresActions);
  };

  return (
    <Tooltip title={t('addToFavorites.btn.tooltip')}>
      <IconButton onClick={handleAddToFavorites} disabled={loading}>
        <Icon color="primary">{icons.starOutline}</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default withTranslation('accidentNews')(CSDAddToFavorites);
