import * as React from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import at from 'lodash/at';
import { Icon, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DATE_FORMAT } from '@app/v2/shared/constants';
import icons from '@app/assets/iconFont';
import { BillingDocument, BillingDocumentTableData } from '../../types';

type Props = {
  listingData: BillingDocument[];
} & I18N.TranslationFunction<'dialogs', 'accountSettings.tabs.documents.table'>;

type DocumentHeader = {
  value: string;
  label: string;
  align: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  minWidth?: number;
};

type DocumentStatus = {
  value: number;
  icon: string;
  color: string;
};

const DocumentsTable = ({ listingData, t }: Props) => {
  const headers: DocumentHeader[] = React.useMemo(
    () => [
      { value: 'status', label: t('status'), align: 'center', minWidth: 80 },
      { value: 'customer', label: t('customer'), align: 'left', minWidth: 160 },
      { value: 'executor', label: t('executor'), align: 'left', minWidth: 160 },
      { value: 'title', label: t('title'), align: 'left', minWidth: 120 },
      { value: 'dueDebtDate', label: t('dueDebtDate'), align: 'left', minWidth: 120 },
    ],
    [t],
  );

  const statuses: DocumentStatus[] = React.useMemo(
    () => [
      { value: 0, icon: icons.done, color: '#fff' },
      { value: 1, icon: icons.timeAlarm, color: 'rgb(254, 246, 219)' },
      { value: 2, icon: icons.error, color: 'rgb(254, 246, 219)' },
      { value: 3, icon: icons.lock, color: 'rgb(246, 214, 217)' },
    ],
    [],
  );

  const getDocumentStatus = React.useCallback<(v: number) => DocumentStatus>(
    (v: number) => statuses.find(({ value }) => value === v) || statuses[0],
    [statuses],
  );

  const preparedListingData = React.useMemo<BillingDocumentTableData[]>(
    () =>
      listingData.map((item, index) => {
        const status = getDocumentStatus(item.status);

        return {
          ...item,
          id: index,
          status: (
            <Stack key={`${item?.title}${item?.dueDebtDate}`} alignItems="center" justifyContent="center">
              <Icon>{status.icon}</Icon>
            </Stack>
          ),
          dueDebtDate: moment(item.dueDebtDate).format(DATE_FORMAT.FORMAT_RU),
          bgColor: status.color,
        };
      }),
    [listingData, getDocumentStatus],
  );

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            {headers.map(({ label, align, minWidth }) => (
              <TableCell key={label} align={align} sx={{ minWidth }}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {preparedListingData.map(row => (
            <TableRow key={row.id}>
              {headers.map(({ value, align, minWidth }) => (
                <TableCell key={value} align={align} sx={{ minWidth, padding: '0.5rem 1rem', backgroundColor: row.bgColor }}>
                  {at(row, value)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default withTranslation('dialogs', { keyPrefix: 'accountSettings.tabs.documents.table' })(DocumentsTable);
