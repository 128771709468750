import { ErrorResponse, onError } from '@apollo/client/link/error';
import { Observable } from '@apollo/client/utilities';
import { FetchResult } from '@apollo/client/link/core';
import { invariant } from 'ts-invariant';
import { fromPromise } from '@apollo/client';
import { print } from 'graphql/language/printer';
import { showApolloError, showError } from '@app/core/utils/notifications';
import logger from '@app/core/logger/Logger';
import { graphQLErrorExtra, networkErrorExtra } from '@app/core/logger/interfaces/Errors';
import tokenStorage from '@app/clients/apollo/tokenStorage';
import StatusCodes from '@app/core/constants/statusCodes';
import refreshTokens from '@app/clients/apollo/refreshTokens';
import ErrorMessages from '@app/core/constants/errorsMessages';
import { store } from '@app/store';
import { authActions } from '@app/store/slices/auth';
import { strictlyEqual } from '@app/v2/shared/helpers';

let isRefreshing = false;
let pendingRequests = [];

const resolvePendingRequests = () => {
  pendingRequests.forEach(callback => callback());
  pendingRequests = [];
};

export default onError(({ graphQLErrors, networkError, operation, forward }: ErrorResponse): Observable<FetchResult> | void => {
  const { variables, query } = operation;
  const { response } = operation.getContext();

  const unauthorizedLogout = () => {
    tokenStorage.remove();
    store.dispatch(authActions.logout());
    operation.getContext().cache.data.clear();
  };

  if (response?.status === StatusCodes.TOO_MANY_REQUEST_429) {
    showError(ErrorMessages.REQUEST_LIMIT_EXCEEDED);

    return;
  }

  if (graphQLErrors) {
    for (const graphQLError of graphQLErrors) {
      const { message, path, extensions } = graphQLError;
      const {
        query: extensionsQuery,
        serviceName,
        validation,
        response: { code: extensionsResponseCode } = {},
      } = extensions as {
        serviceName: string;
        query: string;
        validation: object;
        response: {
          code: number;
        };
      };

      logger.registerError<graphQLErrorExtra>({
        message: `[Apollo error][${serviceName}]: ${message}`,
        extra: {
          path,
          serviceName,
          extensionsQuery,
          query: print(query),
          variables,
          validation,
        },
      });

      if (response.status === StatusCodes.UNAUTHORIZED_401) {
        extensions.code = 'UNAUTHENTICATED';
      }

      let forward$;

      switch (extensions.code) {
        case 'UNAUTHENTICATED':
          if (!isRefreshing) {
            isRefreshing = true;
            forward$ = fromPromise(
              refreshTokens()
                .then(({ accessToken }) => {
                  if (!accessToken) return unauthorizedLogout();

                  resolvePendingRequests();
                  return accessToken;
                })
                // eslint-disable-next-line no-loop-func
                .catch(() => {
                  pendingRequests = [];
                  unauthorizedLogout();
                })
                // eslint-disable-next-line no-loop-func
                .finally(() => {
                  isRefreshing = false;
                }),
            )
              .filter(Boolean)
              .flatMap(accessToken => {
                const oldHeaders = operation.getContext().headers;
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${accessToken}`,
                  },
                });

                return forward(operation);
              });
          } else {
            forward$ = fromPromise(
              // eslint-disable-next-line no-loop-func
              new Promise(resolve => {
                pendingRequests.push(() => resolve('Success'));
              }),
            );
          }

          // eslint-disable-next-line consistent-return
          return forward$.flatMap(() => forward(operation));
      }

      if (response?.status >= StatusCodes.INTERNAL_SERVER_ERROR_500 || extensionsResponseCode >= StatusCodes.INTERNAL_SERVER_ERROR_500) {
        showApolloError(graphQLError);
      }
    }
  }

  if (networkError && !(networkError instanceof Error)) {
    const { statusCode = -1, message } = networkError;

    if (statusCode && statusCode === -1) {
      showError(message);
    }

    if (statusCode && statusCode !== -1 && message) {
      showError(message);
    }

    if (strictlyEqual<number>(statusCode, StatusCodes.UNAUTHORIZED_401)) {
      unauthorizedLogout();
    }

    logger.registerError<networkErrorExtra>({
      message: `[Network error]: ${message}`,
      extra: {
        statusCode,
        query,
        variables,
      },
    });

    invariant.error(`[Network error]: ${networkError}`);
  }
});
