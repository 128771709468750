import Yup from '@app/core/validation/locale';

const Schema = Yup.object().shape({
  title: Yup.string().required(),
  organizationId: Yup.number().required(),
  playlist: Yup.array().of(
    Yup.object().shape({
      duration: Yup.number().required(),
      dateTo: Yup.string(),
      dateFrom: Yup.string(),
      startTime: Yup.string(),
      endTime: Yup.string(),
      template: Yup.object().shape({
        id: Yup.number().required(),
        configuration: Yup.object().shape({
          id: Yup.number().required(),
        }),
      }),
    }),
  ),
});

export default Schema;
