/* eslint-disable no-param-reassign */
import moment from 'moment';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CriteriaType } from '@app/modules/monitor-module/dispatcher-page/types';
import { ReducersNames } from '@store/constants';
import { REPORT_DAY_SHIFT } from '@app/modules/monitor-module/dispatcher-page/constants';
import { ReportStatuses } from '@app/v2/shared/enums';
import { getStartSessionDateTime, convertToMoscowTimezone } from '@app/modules/monitor-module/dispatcher-page/helpers';

const getDefaultDailyReportTime = () => {
  const date = convertToMoscowTimezone();
  const startSessionDateTime = getStartSessionDateTime();
  if (date.isBefore(startSessionDateTime)) {
    return startSessionDateTime.clone().add(-REPORT_DAY_SHIFT, 'day').format();
  }
  return startSessionDateTime.format();
};

interface FDAFilters {
  dateFrom: moment.Moment;
  dateTo: moment.Moment;
  status: string[];
  organizationIds: number[];
}
interface DailyReportState {
  dailyReportId: number;
  dailyReportDateTime: string;
  dailyReportUserId: number;
  dailyReportStatus: ReportStatuses;
  isEditable: boolean;
  // TODO: Temporary solution for new Roads. Remove and change related logic when excel Roads will use in filter Dictionary or similar
  excelRoads: Common.SelectorValues;
  emergencyCriteria: CriteriaType[];
  filtersFDA: FDAFilters;
}

const initialState: DailyReportState = {
  dailyReportId: null,
  dailyReportDateTime: getDefaultDailyReportTime(),
  dailyReportUserId: null,
  dailyReportStatus: null,
  isEditable: false,
  excelRoads: [],
  emergencyCriteria: [],
  filtersFDA: {
    dateFrom: moment().add(-1, 'day'),
    dateTo: moment(),
    status: [],
    organizationIds: [],
  },
};

const dailyReportSlice = createSlice({
  name: ReducersNames.dailyReport,
  initialState,
  reducers: {
    setDailyReportId(state: DailyReportState, { payload }: PayloadAction<number>) {
      state.dailyReportId = payload;
    },
    setDailyReportDateTime(state: DailyReportState, { payload }: PayloadAction<string>) {
      state.dailyReportDateTime = payload;
    },
    setDailyReportIsEditable(state: DailyReportState, { payload }: PayloadAction<boolean>) {
      state.isEditable = payload;
    },
    setDailyReportUserId(state: DailyReportState, { payload }: PayloadAction<number>) {
      state.dailyReportUserId = payload;
    },
    setDailyReportStatus(state: DailyReportState, { payload }: PayloadAction<ReportStatuses>) {
      state.dailyReportStatus = payload;
    },
    setExcelRoads(state: DailyReportState, { payload }: PayloadAction<Common.SelectorValues>) {
      state.excelRoads = payload;
    },
    setEmergencyCriteria(state: DailyReportState, { payload }: PayloadAction<CriteriaType[]>) {
      state.emergencyCriteria = payload;
    },
    setIsFiltersFDA(state: DailyReportState, { payload }: PayloadAction<Partial<FDAFilters>>) {
      state.filtersFDA = { ...state.filtersFDA, ...payload };
    },
    resetStoreToDefault() {
      return initialState;
    },
  },
});

export const dailyReportActions = dailyReportSlice.actions;

export default dailyReportSlice.reducer;
