import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogTitle, Icon, IconButton, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DialogRef } from '@app/core/providers/DialogProvider';
import icons from '@app/assets/iconFont';
import getHumanizedAddress from '@app/core/helpers/getHumanizedAddress';
import { formatRuFull } from '@app/core/helpers/dateFormat';

type Props = {
  snapshotUrl: string;
  kilometer: number;
  meter: number;
  title: string;
  dateTime: string;
} & DialogRef;

const CSDWaterLevelSnapshotDialog = ({ title, snapshotUrl, dateTime, kilometer, meter, close }: Props) => {
  const { t } = useTranslation('common');

  const classes = useStyles({ snapshotURL: snapshotUrl });

  return (
    <Dialog open maxWidth="sm" onClose={() => close()}>
      <DialogTitle>
        <Box className={classes.title}>
          <Typography variant="h3">
            {getHumanizedAddress(t('units.kilometers'), kilometer, meter)}, {title}
          </Typography>

          {dateTime && (
            <Typography variant="subtitle1">
              {`${t('dates.date')}:`} {formatRuFull(dateTime)}
            </Typography>
          )}
        </Box>

        <IconButton onClick={() => close()}>
          <Icon>{icons.close}</Icon>
        </IconButton>
      </DialogTitle>

      <Box className={classes.content}>
        <Box className={classes.snapshot} />
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles<Theme, { snapshotURL: string }>({
  title: {
    gap: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    gap: '1rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  snapshot: {
    width: '100%',
    height: '37rem',
    display: 'flex',
    backgroundSize: 'cover',
    backgroundImage: ({ snapshotURL }) => `URL(${snapshotURL})`,
  },
});

export default CSDWaterLevelSnapshotDialog;
