import React from 'react';
import LoginLayout from './containers/login/Layout';
import ConfirmationLayout from './containers/confirmation/Layout';
import PasswordRecoveryLayout from './containers/passwordRecovery/Layout';
import PasswordRecoveryLinkLayout from './containers/passwordRecoveryLink/Layout';

const AuthModule = {
  login: props => <LoginLayout {...props} />,
  confirmation: () => <ConfirmationLayout />,
  passwordRecovery: () => <PasswordRecoveryLayout />,
  passwordRecoveryLink: () => <PasswordRecoveryLinkLayout />,
};

export default AuthModule;
