import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Box, TableBody, TableHead, TableRow } from '@mui/material';
import { SortStatus } from '@app/v2/shared/enums';
import { Header, Row, TableData } from './types';
import createTableRowClassList from './helpers';
import DataGridSortDefault from './DataGridSortDefault';
import CSDTable, { UNHIGHLIGHTED_ROW_INDEX } from '../../CSDTable/CSDTable';
import CSDTableCell from '../../CSDTableCells/CSDTableCell';
import CSDTableEmptyCell from '../../CSDTableCells/CSDTableEmptyCell';

interface Props<Data extends TableData> extends Table.CustomTableProps<any, Row<TableData>> {
  headerRows?: { [key: number]: Array<Header<Data> & { columnIndex: number }> };
  tableData: Row<Data>[];
  getRowCells: (index: number, data: Data) => React.ReactNode;
  scrollToRowCondition?: (row: Row<Data>) => boolean;
  onSorting?: (key: string, value: SortStatus) => void;
  sortingValue?: Common.Sorting;
  isWrapHeaderText?: boolean;
  stickyHeader?: boolean;
  isShowHeader?: boolean;
}

const DataGridCSDTable = <T extends TableData>(props: Props<T>, ref) => {
  const {
    tableData,
    getRowCells,
    headerRows,
    onRowClick,
    scrollToRowCondition,
    onSorting,
    sortingValue,
    isWrapHeaderText = false,
    stickyHeader = false,
    isShowHeader = true,
    ...tableProps
  } = props;
  const scrollRef = useRef<HTMLTableRowElement>(null);
  const isRefAssigned = useRef(false);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [tableData]);

  const tableHeaderRows = Object.entries(headerRows).map(([key, headerColumns], rowIndex) => (
    <TableRow key={`data-grid-table-head-${key}`} data-testid={`header-row-${rowIndex}`}>
      {headerColumns.map(
        (
          { field, headerName, renderHead, tooltip, thProps, columnIndex, isSortable = false, renderSort: RenderSort, headerContextAlign = 'center' },
          cellIndex,
        ) => {
          return (
            <CSDTableCell
              key={`data-grid-table-head-${field}`}
              label={tooltip}
              rowIndex={UNHIGHLIGHTED_ROW_INDEX}
              columnIndex={columnIndex}
              isNormalLineHeight
              data-testid={`header-row-${rowIndex}-header-cell-${cellIndex}`}
              {...thProps}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: headerContextAlign,
                  justifyContent: headerContextAlign,
                  gap: '0.5rem',
                  maxWidth: 'calc(100% - 1px)',
                }}
              >
                <Box sx={{ whiteSpace: isWrapHeaderText ? 'wrap' : 'nowrap' }}>{renderHead || headerName || field}</Box>
                {isSortable && RenderSort && <RenderSort sorting={sortingValue} sortKey={field} onSorting={onSorting} />}
                {isSortable && !RenderSort && <DataGridSortDefault sorting={sortingValue} sortKey={field} onSorting={onSorting} />}
              </Box>
            </CSDTableCell>
          );
        },
      )}
    </TableRow>
  ));

  return (
    <CSDTable ref={ref} {...tableProps}>
      {isShowHeader ? (
        <TableHead
          sx={
            stickyHeader
              ? {
                  zIndex: 2,
                  position: 'sticky',
                  top: '0px',
                }
              : null
          }
        >
          {tableHeaderRows}
        </TableHead>
      ) : null}
      <TableBody>
        {tableData.length ? (
          tableData.map((item, rowIndex) => {
            const shouldAssignRef = scrollToRowCondition && scrollToRowCondition(item) && !isRefAssigned.current;

            if (shouldAssignRef) {
              isRefAssigned.current = true;
            }

            return (
              <TableRow
                key={`data-grid-table-row-${item.key || item.id || rowIndex}`}
                className={createTableRowClassList(item, scrollToRowCondition && scrollToRowCondition(item))}
                ref={shouldAssignRef ? scrollRef : null}
                onClick={() => {
                  if (typeof onRowClick === 'function') onRowClick(item);
                }}
                data-testid={`data-grid-table-row-${rowIndex}`}
              >
                {getRowCells(rowIndex, item as T)}
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <CSDTableEmptyCell colSpan={headerRows[0].length} />
          </TableRow>
        )}
      </TableBody>
    </CSDTable>
  );
};

export default React.forwardRef(DataGridCSDTable);
