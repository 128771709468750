import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { SvgIconComponent } from '@mui/icons-material';

export default function usePrepareContentFromMUIIcons<Key extends string>(data: { name: Key; Icon: SvgIconComponent }[]): Record<Key, string> {
  const searchValue = '<svg ';
  const replaceValue = '<svg xmlns="http://www.w3.org/2000/svg" ';
  const dataImagePrefix = 'data:image/svg+xml,';

  return data.reduce((acc, { name, Icon }) => {
    const svg = renderToStaticMarkup(<Icon />);
    const svgWithXlmns = svg.replace(searchValue, replaceValue);

    acc[name] = `url('${dataImagePrefix}${svgWithXlmns}')`;

    return acc;
  }, {} as Record<Key, string>);
}
