import { DEFAULT_PROFILE_LANGUAGE, Languages } from '@app/v2/shared/enums';

const getCharWidgetIdByLanguage = (locale: Languages): string => {
  switch (locale) {
    case Languages.RU:
      return process.env.REACT_APP_CHAT_WIDGET_ID_RU;
    case Languages.EN:
      return process.env.REACT_APP_CHAT_WIDGET_ID_EN;
    case Languages.ES:
      return process.env.REACT_APP_CHAT_WIDGET_ID_ES;
    default:
      return process.env[`REACT_APP_CHAT_WIDGET_ID_${DEFAULT_PROFILE_LANGUAGE.toUpperCase()}`];
  }
};

export default getCharWidgetIdByLanguage;
