import React from 'react';
import RoadDirectionType from '../../enums/RoadDirectionType';
import { MARKER_RATION, PADDING_RATION, ARROW_ELEMENT_ID } from '../../constants';
import { getArrowCoordinates } from '../../helpers';

type Props = {
  index: number;
  roadHeight: number;
  directionType: RoadDirectionType;
  isOppositeDitectionIndex: boolean;
  isLast: boolean;
  showMiddleDoubleLine: boolean;
};

const CSDDynamicRoadItem = ({ index, roadHeight, directionType, isOppositeDitectionIndex, isLast, showMiddleDoubleLine }: Props) => {
  const isStartIndex = !index;

  const roadMarkerWidth = roadHeight * MARKER_RATION;

  const sideLinePadding = roadHeight * PADDING_RATION;

  const lineWidth = roadMarkerWidth / 2;

  return (
    <g style={{ transform: `translate(0, ${index * roadHeight}%)` }} height={`${roadHeight}%`}>
      {isStartIndex && <line x1="5%" x2="95%" y1={`${sideLinePadding}%`} y2={`${sideLinePadding}%`} strokeWidth={`${lineWidth}%`} stroke="white" />}
      <line
        fill="none"
        stroke="white"
        {...getArrowCoordinates(roadHeight, directionType)}
        markerEnd={`url(#${ARROW_ELEMENT_ID})`}
        strokeWidth={`${roadMarkerWidth}%`}
      />
      {!isStartIndex && (isOppositeDitectionIndex ? !showMiddleDoubleLine : true) && (
        <line x1="5%" x2="95%" strokeDasharray="5% 5%" strokeWidth={`${roadMarkerWidth}%`} stroke="white" />
      )}
      {isOppositeDitectionIndex && showMiddleDoubleLine && (
        <g>
          <line x1="5%" x2="95%" y1={`${roadMarkerWidth}%`} y2={`${roadMarkerWidth}%`} strokeWidth={`${lineWidth}%`} stroke="white" />
          <line x1="5%" x2="95%" y1={`-${roadMarkerWidth}%`} y2={`-${roadMarkerWidth}%`} strokeWidth={`${lineWidth}%`} stroke="white" />
        </g>
      )}
      {isLast && (
        <line
          x1="5%"
          x2="95%"
          y1={`${roadHeight - sideLinePadding}%`}
          y2={`${roadHeight - sideLinePadding}%`}
          strokeWidth={`${lineWidth}%`}
          stroke="white"
        />
      )}
    </g>
  );
};

export default CSDDynamicRoadItem;
