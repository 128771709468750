import ADD_TO_FAVORITES from './addToFavorites.graphql';
import REMOVE_FROM_FAVORITES from './removeFromFavorites.graphql';
import HIDE_NEWS from './hideNews.graphql';

const accidentNewsMutations = {
  addToFavorites: ADD_TO_FAVORITES,
  removeFromFavorites: REMOVE_FROM_FAVORITES,
  hide: HIDE_NEWS,
};

export default accidentNewsMutations;
