import React, { memo } from 'react';
import { Box, BoxProps, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { linearGradient } from '@theme/styles';
import { strictlyEqual } from '@app/v2/shared/helpers';

interface Props {
  activeTabId: string | number;
  tabs: { id: string | number; label: string }[];
  onChange: (id: string | number) => void;
}

const CSDRevertTabs = ({ tabs, activeTabId, onChange }: Props) => {
  return (
    <StyledWrapper>
      {!!tabs.length &&
        tabs.map(({ id, label }) => (
          <Button
            sx={{
              borderRadius: '0px 0px 8px 8px',
              border: strictlyEqual(id, activeTabId) ? 'none' : '1px solid black',
              borderTop: 'none',
              background: strictlyEqual(id, activeTabId) ? linearGradient : 'transparent',
              color: ({ palette }) => (strictlyEqual(id, activeTabId) ? palette.common.white : palette.common.black),
              '&:hover': {
                background: strictlyEqual(id, activeTabId) ? linearGradient : 'transparent',
                color: ({ palette }) => (strictlyEqual(id, activeTabId) ? palette.common.white : palette.common.black),
              },
            }}
            size="small"
            key={id}
            onClick={() => onChange(id)}
          >
            {label}
          </Button>
        ))}
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Box)<BoxProps>({
  display: 'flex',
  width: '100%',
  paddingLeft: '1rem',
  gap: '0.5rem',
});

export default memo(CSDRevertTabs);
