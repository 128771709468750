import { useTranslation } from 'react-i18next';
import { MeteoDPG, MeteoParameters } from '@app/v2/shared/enums';
import { degrees2Rhumbs, isNotNullAndUndefined, strictlyEqual } from '@app/v2/shared/helpers';
import { getTextWindDirection } from '@app/core/constants/windDirections';
import statusDampTypes from '@app/core/constants/statusDampTypes';
import { PrecipitationTypeConfig } from '@app/v2/shared/configs';

export default function usePrepareNotNumberValues({
  windSpeed,
  windGusts,
}: Record<MeteoParameters.WindSpeed | MeteoParameters.WindGusts, Meteo.ChartPointParameters['data'][0]>): Partial<
  Record<MeteoParameters | MeteoDPG, Common.AnyFunction>
> {
  const { t } = useTranslation('constants');

  return {
    [MeteoParameters.PrecipitationType]: item => ({
      ...item,
      value: isNotNullAndUndefined(item.value) ? t(PrecipitationTypeConfig[item.value]?.label) : null,
    }),
    [MeteoParameters.WindDirGr]: item => ({
      ...item,
      value: windSpeed.value && isNotNullAndUndefined(item.value) ? `${t(getTextWindDirection(degrees2Rhumbs(item.value)))} ${item.value}` : null,
    }),
    [MeteoParameters.WindSpeed]: item => ({
      ...item,
      value: !windGusts?.value && strictlyEqual(item.value, 0) ? t('windDirections.calm') : item.value,
    }),
    [MeteoParameters.StatusDamp]: item => ({
      ...item,
      value: statusDampTypes[item.value] ? t(statusDampTypes[item.value]?.label) : null,
    }),
    [MeteoParameters.StatusDamp2]: item => ({
      ...item,
      value: statusDampTypes[item.value] ? t(statusDampTypes[item.value]?.label) : null,
    }),
    [MeteoDPG.D01]: item => replaceDPG(item, '01'),
    [MeteoDPG.D02]: item => replaceDPG(item, '02'),
    [MeteoDPG.D03]: item => replaceDPG(item, '03'),
    [MeteoDPG.D04]: item => replaceDPG(item, '04'),
    [MeteoDPG.D05]: item => replaceDPG(item, '05'),
    [MeteoDPG.D06]: item => replaceDPG(item, '06'),
    [MeteoDPG.D07]: item => replaceDPG(item, '07'),
    [MeteoDPG.D08]: item => replaceDPG(item, '08'),
    [MeteoDPG.D09]: item => replaceDPG(item, '09'),
    [MeteoDPG.D10]: item => replaceDPG(item, '10'),
    [MeteoDPG.D11]: item => replaceDPG(item, '11'),
    [MeteoDPG.D12]: item => replaceDPG(item, '12'),
    [MeteoDPG.D13]: item => replaceDPG(item, '13'),
    [MeteoDPG.D14]: item => replaceDPG(item, '14'),
    [MeteoDPG.D15]: item => replaceDPG(item, '15'),
  };
}

function replaceDPG(item: Meteo.ChartPointParameters['data'][0], number: string) {
  return {
    ...item,
    value: isNotNullAndUndefined(item.value) ? `${item.value}${item.valueSuffix}` : null,
    number,
  };
}
