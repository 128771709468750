import { AlertIndexColorValues, MAX_ALERT_INDEX_VALUE, RED_2 } from '@app/v2/shared/constants';
import { i18n } from '@app/v2/shared/localization';

const getAlertIndexConfig = (alertIndexData: AlertIndex.Data): StaticConfigs.AlertIndexItem[] => [
  {
    id: 1,
    alertIndexValue: alertIndexData?.is_hour_1,
    backgroundColor: alertIndexData?.is_hour_1 > MAX_ALERT_INDEX_VALUE ? RED_2 : AlertIndexColorValues[alertIndexData?.is_hour_1],
    tooltip: `${i18n.t('entities:alertIndex.forecast.tooltip.single')}${alertIndexData?.is_hour_1}`,
  },
  {
    id: 2,
    alertIndexValue: alertIndexData?.is_hour_2,
    backgroundColor: alertIndexData?.is_hour_2 > MAX_ALERT_INDEX_VALUE ? RED_2 : AlertIndexColorValues[alertIndexData?.is_hour_2],
    tooltip: `${i18n.t('entities:alertIndex.forecast.tooltip.plural', { hours: 2 })}${alertIndexData?.is_hour_2}`,
  },
  {
    id: 3,
    alertIndexValue: alertIndexData?.is_hour_3,
    backgroundColor: alertIndexData?.is_hour_3 > MAX_ALERT_INDEX_VALUE ? RED_2 : AlertIndexColorValues[alertIndexData?.is_hour_3],
    tooltip: `${i18n.t('entities:alertIndex.forecast.tooltip.plural', { hours: 3 })}${alertIndexData?.is_hour_3}`,
  },
  {
    id: 4,
    amountHours: 4,
    borderRadius: '0px 0px 8px 0px',
    alertIndexValue: alertIndexData?.is_hour_4,
    backgroundColor: alertIndexData?.is_hour_4 > MAX_ALERT_INDEX_VALUE ? RED_2 : AlertIndexColorValues[alertIndexData?.is_hour_4],
    tooltip: `${i18n.t('entities:alertIndex.forecast.tooltip.plural', { hours: 4 })}${alertIndexData?.is_hour_4}`,
  },
];

export default getAlertIndexConfig;
