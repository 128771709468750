import React, { useState, useMemo, memo } from 'react';
import { withTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Stack, CardContent, CardContentProps, Icon, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSDInput, CSDAlert } from '@app/modules/kit-module/shared/ui';
import { PasswordRecoveryStatuses } from '@app/v2/shared/enums';
import { useAppSelector } from '@app/v2/shared/hooks';
import icons from '@app/assets/iconFont';
import { ValidationSchemeState, EQUAL_VALIDATION_KEY } from '../validationSchema';

const FormContent = ({ t }: I18N.TranslationFunction<'auth', 'passwordRecovery.cardContent'>) => {
  const [isVisiblePassword, setIsVisiblePassword] = useState<boolean>(false);
  const [isVisiblePasswordRepeat, setIsVisiblePasswordRepeat] = useState<boolean>(false);
  const { values, handleChange, touched, errors } = useFormikContext<ValidationSchemeState>();
  const passwordRecoveryStatus = useAppSelector(state => state.auth.passwordRecoveryStatus);

  const handleChangePasswordVisibility = () => {
    setIsVisiblePassword(prev => !prev);
  };

  const handleChangePasswordRepeatVisibility = () => {
    setIsVisiblePasswordRepeat(prev => !prev);
  };

  const hasEqualError = useMemo(() => errors?.passwordRepeat === EQUAL_VALIDATION_KEY, [errors]);

  const cardContent = useMemo(() => {
    switch (passwordRecoveryStatus) {
      case PasswordRecoveryStatuses.SUCCESS:
        return (
          <CSDAlert sx={{ wordBreak: 'break-word', paddingRight: 0 }} severity="success">
            {t('reset.success')}
          </CSDAlert>
        );
      case PasswordRecoveryStatuses.INVALID:
        return (
          <CSDAlert sx={{ wordBreak: 'break-word', paddingRight: 0 }} severity="error">
            {t('reset.invalid')}
          </CSDAlert>
        );
      default:
        return (
          <Stack direction="column" spacing="1.5rem">
            {hasEqualError && (
              <CSDAlert sx={{ wordBreak: 'break-word', paddingRight: 0 }} severity="error">
                {t('error.mustBeTheSame')}
              </CSDAlert>
            )}
            <CSDInput
              name="password"
              value={values.password}
              onChange={handleChange('password')}
              helperText={touched.password ? errors.password : ''}
              error={(touched.password && Boolean(errors.password)) || hasEqualError}
              placeholder={t('password.placeholder')}
              type={isVisiblePassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Icon sx={{ cursor: 'pointer' }} onClick={handleChangePasswordVisibility}>
                      {icons.eye}
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
            <CSDInput
              name="passwordRepeat"
              value={values.passwordRepeat}
              onChange={handleChange('passwordRepeat')}
              helperText={!hasEqualError && touched.passwordRepeat ? errors.passwordRepeat : ''}
              error={touched.passwordRepeat && Boolean(errors.passwordRepeat)}
              placeholder={t('passwordRepeat.placeholder')}
              type={isVisiblePasswordRepeat ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Icon sx={{ cursor: 'pointer' }} onClick={handleChangePasswordRepeatVisibility}>
                      {icons.eye}
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        );
    }
  }, [errors, values, touched, handleChange, hasEqualError, isVisiblePassword, isVisiblePasswordRepeat, passwordRecoveryStatus, t]);

  return <StyledCardContent>{cardContent}</StyledCardContent>;
};

const StyledCardContent = styled(CardContent)<CardContentProps>({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  padding: '0 1.5rem !important',
});

export default withTranslation('auth', { keyPrefix: 'passwordRecovery.cardContent' })(memo(FormContent));
