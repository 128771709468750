import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Highcharts from 'highcharts';
import { useAppSelector } from '@app/v2/shared/hooks';
import { DATE_FORMAT } from '@app/v2/shared/constants';
import { isNotNullAndUndefined } from '@app/v2/shared/helpers';
import {
  preparePlotOptionsParameters,
  prepareSeriesParameters,
  prepareTooltip,
  prepareXAxisParameters,
  prepareYAxisParameters,
} from './chartOptionsPreparationFns';
import type { MeteoChartOptionsParameters } from './types';

interface Parameters extends Partial<MeteoChartOptionsParameters> {
  setAmountsOfPoints: (value: number) => void;
}

export default function useMeteoChartOptions({
  content,
  title,
  contentKeys,
  dates,
  datePrognosisType,
  fixTooltipPosition,
  isFixed,
  currentIndex,
  chartsSetup,
  setAmountsOfPoints,
}: Parameters) {
  const { t: chartsT } = useTranslation('charts');
  const { t: commonT } = useTranslation('common');
  const { t: parameterT } = useTranslation('parameters');
  const { t: constantsT } = useTranslation('constants');

  const keys = contentKeys.filter(key => content?.[key]?.some(isNotNullAndUndefined));

  const utc = useAppSelector(state => state.account.settings.utc);
  const isWithTimeZoneOutput = useAppSelector(state => state.account.settings.timezoneInsteadCheckbox);

  const timezoneOffset = isWithTimeZoneOutput
    ? moment.parseZone(moment().utcOffset(utc), DATE_FORMAT.WITH_TIME_ZONE).utcOffset()
    : moment.parseZone(dates?.[0]).utcOffset();

  const convertDataToMomentWithOffeset = (date: moment.MomentInput): moment.Moment => {
    return moment.utc(date).utcOffset(timezoneOffset);
  };

  const arrOfSetups = chartsSetup.reduce<Meteo.ChartsSetupParameter[]>((acc, setUp) => {
    setUp.params.forEach(parameter => {
      if (!keys.includes(parameter.label)) return;
      acc.push(parameter);
    });

    return acc;
  }, []);

  return {
    options: {
      accessibility: {
        enabled: false,
      },
      legend: {
        itemStyle: {
          cursor: 'default',
          color: 'black',
        },
      },
      time: {
        // Used offset like in ECMAScript getTimezoneOffset method. https://api.highcharts.com/highcharts/time.timezoneOffset
        timezoneOffset: -timezoneOffset,
      },
      exporting: {
        enabled: false,
      },
      title: {
        text: title,
        style: {
          color: 'black',
          fontWeight: 'bold',
        },
      },
      lang: {
        noData: commonT('notificationMessages.noDataForPeriod'),
      },
      noData: {
        style: {
          fontWeight: '700',
          fontSize: '24px',
          color: '#222631',
        },
      },
      credits: {
        enabled: false,
      },
      chart: {
        zoomType: 'x',
        marginLeft: 50,
        events: {
          click(this: any) {
            if (!this?.hoverPoint || !this?.tooltip || !this?.userOptions) return;
            if (isFixed) return;

            fixTooltipPosition({
              index: this.hoverPoint.index,
              date: convertDataToMomentWithOffeset(this.hoverPoint.category).format(DATE_FORMAT.FORMAT_RU_UNFULL_DATE_AND_TIME),
              coords: { x: this.tooltip.now.x + 24, y: this.tooltip.now.y + 16 },
              currentPointData: this.userOptions.series.reduce((acc, { icon, name, tooltip, label }) => {
                acc[label] = { icon, name, valueSuffix: tooltip.valueSuffix };
                return acc;
              }, {}),
            });
          },
          render() {
            if (!Array.isArray(Highcharts.charts)) return;

            const { amount } = Highcharts.charts.filter(Boolean).reduce(
              (acc, { series }) => {
                series.forEach(({ data }) => {
                  acc.amount += data.length;
                });

                return acc;
              },
              { amount: 0 },
            );

            setAmountsOfPoints(amount);
          },
        },
      },
      boost: {
        useGPUTranslations: true,
        seriesThreshold: 1,
      },
      tooltip: prepareTooltip({
        isFixed,
        constantsT,
        convertDataToMomentWithOffeset,
      }),
      plotOptions: preparePlotOptionsParameters(),
      yAxis: prepareYAxisParameters({ arrOfSetups, content, commonT }),
      xAxis: prepareXAxisParameters({ dates, datePrognosisType, chartsT, isFixed, currentIndex, keys, convertDataToMomentWithOffeset }),
      series: prepareSeriesParameters({
        commonT,
        constantsT,
        arrOfSetups,
        content,
        dates,
        parameterT,
        convertDataToMomentWithOffeset,
      }),
    },
  };
}
