import { Dispatch } from 'redux';
import debounce from 'lodash/debounce';
import client from '@app/clients/apollo/client';
import filterQueries from '@app/clients/apollo/requests/queries/filters';
import { showApolloErrors, showError } from '@app/core/utils/notifications';
import ErrorMessages from '@app/core/constants/errorsMessages';
import { filterActions } from '@app/store/slices/filters';
import { StationTypes } from '@app/v2/shared/enums';
import { EMPTY_STRING } from '@app/v2/shared/constants';

const fetch = debounce(async (dispatch: Dispatch, filters: Filters.Values, stationType?: StationTypes): Promise<void> => {
  dispatch(filterActions.setDictionaryLoaderStatus(true));

  try {
    const { data, errors } = await client.query<Filters.Dictionary, Filters.DictionaryVariables>({
      query: filterQueries.filterEntities,
      variables: {
        roadIds: filters.roads,
        organizationIds: filters.organizations,
        placeIds: filters?.places || [],
        stationType: stationType ?? EMPTY_STRING,
      },
      fetchPolicy: 'cache-first',
    });

    if (errors) showApolloErrors(errors);

    if (data) {
      dispatch(
        filterActions.setFilterDictionary({
          organizations: data?.organizations || [],
          roads: data?.roads || [],
          places: data?.places || [],
        }),
      );
    }
  } catch (e) {
    showError(ErrorMessages.COMMON_ERROR_MESSAGE);
  } finally {
    dispatch(filterActions.setDictionaryLoaderStatus(false));
  }
}, 1000);

// eslint-disable-next-line import/prefer-default-export
export const fetchFilters = (filters: Filters.Values, stationType?: StationTypes) => {
  return async dispatch => fetch(dispatch, filters, stationType);
};
