import { toast, ToastOptions, ToastPosition } from 'react-toastify';
import { GraphQLError } from 'graphql';
import getErrorTranslationMessage from '@app/core/helpers/getErrorTranslationMessage';
import ErrorMessages from '@app/core/constants/errorsMessages';

const DEFAULT_NOTIFICATION_POSITION: ToastPosition = 'top-right';

const DEFAULT_NOTIFICATION_DELAY = 3000;

const defaultOptions: ToastOptions = {
  position: DEFAULT_NOTIFICATION_POSITION,
  autoClose: DEFAULT_NOTIFICATION_DELAY,
  type: 'info',
};

export default function showToast(content: string, options: ToastOptions = {}) {
  toast(content, { ...defaultOptions, ...options });
}

export function showErrorWithoutCodes(content: string, options: ToastOptions = {}) {
  showToast(content, { ...options, type: 'error' });
}

export function showError(content: string, options: ToastOptions = {}) {
  showToast(getErrorTranslationMessage(content), { ...options, type: 'error' });
}

export function showSuccess(content: string, options: ToastOptions = {}) {
  showToast(content, { ...options, type: 'success' });
}

export function showInfo(content: string, options: ToastOptions = {}) {
  showToast(content, { ...options, type: 'info' });
}

export function showWarning(content: string, options: ToastOptions = {}) {
  showToast(content, { ...options, type: 'warning' });
}

export function showApolloError(error: GraphQLError) {
  const errorsArr = (error?.extensions?.response as any)?.body?.errors;
  const errorMessageInBody = (error?.extensions?.response as any)?.body?.message;

  if (Array.isArray(errorsArr) && errorsArr.length > 0) {
    errorsArr.forEach(({ message, extensions }) => {
      showError(extensions?.response?.body?.message || message || ErrorMessages.COMMON_ERROR_MESSAGE);
    });
  } else if (errorMessageInBody || error?.message) {
    showErrorWithoutCodes(errorMessageInBody || error?.message);
  } else {
    showError(ErrorMessages.COMMON_ERROR_MESSAGE);
  }
}

export function showApolloErrors(errors: ReadonlyArray<GraphQLError>) {
  errors.forEach(error => showApolloError(error));
}
