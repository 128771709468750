import React from 'react';
import clsx from 'clsx';
import { Icon, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import icons from '@app/assets/iconFont';

type SliderArrowProps = {
  onClick?: Common.AnyFunction;
  className?: string;
  additionalClassName?: string;
  icon?: string;
};

const CSDSliderArrow = ({ className, additionalClassName, onClick, icon = icons.right }: SliderArrowProps) => {
  const classes = useStyles();

  return (
    <IconButton onClick={onClick} className={clsx(className, additionalClassName, classes.slideButton)}>
      <Icon>{icon}</Icon>
    </IconButton>
  );
};

const useStyles = makeStyles(() => ({
  slideButton: {
    '&.MuiIconButton-root': {
      position: 'absolute',
      width: 'auto',
      height: 'auto',
      zIndex: 100,
      '& .MuiIcon-root': {
        fontSize: '2.25rem',
      },
    },
    '&::before': {
      display: 'none',
    },
    '&:hover': {
      color: 'inherit',
    },
    '&:focus': {
      color: 'inherit',
    },
    '&.slick-disabled': {
      opacity: 0.4,
      cursor: 'default',
    },
  },
}));

export default CSDSliderArrow;
