const licenseConfig: StaticConfigs.LicenseItem[] = [
  {
    label: 'license.1.label',
    variant: 'h6',
    gutters: true,
    children: [
      {
        label: 'license.1.1.1.label',
        variant: 'body2',
        gutters: true,
        children: [
          {
            label: 'license.1.1.1.1',
            variant: 'body2',
            gutters: false,
          },
          {
            label: 'license.1.1.1.2',
            variant: 'body2',
            gutters: false,
          },
          {
            label: 'license.1.1.1.3',
            variant: 'body2',
            gutters: false,
          },
          {
            label: 'license.1.1.1.4',
            variant: 'body2',
            gutters: false,
          },
          {
            label: 'license.1.1.1.5',
            variant: 'body2',
            gutters: false,
          },
          {
            label: 'license.1.1.1.6',
            variant: 'body2',
            gutters: false,
          },
          {
            label: 'license.1.1.1.7',
            variant: 'body2',
            gutters: false,
          },
          {
            label: 'license.1.1.1.8',
            variant: 'body2',
            gutters: false,
          },
          {
            label: 'license.1.1.1.9',
            variant: 'body2',
            gutters: false,
          },
          {
            label: 'license.1.1.1.10',
            variant: 'body2',
            gutters: false,
          },
          {
            label: 'license.1.1.1.11',
            variant: 'body2',
            gutters: false,
          },
        ],
      },
      {
        label: 'license.1.1.2',
        variant: 'body2',
        gutters: true,
        children: [],
      },
      {
        label: 'license.1.1.3.label',
        variant: 'body2',
        gutters: true,
        children: [
          {
            label: 'license.1.1.3.1',
            variant: 'body2',
            gutters: false,
          },
          {
            label: 'license.1.1.3.2',
            variant: 'body2',
            gutters: false,
          },
          {
            label: 'license.1.1.3.3',
            variant: 'body2',
            gutters: false,
          },
          {
            label: 'license.1.1.3.4',
            variant: 'body2',
            gutters: false,
          },
        ],
      },
      {
        label: 'license.1.1.3.5',
        variant: 'body2',
        gutters: true,
        children: [],
      },
      {
        label: 'license.1.1.4.label',
        variant: 'body2',
        gutters: true,
        children: [
          {
            label: 'license.1.1.4.1',
            variant: 'body2',
            gutters: false,
          },
          {
            label: 'license.1.1.4.2',
            variant: 'body2',
            gutters: false,
          },
          {
            label: 'license.1.1.4.3',
            variant: 'body2',
            gutters: false,
          },
        ],
      },
    ],
  },
  {
    label: 'license.2.label',
    variant: 'h6',
    gutters: true,
    children: [
      {
        label: 'license.2.2.1.label',
        variant: 'body2',
        gutters: true,
        children: [
          {
            label: 'license.2.2.1.1',
            variant: 'body2',
            gutters: false,
          },
          {
            label: 'license.2.2.1.2',
            variant: 'body2',
            gutters: false,
          },
        ],
      },
      {
        label: 'license.2.2.2',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.2.2.3',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.2.2.4',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.2.2.5',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.2.2.6',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.2.2.7',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.2.2.8',
        variant: 'body2',
        gutters: true,
      },
    ],
  },
  {
    label: 'license.3.label',
    variant: 'h6',
    gutters: true,
    children: [
      {
        label: 'license.3.3.1',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.3.3.2',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.3.3.3',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.3.3.4',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.3.3.5',
        variant: 'body2',
        gutters: true,
      },
    ],
  },
  {
    label: 'license.4.label',
    text: 'license.4.text',
    variant: 'h6',
    gutters: true,
    children: [
      {
        label: 'license.4.4.1',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.4.4.2',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.4.4.3',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.4.4.4',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.4.4.5',
        variant: 'body2',
        gutters: true,
      },
    ],
  },
  {
    label: 'license.5.label',
    variant: 'h6',
    gutters: true,
    children: [
      {
        label: 'license.5.5.1',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.5.5.2',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.5.5.3',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.5.5.4.label',
        variant: 'body2',
        gutters: true,
        children: [
          {
            label: 'license.5.5.4.1',
            variant: 'body2',
            gutters: false,
          },
          {
            label: 'license.5.5.4.2',
            variant: 'body2',
            gutters: false,
          },
          {
            label: 'license.5.5.4.3',
            variant: 'body2',
            gutters: false,
          },
        ],
      },
    ],
  },
  {
    label: 'license.6.label',
    text: 'license.6.text',
    variant: 'h6',
    gutters: true,
    children: [
      {
        label: 'license.6.6.1',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.6.6.2',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.6.6.3',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.6.6.4',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.6.6.5',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.6.6.6',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.6.6.7',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.6.6.8',
        variant: 'body2',
        gutters: true,
      },
    ],
  },
  {
    label: 'license.7.label',
    variant: 'h6',
    gutters: true,
    children: [
      {
        label: 'license.7.7.1',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.7.7.2',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.7.7.3',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.7.7.4',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.7.7.5',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.7.7.6',
        variant: 'body2',
        gutters: true,
      },
    ],
  },
  {
    label: 'license.8.label',
    variant: 'h6',
    gutters: true,
    children: [
      {
        label: 'license.8.8.1',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.8.8.2',
        variant: 'body2',
        gutters: true,
      },
    ],
  },
  {
    label: 'license.9.label',
    variant: 'h6',
    gutters: true,
    children: [
      {
        label: 'license.9.9.1',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.9.9.2',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.9.9.3',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.9.9.4',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.9.9.5',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.9.9.6',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.9.9.7',
        variant: 'body2',
        gutters: true,
      },
    ],
  },
  {
    label: 'license.10.label',
    variant: 'h6',
    gutters: true,
    children: [
      {
        label: 'license.10.10.1',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.10.10.2',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.10.10.3',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.10.10.4',
        variant: 'body2',
        gutters: true,
      },
      {
        label: 'license.10.10.5',
        variant: 'body2',
        gutters: true,
      },
    ],
  },
  {
    label: 'license.11.label',
    variant: 'h6',
    gutters: true,
    children: [
      {
        label: 'license.11.11.1',
        variant: 'body2',
        gutters: false,
      },
      {
        label: 'license.11.11.2',
        variant: 'body2',
        gutters: false,
      },
      {
        label: 'license.11.11.3',
        variant: 'body2',
        gutters: false,
      },
      {
        label: 'license.11.11.4',
        variant: 'body2',
        gutters: false,
      },
      {
        label: 'license.11.11.5',
        variant: 'body2',
        gutters: false,
      },
    ],
  },
];

export default licenseConfig;
