import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Icon, Tooltip } from '@mui/material';
import { useMutation } from '@apollo/client';
import icons from '@app/assets/iconFont';
import vmsNewMutations from '@app/clients/apollo/requests/mutations/vmsNew';
import { useDialog } from '@app/v2/shared/hooks';
import { addFeatureActions } from '@app/v2/shared/utils';
import ErrorMessages from '@app/core/constants/errorsMessages';
import { isNotNullAndUndefined } from '@app/v2/shared/helpers';
import CSDTemplateDialog from './CSDTemplateDialog';

interface Props extends Common.FeaturesActions {
  template: Scoreboards.TemplateContent;
}

const CSDUpdateTemplate = ({ template, ...featureActions }: Props) => {
  const { t } = useTranslation('scoreboard');

  const openEditTemplateDialog = useDialog(CSDTemplateDialog);

  const [updateTemplate, { loading }] = useMutation<Common.MutationResponse, Scoreboards.UpdateTemplateVariables>(vmsNewMutations.template.update);

  const handleUpdate = useCallback(
    ({ id, title, configuration, contents }: Scoreboards.TemplateContent) => {
      if (loading) return;

      if (!isNotNullAndUndefined(id)) {
        throw new Error(ErrorMessages.INCORRECT_UPDATING);
      }

      addFeatureActions(
        updateTemplate({
          variables: {
            id,
            title,
            template: {
              content: {
                contents,
                configurationId: configuration.id,
                configurationTitle: configuration.title,
              },
            },
          },
        }),
        featureActions,
      );
    },
    [featureActions, loading, updateTemplate],
  );

  return (
    <Tooltip title={t('templates.actions.update')}>
      <IconButton onClick={() => openEditTemplateDialog({ onUpdate: handleUpdate, template })}>
        <Icon>{icons.edit}</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default memo(CSDUpdateTemplate);
