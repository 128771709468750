import * as React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Icon, IconButton, Stack, Typography } from '@mui/material';
import { Alert } from '@mui/lab';
import CircularProgress from '@app/components/common/circular-progress/CircularProgress';
import { useAccount, useActions, useAppDispatch, useReloadPage } from '@app/v2/shared/hooks';
import { AccountSettings as AccountSettingsType } from '@store/slices/account/types';
import { changeAccountSettings, fetchAccountSettings } from '@store/thunks/account/account-thunks';
import preparePhone from '@app/core/helpers/preparePhone';
import { isFunction, strictlyEqual } from '@app/v2/shared/helpers';
import useLoading from '@app/core/hooks/useLoading';
import { DEFAULT_PROFILE_LANGUAGE } from '@app/v2/shared/enums';
import icons from '@app/assets/iconFont';
import validationSchema from './validationShema';
import Form from './Form';

type Props = {
  close: () => void;
} & I18N.TranslationFunction<'dialogs', 'accountSettings.tabs.profile'>;

const AccountSettingsTabPanel = ({ close = () => {}, t }: Props) => {
  const dispatch = useAppDispatch();
  const { toggleIsAccountTimeSettings } = useActions();
  const [loading] = useLoading();
  const form = React.useRef<FormikProps<AccountSettingsType>>();
  const { settings, startPages } = useAccount();
  const { reloadPage } = useReloadPage();
  const { t: notificationT } = useTranslation('common', { keyPrefix: 'notificationMessages' });

  React.useEffect(() => {
    dispatch(fetchAccountSettings());
  }, [dispatch]);

  const isHaveAlert = React.useMemo(() => !startPages.map(p => p.value).includes(settings.startPage), [startPages, settings]);

  const handleSubmit = async (value: AccountSettingsType): Promise<void> => {
    if (isFunction(close)) {
      close();
    }

    const newUserSettings = {
      userName: value.userName,
      userEmail: value.userEmail,
      userPosition: value.userPosition || '',
      userPhone: preparePhone(value.userPhone),
      timezoneInsteadCheckbox: value.timezoneInsteadCheckbox,
      utc: value.utc,
      isRemoveFromOrganizationList: value.isRemoveFromOrganizationList,
      isNotReceiveWeatherReports: value.isNotReceiveWeatherReports,
      isMeteoAlertPush: value.isMeteoAlertPush,
      isMeteoBulletinPush: value.isMeteoBulletinPush,
      locale: value?.locale ? value.locale.toUpperCase() : DEFAULT_PROFILE_LANGUAGE,
      startPage: value.startPage,
      isClockDisabled: value.isClockDisabled,
    };

    if (!strictlyEqual(settings.utc, value.utc) || !strictlyEqual(settings.timezoneInsteadCheckbox, value.timezoneInsteadCheckbox)) {
      toggleIsAccountTimeSettings({ status: true });
    }

    await dispatch(changeAccountSettings(newUserSettings));

    if (!strictlyEqual(settings.locale, value.locale)) {
      reloadPage();
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h2">{t('title')}</Typography>
        <IconButton onClick={close}>
          <Icon>{icons.close}</Icon>
        </IconButton>
      </Stack>
      <Stack direction="column" spacing={2} height="100%" overflow="auto">
        {(loading && (
          <Stack height="100%" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        )) || (
          <>
            {isHaveAlert && <Alert severity="error">{notificationT('startPageRedirectMessage')}</Alert>}
            <Formik
              innerRef={form}
              initialValues={settings}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              render={props => <Form {...props} startPages={startPages} closeWindow={close} />}
            />
          </>
        )}
      </Stack>
    </>
  );
};

export default withTranslation('dialogs', { keyPrefix: 'accountSettings.tabs.profile' })(AccountSettingsTabPanel);
