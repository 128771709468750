import Highcharts, { ExportingMimeTypeValue } from 'highcharts';

export interface ChartInfo {
  name: string;
  value: string | number;
}

export const getSVG = (charts: Highcharts.Chart[], title: string, infoSetup: ChartInfo[]) => {
  let top = 20;
  let width = 0;

  const svgArr: string[] = [];

  function addText(text: string, x: number, y: number, fontSize = 16) {
    return `<text fill="#222631" font-family="Roboto" font-size="${fontSize}" letter-spacing="0em"><tspan x="${x}" y="${y}">${text}</tspan></text>`;
  }

  // Add the title
  svgArr.push(`
    <g transform="translate(20, ${top})">
      <text fill="#222631" font-family="Roboto" font-size="30" font-weight="bold" letter-spacing="0em">
        <tspan x="0" y="25.2539">${title}</tspan>
      </text>
    </g>
  `);

  top += 40; // Increase the top position after adding the title

  infoSetup.forEach(({ value, name }, index) => {
    const xPosition =
      20 +
      infoSetup
        .slice(0, index)
        .reduce(
          (previousValue, currentValue) => previousValue + 20 + Math.max(String(currentValue.value).length, String(currentValue.name).length) * 10,
          0,
        );

    svgArr.push(addText(`${name.replace(':', '')}:`, xPosition, top + 20, 16));
    svgArr.push(addText(value.toString(), xPosition, top + 40, 16));
  });

  top += 100; // Increase the top position after adding infoSetup

  function addSVG(svgres: string) {
    const svgWidth = +svgres.match(/^<svg[^>]*width\s*=\s*"?(\d+)"?[^>]*>/)[1];
    const svgHeight = +svgres.match(/^<svg[^>]*height\s*=\s*"?(\d+)"?[^>]*>/)[1];

    // Offset the position of this chart in the final SVG
    let svg = svgres.replace('<svg', `<g transform="translate(20, ${top})" `);
    svg = svg.replace('</svg>', '</g>');
    top += svgHeight;
    width = Math.max(width, svgWidth, 1920);
    svgArr.push(svg);
  }

  charts.forEach(chart => addSVG(chart.getSVG({ chart: { width: 1880 } })));

  return `<svg height="${top + 20}" width="${width}" version="1.1" xmlns="http://www.w3.org/2000/svg">${svgArr.join('')}</svg>`;
};

export const exportCharts = (
  charts: Highcharts.Chart[],
  options: { type: ExportingMimeTypeValue; filename: string; title: string; infoSetup: { name: string; value: string | number }[] },
) => {
  const mergedOptions = Highcharts.merge(Highcharts.getOptions().exporting, options);

  (Highcharts as any).post(mergedOptions.url, {
    filename: options.filename || `charts-${Date.now()}`,
    type: mergedOptions.type,
    width: mergedOptions.width,
    svg: getSVG(charts, options.title, options.infoSetup),
  });
};
