import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import { addIdsAndViewModeToTemplates, getAutoType, strictlyEqual } from '@app/v2/shared/helpers';
import { CardsViewMode, DataTestIds, VMSTemplatesTypes } from '@app/v2/shared/enums';
import { getScrollStyles } from '@theme/themeSettings/componentSettings';
import CSDTPICard from './CSDTPICard';

interface Props {
  playlist: Scoreboards.PlaylistModel;
  onUpdate: Common.VoidCallBack;
  type?: VMSTemplatesTypes;
  updatePlaylist: FC<Scoreboards.UpdatePlaylistProps>;
  updateTemplateContent: FC<Scoreboards.UpdateTemplateContentProps>;
  deleteTemplateFrom: FC<Scoreboards.DeleteTemplateFromProps>;
}

const CSDTPICards = ({ playlist, onUpdate, type, ...cardFeatures }: Props) => {
  const classes = useStyles();

  const { preparedTemplates } = useMemo(() => {
    const { playlist: templates = [] } = playlist;

    return {
      preparedTemplates: addIdsAndViewModeToTemplates(templates),
    };
  }, [playlist]);

  const [localTemplates, setLocalTemplates] = useState<Scoreboards.TemplateWithModeAndId[]>(preparedTemplates);

  useEffect(() => setLocalTemplates(preparedTemplates), [preparedTemplates]);

  const onChangeActiveStatus = useCallback(
    (mode: CardsViewMode, templateId?: number) => {
      if (strictlyEqual(mode, CardsViewMode.View) && !templateId) {
        setLocalTemplates(preparedTemplates);
      } else {
        setLocalTemplates(prevLocalTemplates =>
          prevLocalTemplates.map(template => {
            if (strictlyEqual(template.id, templateId)) return { ...template, mode: CardsViewMode.Edit };
            return { ...template, mode: CardsViewMode.View };
          }),
        );
      }
    },
    [preparedTemplates],
  );

  return (
    <Box
      className={classes.cardsContainer}
      sx={{
        paddingRight: localTemplates[localTemplates.length - 1]?.mode === CardsViewMode.Edit ? '2.5rem' : '0.5rem',
      }}
      data-testid={DataTestIds.VMSCards}
    >
      {!!localTemplates?.length &&
        localTemplates.map(template => (
          <CSDTPICard
            key={template.id}
            type={type ?? getAutoType(template)}
            template={template}
            playlist={playlist}
            onUpdate={onUpdate}
            onChangeActiveCard={() => {}}
            onChangeActiveStatus={onChangeActiveStatus}
            {...cardFeatures}
          />
        ))}
    </Box>
  );
};

const useStyles = makeStyles<Theme>(({ palette }) => ({
  cardsContainer: {
    gap: '0.5rem',
    display: 'flex',
    overflow: 'auto',
    padding: '0.5rem',
    flexDirection: 'row',
    justifyContent: 'space-between',
    ...getScrollStyles(palette.mode, palette.text),
  },
}));

export default memo(CSDTPICards);
