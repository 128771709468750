export enum FiltersKeys {
  Organizations = 'organizations',
  Places = 'places',
  Roads = 'roads',
  AlertStatus = 'alertStatus',
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
  IsRoadcast = 'isRoadcast',
  IsFullData = 'isFullData',
  PlaylistTitle = 'playlistTitle',
  PlaylistHeight = 'playlistHeight',
  PlaylistWidth = 'playlistWidth',
  OrganizationType = 'organizationType',
  IsLocator = 'isLocator',
  Reaction = 'reaction',
}

export enum FiltersTypes {
  Array = 'array',
  Sting = 'sting',
  Boolean = 'boolean',
}

export enum FiltersAlertStatus {
  Alert,
  Warning,
  Notice,
}

export enum ViewMode {
  Default = 'default',
  Create = 'create',
}

export enum PresetsBtnsView {
  Default = 'default',
  Swiper = 'swiper',
}

export enum FiltersEvents {
  Apply,
  Reset,
}

export enum FiltersReactions {
  None = 'none',
  All = 'yes',
  Accept = 'accept',
  Reject = 'reject',
}
