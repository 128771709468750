import React, { ReactNode, useState } from 'react';
import { Badge, Box, Button, Icon, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import icons from '@app/assets/iconFont';
import {
  useCurrentPage,
  useFiltersDictionary,
  useLayoutConfig,
  useNewFilters,
  useNewUserSetsManager,
  useNewUserSetsViewManager,
  usePopover,
} from '@app/v2/shared/hooks';
import { typedMemo } from '@app/v2/shared/utils';
import { DataTestIds, FiltersEvents } from '@app/v2/shared/enums';
import { AmountCounters, anchorOrigin, paperProps, transformOrigin } from '@app/v2/shared/constants';
import { useFilterContext } from '@app/v2/shared/contexts';
import CSDFiltersDefaultActions from './CSDFiltersDefaultActions';
import CSDFilterSection from './CSDFilterSection';
import AdditionalFilters from '../additionalFilters';

interface Props {
  userPresets: ReactNode;
}

const CSDFilters = ({ userPresets }: Props) => {
  const {
    filterConfig: { isViewPresets },
  } = useLayoutConfig();

  const [event, setEvent] = useState<FiltersEvents | null>(null);

  const eventsSubscribers: Partial<Record<FiltersEvents, Common.AnyFunction>> = {
    [FiltersEvents.Apply]: () => {
      setFilters(filters);
      setFiltersToURL(filters);
    },
  };

  const classes = useStyles({ isViewPresets });

  const { anchorEl, isOpen, openPopover, closePopover } = usePopover();

  const { isDictionaryLoading, filterDictionary } = useFiltersDictionary();
  const { filters, filtersValues, setFilter } = useFilterContext();
  const { filtersAmount, resetFilters, setFilters, setFiltersToURL } = useNewFilters();
  const { isDefaultMode } = useNewUserSetsViewManager();
  const { activeUserSet } = useNewUserSetsManager();

  const currentPage = useCurrentPage();
  const AdditionalComponent = AdditionalFilters[currentPage?.name];

  return (
    <>
      <Badge badgeContent={filtersAmount} color="primary" data-testid={DataTestIds.FilterButtonBadge}>
        <Button className={classes.btn} variant="outlined" onClick={openPopover} data-testid={DataTestIds.OpenFilterButton}>
          <Icon>{icons.filter}</Icon>
        </Button>
      </Badge>

      <Popover
        open={isOpen}
        closeAfterTransition
        anchorEl={anchorEl}
        onTransitionExited={() => {
          if (eventsSubscribers[event]) eventsSubscribers[event]();
          setEvent(null);
        }}
        onClose={closePopover}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        slotProps={{ paper: { style: paperProps } }}
        marginThreshold={0}
      >
        <Box className={classes.wrapper}>
          <Box className="filtersHeader">
            {isViewPresets && userPresets}

            {isDefaultMode && (
              <Box className="actionsBtns">
                <CSDFiltersDefaultActions
                  hiddenReset={!!activeUserSet}
                  disabledReset={!Object.values(filters).filter(({ type, value }) => AmountCounters[type](value)).length}
                  onReset={resetFilters}
                  onApply={() => {
                    setEvent(FiltersEvents.Apply);
                    closePopover();
                  }}
                />
              </Box>
            )}
          </Box>

          <CSDFilterSection
            dictionaries={filterDictionary}
            isDictionaryLoading={isDictionaryLoading}
            contextFilters={filters}
            setFilter={setFilter}
          />

          {!!AdditionalComponent && <AdditionalComponent filters={filtersValues} onChange={setFilter} currentPage={currentPage} />}
        </Box>
      </Popover>
    </>
  );
};

const useStyles = makeStyles<Theme, { isViewPresets: boolean }>({
  btn: {
    '&.MuiButton-root': {
      minWidth: '0px',
    },
    '& .custom-icon': {
      fontSize: '1.3rem',
    },
  },
  wrapper: {
    display: 'flex',
    padding: '1.5rem',
    flexDirection: 'column',
    gap: '1.5rem',

    '& .filtersHeader': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: ({ isViewPresets }) => (isViewPresets ? 'space-between' : 'flex-end'),
      gap: '0.5rem',
    },

    '& .filterSection': {
      gap: '1.5rem',

      '& > div': {
        flexBasis: '100%',
      },
    },

    '& .actionsBtns': {
      alignSelf: 'flex-start',
      display: 'flex',
      gap: '0.5rem',
    },
  },
});

export default typedMemo(CSDFilters);
