import React from 'react';
import { Box, Icon, TextField, Typography, Stack } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';

type Props = {
  label: string;
  icon?: string;
  hideLabel?: boolean;
} & Omit<TextFieldProps, 'variant'>;

const CSDSelectsStandard = ({ label, icon, hideLabel, ...textFieldProps }: Props) => {
  return (
    <Box>
      {!hideLabel && (
        <Typography
          sx={{
            fontSize: '0.875rem',
            lineHeight: '0.875rem',
            color: ({ palette }) => (textFieldProps?.disabled ? palette.text.t3 : palette.text.t1),
            marginBottom: ({ spacing }) => spacing(1),
          }}
          variant="subtitle2"
        >
          {label}
        </Typography>
      )}
      <Stack direction="row" spacing={1}>
        {icon && <Icon>{icon}</Icon>}
        <TextField defaultValue="2-я бауманская д.5" select variant="standard" {...textFieldProps} />
      </Stack>
    </Box>
  );
};

export default CSDSelectsStandard;
