import { Observable, Subject } from 'rxjs';
import BaseManager from '@app/core/source/BaseManager';

// eslint-disable-next-line import/prefer-default-export
export abstract class BaseFiltersManager<DefaultFilters> extends BaseManager<DefaultFilters> {
  public changeSubject = new Subject<number>();

  public step = 0;

  abstract change: Observable<number>;

  constructor() {
    super(null);
  }

  next(data: Filters.Values & DefaultFilters) {
    super.next(data);

    this.changeSubject.next(this.step);
    this.step += 1;
  }
}
