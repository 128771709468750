import icons from '@app/assets/iconFont';
import { FooterTabs, UsersAccess } from '@app/v2/shared/enums';
import { TabItem } from '../types/TabItem';
import MeteoPanel from '../ui/TabPanels/MeteoPanel';
import CSDTableDataSetupPanel from '../ui/TabPanels/CSDTableDataSetupPanel/CSDTableDataSetupPanel';
import WeatherPanel from '../ui/TabPanels/WeatherPanel';
import MeteoLocatorsPanel from '../ui/TabPanels/MeteoLocatorsPanel';
import MapSymbolsPanel from '../ui/TabPanels/MapSymbolsPanel';
import NotesPanel from '../ui/TabPanels/NotesPanel';
import CSDVideoWallSettingsPanel from '../ui/TabPanels/SettingsPanel/containers/CSDVideoWallSettingsPanel';
import CSDWaterLevelControlSettingsPanel from '../ui/TabPanels/SettingsPanel/containers/CSDWaterLevelControlSettingsPanel';

const tabList: TabItem[] = [
  { name: FooterTabs.Meteo, icon: icons.data, Panel: MeteoPanel, hasPreview: true },
  { name: FooterTabs.Weather, icon: icons.play, Panel: WeatherPanel, hasPreview: true },
  { name: FooterTabs.MeteoLocators, icon: icons.radar, Panel: MeteoLocatorsPanel, hasPreview: true },
  { name: FooterTabs.MapSymbols, icon: icons.map, Panel: MapSymbolsPanel, hasPreview: true },
  { name: FooterTabs.Notes, icon: icons.comment, Panel: NotesPanel, hasPreview: true, permissions: [UsersAccess.PAGE_NOTES] },
  { name: FooterTabs.VideoWallSettings, icon: icons.gear, Panel: CSDVideoWallSettingsPanel },
  { name: FooterTabs.WaterLevelControlSettings, icon: icons.gear, Panel: CSDWaterLevelControlSettingsPanel },
  { name: FooterTabs.TableDataSetup, icon: icons.data, Panel: CSDTableDataSetupPanel, hasPreview: true },
];

export default tabList;
