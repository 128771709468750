import FETCH_LOCATORS from './fetchLocators.graphql';
import FETCH_LOCATOR_IMAGES from './fetchLocatorImages.graphql';
import FETCH_LOCATORS_TIMES_UPDATE from './fetchLocatorsTimesUpdate.graphql';

const locatorQueries = {
  locators: FETCH_LOCATORS,
  locatorsTimesUpdate: FETCH_LOCATORS_TIMES_UPDATE,
  locatorImages: FETCH_LOCATOR_IMAGES,
};

export default locatorQueries;
