import icons from '@app/assets/iconFont';
import { AutoPaginationPeriodsInSeconds } from '@app/v2/shared/enums';

export const BASE_ROWS = 3;
export const BASE_COLS = 4;

export const MODE = {
  rows: 'rows',
  cols: 'cols',
  both: 'both',
};

export const DEFAULT_FONT_SIZE = 16;
export const DEFAULT_LINKS_SIZE = 32;
export const DEFAULT_AUTO_PAGINATION = AutoPaginationPeriodsInSeconds.fifteen;

export const VIDEO_WALL_DEFAULT_STATE = {
  rows: BASE_ROWS,
  cols: BASE_COLS,
  mode: MODE.rows,
  isShowAltNames: false,
  isWithoutExpired: false,
  isShowMeteodata: true,
  isShowLinks: true,
  fontSize: DEFAULT_FONT_SIZE,
  linksSize: DEFAULT_LINKS_SIZE,
  autoPagination: DEFAULT_AUTO_PAGINATION,
};

export const TRENDS_INFO = {
  tAir: {
    label: 'tAir',
    shortLabel: 'tAirShort',
    icon: icons.tempAir,
  },
  tUnderroad: {
    label: 'tUnderroad',
    shortLabel: 'tUnderroadShort',
    icon: icons.tempBelowSurface,
  },
  tUnderroad2: {
    label: 'tUnderroad2',
    shortLabel: 'tUnderroad2Short',
    icon: icons.tempBelowSurface,
  },
  tRoad: {
    label: 'tRoad',
    shortLabel: 'tRoadShort',
    icon: icons.tempSurface,
  },
  tRoad2: {
    label: 'tRoad2',
    shortLabel: 'tRoad2Short',
    icon: icons.tempBelowSurface,
  },
  tDewPoint: {
    label: 'tDewPoint',
    shortLabel: 'tDewPointShort',
    icon: icons.tempDewPoint,
  },

  precipitationIntensity: {
    label: 'precipitationIntensity',
    shortLabel: 'precipitationIntensityShort',
    icon: icons.precipitationIntensity,
  },
  precipitationInstant: {
    label: 'precipitationInstant',
    icon: icons.precipitationIntensity,
  },
  precipitationType: {
    label: 'precipitationType',
    icon: icons.typeRain,
  },
  waterHeight: {
    label: 'waterHeight',
    shortLabel: 'waterHeightShort',
    icon: icons.layerThickness,
  },
  statusDamp: {
    label: 'statusDamp',
    shortLabel: 'statusDampShort',
    icon: icons.surfaceCondition,
  },
  statusDamp2: {
    label: 'statusDamp2',
    icon: icons.surfaceCondition,
  },

  visibility: {
    label: 'visibility',
    icon: icons.visibility,
  },
  pressure: {
    label: 'pressure',
    shortLabel: 'pressureShort',
    icon: icons.pressure,
  },
  humidity: {
    label: 'humidity',
    icon: icons.humidOne,
  },
  cloudiness: {
    label: 'cloudiness',
    shortLabel: 'cloudinessShort',
    icon: icons.sky,
  },

  windSpeed: {
    label: 'windSpeed',
    icon: icons.windSpeed,
  },
  windGusts: {
    label: 'windGusts',
    icon: icons.windDirection,
  },
  windDirection: {
    label: 'windDirection',
    icon: icons.windGusts,
  },
  windDirGr: {
    label: 'windDirGr',
    icon: icons.windDirection,
  },

  tFreezingPoint: {
    label: 'tFreezingPoint',
    shortLabel: 'tFreezingPointShort',
    icon: icons.tempFreezing,
  },
  tFreezingPoint2: {
    label: 'tFreezingPoint2',
    icon: icons.tempFreezing,
  },
  salineConcentration: {
    label: 'salineConcentration',
    icon: icons.tempFreezing,
  },
  salineConcentration2: {
    label: 'salineConcentration2',
    icon: icons.tempFreezing,
  },
  trCars: {
    label: 'trCars',
    shortLabel: 'trCarsShort',
    icon: icons.passenger,
  },
  trSmallTrucks: {
    label: 'trSmallTrucks',
    shortLabel: 'trSmallTrucksShort',
    icon: icons.smallCargo,
  },
  trTrucks: {
    label: 'trTrucks',
    shortLabel: 'trTrucksShort',
    icon: icons.cargo,
  },
  trBigTrucks: {
    label: 'trBigTrucks',
    shortLabel: 'trBigTrucksShort',
    icon: icons.bigCargo,
  },
  trRoadTrains: {
    label: 'trRoadTrains',
    shortLabel: 'trRoadTrainsShort',
    icon: icons.roadTrain,
  },
  trBuses: {
    label: 'trBuses',
    icon: icons.bus,
  },
  trMotorcycles: {
    label: 'trMotorcycles',
    icon: icons.motorbike,
  },

  trTotalVehicles: {
    label: 'trTotalVehicles',
    shortLabel: 'trTotalVehiclesShort',
    icon: icons.traffic,
  },
  trSpeed: {
    label: 'trSpeed',
    icon: icons.speed,
  },
  trOccupancy: {
    label: 'trOccupancy',
    icon: icons.workload,
  },
  trGap: {
    label: 'trGap',
    icon: icons.distance,
  },
  dpgT01: {
    label: 'dpgT01',
    icon: icons.passenger,
  },
  dpgT02: {
    label: 'dpgT02',
    icon: icons.passenger,
  },
  dpgT03: {
    label: 'dpgT03',
    icon: icons.passenger,
  },
  dpgT04: {
    label: 'dpgT04',
    icon: icons.passenger,
  },
  dpgT05: {
    label: 'dpgT05',
    icon: icons.passenger,
  },
  dpgT06: {
    label: 'dpgT06',
    icon: icons.passenger,
  },
  dpgT07: {
    label: 'dpgT07',
    icon: icons.passenger,
  },
  dpgT08: {
    label: 'dpgT08',
    icon: icons.passenger,
  },
  dpgT09: {
    label: 'dpgT09',
    icon: icons.passenger,
  },
  dpgT10: {
    label: 'dpgT10',
    icon: icons.passenger,
  },
  dpgT11: {
    label: 'dpgT11',
    icon: icons.passenger,
  },
  dpgT12: {
    label: 'dpgT12',
    icon: icons.passenger,
  },
  dpgT13: {
    label: 'dpgT13',
    icon: icons.passenger,
  },
  dpgT14: {
    label: 'dpgT14',
    icon: icons.passenger,
  },
  dpgT15: {
    label: 'dpgT15',
    icon: icons.passenger,
  },
};

export const DEFAULT_METEODATA_ROUNDED_SIGN = 1;

export const MAX_COLS_FOR_FULL_TAB_LABEL = 5;
