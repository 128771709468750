import React, { FocusEvent, KeyboardEvent, memo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, BoxProps, Icon, InputAdornment, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormikContext } from 'formik';
import { CSDInput } from '@app/modules/kit-module/shared/ui';
import { KeyCodes, PublicPath } from '@app/v2/shared/enums';
import icons from '@app/assets/iconFont';

const InputFields = ({ t }: I18N.TranslationFunction<'auth', 'login.cardContent'>) => {
  const [isVisiblePassword, setIsVisiblePassword] = useState<boolean>(false);
  const [isInputFilled, setIsInputFilled] = useState<Record<keyof Auth.AuthParameters, boolean>>({
    login: false,
    password: false,
  });
  const { push: redirect } = useHistory();

  const { values, handleChange, touched, handleBlur, errors, handleSubmit } = useFormikContext<Auth.AuthParameters>();

  const onKeyPress = ({ keyCode }: KeyboardEvent<HTMLDivElement>): void => {
    if (keyCode === KeyCodes.Enter) {
      handleSubmit();
    }
  };

  const handleChangePasswordVisibility = () => {
    setIsVisiblePassword(prev => !prev);
  };

  const changeChangeFill = (key: keyof Auth.AuthParameters, value: boolean): void => {
    setIsInputFilled(prev => ({ ...prev, [key]: value }));
  };

  const handleOnBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>, key: keyof Auth.AuthParameters) => {
    handleBlur(event);

    if (!values[key]) {
      changeChangeFill(key, false);
    }
  };

  const handleForgotPassword = () => {
    redirect(PublicPath.Recovery);
  };

  return (
    <>
      <CSDInput
        name="login"
        value={values.login}
        onChange={handleChange}
        onFocus={() => {
          changeChangeFill('login', true);
        }}
        onBlur={event => {
          handleOnBlur(event, 'login');
        }}
        label={isInputFilled.login ? t('cardLoginField') : null}
        helperText={touched.login ? errors.login : ''}
        error={touched.login && Boolean(errors.login)}
        placeholder={t('cardLoginField')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon>{icons.mail}</Icon>
            </InputAdornment>
          ),
        }}
        onKeyPress={onKeyPress}
      />
      <StyledBox>
        <CSDInput
          name="password"
          value={values.password}
          onChange={handleChange}
          onFocus={() => {
            changeChangeFill('password', true);
          }}
          onBlur={event => {
            handleOnBlur(event, 'password');
          }}
          label={isInputFilled.password ? t('cardPasswordField') : null}
          helperText={touched.password ? errors.password : ''}
          error={touched.password && Boolean(errors.password)}
          placeholder={t('cardPasswordField')}
          type={isVisiblePassword ? 'text' : 'password'}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon>{icons.key}</Icon>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                <Icon sx={{ cursor: 'pointer' }} onClick={handleChangePasswordVisibility}>
                  {icons.eye}
                </Icon>
              </InputAdornment>
            ),
          }}
          onKeyPress={onKeyPress}
        />
        <StyledTechSupportTypography align="center" variant="body2" onClick={handleForgotPassword}>
          {t('forgotPassword')}
        </StyledTechSupportTypography>
      </StyledBox>
    </>
  );
};

const StyledTechSupportTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.text.t2,
  textAlign: 'center',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const StyledBox = styled(Box)<BoxProps>({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
});

export default withTranslation('auth', { keyPrefix: 'login.cardContent' })(memo(InputFields));
