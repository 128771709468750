import React, { useState, useRef, useEffect } from 'react';
import { Tooltip } from '@mui/material';

type Props = {
  text: string;
  className?: string;
  placement?: any;
};

const EllipsisTooltip = ({ text, className, placement }: Props) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const textRef = useRef(null);
  useEffect(() => {
    const checkOverflow = () => {
      const { current } = textRef;
      if (current) {
        const isOverflowing = current.offsetWidth < current.scrollWidth || current.offsetHeight < current.scrollHeight;
        setIsTooltipVisible(isOverflowing);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => window.removeEventListener('resize', checkOverflow);
  }, [text]);

  return (
    <Tooltip title={isTooltipVisible ? text : ''} placement={placement}>
      <div ref={textRef} className={className}>
        {text}
      </div>
    </Tooltip>
  );
};

export default EllipsisTooltip;
