import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useFormikContext } from 'formik';
import TemplateMessageFormRenderless from '@app/modules/scoreboard-module/templates/form/TemplateMessageFormRenderless';
import TemplateSchema from '@app/modules/scoreboard-module/templates/data/Schema';
import useDialog from '@app/core/hooks/useDialog';
import CreateTemplatesDialog from '@app/modules/scoreboard-module/templates/dialogs/CreateTemplatesDialog';
import values from '@app/modules/scoreboard-module/templates/form/values';
import { formInitialValue } from '@app/modules/scoreboard-module/playlists/form/values';
import vmsNewMutations from '@app/clients/apollo/requests/mutations/vmsNew';

const CreateTemplateButton = () => {
  const openCreateTemplatesDialog = useDialog(CreateTemplatesDialog);
  const { t } = useTranslation('scoreboard');

  const {
    values: { playlist },
    setFieldValue,
    getFieldProps,
  } = useFormikContext<Scoreboards.PlaylistModel>();

  const { value: configurationValue } = getFieldProps('playlist[0].template.configuration.id');

  const formValues = useMemo(
    () => ({
      ...values,
      content: {
        ...values.content,
        configuration: { ...values.content.configuration, id: configurationValue },
      },
    }),
    [configurationValue],
  );

  const onAdd = useCallback(() => {
    openCreateTemplatesDialog({
      dialogData: {
        component: TemplateMessageFormRenderless,
        values: formValues,
        schema: TemplateSchema,
        title: t('templates.actions.create'),
        mutation: vmsNewMutations.template.create,
      },
    }).beforeClose.subscribe(data => {
      if (data) {
        setFieldValue(`playlist[${playlist.length}]`, {
          ...formInitialValue,
          template: {
            ...formInitialValue.template,
            id: data,
            configuration: { ...formInitialValue.template.configuration, id: configurationValue },
          },
        });
      }
    });
  }, [configurationValue, formValues, openCreateTemplatesDialog, playlist.length, setFieldValue, t]);

  return (
    <Button variant="outlined" color="primary" onClick={onAdd}>
      {t('templates.actions.create')}
    </Button>
  );
};

export default CreateTemplateButton;
