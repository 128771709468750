import { useCallback } from 'react';
import { Moment } from 'moment';
import useActions from '../reactRedux/useActions';
import useAppSelector from '../reactRedux/useAppSelector';

interface UseWaterLevelChartChartReturn {
  dateFrom: Moment;
  dateTo: Moment;
  chartsData: WLC.ChartPointParameters;
  handleSetDateFrom: (value: Moment) => void;
  handleSetDateTo: (value: Moment) => void;
  handleSetIsFixed: (value: boolean) => void;
  handleSetParameters: (value: WLC.ChartPointParameters) => void;
  handleSetInitDates: Common.VoidCallBack;
}

export default function useWaterLevelChart(): UseWaterLevelChartChartReturn {
  const dateFrom = useAppSelector(state => state.waterLevelChart.dateFrom);
  const dateTo = useAppSelector(state => state.waterLevelChart.dateTo);
  const chartsData = useAppSelector(state => state.waterLevelChart.chartsData);

  const { setWaterLevelDateFrom, setWaterLevelDateTo, setWaterLevelIsFixed, setWaterLevelChartsParameters, setWaterLevelInitialDates } = useActions();

  const handleSetDateFrom = useCallback(
    (value: Moment) => {
      setWaterLevelDateFrom({ value });
    },
    [setWaterLevelDateFrom],
  );

  const handleSetDateTo = useCallback(
    (value: Moment) => {
      setWaterLevelDateTo({ value });
    },
    [setWaterLevelDateTo],
  );

  const handleSetIsFixed = useCallback(
    (value: boolean) => {
      setWaterLevelIsFixed({ value });
    },
    [setWaterLevelIsFixed],
  );

  const handleSetParameters = useCallback(
    (value: WLC.ChartPointParameters) => {
      setWaterLevelChartsParameters(value);
    },
    [setWaterLevelChartsParameters],
  );

  const handleSetInitDates = useCallback(() => setWaterLevelInitialDates(), [setWaterLevelInitialDates]);

  return {
    dateFrom,
    dateTo,
    chartsData,
    handleSetDateFrom,
    handleSetDateTo,
    handleSetIsFixed,
    handleSetParameters,
    handleSetInitDates,
  };
}
