import * as React from 'react';
import { Typography } from '@mui/material';
import { BASE_COLUMN_WIDTH } from '@app/v2/shared/constants';
import CSDTableCell, { HighlightedCell } from '../CSDTableCells/CSDTableCell';

interface Props extends HighlightedCell {
  label: string;
}

const CSDTableAddressCell = ({ label, rowIndex, columnIndex }: Props) => {
  return (
    <CSDTableCell rowIndex={rowIndex} columnIndex={columnIndex} sx={{ width: BASE_COLUMN_WIDTH }}>
      <Typography sx={{ color: 'inherit' }} fontSize={12} textAlign="center">
        {label}
      </Typography>
    </CSDTableCell>
  );
};

export default CSDTableAddressCell;
