import React, { useState, useCallback, useEffect, useRef, ReactNode, useMemo, MouseEvent, memo } from 'react';
import { useLeaflet } from 'react-leaflet';
import clsx from 'clsx';
import { Box, Icon, Badge, IconButton } from '@mui/material';
import { DATE_PERIOD } from '@app/v2/shared/constants';
import icons from '@app/assets/iconFont';
import { AlertStatuses, DataTestIds, RoadObjectsTypes } from '@app/v2/shared/enums';
import { isFunction } from '@app/v2/shared/helpers';
import { useAppSelector, usePrevious } from '@app/v2/shared/hooks';
import useCustomMarkerContext from '@app/modules/map-module/components/map/markers/context/useCustomMarkerContext';
import useMarkerStyles from './useMarkerStyles';
import CSDMapMarkerItem from './CSDMapMarkerItem';
import { MarkerState } from './types';
import CSDMapMarkerPopover from '../CSDMapMarkerPopover';
import CSDMapMarkerBasic from '../CSDMapMarkerBasic/CSDMapMarkerBasic';
import CSDIconMarkerWithTooltip from '../CSDIconMarkerWithTooltip';
import { MARKER_Z_INDEX_OFFSET } from '../constants';

type Props = {
  mainIcon?: string;
  containerClassName?: string;
  setOfIcons: { icon: string; component?: ReactNode; key: RoadObjectsTypes; count?: number; isExpired?: boolean; onClick?: () => {} }[];
  alertStatus: AlertStatuses;
  collapsible?: boolean;
  openByDefault?: boolean;
  notifications?: {
    iconName: string;
    tooltip?: string;
  }[];
  badgeColor?: string;
  onCircleClick?: () => void;
};

const CSDMapMarkerNormal = ({
  mainIcon,
  containerClassName,
  collapsible = false,
  openByDefault = false,
  setOfIcons = [],
  notifications = [],
  onCircleClick,
  badgeColor,
  alertStatus,
}: Props) => {
  const timeout = useRef(null);
  const menuRef = useRef<HTMLDivElement>();
  const { map } = useLeaflet();

  const { latLng, setZIndexOffset } = useCustomMarkerContext();
  const currentRoadObjectController = useAppSelector(state => state.map.currentRoadObjectController);
  const prevCurrentRoadObjectController = usePrevious<RoadObjectsTypes>(currentRoadObjectController);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [currentMarkerType, setCurrentMarkerType] = useState<RoadObjectsTypes>(null);
  const [isOpen, setIsOpen] = useState<boolean>(openByDefault);
  const [markerSet, setMarkerSet] = useState<MarkerState>([]);
  const sectionDegree = useMemo(() => 360 / setOfIcons.length, [setOfIcons]);
  const setOfDegrees = useMemo(
    () => Array.from({ length: setOfIcons.length }, (_, index) => 360 - index * sectionDegree + sectionDegree / 2),
    [setOfIcons, sectionDegree],
  );
  const [currentMarkerPosition, setCurrentMarkerPosition] = useState<number>(setOfDegrees[0]);
  const isMarkerOnMap = useMemo(() => map.getBounds().contains(latLng), [map, latLng]);

  const classes = useMarkerStyles({ currentMarkerPosition, alertStatus, markersLength: setOfIcons.length, badgeColor });

  useEffect(() => {
    if (currentMarkerType && !isMarkerOnMap) {
      setCurrentMarkerType(null);
    }
  }, [currentMarkerType, isMarkerOnMap]);

  useEffect(() => {
    if (setOfIcons.length) {
      setMarkerSet(
        setOfIcons.map(({ icon, count, key, isExpired, onClick }, index) => ({ icon, key, degrees: setOfDegrees[index], count, isExpired, onClick })),
      );
    }

    return () => {
      timeout.current = null;
    };
  }, [setOfIcons, setOfDegrees]);

  const setMarkerZIndexOffsetByMarkerType = useCallback(
    (isExpanded: boolean = false) => {
      setZIndexOffset(isExpanded ? MARKER_Z_INDEX_OFFSET : 0);
    },
    [setZIndexOffset],
  );

  const handleRotateMarker = useCallback(
    (degrees: number, markerType: RoadObjectsTypes): void => {
      setAnchorEl(null);
      if (currentMarkerType === markerType && Boolean(anchorEl)) {
        setMarkerZIndexOffsetByMarkerType(false);
        return;
      }

      setCurrentMarkerType(prev => {
        if (prev !== markerType) setCurrentMarkerPosition(degrees);
        timeout.current = setTimeout(() => {
          setAnchorEl(menuRef.current);
        }, DATE_PERIOD.SECOND / 2);

        setMarkerZIndexOffsetByMarkerType(!!markerType);

        return markerType;
      });
    },
    [currentMarkerType, anchorEl, setMarkerZIndexOffsetByMarkerType],
  );

  useEffect(() => {
    if (prevCurrentRoadObjectController !== currentRoadObjectController && currentRoadObjectController !== currentMarkerType) {
      const markerData = markerSet.find(({ key }) => key === currentRoadObjectController);
      if (!markerData) {
        setCurrentMarkerType(null);
        return;
      }
      handleRotateMarker(markerData.degrees, markerData.key);
    }
  }, [isOpen, collapsible, markerSet, currentRoadObjectController, prevCurrentRoadObjectController, currentMarkerType, handleRotateMarker]);

  const handleToggleIsOpen = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (collapsible) {
      setAnchorEl(null);
      setIsOpen(prev => !prev);
    }
  };

  const handleCloseCircle = () => {
    setAnchorEl(null);
    setIsOpen(prev => !prev);
  };

  const handleCircleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (isFunction(onCircleClick)) {
      onCircleClick();
    }

    handleToggleIsOpen(e);
  };

  const popoverContent = useMemo(() => setOfIcons.find(({ key }) => key === currentMarkerType)?.component, [currentMarkerType, setOfIcons]);

  if (!setOfIcons.length) {
    return null;
  }

  return (
    <Box className={clsx(classes.wrapper, containerClassName)} ref={menuRef}>
      {collapsible && isOpen && (
        <IconButton className={classes.circleCloseBtn} onClick={handleCloseCircle}>
          <Icon>{icons.close}</Icon>
        </IconButton>
      )}
      {!collapsible || (collapsible && isOpen) ? (
        <>
          <ul className={classes.circle}>
            {markerSet.map(({ icon, key, degrees, count, isExpired, onClick }) => (
              <CSDMapMarkerItem
                key={key}
                classes={classes}
                icon={icon}
                itemMarkerKey={key}
                degrees={degrees}
                count={count}
                isExpired={isExpired}
                anchorEl={anchorEl}
                onClick={onClick}
                handleRotateMarker={handleRotateMarker}
                currentMarkerPosition={currentMarkerPosition}
                isExistPopoverContent={Boolean(popoverContent)}
              />
            ))}
          </ul>
          <Box className={classes.mainIconWrapper} onClick={handleCircleClick} data-testid={DataTestIds.MapMarkerNormalActionButton}>
            <Badge badgeContent={notifications.length > 1 ? notifications.length : null} className={classes.mainBadge}>
              <CSDIconMarkerWithTooltip
                className={classes.mainIcon}
                iconName={mainIcon}
                notifications={[
                  {
                    iconName: notifications[0]?.iconName,
                    tooltip: notifications?.map(({ tooltip }) => tooltip)?.join(', '),
                  },
                ]}
              />
            </Badge>
          </Box>
          <Icon className={classes.pointer}>{icons.pinTPIChoose}</Icon>
        </>
      ) : (
        <CSDMapMarkerBasic
          className={classes.pointer}
          iconName={mainIcon}
          notifications={notifications}
          badgeColor={badgeColor}
          onClick={handleToggleIsOpen}
          markerType={alertStatus}
        />
      )}

      {Boolean(anchorEl) && Boolean(popoverContent) && <CSDMapMarkerPopover anchorEl={anchorEl} component={popoverContent} />}
    </Box>
  );
};

export default memo(CSDMapMarkerNormal);
