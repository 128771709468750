import React, { ErrorInfo } from 'react';
import { withRouter } from 'react-router-dom';
import logger from '@app/core/logger/Logger';
import ErrorPage from '@app/v2/app/ui/errorPage/ErrorPage';

class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logger.registerError<ErrorInfo>({
      message: error.message,
      extra: errorInfo,
    });
  }

  handleTimeout = (redirectPath: string) => {
    const { history } = this.props;
    history.push(redirectPath);
    window.location.reload();
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorPage handleTimeout={this.handleTimeout} />;
    }

    return children;
  }
}

export default withRouter(ErrorBoundary);
