import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { delay } from 'lodash';
import { Box, Typography, Theme, Slider, Icon, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { VideoWallSettings, WaterLevelControlSettings } from '@store/slices/account/types';
import icons from '@app/assets/iconFont';
import { convertPxToRem } from '@app/v2/shared/helpers';
import useAngleSliderStyles from './useAngleSliderStyles';
import { SliderSettingsProp } from '../constants';

const MIN_FONT_SIZE = 12;
const MAX_FONT_SIZE = 36;

type Props = {
  settings: VideoWallSettings | WaterLevelControlSettings;
  onChangeSlider: (field: SliderSettingsProp, value: number | number[]) => void;
};

const CSDTypographySetting = ({ settings, onChangeSlider }: Props) => {
  const classes = useStyles({ fontSize: convertPxToRem(settings.fontSize) });
  const sliderStyles = useAngleSliderStyles();
  const { t: tooltipT } = useTranslation('video');
  const [isShowPreview, setIsShowPreview] = useState<boolean>(false);

  const handleChangeSlider = useCallback(
    (e: Event, value: number | number[]): void => {
      setIsShowPreview(true);
      onChangeSlider(SliderSettingsProp.FONT_SIZE, value);
    },
    [setIsShowPreview, onChangeSlider],
  );

  const handleChangeCommitted = useCallback(() => {
    delay(() => setIsShowPreview(false), 500);
  }, [setIsShowPreview]);

  return (
    <Box className={classes.typographyContainer}>
      <Typography className={classes.fontSizeValue}>{settings.fontSize}</Typography>
      <Slider
        sx={{ width: '11rem' }}
        className={sliderStyles.slider}
        value={settings.fontSize}
        min={MIN_FONT_SIZE}
        max={MAX_FONT_SIZE}
        step={1}
        valueLabelDisplay="off"
        valueLabelFormat={value => `${value}px`}
        onChange={handleChangeSlider}
        onChangeCommitted={handleChangeCommitted}
        name="fontSize"
      />
      <Tooltip
        open={isShowPreview}
        title={<Typography className={classes.fontSizePreview}>{tooltipT('tooltips.example')}</Typography>}
        onOpen={() => setIsShowPreview(true)}
        onClose={() => setIsShowPreview(false)}
        leaveDelay={500}
      >
        <Icon color="info">{icons.attention}</Icon>
      </Tooltip>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  typographyContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  fontSizePreview: {
    '&.MuiTypography-root': {
      ...theme.typography.h4,
      color: theme.palette.text.t6,
      fontSize: ({ fontSize }: any) => `${fontSize}rem`,
    },
  },
  fontSizeValue: {
    '&.MuiTypography-root': {
      ...theme.typography.h4,
    },
  },
}));

export default CSDTypographySetting;
