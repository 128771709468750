import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { ResponseSelectors } from '@app/modules/scoreboard-module/contexts/types';
import vmsNewQueries from '@app/clients/apollo/requests/queries/vmsNew';
import ConfigurationContext from './ConfigurationContext';

type Props = {
  children: JSX.Element;
};

const ConfigurationContextProvider = ({ children }: Props) => {
  const { data: configurationsSelectorsData, loading: loadingConfiguration } = useQuery(vmsNewQueries.configurationSelectors);

  const [configurationsSelectors, setConfigurationsSelectors] = useState<ResponseSelectors>([]);

  const loading = useMemo(() => loadingConfiguration, [loadingConfiguration]);

  useEffect(() => {
    if (!loading) {
      setConfigurationsSelectors(configurationsSelectorsData.vmsNew.selectors.configurations.configurations);
    }
  }, [configurationsSelectorsData, loading]);

  return <ConfigurationContext.Provider value={{ configurationsSelectors }}>{children}</ConfigurationContext.Provider>;
};

export default ConfigurationContextProvider;
