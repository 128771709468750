import * as React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import CircularProgress from '@app/components/common/circular-progress/CircularProgress';

type Props = {
  loading: boolean;
  circularSize?: number;
  icon?: JSX.Element;
};

const ButtonLoadingIcon = ({ loading, circularSize = 18, icon = <CircleIcon /> }: Props): JSX.Element =>
  loading ? <CircularProgress size={circularSize} /> : icon;

export default ButtonLoadingIcon;
