import { EMPTY_STRING } from '@app/v2/shared/constants';
import { findCurrentVersion } from '@app/modules/federal-roads-module/utils/utils';

const getStatus = row => {
  let status;

  if (row.status) {
    status = row.status;
  } else if (row.versions) {
    status = row.versions.find(v => v.status === 'new') ? 'new' : findCurrentVersion(row.versions)?.status;
  }

  return status;
};

function createTableRowClassList(row, selected = false): string {
  const list: { [key: string]: string } = {
    alertStatus: row?.alertStatus ? String(row?.alertStatus) : EMPTY_STRING,
    unpaidStatus: row?.isUnpaid ? 'unpaid' : EMPTY_STRING,
    selected: selected ? 'selected' : EMPTY_STRING,
  };

  const status = getStatus(row);

  if (status === 'accept') {
    list.ok = 'success';
  }

  if (status === 'new') {
    list.warning = 'warning';
  }

  if (status === 'reject') {
    list.error = 'error';
  }

  return Object.entries(list)
    .map(([_, value]) => value)
    .join(' ');
}

export default createTableRowClassList;
