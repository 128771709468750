import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { DATE_FORMAT } from '@app/v2/shared/constants';
import { WEATHER_MAP_MAX_DATE, WEATHER_MAP_STEP_COUNT, WEATHER_MAP_STEP_DATE } from '@app/core/constants/map';
import useSliderValue from '@app/modules/map-module/hooks/useSliderValue';
import MapCardControlTabs from '@app/modules/map-module/enums/MapCardControlTabs';
import { useAppSelector } from '@app/v2/shared/hooks';
import CSDSlider from '../../components/Slider';

interface Mark {
  value: number;
  label?: React.ReactNode;
}

const WeatherSlide = () => {
  const currentDate = useMemo(() => moment().startOf('hour').unix(), []);
  const maxDate = currentDate + WEATHER_MAP_MAX_DATE;

  const [currentSliderValue, setCurrentSliderValue] = useSliderValue();

  const onChange = useCallback(newValue => setCurrentSliderValue(newValue), [setCurrentSliderValue]);
  const valueDate = useMemo<number>(() => currentSliderValue || currentDate, [currentDate, currentSliderValue]);

  const [sliderValue, setSliderValue] = useState<number>(valueDate);
  const [dateRange, setDateRange] = useState<Mark[]>([]);

  const currentTab = useAppSelector(({ map }) => map.currentTab);
  const activeWeatherType = useAppSelector(({ map }) => map.weather.activeWeatherType);

  const isActive = useMemo<boolean>(() => !!activeWeatherType, [activeWeatherType]);
  const isCurrentTab = useMemo<boolean>(() => Boolean(currentTab === MapCardControlTabs.Weather), [currentTab]);

  const MarkLabelComponent = ({ dateString }: { dateString: string }) => {
    const [date, year] = dateString.split(' ');

    return (
      <>
        <span>{date}</span>
        <br />
        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>{year}</span>
      </>
    );
  };

  useEffect(() => {
    setSliderValue(valueDate);
  }, [valueDate, setSliderValue]);

  useLayoutEffect(() => {
    const getDateRangeArray = (): Mark[] => {
      let dateFrom: number = currentDate;
      const dateRangeArr: Mark[] = [];

      while (dateFrom <= maxDate) {
        const currentDateFromHour = moment(dateFrom, 'X').get('hour');

        const label =
          currentDateFromHour < WEATHER_MAP_STEP_COUNT ? (
            <MarkLabelComponent dateString={moment(dateFrom, 'X').format(DATE_FORMAT.FORMAT_RU_POINT_WHITESPACE)} />
          ) : undefined;

        dateRangeArr.push({
          value: dateFrom,
          label,
        });
        dateFrom = moment(dateFrom, 'X').add(WEATHER_MAP_STEP_COUNT, 'hour').unix();
      }
      return dateRangeArr;
    };

    const dateRangeArr = getDateRangeArray();
    setDateRange(dateRangeArr);
  }, [currentDate, isCurrentTab, maxDate, activeWeatherType]);

  return (
    <CSDSlider
      aria-label="Custom marks"
      defaultValue={currentDate}
      valueLabelFormat={d => moment(d, 'X').format(DATE_FORMAT.FORMAT_RU_HOURS)}
      value={sliderValue}
      step={WEATHER_MAP_STEP_DATE}
      onChange={(e, v) => setSliderValue(v as number)}
      onChangeCommitted={(e, v) => onChange(v as number)}
      valueLabelDisplay="auto"
      marks={dateRange}
      min={currentDate}
      max={maxDate}
      disabled={!isActive}
    />
  );
};

export default WeatherSlide;
