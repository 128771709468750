import { KeyPrefix } from 'react-i18next';
import icons from '@app/assets/iconFont';
import { ProfileMapSettingLabels, RoadObjectsTypes, UsersAccess } from '@app/v2/shared/enums';

export interface ObjectPosition {
  column: number;
  position: number;
}
interface RoadObjectData extends ObjectPosition {
  label: KeyPrefix<'map'>;
  key: RoadObjectsTypes;
  icon: string;
  listOfIncludes?: string[];
  perimeters?: UsersAccess[];
}

export interface MeteoTabItem {
  group: KeyPrefix<'map'>;
  key: ProfileMapSettingLabels;
  data: RoadObjectData[];
}

export const roadObjectsData: RoadObjectData[] = [
  {
    column: 2,
    position: 1,
    label: 'mapCardControl.roadObjectsData.meteoParamsFirst',
    key: RoadObjectsTypes.METEO_PARAMS_FIRST,
    icon: icons.rainfall,
    listOfIncludes: [
      'mapCardControl.roadObjectsData.precipitations',
      'mapCardControl.roadObjectsData.wind',
      'mapCardControl.roadObjectsData.statusDamp',
    ],
  },
  {
    column: 2,
    position: 2,
    label: 'mapCardControl.roadObjectsData.meteoParamsSecond',
    key: RoadObjectsTypes.METEO_PARAMS_SECOND,
    listOfIncludes: ['mapCardControl.roadObjectsData.temperature', 'mapCardControl.roadObjectsData.humidity'],
    icon: icons.temperature,
  },
  { column: 1, position: 2, label: 'mapCardControl.roadObjectsData.video', key: RoadObjectsTypes.VIDEO, icon: icons.photo },
  { column: 1, position: 1, label: 'mapCardControl.roadObjectsData.traffic', key: RoadObjectsTypes.TRAFFIC, icon: icons.car },
  {
    column: 1,
    position: 5,
    label: 'mapCardControl.roadObjectsData.signStations',
    key: RoadObjectsTypes.SIGN_STATIONS,
    icon: icons.zpi,
    perimeters: [UsersAccess.VMS_VIEW_ONLY, UsersAccess.VMS_LIMIT_EDIT, UsersAccess.VMS_FULL_EDIT],
  },
  {
    column: 1,
    position: 6,
    label: 'mapCardControl.roadObjectsData.messageStations',
    key: RoadObjectsTypes.MESSAGE_STATIONS,
    icon: icons.tpi,
    perimeters: [UsersAccess.VMS_VIEW_ONLY, UsersAccess.VMS_LIMIT_EDIT, UsersAccess.VMS_FULL_EDIT],
  },
];

const meteoTabConfiguration: MeteoTabItem[] = [
  {
    group: 'mapCardControl.tabs.roadObjects',
    key: ProfileMapSettingLabels.ROAD_OBJECTS,
    data: roadObjectsData,
  },
];

export const prepareDataWithColumnPosition = <T extends ObjectPosition>(data: T[]): [string, T[]][] =>
  Object.entries(
    data.reduce((res, item) => {
      res[item.column] = res[item.column] ? [...res[item.column], item].sort((a, b) => a.position - b.position) : [item];
      return res;
    }, {}),
  );

export default meteoTabConfiguration;
