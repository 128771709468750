import React from 'react';
import { Avatar, Stack, AvatarGroup } from '@mui/material';
import { deepOrange, deepPurple, green, pink } from '@mui/material/colors';
import FolderIcon from '@mui/icons-material/Folder';
import PageviewIcon from '@mui/icons-material/Pageview';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { avatarsImages, letterAvatars, sizesAvatars, iconAvatars, avatarVariants, avatarGroups, avatarGroupsWithTotal } from './example';
import CSDKitDocBlock from '../../../shared/ui/CSDKitDocBlock/CSDKitDocBlock';

const CSDAvatars = () => {
  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="Image avatars" codeExample={avatarsImages}>
        <Stack direction="row" spacing={2}>
          <Avatar alt="Remy Sharp" src="https://picsum.photos/200/300?random=1" />
          <Avatar alt="Travis Howard" src="https://picsum.photos/200/300?random=2" />
          <Avatar alt="Cindy Baker" src="https://picsum.photos/200/300?random=3" />
        </Stack>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Letter avatars" codeExample={letterAvatars}>
        <Stack direction="row" spacing={2}>
          <Avatar>H</Avatar>
          <Avatar sx={{ bgcolor: deepOrange[500] }}>N</Avatar>
          <Avatar sx={{ bgcolor: deepPurple[500] }}>OP</Avatar>
        </Stack>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Sizes" codeExample={sizesAvatars}>
        <Stack direction="row" spacing={2}>
          <Avatar src="https://picsum.photos/200/300?random=1" sx={{ bgcolor: deepOrange[500], width: 24, height: 24 }} />
          <Avatar src="https://picsum.photos/200/300?random=1" sx={{ bgcolor: deepOrange[500] }} />
          <Avatar src="https://picsum.photos/200/300?random=1" sx={{ bgcolor: deepOrange[500], width: 56, height: 56 }} />
        </Stack>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Icon avatars" codeExample={iconAvatars}>
        <Stack direction="row" spacing={2}>
          <Avatar>
            <FolderIcon />
          </Avatar>
          <Avatar sx={{ bgcolor: pink[500] }}>
            <PageviewIcon />
          </Avatar>
          <Avatar sx={{ bgcolor: green[500] }}>
            <AssignmentIcon />
          </Avatar>
        </Stack>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Variants" codeExample={avatarVariants}>
        <Stack direction="row" spacing={2}>
          <Avatar sx={{ bgcolor: deepOrange[500] }} variant="square">
            N
          </Avatar>
          <Avatar sx={{ bgcolor: green[500] }} variant="rounded">
            <AssignmentIcon />
          </Avatar>
        </Stack>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Avatars group" codeExample={avatarGroups}>
        <AvatarGroup max={4}>
          <Avatar alt="1 Test" src="https://picsum.photos/200/300?random=1" />
          <Avatar alt="2 Test" src="https://picsum.photos/200/300?random=2" />
          <Avatar alt="3 Test" src="https://picsum.photos/200/300?random=3" />
          <Avatar alt="4 Test" src="https://picsum.photos/200/300?random=4" />
          <Avatar alt="5 Test" src="https://picsum.photos/200/300?random=5" />
        </AvatarGroup>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Avatars group with total" codeExample={avatarGroupsWithTotal}>
        <AvatarGroup total={24}>
          <Avatar alt="1 Test" src="https://picsum.photos/200/300?random=1" />
          <Avatar alt="2 Test" src="https://picsum.photos/200/300?random=2" />
          <Avatar alt="3 Test" src="https://picsum.photos/200/300?random=3" />
          <Avatar alt="4 Test" src="https://picsum.photos/200/300?random=4" />
        </AvatarGroup>
      </CSDKitDocBlock>
    </Stack>
  );
};

export default CSDAvatars;
