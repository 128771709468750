import React, { Dispatch } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from '@mui/material';

interface Props extends I18N.TranslationFunction<'filters'> {
  userSet: Filters.Set;
  isLoading: boolean;
  setLoadingUserSet: Dispatch<number>;
  isActiveAndLocalIsEqual: boolean;
  handleUpdateUserSetStatus: (userSet: Filters.Set) => void;
}

const CSDPresetFiltersBtn = ({ userSet, isLoading, setLoadingUserSet, isActiveAndLocalIsEqual, handleUpdateUserSetStatus, t }: Props) => {
  const loadingLabel = userSet.isActive ? t('actions.deactivation') : t('actions.activation');

  return (
    <Button
      variant={userSet.isActive && isActiveAndLocalIsEqual ? 'contained' : 'outlined'}
      sx={{ border: ({ palette: { primary } }) => userSet.isActive && !isActiveAndLocalIsEqual && `2px solid ${primary.main}` }}
      onClick={() => {
        handleUpdateUserSetStatus(userSet);
        setLoadingUserSet(userSet.value);
      }}
    >
      {isLoading ? loadingLabel : userSet.label}
    </Button>
  );
};

export default withTranslation('filters')(CSDPresetFiltersBtn);
