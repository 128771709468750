import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import CSDTrafficChartDirections from './CSDTrafficChartDirections';

interface Props {
  data: Traffic.ChartDirections | undefined;
  loading: boolean;
}

const CSDTrafficChartDirectionsConnector = ({ data, loading }: Props) => {
  const classes = useStyles();

  return <Box className={classes.container}>{!loading && !!data && <CSDTrafficChartDirections data={data} />}</Box>;
};

const useStyles = makeStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
});

export default CSDTrafficChartDirectionsConnector;
