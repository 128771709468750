import React, { ChangeEvent, useState } from 'react';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Icon, IconButton, InputAdornment } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { makeStyles } from '@mui/styles';
import icons from '@app/assets/iconFont';
import { CSDInput } from '@app/modules/kit-module/shared/ui';
import { EMPTY_STRING } from '@app/v2/shared/constants';

interface Props extends I18N.TranslationFunction<'filters'> {
  onChange: (value: string) => void;
  onClear: Common.VoidCallBack;
}

const CSDRoadsSearchInput = ({ onChange, onClear, t }: Props) => {
  const classes = useStyles();

  const [value, setValue] = useState<string>(EMPTY_STRING);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);
    onChange(target.value);
  };

  const handleClear = () => {
    onClear();
    setValue(EMPTY_STRING);
  };

  return (
    <CSDInput
      label={t('searchByFoundRoads.label')}
      placeholder={t('searchByFoundRoads.placeholder')}
      autoFocus
      value={value}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon>{icons.search}</Icon>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disabled={!value}
              onClick={handleClear}
              className={clsx({
                [classes.empty]: !value,
                [classes.full]: !!value,
              })}
            >
              <Icon>{icons.close}</Icon>
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

const useStyles = makeStyles<Theme>(({ palette }) => ({
  empty: {
    cursor: 'default',
  },
  full: {
    cursor: 'pointer',
    '& span.MuiIcon-root.custom-icon': {
      color: palette.common.black,
    },
  },
}));

export default withTranslation('filters')(CSDRoadsSearchInput);
