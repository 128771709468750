/* eslint-disable max-classes-per-file */
import React from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Icon,
  IconButton,
  Paper,
  Stack,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import groupBy from 'lodash/groupBy';
import {
  CSDBadge as CSDOpenCloseBudges,
  CSDDataGrid,
  CSDDataGridTable,
  CSDGroupCell,
  CSDOpenCloseTableCell,
  CSDTable,
  CSDTableCell,
} from '@app/modules/kit-module/shared/ui';
import {
  basicTable,
  dataGridTableBacis,
  dataGridTableVirtualizedAndGrouping,
  dataGridVirtualized,
  errorCell,
  headerGrouping,
  openCloseCell,
  rowColors,
  stickyHeader,
  tableColumnsTooltip,
  virtualizedTable,
} from './example';
import CSDKitDocBlock from '../../../shared/ui/CSDKitDocBlock/CSDKitDocBlock';
import { CSDTableData, FlatAdapter, GroupAdapter } from '../../../shared/ui/CSDDataGrid';

const createData = (
  pin: string,
  time: string,
  traffic: string,
  passenger: string,
  smallCargo: string,
  cargo: string,
  bigCargo: string,
  roadTrain: string,
  bus: string,
  motobike: string,
  speed: string,
  workload: string,
  distance: string,
) => {
  return { pin, time, traffic, passenger, smallCargo, cargo, bigCargo, roadTrain, bus, motobike, speed, workload, distance };
};

const testColumnData = [
  {
    field: 'id',
    headerName: 'ID',
    cellProps: { colSpan: 1 },
  },
  {
    field: 'warnings',
    renderHead: <Icon>attention</Icon>,
    renderCell: (params, rowIndex, columnIndex) => (
      <CSDTableCell colSpan={1} align="center" key={`warnings-table-cell-${params.id}`} rowIndex={rowIndex} columnIndex={columnIndex}>
        <CSDOpenCloseBudges max={3}>
          {params.warnings.map(data => (
            <Icon key={`${data}-${params.id}-${rowIndex}-${columnIndex}`}>{data}</Icon>
          ))}
        </CSDOpenCloseBudges>
      </CSDTableCell>
    ),
    cellProps: { colSpan: 1 },
  },
  {
    field: 'coordinates',
    headerName: 'Coordinates',
    renderCell: (params, rowIndex, columnIndex) => (
      <CSDOpenCloseTableCell label={params.coordinates} key={`open-close-table-cell-${params.id}`} rowIndex={rowIndex} columnIndex={columnIndex}>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <IconButton color="inherit">
            <Icon>star-outline</Icon>
          </IconButton>
          <span>{params.coordinates}</span>
          <IconButton color="inherit">
            <Icon>camera</Icon>
          </IconButton>
          <IconButton color="inherit">
            <Icon>traffic</Icon>
          </IconButton>
          <IconButton color="inherit">
            <Icon>schedule</Icon>
          </IconButton>
        </Stack>
      </CSDOpenCloseTableCell>
    ),
    cellProps: { colSpan: 1 },
  },
  {
    field: 'traffic',
    renderHead: <Icon>traffic</Icon>,
    headerName: 'Traffic',
    cellProps: { colSpan: 1 },
  },
  {
    field: 'distance',
    headerName: 'Distance',
    cellProps: { colSpan: 1 },
  },
  {
    field: 'speed',
    renderHead: <Icon>speed</Icon>,
    headerName: 'Speed',
    cellProps: { colSpan: 1 },
  },
  {
    field: 'passenger',
    renderHead: <Icon>passenger</Icon>,
    headerName: 'Passenger',
    cellProps: { colSpan: 1 },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    renderCell: (params, rowIndex, columnIndex) => (
      <CSDTableCell key={`action-table-cell-${params.id}`} rowIndex={rowIndex} columnIndex={columnIndex}>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <IconButton color="inherit">
            <Icon>star-outline</Icon>
          </IconButton>
          <IconButton color="inherit">
            <Icon>calender</Icon>
          </IconButton>
        </Stack>
      </CSDTableCell>
    ),
    cellProps: { colSpan: 1 },
  },
];

interface ITestAdapterData extends CSDTableData {
  header: string;
  id: number | string;
  warnings: string[];
  coordinates: string;
  traffic: string;
  distance: string;
  speed: string;
  passenger: string;
}

const testGridData = Array.from({ length: 20 }, (_, index) => ({
  id: 5306 + index,
  road: { id: 22, title: 'М-10 "Россия"', titleFull: 'М-10 "Россия" Москва – Тверь – Великий Новгород – Санкт-Петербург' },
  snapshotUrl: 'https://picsum.photos/300/200?random=1',
  dateTime: '2023-03-14 13:43:04',
  isExpired: false,
  alertStatus: 'warning',
  isStream: false,
  isPlanGraph: true,
  snowCoverage: null,
}));

const testAdapterData: ITestAdapterData[] = [
  {
    header: 'Header 1',
    id: 1,
    warnings: ['slippery', 'wind', 'temperature-change'],
    coordinates: '700+123',
    traffic: '53 456',
    distance: '5.89',
    speed: '112',
    passenger: '3',
  },
  {
    header: 'Header 1',
    id: 2,
    warnings: ['slippery', 'slippery-2', 'liquid-precipitation'],
    coordinates: '700+123',
    traffic: '',
    distance: '5.89',
    speed: '112',
    passenger: '3',
  },
  { header: 'Header 1', id: 3, warnings: ['skid'], coordinates: '700+123', traffic: '53 456', distance: '5.89', speed: '112', passenger: '3' },
  {
    header: 'Header 1',
    id: 4,
    warnings: ['slippery', 'temperature-change', 'wind', 'mixed-rainfall', 'slippery-2', 'liquid-precipitation', 'skid'],
    coordinates: '700+123',
    traffic: '53 456',
    distance: undefined,
    speed: '112',
    passenger: '3',
  },
  {
    header: 'Header 1',
    id: 5,
    warnings: ['slippery-2', 'skid', 'temperature-change'],
    coordinates: '700+123',
    traffic: '53 456',
    distance: '5.89',
    speed: '112',
    passenger: '3',
  },
  {
    header: 'Header 1',
    id: 6,
    warnings: ['slippery', 'temperature-change', 'slippery-2', 'liquid-precipitation'],
    coordinates: '700+123',
    traffic: '53 456',
    distance: '5.89',
    speed: null,
    passenger: '3',
  },
  {
    header: 'Header 2',
    id: 7,
    warnings: ['slippery', 'wind', 'temperature-change'],
    coordinates: '700+123',
    traffic: '53 456',
    distance: '5.89',
    speed: '112',
    passenger: '3',
  },
  {
    header: 'Header 2',
    id: 8,
    warnings: ['slippery', 'slippery-2', 'liquid-precipitation'],
    coordinates: '700+123',
    traffic: '',
    distance: '5.89',
    speed: '112',
    passenger: '3',
  },
  { header: 'Header 2', id: 9, warnings: ['skid'], coordinates: '700+123', traffic: '53 456', distance: '5.89', speed: '112', passenger: '3' },
  {
    header: 'Header 2',
    id: 10,
    warnings: ['slippery', 'temperature-change', 'wind', 'mixed-rainfall', 'slippery-2', 'liquid-precipitation', 'skid'],
    coordinates: '700+123',
    traffic: '53 456',
    distance: undefined,
    speed: '112',
    passenger: '3',
  },
  {
    header: 'Header 2',
    id: 11,
    warnings: ['slippery-2', 'skid', 'temperature-change'],
    coordinates: '700+123',
    traffic: '53 456',
    distance: '5.89',
    speed: '112',
    passenger: '3',
  },
  {
    header: 'Header 2',
    id: 12,
    warnings: ['slippery', 'temperature-change', 'slippery-2', 'liquid-precipitation'],
    coordinates: '700+123',
    traffic: '53 456',
    distance: '5.89',
    speed: null,
    passenger: '3',
  },
  {
    header: 'Header 3',
    id: 13,
    warnings: ['slippery', 'wind', 'temperature-change'],
    coordinates: '700+123',
    traffic: '53 456',
    distance: '5.89',
    speed: '112',
    passenger: '3',
  },
  {
    header: 'Header 3',
    id: 14,
    warnings: ['slippery', 'slippery-2', 'liquid-precipitation'],
    coordinates: '700+123',
    traffic: '',
    distance: '5.89',
    speed: '112',
    passenger: '3',
  },
  { header: 'Header 3', id: 15, warnings: ['skid'], coordinates: '700+123', traffic: '53 456', distance: '5.89', speed: '112', passenger: '3' },
  {
    header: 'Header 3',
    id: 16,
    warnings: ['slippery', 'temperature-change', 'wind', 'mixed-rainfall', 'slippery-2', 'liquid-precipitation', 'skid'],
    coordinates: '700+123',
    traffic: '53 456',
    distance: undefined,
    speed: '112',
    passenger: '3',
  },
  {
    header: 'Header 3',
    id: 17,
    warnings: ['slippery-2', 'skid', 'temperature-change'],
    coordinates: '700+123',
    traffic: '53 456',
    distance: '5.89',
    speed: '112',
    passenger: '3',
  },
  {
    header: 'Header 3',
    id: 18,
    warnings: ['slippery', 'temperature-change', 'slippery-2', 'liquid-precipitation'],
    coordinates: '700+123',
    traffic: '53 456',
    distance: '5.89',
    speed: null,
    passenger: '3',
  },
];

const getTableRow = (rowData, rowIndex) => (
  <TableRow key={rowData.key}>
    <CSDOpenCloseTableCell label={rowData.pin} rowIndex={rowIndex} columnIndex={0}>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <IconButton color="inherit">
          <Icon>star-outline</Icon>
        </IconButton>
        <span>{rowData.pin}</span>
        <IconButton color="inherit">
          <Icon>camera</Icon>
        </IconButton>
        <IconButton color="inherit">
          <Icon>traffic</Icon>
        </IconButton>
        <IconButton color="inherit">
          <Icon>schedule</Icon>
        </IconButton>
      </Stack>
    </CSDOpenCloseTableCell>
    <CSDTableCell colSpan={1} align="center" rowIndex={rowIndex} columnIndex={1}>
      {rowData.time}
    </CSDTableCell>
    <CSDTableCell colSpan={1} align="center" rowIndex={rowIndex} columnIndex={2}>
      {rowData.traffic}
    </CSDTableCell>
    <CSDTableCell colSpan={1} align="center" rowIndex={rowIndex} columnIndex={3}>
      {rowData.passenger}
    </CSDTableCell>
    <CSDTableCell colSpan={1} align="center" rowIndex={rowIndex} columnIndex={4}>
      {rowData.smallCargo}
    </CSDTableCell>
    <CSDTableCell colSpan={1} align="center" rowIndex={rowIndex} columnIndex={5}>
      {rowData.cargo}
    </CSDTableCell>
    <CSDTableCell colSpan={1} align="center" rowIndex={rowIndex} columnIndex={6}>
      {rowData.bigCargo}
    </CSDTableCell>
    <CSDTableCell colSpan={1} align="center" rowIndex={rowIndex} columnIndex={7}>
      {rowData.roadTrain}
    </CSDTableCell>
    <CSDTableCell colSpan={1} align="center" rowIndex={rowIndex} columnIndex={8}>
      {rowData.bus}
    </CSDTableCell>
    <CSDTableCell colSpan={1} align="center" rowIndex={rowIndex} columnIndex={9}>
      {rowData.motobike}
    </CSDTableCell>
    <CSDTableCell colSpan={1} align="center" rowIndex={rowIndex} columnIndex={10}>
      {rowData.speed}
    </CSDTableCell>
    <CSDTableCell colSpan={1} align="center" rowIndex={rowIndex} columnIndex={11}>
      {rowData.workload}
    </CSDTableCell>
    <CSDTableCell colSpan={1} align="center" rowIndex={rowIndex} columnIndex={12}>
      {rowData.distance}
    </CSDTableCell>
  </TableRow>
);

const getTableColumns = () => (
  <TableRow>
    <CSDTableCell align="center" rowIndex={-1} columnIndex={0}>
      <Icon>pin</Icon>
    </CSDTableCell>
    <CSDTableCell align="center" rowIndex={-1} columnIndex={1}>
      <Icon>time</Icon>
    </CSDTableCell>
    <CSDTableCell align="center" rowIndex={-1} columnIndex={2}>
      <Icon>traffic</Icon>
    </CSDTableCell>
    <CSDTableCell align="center" rowIndex={-1} columnIndex={3}>
      <Icon>passenger</Icon>
    </CSDTableCell>
    <CSDTableCell align="center" rowIndex={-1} columnIndex={4}>
      <Icon>small-cargo</Icon>
    </CSDTableCell>
    <CSDTableCell align="center" rowIndex={-1} columnIndex={5}>
      <Icon>cargo</Icon>
    </CSDTableCell>
    <CSDTableCell align="center" rowIndex={-1} columnIndex={6}>
      <Icon>big-cargo</Icon>
    </CSDTableCell>
    <CSDTableCell align="center" rowIndex={-1} columnIndex={7}>
      <Icon>road-train</Icon>
    </CSDTableCell>
    <CSDTableCell align="center" rowIndex={-1} columnIndex={8}>
      <Icon>bus</Icon>
    </CSDTableCell>
    <CSDTableCell align="center" rowIndex={-1} columnIndex={9}>
      <Icon>motorbike</Icon>
    </CSDTableCell>
    <CSDTableCell align="center" rowIndex={-1} columnIndex={10}>
      <Icon>speed</Icon>
    </CSDTableCell>
    <CSDTableCell align="center" rowIndex={-1} columnIndex={11}>
      <Icon>workload</Icon>
    </CSDTableCell>
    <CSDTableCell align="center" rowIndex={-1} columnIndex={12}>
      <Icon>distance</Icon>
    </CSDTableCell>
  </TableRow>
);

const createRundomRowObjects = size =>
  Array.from({ length: size }, (_, index) => {
    return createData(
      `7+60${Math.round(Math.random() * 10)}`,
      '25.10/24:20',
      '66 794',
      '50 523',
      '2 809',
      '4 379',
      '2 564',
      '375',
      '628',
      '0',
      '71.53',
      '15.49%',
      '5.69',
    );
  });

const testVirtualizedRows = [
  {
    header: 'Header 1',
    items: createRundomRowObjects(20),
  },
  {
    header: 'Header 2',
    items: createRundomRowObjects(20),
  },
  {
    header: 'Header 3',
    items: createRundomRowObjects(20),
  },
];

class AdapterForGroup extends GroupAdapter<ITestAdapterData> {
  transform = data => {
    const groupedByHeader = groupBy(data, 'header');
    return Object.keys(groupedByHeader).map(key => ({
      header: key,
      items: groupedByHeader[key],
    }));
  };
}

class BasicAdapter extends FlatAdapter<ITestAdapterData> {
  transform = data => {
    return data.map(({ header, ...other }) => ({ ...other }));
  };
}

const prepareRows =
  (withHead = true) =>
  groupedObject => {
    return groupedObject.reduce((result, current, index, array) => {
      if (!withHead) {
        return [...result, ...current.items];
      }
      return [
        ...result,
        ...current.items,
        array[index + 1]
          ? {
              topHeaderSettings: { show: true, text: array[index + 1]?.header },
              bottomHeaderSettings: { show: true, text: current?.header },
            }
          : {
              bottomHeaderSettings: { show: true, text: current?.header },
            },
      ];
    }, []);
  };

const CSDTables = () => {
  const virualizedTableData = prepareRows()(testVirtualizedRows);
  const groupAdapter = new AdapterForGroup();
  const flatAdapter = new BasicAdapter();
  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="Basic table" codeExample={basicTable}>
        <TableContainer component={Paper}>
          <CSDTable>
            <TableHead>
              <TableRow>
                <CSDTableCell align="center" rowIndex={-1} columnIndex={0}>
                  <Icon>pin</Icon>
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={-1} columnIndex={1}>
                  <Icon>time</Icon>
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={-1} columnIndex={2}>
                  <Icon>traffic</Icon>
                </CSDTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <CSDTableCell align="center" rowIndex={0} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={0} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={0} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow>
                <CSDTableCell align="center" rowIndex={1} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={1} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={1} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow>
                <CSDTableCell align="center" rowIndex={2} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={2} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={2} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
            </TableBody>
          </CSDTable>
        </TableContainer>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Open/Close table cell" codeExample={openCloseCell}>
        <TableContainer component={Paper}>
          <CSDTable>
            <TableHead>{getTableColumns()}</TableHead>
            <TableBody>{createRundomRowObjects(10).map((row, index) => getTableRow(row, index))}</TableBody>
          </CSDTable>
        </TableContainer>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Error cell" codeExample={errorCell}>
        <TableContainer component={Paper}>
          <CSDTable>
            <TableHead>
              <TableRow>
                <CSDTableCell align="center" rowIndex={-1} columnIndex={0}>
                  <Icon>pin</Icon>
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={-1} columnIndex={1}>
                  <Icon>time</Icon>
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={-1} columnIndex={2}>
                  <Icon>traffic</Icon>
                </CSDTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className="error">
                <CSDTableCell className="error" align="center" rowIndex={0} columnIndex={0}>
                  <Icon>traffic</Icon>
                </CSDTableCell>
                <CSDTableCell align="center" sx={{ maxWidth: '90px' }} rowIndex={0} columnIndex={1}>
                  <CSDOpenCloseBudges max={2}>
                    <Icon>slippery</Icon>
                    <Icon>temperature-change</Icon>
                    <Icon>wind</Icon>
                    <Icon>mixed-rainfall</Icon>
                    <Icon>slippery-2</Icon>
                    <Icon>liquid-precipitation</Icon>
                    <Icon>skid</Icon>
                  </CSDOpenCloseBudges>
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={0} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow className="error">
                <CSDTableCell align="center" rowIndex={1} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" sx={{ maxWidth: '90px' }} rowIndex={1} columnIndex={1}>
                  <CSDOpenCloseBudges max={2}>
                    <Icon>slippery</Icon>
                    <Icon>temperature-change</Icon>
                    <Icon>wind</Icon>
                    <Icon>mixed-rainfall</Icon>
                    <Icon>slippery-2</Icon>
                    <Icon>liquid-precipitation</Icon>
                    <Icon>skid</Icon>
                  </CSDOpenCloseBudges>
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={1} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow className="error">
                <CSDTableCell align="center" rowIndex={2} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" sx={{ maxWidth: '90px' }} rowIndex={2} columnIndex={1}>
                  <CSDOpenCloseBudges max={2}>
                    <Icon>slippery</Icon>
                    <Icon>temperature-change</Icon>
                    <Icon>wind</Icon>
                    <Icon>mixed-rainfall</Icon>
                    <Icon>slippery-2</Icon>
                    <Icon>liquid-precipitation</Icon>
                    <Icon>skid</Icon>
                  </CSDOpenCloseBudges>
                </CSDTableCell>
                <CSDTableCell className="error" align="center" rowIndex={2} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
            </TableBody>
          </CSDTable>
        </TableContainer>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Row colors" codeExample={rowColors}>
        <TableContainer component={Paper}>
          <CSDTable>
            <TableHead>
              <TableRow>
                <CSDTableCell align="center" rowIndex={-1} columnIndex={0} />
                <CSDTableCell align="center" rowIndex={-1} columnIndex={1}>
                  <Icon>time</Icon>
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={-1} columnIndex={2}>
                  <Icon>traffic</Icon>
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={-1} columnIndex={3}>
                  <Icon>passenger</Icon>
                </CSDTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <CSDTableCell align="center" rowIndex={0} columnIndex={0}>
                  whiout class
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={0} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={0} columnIndex={2}>
                  2 809
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={0} columnIndex={3}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow className="success">
                <CSDTableCell align="center" rowIndex={1} columnIndex={0}>
                  success
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={1} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={1} columnIndex={2}>
                  2 809
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={1} columnIndex={3}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow className="error">
                <CSDTableCell align="center" rowIndex={2} columnIndex={0}>
                  error
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={2} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={2} columnIndex={2}>
                  2 809
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={2} columnIndex={3}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow className="notEnoughData">
                <CSDTableCell align="center" rowIndex={3} columnIndex={0}>
                  notEnoughData
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={3} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={3} columnIndex={2}>
                  2 809
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={3} columnIndex={3}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow className="unavailable">
                <CSDTableCell align="center" rowIndex={4} columnIndex={0}>
                  unavailable
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={4} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={4} columnIndex={2}>
                  2 809
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={4} columnIndex={3}>
                  2 809
                </CSDTableCell>
              </TableRow>
            </TableBody>
          </CSDTable>
        </TableContainer>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Table column with tooltip" codeExample={tableColumnsTooltip}>
        <TableContainer component={Paper}>
          <CSDTable>
            <TableHead>
              <TableRow>
                <CSDTableCell align="center" label="Координаты" rowIndex={-1} columnIndex={0}>
                  <Icon>pin</Icon>
                </CSDTableCell>
                <CSDTableCell align="center" label="Время" rowIndex={-1} columnIndex={1}>
                  <Icon>time</Icon>
                </CSDTableCell>
                <CSDTableCell align="center" label="Трафик" rowIndex={-1} columnIndex={2}>
                  <Icon>traffic</Icon>
                </CSDTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <CSDTableCell align="center" rowIndex={0} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={0} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={0} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow>
                <CSDTableCell align="center" rowIndex={1} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={1} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={1} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow>
                <CSDTableCell align="center" rowIndex={2} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={2} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={2} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
            </TableBody>
          </CSDTable>
        </TableContainer>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Sticky header" codeExample={stickyHeader}>
        <TableContainer component={Paper} sx={{ maxHeight: 150 }}>
          <CSDTable stickyHeader>
            <TableHead>
              <TableRow>
                <CSDTableCell align="center" label="Координаты" rowIndex={-1} columnIndex={0}>
                  <Icon>pin</Icon>
                </CSDTableCell>
                <CSDTableCell align="center" label="Время" rowIndex={-1} columnIndex={1}>
                  <Icon>time</Icon>
                </CSDTableCell>
                <CSDTableCell align="center" label="Трафик" rowIndex={-1} columnIndex={2}>
                  <Icon>traffic</Icon>
                </CSDTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <CSDTableCell align="center" rowIndex={0} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={0} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={0} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow>
                <CSDTableCell align="center" rowIndex={1} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={1} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={1} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow>
                <CSDTableCell align="center" rowIndex={2} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={2} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={2} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow>
                <CSDTableCell align="center" rowIndex={3} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={3} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={3} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow>
                <CSDTableCell align="center" rowIndex={4} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={4} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={4} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow>
                <CSDTableCell align="center" rowIndex={5} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={5} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={5} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow>
                <CSDTableCell align="center" rowIndex={6} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={6} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={6} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow>
                <CSDTableCell align="center" rowIndex={7} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={7} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={7} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow>
                <CSDTableCell align="center" rowIndex={8} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={8} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={8} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
            </TableBody>
          </CSDTable>
        </TableContainer>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Header grouping" codeExample={headerGrouping}>
        <TableContainer component={Paper}>
          <CSDTable>
            <TableHead>
              <TableRow>
                <CSDTableCell align="center" rowIndex={-1} columnIndex={0}>
                  <Icon>pin</Icon>
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={-1} columnIndex={1}>
                  <Icon>time</Icon>
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={-1} columnIndex={2}>
                  <Icon>traffic</Icon>
                </CSDTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <CSDTableCell align="center" rowIndex={0} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={0} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={0} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow>
                <CSDTableCell align="center" rowIndex={1} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={1} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={1} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow>
                <CSDGroupCell
                  topHeaderSettings={{
                    show: true,
                    text: 'Top level header with alarm color',
                    sx: { background: '#CD001529' },
                  }}
                  bottomHeaderSettings={{
                    show: true,
                    text: 'Bottom level header',
                  }}
                  colSpan={3}
                />
              </TableRow>
              <TableRow>
                <CSDTableCell align="center" rowIndex={2} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={2} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={2} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow>
                <CSDTableCell align="center" rowIndex={3} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={3} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={3} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow>
                <CSDGroupCell
                  topHeaderSettings={{
                    show: true,
                    Icon: <Icon sx={{ fontSize: '16px', marginRight: '3px' }}>time</Icon>,
                    text: 'Top level header with ICON',
                  }}
                  bottomHeaderSettings={{
                    show: true,
                    Icon: <Icon sx={{ fontSize: '16px', marginRight: '3px' }}>pin</Icon>,
                    text: 'Bottom level header',
                  }}
                  colSpan={3}
                />
              </TableRow>
              <TableRow>
                <CSDTableCell align="center" rowIndex={4} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={4} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={4} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow>
                <CSDTableCell align="center" rowIndex={5} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={5} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={5} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow>
                <CSDGroupCell
                  topHeaderSettings={{
                    show: true,
                    Icon: <Icon sx={{ fontSize: '16px', marginRight: '3px' }}>time</Icon>,
                    text: 'Bottom level header without bottom header',
                  }}
                  bottomHeaderSettings={{
                    show: false,
                  }}
                  colSpan={3}
                />
              </TableRow>
              <TableRow>
                <CSDTableCell align="center" rowIndex={6} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={6} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={6} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
              <TableRow>
                <CSDTableCell align="center" rowIndex={7} columnIndex={0}>
                  66 794
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={7} columnIndex={1}>
                  50 523
                </CSDTableCell>
                <CSDTableCell align="center" rowIndex={7} columnIndex={2}>
                  2 809
                </CSDTableCell>
              </TableRow>
            </TableBody>
          </CSDTable>
        </TableContainer>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Virtualized table (react-virtuoso)" codeExample={virtualizedTable}>
        <TableVirtuoso
          style={{ height: 400 }}
          totalCount={virualizedTableData.length}
          data={virualizedTableData}
          fixedItemHeight={40}
          components={{
            Table: props => <CSDTable {...props} style={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />,
            Scroller: TableContainer,
            TableHead,
            TableRow,
            TableBody,
          }}
          fixedHeaderContent={getTableColumns}
          itemContent={(index, data) => {
            if (data?.topHeaderSettings || data?.bottomHeaderSettings) {
              return (
                <CSDGroupCell
                  colSpan={13}
                  topHeaderSettings={data?.topHeaderSettings || {}}
                  bottomHeaderSettings={data?.bottomHeaderSettings || {}}
                />
              );
            }
            return (
              <>
                <CSDOpenCloseTableCell label={data.pin} rowIndex={index} columnIndex={0}>
                  <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                    <IconButton color="inherit">
                      <Icon>star-outline</Icon>
                    </IconButton>
                    <span>{data.pin}</span>
                    <IconButton color="inherit">
                      <Icon>camera</Icon>
                    </IconButton>
                    <IconButton color="inherit">
                      <Icon>traffic</Icon>
                    </IconButton>
                    <IconButton color="inherit">
                      <Icon>schedule</Icon>
                    </IconButton>
                  </Stack>
                </CSDOpenCloseTableCell>
                <CSDTableCell colSpan={1} align="center" rowIndex={index} columnIndex={1}>
                  {data.time}
                </CSDTableCell>
                <CSDTableCell colSpan={1} align="center" rowIndex={index} columnIndex={2}>
                  {data.traffic}
                </CSDTableCell>
                <CSDTableCell colSpan={1} align="center" rowIndex={index} columnIndex={3}>
                  {data.passenger}
                </CSDTableCell>
                <CSDTableCell colSpan={1} align="center" rowIndex={index} columnIndex={4}>
                  {data.smallCargo}
                </CSDTableCell>
                <CSDTableCell colSpan={1} align="center" rowIndex={index} columnIndex={5}>
                  {data.cargo}
                </CSDTableCell>
                <CSDTableCell colSpan={1} align="center" rowIndex={index} columnIndex={6}>
                  {data.bigCargo}
                </CSDTableCell>
                <CSDTableCell colSpan={1} align="center" rowIndex={index} columnIndex={7}>
                  {data.roadTrain}
                </CSDTableCell>
                <CSDTableCell colSpan={1} align="center" rowIndex={index} columnIndex={8}>
                  {data.bus}
                </CSDTableCell>
                <CSDTableCell colSpan={1} align="center" rowIndex={index} columnIndex={9}>
                  {data.motobike}
                </CSDTableCell>
                <CSDTableCell colSpan={1} align="center" rowIndex={index} columnIndex={10}>
                  {data.speed}
                </CSDTableCell>
                <CSDTableCell colSpan={1} align="center" rowIndex={index} columnIndex={11}>
                  {data.workload}
                </CSDTableCell>
                <CSDTableCell colSpan={1} align="center" rowIndex={index} columnIndex={12}>{`${data.distance} ${index}`}</CSDTableCell>
              </>
            );
          }}
        />
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Data Grid Table (Basic)" codeExample={dataGridTableBacis}>
        <TableContainer component={Paper}>
          <CSDDataGridTable adapter={flatAdapter} columns={testColumnData} data={testAdapterData} />
        </TableContainer>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Data Grid Table (Virtualize + Grouping)" codeExample={dataGridTableVirtualizedAndGrouping}>
        <CSDDataGridTable adapter={groupAdapter} columns={testColumnData} data={testAdapterData} isVirtualize />
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Data Grid (Virtualized)" codeExample={dataGridVirtualized}>
        <CSDDataGrid
          render={(index, data) => (
            <Card sx={{ boxShadow: '0px -2px 5px 0px #34343433', padding: '10px', width: '100%' }}>
              <CardMedia sx={{ height: 140 }} image={data.snapshotUrl} title="green iguana" />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {data.road.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {data.road.titleFull}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Опции</Button>
                <Button size="small">Еще...</Button>
              </CardActions>
            </Card>
          )}
          data={testGridData}
          style={{ height: 650, width: '100%' }}
        />
      </CSDKitDocBlock>
    </Stack>
  );
};

export default CSDTables;
