import React, { ReactNode } from 'react';
import CheckboxMaterial from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormControlLabelClasses } from '@mui/material/FormControlLabel/formControlLabelClasses';
import { DataTestIds } from '@app/v2/shared/enums';

type Props = {
  label: ReactNode;
  checked: boolean;
  indeterminate?: boolean;
  classes?: Partial<FormControlLabelClasses>;
  className?: string;
  color?: 'secondary' | 'primary' | 'default';
  disabled?: boolean;
  onChange: (v: boolean) => void;
  dataTestId?: DataTestIds;
};

const Checkbox = (props: Props) => {
  const { checked, indeterminate, classes, className, color = 'secondary', label, onChange = () => {}, disabled = false, dataTestId } = props;

  return (
    <FormControlLabel
      classes={classes}
      className={className}
      data-testid={dataTestId}
      control={
        <CheckboxMaterial
          disabled={disabled}
          checked={checked}
          color={color}
          onChange={e => onChange(e.target.checked)}
          indeterminate={indeterminate}
        />
      }
      label={label}
    />
  );
};

export default Checkbox;
