import React, { useCallback, useEffect } from 'react';
import { WithTranslation, withTranslation, useTranslation } from 'react-i18next';
import { Stack, Box, Button, Icon, Typography } from '@mui/material';
import icons from '@app/assets/iconFont';
import useNotificationProfileContext from '@app/v2/features/notificationProfile/context/useNotificationProfileContext';
import { INITIAL_NOTIFICATION_PROFILE_ID, MAX_NOTIFICATION_PROFILES_AMOUNT } from '@app/v2/shared/constants';
import { Profile } from '@app/v2/features/notifications/types';
import useDialog from '@app/core/hooks/useDialog';
import ConfirmDialog from '@app/components/dialogs/confirm-dialog/ConfirmDialog';

const ProfileManager = ({ t }: WithTranslation<'profiles'>) => {
  const { currentProfile, isEdit, isLoading, profiles, setCurrentProfile, toggleProfileStatus, addNewProfile, reset } =
    useNotificationProfileContext();
  const { t: tNotificationProfile } = useTranslation('notification', { keyPrefix: 'profiles' });
  const openConfirmDialog = useDialog(ConfirmDialog);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const handleChangeProfile = useCallback(
    (profile: Profile) => {
      if (isEdit && currentProfile.id === INITIAL_NOTIFICATION_PROFILE_ID) {
        openConfirmDialog({ question: t('questions.cancelSaveProfileMode') }).beforeClose.subscribe((answerToQuestion: boolean): void => {
          if (!answerToQuestion) return;

          setCurrentProfile(profile);
        });
      } else {
        setCurrentProfile(profile);
      }
    },
    [isEdit, currentProfile, setCurrentProfile, openConfirmDialog, t],
  );

  const handleToggle = useCallback(
    (checked: boolean, id: number) => {
      if (isEdit) {
        openConfirmDialog({ question: t('questions.actionWithCancelChanges') }).beforeClose.subscribe((answerToQuestion: boolean): void => {
          if (!answerToQuestion) return;

          toggleProfileStatus(checked, id);
        });
      } else {
        toggleProfileStatus(checked, id);
      }
    },
    [isEdit, openConfirmDialog, toggleProfileStatus, t],
  );

  return (
    <Box>
      <Stack direction="row" spacing={1}>
        {profiles.length < MAX_NOTIFICATION_PROFILES_AMOUNT && (
          <Button
            sx={{ minWidth: 'auto' }}
            variant={currentProfile?.id === INITIAL_NOTIFICATION_PROFILE_ID ? 'contained' : 'outlined'}
            onClick={addNewProfile}
            disabled={isLoading}
          >
            <Icon>{icons.plus}</Icon>
            {currentProfile?.id === INITIAL_NOTIFICATION_PROFILE_ID && (
              <Typography marginLeft="0.3rem" color="inherit">
                {currentProfile.title || tNotificationProfile('defaultProfileLabel')}
              </Typography>
            )}
          </Button>
        )}
        {profiles.map(profile => (
          <Button
            key={profile?.id}
            variant={currentProfile?.id === profile?.id ? 'contained' : 'outlined'}
            {...(profile.id !== INITIAL_NOTIFICATION_PROFILE_ID && {
              startIcon: (
                <Icon
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleToggle(!profile.isActive, profile.id);
                  }}
                >
                  {profile.isActive ? icons.checkboxOn : icons.checkboxOff}
                </Icon>
              ),
            })}
            disabled={isLoading}
            onClick={() => handleChangeProfile(profile)}
          >
            {profile.title}
          </Button>
        ))}
      </Stack>
    </Box>
  );
};

export default withTranslation('profiles')(ProfileManager);
