import React, { ReactNode } from 'react';
import { Box, Chip, ChipProps, Divider, Icon, Stack, Tooltip, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { isFunction } from '@app/v2/shared/helpers';
import { DataTestIds } from '@app/v2/shared/enums';

interface Actions {
  id: string | number;
  icon: string;
  tooltipTitle: string;
  onClick?: Common.VoidCallBack;
}

interface ChipsConfigItem {
  id: string | number;
  label: string;
  onClick?: Common.VoidCallBack;
  onDelete?: Common.VoidCallBack;
  actions?: Actions[];
  actionsNodes?: ReactNode[];
  isActive?: boolean;
  dataTestId?: DataTestIds;
  icon?: string;
  iconColor?: string;
}

interface Props extends ChipProps {
  titleNode?: ReactNode;
  title?: string;
  titleVariant?: Variant;
  addChip?: ReactNode;
  isAddView?: boolean;
  chipsConfig: ChipsConfigItem[];
  hidden?: boolean;
  withEndDivider?: boolean;
  withStartDivider?: boolean;
}

const CSDChipsBlock = ({
  title,
  titleNode,
  chipsConfig,
  addChip,
  isAddView = true,
  withEndDivider = false,
  withStartDivider = false,
  hidden = false,
  titleVariant = 'h3',
  ...other
}: Props) => {
  const handleClick = (handler: Common.VoidCallBack): void => {
    if (!isFunction(handler)) return;
    handler();
  };

  return (
    <>
      {!hidden && (
        <Stack direction="row" gap="1rem">
          {withStartDivider && <Divider orientation="vertical" flexItem />}

          <Box>
            {titleNode || (
              <Typography variant={titleVariant} sx={{ marginBottom: '0.5rem' }}>
                {title}
              </Typography>
            )}

            <Stack direction="row" sx={{ gap: '0.5rem' }}>
              {chipsConfig.map(({ id, label, icon, iconColor, onClick, onDelete, actions = [], actionsNodes = [], isActive, dataTestId }) => (
                <Chip
                  key={`chip-${id}`}
                  label={label}
                  color={isActive ? 'primary' : 'default'}
                  variant={isActive ? 'filled' : 'outlined'}
                  icon={icon ? <Icon style={{ fontSize: '1rem', color: iconColor }}>{icon}</Icon> : null}
                  onClick={() => handleClick(onClick)}
                  onDelete={isActive ? () => handleClick(onDelete) : undefined}
                  data-testid={dataTestId}
                  deleteIcon={
                    isActive ? (
                      <>
                        {[
                          ...actions.map(action => (
                            <Tooltip key={`action-${id}-${action.id}`} title={action?.tooltipTitle}>
                              <Icon sx={{ fontSize: '1rem' }} onClick={() => handleClick(action?.onClick)}>
                                {action?.icon}
                              </Icon>
                            </Tooltip>
                          )),
                          ...actionsNodes,
                        ]}
                      </>
                    ) : undefined
                  }
                  {...other}
                />
              ))}
              {isAddView && addChip}
            </Stack>
          </Box>

          {withEndDivider && <Divider orientation="vertical" flexItem />}
        </Stack>
      )}
    </>
  );
};

export default CSDChipsBlock;
