import { Observable, OperatorFunction, Subject } from 'rxjs';

export default abstract class BaseManager<T> {
  private subject: Subject<T>;

  private observable: Observable<T>;

  private data: T;

  protected constructor(defaultValue: T) {
    this.data = defaultValue;
    this.subject = new Subject<T>();
    this.observable = this.subject.asObservable();
  }

  pipe<A>(op1: OperatorFunction<T, A>): Observable<A> {
    return this.observable.pipe<A>(op1);
  }

  subscribe(next, error?, final?) {
    return this.observable.subscribe(next, error, final);
  }

  get value(): T {
    return this.data;
  }

  next(data: T) {
    this.data = data;
    this.subject.next(data);
  }
}
