import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogTitle, Icon, IconButton, Skeleton, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { DialogRef } from '@app/core/providers/DialogProvider';
import otherQueries from '@app/clients/apollo/requests/queries/others';
import icons from '@app/assets/iconFont';
import { showError } from '@app/core/utils/notifications';
import getHumanizedAddress from '@app/core/helpers/getHumanizedAddress';
import { formatRuFull } from '@app/core/helpers/dateFormat';
import CSDSnapshotDialogContent from '@app/v2/shared/ui/custom/CSDSnapshotDialog/CSDSnapshotDialogContent';

type Props = Meteo.ChartPointParameters['dialogData'] & DialogRef;

const CSDSnapshotDialog = ({ title, dateTime, meteoStationId, kilometer, meter, close }: Props) => {
  const { t } = useTranslation('common');

  const { refetch: fetchSnapshot } = useQuery<Video.SnapshotsResponse>(otherQueries.snapshot, { skip: true });

  const [loading, setLoading] = useState<boolean>(true);
  const [snapshots, setSnapshots] = useState<Video.Snapshot[]>([]);
  const [activeSnapshot, setActiveSnapshot] = useState<Video.Snapshot | null>(null);

  const classes = useStyles({ snapshotURL: activeSnapshot?.url });

  useEffect(() => {
    fetchSnapshot({ meteoStationId, dateTime })
      .then(({ data }) => {
        setSnapshots(data?.closestSnapshot ?? []);
        setActiveSnapshot(data?.closestSnapshot?.[0]);
      })
      .catch(() => {
        showError(t('errors.NOT_FOUND'));
        close();
      })
      .finally(() => setLoading(false));
  }, [close, dateTime, fetchSnapshot, meteoStationId, t]);

  return (
    <Dialog open maxWidth="sm" onClose={() => close()}>
      <DialogTitle>
        <Box className={classes.title}>
          <Typography variant="h3">
            {getHumanizedAddress(t('units.kilometers'), kilometer, meter)}, {title}
          </Typography>

          {activeSnapshot?.dateTime && (
            <Typography variant="subtitle1">
              {`${t('dates.date')}:`} {formatRuFull(activeSnapshot?.dateTime)}
            </Typography>
          )}
        </Box>

        <IconButton onClick={() => close()}>
          <Icon>{icons.close}</Icon>
        </IconButton>
      </DialogTitle>

      <Box className={classes.content}>
        {snapshots?.length > 1 && (
          <Box>
            {snapshots.map(snapshot => (
              <IconButton
                key={snapshot.url}
                onClick={() => setActiveSnapshot(snapshot)}
                className={clsx({ [classes.activeSnapshot]: activeSnapshot?.stationId === snapshot.stationId })}
              >
                <Icon>{icons.photo}</Icon>
              </IconButton>
            ))}
          </Box>
        )}

        {loading ? <Skeleton className={classes.skeleton} variant="rectangular" /> : <CSDSnapshotDialogContent activeSnapshot={activeSnapshot} />}
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles({
  title: {
    gap: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  snapshotsStations: {
    gap: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  activeSnapshot: {
    '& .custom-icon': {
      color: 'red',
    },
  },
  content: {
    gap: '1rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  skeleton: {
    '&.MuiSkeleton-root': {
      width: '100%',
      height: '37rem',
      borderRadius: '0.5rem',
    },
  },
});

export default CSDSnapshotDialog;
