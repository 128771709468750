import { PositionVariants, DirectionVariants, CSDStationItem } from './types';
import RoadDirectionType from './enums/RoadDirectionType';
import PositionType from './enums/PositionType';
import { COLORS, ARROW_SIZE } from './constants';

type CameraCoordinate = {
  size: number;
  position: PositionVariants;
  hasNeighbor: boolean;
  hasWrongStations: boolean;
  direction: DirectionVariants;
};

export const getXYCameraCoordinate = ({
  size,
  position,
  hasNeighbor,
  hasWrongStations,
  direction,
}: CameraCoordinate): { x: string; y: string; translation: string } => {
  const halfSize = size / 2;

  const isForward = direction === RoadDirectionType.forward;

  const neighborOffsetX = isForward ? 0 : size;

  const translationX = hasNeighbor ? neighborOffsetX : halfSize;

  const yCoordinateMapper = {
    [PositionType.top]: '10%',
    [PositionType.middle]: '50%',
    [PositionType.bottom]: hasWrongStations ? '100%' : '90%',
  };

  const translationValueMapper = {
    [PositionType.top]: `translate(-${translationX}px, 0px)`,
    [PositionType.middle]: `translate(-${translationX}px, -${halfSize}px)`,
    [PositionType.bottom]: `translate(-${translationX}px, -${hasWrongStations ? size * 2 : size}px)`,
  };

  return {
    y: yCoordinateMapper[position],
    x: '50%',
    translation: translationValueMapper[position],
  };
};

export const getCameraColor = (isActive: boolean, isExpired: boolean) => {
  switch (true) {
    case isActive:
      return COLORS.ACTIVE;
    case isExpired:
      return COLORS.EXPIRED;
    default:
      return COLORS.BASE;
  }
};

export const validateStations = (stations: CSDStationItem[]) =>
  stations.reduce<{ validStation: CSDStationItem[]; wrongStations: CSDStationItem[] }>(
    (res, station) => {
      const hasPosition = Object.keys(PositionType).includes(station.position);
      const hasValidDublicate = !!res.validStation.find(
        ({ position, direction }) => station.position === position && station.direction === direction,
      );
      const hasNeighbor = !!stations.find(({ position, direction }) => station.position === position && station.direction !== direction);

      if (hasPosition && !hasValidDublicate) {
        res.validStation.push({ ...station, hasNeighbor });
      } else {
        res.wrongStations.push({ ...station, isDuplicate: hasValidDublicate });
      }

      return res;
    },
    {
      validStation: [],
      wrongStations: [],
    },
  );

export const getArrowCoordinates = (roadHeight: number, direction: RoadDirectionType): { x1: string; x2: string; y1: string; y2: string } => {
  const halfArrowSize = ARROW_SIZE / 2;

  const centerYPosition = roadHeight / 2;

  const coordinateDirectionMapper = {
    [RoadDirectionType.backward]: [60 + halfArrowSize, 60 - halfArrowSize],
    [RoadDirectionType.forward]: [40 - halfArrowSize, 40 + halfArrowSize],
  };

  const [x1, x2] = coordinateDirectionMapper[direction];

  return {
    x1: `${x1}%`,
    x2: `${x2}%`,
    y1: `${centerYPosition}%`,
    y2: `${centerYPosition}%`,
  };
};
