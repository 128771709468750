enum ActiveAlerts {
  wind = 'wind',
  temperatureChange = 'temperatureChange',
  liquidPrecipitation = 'liquidPrecipitation',
  solidAndMixedPrecipitation = 'solidAndMixedPrecipitation',
  winterSlipperiness = 'winterSlipperiness',
  winterSlipperiness2 = 'winterSlipperiness2',
}

export default ActiveAlerts;
