import React from 'react';
import { Icon, TextField, TextFieldProps } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DesktopDatePickerProps } from '@mui/x-date-pickers/DesktopDatePicker/DesktopDatePicker';
import icons from '@app/assets/iconFont';
import CSDRootDatePicker from './CSDRootDatePicker';

type Props<TInputDate, TDate> = {
  picker?: Omit<DesktopDatePickerProps<TInputDate, TDate>, 'renderInput'> & React.RefAttributes<HTMLDivElement>;
  textField?: TextFieldProps;
};

const OpenPickerIcon = () => <Icon>{icons.calender}</Icon>;
const LeftArrowButton = () => <Icon>{icons.left}</Icon>;
const RightArrowButton = () => <Icon>{icons.right}</Icon>;

const CSDDatePicker = <TInputDate, TDate>({ picker, textField }: Props<TInputDate, TDate>) => {
  return (
    <CSDRootDatePicker
      Component={
        <DesktopDatePicker
          showToolbar
          components={{
            OpenPickerIcon,
            LeftArrowButton,
            RightArrowButton,
          }}
          renderInput={params => <TextField fullWidth={false} variant="standard" {...textField} {...params} />}
          {...picker}
        />
      }
    />
  );
};

export default CSDDatePicker;
