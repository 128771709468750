import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { DialogRef } from '@app/core/providers/DialogProvider';

const AllowPositionDialog = ({ close }: DialogRef) => {
  const { t } = useTranslation('components');
  const { t: dialogTranslates } = useTranslation('dialogs');
  const classes = useStyles();

  return (
    <Dialog maxWidth="sm" open onClose={() => close()}>
      <DialogTitle className={classes.title}>
        <Typography variant="h6" margin="auto">
          {t('allowPositionTitle')}
        </Typography>

        <IconButton onClick={() => close()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>{t('allowPositionDesc')}</DialogContent>

      <DialogActions className={classes.actionPanel}>
        <Button variant="outlined" onClick={() => close()}>
          {dialogTranslates('common.buttons.proceed')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    marginBottom: '3rem',
  },
  actionPanel: {
    marginTop: '3rem',
    padding: '0.5 rem',
    height: '2rem',
    margin: '1rem 0',
    alignItems: 'center',
    alignContent: 'center',
  },
}));

export default AllowPositionDialog;
