enum RoadObjectsTypes {
  VIDEO = 'VIDEO',
  TRAFFIC = 'TRAFFIC',
  MESSAGE_STATIONS = 'MESSAGE_STATIONS',
  SIGN_STATIONS = 'SIGN_STATIONS',
  METEO_PARAMS_FIRST = 'METEO_PARAMS_FIRST',
  METEO_PARAMS_SECOND = 'METEO_PARAMS_SECOND',
  ROAD_ADDRESS = 'ROAD_ADDRESS',
}

export default RoadObjectsTypes;
