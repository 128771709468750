import React from 'react';
import { Box, Icon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import icons from '@app/assets/iconFont';
import { DataTestIds } from '@app/v2/shared/enums';

const CSDTPIEmptyCard = () => {
  const classes = useStyles();

  return (
    <Box className={classes.noData} data-testid={DataTestIds.EmptyPlaylistsListCell}>
      <Icon>{icons.close}</Icon>
    </Box>
  );
};

const useStyles = makeStyles({
  noData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '18rem',
    height: '15rem',
    border: '1px solid black',
    padding: '1rem',
    margin: '0.5rem',
    borderRadius: '0.5rem',

    '& > .custom-icon': {
      fontSize: '5rem',
    },
  },
});

export default CSDTPIEmptyCard;
