import { KeyPrefix } from 'react-i18next';
import { NO_DATA } from '@app/core/constants';
import noDataFilter from '@app/core/helpers/noDataFilter';
import icons from '@app/assets/iconFont';

type WindDirectionItem = {
  label: KeyPrefix<'constants'>;
  iconName: string;
  start: number;
  end: number;
};

export const WIND_DIRECTION_CORRECTION = -1;
const MAX_DEGREES = 360;
const MAX_RHUMBS = 8;
const RHUMB_DEGREES = 45;

const windDirections: WindDirectionItem[] = [
  {
    label: 'windDirections.northern',
    iconName: icons.northern,
    start: 0,
    end: 22.5,
  },
  {
    label: 'windDirections.northeastern',
    iconName: icons.northeastern,
    start: 22.6,
    end: 67.4,
  },
  {
    label: 'windDirections.eastern',
    iconName: icons.eastern,
    start: 67.5,
    end: 112.5,
  },
  {
    label: 'windDirections.southeastern',
    iconName: icons.southeastern,
    start: 112.6,
    end: 157.4,
  },
  {
    label: 'windDirections.south',
    iconName: icons.south,
    start: 157.5,
    end: 202.5,
  },
  {
    label: 'windDirections.southwestern',
    iconName: icons.southwestern,
    start: 202.6,
    end: 247.4,
  },
  {
    label: 'windDirections.western',
    iconName: icons.western,
    start: 247.5,
    end: 292.5,
  },
  {
    label: 'windDirections.northwestern',
    iconName: icons.northwestern,
    start: 292.6,
    end: 337.4,
  },
  {
    label: 'windDirections.northern',
    iconName: icons.northern,
    start: 337.5,
    end: 360,
  },
];

export const degrees2Rhumbs = (degrees: number): number => {
  if (typeof degrees === 'number' && degrees >= 0 && !Number.isNaN(degrees)) {
    return (Number(degrees.toFixed(1)) * MAX_RHUMBS) / MAX_DEGREES;
  }

  return null;
};

export const rhumb2Degrees = (rhumbs: number): number | string => {
  if (rhumbs === null || rhumbs === undefined) {
    return NO_DATA;
  }

  const degrees = Math.round(rhumbs * RHUMB_DEGREES);

  return degrees > MAX_DEGREES ? MAX_DEGREES : degrees;
};

const determineDirection = (rhumbs: number): WindDirectionItem | undefined => {
  const degrees = rhumb2Degrees(rhumbs);

  return windDirections.find(({ start, end }) => Number(degrees) >= Number(start) && Number(degrees) <= Number(end));
};

export const getTextWindDirection = (rhumbs: number): KeyPrefix<'constants'> => {
  const direction = determineDirection(rhumbs);

  return noDataFilter`${direction?.label}` as KeyPrefix<'constants'>;
};

export const getWindDirectionIcon = (rhumbs: number): string => {
  const direction = determineDirection(rhumbs);

  return noDataFilter`${direction?.iconName}`;
};

export const rotateCircleByDegree = (direction: number, angle: number): number => (direction + angle) % 360;

export const turnCircleInOppositeDirection = (direction: number): number => rotateCircleByDegree(direction, 180);
