import React from 'react';
import { Virtuoso } from 'react-virtuoso';
import { Box, TableContainer, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getScrollStyles } from '@theme/themeSettings/componentSettings';
import CSDNotificationListItem from './CSDNotificationListItem';

type Props = {
  filteredNotifications: Notifications.Models[];
};

const CSDNotificationsList = ({ filteredNotifications = [] }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.notificationList}>
      <Virtuoso
        totalCount={filteredNotifications.length}
        data={filteredNotifications}
        components={{
          Item: props => {
            return <Box {...props} className={classes.notificationListItemWrapper} />;
          },
          Scroller: TableContainer,
        }}
        itemContent={(_, data) => <CSDNotificationListItem notification={data} />}
      />
    </Box>
  );
};

const useStyles = makeStyles(({ palette: { text, mode, background } }: Theme) => ({
  notificationList: {
    '&.MuiBox-root': {
      width: '39.5rem',
      height: 'calc(100% - 15.5rem)',
      backgroundColor: background.second,
      ...getScrollStyles(mode, text),
      paddingBottom: '1rem',
    },
  },
  notificationListItemWrapper: {
    marginBottom: '1rem',
    '&:first-child': {
      marginTop: '1.5rem',
    },
  },
}));

export default CSDNotificationsList;
