import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps, Icon, Stack, StackProps, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { typedMemo } from '@app/v2/shared/utils';
import isFunction from '@app/core/helpers/isFunction';
import { EMPTY_STRING, ENTER_TOOLTIP_DELAY, MAX_PROFILES_AMOUNT } from '@app/v2/shared/constants';
import icons from '@app/assets/iconFont';
import { getSkeletonItems, strictlyEqual } from '@app/v2/shared/helpers';
import { ProfileActions } from '@app/v2/shared/enums';

interface SkeletonSettings {
  loading: boolean;
  skeletonsAmount: number;
  component: JSX.Element;
}

interface PanelActions<Profile> {
  actionName: string;
  actionType?: 'error' | 'outlined' | 'contained';
  isDisabled?: boolean;
  isView?: boolean;
  action: (profile?: Profile) => void;
  Icon?: ReactNode;
  tooltipTitle?: string;
}

interface Props<Profile> {
  profiles: Profile[];
  activeProfile?: Profile;
  profileActions: Partial<Record<ProfileActions, Omit<PanelActions<Profile>, 'actionType' | 'isView' | 'Icon'>>>;
  isSaveProfileMode?: boolean;
  hideProfileActions?: boolean;
  panelActions?: PanelActions<Profile>[];
  skeletonSettings?: SkeletonSettings;
  maxProfilesAmount?: number;
}

const CSDProfileActionPanel = <Profile extends Profiles.Default>({
  profiles,
  activeProfile,
  hideProfileActions = false,
  isSaveProfileMode,
  profileActions,
  panelActions,
  skeletonSettings,
  maxProfilesAmount = MAX_PROFILES_AMOUNT,
}: Props<Profile>) => {
  const [currentProfile, setCurrentProfile] = useState<Profile | null>(null);
  const { t } = useTranslation('profiles');

  useEffect(() => {
    if (activeProfile) {
      setCurrentProfile(activeProfile);
    } else {
      setCurrentProfile(profiles?.find(profile => profile.isDefault));
    }
  }, [profiles, activeProfile]);

  const onClickAction = (profile: Profile, cb: (profile?: Profile) => void): void => {
    if (!isFunction(cb)) return;

    cb(profile);
  };

  const isMaxProfileAmount = useMemo(() => maxProfilesAmount <= profiles.length, [maxProfilesAmount, profiles]);

  return (
    <WrapperStyledStack>
      <StyledStack justifyContent="space-between">
        {skeletonSettings && skeletonSettings.loading && (
          <StyledStack>
            {getSkeletonItems(skeletonSettings.skeletonsAmount).map(key => (
              <React.Fragment key={key}>{skeletonSettings.component}</React.Fragment>
            ))}
          </StyledStack>
        )}

        {!!profiles?.length && !skeletonSettings?.loading && (
          <StyledStack>
            {profiles.map(profile => (
              <Button
                key={profile.id}
                variant={!strictlyEqual<number>(currentProfile?.id, profile.id) ? 'outlined' : 'contained'}
                onClick={() => onClickAction(profile, profileActions.setActive.action)}
              >
                {(strictlyEqual<number>(currentProfile?.id, profile.id) ? currentProfile.label : profile.label) || t('placeholder.newProfile')}
              </Button>
            ))}

            {!hideProfileActions && !isSaveProfileMode && (
              <Tooltip title={profileActions.activateSaveMod?.tooltipTitle} enterDelay={ENTER_TOOLTIP_DELAY}>
                <StyledBtn
                  variant="outlined"
                  sx={{
                    display: isMaxProfileAmount ? 'none' : 'flex',
                  }}
                  onClick={() => onClickAction(activeProfile, profileActions.activateSaveMod.action)}
                >
                  <Icon>{icons.plus}</Icon>
                </StyledBtn>
              </Tooltip>
            )}
          </StyledStack>
        )}
      </StyledStack>

      {!hideProfileActions && isSaveProfileMode && (
        <Stack direction="row" gap="0.5rem">
          <Tooltip title={profileActions.deactivateSaveMod?.tooltipTitle} enterDelay={ENTER_TOOLTIP_DELAY}>
            <Button variant="outlined" onClick={() => onClickAction(activeProfile, profileActions.deactivateSaveMod.action)}>
              {profileActions.deactivateSaveMod.actionName}
            </Button>
          </Tooltip>
          <Tooltip title={profileActions.addNewProfile?.tooltipTitle} enterDelay={ENTER_TOOLTIP_DELAY}>
            <span>
              <Button variant="contained" onClick={() => onClickAction(activeProfile, profileActions.addNewProfile.action)}>
                {profileActions.addNewProfile.actionName}
              </Button>
            </span>
          </Tooltip>
        </Stack>
      )}

      {!hideProfileActions && !isSaveProfileMode && (
        <StyledStack justifyContent="flex-end">
          {!!panelActions?.length &&
            panelActions.map(({ actionName, action, Icon: icon, actionType, isDisabled, isView = true, tooltipTitle }) => (
              <React.Fragment key={actionName}>
                {isView && (
                  <Tooltip title={isDisabled ? tooltipTitle : EMPTY_STRING}>
                    <span>
                      <Button
                        variant={actionType}
                        disabled={isDisabled}
                        {...(icon && { startIcon: icon })}
                        onClick={() => onClickAction(currentProfile, action)}
                      >
                        {actionName}
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </React.Fragment>
            ))}
        </StyledStack>
      )}
    </WrapperStyledStack>
  );
};

const WrapperStyledStack = styled(Stack)<StackProps>({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '1rem',
  gap: '1rem',
});

const StyledStack = styled(Stack)<StackProps>({
  flexDirection: 'row',
  gap: '0.5rem',
  flexWrap: 'wrap',
});

const StyledBtn = styled(Button)<ButtonProps>({
  minWidth: '2.75rem',
  padding: '0rem',
});

export default typedMemo(CSDProfileActionPanel);
