import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Typography, Divider, Stack } from '@mui/material';
import { styled } from '@mui/styles';
import { VideoWallSettings } from '@store/slices/account/types';
import { VIDEO_WALL_DEFAULT_STATE } from '@app/modules/video-wall-module/constants/constants';
import usePaginationSearch from '@app/core/source/hooks/pagination/usePaginationSearch';
import { LISTING_DEFAULT_PAGE_NUMBER } from '@app/core/constants';
import { changeAccountSettings } from '@store/thunks/account/account-thunks';
import { useActions, useAppDispatch, useAppSelector, useSystem } from '@app/v2/shared/hooks';
import useDialog from '@app/core/hooks/useDialog';
import ConfirmDialog from '@app/components/dialogs/confirm-dialog/ConfirmDialog';
import { CSDSwitcher, CSDButtonWrapperWithTooltip } from '@app/modules/kit-module/shared/ui';
import CSDGridController from '../components/CSDGridController';
import CSDTypographySetting from '../components/CSDTypographySetting';
import CSDSettingActions from '../components/CSDSettingActions';
import CSDAutoPagination from '../components/CSDAutoPagination';
import CSDLinksSize from '../components/CSDLinksSize';
import { ArrowButtonType, SettingsProperty, SliderSettingsProp } from '../constants';

const CSDVideoWallSettingsPanel = () => {
  const { t } = useTranslation('video');
  const { isVideoWall, handleSetPaginationStatus } = useSystem();
  const { videoWallSetting } = useAppSelector(state => state.account.settings);
  const dispatch = useAppDispatch();
  const { setAccountVideoWallSettings, setIsFooterOpen } = useActions();
  const [settings, setSettings] = useState<VideoWallSettings>(videoWallSetting);
  const isAutoPaginationForVideoWall = useMemo(() => settings?.autoPagination && isVideoWall, [settings, isVideoWall]);
  const [pagination, setPagination] = usePaginationSearch();
  const openConfirmDialog = useDialog(ConfirmDialog);

  useEffect(() => {
    if (!isAutoPaginationForVideoWall) return undefined;

    handleSetPaginationStatus(true);

    const interval = setInterval(() => {
      const currentPage = pagination.total > pagination.page * pagination.limit ? pagination.page + 1 : LISTING_DEFAULT_PAGE_NUMBER;

      return setPagination({
        page: currentPage,
        limit: pagination.limit,
      });
    }, settings.autoPagination);

    return () => {
      handleSetPaginationStatus(false);
      clearInterval(interval);
      return undefined;
    };
  }, [settings?.autoPagination, pagination, isAutoPaginationForVideoWall, setPagination, handleSetPaginationStatus]);

  const handleReset = useCallback(async (): Promise<void> => {
    openConfirmDialog({ question: t('resetQuestion') }).beforeClose.subscribe(async (answerToQuestion: boolean): Promise<void> => {
      if (answerToQuestion) {
        setSettings(VIDEO_WALL_DEFAULT_STATE);
        setAccountVideoWallSettings({ settings: VIDEO_WALL_DEFAULT_STATE });
        await dispatch(changeAccountSettings({ videoWallSetting: { content: VIDEO_WALL_DEFAULT_STATE } }));
      }
    });
  }, [openConfirmDialog, t, setAccountVideoWallSettings, dispatch]);

  const handleSave = useCallback(() => {
    if (settings.isWithoutExpired) {
      setPagination(prev => ({ ...prev, page: 1 }));
    }
    dispatch(changeAccountSettings({ videoWallSetting: { content: settings } }));
    setIsFooterOpen(false);
  }, [settings, setIsFooterOpen, dispatch, setPagination]);

  const handleChangeRowsColumns = useCallback(
    (type: ArrowButtonType, property: SettingsProperty) => () => {
      setSettings(prevState => ({
        ...prevState,
        [property]: type === ArrowButtonType.INCREASE ? prevState[property] + 1 : prevState[property] - 1,
      }));
    },
    [setSettings],
  );

  const handleChangeMode = useCallback(
    (mode: string) => () => {
      setSettings(prevState => ({
        ...prevState,
        mode,
      }));
    },
    [setSettings],
  );

  const handleChangeCheckbox = useCallback(
    (field: SettingsProperty, checked: boolean) => {
      setSettings(prevState => ({
        ...prevState,
        [field]: checked,
      }));
    },
    [setSettings],
  );

  const handleChangeSlider = useCallback(
    (field: SliderSettingsProp, value: number | number[]) => {
      if (typeof value === 'number') {
        setSettings(prevState => ({
          ...prevState,
          [field]: value,
        }));
      }
    },
    [setSettings],
  );

  const handleChangePeriod = useCallback(
    (value: number) => () => {
      if (settings.autoPagination !== value) {
        setSettings(prevState => ({
          ...prevState,
          autoPagination: value,
        }));
      }
    },
    [settings.autoPagination],
  );

  return (
    <SettingsPanelWrapper>
      <Stack direction="row" spacing={2}>
        <Stack justifyContent="space-between" spacing={1}>
          <Typography variant="h4">{t('settings.headers.columnsRows')}</Typography>
          <CSDGridController settings={settings} onChangeMode={handleChangeMode} onChangeRowsColumns={handleChangeRowsColumns} />
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack justifyContent="space-between" spacing={1} data-testid="add-setting-block">
          <Typography variant="h4">{t('settings.headers.additionalSettings')}</Typography>
          <Stack direction="row" spacing={2}>
            <CSDButtonWrapperWithTooltip title={t('tooltips.settings.meteoData')} placement="top">
              <CSDSwitcher
                size="small"
                checked={settings.isShowMeteodata}
                label={t('settings.meteoData')}
                onChange={(_, checked) => {
                  handleChangeCheckbox(SettingsProperty.IS_SHOW_METEODATA, checked);
                }}
                data-testid="meteo-data-switcher"
              />
            </CSDButtonWrapperWithTooltip>
            <CSDButtonWrapperWithTooltip title={t('tooltips.settings.outdatedData')} placement="top">
              <CSDSwitcher
                checked={settings.isWithoutExpired}
                size="small"
                label={t('settings.outdatedData')}
                onChange={(_, checked) => {
                  handleChangeCheckbox(SettingsProperty.IS_WITHOUT_EXPIRED, checked);
                }}
                data-testid="outdated-data-switcher"
              />
            </CSDButtonWrapperWithTooltip>
            <CSDButtonWrapperWithTooltip title={t('tooltips.settings.alternativeTitles')} placement="top">
              <CSDSwitcher
                checked={settings.isShowAltNames}
                size="small"
                label={t('settings.alternativeTitles')}
                onChange={(_, checked) => {
                  handleChangeCheckbox(SettingsProperty.IS_SHOW_ALT_NAMES, checked);
                }}
                data-testid="alternative-titles-switcher"
              />
            </CSDButtonWrapperWithTooltip>
          </Stack>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack justifyContent="space-between" spacing={1}>
          <Typography variant="h4">{t('settings.headers.fontSize')}</Typography>
          <CSDTypographySetting settings={settings} onChangeSlider={handleChangeSlider} />
        </Stack>
        <Stack justifyContent="space-between" spacing={1}>
          <TitleWithButtonWrapper>
            <CSDButtonWrapperWithTooltip title={t('tooltips.settings.links')} placement="top" wrapperProps={{ style: { lineHeight: 1 } }}>
              <CSDSwitcher
                size="small"
                checked={settings.isShowLinks}
                onChange={(_, checked) => {
                  handleChangeCheckbox(SettingsProperty.IS_SHOW_LINKS, checked);
                }}
              />
            </CSDButtonWrapperWithTooltip>
            <Typography variant="h4">{t('settings.headers.linksSize')}</Typography>
          </TitleWithButtonWrapper>
          <CSDLinksSize value={settings.linksSize} disabled={!settings.isShowLinks} onChangeSlider={handleChangeSlider} />
        </Stack>
        <Divider orientation="vertical" flexItem />
        {isVideoWall && (
          <>
            <Stack justifyContent="space-between" spacing={1} data-testid="auto-pagination-block">
              <Typography variant="h4">{t('settings.headers.autoPagination')}</Typography>
              <CSDAutoPagination period={settings.autoPagination} onChangePeriod={handleChangePeriod} />
            </Stack>
            <Divider orientation="vertical" flexItem />
          </>
        )}
      </Stack>
      <CSDSettingActions onSave={handleSave} onReset={handleReset} />
    </SettingsPanelWrapper>
  );
};

const SettingsPanelWrapper = styled(Box)<BoxProps>({
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',
  height: '3.313rem',
});

const TitleWithButtonWrapper = styled(Box)<BoxProps>({
  display: 'flex',
  alignItems: 'center',
});

export default CSDVideoWallSettingsPanel;
