import React from 'react';
import CSDSvgText from './components/CSDSvgText';
import SvgDefs from './components/SvgDefs';
import CSDStationsLists from './components/CSDStation/CSDStationsLists';
import CSDDynamicRoad from './components/CSDDynamicRoad/CSDDynamicRoad';
import { LINEAR_GRADIENT_BLACK_ID, LINEAR_GRADIENT_GREEN_ID, IMG_WHITE_NOISE_PATTERN_ID } from './constants';
import { CSDStationItem } from './types';
import { validateStations } from './helpers';
import PositionType from './enums/PositionType';

type Props = {
  width?: string | number;
  height?: string | number;
  forwardRoadTitle?: string;
  backwardRoadTitle?: string;
  time?: string;
  forward: number;
  backward: number;
  stations: CSDStationItem[];
  activeStationId: number | string;
};

const CSDDynamicRoadInterface = ({
  forward,
  backward,
  width = '100%',
  height = '100%',
  backwardRoadTitle,
  forwardRoadTitle,
  time,
  stations = [],
  activeStationId,
}: Props) => {
  const roadCount = forward + backward;

  const { validStation, wrongStations } = validateStations(stations);
  const hasMiddleStation = !!validStation.find(({ position }) => position === PositionType.middle);

  // calc road height in % (2 - top and bottom green places)
  const roadHeight = 100 / (roadCount + 2);

  return (
    <svg width={width} height={height}>
      <rect width="100%" height="100%" strokeWidth={0} fill={`url(#${LINEAR_GRADIENT_GREEN_ID})`} rx="16" />
      <rect
        width="100%"
        height={`${roadHeight * roadCount}%`}
        strokeWidth={0}
        fill={`url(#${LINEAR_GRADIENT_BLACK_ID})`}
        style={{ transform: `translate(0, ${roadHeight}%)` }}
      />
      <rect
        width="100%"
        height="100%"
        fillOpacity={0.2}
        strokeWidth={0}
        fill={`url(#${IMG_WHITE_NOISE_PATTERN_ID})`}
        style={{ mixBlendMode: 'soft-light' }}
      />
      <CSDSvgText y={`${roadHeight / 2}%`} x="95%" textAnchor="end" dominantBaseline="middle" label={time} />
      <CSDDynamicRoad
        forward={forward}
        backward={backward}
        roadHeight={roadHeight}
        hasMiddleStation={hasMiddleStation}
        forwardRoadTitle={forwardRoadTitle}
        backwardRoadTitle={backwardRoadTitle}
      />
      <CSDStationsLists validStation={validStation} wrongStations={wrongStations} activeStationId={activeStationId} />
      <SvgDefs />
    </svg>
  );
};

export default CSDDynamicRoadInterface;
