import React, { useState } from 'react';
import get from 'lodash/get';
import { Icon, IconButton, Tooltip } from '@mui/material';
import { DocumentNode } from 'graphql/language/ast';
import { useQuery } from '@apollo/client';
import icons from '@app/assets/iconFont';
import { useDialog } from '@app/v2/shared/hooks';
import { DialogData } from '@app/components/common/dialog/types/DialogData';
import { DialogRef } from '@app/core/providers/DialogProvider';
import { EMPTY_STRING } from '@app/v2/shared/constants';
import { VMSEntityTypes } from '@app/v2/shared/enums';

interface Props<DataItem, DataVariables> extends Common.FeaturesActions {
  uiSettings?: { icon?: string; tooltipTitle?: string };
  entityType: VMSEntityTypes;
  query: DocumentNode;
  variables: DataVariables;
  dialogData: DialogData<DataItem>;
  dialog: React.FC<{ item: DataItem; dialogData: DialogData<DataItem> } & DialogRef>;
  disabled?: boolean;
}

const CSDUpdateScoreboardEntity = <DataItem, DataResponse extends Record<'vmsNew', Record<string, DataItem>>, DataVariables = { id: number }>({
  uiSettings,
  query,
  variables,
  dialogData,
  dialog,
  entityType,
  disabled,
  ...featureActions
}: Props<DataItem, DataVariables>) => {
  const [loading, setLoading] = useState<boolean>(false);

  const openDialog = useDialog<{ item: DataItem; dialogData: DialogData<DataItem> }>(dialog);

  const { refetch } = useQuery<DataResponse, DataVariables>(query, { skip: true });

  const handleUpdate = () => {
    setLoading(true);

    refetch(variables)
      .then(({ data }) =>
        openDialog({
          item: get(data?.vmsNew, entityType),
          dialogData: { ...dialogData, featureActions },
        }),
      )
      .finally(() => setLoading(false));
  };

  return (
    <Tooltip title={uiSettings?.tooltipTitle ?? EMPTY_STRING}>
      <span>
        <IconButton disabled={disabled || loading} onClick={handleUpdate}>
          <Icon>{uiSettings?.icon ?? icons.edit}</Icon>
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default CSDUpdateScoreboardEntity;
