import React, { useState } from 'react';
import { Box, Icon, Stack, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CSDKitDocBlock from '@app/modules/kit-module/shared/ui/CSDKitDocBlock/CSDKitDocBlock';
import icons from '@app/assets/iconFont';
import { showInfo } from '@app/core/utils/notifications';
import { CSDInput } from '@app/modules/kit-module/shared/ui';
import { EMPTY_STRING } from '@app/v2/shared/constants';

const CSDIcons = () => {
  const classes = useStyles();

  const [search, setSearch] = useState<string>(EMPTY_STRING);

  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="CUSAD Icons" codeExample="<Icon>{iconName}</Icon>">
        <Box className={classes.wrapper}>
          <Box
            sx={{
              width: '100%',
              padding: '0 11rem 2rem 12rem',
            }}
          >
            <CSDInput label="Search" placeholder="Enter icon name..." value={search} onChange={event => setSearch(event.target.value)} />
          </Box>

          {Object.entries(icons)
            .filter(([iconName]) => iconName.toLowerCase().includes(search.toLowerCase()))
            .map(([iconKey, iconName]) => (
              <Box key={iconName} className={classes.item}>
                <Tooltip title="Click for copy name">
                  <Icon
                    sx={{
                      cursor: 'pointer',
                      fontSize: '3rem',
                    }}
                    onClick={async () => {
                      await navigator.clipboard.writeText(iconKey);
                      showInfo('Name copied');
                    }}
                  >
                    {iconName}
                  </Icon>
                </Tooltip>

                <Box className={classes.title}>
                  <Typography>Inner: {iconKey}</Typography>
                  <Typography>Liga: {iconName}</Typography>
                </Box>
              </Box>
            ))}
        </Box>
      </CSDKitDocBlock>
    </Stack>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    gap: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  item: {
    gap: '1rem',
    display: 'flex',
    flexBasis: '20%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    gap: '0.2rem',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default CSDIcons;
