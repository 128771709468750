import DrawScoreboard from './DrawScoreboard';

export type PreviewMessageProps = {
  content: number[][][][];
  charsInterval: number;
  linesInterval: number;
  pixelSize: number;
  startCoords: { x: number; y: number };
  font: { h: number; w: number; color: string };
  background: string;
  itemSize: Scoreboards.Size;
  messageValign: string | null;
};

const previewMessage = (
  context: CanvasRenderingContext2D,
  {
    content,
    charsInterval,
    linesInterval,
    pixelSize,
    startCoords,
    font,
    background,
    itemSize: { margins, width, height },
    messageValign,
  }: PreviewMessageProps,
): Promise<void> => {
  const numLines = content.length;
  const messageHeight = numLines * font.h + (numLines - 1) * linesInterval + margins.top + margins.bottom;

  const additionalMarginTop = {
    top: 0,
    middle: Math.max(height - messageHeight, 0) / 2,
    bottom: Math.max(height - messageHeight, 0),
  };

  const drawScoreboard = new DrawScoreboard({
    x: (startCoords.x + margins.left) * pixelSize,
    y: (startCoords.y + margins.top + additionalMarginTop[messageValign]) * pixelSize,
  });

  drawScoreboard.drawBoard(context, content, {
    size: pixelSize,
    intervals: {
      line: linesInterval,
      char: charsInterval,
    },
    font,
    background,
    margins,
    width,
    height,
    additionalMarginTop: additionalMarginTop[messageValign],
  });

  return Promise.resolve();
};

export default previewMessage;
