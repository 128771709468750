import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Stack, Button } from '@mui/material';
import useMapProfileContext from '@app/v2/features/mapProfiles/hooks/useMapProfileContext';
import getViewportData from '@app/core/helpers/getViewportData';
import { isDefaultMapProfile } from '@app/v2/shared/helpers';
import { useAppSelector } from '@app/v2/shared/hooks';

const Viewport = () => {
  const { t } = useTranslation('map');
  const viewport = useAppSelector(state => state.map.viewport);

  const {
    center: [latitude, longitude],
    zoom,
  } = getViewportData(viewport);

  const { handleSaveCoordinates, profileData, currentActiveProfile } = useMapProfileContext();

  const {
    center: [profileLatitude, profileLongitude],
    zoom: profileZoom,
  } = getViewportData(profileData.viewport);

  const isDisabled = useMemo(
    () => (profileZoom === zoom && profileLatitude === latitude && profileLongitude === longitude) || isDefaultMapProfile(currentActiveProfile),
    [longitude, latitude, zoom, profileLatitude, profileLongitude, profileZoom, currentActiveProfile],
  );

  return (
    <>
      <Typography variant="h4" sx={{ margin: '1rem 0' }}>
        {t('mapSettingLabels.centreAndScaleMap')}
      </Typography>
      <Box sx={{ display: 'flex', marginBottom: '1rem' }}>
        <Stack spacing={1}>
          <Typography variant="body1">{`${t('notes.headers.latitude')}: ${latitude}`}</Typography>
          <Typography variant="body1">{`${t('notes.headers.longitude')}: ${longitude}`}</Typography>
          <Typography variant="body1">{`Zoom: ${zoom}`}</Typography>
        </Stack>
        <Button sx={{ marginLeft: '2rem' }} disabled={isDisabled} onClick={handleSaveCoordinates}>
          {t('mapCardControl.buttons.updateMapCoordinates')}
        </Button>
      </Box>
    </>
  );
};

export default Viewport;
