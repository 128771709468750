import RoadStatusConstants from '@app/modules/federal-roads-module/utils/constants';

const ONE_KILOMETRE = 1;
const METERS_AMOUNT_IN_A_KILOMETER = 1000;
const METERS_DIGITS_AMOUNT = 3;

export function findCurrentVersion(versions: FederalRoads.Version[]): FederalRoads.Version {
  if (!versions?.length || versions.every(version => !version)) {
    return null;
  }

  return versions.find(version => version.isCurrent) || versions[0];
}

export function isShowEditHeader(isModerator: boolean, versions: FederalRoads.Version[]): boolean {
  return isModerator && !!versions?.find(version => version.status === RoadStatusConstants.NEW_ROAD_STATUS);
}

export function isShowEditCell(isModerator: boolean, status: string): boolean {
  return isModerator && status === RoadStatusConstants.NEW_ROAD_STATUS;
}

export function isUserCanEditCurrentVersion(isModerator: boolean, version: FederalRoads.Version): boolean {
  if (!version) {
    return false;
  }

  return isModerator;
}

const formatNumberWithAtLeastThreeDigits = num => {
  if (!num) {
    return num;
  }

  return num.toString().padStart(METERS_DIGITS_AMOUNT, '0');
};

function convertToNumber(str: string) {
  return parseFloat(str.replace(/,/g, ''));
}

function convertToFormattedString(num: Number) {
  return num.toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 });
}

export const formatLength = length => {
  if (!length) {
    return length;
  }

  let result = length;
  if (typeof result === 'string' && result.includes(',')) {
    result = convertToNumber(result);

    if (typeof result !== 'number') {
      return length;
    }

    return convertToFormattedString(Number(result.toFixed(METERS_DIGITS_AMOUNT)));
  }

  result = Number(result);
  if (typeof result !== 'number') {
    return length;
  }

  result = result.toFixed(METERS_DIGITS_AMOUNT);
  return length instanceof Number ? result : `${result}`;
};

export const formatAddress = (address: string) => {
  if (!address || !address.includes('+')) {
    return address;
  }
  const [kilometers, meters] = address.split('+');
  return `${kilometers}+${formatNumberWithAtLeastThreeDigits(meters)}`;
};

export function calculateLengths({ addressStart, addressEnd }: { addressStart: string; addressEnd: string }): {
  lengthKm: string;
  lengthM: string;
} {
  const [startKm, startM] = addressStart.split('+');
  const [endKm, endM] = addressEnd.split('+');

  let lengthKm = Number(endKm) - Number(startKm);
  let lengthM = Number(endM) - Number(startM);

  if (lengthM < 0) {
    lengthKm -= ONE_KILOMETRE;
    lengthM += METERS_AMOUNT_IN_A_KILOMETER;
  }

  return { lengthKm: `${lengthKm}`, lengthM: formatNumberWithAtLeastThreeDigits(lengthM) };
}
