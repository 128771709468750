import { RoadObjectsTypes } from '@app/v2/shared/enums';

const dataRoadObjects: Record<RoadObjectsTypes, string> = {
  [RoadObjectsTypes.METEO_PARAMS_FIRST]: 'meteoStation',
  [RoadObjectsTypes.VIDEO]: 'videoStations',
  [RoadObjectsTypes.TRAFFIC]: 'trafficStations',
  [RoadObjectsTypes.SIGN_STATIONS]: 'signStations',
  [RoadObjectsTypes.MESSAGE_STATIONS]: 'messageStations',
  [RoadObjectsTypes.METEO_PARAMS_SECOND]: 'meteoStation',
  [RoadObjectsTypes.ROAD_ADDRESS]: 'id',
};

export default dataRoadObjects;
