import { ApolloLink, Operation } from '@apollo/client';

export default function factory(middleware: (operation: Operation) => Operation): ApolloLink {
  return new ApolloLink((operation, forward) => {
    let newOperation = operation;

    if (middleware && typeof middleware === 'function') {
      newOperation = middleware(operation);
    }

    return forward(newOperation);
  });
}
