import moment, { Moment } from 'moment';
import { DATE_FORMAT } from '@app/v2/shared/constants';

export const formatEn = (date: number | string | Moment): string => moment(date).format(DATE_FORMAT.FORMAT_EN);
export const formatEnFull = (date: number | string | Moment): string => moment(date).format(DATE_FORMAT.FORMAT_EN_FULL);
export const formatAPI = (date: number | string | Moment): string => moment(date).format(DATE_FORMAT.API_DATE_FORMAT);
export const formatRu = (date: number | string | Moment): string => moment(date).format(DATE_FORMAT.FORMAT_RU);
export const formatRuPoint = (date: number | string | Moment): string => moment(date).format(DATE_FORMAT.FORMAT_RU_POINT);
export const formatRuShort = (date: number | string | Moment): string => moment(date).format(DATE_FORMAT.FORMAT_RU_SHORT);
export const formatRuFull = (date: number | string | Moment): string => moment(date).format(DATE_FORMAT.FORMAT_RU_FULL);
export const formatRuUnfull = (date: number | string | Moment): string => moment(date).format(DATE_FORMAT.FORMAT_RU_UNFULL_TIME);
export const formatUnix = (date: number | string | Moment): number => moment(date).unix();
export const formatRuPointFullDateAndShortTime = (date: number | string | Moment): string =>
  moment(date).format(DATE_FORMAT.FORMAT_RU_POINT_FULL_DATE_AND_UNFULL_TIME);
