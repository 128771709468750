import React, { memo } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import TwoFactorAuthenticationForm from '@app/modules/auth-module/components/two-factorAuthenticationForm/TwoFactorAuthenticationForm';
import { useTitle } from '@app/v2/shared/hooks';
import styles from './index.module.scss';

const Layout = ({ t }: WithTranslation<'auth', 'login'>) => {
  useTitle(t('moduleTitle'));

  return (
    <div className={styles['auth-wrapper']}>
      <TwoFactorAuthenticationForm />
    </div>
  );
};

export default withTranslation('auth', { keyPrefix: 'login' })(memo(Layout));
