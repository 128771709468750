import React from 'react';
import { Tooltip, TooltipProps } from '@mui/material';

interface Props extends TooltipProps {
  wrapperProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;
}

const CSDButtonWrapperWithTooltip = ({ wrapperProps, children, ...tooltipProps }: Props) => {
  return (
    <Tooltip {...tooltipProps}>
      {/* span -> https://mui.com/material-ui/react-tooltip/#disabled-elements */}
      <span {...wrapperProps}>{children}</span>
    </Tooltip>
  );
};

export default CSDButtonWrapperWithTooltip;
