import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Theme, Box, BoxProps, CircularProgress, CircularProgressProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useImageLoad from '@app/v2/shared/hooks/useImageLoad';
import { useDialog } from '@app/v2/shared/hooks';
import CSDWaterLevelSnapshotDialog from './CSDWaterLevelSnapshotDialog';

type StyleType = {
  backgroundImage: string;
};

type Props = {
  chartData: WLC.ChartData;
  roadTitle: string;
};

const CSDWaterLevelSnapshot = ({ chartData, roadTitle }: Props) => {
  const openSnapshotDialog = useDialog(CSDWaterLevelSnapshotDialog);
  const lastSnapshot = useMemo(() => chartData.content.snapshot[chartData.content.snapshot.length - 1], [chartData]);
  const lastSnapshotDateTime = useMemo(() => chartData.dates[chartData.dates.length - 1], [chartData]);

  const { src: imageSrc, loading: imageLoading } = useImageLoad(lastSnapshot || null);

  const bgImageUrl = useMemo(() => imageSrc && lastSnapshot && `url(${imageSrc})`, [imageSrc, lastSnapshot]);

  const classes = useStyles({ backgroundImage: bgImageUrl });

  return (
    <StyledWrapper>
      {imageLoading && (
        <Box className={classes.loaderStyles}>
          <StyledCircularProgress />
        </Box>
      )}
      <Box
        className={classes.snapshot}
        onClick={() =>
          openSnapshotDialog({
            snapshotUrl: lastSnapshot,
            kilometer: chartData.place.address,
            meter: chartData.place.meters,
            title: roadTitle,
            dateTime: lastSnapshotDateTime,
          })
        }
      />
    </StyledWrapper>
  );
};

const StyledCircularProgress = styled(CircularProgress)<CircularProgressProps>(
  ({
    theme: {
      palette: {
        common: { white },
      },
    },
  }) => ({
    color: white,
    size: '3rem',
  }),
);

const StyledWrapper = styled(Box)<BoxProps>(
  ({
    theme: {
      palette: { text },
    },
  }) => ({
    border: `0.125rem solid ${text.t1}`,
    borderRadius: '0.5rem',
    cursor: 'pointer',
    height: '100%',
    width: '100%',
    position: 'relative',
  }),
);

const useStyles = makeStyles<Theme, StyleType>(theme => ({
  snapshot: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    backgroundImage: ({ backgroundImage }) => backgroundImage,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    borderRadius: '0.5rem',
  },
  loaderStyles: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    background: 'rgba(103, 103, 103, 0.51)',
    zIndex: 100,
  },
}));

export default CSDWaterLevelSnapshot;
