import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, Icon, IconButton } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { DialogRef } from '@app/core/providers/DialogProvider';
import icons from '@app/assets/iconFont';
import themeCreator from '@theme/index';
import CSDViewDeviceSettingsDialogContent from './CSDViewDeviceSettingsDialogContent';
import CSDViewDeviceSettingsDialogPanel from './CSDViewDeviceSettingsDialogPanel';

interface Props extends DialogRef {
  devices: {
    stationId: number;
    settings: Scoreboards.DeviceSettings;
  }[];
}

const CSDViewDeviceSettingsDialog = ({ devices, close }: Props) => {
  const { t } = useTranslation('scoreboard');

  const [activeId, setActiveId] = useState<number>(devices?.[0]?.stationId);

  return (
    <ThemeProvider theme={themeCreator()}>
      <Dialog open maxWidth="sm" onClose={() => close()}>
        <DialogTitle sx={{ paddingBottom: 0 }}>
          {t('devices.info.title')}

          <IconButton onClick={() => close()}>
            <Icon>{icons.close}</Icon>
          </IconButton>
        </DialogTitle>

        {devices.length > 1 && <CSDViewDeviceSettingsDialogPanel setActiveId={setActiveId} activeId={activeId} devices={devices} />}

        <CSDViewDeviceSettingsDialogContent activeId={activeId} devices={devices} />
      </Dialog>
    </ThemeProvider>
  );
};

export default CSDViewDeviceSettingsDialog;
