import { useRef, useEffect, useState, useCallback } from 'react';
import { MESSAGE, REDIRECT, START } from '@app/workers/constants';
// @ts-ignore
import CountdownWorker from '@app/workers/countdown.worker';

type Return = {
  sendTimeStamp: (timeStamp: string) => void;
  addHandler: (cb: Common.AnyFunction) => void;
  removeHandler: (cb: Common.AnyFunction) => void;
};

export default function useCountdownWorker(): Return {
  const [timeStamp, setState] = useState<string>('');
  const eventsHandlers = useRef<Set<Common.AnyFunction>>(new Set());

  const worker = useRef<Worker>();

  useEffect(() => {
    worker.current = new CountdownWorker();

    const onMessage = ({ data }: MessageEvent): void => {
      if (data === REDIRECT) {
        Array.from(eventsHandlers.current.values()).forEach(fn => fn());
      }
    };

    if (typeof timeStamp === 'string' && timeStamp.length) {
      worker.current?.postMessage({ timeStamp });
    }

    worker.current?.postMessage(START);
    worker.current?.addEventListener(MESSAGE, onMessage);

    return () => {
      worker.current?.removeEventListener(MESSAGE, onMessage);
      worker.current?.terminate();
      worker.current = undefined;
    };
  }, [timeStamp]);

  const sendTimeStamp = (time: string): void => {
    setState(time);
  };

  const addEventListener = useCallback((cb: Common.AnyFunction) => {
    if (typeof cb === 'function') {
      eventsHandlers.current.add(cb);
    }
  }, []);

  const removeEventListener = useCallback((cb: Common.AnyFunction) => {
    if (typeof cb === 'function') {
      eventsHandlers.current.delete(cb);
    }
  }, []);

  return {
    sendTimeStamp,
    addHandler: addEventListener,
    removeHandler: removeEventListener,
  };
}
