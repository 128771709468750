import { TFunction } from 'react-i18next';
import parameters from '@app/core/constants/parameters/parameters';
import { TrafficViewType } from '@app/core/types/charts/traffic';

export default function prepareSeriesParameters({
  content,
  keysSetup,
  typeView,
  parametersT,
  commonT,
}: {
  content: Traffic.ChartData<number[]>;
  keysSetup: Record<TrafficViewType, string[]>;
  typeView: TrafficViewType;
  parametersT: TFunction<'parameters'>;
  commonT: TFunction<'common'>;
}) {
  if (!content) return [];

  return keysSetup[typeView].map(key => {
    const {
      label: name,
      chartOptions: { lineColor: color },
      unitsOptions: { value },
    } = parameters[key];

    return {
      color,
      name: parametersT(name),
      data: content[key],
      tooltip: { valueSuffix: ` ${commonT(value)}` },
    };
  });
}
