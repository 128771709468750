import Yup from '@app/core/validation/locale';

const Schema = Yup.object<Omit<Scoreboards.TemplateModel, 'id'>>().shape({
  title: Yup.string().required(),
  isCommon: Yup.boolean().required(),
  organizationId: Yup.string().nullable(true),
  content: Yup.object().shape({
    configuration: Yup.object().shape({
      id: Yup.string().required(),
      title: Yup.string(),
    }),
  }),
});

export default Schema;
