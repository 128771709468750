/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Moment } from 'moment';
import { ReducersNames } from '@store/constants';
import { MeteoChartViewTypes } from '@app/v2/shared/enums';

interface State {
  dateFrom: Moment | null;
  dateTo: Moment | null;
  viewMode: MeteoChartViewTypes;
  chartsData: Record<string, Omit<Meteo.ChartPointParameters, 'chartId'>>;
  chartsSetup: Meteo.ChartsSetup;
  amountsOfPoints: number;
}

export const initialState: State = {
  dateFrom: null,
  dateTo: null,
  viewMode: MeteoChartViewTypes.HistoryAndForecast,
  chartsData: {},
  chartsSetup: [],
  amountsOfPoints: 0,
};

export const initialCartValue: Omit<Meteo.ChartPointParameters, 'chartId'> = {
  currentValueIndex: 0,
  data: [],
  date: null,
  isFixed: false,
  isFixedOnForecast: false,
  coords: { y: 0, x: 0 },
  dialogData: { meteoStationId: null, kilometer: null, meter: null, title: null, dateTime: null },
};

const meteoChartSlice = createSlice({
  name: ReducersNames.meteoChart,
  initialState,
  reducers: {
    setDateFrom(state: State, { payload }: PayloadAction<{ value: Moment }>) {
      state.dateFrom = payload.value;
    },
    setDateTo(state: State, { payload }: PayloadAction<{ value: Moment }>) {
      state.dateTo = payload.value;
    },
    setMeteoChartInitialDates(state: State, { payload }: PayloadAction<Pick<State, 'dateFrom' | 'dateTo'>>) {
      state.dateFrom = payload.dateFrom;
      state.dateTo = payload.dateTo;
    },
    setViewMode(state: State, { payload }: PayloadAction<{ value: MeteoChartViewTypes }>) {
      state.viewMode = payload.value;
    },
    setMeteoCharts(state: State, { payload }: PayloadAction<Pick<Meteo.ChartPointParameters, 'chartId'>>) {
      if (state.chartsData[payload.chartId]) return;
      state.chartsData[payload.chartId] = initialCartValue;
    },
    removeMeteoChartById(state: State, { payload }: PayloadAction<Pick<Meteo.ChartPointParameters, 'chartId'>>) {
      if (!state.chartsData[payload.chartId]) return;
      delete state.chartsData[payload.chartId];
    },
    toggleIsFixed(state: State, { payload }: PayloadAction<Pick<Meteo.ChartPointParameters, 'chartId' | 'isFixed'>>) {
      if (!state.chartsData[payload.chartId]) return;
      state.chartsData[payload.chartId].isFixed = payload.isFixed;
    },
    setMeteoChartsParameters(state: State, { payload }: PayloadAction<Meteo.ChartPointParameters>) {
      const { chartId, ...other } = payload;
      state.chartsData[chartId] = other;
    },
    setMeteoChartsSetup(state: State, { payload }: PayloadAction<Meteo.ChartsSetup>) {
      state.chartsSetup = payload;
    },
    setAmountsOfPointsMeteoChart(state: State, { payload }: PayloadAction<Pick<State, 'amountsOfPoints'>>) {
      state.amountsOfPoints = payload.amountsOfPoints;
    },
    clearMeteoCharts(state: State) {
      state = initialState;
    },
  },
});

export const meteoChartActions = meteoChartSlice.actions;

export default meteoChartSlice.reducer;
