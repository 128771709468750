import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAppSelector from '../hooks/reactRedux/useAppSelector';
import { Languages } from '../enums/Languages';

export default function useLanguageChange(): Languages {
  const { i18n } = useTranslation();

  const { locale } = useAppSelector(state => state.account.settings);

  useEffect(() => {
    const changeLanguage = async (ln: Languages): Promise<void> => {
      await i18n.changeLanguage(ln?.toUpperCase());

      const html = document.getElementsByTagName('html');
      Array.from(html).forEach(tag => tag.setAttribute('lang', ln?.toLowerCase()));
    };

    changeLanguage(locale);
  }, [i18n, locale]);

  return locale;
}
