import React from 'react';
import { withTranslation } from 'react-i18next';
import { Typography, Slider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isFunction } from '@app/v2/shared/helpers';

const TemplateSizeFilter = ({ label, values, onChange, maxValue, t }: Filters.SlidersConfigItem & I18N.TranslationFunction<'filters'>) => {
  const classes = useStyles();

  const handleChange = (_, value: number | number[]) => {
    if (!Array.isArray(value) || !isFunction(onChange)) return;
    onChange(value);
  };

  return (
    <div className={classes.wrapper}>
      <Typography gutterBottom>{label}</Typography>

      <Slider value={values} max={maxValue} onChange={handleChange} valueLabelDisplay="auto" aria-labelledby="range-slider" />
      <div className={classes.valuesWrapper}>
        <div>{`${t('additional.from')} ${values[0]}`}</div>
        <div>{`${t('additional.to')} ${values[1]}`}</div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
  },
  valuesWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    gap: '1rem',

    '& > div': {
      border: '1.5px lightgray solid',
      padding: 5,
      maxWidth: 100,
      borderRadius: 5,
      minWidth: 60,
    },
  },
});

export default withTranslation('filters')(TemplateSizeFilter);
