import { RefObject, useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import usePrevious from '@app/core/hooks/usePrevious';

export default function useScrollEffect(ref: RefObject<any>, name: string) {
  const { submitCount, getFieldMeta } = useFormikContext();

  const prev = usePrevious(submitCount);

  const { error, touched } = getFieldMeta(name);

  const isError = useMemo(() => error && touched, [error, touched]);

  useEffect(() => {
    if (isError && prev !== submitCount) {
      ref.current.scrollIntoView(true);
    }
  }, [ref, isError, prev, submitCount]);
}
