import { useCallback } from 'react';
import useAppSelector from '@app/core/hooks/customReduxHooks/useAppSelector';
import { UsersAccess } from '@app/v2/shared/enums';

export default function useCheckAccess() {
  const { permissions } = useAppSelector(state => state.account.settings);

  const accessChecker = useCallback(
    (accessRights: UsersAccess[]) => {
      return permissions.some(permission => accessRights.includes(permission)) || permissions.includes(UsersAccess.ROOT);
    },
    [permissions],
  );

  return { accessChecker };
}
