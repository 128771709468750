import React from 'react';
import { Box, BoxProps, Icon, IconButton, Stack, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import icons from '@app/assets/iconFont';
import { isFunction, isNotNullAndUndefined, isString, strictlyEqual } from '@app/v2/shared/helpers';
import { DataTestIds, MeteoParameters } from '@app/v2/shared/enums';
import { getScrollStyles } from '@theme/themeSettings/componentSettings';
import usePrepareNotNumberValues from './usePrepareNotNumberValues';

type Props = Omit<Meteo.ChartPointParameters, 'chartId' | 'currentValueIndex'> & {
  onClose: Common.VoidCallBack;
  openDialog?: Common.VoidCallBack;
  isFixedOnForecast?: boolean;
};

const CSDMeteoChartTooltip = ({ date, coords, data, isFixed, onClose, openDialog, isFixedOnForecast }: Props) => {
  const notNumberValues = usePrepareNotNumberValues({
    windGusts: data.find(({ key }) => strictlyEqual(key, MeteoParameters.WindGusts)),
    windSpeed: data.find(({ key }) => strictlyEqual(key, MeteoParameters.WindSpeed)),
  });

  const notNumberValueKeys = Object.keys(notNumberValues);

  const filteredValues: (Meteo.ChartPointParameters['data'][0] & { number: string })[] = data
    .map(item => {
      if (!notNumberValueKeys.includes(item.key)) return item;
      return notNumberValues[item.key](item);
    })
    .filter(({ value }) => isNotNullAndUndefined(value));

  return (
    <Wrapper sx={{ left: coords.x, top: coords.y }} data-testid={DataTestIds.MeteoChartTooltip}>
      <Header>
        <span>{date}</span>

        <Box>
          {isFixedOnForecast && (
            <IconButton data-testid={DataTestIds.MeteoChartTooltipCameraBtn} onClick={() => isFunction(openDialog) && openDialog()}>
              <Icon>{icons.camera}</Icon>
            </IconButton>
          )}

          <IconButton
            data-testid={DataTestIds.MeteoChartTooltipCloseBtn}
            onClick={() => {
              if (!isFixed || !isFunction(onClose)) return;
              onClose();
            }}
          >
            <Icon>{icons.close}</Icon>
          </IconButton>
        </Box>
      </Header>
      <Point>
        {!!filteredValues.length &&
          filteredValues.map(({ icon, value, valueSuffix, name, number }) => (
            <Tooltip title={name} key={`${name}${valueSuffix}`}>
              <Stack direction="row" gap="0.35rem" alignItems="center" justifyContent="flex-star" position="relative">
                {number && <span className="dpg">{number}</span>}

                <Icon className="dpgIcon">{icons[icon] ?? icons.empty}</Icon>
                {value}
                {!isString(value) && valueSuffix}
              </Stack>
            </Tooltip>
          ))}
      </Point>
    </Wrapper>
  );
};

export default CSDMeteoChartTooltip;

const Wrapper = styled(Box)<BoxProps>({
  position: 'absolute',
  maxWidth: '15rem',
  minWidth: '15rem',
  backgroundColor: '#6F6E6E',
  borderRadius: '5px',
  borderColor: 'transparent',
  opacity: 0.8,
  color: '#fff',
  padding: '0.35rem 0.5rem 0.35rem 0.5rem',
});

const Header = styled(Box)<BoxProps>({
  color: '#ffffff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '0.5rem 0.5rem 0.25rem 0.5rem',
  fontSize: '1rem',
  fontWeight: '700',
  borderBottom: '1px solid white',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '0.25rem',
  },
});

const Point = styled(Box)<BoxProps>(({ theme: { palette } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  rowGap: '0.5rem',
  columnGap: '0.5rem',
  fontSize: '1rem',
  padding: '0.4rem 0.3rem 0.5rem 0.3rem',
  flexWrap: 'wrap',
  maxHeight: '22rem',
  overflow: 'auto',
  ...getScrollStyles(palette.mode, palette.text),

  '& .dpg': {
    position: 'absolute',
    fontSize: '10px',
    top: '4px',
    left: '7px',
  },
}));
