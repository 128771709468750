import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import uniqBy from 'lodash/uniqBy';
import { ApolloQueryResult } from '@apollo/client';
import PLACE_DATA_POST_DIALOG from '@app/clients/apollo/requests/queries/others/placeDataPostDialog.graphql';
import client from '@app/clients/apollo/client';
import getHumanizedAddress from '@app/core/helpers/getHumanizedAddress';
import { showError } from '@app/core/utils/notifications';
import videoQueries from '@app/clients/apollo/requests/queries/video';
import type { ViewData, PlacePost, SignMessagesStation, Station } from '../types';

type Props = {
  placeId: number;
  video: number[];
  close: (data?: string) => void;
  children: (data: ViewData) => React.ReactNode;
};

type videoResponse = {
  videoStationDetails: Video.StationDetail;
};

type InitialData = {
  loading: Boolean;
  data: Data;
};

type Data = {
  map: PlacePost[];
};

const getVideoStation = async (id: number) => {
  const {
    data: {
      videoStationDetails: { owner, position, directionAlias, isExpired },
    },
  } = await client.query<videoResponse>({ query: videoQueries.missingVideoStation, variables: { stationId: id } });
  return { id, owner, position, direction: directionAlias, isExpired };
};

const getPlaceData = (placeId: number): Promise<ApolloQueryResult<Data>> => {
  return client.query<Data>({
    query: PLACE_DATA_POST_DIALOG,
    variables: { placeId },
  });
};

export default function DialogData({ placeId, close, video, children }: Props) {
  const [initDataState, setInitialDataState] = useState<InitialData>({
    loading: true,
    data: { map: null },
  });

  const { t } = useTranslation('common', { keyPrefix: 'units' });

  const [videoDataState, setVideoDataState] = useState<Station[]>(null);
  const [placeState, setPlaceState] = useState<PlacePost>(null);
  const isLoaded = useMemo(() => Boolean(placeState && placeState.videoStations), [placeState]);

  const getMissingStations = useCallback(
    attachedVideoStations => {
      const missingStations = [];
      const stationsIds = attachedVideoStations.map(({ id }) => id);

      const missingStationIds = video.filter(id => stationsIds.indexOf(id) === -1);

      for (const id of missingStationIds) {
        const result = getVideoStation(id);
        missingStations.push(result);
      }

      return Promise.all(missingStations);
    },
    [video],
  );

  const getAttachedStations = (newPlaceData: PlacePost): Station[] => {
    const placeVideoStations = newPlaceData?.videoStations || [];
    const { messageStations, signStations } = newPlaceData;

    const normalizeStations = (stations: SignMessagesStation[]) => {
      return stations.filter(({ videoStationId }) => videoStationId).map(({ videoStationId, owner }) => ({ id: videoStationId, owner }));
    };

    if (Array.isArray(signStations)) {
      placeVideoStations.push(...normalizeStations(signStations));
    }
    if (Array.isArray(messageStations)) {
      placeVideoStations.push(...normalizeStations(messageStations));
    }

    return uniqBy(placeVideoStations, 'id');
  };

  useEffect(() => {
    const getVideoStations = async (newPlaceData: PlacePost) => {
      try {
        const attachedStations = getAttachedStations(newPlaceData);
        const missingStations = await getMissingStations(attachedStations);
        return [...attachedStations, ...missingStations];
      } catch (e) {
        if (e instanceof Error) {
          showError(e.message);
        } else {
          throw e;
        }
        return null;
      }
    };

    const getVideoData = () => {
      const {
        loading,
        data: { map },
      } = initDataState;

      if (!loading && map == null) {
        close('Error');
        return null;
      }
      if (!Array.isArray(map) || map.length === 0) {
        return null;
      }

      const newPlaceData: PlacePost = map[0];

      return getVideoStations(newPlaceData);
    };

    Promise.resolve()
      .then(getVideoData)
      .then(videoData => setVideoDataState(videoData))
      .catch(e => showError(e.message));
  }, [close, getMissingStations, initDataState, initDataState.loading, placeId]);

  useEffect(() => {
    Promise.resolve()
      .then(() => getPlaceData(placeId))
      .then(initialData => setInitialDataState(initialData));
  }, [placeId]);

  useEffect(() => {
    const normalizeData = () => {
      const {
        data: { map },
      } = initDataState;

      if (!map) return null;

      const normalizedData: PlacePost = {
        ...map[0],
        videoStations: videoDataState || map[0].videoStations,
      };

      return normalizedData;
    };

    Promise.resolve()
      .then(normalizeData)
      .then(normalizedData => setPlaceState(normalizedData))
      .catch(e => showError(e.message));
  }, [initDataState, videoDataState]);

  const dialogTitle = useMemo(() => {
    if (placeState && placeState?.kilometer != null && placeState?.meter != null) {
      const { kilometer, meter } = placeState;
      return `${getHumanizedAddress(t('kilometers'), kilometer, meter)}`;
    }

    return '';
  }, [placeState, t]);

  const content = useMemo(() => {
    return children({ placeData: placeState, loading: !isLoaded, dialogTitle });
  }, [children, dialogTitle, isLoaded, placeState]);

  return <>{content}</>;
}
