import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { CSDIconPanelPaper, CSDSwitcher } from '@app/modules/kit-module/shared/ui';
import { useLayoutConfig, useSystem } from '@app/v2/shared/hooks';
import { DEFAULT_FOOTER_HEIGHT } from '@app/v2/shared/constants';
import { DataTestIds } from '@app/v2/shared/enums';

type Props = {
  switcherLabel?: string;
} & WithTranslation<'filters'>;

const CSDHeaderCollapsibleSwitcher = ({ switcherLabel, t }: Props) => {
  const {
    headerConfig: { isViewVideoWall },
  } = useLayoutConfig();

  const { handleChangeHeaderStatus, isHeaderRollUp, isVideoWall, handleSetCurrentFooterHeight } = useSystem();
  const isDisabled = isViewVideoWall && isVideoWall;

  const handleChange = (_, checked: boolean): void => {
    handleChangeHeaderStatus(checked);
    handleSetCurrentFooterHeight(checked ? DEFAULT_FOOTER_HEIGHT : 0);
  };

  return (
    <CSDIconPanelPaper>
      <Tooltip title={isDisabled && t('notifications.videoWallIsUsed')}>
        {/* span -> https://mui.com/material-ui/react-tooltip/#disabled-elements */}
        <span>
          <CSDSwitcher
            dataTestId={DataTestIds.FilterSwitcher}
            checked={isHeaderRollUp}
            size="small"
            disabled={isDisabled}
            label={switcherLabel || t('label')}
            onChange={handleChange}
          />
        </span>
      </Tooltip>
    </CSDIconPanelPaper>
  );
};

export default withTranslation('filters')(CSDHeaderCollapsibleSwitcher);
