import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Box, BoxProps, Button, ButtonProps, Icon, Popover, Stack, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSDInput } from '@app/modules/kit-module/shared/ui';
import { usePopover } from '@app/v2/shared/hooks';
import { EMPTY_STRING, METEO_CHART_PROFILE_INPUT_LENGTH } from '@app/v2/shared/constants';
import icons from '@app/assets/iconFont';
import { COLORS } from '@theme/constants';
import useMeteoChartProfileActions from '../../hooks/useMeteoChartProfileActions';

interface Props {
  icon?: string;
  tooltipTitle?: string;
  buttonLabel?: string;
  buttonProps?: ButtonProps;
}

const CSDCreateMeteoChartProfile = ({
  icon = icons.plus,
  tooltipTitle,
  buttonProps,
  buttonLabel,
  t,
}: Props & I18N.TranslationFunction<'charts', 'meteoConstructor'>) => {
  const [profileName, setProfileName] = useState<string>(EMPTY_STRING);
  const { isOpen, openPopover, closePopover, anchorEl } = usePopover();

  const { handleCreateMeteoChartProfile } = useMeteoChartProfileActions();

  const handleClose = () => {
    setProfileName(EMPTY_STRING);
    closePopover();
  };

  const handleCreate = () => {
    handleCreateMeteoChartProfile(profileName);
    handleClose();
  };

  return (
    <>
      <Tooltip title={tooltipTitle ?? t('createProfile')}>
        <Button variant="outlined" onClick={openPopover} {...buttonProps}>
          {buttonLabel || <Icon sx={{ fontSize: '1rem' }}>{icon}</Icon>}
        </Button>
      </Tooltip>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        slotProps={{ paper: { style: { backgroundColor: COLORS.white } } }}
        marginThreshold={0}
      >
        <Wrapper>
          <CSDInput
            autoFocus
            fullWidth
            size="small"
            label={t('addProfileNameLabel')}
            placeholder={t('addProfileNamePlaceholder')}
            inputProps={{ maxLength: METEO_CHART_PROFILE_INPUT_LENGTH }}
            value={profileName}
            onChange={({ target }) => setProfileName(target.value)}
          />

          <Stack direction="row" gap="0.5rem">
            <Button size="small" variant="contained" disabled={!profileName} onClick={handleCreate}>
              <Icon>{icons.done}</Icon>
            </Button>
            <Button size="small" variant="outlined" onClick={handleClose}>
              <Icon>{icons.close}</Icon>
            </Button>
          </Stack>
        </Wrapper>
      </Popover>
    </>
  );
};

export default withTranslation('charts', { keyPrefix: 'meteoConstructor' })(CSDCreateMeteoChartProfile);

const Wrapper = styled(Box)<BoxProps>({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '168px',
  padding: '1.5rem',
  gap: '0.5rem',
});
