import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { useAppSelector } from '@app/v2/shared/hooks';
import { DATE_FORMAT } from '@app/v2/shared/constants';
import type { PreparedWaterLevelChartData } from './types';
import {
  prepareYAxisParameters,
  prepareXAxisParameters,
  prepareSeriesParameters,
  preparePlotOptionsParameters,
  prepareTooltip,
} from './chartOptionsPreparationFns';

interface Parameters {
  title: string;
  data: PreparedWaterLevelChartData;
  isFixed: boolean;
  currentIndex: number;
  keys: string[];
  fixTooltipPosition: (value: WLC.ChartPointData) => void;
}

export default function useWeatherLevelOptions({ data, title, isFixed, fixTooltipPosition, currentIndex, keys }: Parameters) {
  const { t: commonT } = useTranslation('common');
  const { t: parameterT } = useTranslation('parameters');
  const { t: constantsT } = useTranslation('constants');
  const utc = useAppSelector(state => state.account.settings.utc);
  const isWithTimeZoneOutput = useAppSelector(state => state.account.settings.timezoneInsteadCheckbox);

  const timezoneOffset = isWithTimeZoneOutput
    ? moment.parseZone(moment().utcOffset(utc), DATE_FORMAT.WITH_TIME_ZONE).utcOffset()
    : moment.parseZone(data?.dates?.[0]).utcOffset();

  const convertDataToMomentWithOffeset = (date: moment.MomentInput): moment.Moment => {
    return moment.utc(date).utcOffset(timezoneOffset);
  };

  const { dates = [], content } = data;

  return {
    options: {
      accessibility: {
        enabled: false,
      },
      legend: {
        itemStyle: {
          cursor: 'default',
          color: '#000000',
        },
      },
      time: {
        // Used offset like in ECMAScript getTimezoneOffset method. https://api.highcharts.com/highcharts/time.timezoneOffset
        timezoneOffset: -timezoneOffset,
      },
      exporting: {
        enabled: false,
      },
      title: {
        text: title,
        style: {
          color: 'black',
          fontWeight: 'bold',
        },
      },
      lang: {
        noData: commonT('notificationMessages.noDataForPeriod'),
      },
      noData: {
        style: {
          fontWeight: '700',
          fontSize: '24px',
          color: '#222631',
        },
      },
      credits: {
        enabled: false,
      },
      chart: {
        zoomType: 'x',
        marginLeft: 50,
        events: {
          click(this: any) {
            if (!this?.hoverPoint || !this?.tooltip || !this?.userOptions) return;
            if (isFixed) return;

            fixTooltipPosition({
              index: this.hoverPoint.index,
              date: convertDataToMomentWithOffeset(this.hoverPoint.category).format(DATE_FORMAT.FORMAT_RU_UNFULL_DATE_AND_TIME),
              coords: { x: this.tooltip.now.x + 36, y: this.tooltip.now.y + 16 },
              currentPointData: this.userOptions.series.reduce((acc, { icon, name, tooltip, label }) => {
                acc[label] = { icon, name, valueSuffix: tooltip.valueSuffix };
                return acc;
              }, {}),
            });
          },
        },
      },
      boost: {
        useGPUTranslations: true,
        seriesThreshold: 1,
      },
      tooltip: prepareTooltip(isFixed, constantsT, convertDataToMomentWithOffeset),
      plotOptions: preparePlotOptionsParameters(),
      yAxis: prepareYAxisParameters({ keys, commonT }),
      xAxis: prepareXAxisParameters({ dates, isFixed, currentIndex, convertDataToMomentWithOffeset }),
      series: prepareSeriesParameters({ content, dates, keys, parameterT, commonT, convertDataToMomentWithOffeset }),
    },
  };
}
