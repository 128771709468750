import React from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, IconButton, Tooltip } from '@mui/material';
import { useMutation } from '@apollo/client';
import vmsNewMutations from '@app/clients/apollo/requests/mutations/vmsNew';
import { useCheckAccess, useDialog } from '@app/v2/shared/hooks';
import { CSDConfirmDialog } from '@app/modules/kit-module/shared/ui';
import { showSuccess } from '@app/core/utils/notifications';
import icons from '@app/assets/iconFont';
import { addFeatureActions } from '@app/v2/shared/utils';
import { DataTestIds, UsersAccess } from '@app/v2/shared/enums';

interface Props extends Common.FeaturesActions {
  stationId: number;
}

const CSDDetachPlaylistFromDeviceBtn = ({ stationId, t, ...featuresCbs }: Props & I18N.TranslationFunction<'scoreboard'>) => {
  const { accessChecker } = useCheckAccess();

  const openConfirmDialog = useDialog(CSDConfirmDialog);

  const [detachPlaylist, { loading }] = useMutation<Common.MutationResponse, Scoreboards.DetachPlaylistFromStationVariables>(
    vmsNewMutations.playlist.add,
  );

  const handleDetach = () => {
    if (!stationId) return;

    openConfirmDialog({ question: t('playlists.actions.confirmationQuestionOnDetach') }).beforeClose.subscribe((answerToQuestion: boolean) => {
      if (!answerToQuestion) return;

      addFeatureActions(
        detachPlaylist({ variables: { stationId, playlistId: null } }).then(({ data: { message } }) => showSuccess(message)),
        featuresCbs,
      );
    });
  };

  return (
    <Tooltip title={t('playlists.actions.detach')}>
      <IconButton
        onClick={handleDetach}
        disabled={loading || !accessChecker([UsersAccess.VMS_LIMIT_EDIT, UsersAccess.VMS_FULL_EDIT])}
        data-testid={DataTestIds.DetachPlaylistFromDeviceBtn}
      >
        <Icon>{icons.close}</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default withTranslation('scoreboard')(CSDDetachPlaylistFromDeviceBtn);
