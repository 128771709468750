import React from 'react';
import { withTranslation } from 'react-i18next';
import { Box, IconButton, Typography, Icon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import icons from '@app/assets/iconFont';

type Props = { onClose } & I18N.TranslationFunction<'dialogs', 'alertSubscriptions'>;

const CSDNotificationSettingsTitle = ({ onClose, t }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.title}>
      <Typography variant="h2">{t('title')}</Typography>
      <IconButton onClick={onClose}>
        <Icon>{icons.close}</Icon>
      </IconButton>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
  },
}));

export default withTranslation('dialogs', { keyPrefix: 'alertSubscriptions' })(CSDNotificationSettingsTitle);
