export enum AutoPaginationPeriodsInSeconds {
  fifteen = 15000,
  thirty = 30000,
  minute = 60000,
}

export enum AlertStatusVideo {
  Notice = 'notice',
  Alert = 'alert',
  Warning = 'warning',
  Expired = 'expired',
  noData = 'noData',
}

export enum VideoStationMode {
  IMAGE_MODE = 'image',
  VIDEO_MODE = 'video',
}

export enum ScrollDirection {
  UP = 'up',
  DOWN = 'down',
}

export enum VideoActionButtons {
  VIDEO = 'video',
  TRAFFIC = 'traffic',
  METEO = 'meteo',
  RIVER_LEVEL = 'riverLevel',
}
