import { useLocation } from 'react-router-dom';
import layoutConfig from '../configs/layout';

const layoutConfigInitialValue: StaticConfigs.Layout = {
  permissionScopes: [],
  headerConfig: {
    isViewFilters: false,
    isViewFiltersPresets: false,
    isViewInfoBadgeWithForecast: false,
    isViewRoadsSegments: false,
    isViewReportBtn: false,
    isViewVideoWall: false,
    isViewPagination: false,
  },
  footerConfig: {
    footerTabs: [],
    defaultTab: false,
    isOpenByDefault: false,
  },
  filterConfig: {
    isViewPresets: true,
    isViewActions: true,
    isDisabledOrganizations: false,
    isDisabledRoads: false,
    isDisabledPlaces: false,
    filterPermissionScopes: [],
  },
};

export default function useLayoutConfig(): Partial<StaticConfigs.Layout> {
  const { pathname } = useLocation();

  return {
    permissionScopes: (layoutConfig[pathname] ?? {})?.permissionScopes ?? layoutConfigInitialValue.permissionScopes,
    headerConfig: { ...layoutConfigInitialValue.headerConfig, ...(layoutConfig[pathname] ?? {})?.headerConfig },
    footerConfig: { ...layoutConfigInitialValue.footerConfig, ...(layoutConfig[pathname] ?? {})?.footerConfig },
    filterConfig: { ...layoutConfigInitialValue.filterConfig, ...(layoutConfig[pathname] ?? {})?.filterConfig },
  };
}
