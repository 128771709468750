import { i18n } from '@app/v2/shared/localization';
import { MeteoChartContainerWidth, ProfilesTypes } from '@app/v2/shared/enums';

export const getDefaultProfileSettings = (type: ProfilesTypes): Profiles.CommonProfileItem[] => {
  const settings: Partial<Record<ProfilesTypes, Profiles.CommonProfileItem[]>> = {
    [ProfilesTypes.meteo]: [
      { key: 'tAir', checked: true },
      { key: 'tRoad', checked: true },
      { key: 'tFreezingPoint', checked: false },
      { key: 'humidity', checked: true },
      { key: 'tDewPoint', checked: false },
      { key: 'precipitationType', checked: true },
      { key: 'precipitationIntensity', checked: true },
      { key: 'statusDamp', checked: true },
      { key: 'waterHeight', checked: false },
      { key: 'windDirection', checked: true, dependencies: ['windSpeed'] },
      { key: 'windSpeed', checked: true },
      { key: 'windGusts', checked: true },
      { key: 'pressure', checked: false },
      { key: 'salineConcentration', checked: true },
      { key: 'tUnderroad', checked: true },
      { key: 'cloudiness', checked: false },
      { key: 'statusDamp2', checked: false },
      { key: 'tRoad2', checked: false },
      { key: 'tUnderroad2', checked: false },
      { key: 'tFreezingPoint2', checked: false },
      { key: 'salineConcentration2', checked: false },
      { key: 'windDirGr', checked: true, dependencies: ['windSpeed'] },
      { key: 'visibility', checked: false },
      { key: 'precipitationInstant', checked: false },
    ],
    [ProfilesTypes.traffic]: [
      { key: 'trTotalVehicles', checked: true },
      { key: 'trCars', checked: true },
      { key: 'trSmallTrucks', checked: true },
      { key: 'trTrucks', checked: true },
      { key: 'trBigTrucks', checked: true },
      { key: 'trRoadTrains', checked: true },
      { key: 'trBuses', checked: false },
      { key: 'trMotorcycles', checked: false },
      { key: 'trSpeed', checked: true },
      { key: 'trOccupancy', checked: true },
      { key: 'trGap', checked: true },
    ],
    [ProfilesTypes.linearGraph]: [
      { key: 'tAir', checked: true },
      { key: 'tRoad', checked: true },
      { key: 'tFreezingPoint', checked: false },
      { key: 'humidity', checked: false },
      { key: 'tDewPoint', checked: true },
      { key: 'precipitationType', checked: true },
      { key: 'precipitationIntensity', checked: false },
      { key: 'statusDamp', checked: false },
      { key: 'waterHeight', checked: false },
      { key: 'windDirection', checked: true },
      { key: 'windSpeed', checked: false },
      { key: 'windGusts', checked: false },
      { key: 'visibility', checked: false },
      { key: 'pressure', checked: false },
      { key: 'salineConcentration', checked: false },
      { key: 'tUnderroad', checked: false },
      { key: 'cloudiness', checked: false },
    ],
    [ProfilesTypes.planGraph]: [
      { key: 'alerts', checked: true },
      { key: 'recommendations', checked: true },
    ],
    [ProfilesTypes.adaptationToClimateChange]: [
      { key: 'dpgT01', checked: true },
      { key: 'dpgT02', checked: true },
      { key: 'dpgT03', checked: true },
      { key: 'dpgT04', checked: true },
      { key: 'dpgT05', checked: true },
      { key: 'dpgT06', checked: true },
      { key: 'dpgT07', checked: true },
      { key: 'dpgT08', checked: true },
      { key: 'dpgT09', checked: true },
      { key: 'dpgT10', checked: true },
      { key: 'dpgT11', checked: true },
      { key: 'dpgT12', checked: true },
      { key: 'dpgT13', checked: true },
      { key: 'dpgT14', checked: true },
      { key: 'dpgT15', checked: true },
    ],
    [ProfilesTypes.sops]: [
      { key: 'tAir', checked: true },
      { key: 'tRoad', checked: true },
      { key: 'tFreezingPoint', checked: false },
      { key: 'humidity', checked: true },
      { key: 'tDewPoint', checked: false },
      { key: 'precipitationType', checked: true },
      { key: 'precipitationIntensity', checked: true },
      { key: 'precipitationInstant', checked: false },
      { key: 'statusDamp', checked: true },
      { key: 'waterHeight', checked: false },
      { key: 'windDirGr', checked: true, dependencies: ['windSpeed'] },
      { key: 'windSpeed', checked: true },
      { key: 'windGusts', checked: true },
      { key: 'visibility', checked: false },
      { key: 'pressure', checked: true },
      { key: 'salineConcentration', checked: true },
      { key: 'tUnderroad', checked: true },
      { key: 'cloudiness', checked: false },
      { key: 'progRecCnctr', checked: true },
    ],
    [ProfilesTypes.stationDetails]: [
      { key: 'tAir', checked: true },
      { key: 'tRoad', checked: true },
      { key: 'tDewPoint', checked: true },
      { key: 'tUnderroad', checked: true },
      { key: 'rainIntensity', checked: true },
      { key: 'snowIntensity', checked: true },
      { key: 'windSpeed', checked: true },
      { key: 'humidity', checked: true },
    ],
  };

  return settings[type];
};

export const getDefaultMeteoChartProfileSettings = (): Meteo.ChartProfile['items'] => {
  return [
    {
      id: -1,
      name: i18n.t('charts:meteoConstructor.defaultCommon'),
      order: 0,
      containerWidth: MeteoChartContainerWidth.Full,
      parameters: {
        tAir: true,
        tUnderroad: true,
        tRoad: true,
        precipitationIntensity: true,
        precipitationType: true,
        statusDamp: true,
        pressure: true,
        humidity: true,
        windSpeed: true,
        windGusts: true,
      },
    },
    {
      id: -2,
      name: i18n.t('charts:meteoConstructor.defaultAir'),
      order: 1,
      containerWidth: MeteoChartContainerWidth.Full,
      parameters: { tAir: true, tDewPoint: true, humidity: true, visibility: true, pressure: true },
    },
    {
      id: -3,
      name: i18n.t('charts:meteoConstructor.defaultPrecipitation'),
      order: 2,
      containerWidth: MeteoChartContainerWidth.Full,
      parameters: { precipitationIntensity: true, precipitationInstant: true, precipitationType: true },
    },
    {
      id: -4,
      name: i18n.t('charts:meteoConstructor.defaultRoad'),
      order: 3,
      containerWidth: MeteoChartContainerWidth.Full,
      parameters: { tUnderroad: true, tUnderroad2: true, tRoad: true, tRoad2: true, statusDamp: true, statusDamp2: true, tAir: true },
    },
    {
      id: -5,
      name: i18n.t('charts:meteoConstructor.defaultWind'),
      order: 4,
      containerWidth: MeteoChartContainerWidth.Full,
      parameters: { windSpeed: true, windGusts: true },
    },
  ];
};
