import client from '@app/clients/apollo/client';
import ErrorMessages from '@app/core/constants/errorsMessages';
import { showError } from '@app/core/utils/notifications';
import { systemActions } from '@app/store/slices/system';
import otherQueries from '@app/clients/apollo/requests/queries/others';

// eslint-disable-next-line import/prefer-default-export
export const getStartPages = () => async dispatch => {
  dispatch(systemActions.setStartPagesLoading(true));

  try {
    const { data } = await client.query({ query: otherQueries.startPages });
    dispatch(systemActions.setStartPages(data));
  } catch (error) {
    showError(ErrorMessages.COMMON_ERROR_MESSAGE);
  } finally {
    dispatch(systemActions.setStartPagesLoading(false));
  }
};
