import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link, Box, Typography, Icon } from '@mui/material';
import moment from 'moment';
import { formatRuUnfull } from '@app/core/helpers/dateFormat';
import icons from '@app/assets/iconFont';

interface Props extends I18N.TranslationFunction<'accidentNews'> {
  date: string;
  link: string;
}

const CSDAccidentNewsCardContent = ({ link, date, t }: Props) => {
  return (
    <Box className="cardContent">
      {link ? (
        <Link href={link} target="_blank">
          <Typography>{t('card.content.link')}</Typography>
        </Link>
      ) : (
        <Icon>{icons.noData}</Icon>
      )}

      <Box className="accidentNewsDate">
        <Typography>{t('card.content.dateTime')}</Typography>

        {date ? formatRuUnfull(moment(date)) : <Icon>{icons.noData}</Icon>}
      </Box>
    </Box>
  );
};

export default withTranslation('accidentNews')(CSDAccidentNewsCardContent);
