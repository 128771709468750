import React, { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Theme } from '@mui/material/styles';
import { Drawer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useActions, useAppSelector, useSystem, useCheckAccess } from '@app/v2/shared/hooks';
import { isDefaultMapProfile, isDefaultProfile, isFunction, strictlyEqual } from '@app/v2/shared/helpers';
import useNodeSize from '@app/core/hooks/useNodeSize';
import useMapProfileContext from '@app/v2/features/mapProfiles/hooks/useMapProfileContext';
import useProfileContext from '@app/core/context/profile/useProfileContext';
import { FooterTabs } from '@app/v2/shared/enums';
import TabPanelWrapper from './TabPanels/TabPanelWrapper';
import TabBar from './components/TabBar';
import StyledFooterPanelContainer from './containers/StyledFooterPanelContainer';
import StyledFooterTabsContainer from './containers/StyledFooterTabsContainer';
import tabList from '../configurations/tabList';
import FooterNodesIds from '../types/footerNodesIds';
import { FooterStylesParameters } from '../types/footerStylesParameters';

type Props = {
  tabs?: FooterTabs[];
  defaultTab?: FooterTabs | false;
  isOpenByDefault?: boolean;
  isOnlyIconLabel?: boolean;
};

const CSDFooter = ({ tabs = [], defaultTab, isOpenByDefault, isOnlyIconLabel = false }: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [isRecalculateContainerHeight, setIsRecalculateContainerHeight] = useState<boolean>(false);

  const { changeFooterTab, setIsFooterOpen } = useActions();
  const { accessChecker } = useCheckAccess();

  const currentFooterTab = useAppSelector(state => state.map.currentFooterTab);
  const isFooterOpen = useAppSelector(state => state.map.isFooterOpen);

  const availableTabs = useMemo(
    () => tabList.filter(({ name, permissions }) => tabs.includes(name) && (permissions ? accessChecker(permissions) : true)),
    [tabs, accessChecker],
  );

  const currentTabData = availableTabs.find(({ name }) => name === currentFooterTab);
  const isFooterTab = currentFooterTab && [FooterTabs.VideoWallSettings, FooterTabs.WaterLevelControlSettings].includes(currentFooterTab);

  const { isHeaderRollUp, isVideoWall, handleSetCurrentFooterHeight } = useSystem();
  const { width } = useNodeSize(containerRef);

  const { currentActiveProfile: activeMapProfile } = useMapProfileContext();
  const { activeProfile, isSaveProfileMode } = useProfileContext();
  const isUseDefaultProfile = (activeMapProfile && isDefaultMapProfile(activeMapProfile)) || (activeProfile && isDefaultProfile(activeProfile));

  const handleTransitionMapper: Record<string, Common.AnyFunction> = {
    height: (height: number) => handleSetCurrentFooterHeight(height),
  };

  const classes = useStyles({
    isFooterTab,
    open: isFooterOpen,
    hasPreview: currentTabData?.hasPreview,
    isTabSelected: currentFooterTab === currentTabData?.name,
    isHeaderRollUp,
    containerHeight,
  });

  useLayoutEffect(() => {
    if ((containerRef?.current && isFooterOpen) || (isRecalculateContainerHeight && isFooterOpen)) {
      setContainerHeight(containerRef.current.offsetHeight);
    }
    if (isRecalculateContainerHeight) {
      setIsRecalculateContainerHeight(false);
    }
  }, [containerRef, width, currentFooterTab, isFooterOpen, setContainerHeight, isUseDefaultProfile, isSaveProfileMode, isRecalculateContainerHeight]);

  useEffect(() => {
    const isTabAvailable = availableTabs.find(({ name }) => name === defaultTab);
    if (isTabAvailable && defaultTab) {
      changeFooterTab(defaultTab);
    }
    if (isTabAvailable && defaultTab && isOpenByDefault) {
      setIsFooterOpen(true);
    }
    return () => {
      // value false for changeFooterTab mean that no one tab select (false || FooterTabs)
      changeFooterTab(false);
      setIsFooterOpen(false);
    };
  }, [availableTabs, changeFooterTab, defaultTab, isOpenByDefault, setIsFooterOpen]);

  useEffect(() => {
    if (isVideoWall) {
      setIsFooterOpen(false);
    }
  }, [setIsFooterOpen, isVideoWall]);

  const handleTabChange = useCallback(
    (_, newValue: FooterTabs) => {
      setIsFooterOpen(true);
      changeFooterTab(newValue);
    },
    [changeFooterTab, setIsFooterOpen],
  );

  const handleOpenTabPanel = (tabName: FooterTabs) => () => {
    if (tabName === currentFooterTab) setIsFooterOpen(!isFooterOpen);
  };

  const cbRef = (tabsContentElement: HTMLDivElement | null): void => {
    if (!tabsContentElement) return;

    handleSetCurrentFooterHeight(tabsContentElement.clientHeight);
  };

  const handleTransitionEnd = (tabsContentElement: HTMLDivElement, propertyName: string): void => {
    if (!tabsContentElement) return;

    if (strictlyEqual<string>(tabsContentElement.id, FooterNodesIds.TabsContentContainer)) {
      isFunction(handleTransitionMapper[propertyName]) && handleTransitionMapper[propertyName](tabsContentElement.clientHeight);
    }
  };

  return (
    <Drawer variant="permanent" anchor="bottom" PaperProps={{ className: classes.drawerPaper }}>
      <StyledFooterTabsContainer isFooterTab={isFooterTab} open={isFooterOpen}>
        <TabBar
          tabs={availableTabs}
          currentValue={(currentTabData && currentFooterTab) ?? false}
          onTabChange={handleTabChange}
          onTabSecondClick={handleOpenTabPanel}
          isOnlyIconLabel={isOnlyIconLabel && !isFooterOpen}
          indicationIconsSettings={{
            isTabsOpen: isFooterOpen,
            position: { right: '0.25rem' },
            size: { height: '1.5rem', width: '1.5rem' },
          }}
        />
      </StyledFooterTabsContainer>
      <StyledFooterPanelContainer
        id={FooterNodesIds.TabsContentContainer}
        ref={cbRef}
        open={isFooterOpen}
        hasPreview={currentTabData?.hasPreview}
        isTabSelected={currentFooterTab === currentTabData?.name}
        isHeaderRollUp={isHeaderRollUp}
        containerHeight={containerHeight}
        onTransitionEnd={({ currentTarget, propertyName }) => {
          handleTransitionEnd(currentTarget, propertyName);
        }}
      >
        {!!currentTabData && (
          <TabPanelWrapper
            ref={containerRef}
            key={currentTabData.name}
            className={classes.tabWrapper}
            value={currentFooterTab}
            name={currentTabData.name}
          >
            <currentTabData.Panel
              recalculateContainerHeight={() => setIsRecalculateContainerHeight(true)}
              hideProfileActions={currentTabData?.hasPreview && !isFooterOpen && currentFooterTab === currentTabData?.name}
            />
          </TabPanelWrapper>
        )}
      </StyledFooterPanelContainer>
    </Drawer>
  );
};

const useStyles = makeStyles<Theme, FooterStylesParameters>(theme => ({
  linearProgress: {
    position: 'absolute',
    bottom: '0.5rem',
    width: '100%',
  },
  drawerPaper: {
    '&.MuiPaper-root': {
      background: 'transparent',
      border: 'none',
      overflow: 'inherit',
      boxShadow: '0px -3px 5px 0px #34343433',
    },
    position: 'relative',
  },
  tabWrapper: {
    padding: '1rem 1.5rem',
  },
}));

export default memo(CSDFooter);
