import { TFunction } from 'react-i18next';
import moment from 'moment/moment';
import Highcharts, { TooltipFormatterContextObject } from 'highcharts';
import icons from '@app/assets/iconFont';
import { getTextWindDirection } from '@app/core/constants/windDirections';
import { degrees2Rhumbs, isNotNullAndUndefined } from '@app/v2/shared/helpers';
import statusDampTypes from '@app/core/constants/statusDampTypes';
import { DATE_FORMAT, EMPTY_STRING } from '@app/v2/shared/constants';
import { COLORS } from '@theme/constants';
import { PrecipitationTypeConfig } from '@app/v2/shared/configs';

export default function prepareTooltip(
  isFixed: boolean,
  constantsT: TFunction<'constants'>,
  convertDataToMomentWithOffeset: (date: moment.MomentInput) => moment.Moment,
): Highcharts.TooltipOptions {
  return {
    animation: false,
    enabled: !isFixed,
    shared: true,
    distance: 4,
    useHTML: true,
    shape: 'square',
    valueDecimals: 0,
    backgroundColor: '#6F6E6E',
    borderRadius: 10,
    borderColor: 'transparent',
    padding: 0,
    style: {
      fontFamily: 'Roboto',
      color: COLORS.white,
      opacity: 0.8,
      width: 240,
      margin: 0,
      padding: '0',
    },
    formatter(this: TooltipFormatterContextObject) {
      const render = this.points.map(point => {
        const icon = icons[(point?.series?.userOptions as any)?.icon] ?? icons.dot;
        const label = (point?.series?.userOptions as any)?.label;
        const value = point.y;
        const valueSuffix = point.series.userOptions.tooltip?.valueSuffix;
        const tooltipConfigValue = (point as any)?.point?.tooltipConfig?.value;

        if (!valueSuffix) return EMPTY_STRING;

        const notNumberValue = {
          precipitationType: tooltipConfigValue ? constantsT(PrecipitationTypeConfig[tooltipConfigValue]?.label) : null,
          windDirGr: isNotNullAndUndefined(tooltipConfigValue)
            ? `${constantsT(getTextWindDirection(degrees2Rhumbs(tooltipConfigValue)))} ${tooltipConfigValue}`
            : null,
          statusDamp: statusDampTypes[tooltipConfigValue] ? constantsT(statusDampTypes[tooltipConfigValue]?.label) : null,
          statusDamp2: statusDampTypes[tooltipConfigValue] ? constantsT(statusDampTypes[tooltipConfigValue]?.label) : null,
        };

        const notNumberValueKeys = Object.keys(notNumberValue);

        const valueOfTemplate = notNumberValueKeys.includes(label) ? notNumberValue?.[label] : value;

        const iconTemplate = `<span class="custom-icon">${icon}</span>`;
        const valueTemplate = `<span class="tooltipValue">${valueOfTemplate}${valueSuffix}</span>`;

        if (!isNotNullAndUndefined(valueOfTemplate)) return EMPTY_STRING;

        return `<div class="tooltipPoint">${iconTemplate} ${valueTemplate}</div>`;
      });

      return `<div class="tooltipContainer">
                <div class="tooltipHeader"><span>${convertDataToMomentWithOffeset(this.x).format(
                  DATE_FORMAT.FORMAT_RU_UNFULL_DATE_AND_TIME,
                )}</span></div>
                <div class="tooltipBody">${render.join('')}<div>
              </div>`;
    },
  };
}
