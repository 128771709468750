import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { DocumentNode } from 'graphql/language/ast';
import { QueryHookOptions } from '@apollo/client/react/types/types';
import { OperationVariables } from '@apollo/client/core';
import { showErrorWithoutCodes } from '@app/core/utils/notifications';
import { typedMemo } from '@app/v2/shared/utils';
import { isFunction } from '@app/v2/shared/helpers';

interface Props<ResponseData, TData, TVariables> {
  query: DocumentNode;
  options?: QueryHookOptions<ResponseData, TVariables>;
  variables?: TVariables;
  normalizer?: (data: ResponseData) => TData;
  children: ({ data, error, loading }: { data: TData; error: ApolloError; loading: boolean }) => ReactNode;
}

const CSDApolloQuery = <ResponseData, TVariables = OperationVariables, TData = ResponseData>({
  query,
  options,
  variables,
  normalizer,
  children,
}: Props<ResponseData, TData, TVariables>) => {
  const abortControllerRef = useRef<AbortController>(new AbortController());

  const {
    data: responseData,
    loading,
    error,
  } = useQuery<ResponseData, TVariables>(query, {
    variables,
    ...options,
    context: {
      fetchOptions: {
        signal: abortControllerRef.current.signal,
      },
    },
  });

  if (error) showErrorWithoutCodes(error.message);

  const data = useMemo(() => {
    if (!!normalizer && isFunction(normalizer) && !loading && responseData) {
      return normalizer(responseData);
    }

    // todo: Need to compare generics when returning a value
    return responseData as any as TData;
  }, [loading, normalizer, responseData]);

  useEffect(() => {
    const signal = abortControllerRef.current;

    return () => {
      signal.abort();
    };
  }, []);

  return <>{children({ data, error, loading })}</>;
};

export default typedMemo(CSDApolloQuery);
