import React from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, IconButton, Tooltip } from '@mui/material';
import icons from '@app/assets/iconFont';
import { MeteoChartContainerWidth } from '@app/v2/shared/enums';
import useMeteoChartProfileActions from '../../hooks/useMeteoChartProfileActions';

interface Props extends I18N.TranslationFunction<'charts', 'meteoConstructor'> {
  currentValue: MeteoChartContainerWidth;
  profileId: string;
  setupId: string;
  tooltipTitle?: string;
}

const CSDResizeMeteoChart = ({ currentValue, profileId, setupId, tooltipTitle, t }: Props) => {
  const { handleResizeChart } = useMeteoChartProfileActions();

  return (
    <Tooltip title={tooltipTitle ?? t('changeSize')}>
      <IconButton onClick={() => handleResizeChart(profileId, setupId)}>
        <Icon>{currentValue === MeteoChartContainerWidth.Half ? icons.resize : icons.collapse}</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default withTranslation('charts', { keyPrefix: 'meteoConstructor' })(CSDResizeMeteoChart);
