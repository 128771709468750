import { TooltipFormatterContextObject, TooltipOptions, TooltipPositionerPointObject } from 'highcharts';

export default function prepareTooltipForPlanChart(): TooltipOptions {
  return {
    hideDelay: 300,
    useHTML: true,
    formatter(this: TooltipFormatterContextObject) {
      return this.point.options.description;
    },
    positioner(labelWidth: number, labelHeight: number, point: TooltipPositionerPointObject) {
      let x = this.chart.plotLeft + point.plotX - labelWidth / 2;

      if (this.chart.plotWidth - point.plotX < labelWidth / 2) {
        x = this.chart.plotLeft + this.chart.plotWidth - labelWidth;
      }

      if (point.plotX < labelWidth / 2) {
        x = this.chart.plotLeft;
      }

      return {
        x,
        y: point.plotY + labelHeight,
      };
    },
  };
}
