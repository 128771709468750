import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Stack, Icon, Button, IconButton } from '@mui/material';
import { DialogRef } from '@app/core/providers/DialogProvider';

const CSDDialogExample = ({ close }: DialogRef) => {
  return (
    <Dialog open>
      <DialogTitle>
        <Typography variant="h2">Title</Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <IconButton>
            <Icon sx={{ color: ({ palette }) => palette.success.main }}>done</Icon>
          </IconButton>
          <IconButton onClick={() => close()}>
            <Icon>close</Icon>
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ minHeight: '25rem' }}>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cupiditate, dolorum exercitationem iusto molestiae natus qui! Alias aliquam, aperiam
        cum dolor incidunt labore magnam modi odit quos repudiandae sequi voluptas voluptatem. Lorem ipsum dolor sit amet, consectetur adipisicing
        elit. Cupiditate, dolorum exercitationem iusto molestiae natus qui! Alias aliquam, aperiam cum dolor incidunt labore magnam modi odit quos
        repudiandae sequi voluptas voluptatem.
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={1}>
          <Button variant="outlined">
            <Icon>done</Icon>
          </Button>
          <Button variant="outlined" onClick={() => close()}>
            <Icon>close</Icon>
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default CSDDialogExample;
