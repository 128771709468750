export enum ArrowButtonType {
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE',
}
export enum SliderSettingsProp {
  FONT_SIZE = 'fontSize',
  LINKS_SIZE = 'linksSize',
}

export enum SettingsProperty {
  COLUMNS = 'cols',
  ROWS = 'rows',
  BOTH = 'both',
  IS_SHOW_ALT_NAMES = 'isShowAltNames',
  IS_WITHOUT_EXPIRED = 'isWithoutExpired',
  IS_SHOW_METEODATA = 'isShowMeteodata',
  IS_SHOW_LINKS = 'isShowLinks',
}
