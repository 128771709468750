import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import SupportMessage from '@app/modules/auth-module/components/supportMessage/SupportMessage';
import useDialog from '@app/core/hooks/useDialog';
import LicenseDialog from '@app/components/dialogs/license-dialog/LicenseDialog';

const AuthFormActions = ({ t }: I18N.TranslationFunction<'auth', 'login'>) => {
  const classes = useStyles();

  const openDialog = useDialog(LicenseDialog);

  return (
    <Grid container padding="0 1.5rem 1.5rem 1.5rem">
      <Grid item xs={12}>
        <SupportMessage variant="body2" generalContent={t('cardContent.cardSupportMessage')} supportLink={t('cardContent.cardSupportLink')} />
      </Grid>
      <Grid item xs={12} margin="1.25rem 0">
        <Button type="submit" size="large" fullWidth>
          {t('cardActions.signIn')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="column" alignItems="center">
          <Typography variant="body2" className={classes.usageRules}>
            {t('cardContent.termsOfUseMessage')}
          </Typography>
          <button type="button" className={classes.termsOfUseLink} onClick={openDialog}>
            {t('cardContent.termsOfUseLink')}
          </button>
        </Stack>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles<Theme>(({ palette }) => ({
  termsOfUseLink: {
    color: palette.text.t2,
    fontSize: '0.75rem',
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  usageRules: {
    color: palette.text.t2,
    padding: '0 0.625rem',
    textAlign: 'center',
  },
}));

export default withTranslation('auth', { keyPrefix: 'login' })(memo(AuthFormActions));
