import { TFunction } from 'react-i18next';
import { Chart, PointLabelObject } from 'highcharts';
import { PlanGraphData, PlanGraphExportingInfo } from '@app/components/charts/plan-dialog/types';
import { chartInfoBoxOffsetForExporting, headerHeight } from '../../constants/planChart';
import { drawInfoBoxForExporting, drawYAxisBackground } from '../../helpers';

export default function prepareExportingForPlanChart(
  chart: Chart,
  nameOfPlace: string,
  planGraph: PlanGraphData,
  t: TFunction<'charts'>,
): Partial<Highcharts.ExportingOptions> {
  const info: PlanGraphExportingInfo = {
    address: nameOfPlace,
    id: planGraph?.stationId,
    road: planGraph?.road?.code,
    owner: planGraph?.owner,
  };

  const marginTopForExporting = headerHeight + chartInfoBoxOffsetForExporting;

  return {
    sourceHeight: chart.chartHeight + chartInfoBoxOffsetForExporting,
    sourceWidth: chart.chartWidth,
    chartOptions: {
      chart: {
        marginTop: marginTopForExporting,
        events: {
          redraw(this) {
            drawInfoBoxForExporting(this, info, t);
            drawYAxisBackground(this);
          },
        },
      },
      series: [
        {
          type: 'gantt',
          dataLabels: {
            formatter(this: PointLabelObject) {
              return (this.point as any).options.shortDescription;
            },
          },
        },
      ],
    },
  };
}
