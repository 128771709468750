import * as Yup from 'yup';
import { i18n } from '@app/v2/shared/localization';

export interface ValidationSchemeState extends Pick<Auth.PasswordRecoveryStepTwoParams, 'password'> {
  passwordRepeat: string;
}

export const EQUAL_VALIDATION_KEY = 'mustBeTheSame';

export const validationSchema = Yup.object().shape<ValidationSchemeState>({
  password: Yup.string().trim().required(i18n.t('validation:required')),
  passwordRepeat: Yup.string()
    .trim()
    .required(i18n.t('validation:required'))
    .when('password', (password, schema) => {
      return schema.test({
        test: passwordRepeat => !!password && !!passwordRepeat && password === passwordRepeat,
        message: EQUAL_VALIDATION_KEY,
      });
    }),
});
