import React, { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, CardActions, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import Countdown from '@app/modules/auth-module/components/common/Countdown/Countdown';
import { confirmLogin, resendCode } from '@store/thunks/auth/auth-thunks';
import tokenStorage, { REFRESH_STORAGE_KEY } from '@app/clients/apollo/tokenStorage';
import { CONFIRMATION_COUNTDOWN_VALUE, EXPIRES_IN, EXPIRES_IN_TIMESTAMP, REFRESH_IN } from '@app/core/constants/auth';
import useCountdownWorker from '@app/core/hooks/workers/useCountdownWorker';
import { DATE_FORMAT } from '@app/v2/shared/constants';
import SupportMessage from '@app/modules/auth-module/components/supportMessage/SupportMessage';
import { CSDAlert } from '@app/modules/kit-module/shared/ui';
import { Events, KeyCodes } from '@app/v2/shared/enums';
import { strictlyEqual } from '@app/v2/shared/helpers';
import { useActions, useAppDispatch, useLocalStorage, useEventListener } from '@app/v2/shared/hooks';

type Props = {
  value: string;
} & I18N.TranslationFunction<'auth'>;

const FormActions = ({ value, t }: Props) => {
  const { push: redirect } = useHistory();

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { setConfirmationError } = useActions();

  const { getItem, setItem } = useLocalStorage();
  const { sendTimeStamp } = useCountdownWorker();

  const deadlineValue = useMemo(() => Number(getItem(REFRESH_IN)) * 1000, [getItem]);
  const currentCountdownValue = useMemo(() => JSON.parse(getItem(CONFIRMATION_COUNTDOWN_VALUE)) * 1000, [getItem]);

  const [isTimeNotOver, setIsTimeNotOver] = useState<boolean>(true);
  const [deadline, setDeadline] = useState<number>(deadlineValue);
  const [isInitial, setIsInitial] = useState<boolean>(true);

  useEffect(() => {
    if (currentCountdownValue && isInitial) {
      setDeadline(currentCountdownValue);
      setIsInitial(false);
    }

    return () => {
      setIsInitial(false);
    };
  }, [currentCountdownValue, deadlineValue, isInitial]);

  const reRequestConfirmCode = () => {
    const newExpiresTime = moment().add(getItem(EXPIRES_IN), 'seconds').format(DATE_FORMAT.FORMAT_RU_TIME);

    dispatch(resendCode({ token: tokenStorage.get(REFRESH_STORAGE_KEY), redirectCb: redirect }));
    setIsTimeNotOver(prev => !prev);
    setDeadline(deadlineValue);
    setItem(EXPIRES_IN_TIMESTAMP, newExpiresTime);
    sendTimeStamp(newExpiresTime);
  };

  const handleSubmit = (): void => {
    if (!value) {
      dispatch(
        setConfirmationError({
          status: true,
          errorMessage: 'Обязательное поле!',
        }),
      );

      return;
    }

    dispatch(
      confirmLogin({
        authenticateToken: tokenStorage.get(REFRESH_STORAGE_KEY),
        code: value,
        redirectCb: redirect,
      }),
    );
  };

  const handleSubmitByEnter = (event: KeyboardEvent<HTMLDivElement>) => {
    if (!strictlyEqual<number>(event.keyCode, KeyCodes.Enter) || !value) return;
    handleSubmit();
  };

  useEventListener(Events.Keydown, handleSubmitByEnter);

  return (
    <CardActions className={classes.actionRoot}>
      <Stack direction="column">
        <Button color="primary" fullWidth size="large" onClick={() => handleSubmit()}>
          {t('confirmation.form.confirm')}
        </Button>

        {isTimeNotOver ? (
          <Stack direction="column" alignItems="center" marginTop="1.5rem">
            <Typography variant="body2">{t('confirmation.form.resetTimer')}</Typography>
            <Countdown
              setIsTimeNotOver={setIsTimeNotOver}
              deadline={deadline}
              setDeadline={setDeadline}
              localStorageCountdownKey={CONFIRMATION_COUNTDOWN_VALUE}
            />
          </Stack>
        ) : (
          <>
            <Button
              className={classes.resetButton}
              fullWidth
              color="primary"
              variant="text"
              size="small"
              disabled={isTimeNotOver}
              onClick={reRequestConfirmCode}
            >
              <span id="buttonText">{t('confirmation.form.resetConfirm')}</span>
            </Button>

            <CSDAlert
              sx={{
                marginTop: '1.5rem',
                padding: '1rem',
              }}
              severity="info"
            >
              <SupportMessage
                variant="body2"
                textAlign="left"
                generalContent={t('login.cardContent.cardConnectionMessage')}
                supportLink={t('login.cardContent.techSupportLink')}
              />
            </CSDAlert>
          </>
        )}
      </Stack>
    </CardActions>
  );
};

const useStyles = makeStyles({
  actionRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 1.5rem 1.5rem 1.5rem !important',
    margin: 0,
  },
  resetButton: {
    '&.MuiButton-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '0.5rem',
      '& #buttonText': {
        width: '100%',
        fontSize: '0.875rem',
        marginRight: '0.313rem',
        color: 'black',
      },
    },
  },
  supportMessageWrapper: {
    textAlign: 'center',
    fontSize: '0.75rem',
    marginTop: '0.438rem',
  },
});

export default withTranslation('auth')(FormActions);
