import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Divider, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import isEqual from 'lodash/isEqual';
import useProfileContext from '@app/core/context/profile/useProfileContext';
import { CSDButtonSkeleton, CSDProfileActionPanel } from '@app/modules/kit-module/shared/ui';
import getRandomInteger from '@app/core/helpers/getRandomInteger';
import ConfirmDialog from '@app/components/dialogs/confirm-dialog/ConfirmDialog';
import { getNewTemporaryProfile, isDefaultProfile, strictlyEqual } from '@app/v2/shared/helpers';
import { useDialog } from '@app/v2/shared/hooks';
import parameters from '@app/core/constants/parameters/parameters';
import { INITIAL_PROFILE_ID } from '@app/v2/shared/constants';
import { Groups, ProfilesTypes } from '@app/v2/shared/enums';
import { MeteoParametersSetup } from '@app/v2/shared/configs';
import usePaginationSearch from '@app/core/source/hooks/pagination/usePaginationSearch';
import CSDTableDataSetupParameters from './CSDTableDataSetupParameters';
import CSDTableDataProfileNameInput from './CSDTableDataProfileNameInput';
import DisabledWrapperWithTooltip from '../../components/DisabledWrapperWithTooltip';

interface Props {
  hideProfileActions: boolean;
}

const CSDTableDataSetupPanel = ({ hideProfileActions }: Props) => {
  const {
    type,
    profiles: contextProfiles,
    activeProfile,
    updateActiveProfile,
    saveProfiles,
    isSaveProfileMode,
    setIsSaveProfileMode,
    loading,
    setError,
    error,
  } = useProfileContext();

  const [pagination] = usePaginationSearch();

  const inputRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!inputRef.current || !isSaveProfileMode) return;
    inputRef.current.focus();
  }, [isSaveProfileMode]);

  const { t } = useTranslation('profiles');
  const { t: tFooter } = useTranslation('footer');
  const openConfirmDialog = useDialog(ConfirmDialog);

  const [profiles, setProfiles] = useState<Profiles.CommonProfile[]>([]);
  const [currentActiveProfile, setCurrentActiveProfile] = useState<Profiles.CommonProfile | null>(null);

  const isEqualCurrentAndActiveProfile = useMemo(() => {
    return isEqual(activeProfile?.items, currentActiveProfile?.items) && isEqual(activeProfile?.label, currentActiveProfile?.label);
  }, [activeProfile, currentActiveProfile]);

  const setupParameters = useMemo(() => {
    return currentActiveProfile?.items
      .reduce((acc, current) => {
        if (MeteoParametersSetup[type][current.key]) {
          acc.push({ ...MeteoParametersSetup[type][current.key], order: parameters[current.key].order });
        }
        return acc;
      }, [])
      .sort((a, b) => a.order - b.order);
  }, [currentActiveProfile?.items, type]);

  useEffect(() => {
    if (activeProfile) {
      setCurrentActiveProfile(activeProfile);
    }
    return () => {
      setIsSaveProfileMode(false);
    };
  }, [activeProfile, setIsSaveProfileMode]);

  useEffect(() => {
    if (!contextProfiles.length) return;

    setProfiles(contextProfiles);
  }, [contextProfiles]);

  const handleSetActiveProfile = useCallback(
    (nextProfileValue: Profiles.CommonProfile): void => {
      if (strictlyEqual<number>(nextProfileValue.id, currentActiveProfile.id)) return;

      if (isSaveProfileMode) {
        openConfirmDialog({ question: t('questions.cancelSaveProfileMode') }).beforeClose.subscribe((answerToQuestion: boolean): void => {
          if (!answerToQuestion) return;

          setIsSaveProfileMode(false);
          setProfiles(prev => prev.filter(({ id }) => id >= INITIAL_PROFILE_ID));

          setCurrentActiveProfile(nextProfileValue);
          updateActiveProfile(nextProfileValue);
        });
      } else {
        setCurrentActiveProfile(nextProfileValue);
        updateActiveProfile(nextProfileValue);
      }
    },
    [currentActiveProfile, isSaveProfileMode, openConfirmDialog, setIsSaveProfileMode, t, updateActiveProfile],
  );

  const handleSetDefaultProfiles = useCallback(
    async ({ id }: Profiles.CommonProfile): Promise<void> => {
      let mergedProfiles: Profiles.CommonProfile[];

      if (isDefaultProfile(activeProfile)) {
        setCurrentActiveProfile(prev => ({ ...prev, isDefault: false }));
        mergedProfiles = profiles.map(profile => ({ ...profile, isDefault: false }));
      } else {
        setCurrentActiveProfile(prev => ({ ...prev, isDefault: true }));

        mergedProfiles = profiles.map(profile => {
          if (strictlyEqual<number>(profile.id, id)) {
            return { ...profile, isDefault: true };
          }
          return { ...profile, isDefault: false };
        });
      }

      await saveProfiles(mergedProfiles);
    },
    [activeProfile, profiles, saveProfiles],
  );

  const handleChangeParameterStatus = useCallback((key: string): void => {
    setCurrentActiveProfile(prev => ({
      ...prev,
      items: prev.items.map(item => {
        if (strictlyEqual<string>(item.key, key)) {
          return { ...item, checked: !item.checked };
        }

        return item;
      }),
    }));
  }, []);

  const handleChangeGroupParameterStatus = useCallback(
    (key: keyof typeof Groups, nextValue: boolean): void => {
      const groupParameters = setupParameters.filter(({ groupRelation }) => strictlyEqual<string>(groupRelation, key));

      setCurrentActiveProfile(prev => ({
        ...prev,
        items: prev.items.map(item => {
          if (groupParameters.find(({ label }) => strictlyEqual<string>(label, item.key))) {
            return { ...item, checked: nextValue };
          }

          return item;
        }),
      }));
    },
    [setupParameters],
  );

  const handleSaveProfiles = useCallback(async (): Promise<void> => {
    if (error) return;

    const mergedProfiles = profiles.map(profile => {
      if (strictlyEqual<number>(profile.id, currentActiveProfile.id)) {
        return currentActiveProfile;
      }
      return profile;
    });

    await saveProfiles(mergedProfiles);
  }, [error, currentActiveProfile, profiles, saveProfiles]);

  const handleAddNewProfile = useCallback(
    async (nextProfileValue: Profiles.CommonProfile): Promise<void> => {
      if (error) return;
      if (!nextProfileValue.label) {
        setError(t('errors.requiredInput'));
        return;
      }

      const newProfile: Profiles.CommonProfile = {
        id: -getRandomInteger(),
        label: nextProfileValue.label,
        description: t('withoutDescription'),
        isDefault: true,
        items: nextProfileValue.items,
      };

      await saveProfiles([...contextProfiles, newProfile]);
      setIsSaveProfileMode(false);
    },
    [contextProfiles, error, saveProfiles, setError, setIsSaveProfileMode, t],
  );

  const onChangeProfileLabel = useCallback((nextLabel: string): void => {
    setCurrentActiveProfile(prev => ({ ...prev, label: nextLabel }));
  }, []);

  const handleResetProfile = useCallback((): void => {
    setCurrentActiveProfile(activeProfile);
  }, [activeProfile]);

  const handleRemoveProfile = useCallback(
    async (nextProfileValue: Profiles.CommonProfile): Promise<void> => {
      openConfirmDialog({ question: t('questions.deleteProfile') }).beforeClose.subscribe(async (answerToQuestion: boolean): Promise<void> => {
        if (!answerToQuestion) return;

        const newProfiles = profiles
          .filter(profile => !strictlyEqual<number>(nextProfileValue.id, profile.id))
          .map((profile, index) => {
            if (!index) {
              return { ...profile, isDefault: true };
            }

            return profile;
          });

        await saveProfiles(newProfiles);
      });
    },
    [openConfirmDialog, profiles, saveProfiles, t],
  );

  const handleActivateSaveMod = useCallback(() => {
    setIsSaveProfileMode(true);
    const newProfile = getNewTemporaryProfile(type);

    setProfiles(prev => [...prev, newProfile]);
    setCurrentActiveProfile(newProfile);
  }, [setIsSaveProfileMode, type]);

  const handleDeactivateSaveMod = useCallback(() => {
    openConfirmDialog({ question: t('questions.cancelSaveProfileMode') }).beforeClose.subscribe((answerToQuestion: boolean): void => {
      if (!answerToQuestion) return;

      setError(null);
      setIsSaveProfileMode(false);
      setProfiles(prev => prev.filter(({ id }) => id >= INITIAL_PROFILE_ID));

      const defaultProfile = profiles.find(({ isDefault }) => isDefault);

      setCurrentActiveProfile(defaultProfile);
      updateActiveProfile(defaultProfile);
    });
  }, [openConfirmDialog, t, setError, setIsSaveProfileMode, profiles, updateActiveProfile]);

  const isViewProfileNameInput = isSaveProfileMode || !isDefaultProfile(activeProfile);

  useEffect(() => {
    const isProfileNameExist = Boolean(contextProfiles.find(({ label }) => strictlyEqual<string>(label, currentActiveProfile?.label)));
    const isCurrentNameIsEqualActiveName = strictlyEqual<string>(activeProfile?.label, currentActiveProfile?.label);

    if (isSaveProfileMode && isProfileNameExist) {
      setError(t('notifications.profileIsExist'));
    }

    if (!isSaveProfileMode && !isCurrentNameIsEqualActiveName && isProfileNameExist) {
      setError(t('notifications.profileIsExist'));
    }

    return () => {
      setError(null);
    };
  }, [activeProfile, contextProfiles, currentActiveProfile, isSaveProfileMode, setError, t]);

  return (
    <>
      {!!currentActiveProfile && (
        <Box position="relative">
          {!!pagination.total && type === ProfilesTypes.meteo && (
            <TotalBox>
              <Tooltip title={tFooter('panels.totalNumberOfStations')}>
                <span>{pagination.total}</span>
              </Tooltip>
            </TotalBox>
          )}
          <CSDProfileActionPanel<Profiles.CommonProfile>
            activeProfile={currentActiveProfile}
            profiles={profiles}
            hideProfileActions={hideProfileActions}
            isSaveProfileMode={isSaveProfileMode}
            skeletonSettings={{
              loading,
              skeletonsAmount: profiles.length + 1,
              component: <CSDButtonSkeleton />,
            }}
            profileActions={{
              setActive: {
                action: profile => handleSetActiveProfile(profile),
                actionName: t('buttons.setToActive'),
              },
              addNewProfile: {
                action: profile => handleAddNewProfile(profile),
                actionName: t('buttons.save'),
                tooltipTitle: t('tooltips.saveNewProfile'),
              },
              activateSaveMod: {
                action: handleActivateSaveMod,
                actionName: t('buttons.save'),
                tooltipTitle: t('tooltips.addNewProfile'),
              },
              deactivateSaveMod: {
                action: handleDeactivateSaveMod,
                actionName: t('buttons.cancel'),
                tooltipTitle: t('tooltips.cancelSaveMode'),
              },
            }}
            panelActions={[
              {
                actionName: t('buttons.default'),
                action: handleSetDefaultProfiles,
                isView: !activeProfile?.isDefault,
              },
              {
                actionName: t('buttons.reset'),
                actionType: 'error',
                action: handleResetProfile,
                isView: !isEqualCurrentAndActiveProfile,
              },
              {
                actionName: t('buttons.delete'),
                action: handleRemoveProfile,
                actionType: 'error',
                isView: !isDefaultProfile(activeProfile) && profiles.length > 1,
              },
              {
                actionName: t('buttons.save'),
                action: handleSaveProfiles,
                isView: !isDefaultProfile(activeProfile),
                isDisabled: isEqualCurrentAndActiveProfile,
              },
            ]}
          />

          {isViewProfileNameInput && (
            <>
              <Divider sx={{ marginBottom: '1rem' }} />

              <CSDTableDataProfileNameInput inputRef={inputRef} activeProfile={currentActiveProfile} onChange={onChangeProfileLabel} />
            </>
          )}

          <DisabledWrapperWithTooltip disabled={isDefaultProfile(activeProfile) && !isSaveProfileMode}>
            <CSDTableDataSetupParameters
              activeProfile={currentActiveProfile}
              setupParameters={setupParameters}
              onGroupChange={handleChangeGroupParameterStatus}
              onChange={handleChangeParameterStatus}
            />
          </DisabledWrapperWithTooltip>
        </Box>
      )}
    </>
  );
};

const TotalBox = styled(Box)<BoxProps>({
  position: 'fixed',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  right: 50,
  marginTop: '-2.875rem',
  padding: '0 1rem',
  height: '1.875rem',
  minHeight: '1.875rem',
  border: '0.063rem solid #8D909A',
  borderBottom: 'unset',
  borderRadius: '0.5rem 0.5rem 0rem 0rem',
  boxShadow: '0rem -0.125rem 0.313rem 0rem #34343433',
  clipPath: 'inset(-0.313rem -0.313rem 0rem -0.313rem)',
  backgroundColor: '#FFFFFF',
  color: '#222631',
  borderColor: '#222631',
  cursor: 'pointer',
});

export default CSDTableDataSetupPanel;
