import Highcharts from 'highcharts';
import { PieData, PieCenterNumber } from './types';
import { gradientColorMapper, textColorMapper } from './colorMapper';

const PIE_DIAMETER = 180;

type Props = {
  chartWidth?: number;
  radiusOffset?: number;
  data: Array<PieData[]>;
  rightSideNumber?: PieCenterNumber;
  leftSideNumber?: PieCenterNumber;
  topSideNumber?: PieCenterNumber;
  title?: string;
  seriesLegendShow?: boolean[];
  totalElements: number;
  totalValueSuffix: string;
};

const prepareSeries = ({
  data,
  radiusOffset,
  seriesLegendShow,
}: Pick<Props, 'data' | 'radiusOffset' | 'seriesLegendShow'>): Highcharts.SeriesPieOptions[] => {
  if (!data.length) return [];

  return data.map((dataItem, index) => {
    const size = PIE_DIAMETER - radiusOffset * index;

    return {
      type: 'pie',
      size,
      innerSize: size - radiusOffset,
      showInLegend: seriesLegendShow[index],
      data: dataItem.map(({ name, value, color }) => {
        const [colorFrom, colorTo] = gradientColorMapper[color];
        return {
          name,
          y: value,
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, colorFrom],
              [1, colorTo],
            ],
          },
        };
      }),
    };
  });
};

const configureChartOptions = ({
  chartWidth = 500,
  radiusOffset = 30,
  data = [],
  rightSideNumber,
  leftSideNumber,
  topSideNumber,
  title,
  seriesLegendShow,
  totalElements,
  totalValueSuffix,
}: Props): Highcharts.Options => {
  const series = prepareSeries({ data, radiusOffset, seriesLegendShow });

  return {
    chart: {
      type: 'pie',
      width: chartWidth,
      height: 270,
    },
    title: {
      useHTML: true,
      floating: true,
      align: 'left',
      text: `<div style="font-size: 15px; color: black; width: 220px; text-wrap: wrap; text-align: center">${title}</div>`,
      y: 230,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: true,
      layout: 'vertical',
      align: 'right',
      itemMarginBottom: 10,
      itemMarginTop: 10,
      y: -40,
      labelFormat: `<span style="font-size: 15px; font-weight: 400;">{name}</span>`,
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    plotOptions: {
      pie: {
        center: ['90', '80'],
        dataLabels: {
          enabled: false,
        },
        states: {
          hover: {
            halo: {
              size: 5,
            },
          },
        },
        point: {
          events: {
            legendItemClick(e) {
              const { chart } = this.series;

              this.series.points.forEach(point => {
                if (point.name === this.name) {
                  point.graphic.attr({
                    opacity: 1,
                  });
                }
                if (point.name !== this.name) {
                  point.graphic.attr({
                    opacity: 0.2,
                  });
                }
              });

              chart.tooltip.refresh(this);
              e.preventDefault();
            },
            mouseOver() {
              this.series.points.forEach(point => {
                if (point.name === this.name) {
                  point.graphic.attr({
                    opacity: 1,
                  });
                }
                if (point.name !== this.name) {
                  point.graphic.attr({
                    opacity: 0.2,
                  });
                }
              });
            },
            mouseOut() {
              this.series.points.forEach(point => {
                point.graphic.attr({
                  opacity: 1,
                });
              });
            },
          },
        },
      },
    },
    tooltip: {
      backgroundColor: 'rgba(255,255,255,1)',
      outside: true,
      headerFormat: '',
      pointFormat: `<span>
        <span style="color:{point.color};">\u25CF</span> 
        <span>{point.name}</span>: <b>{point.y}</b>
      </span>`,
    },
    subtitle: {
      useHTML: true,
      text: `<div style="width: 106px; height: 106px; display: flex; justifyContent: space-around; alignItems: center; position: relative;">
        <span style="position: absolute; display: ${topSideNumber ? 'inline' : 'none'}; fontSize: 12px; top: 0px; color: ${
        textColorMapper[topSideNumber?.color]
      };">${topSideNumber?.value}</span>
        <span style="position: absolute; display: ${leftSideNumber ? 'inline' : 'none'}; fontSize: 12px; top: 15px; left: 5px; color: ${
        textColorMapper[leftSideNumber?.color]
      };">${leftSideNumber?.value}</span>
        <span style="position: absolute; display: ${rightSideNumber ? 'inline' : 'none'}; top: 15px; right: 5px; fontSize: 12; color: ${
        textColorMapper[rightSideNumber?.color]
      };">${rightSideNumber?.value}</span>
        <div style="textAlign: center;">
          <span style="fontSize: 22px; color: black;">${totalElements}</span>
          <br />
          <span style="fontSize: 12px; color: black; display: ${totalValueSuffix ? 'inline' : 'none'}">${totalValueSuffix}</span>
        </div>
      </div>
      `,
      floating: true,
      align: 'left',
      verticalAlign: 'top',
      x: 57,
      y: 60,
    },
    series,
  };
};

export default configureChartOptions;
