import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { showErrorWithoutCodes } from '@app/core/utils/notifications';
import { useActions, useMeteoChartProfiles, useLocalStorage } from '@app/v2/shared/hooks';
import { LocalStorageKeys, MeteoChartContainerWidth } from '@app/v2/shared/enums';
import adapter from '../adapter/CSDMeteoChartConstructorAdapter';

interface MeteoChartProfileActions {
  handleGetSetup: Common.VoidCallBack;
  handleCreateChart: (profileId: string, newProfileItem: Meteo.ChartProfileItem<string[]>) => void;
  handleUpdateChart: (profileId: string, nextProfileItem: Meteo.ChartProfileItem<string[]>) => void;
  handleDeleteChart: (profileId: string, itemId: string) => void;
  handleResizeChart: (profileId: string, itemId: string) => void;
  handleCreateMeteoChartProfile: (name: string) => void;
  handleDeleteMeteoChartProfile: (profileId: number) => void;
}

export default function useMeteoChartProfileActions(): MeteoChartProfileActions {
  const { t } = useTranslation('charts', { keyPrefix: 'meteoConstructor' });

  const { removeItem } = useLocalStorage();
  const { meteoChartProfiles, handleSetMeteoChartProfiles, handleGetMeteoChartById } = useMeteoChartProfiles();
  const { removeMeteoChartById } = useActions();

  useEffect(() => {
    if (adapter.getProfiles().length) return;
    adapter.setProfiles(meteoChartProfiles);
  }, [meteoChartProfiles]);

  const handleGetSetup = useCallback(() => {
    adapter
      .getValues()
      .then(handleSetMeteoChartProfiles)
      .catch(() => showErrorWithoutCodes(t('errorOfFetchChartsSetup')));
  }, [t, handleSetMeteoChartProfiles]);

  const handleCreateChart = useCallback(
    (profileId: string | null, newProfileItem: Meteo.ChartProfileItem<string[]>) => {
      adapter
        .createValue(profileId, newProfileItem)
        .catch(() => showErrorWithoutCodes(t('errorOfCreateChartSetup')))
        .then(handleGetSetup);
    },
    [t, handleGetSetup],
  );

  const handleUpdateChart = useCallback(
    (profileId: string, nextProfileItem: Meteo.ChartProfileItem<string[]>) => {
      adapter
        .updateValue(profileId, nextProfileItem)
        .catch(() => showErrorWithoutCodes(t('errorOfUpdateChartSetup')))
        .then(handleGetSetup);
    },
    [t, handleGetSetup],
  );

  const handleDeleteChart = useCallback(
    (profileId: string, itemId: string) => {
      removeMeteoChartById({ chartId: itemId });

      adapter
        .removeValue(profileId, itemId)
        .catch(() => showErrorWithoutCodes(t('errorOfDeleteChartSetup')))
        .then(handleGetSetup);
    },
    [removeMeteoChartById, handleGetSetup, t],
  );

  const handleResizeChart = useCallback(
    (profileId: string, itemId: string) => {
      const chart = handleGetMeteoChartById(itemId);

      const containerWidths = {
        [MeteoChartContainerWidth.Full]: MeteoChartContainerWidth.Half,
        [MeteoChartContainerWidth.Half]: MeteoChartContainerWidth.Full,
      };

      const nextProfileItem: Meteo.ChartProfileItem<string[]> = {
        ...chart,
        containerWidth: containerWidths[chart.containerWidth],
        parameters: Object.keys(chart.parameters),
      };

      handleUpdateChart(profileId, nextProfileItem);
    },
    [handleGetMeteoChartById, handleUpdateChart],
  );

  const handleCreateMeteoChartProfile = useCallback(
    (name: string) => {
      adapter
        .createProfile(name)
        .catch(() => showErrorWithoutCodes(t('errorOfCreateChartProfile')))
        .then(handleGetSetup);
    },
    [handleGetSetup, t],
  );

  const handleDeleteMeteoChartProfile = useCallback(
    (profileId: number) => {
      adapter
        .deleteProfile(profileId)
        .catch(() => showErrorWithoutCodes(t('errorOfDeleteChartProfile')))
        .then(() => removeItem(LocalStorageKeys.ActiveMeteoChartProfileId))
        .then(handleGetSetup);
    },
    [handleGetSetup, removeItem, t],
  );

  return {
    handleCreateMeteoChartProfile,
    handleDeleteMeteoChartProfile,
    handleGetSetup,
    handleCreateChart,
    handleUpdateChart,
    handleDeleteChart,
    handleResizeChart,
  };
}
