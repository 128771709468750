import React from 'react';
import { Box, Icon, Stack, Tooltip } from '@mui/material';
import { CSDMapMarkerNormal, CSDMapMarkerSmall, CSDTub, CSDTubCounter, CSDZoomControl } from '@app/modules/kit-module/shared/ui';
import { AlertStatuses, RoadObjectsTypes } from '@app/v2/shared/enums';
import icons from '@app/assets/iconFont';
import {
  CSDMapMarkerSmallExample,
  CSDMapMarkerSmallNormal,
  CSDMapMarkerSmallWithoutIconsExample,
  CSDTubCounterExample,
  CSDTubExample,
  CSDZoomControlExample,
} from './example';
import CSDKitDocBlock from '../../../shared/ui/CSDKitDocBlock/CSDKitDocBlock';

const Temperature = () => {
  return (
    <Stack direction="row" spacing={1} sx={{ background: 'white' }}>
      <Stack direction="column" spacing={2}>
        <Icon>{icons.tempBelowSurface}</Icon>
        <Icon>{icons.tempDewPoint}</Icon>
      </Stack>
      <Stack direction="column" spacing={2}>
        <Icon>{icons.temperatureChange}</Icon>
        <Icon>{icons.tempFreezing}</Icon>
      </Stack>
    </Stack>
  );
};
const VideoWithCount = () => {
  return (
    <Stack direction="row" spacing={1} sx={{ background: 'white' }}>
      <Tooltip title="Камера 1">
        <Icon>{icons.photo}</Icon>
      </Tooltip>
      <Tooltip title="Камера 2">
        <Icon>{icons.photo}</Icon>
      </Tooltip>
    </Stack>
  );
};

const Rainfall = () => <Box sx={{ background: 'white' }}>Content for rainfall</Box>;

const CSDNotFromMuiDocs = () => {
  const tubContent = (
    <>
      {['save', 'done', 'affected', 'archive'].map(iconName => (
        <Icon key={iconName}>{iconName}</Icon>
      ))}
    </>
  );

  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="Tub" codeExample={CSDTubExample}>
        <Stack direction="row" spacing={3}>
          <Stack direction="column" spacing={3}>
            <CSDTub>{tubContent}</CSDTub>
            <CSDTub backgroundColor="#3245AF">{tubContent}</CSDTub>
            <CSDTub size="small">{tubContent}</CSDTub>
            <CSDTub size="small" backgroundColor="#3245AF">
              {tubContent}
            </CSDTub>
          </Stack>
          <Stack direction="column" spacing={3}>
            <CSDTub variant="outlined">{tubContent}</CSDTub>
            <CSDTub size="small" variant="outlined">
              {tubContent}
            </CSDTub>
          </Stack>
        </Stack>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Tub counter" codeExample={CSDTubCounterExample}>
        <Stack direction="column" spacing={3}>
          <CSDTubCounter iconName="adult" />
          <CSDTubCounter size="small" iconName="adult" />
        </Stack>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Zoom control" codeExample={CSDZoomControlExample}>
        <CSDZoomControl />
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Map marker small without icon" codeExample={CSDMapMarkerSmallWithoutIconsExample}>
        <Stack direction="row" spacing={3}>
          <CSDMapMarkerSmall markerType={AlertStatuses.Warning} />
          <CSDMapMarkerSmall markerType={AlertStatuses.Alert} />
          <CSDMapMarkerSmall markerType={AlertStatuses.Notice} />
        </Stack>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Map marker small" codeExample={CSDMapMarkerSmallExample}>
        <Stack direction="row" spacing={3}>
          <Box position="relative">
            <CSDMapMarkerSmall iconName={icons.tpi} />
          </Box>
          <Box position="relative">
            <CSDMapMarkerSmall notifications={[{ iconName: icons.rainfall }, { iconName: icons.car }]} iconName={icons.tpi} />
          </Box>
          <Box position="relative">
            <CSDMapMarkerSmall
              notifications={[{ iconName: icons.rainfall }, { iconName: icons.car }, { iconName: icons.photo }]}
              iconName={icons.tpi}
              markerType={AlertStatuses.Warning}
            />
          </Box>
          <Box position="relative">
            <CSDMapMarkerSmall iconName={icons.tpi} markerType={AlertStatuses.Alert} />
          </Box>
          <Box position="relative">
            <CSDMapMarkerSmall
              notifications={[
                { iconName: icons.rainfall },
                { iconName: icons.car },
                { iconName: icons.photo },
                { iconName: icons.zpi },
                { iconName: icons.tpi },
              ]}
              iconName={icons.tpi}
              markerType={AlertStatuses.Notice}
            />
          </Box>
        </Stack>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="MapMarkerNormal" codeExample={CSDMapMarkerSmallNormal}>
        <Stack padding={8} direction="row" spacing={3}>
          <CSDMapMarkerNormal
            alertStatus={AlertStatuses.Alert}
            mainIcon={icons.rain}
            setOfIcons={[
              { icon: icons.rainfall, key: RoadObjectsTypes.METEO_PARAMS_FIRST, component: <Rainfall /> },
              { icon: icons.photo, count: 2, key: RoadObjectsTypes.VIDEO, component: <VideoWithCount /> },
              { icon: icons.temperature, key: RoadObjectsTypes.METEO_PARAMS_SECOND, component: <Temperature /> },
            ]}
          />
        </Stack>
      </CSDKitDocBlock>
    </Stack>
  );
};

export default CSDNotFromMuiDocs;
