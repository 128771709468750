import React, { PropsWithChildren, Suspense } from 'react';
import Container from '@mui/material/Container';
import CSDNotifications from '@app/v2/features/notifications/ui/CSDNotifications';
import { CSDHeader } from '@app/v2/widgets';
import { FilterContextProvider } from '@app/v2/shared/contexts';

const MainLayout = ({ children }: PropsWithChildren<{}>) => (
  <Container maxWidth={false} style={{ padding: '0px' }}>
    <FilterContextProvider>
      <CSDHeader />
    </FilterContextProvider>

    <CSDNotifications />

    <Suspense>{children}</Suspense>
  </Container>
);

export default MainLayout;
