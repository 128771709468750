import { useEffect } from 'react';
import useTableMode from '@app/core/source/hooks/useTableMode';
import { LISTING_DEFAULT_PAGE_NUMBER } from '@app/core/constants';
import useListingPage from '@app/components/listing-page/hooks/useListingPage';
import { TableMode } from '@app/v2/shared/enums';

export default function useTemporarySetTableMode() {
  const [, setTableMode] = useTableMode();
  const { handleUpdatePagination } = useListingPage(false);

  useEffect(() => {
    setTableMode(TableMode.Scroll);
    handleUpdatePagination(LISTING_DEFAULT_PAGE_NUMBER, 'page');

    return () => {
      setTableMode(TableMode.Pagination);
      handleUpdatePagination(LISTING_DEFAULT_PAGE_NUMBER, 'page');
    };
  }, [setTableMode, handleUpdatePagination]);
}
