import React from 'react';
import { Checkbox, FormControlLabel, FormControlLabelProps } from '@mui/material';
import { CheckboxProps } from '@mui/material/Checkbox/Checkbox';
import { DataTestIds } from '@app/v2/shared/enums';

type Props = {
  label?: string;
  dateTestId?: DataTestIds;
  formControlLabelProps?: Omit<FormControlLabelProps, 'control' | 'label'>;
} & CheckboxProps;

const CSDCheckbox = ({ label, dateTestId, formControlLabelProps, ...checkboxProps }: Props) => {
  return <FormControlLabel control={<Checkbox {...checkboxProps} />} label={label} data-testid={dateTestId} {...formControlLabelProps} />;
};

export default CSDCheckbox;
