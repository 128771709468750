import React from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, IconButton, Tooltip } from '@mui/material';
import { useMutation } from '@apollo/client';
import icons from '@app/assets/iconFont';
import accidentNewsMutations from '@app/clients/apollo/requests/mutations/accidentNews';
import { addFeatureActions } from '@app/v2/shared/utils';

interface Props extends Common.FeaturesActions, I18N.TranslationFunction<'accidentNews'> {
  newsId: number;
}

const CSDRemoveFromFavorites = ({ newsId, t, ...featuresActions }: Props) => {
  const [onRemove, { loading }] = useMutation<Common.MutationResponse, AccidentNews.UpdateFavoritesStatusVariables>(
    accidentNewsMutations.removeFromFavorites,
  );

  const handleRemoveFromFavorites = () => {
    addFeatureActions(onRemove({ variables: { newsId } }), featuresActions);
  };

  return (
    <Tooltip title={t('removeFromFavorites.btn.tooltip')}>
      <IconButton onClick={handleRemoveFromFavorites} disabled={loading}>
        <Icon sx={{ color: '#f57c00' }}>{icons.starFill}</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default withTranslation('accidentNews')(CSDRemoveFromFavorites);
