/* eslint-disable react/no-array-index-key */
import React, { memo, ReactNode, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, Stack, StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ENTER_TOOLTIP_DELAY } from '@app/v2/shared/constants';
import { isFunction } from '@app/v2/shared/helpers';
import icons from '@app/assets/iconFont';
import CSDTableCell, { HighlightedCell } from '../CSDTableCells/CSDTableCell';

type Props = {
  values: (number | string)[];
  formatter?: Common.Formatter;
  isCustomSensorRender?: boolean;
} & HighlightedCell &
  I18N.TranslationFunction<'meteo'>;

const CSDTableCellWithTwoSensors = ({ values, formatter, rowIndex, columnIndex, isCustomSensorRender, t }: Props) => {
  const valuesSeparator = '/';

  const [cellText, tooltipText] = useMemo<[ReactNode[], string]>(() => {
    const cellTextResult = [];
    const tooltipTextResult = [];

    values.forEach((value, index) => {
      if (value === undefined) return;

      if (formatter && isFunction(formatter)) {
        cellTextResult.push(formatter(value, index));
      } else {
        cellTextResult.push(String(value) ?? <Icon>{icons.noData}</Icon>);
      }

      if (!isCustomSensorRender) {
        tooltipTextResult.push(t('tooltipValues.sensors', { amount: index + 1 }));
      }
    });

    return [cellTextResult, tooltipTextResult.join(valuesSeparator)];
  }, [formatter, isCustomSensorRender, t, values]);

  const render = useMemo(() => {
    return (
      <StyledStack>
        {cellText.map((formatterNode, index) => (
          <StyledStack key={`formatterNode-${index}`}>
            {formatterNode}
            {cellText.length - 1 !== index && valuesSeparator}
          </StyledStack>
        ))}
      </StyledStack>
    );
  }, [cellText]);

  return (
    <CSDTableCell label={tooltipText} tooltipProps={{ enterDelay: ENTER_TOOLTIP_DELAY }} rowIndex={rowIndex} columnIndex={columnIndex} align="center">
      {render}
    </CSDTableCell>
  );
};

const StyledStack = styled(Stack)<StackProps>({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});

export default withTranslation('meteo')(memo(CSDTableCellWithTwoSensors));
