import * as React from 'react';
import ProfileTabPanel from '@app/components/dialogs/profile-settings-dialog/ProfileTabPanel';
import TabsBar from '@app/components/common/tabs-bar/TabsBar';

type Props = {
  profiles: Profiles.CommonProfile[];
  indexActiveProfile: number;
  onChangeIndexActiveProfile: (value) => void;
  onChangeGroupCheckbox: (value: boolean) => void;
  onChangeCheckbox: (key: string, value: boolean) => void;
  onSetDefaultProfile: Common.AnyFunction;
  onRemoveProfile: Common.AnyFunction;
};

const ProfileTabs = ({
  profiles,
  indexActiveProfile,
  onChangeIndexActiveProfile = () => {},
  onChangeGroupCheckbox = () => {},
  onChangeCheckbox = () => {},
  onRemoveProfile = () => {},
  onSetDefaultProfile = () => {},
}: Props) => {
  return (
    <>
      <TabsBar
        items={profiles}
        value={profiles[indexActiveProfile] || null}
        tabProps={({ label }) => ({ label })}
        onChangeIndex={onChangeIndexActiveProfile}
      />
      <ProfileTabPanel
        profile={profiles[indexActiveProfile]}
        isDisabledRemove={profiles.length === 1}
        onChangeGroupCheckbox={onChangeGroupCheckbox}
        onChangeCheckbox={onChangeCheckbox}
        onRemoveProfile={onRemoveProfile}
        onSetDefaultProfile={onSetDefaultProfile}
      />
    </>
  );
};

export default ProfileTabs;
