import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps, Icon } from '@mui/material';
import { isFunction } from '@app/v2/shared/helpers';
import icons from '@app/assets/iconFont';

interface Props extends ButtonProps {
  handleClick: (event?: MouseEvent<HTMLElement>) => void;
  loading?: boolean;
}

const CSDDownloadVideoSnapshotBtn = ({ loading, handleClick, ...other }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'buttons' });

  const handleClickChecker = (event: MouseEvent<HTMLElement>) => {
    if (!isFunction(handleClick) || loading) return;
    handleClick(event);
  };

  return (
    <Button size="large" variant="outlined" startIcon={<Icon>{icons.download}</Icon>} onClick={handleClickChecker} {...other}>
      {t('download')}
    </Button>
  );
};

export default CSDDownloadVideoSnapshotBtn;
