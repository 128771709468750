import { Pages } from '@app/v2/shared/constants';

export default function findPageByPathName(pathname: string) {
  return Pages.find(page => {
    const currentPath = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;

    if (currentPath.includes('/reports/common') && page.path.includes(':reportSection/:reportGroup/:reportType')) {
      return currentPath.includes(page.path.replace(':reportSection/:reportGroup/:reportType', ''));
    }

    if (currentPath.includes('/reports/services') && page.path.includes(':reportGroup/:reportType')) {
      return currentPath.includes(page.path.replace(':reportGroup/:reportType', ''));
    }

    return page.path.includes(currentPath);
  });
}
