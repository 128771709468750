import { KeyPrefix } from 'react-i18next';
import { DATE_PERIOD } from '@app/v2/shared/constants';

export const TITLE: KeyPrefix<'constants'> = 'index.title';

export const USER_MANUAL_URL = 'https://docs.google.com/document/d/1wa9u4webwjyFm6KwOJe3oTwJC6_Fzs0X/edit';

export const LISTING_PAGE_LIMITS_DEFAULT = [25, 50, 100];

export const LISTING_DEFAULT_PAGE_LIMIT = 100;

export const LISTING_DEFAULT_PAGE_NUMBER = 1;

export const PAD_START_STATION_METERS = 3;

export const NO_DATA = '---';

export const NOTE_IMAGE_UPLOAD_URL = process.env.REACT_APP_NOTE_IMAGE_UPLOAD_URL;

export const DEFAULT_UPDATE_PERIOD = 5 * DATE_PERIOD.MINUTE;

export const ROAD_TITLE_SIZE_WITHOUT_TOOLTIP = 160;

export const APP_PREFIX = 'cusad';

export const DEFAULT_URL = `/index`;

export const ZERO_POINT_WIND_SPEED = 0;
