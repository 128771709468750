import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import moment, { Moment } from 'moment';
import { strictlyEqual } from '@app/v2/shared/helpers';
import type { TrafficChartInitialState } from '@store/slices/trafficChart/trafficChartSlice';
import { ExecutionType } from '@app/v2/shared/enums';
import useActions from '../reactRedux/useActions';
import useAppSelector from '../reactRedux/useAppSelector';

interface TrafficChartReturn {
  trafficChartData: TrafficChartInitialState;
  handleChangeTrafficChartDateFrom: (nextValue: Pick<TrafficChartInitialState, 'dateFrom'>) => void;
  handleChangeTrafficChartDateTo: (nextValue: Pick<TrafficChartInitialState, 'dateTo'>) => void;
  handleChangeTrafficChartDirection: (nextValue: Pick<TrafficChartInitialState, 'direction'>) => void;
  handleChangeTrafficChartInterval: (nextValue: Pick<TrafficChartInitialState, 'interval'>) => void;
  handleChangeTrafficChartType: (nextValue: Pick<TrafficChartInitialState, 'typeChart'>) => void;
  handleChangeTrafficChartViewType: (nextValue: Pick<TrafficChartInitialState, 'typeView'>) => void;
  handleToggleIsViewAdditionalChart: (nextValue: Pick<TrafficChartInitialState, 'isViewAdditionalChart'>) => void;
  handleChangeTrafficChartDates: (nextValues: [Moment, Moment]) => void;
  handleSetOrClearInitialDates: (nextValue: ExecutionType) => void;
}

export default function useMeteoChart(): TrafficChartReturn {
  const {
    setTrafficChartDateFrom,
    setTrafficChartDateTo,
    setTrafficChartDates,
    setTrafficChartDirection,
    setTrafficChartInterval,
    setTrafficChartType,
    setTrafficChartViewType,
    toggleIsViewAdditionalChart,
  } = useActions();

  const handleChangeTrafficChartDateFrom = useCallback(
    (nextValue: Pick<TrafficChartInitialState, 'dateFrom'>) => {
      setTrafficChartDateFrom(nextValue);
    },
    [setTrafficChartDateFrom],
  );

  const handleChangeTrafficChartDateTo = useCallback(
    (nextValue: Pick<TrafficChartInitialState, 'dateTo'>) => {
      const isToday = strictlyEqual<string>(nextValue.dateTo.format('yyyyMMDD'), moment().format('yyyyMMDD'));

      setTrafficChartDateTo({ dateTo: isToday ? moment() : nextValue.dateTo.endOf('day') });
    },
    [setTrafficChartDateTo],
  );

  const handleChangeTrafficChartDirection = useCallback(
    (nextValue: Pick<TrafficChartInitialState, 'direction'>) => {
      setTrafficChartDirection(nextValue);
    },
    [setTrafficChartDirection],
  );

  const handleChangeTrafficChartInterval = useCallback(
    (nextValue: Pick<TrafficChartInitialState, 'interval'>) => {
      setTrafficChartInterval(nextValue);
    },
    [setTrafficChartInterval],
  );

  const handleChangeTrafficChartType = useCallback(
    (nextValue: Pick<TrafficChartInitialState, 'typeChart'>) => {
      setTrafficChartType(nextValue);
    },
    [setTrafficChartType],
  );

  const handleChangeTrafficChartViewType = useCallback(
    (nextValue: Pick<TrafficChartInitialState, 'typeView'>) => {
      setTrafficChartViewType(nextValue);
    },
    [setTrafficChartViewType],
  );
  const handleToggleIsViewAdditionalChart = useCallback(
    (nextValue: Pick<TrafficChartInitialState, 'isViewAdditionalChart'>) => {
      toggleIsViewAdditionalChart(nextValue);
    },
    [toggleIsViewAdditionalChart],
  );

  const handleChangeTrafficChartDates = useCallback(
    ([nextDateFrom, nextDateTo]: [Moment, Moment]) => {
      handleChangeTrafficChartDateFrom({ dateFrom: nextDateFrom });
      handleChangeTrafficChartDateTo({ dateTo: nextDateTo });
    },
    [handleChangeTrafficChartDateFrom, handleChangeTrafficChartDateTo],
  );

  const handleSetOrClearInitialDates = useCallback(
    (nextValue: ExecutionType) => {
      const nextValueMapper: Record<ExecutionType, Pick<TrafficChartInitialState, 'dateTo' | 'dateFrom'>> = {
        [ExecutionType.Set]: { dateFrom: moment().add(-7, 'days'), dateTo: moment() },
        [ExecutionType.Clear]: { dateFrom: null, dateTo: null },
      };

      setTrafficChartDates(nextValueMapper[nextValue]);
    },
    [setTrafficChartDates],
  );

  return {
    trafficChartData: {
      ...useAppSelector(state => state.trafficChart, shallowEqual),
    },
    handleChangeTrafficChartDateFrom,
    handleChangeTrafficChartDateTo,
    handleChangeTrafficChartDirection,
    handleChangeTrafficChartInterval,
    handleChangeTrafficChartType,
    handleChangeTrafficChartViewType,
    handleToggleIsViewAdditionalChart,
    handleChangeTrafficChartDates,
    handleSetOrClearInitialDates,
  };
}
