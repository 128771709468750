import { Visibility } from '@app/core/constants/noteVisibility';

export const Colors = {
  RED: '#F63836',
  RED_TRIANGLE: '#CE2E2E',
  RED_SHADE: '#c42c2b',
  SKY: '#0E9DB1',
  SKY_TRIANGLE: '#118494',
  SKY_SHADE: '#0b7d8d',
  GREEN: '#03A100',
  GREEN_TRIANGLE: '#037D00',
  GREEN_SHADE: '#028000',
  BLUE: '#3B61F8',
  BLUE_TRIANGLE: '#1645FC',
  BLUE_SHADE: '#2f4dc6',
  BLACK: '#4d4d4d',
  BLACK_TRIANGLE: '#3c3c3c',
  BLACK_SHADE: '#1e1e1e',
};

export const colorMap: Notes.ColorMapType = {
  [Visibility.ALL]: {
    backgroundColor: Colors.RED,
    backgroundTriangleColor: Colors.RED_TRIANGLE,
    backgroundShadeColor: Colors.RED_SHADE,
  },
  [Visibility.ORGANIZATION]: {
    backgroundColor: Colors.SKY,
    backgroundTriangleColor: Colors.SKY_TRIANGLE,
    backgroundShadeColor: Colors.SKY_SHADE,
  },
  [Visibility.MAIN_ORGANIZATION]: {
    backgroundColor: Colors.GREEN,
    backgroundTriangleColor: Colors.GREEN_TRIANGLE,
    backgroundShadeColor: Colors.GREEN_SHADE,
  },
  [Visibility.PRIVATE]: {
    backgroundColor: Colors.BLUE,
    backgroundTriangleColor: Colors.BLUE_TRIANGLE,
    backgroundShadeColor: Colors.BLUE_SHADE,
  },
};

export const colorList: Notes.ColorListType = {
  [Visibility.ALL]: {
    backgroundColor: Colors.RED,
  },
  [Visibility.ORGANIZATION]: {
    backgroundColor: Colors.SKY,
  },
  [Visibility.MAIN_ORGANIZATION]: {
    backgroundColor: Colors.GREEN,
  },
  [Visibility.PRIVATE]: {
    backgroundColor: Colors.BLUE,
  },
};

export const FULL_WIDTH = '100%';

export const NOTES_GRID_OVERSCAN = 1000;

export const FULL_SCREEN = { height: '100%', width: '100%' };

export const LAT_LONG_SLICE_START = 0;

export const LAT_LONG_SLICE_END = 7;

export const MESSAGE_TEXT_LIMIT = 1800;

export const TITLE_TEXT_LIMIT = 50;

export const CARD_IN_CAROUSEL_SIZE = 310;

export const CARD_HEIGHT = 376;

export const CAROUSEL_PADDING_RIGHT = 45;

export const HORIZONTAL_LISTING_NOTES_HEIGHT = '18rem';

export const DISPLAYED_IMAGES_SLICE_START = 0;

export const DISPLAYED_IMAGES_SLICE_END = 4;
