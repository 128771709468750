import { MainPaths } from '@app/v2/shared/enums';

const GREEN_0 = '#81c784';
const GREEN_1 = '#81c784';
const GREEN_2 = '#4caf50';
const GREEN_3 = '#388e3c';
const YELLOW_0 = '#ffb74d';
const YELLOW_1 = '#ffb74d';
const YELLOW_2 = '#ff9800';
const YELLOW_3 = '#f57c00';
const RED_0 = '#e57373';
const RED_1 = '#f44336';
export const RED_2 = '#d32f2f';

export const AlertIndexColorValues = [GREEN_0, GREEN_1, GREEN_2, GREEN_3, YELLOW_0, YELLOW_1, YELLOW_2, YELLOW_3, RED_0, RED_1, RED_2];
export const displayOnPages = [MainPaths.Meteo, MainPaths.Video];

export const MAX_ALERT_INDEX_VALUE = AlertIndexColorValues.length - 1;
