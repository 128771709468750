export enum ExecutionType {
  Set = 'set',
  Clear = 'clear',
}

export enum TableHighlightMode {
  WithHighlight = 'withHighlight',
  WithoutHighlight = 'withoutHighlight',
}

export enum FooterTabs {
  Meteo = 'meteo',
  MeteoLocators = 'meteoLocators',
  Weather = 'weather',
  MapSymbols = 'mapSymbols',
  Notes = 'notes',
  VideoWallSettings = 'videoWallSettings',
  WaterLevelControlSettings = 'waterLevelControlSettings',
  TableDataSetup = 'tableDataSetup',
}

export enum FavoritesStationTypes {
  MeteoStation = 'meteoStation',
  TrafficStation = 'trafficStation',
  VMSStation = 'vmsStation',
}

export enum UpdateFavoritesActions {
  Add = 'add',
  Remove = 'remove',
}

export enum AppTypes {
  AUTHORIZATION = 'AUTHORIZATION',
  MAIN = 'MAIN',
  KIT = 'KIT',
}

export enum SortStatus {
  None = 'none',
  Asc = 'asc',
  Desc = 'desc',
}
