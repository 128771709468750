export const CSDTubExample = `<CSDTub>{tubContent}</CSDTub>
<CSDTub backgroundColor="#3245AF">{tubContent}</CSDTub>
<CSDTub size="small">{tubContent}</CSDTub>
<CSDTub size="small" backgroundColor="#3245AF">{tubContent}</CSDTub>
<CSDTub variant="outlined">{tubContent}</CSDTub>
<CSDTub size="small" variant="outlined">{tubContent}</CSDTub>`;

export const CSDTubCounterExample = `<CSDTubCounter iconName="adult" />
<CSDTubCounter size="small" iconName="adult" />`;

export const CSDZoomControlExample = `<CSDZoomControl />`;

export const CSDMapMarkerSmallWithoutIconsExample = `<CSDMapMarkerSmall markerType={AlertStatuses.Warning} />
<CSDMapMarkerSmall markerType={AlertStatuses.Alert} />
<CSDMapMarkerSmall markerType={AlertStatuses.Notice} />`;

export const CSDMapMarkerSmallExample = `<CSDMapMarkerSmall iconName={icons.tpi} />
<CSDMapMarkerSmall notifications={[{ icon: icons.rainfall }, { icon: icons.car }]} iconName={icons.tpi} />
<CSDMapMarkerSmall notifications={[{ icon: icons.rainfall }, { icon: icons.car }, { icon: icons.photo }]} iconName={icons.tpi} markerType={AlertStatuses.Warning} />
<CSDMapMarkerSmall iconName={icons.tpi} markerType={AlertStatuses.Alert} />
<CSDMapMarkerSmall iconName={icons.tpi} notifications={...}  markerType={AlertStatuses.Notice} />`;

export const CSDMapMarkerSmallNormal = `<CSDMapMarkerNormal
  alertStatus={1}
  mainIcon={icons.rain}
  setOfIcons={[
    { icon: icons.rainfall, component: <Box>Content for rainfall</Box> },
    { icon: icons.car },
    { icon: icons.photo },
    { icon: icons.zpi },
    { icon: icons.tpi },
    { icon: icons.temperature, component: <Temperature /> },
  ]}
/>
<CSDMapMarkerNormal alertStatus={0} collapsible openByDefault setOfIcons={[...]}/>
<CSDMapMarkerNormal alertStatus={0} collapsible openByDefault={false} setOfIcons={[...]}/>
<CSDMapMarkerNormal alertStatus={0} setOfIcons={[...]}/>`;
