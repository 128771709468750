import { useMemo } from 'react';
import moment from 'moment';
import useAppSelector from '@app/core/hooks/customReduxHooks/useAppSelector';

export default function useUtcOffset(): string {
  const { timezoneInsteadCheckbox, utc } = useAppSelector(state => state.account.settings);

  return useMemo(() => {
    return timezoneInsteadCheckbox ? utc : moment().format('Z');
  }, [timezoneInsteadCheckbox, utc]);
}
