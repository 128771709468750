const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;
const MONTH = 30 * DAY;
const YEAR = 12 * MONTH;
const FORECAST = 3 * DAY;

const FORMAT_EN = 'YYYY-MM-DD';
const FORMAT_EN_FULL = 'YYYY-MM-DD HH:mm:ss';
const FORMAT_RU = 'DD-MM-YYYY';
const FORMAT_RU_POINT_WHITESPACE = 'DD.MM YYYY';
const FORMAT_RU_POINT_SHORT_DATE = 'DD.MM.YY';
const FORMAT_RU_POINT_FULL_DATE_AND_UNFULL_TIME = 'DD.MM.YYYY HH:mm';
const FORMAT_RU_POINT_SHORT_DATE_AND_UNFULL_TIME = 'DD.MM.YY HH:mm';
const FORMAT_RU_POINT_SHORTER_DATE_AND_UNFULL_TIME = 'DD.MM HH:mm';
const FORMAT_RU_SHORT = 'DD-MM-YY';
const FORMAT_RU_POINT = 'DD.MM.YYYY';
const FORMAT_RU_FULL = 'DD-MM-YYYY HH:mm:ss';
const FORMAT_RU_UNFULL_TIME = 'DD-MM-YY HH:mm';
const FORMAT_RU_UNFULL_DATE_AND_TIME = 'DD-MM HH:mm';
const FORMAT_RU_HOURS = 'HH:mm';
const FORMAT_RU_TIME = 'HH:mm:ss';
const API_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const FORMAT_WITHOUT_SEPARATORS = 'YYYYMMDDTHHmmss+hhmm';
const WITH_TIME_ZONE = 'YYYY-MM-DDTHH:mm:ssZ';

export const DATE_PERIOD = {
  SECOND,
  MINUTE,
  HOUR,
  DAY,
  WEEK,
  MONTH,
  YEAR,
  FORECAST,
};

export const DATE_PERIOD_IN_SECONDS = {
  MINUTE: MINUTE / SECOND,
  HOUR: HOUR / SECOND,
  DAY: DAY / SECOND,
  WEEK: WEEK / SECOND,
  MONTH: MONTH / SECOND,
};

export const DATE_FORMAT = {
  FORMAT_EN,
  FORMAT_EN_FULL,
  FORMAT_RU,
  FORMAT_RU_SHORT,
  FORMAT_RU_POINT,
  FORMAT_RU_POINT_WHITESPACE,
  FORMAT_RU_POINT_SHORT_DATE,
  FORMAT_RU_POINT_SHORT_DATE_AND_UNFULL_TIME,
  FORMAT_RU_POINT_FULL_DATE_AND_UNFULL_TIME,
  FORMAT_RU_POINT_SHORTER_DATE_AND_UNFULL_TIME,
  FORMAT_RU_FULL,
  FORMAT_RU_HOURS,
  FORMAT_RU_UNFULL_TIME,
  FORMAT_RU_UNFULL_DATE_AND_TIME,
  FORMAT_RU_TIME,
  API_DATE_FORMAT,
  FORMAT_WITHOUT_SEPARATORS,
  WITH_TIME_ZONE,
};
