import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Box, Typography, Divider, TablePagination, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { WaterLevelControlSettings } from '@store/slices/account/types';
import { WATER_LEVEL_CONTROL_DEFAULT_STATE } from '@app/modules/video-water-level-control-module/constants/constants';
import usePaginationSearch from '@app/core/source/hooks/pagination/usePaginationSearch';
import TablePaginationActions from '@app/components/table-pagination-actions/TablePaginationActions';
import { changeAccountSettings } from '@store/thunks/account/account-thunks';
import { useActions, useAppDispatch, useAppSelector } from '@app/v2/shared/hooks';
import { CSDSwitcher, CSDButtonWrapperWithTooltip } from '@app/modules/kit-module/shared/ui';
import useDialog from '@app/core/hooks/useDialog';
import ConfirmDialog from '@app/components/dialogs/confirm-dialog/ConfirmDialog';
import CSDGridController from '../components/CSDGridController';
import CSDTypographySetting from '../components/CSDTypographySetting';
import CSDSettingActions from '../components/CSDSettingActions';
import { ArrowButtonType, SettingsProperty, SliderSettingsProp } from '../constants';
import CSDLinksSize from '../components/CSDLinksSize';

const CSDWaterLevelControlSettingsPanel = () => {
  const { t } = useTranslation('videoWaterLevelControl');
  const { t: tPagination } = useTranslation('videoWaterLevelControl', { keyPrefix: 'pagination' });
  const { waterLevelControlSettings } = useAppSelector(state => state.account.settings);
  const isFooterOpen = useAppSelector(state => state.map.isFooterOpen);
  const dispatch = useAppDispatch();
  const { setAccountWaterLevelControlSettings, setIsFooterOpen } = useActions();
  const [settings, setSettings] = useState<WaterLevelControlSettings>(waterLevelControlSettings);
  const classes = useStyles({ fontSize: settings.fontSize });
  const [pagination, setPagination] = usePaginationSearch();
  const openConfirmDialog = useDialog(ConfirmDialog);

  const handleReset = useCallback(async (): Promise<void> => {
    openConfirmDialog({ question: t('resetQuestion') }).beforeClose.subscribe(async (answerToQuestion: boolean): Promise<void> => {
      if (answerToQuestion) {
        setSettings(WATER_LEVEL_CONTROL_DEFAULT_STATE);
        setAccountWaterLevelControlSettings({ settings: WATER_LEVEL_CONTROL_DEFAULT_STATE });
        await dispatch(changeAccountSettings({ waterLevelControlSettings: { content: WATER_LEVEL_CONTROL_DEFAULT_STATE } }));
      }
    });
  }, [openConfirmDialog, t, setAccountWaterLevelControlSettings, dispatch]);

  const handleSave = useCallback(() => {
    dispatch(changeAccountSettings({ waterLevelControlSettings: { content: settings } }));
    setIsFooterOpen(false);
  }, [settings, setIsFooterOpen, dispatch]);

  const handleChangePage = useCallback(
    (value: number) => {
      setPagination(prev => {
        return {
          ...prev,
          page: value,
        };
      });
    },
    [setPagination],
  );

  const handleChangeRowsColumns = useCallback(
    (type: ArrowButtonType, property: SettingsProperty) => () => {
      setSettings(prevState => ({
        ...prevState,
        [property]: type === ArrowButtonType.INCREASE ? prevState[property] + 1 : prevState[property] - 1,
      }));
    },
    [setSettings],
  );

  const handleChangeMode = useCallback(
    (mode: string) => () => {
      setSettings(prevState => ({
        ...prevState,
        mode,
      }));
    },
    [setSettings],
  );

  const handleChangeCheckbox = useCallback(
    (field: SettingsProperty, checked: boolean) => {
      setSettings(prevState => ({
        ...prevState,
        [field]: checked,
      }));
    },
    [setSettings],
  );

  const handleChangeSlider = useCallback(
    (field: SliderSettingsProp, value: number | number[]) => {
      if (typeof value === 'number') {
        setSettings(prevState => ({
          ...prevState,
          [field]: value,
        }));
      }
    },
    [setSettings],
  );

  return (
    <Box className={classes.settingsPanelContainer}>
      <Stack direction="row" spacing={2}>
        <Stack justifyContent="space-between" spacing={1}>
          <Typography variant="h4">{t('settings.headers.columnsRows')}</Typography>
          <CSDGridController settings={settings} onChangeMode={handleChangeMode} onChangeRowsColumns={handleChangeRowsColumns} />
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack justifyContent="space-between" spacing={1}>
          <Typography variant="h4">{t('settings.headers.fontSize')}</Typography>
          <CSDTypographySetting settings={settings} onChangeSlider={handleChangeSlider} />
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack justifyContent="space-between" spacing={1}>
          <Box className={classes.titleWithButtons}>
            <CSDButtonWrapperWithTooltip title={t('tooltips.settings.links')} placement="top" wrapperProps={{ style: { lineHeight: 1 } }}>
              <CSDSwitcher
                size="small"
                checked={settings.isShowLinks}
                onChange={(_, checked) => {
                  handleChangeCheckbox(SettingsProperty.IS_SHOW_LINKS, checked);
                }}
              />
            </CSDButtonWrapperWithTooltip>
            <Typography variant="h4">{t('settings.headers.linksSize')}</Typography>
          </Box>
          <CSDLinksSize value={settings.linksSize} disabled={!settings.isShowLinks} onChangeSlider={handleChangeSlider} />
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack justifyContent="space-between" spacing={1}>
          <Typography variant="h4">{t('settings.headers.pagination')}</Typography>
          <TablePagination
            classes={{
              root: clsx(classes.tabPanelPaginationRoot),
              toolbar: classes.paginationToolbar,
            }}
            rowsPerPageOptions={[waterLevelControlSettings.cols * waterLevelControlSettings.rows]}
            component="div"
            ActionsComponent={TablePaginationActions}
            count={pagination.total}
            rowsPerPage={pagination.limit}
            page={Math.max(pagination.page - 1, 0)}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}-${to} ${tPagination('from')} ${count}`;
            }}
            onPageChange={(_, val) => handleChangePage(val + 1)}
          />
        </Stack>
      </Stack>
      <CSDSettingActions onSave={handleSave} onReset={handleReset} />
      {!isFooterOpen && (
        <Box className={classes.tabPanelPaginationContainer}>
          <TablePagination
            classes={{
              root: clsx(classes.tabPanelPaginationRoot),
            }}
            rowsPerPageOptions={[waterLevelControlSettings.cols * waterLevelControlSettings.rows]}
            component="div"
            ActionsComponent={TablePaginationActions}
            count={pagination.total}
            rowsPerPage={pagination.limit}
            page={Math.max(pagination.page - 1, 0)}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}-${to} ${tPagination('from')} ${count}`;
            }}
            onPageChange={(_, val) => handleChangePage(val + 1)}
          />
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  settingsPanelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    height: '3.313rem',
  },
  paginationRoot: {
    position: 'relative',
    '& .MuiTablePagination-displayedRows': {
      margin: 0,
      fontSize: '0.938rem',
      lineHeight: 'inherit',
    },
    '& .MuiSvgIcon-root': {
      width: '1.5rem',
      height: '1.5rem',
    },
  },
  paginationRootDisabled: {
    '&:before': {
      display: 'block',
      content: `''`,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 10,
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
  },
  paginationToolbar: {
    minHeight: '1.5rem !important',
    maxHeight: '1.5rem !important',
    padding: '0 !important',
  },
  tabPanelPaginationContainer: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    right: 50,
    marginTop: '-2.875rem',
    minWidth: '195px',
    height: '1.875rem',
    minHeight: '1.875rem',
    border: '0.063rem solid #8D909A',
    borderBottom: 'unset',
    borderRadius: '0.5rem 0.5rem 0rem 0rem',
    boxShadow: '0rem -0.125rem 0.313rem 0rem #34343433',
    clipPath: 'inset(-0.313rem -0.313rem 0rem -0.313rem)',
    backgroundColor: '#FFFFFF',
    color: '#222631',
    borderColor: '#222631',
    '& .MuiToolbar-root': {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  },
  tabPanelPaginationRoot: {
    position: 'relative',
    '& .MuiTablePagination-displayedRows': {
      margin: 0,
      fontSize: '14px',
      lineHeight: 'inherit',
    },
    '& .MuiSvgIcon-root': {
      width: '1rem',
      height: '1rem',
    },
    '& .MuiToolbar-root': {
      minHeight: '1.25rem !important',
      maxHeight: '1.25rem !important',
    },
    '&.MuiTablePagination-root': {
      overflow: 'hidden',
    },
  },
  titleWithButtons: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default CSDWaterLevelControlSettingsPanel;
