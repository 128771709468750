import * as Yup from 'yup';
import { i18n } from '@app/v2/shared/localization';

Yup.setLocale({
  string: {
    length: ({ length }) => i18n.t('validation:string.length', { length }),
    min: ({ min }) => i18n.t('validation:string.min', { min }),
    max: ({ max }) => i18n.t('validation:string.max', { max }),
    matches: ({ regex }) => i18n.t('validation:string.matches', { regex }),
    email: () => i18n.t('validation:string.email'),
    url: () => i18n.t('validation:string.url'),
    trim: () => i18n.t('validation:string.trim'),
    lowercase: () => i18n.t('validation:string.lowercase'),
    uppercase: () => i18n.t('validation:string.uppercase'),
  },
  number: {
    min: ({ min }) => i18n.t('validation:number.min', { min }),
    max: ({ max }) => i18n.t('validation:number.max', { max }),
    lessThan: ({ less }) => i18n.t('validation:number.less', { less }),
    moreThan: ({ more }) => i18n.t('validation:number.more', { more }),
    positive: () => i18n.t('validation:string.positive'),
    negative: () => i18n.t('validation:string.negative'),
    integer: () => i18n.t('validation:string.integer'),
  },
  date: {
    min: ({ min }) => i18n.t('validation:date.min', { min }),
    max: ({ max }) => i18n.t('validation:date.max', { max }),
  },
  object: {
    noUnknown: ({ label }) => i18n.t('validation:object.noUnknown', { label }),
  },
  array: {
    min: ({ min }) => i18n.t('validation:array.min', { min }),
    max: ({ max }) => i18n.t('validation:array.max', { max }),
  },
  mixed: {
    default: () => i18n.t('validation:mixed.default'),
    required: () => i18n.t('validation:mixed.required'),
    oneOf: ({ values }) => i18n.t('validation:array.oneOf', { values }),
    notOneOf: ({ path, values }) => i18n.t('validation:array.notOneOf', { values }),
    notType: () => i18n.t('validation:mixed.notType'),
  },
});

export default Yup;
