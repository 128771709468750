import React, { memo, useEffect, useRef } from 'react';
import DrawScoreboard from '@app/modules/kit-module/shared/ui/CSDScoreboardsViewer/DrawScoreboard';
import { GRID_LINE_COLOR, PIXEL_SIZE } from '@app/v2/shared/constants';

const CSDScoreboardsViewerCanvas = ({
  fullSize,
  background,
  isDrawCells,
  scoreboardWidth,
  compressionRatio,
  scoreboardHeight,
  prepareRender,
}: Scoreboards.ViewerProps & { prepareRender: (context: CanvasRenderingContext2D) => Promise<void>[] }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const drawScoreboard = new DrawScoreboard({ x: 0, y: 0 });

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    canvas.height = scoreboardHeight * PIXEL_SIZE;
    canvas.width = scoreboardWidth * PIXEL_SIZE;

    drawScoreboard.reset(context, { background });

    canvas.style.height = fullSize ? '100%' : `${canvas.height * compressionRatio}px`;
    canvas.style.width = fullSize ? '100%' : `${canvas.width * compressionRatio}px`;

    Promise.all(prepareRender(context)).then(() => {
      if (isDrawCells) drawScoreboard.drawCells(context, { size: PIXEL_SIZE / compressionRatio, color: GRID_LINE_COLOR });
    });
  }, [background, compressionRatio, fullSize, isDrawCells, prepareRender, scoreboardHeight, scoreboardWidth]);

  return <canvas ref={canvasRef} />;
};

export default memo(CSDScoreboardsViewerCanvas);
