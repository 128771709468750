const splitArrayByCondition = <T>(array: T[], conditionFunction: (item: T) => boolean): { itemsWithCondition: T[]; otherItems: T[] } => {
  return array.reduce<{ itemsWithCondition: T[]; otherItems: T[] }>(
    (res, item) => {
      if (conditionFunction(item)) {
        res.itemsWithCondition.push(item);
        return res;
      }
      res.otherItems.push(item);
      return res;
    },
    { itemsWithCondition: [], otherItems: [] },
  );
};

export default splitArrayByCondition;
