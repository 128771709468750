import React from 'react';
import { Box, Button, ButtonProps, Divider, Icon, Stack, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isFunction } from '@app/v2/shared/helpers';

interface BtnsConfigItem {
  isActive: boolean;
  disabled?: boolean;
  label?: string;
  icon?: string;
  tooltip?: string;
  onClick?: Common.VoidCallBack;
}

interface Props {
  title?: string;
  disableAll?: boolean;
  btnsConfig: BtnsConfigItem[];
  hidden?: boolean;
  withEndDivider?: boolean;
  withStartDivider?: boolean;
}

const CSDButtonsBlock = ({ title, disableAll, btnsConfig, withEndDivider = false, withStartDivider = false, hidden = false }: Props) => {
  const handleClick = (handler: Common.VoidCallBack): void => {
    if (!isFunction(handler)) return;
    handler();
  };

  return (
    <>
      {!hidden && (
        <Stack direction="row" gap="1rem">
          {withStartDivider && <Divider orientation="vertical" flexItem />}

          <Box>
            <Typography variant="h3" sx={{ marginBottom: '0.5rem' }}>
              {title}
            </Typography>

            <Stack direction="row" sx={{ gap: '0.5rem' }}>
              {btnsConfig
                .map((configItem, index) => ({ ...configItem, id: index }))
                .map(({ id, label, icon, onClick, isActive, disabled, tooltip }) => (
                  <Tooltip title={tooltip} key={id}>
                    <span>
                      <StyledBtn
                        key={id}
                        disabled={disableAll || disabled}
                        onClick={() => handleClick(onClick)}
                        variant={isActive ? 'contained' : 'outlined'}
                      >
                        {label ?? <Icon sx={{ fontSize: '1rem' }}>{icon}</Icon>}
                      </StyledBtn>
                    </span>
                  </Tooltip>
                ))}
            </Stack>
          </Box>

          {withEndDivider && <Divider orientation="vertical" flexItem />}
        </Stack>
      )}
    </>
  );
};

export default CSDButtonsBlock;

const StyledBtn = styled(Button)<ButtonProps>({
  height: '1.75rem',
  padding: '0 0.5rem',
  minWidth: 0,
  fontSize: '0.875rem',
});
