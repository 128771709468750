export const errorSnackbar = `<CSDSnackbar 
  message="Сообщение об ошибке" 
  actionButtonTitle="Открыть" 
  severity="error"
/>`;

export const successSnackbar = `<CSDSnackbar 
  message="Сообщение об успехе" 
  actionButtonTitle="Открыть" 
  severity="success" 
/>`;

export const infoSnackbar = `<CSDSnackbar 
  message="Нейтральное сообщение" a
  ctionButtonTitle="Открыть" 
  severity="info"
/>`;
