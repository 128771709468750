import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { CSDDataPickerAntd } from '@app/modules/kit-module/shared/ui';
import { DATE_FORMAT } from '@app/v2/shared/constants';

interface Props {
  path: string;
}

const PlaylistTimeFormFields = ({ path }: Props) => {
  const classes = useStyles();

  const { setFieldValue, getFieldProps } = useFormikContext<Scoreboards.PlaylistModel>();

  const { t } = useTranslation('scoreboard');

  const { dateFrom, dateTo, endTime, startTime } = useMemo(
    () => ({
      dateFrom: {
        path: `${path}.dateFrom`,
        value: moment(getFieldProps(`${path}.dateFrom`).value),
      },
      dateTo: {
        path: `${path}.dateTo`,
        value: moment(getFieldProps(`${path}.dateTo`).value),
      },
      startTime: {
        path: `${path}.startTime`,
        value: moment(getFieldProps(`${path}.startTime`).value, DATE_FORMAT.FORMAT_RU_HOURS),
      },
      endTime: {
        path: `${path}.endTime`,
        value: moment(getFieldProps(`${path}.endTime`).value, DATE_FORMAT.FORMAT_RU_HOURS),
      },
    }),
    [getFieldProps, path],
  );

  const handleChange = (pathToValue: string, nextValue: string) => {
    setFieldValue(pathToValue, nextValue);
  };

  return (
    <Box className={classes.timeFieldsWrapper}>
      <CSDDataPickerAntd
        size="large"
        value={dateFrom.value}
        labelVariant="subtitle2"
        format={DATE_FORMAT.FORMAT_RU_POINT}
        label={t('playlists.form.dateStart')}
        disabledDate={current => current && current.isBefore(moment().subtract(1, 'day'))}
        onChange={nextValue => handleChange(dateFrom.path, nextValue.format(DATE_FORMAT.API_DATE_FORMAT))}
      />

      <CSDDataPickerAntd
        size="large"
        value={dateTo.value}
        labelVariant="subtitle2"
        label={t('playlists.form.dateEnd')}
        disabledDate={current => current && current.isBefore(dateFrom.value)}
        format={DATE_FORMAT.FORMAT_RU_POINT}
        onChange={nextValue => handleChange(dateTo.path, nextValue.format(DATE_FORMAT.API_DATE_FORMAT))}
      />

      <CSDDataPickerAntd
        size="large"
        picker="time"
        value={startTime.value}
        labelVariant="subtitle2"
        format={DATE_FORMAT.FORMAT_RU_HOURS}
        label={t('playlists.form.timeStart')}
        onSelect={nextValue => handleChange(startTime.path, nextValue.format(DATE_FORMAT.FORMAT_RU_HOURS))}
      />

      <CSDDataPickerAntd
        size="large"
        picker="time"
        value={endTime.value}
        labelVariant="subtitle2"
        format={DATE_FORMAT.FORMAT_RU_HOURS}
        label={t('playlists.form.timeEnd')}
        onSelect={nextValue => handleChange(endTime.path, nextValue.format(DATE_FORMAT.FORMAT_RU_HOURS))}
      />
    </Box>
  );
};

const useStyles = makeStyles({
  timeFieldsWrapper: {
    gap: '1rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export default PlaylistTimeFormFields;
