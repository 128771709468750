import React from 'react';
import { Stack } from '@mui/material';
import { CSDAutocomplete } from '@app/modules/kit-module/shared/ui';
import CSDKitDocBlock from '@app/modules/kit-module/shared/ui/CSDKitDocBlock/CSDKitDocBlock';
import { autocompleteCodeExample, disabledAutocompleteCodeExample, fullWidthAutocompleteCodeExample, multipleAutocomplete } from './example';

const top100Films = [
  { label: 'The Shawshank Redemption', value: 1994 },
  { label: 'The Godfather', value: 1972 },
  { label: 'The Godfather: Part II', value: 1974 },
  { label: 'The Dark Knight', value: 2008 },
  { label: '12 Angry Men', value: 1957 },
  { label: "Schindler's List", value: 1993 },
  { label: 'Pulp Fiction', value: 1994 },
  { label: 'City of God', value: 2002 },
  { label: 'Se7en', value: 1995 },
  { label: 'The Silence of the Lambs', value: 1991 },
  { label: "It's a Wonderful Life", value: 1946 },
  { label: 'Life Is Beautiful', value: 1997 },
  { label: 'The Usual Suspects', value: 1995 },
  { label: 'Léon: The Professional', value: 1994 },
  { label: 'Spirited Away', value: 2001 },
  { label: 'Saving Private Ryan', value: 1998 },
  { label: 'Once Upon a Time in the West', value: 1968 },
  { label: 'American History X', value: 1998 },
  { label: 'Interstellar', value: 2014 },
  { label: 'Casablanca', value: 1942 },
  { label: 'City Lights', value: 1931 },
  { label: 'Psycho', value: 1960 },
];

const CSDAutocompletes = () => {
  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="Autocomplete" codeExample={autocompleteCodeExample}>
        <CSDAutocomplete<Common.SelectorValue, false>
          label="Вопрос"
          inputProps={{
            placeholder: '2-я бауманская д5',
            sx: { width: '450px' },
          }}
          autocompleteProps={{
            options: top100Films,
          }}
        />
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Autocomplete full width" codeExample={fullWidthAutocompleteCodeExample}>
        <CSDAutocomplete<Common.SelectorValue, false>
          label="Вопрос"
          inputProps={{
            placeholder: '2-я бауманская д5',
            fullWidth: true,
          }}
          autocompleteProps={{
            options: top100Films,
          }}
        />
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Autocomplete disabled" codeExample={disabledAutocompleteCodeExample}>
        <CSDAutocomplete<Common.SelectorValue, false>
          label="Вопрос"
          disabled
          inputProps={{
            placeholder: '2-я бауманская д5',
            sx: { width: '450px' },
          }}
          autocompleteProps={{
            options: top100Films,
          }}
        />
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Multiple autocomplete" codeExample={multipleAutocomplete}>
        <CSDAutocomplete
          label="Вопрос"
          inputProps={{
            placeholder: '2-я бауманская д5',
            sx: { width: '450px' },
          }}
          autocompleteProps={{
            options: top100Films,
            multiple: true,
          }}
        />
      </CSDKitDocBlock>
    </Stack>
  );
};

export default CSDAutocompletes;
