import React from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, IconButton, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { makeStyles } from '@mui/styles';
import { CSDInputStandard } from '@app/modules/kit-module/shared/ui';
import { alignmentConfig, EMPTY_STRING } from '@app/v2/shared/constants';
import { DataTestIds } from '@app/v2/shared/enums';

interface Props extends Omit<Scoreboards.TemplateFieldsOptions, 'numLines'> {
  fieldIndex: number;
}

const CSDMessageField = ({ fieldIndex, numCharactersPerLine, configurationItemIndex, t }: Props & I18N.TranslationFunction<'scoreboard'>) => {
  const { values, handleChange } = useFormikContext<Scoreboards.TemplateContent>();

  const classes = useStyles();

  return (
    <Stack direction="row" className={classes.wrapper} data-testid={DataTestIds.TemplateMessageField}>
      <Typography variant="body2">{`${t('templates.form.string')} ${fieldIndex + 1}`}</Typography>

      <CSDInputStandard
        name={`contents[${configurationItemIndex}][${fieldIndex}].text`}
        value={values.contents[configurationItemIndex][fieldIndex]?.text ?? EMPTY_STRING}
        onChange={handleChange}
        inputProps={{
          maxLength: numCharactersPerLine,
        }}
      />

      {alignmentConfig.map(({ isActive, icon, direction, key }) => (
        <IconButton
          key={`${key}-${icon}`}
          onClick={() =>
            handleChange({
              target: {
                name: `contents[${configurationItemIndex}][${fieldIndex}].align`,
                value: direction,
              },
            })
          }
        >
          <Icon
            sx={{
              color: ({ palette }) => {
                if (isActive(values.contents[configurationItemIndex][fieldIndex]?.align)) return palette.common.black;
                return palette.text.t2;
              },
            }}
          >
            {icon}
          </Icon>
        </IconButton>
      ))}
    </Stack>
  );
};

const useStyles = makeStyles({
  wrapper: {
    gap: '0.5rem',
    alignItems: 'flex-end',
    '& > p': {
      whiteSpace: 'nowrap',
    },
  },
  paperSkeleton: {
    width: 'calc(100% - 4rem)',
    margin: '0 2rem',
    height: '39.75rem',
    marginBottom: '1rem',
    minHeight: '39.75rem',
    borderRadius: '0.5rem',
  },
});

export default withTranslation('scoreboard')(CSDMessageField);
