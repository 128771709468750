import React, { memo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import CustomEvents from 'highcharts-custom-events';
import { Box } from '@mui/material';
import moment from 'moment';
import NoChartData from '@app/components/charts/common/components/NoChartData';
import useRoadPlanChartOptions from '../hooks/useRoadPlanChartOptions';
import PlanChartHeaderId from '../../common/enums';
import type { RoadPlan } from '../types';

// @ts-ignore
CustomEvents(Highcharts);

type Props = {
  data: RoadPlan;
  chartHeight: number;
};

const RoadPlanChart = ({ data: { places, alerts }, chartHeight }: Props) => {
  alerts.sort((a, b) => moment(a.from).valueOf() - moment(b.from).valueOf());

  const chartOptions = useRoadPlanChartOptions({ alerts, places }, chartHeight);

  return (
    <Box id={PlanChartHeaderId.ROAD} position="relative" overflow="hidden">
      {[...alerts, ...places].length ? (
        <HighchartsReact
          highcharts={Highcharts}
          immutable
          constructorType="ganttChart"
          options={chartOptions}
          containerProps={{
            style: {
              padding: '0 1rem',
            },
          }}
        />
      ) : (
        <NoChartData />
      )}
    </Box>
  );
};

export default memo(RoadPlanChart);
