import { ProfilesTypes } from '@app/v2/shared/enums';
import { INITIAL_PROFILES } from '../constants/profiles';

export default function getNewTemporaryProfile(type: ProfilesTypes): Profiles.CommonProfile {
  return {
    id: -1,
    label: '',
    description: 'Описание отсутствует!',
    isDefault: false,
    items: INITIAL_PROFILES[type].items,
  };
}
