import React, { useCallback, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import CSDWaterLevelSnapshotDialog from '@app/modules/kit-module/shared/ui/CSDWaterLevelSnapshot/CSDWaterLevelSnapshotDialog';
import { useWaterLevelChart, useDialog } from '@app/v2/shared/hooks';
import { CSDWaterLevelChart } from '@app/modules/kit-module/shared/ui';
import useWeatherLevelOptions from './useWeatherLevelOptions';
import type { ChartConnectorProps } from './types';

const chartKeys: string[] = ['waterLevel'];

const CSDWeatherLevelChartConnector = ({ dataForChart, roadTitle, t }: ChartConnectorProps & I18N.TranslationFunction<'charts', 'waterLevel'>) => {
  const classes = useStyles();
  const openSnapshotDialog = useDialog(CSDWaterLevelSnapshotDialog);

  const { chartsData, handleSetParameters, handleSetIsFixed } = useWaterLevelChart();

  const fixTooltipPosition = useCallback(
    ({ date, coords, index, currentPointData }: WLC.ChartPointData) => {
      handleSetParameters({
        date,
        coords,
        isFixed: true,
        currentValueIndex: index,
        data: chartKeys.map(key => ({
          key,
          ...currentPointData[key],
          value: dataForChart?.content?.[key]?.[index],
        })),
        dialogData: {
          snapshotUrl: dataForChart?.content?.snapshot?.[index],
          kilometer: dataForChart.place.address,
          meter: dataForChart.place.meters,
          title: roadTitle,
          dateTime: dataForChart?.dates?.[index],
        },
      });
    },
    [dataForChart, roadTitle, handleSetParameters],
  );

  const { options } = useWeatherLevelOptions({
    title: t('title'),
    data: dataForChart,
    isFixed: chartsData?.isFixed ?? true,
    currentIndex: chartsData?.currentValueIndex,
    keys: chartKeys,
    fixTooltipPosition,
  });

  useEffect(() => () => handleSetIsFixed(false), [handleSetIsFixed]);

  return (
    <Box className={classes.wrapper}>
      <CSDWaterLevelChart
        className={classes.chart}
        options={options}
        chartsData={chartsData}
        onCloseTooltip={() => handleSetIsFixed(false)}
        openDialog={() => openSnapshotDialog(chartsData.dialogData)}
      />
    </Box>
  );
};

const useStyles = makeStyles<Theme>({
  wrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    height: '100%',
    width: '100%',
    borderRadius: '0.5rem',
  },
  chart: {
    borderRadius: '0.5rem',
    border: '2px solid black',
    padding: '1rem 1.5rem',
  },
});

export default withTranslation('charts', { keyPrefix: 'waterLevel' })(CSDWeatherLevelChartConnector);
