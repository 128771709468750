import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useDialog } from '@app/v2/shared/hooks';
import CSDViewDeviceSettingsDialog from './CSDViewDeviceSettingsDialog';

interface Props {
  devices: {
    stationId: number;
    settings: Scoreboards.DeviceSettings;
  }[];
}

const CSDViewDeviceSettings = ({ devices }: Props) => {
  const { t } = useTranslation('scoreboard');
  const openDeviceSettingsDialog = useDialog<Props>(CSDViewDeviceSettingsDialog);

  const handleFetchDeviceSettingsInfo = () => {
    openDeviceSettingsDialog({ devices });
  };

  return (
    <Button sx={{ minWidth: 0, padding: 0 }} variant="outlined" size="small" onClick={handleFetchDeviceSettingsInfo}>
      {t('devices.devices')}
    </Button>
  );
};

export default CSDViewDeviceSettings;
