import { RoadObjectsTypes } from '@app/v2/shared/enums';
import Place from '@app/modules/map-module/interfaces/Place';
import AlertStatuses from '../enums/AlertStatuses';

export const MarkerColors = {
  [AlertStatuses.Alert]: { background: '#CE2E2E', text: 'white' },
  [AlertStatuses.Warning]: { background: '#FFDB5B', text: 'black' },
  [AlertStatuses.Notice]: { background: '#00A151', text: 'white' },
  [AlertStatuses.Base]: { background: '#0F79DF', text: 'white' },
  [AlertStatuses.Expired]: { background: '#8D909A', text: 'white' },
};

export const roadObjectsMarkersInitialState: Record<RoadObjectsTypes, Place[]> = {
  [RoadObjectsTypes.VIDEO]: [],
  [RoadObjectsTypes.TRAFFIC]: [],
  [RoadObjectsTypes.MESSAGE_STATIONS]: [],
  [RoadObjectsTypes.SIGN_STATIONS]: [],
  [RoadObjectsTypes.METEO_PARAMS_FIRST]: [],
  [RoadObjectsTypes.METEO_PARAMS_SECOND]: [],
  [RoadObjectsTypes.ROAD_ADDRESS]: [],
};
