import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Icon, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import { HeaderFastLinksItem } from '@app/v2/widgets/header/types';

interface Props extends WithTranslation<'header'> {
  link: HeaderFastLinksItem;
  search: string;
}

const CSDFastLinkItem = ({ link, search, t }: Props) => {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);

  return (
    <Tooltip open={open} leaveDelay={0} title={t(link.tooltip)}>
      <NavLink
        to={{ pathname: link.path, search }}
        onMouseOver={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        onClick={() => setOpen(false)}
        className={classes.link}
        activeClassName={classes.activeLink}
      >
        <Icon>{link.icon}</Icon>
      </NavLink>
    </Tooltip>
  );
};

const useStyles = makeStyles(({ palette }: Theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    textDecoration: 'none',

    '& .custom-icon': {
      overflow: 'inherit',
    },
  },
  activeLink: {
    color: palette.primary.main,
    cursor: 'default',
    transform: 'scale(1.2)',
  },
}));

export default withTranslation('header')(CSDFastLinkItem);
