import React, { memo } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import PasswordRecoveryLink from '@app/modules/auth-module/components/passwordRecoveryLinkForm/PasswordRecoveryLink';
import { useTitle } from '@app/v2/shared/hooks';
import styles from './index.module.scss';

const Layout = ({ t }: WithTranslation<'auth', 'passwordRecovery'>) => {
  useTitle(t('linkModuleTitle'));

  return (
    <Box className={styles.authWrapper}>
      <PasswordRecoveryLink />
    </Box>
  );
};

export default withTranslation('auth', { keyPrefix: 'passwordRecovery' })(memo(Layout));
