import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';

type TableStylesParams = {
  isWithOldPanel: boolean;
  backgroundHeaderColor: string;
  colorHeader: string;
  top: number;
};

const useTableStyles = makeStyles<Theme, Partial<TableStylesParams>>(() => ({
  paperContainer: {
    height: ({ isWithOldPanel }) => (isWithOldPanel ? 'calc(100% - 60px)' : '100%'),
  },
  tableContainer: {
    height: 'calc(100% - 40px)',
  },
  tableContainerWithoutPagination: {
    height: '100%',
  },
  row: {
    '& td': {
      textAlign: 'center',
      backgroundColor: '#eee',
      padding: '6px 17px 6px 16px',
    },

    '&:nth-child(2n) td': {
      backgroundColor: '#fff',
      padding: '6px 17px 6px 16px',
    },
  },
  headerRow: {
    backgroundColor: '#eef',
    position: 'sticky',
    zIndex: 10,
    top: ({ top }) => top,
  },
  headerColumn: {
    backgroundColor: ({ backgroundHeaderColor }) => backgroundHeaderColor || '#000 !important',
    color: ({ colorHeader }) => colorHeader || '#fff !important',
    borderLeft: '1px solid #fff !important',
    borderBottom: '1px solid #fff !important',
    padding: '8px',
  },
  tooltip: {
    fontSize: 14,
    minWidth: 600,
    textAlign: 'center',
  },
}));

export default useTableStyles;
