import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Icon, Tooltip } from '@mui/material';
import { SortStatus } from '@app/v2/shared/enums';
import icons from '@app/assets/iconFont';

interface Props {
  sorting: Common.Sorting;
  sortKey: string;
  onSorting: (key: string, value: SortStatus) => void;
}

const DataGridSortDefault = ({ sorting, sortKey, onSorting }: Props) => {
  const { t } = useTranslation('common');

  const value = sorting.key === sortKey ? sorting.value : SortStatus.None;

  const renderBySortStatus: Record<SortStatus, ReactNode> = {
    [SortStatus.Asc]: (
      <Tooltip title={t('sorting.asc')}>
        <Icon>{icons.straight}</Icon>
      </Tooltip>
    ),
    [SortStatus.Desc]: (
      <Tooltip title={t('sorting.desc')}>
        <Icon>{icons.reverse}</Icon>
      </Tooltip>
    ),
    [SortStatus.None]: (
      <Tooltip title={t('sorting.none')}>
        <Icon color="disabled">{icons.straight}</Icon>
      </Tooltip>
    ),
  };

  const handleClick = () => {
    if (value === SortStatus.None) onSorting(sortKey, SortStatus.Asc);
    if (value === SortStatus.Asc) onSorting(sortKey, SortStatus.Desc);
    if (value === SortStatus.Desc) onSorting(sortKey, SortStatus.None);
  };

  return (
    <Box sx={{ cursor: 'pointer' }} onClick={handleClick}>
      {renderBySortStatus[value]}
    </Box>
  );
};

export default DataGridSortDefault;
