/* eslint-disable react/no-array-index-key */
/* There is no other way to describe the keys */
import React from 'react';
import { useCheckAccess } from '@app/v2/shared/hooks';
import SideBarListItem from './CSDSideBarListItem';
import SideBarListRootItem from './CSDSideBarListRootItem';

interface Props {
  onClose: Common.VoidCallBack;
  sidebarConfigItems: StaticConfigs.Sidebar[];
}

const CSDSideBarListPoint = ({ sidebarConfigItems, onClose }: Props) => {
  const { accessChecker } = useCheckAccess();
  const isAccess = (sidebarConfigItem: StaticConfigs.Sidebar) =>
    sidebarConfigItem.perimeters && sidebarConfigItem.perimeters.some(perimeter => accessChecker([perimeter]));

  return (
    <>
      {sidebarConfigItems
        .map(sidebarConfigItem => (isAccess(sidebarConfigItem) ? { ...sidebarConfigItem, disabled: !sidebarConfigItem.disabled } : sidebarConfigItem))
        .filter(({ disabled }) => disabled !== true)
        .map((sidebarConfigItem, i) =>
          Object.hasOwnProperty.call(sidebarConfigItem, 'children') ? (
            <SideBarListRootItem
              key={`${sidebarConfigItem.key.toString()}:${i}`}
              sidebarConfigItem={sidebarConfigItem as StaticConfigs.SidebarRootItem}
            >
              <CSDSideBarListPoint sidebarConfigItems={sidebarConfigItem.children} onClose={onClose} />
            </SideBarListRootItem>
          ) : (
            <SideBarListItem key={`${sidebarConfigItem.key.toString()}:${i}`} item={sidebarConfigItem} onClose={onClose} />
          ),
        )}
    </>
  );
};

export default CSDSideBarListPoint;
