import { useCallback, useEffect } from 'react';
import { ListItem } from 'react-virtuoso';
import { Row } from '@app/modules/kit-module/shared/ui/CSDDataGrid/DataGridTable/types';
import useActions from './reactRedux/useActions';

export default function useTableViewportTrack<ListingData extends Record<'id', number>, TableData extends Table.TableData>(
  listingData: ListingData[] = [],
) {
  const { addRoadsToTrack, setAllElementWithTime, setCurrentElementsIdsInViewPort } = useActions();

  useEffect(() => {
    if (listingData.length) {
      setAllElementWithTime(listingData.map(i => ({ id: i.id, time: i[Symbol.for('timestamp')] })));
    }
  }, [listingData, setAllElementWithTime]);

  const addRoadsToTrackHandler = useCallback(
    (items: ListItem<Row<TableData>>[]): void => {
      const { ids, roadIds } = items.reduce<{ ids: { id: string }[]; roadIds: number[] }>(
        (res, { data }) => {
          if (!data || !Object.hasOwnProperty.call(data, 'id')) return res;

          res.roadIds.push(Number(data.roadId));
          res.ids.push({ id: String(data.id) });
          return res;
        },
        { ids: [], roadIds: [] },
      );
      const uniqueRoadIdsArray = new Set(roadIds);

      addRoadsToTrack({ roadIds: Array.from(uniqueRoadIdsArray) });
      setCurrentElementsIdsInViewPort(ids);
    },
    [addRoadsToTrack, setCurrentElementsIdsInViewPort],
  );

  return {
    handleTrack: addRoadsToTrackHandler,
  };
}
