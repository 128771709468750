import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { Card, CardActions, CardContent, CardHeader } from '@mui/material';
import { styled } from '@mui/styles';
import Alert from '@mui/lab/Alert';
import getErrorTranslationMessage from '@app/core/helpers/getErrorTranslationMessage';
import { useAppSelector } from '@app/v2/shared/hooks';
import AuthFormActions from './AuthFormActions/AuthFormActions';
import InputFields from './AuthFormContent/InputFields';

const AuthForm = ({ t }: I18N.TranslationFunction<'auth', 'login'>) => {
  const isAuthError = useAppSelector(state => state.auth.authErrorStatus);
  const authErrorMessage = useAppSelector(state => state.auth.authErrorStatusMessage);

  return (
    <StyledCard elevation={3}>
      <>
        <StyledCardHeader title={t('cardTitle')} />
        {isAuthError && <StyledAlert severity="error">{getErrorTranslationMessage(authErrorMessage)}</StyledAlert>}
        <StyledCardContent>
          <InputFields />
        </StyledCardContent>
        <StyledCardActions>
          <AuthFormActions />
        </StyledCardActions>
      </>
    </StyledCard>
  );
};

const StyledCard = styled(Card)({
  '&.MuiPaper-root': {
    width: '18.25rem',
    minHeight: '24.75rem',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '0 !important',

    '& span.MuiCardHeader-title': {
      textAlign: 'center',
      fontSize: '1.25rem',
      lineHeight: '1.25rem',
      padding: '1rem',
    },
  },
});

const StyledCardHeader = styled(CardHeader)({
  boxShadow: '0rem 0.313rem 0.313rem rgba(52, 52, 52, 0.2)',
});

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  padding: '0 1.5rem !important',
});

const StyledCardActions = styled(CardActions)({
  justifyContent: 'flex-end',
});

const StyledAlert = styled(Alert)({
  margin: '1.5rem 1.5rem 0 1.5rem',
});

export default withTranslation('auth', { keyPrefix: 'login' })(memo(AuthForm));
