enum PieChartColors {
  BLUE = 'blue',
  SKY_BLUE = 'sky-blue',
  GREEN = 'green',
  YELLOW = 'yellow',
  RED = 'red',
  GREY = 'grey',
  SAND = 'sand',
}

export default PieChartColors;
