import { shallowEqual } from 'react-redux';
import { useCallback } from 'react';
import { fetchAccountSettings } from '@store/thunks/account/account-thunks';
import { getStartPages } from '@store/thunks/system/sustem-thunks';
import { Pages } from '@app/v2/shared/enums';
import useAppDispatch from '../reactRedux/useAppDispatch';
import useAppSelector from '../reactRedux/useAppSelector';

export default function useAccount() {
  const dispatch = useAppDispatch();

  const data = useAppSelector(state => state.account, shallowEqual);

  const isAccountHaveEmailAndPhone = !!data.settings?.userEmail && !!data.settings?.userPhone;

  const startPages = useAppSelector(state => state.system.startPages);
  const startPagesLoading = useAppSelector(state => state.system.startPagesLoading);
  const isAccountTimeSettingsUpdated = useAppSelector(state => state.account.isAccountTimeSettingsUpdated);

  const fetchSettings = useCallback(async () => {
    await dispatch(getStartPages());
    await dispatch(fetchAccountSettings());
  }, [dispatch]);

  return {
    accountStartPage: startPages.find(({ value }) => value === data.settings.startPage)?.path ?? Pages.Index,
    startPages,
    isAccountTimeSettingsUpdated,
    fetchSettings,
    startPagesLoading,
    isAccountHaveEmailAndPhone,
    ...data,
  };
}
