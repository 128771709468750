enum WWOWeatherTypes {
  Temperature = 'temperature',
  Cloud = 'clouds',
  Precipitations = 'precipitations',
  Humidity = 'humidity',
  Visibility = 'visibility',
  Wind = 'wind',
  Pressure = 'pressure',
}

export default WWOWeatherTypes;
