import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import L from 'leaflet';
import { Icon, IconButton } from '@mui/material';
import icons from '@app/assets/iconFont';
import AllowPositionDialog from '@app/modules/map-module/components/map/my-location-control/AllowPositionDialog';
import { useDialog } from '@app/v2/shared/hooks';

type Props = {
  onReceivedLocation: (location: { latitude: number; longitude: number }) => void;
  map: L.Map;
  controlPosition?: 'topleft' | 'topright' | 'bottomleft' | 'bottomright';
};

const MyLocationControl = ({ onReceivedLocation, map, controlPosition = 'topright' }: Props) => {
  const { t } = useTranslation('components');
  const allowPositionDialog = useDialog(AllowPositionDialog);

  useEffect(() => {
    const controlDiv = L.DomUtil.create('div', 'leaflet-bar leaflet-control my-position');

    const CustomControl = L.Control.extend({
      onAdd: () => controlDiv,
    });

    const instance = new CustomControl({ position: controlPosition });
    map.addControl(instance);

    return () => {
      map.removeControl(instance);
    };
  }, [controlPosition, map]);

  const requestLocation = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        currentPossition => {
          const { latitude, longitude } = currentPossition.coords;
          onReceivedLocation({ latitude, longitude });
        },
        () => {
          allowPositionDialog();
        },
      );
    } else {
      // eslint-disable-next-line no-console
      console.error('Geolocation is not supported by your browser.');
    }
  };

  return ReactDOM.createPortal(
    <IconButton
      title={t('myLocation')}
      onClick={event => requestLocation(event)}
      sx={{
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: '0.5rem',
      }}
    >
      <Icon>{icons.person}</Icon>
    </IconButton>,
    document.querySelector('div.leaflet-bar.leaflet-control.my-position') || document.body,
  );
};

export default MyLocationControl;
