import { CardsViewMode, TPIConfigurationVolumeLabelValues, VMSTemplatesTypes } from '../enums';

interface VMSMocks {
  playlist: Scoreboards.Playlist;
  templateWithModeAndId: Scoreboards.TemplateWithModeAndId;
  deviceSettings: Scoreboards.DeviceSettings;
}

export const vmsMocks: VMSMocks = {
  playlist: {
    id: 1,
    title: 'title',
    isGroup: false,
    organization: {
      id: 1,
      title: 'title',
    },
    playlist: [
      {
        dateFrom: null,
        dateTo: null,
        endTime: null,
        startTime: null,
        duration: null,
        template: {
          id: 1,
          title: 'title',
          configuration: {
            id: 1,
            organizationId: null,
            type: null,
            size: {
              height: null,
              width: null,
            },
            color: null,
            title: 'title',
            configuration: {
              items: [],
              visible: true,
              backgroundColor: '',
              volumeLabel: TPIConfigurationVolumeLabelValues.D,
            },
          },
          contents: [],
        },
      },
      {
        dateFrom: null,
        dateTo: null,
        endTime: null,
        startTime: null,
        duration: null,
        template: {
          id: 2,
          title: 'title',
          configuration: {
            id: 1,
            organizationId: null,
            color: null,
            type: null,
            size: {
              height: null,
              width: null,
            },
            title: 'title',
            configuration: {
              items: [],
              visible: true,
              backgroundColor: '',
              volumeLabel: TPIConfigurationVolumeLabelValues.D,
            },
          },
          contents: [],
        },
      },
    ],
  },
  templateWithModeAndId: {
    id: 0,
    duration: null,
    startTime: null,
    endTime: null,
    dateTo: null,
    mode: CardsViewMode.View,
    dateFrom: null,
    template: {
      id: 1,
      title: 'title',
      contents: [],
      configuration: {
        id: 1,
        organizationId: null,
        title: 'title',
        color: null,
        type: null,
        size: {
          height: null,
          width: null,
        },
        configuration: {
          items: [],
          visible: true,
          backgroundColor: '',
          volumeLabel: TPIConfigurationVolumeLabelValues.D,
        },
      },
    },
  },
  deviceSettings: {
    JF2LocalFont: 1,
    bufSize: 1,
    charsInterval: 1,
    charsPerLine: 1,
    connectionType: 'string',
    connectionTime: 'string',
    displayLines: 1,
    height: 1,
    ip: 'string',
    isJF2Local: true,
    linesInterval: 1,
    meteo_station_id: 1,
    pixelFormat: 'string',
    port: 1,
    protocol: 'string',
    receivedTime: 1,
    sendTime: 1,
    sim: 'string',
    type: VMSTemplatesTypes.Message,
    width: 1,
  },
};

export const other = {};
