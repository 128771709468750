import React, { PropsWithChildren, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Collapse, ListItem, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface Props extends I18N.TranslationFunction<'sidebar'> {
  sidebarConfigItem: StaticConfigs.SidebarRootItem;
}

const SideBarListRootItem = ({ sidebarConfigItem, children, t }: PropsWithChildren<Props>) => {
  const styles = useStyles();

  const [open, setOpen] = useState<{ [key: StaticConfigs.Sidebar['key']]: boolean }>({});

  const handleClick = (name: StaticConfigs.Sidebar['key']): void => {
    if (!open[name]) {
      setTimeout(() => {
        const target = document.getElementById(sidebarConfigItem.children[sidebarConfigItem.children.length - 1].key);
        const sidebar = document.getElementById('sidebar');
        if (sidebar?.clientHeight < target?.offsetTop) {
          sidebar.scroll({
            top: target.offsetTop,
            behavior: 'smooth',
          });
        }
      }, 200);
    }

    setOpen(prevState => ({
      [name]: !prevState[name],
    }));
  };

  return (
    <>
      <ListItem
        id={sidebarConfigItem.key.toString()}
        key={`${sidebarConfigItem.key.toString()}:item`}
        data-testid={sidebarConfigItem?.dataTestId}
        onClick={() => handleClick(sidebarConfigItem.key)}
      >
        <ListItemText primary={t(sidebarConfigItem.label)} />
        {open[sidebarConfigItem.key] ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse key={`${sidebarConfigItem.key.toString()}:collapse`} in={open[sidebarConfigItem.key]} timeout="auto" unmountOnExit>
        <div className={styles.collapse}>{children}</div>
      </Collapse>
    </>
  );
};

const useStyles = makeStyles(() => ({
  collapse: {
    padding: '0 16px;',
  },
}));

export default withTranslation('sidebar')(SideBarListRootItem);
