import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormikContext } from 'formik';
import Countdown from '@app/modules/auth-module/components/common/Countdown/Countdown';
import { passwordRecoveryStepOne } from '@store/thunks/auth/auth-thunks';
import { PASSWORD_RECOVERY_COUNTDOWN_VALUE } from '@app/core/constants/auth';
import { CSDAlert } from '@app/modules/kit-module/shared/ui';
import { useAppDispatch, useLocalStorage, useAppSelector } from '@app/v2/shared/hooks';
import { PasswordRecoveryStatuses, PublicPath } from '@app/v2/shared/enums';

const INITIAL_DEADLINE = 60 * 1000;

const InProgressActions = ({ t }: I18N.TranslationFunction<'auth', 'passwordRecovery'>) => {
  const classes = useStyles();
  const { push: redirect } = useHistory();
  const dispatch = useAppDispatch();
  const { values } = useFormikContext<Auth.PasswordRecoveryStepOneParams>();
  const passwordRecoveryStatus = useAppSelector(state => state.auth.passwordRecoveryStatus);
  const { getItem } = useLocalStorage();
  const currentCountdownValue = useMemo(() => JSON.parse(getItem(PASSWORD_RECOVERY_COUNTDOWN_VALUE)) * 1000, [getItem]);

  const [isTimeNotOver, setIsTimeNotOver] = useState<boolean>(true);
  const [deadline, setDeadline] = useState<number>(INITIAL_DEADLINE);
  const [isInitial, setIsInitial] = useState<boolean>(true);

  useEffect(() => {
    if (currentCountdownValue && isInitial && passwordRecoveryStatus === PasswordRecoveryStatuses.REQUEST) {
      setDeadline(currentCountdownValue);
      setIsInitial(false);
    }

    return () => {
      setIsInitial(false);
    };
  }, [currentCountdownValue, isInitial, passwordRecoveryStatus]);

  const reRequestConfirmCode = useCallback(() => {
    dispatch(passwordRecoveryStepOne({ login: values.login }));
    setIsTimeNotOver(prev => !prev);
    setDeadline(INITIAL_DEADLINE);
  }, [values.login, dispatch]);

  const redirectToAuth = useCallback(() => {
    redirect(PublicPath.Auth);
  }, [redirect]);

  return (
    <>
      <Button color="primary" fullWidth size="large" onClick={redirectToAuth}>
        {t('cardActions.toAuthPage')}
      </Button>
      {isTimeNotOver ? (
        <Stack direction="column" alignItems="center" marginTop="1.5rem">
          <Typography variant="body2">{t('cardContent.resendTimer')}</Typography>
          <Countdown
            setIsTimeNotOver={setIsTimeNotOver}
            deadline={deadline}
            setDeadline={setDeadline}
            localStorageCountdownKey={PASSWORD_RECOVERY_COUNTDOWN_VALUE}
          />
        </Stack>
      ) : (
        <Button
          className={classes.resetButton}
          fullWidth
          color="primary"
          variant="text"
          size="small"
          disabled={isTimeNotOver}
          onClick={reRequestConfirmCode}
        >
          {t('cardActions.linkResend')}
        </Button>
      )}
      <CSDAlert className={classes.alert} severity="info">
        {t('cardContent.linkSentNotification')}
      </CSDAlert>
    </>
  );
};

const useStyles = makeStyles({
  actionRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: 0,
  },
  resetButton: {
    '&.MuiButton-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '0.5rem',
      width: '100%',
      fontSize: '0.875rem',
      color: 'black',
    },
  },
  alert: {
    margin: '1.5rem 0 0 0',
    padding: '1rem',
    wordBreak: 'break-word',
  },
});

export default withTranslation('auth', { keyPrefix: 'passwordRecovery' })(InProgressActions);
