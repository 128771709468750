import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Stack } from '@mui/material';
import { useQuery } from '@apollo/client';
import useSignContext from '@app/modules/scoreboard-module/contexts/sign/useSignContext';
import ViewImageBase64 from '@app/components/view-image-base64/ViewImageBase64';
import vmsNewQueries from '@app/clients/apollo/requests/queries/vmsNew';
import { CSDSelectFastField } from '@app/modules/kit-module/shared/ui';
import { DataTestIds } from '@app/v2/shared/enums';
import { isFunction } from '@app/v2/shared/helpers';

const PATH = 'content.contents';

const TemplateSignForm = ({ contentIndex }: Scoreboards.Options) => {
  const { values, setFieldValue } = useFormikContext<Scoreboards.TemplateModel>();

  const { t } = useTranslation('scoreboard');
  const { signsSelectors } = useSignContext();

  const [fileForView, setFileForView] = useState<string>(null);

  const { data: initialFileForViewData, refetch } = useQuery(vmsNewQueries.sign, {
    skip: Boolean(fileForView) || !values.content.contents?.[contentIndex]?.signId,
    variables: {
      id: values.content.contents?.[contentIndex]?.signId,
    },
  });

  const onChangeSign = (nextSignId: string) => {
    if (!isFunction(refetch) || !nextSignId) return;

    refetch({ id: Number(nextSignId) }).then(async ({ data }) => {
      if (!data) return;

      const { signFile } = data?.vmsNew?.templates?.sign?.content ?? {};
      setFileForView(signFile);
      await setFieldValue(`${PATH}[${contentIndex}]`, { signFile, signId: nextSignId });
    });
  };

  const initialFileForView = initialFileForViewData?.vmsNew?.templates?.sign?.content?.signFile;

  return (
    <Stack direction="row" gap="1rem" width="100%" data-testid={DataTestIds.TemplateSignField}>
      <CSDSelectFastField
        path={`${PATH}[${contentIndex}].signId`}
        label={t('templates.form.sign')}
        items={signsSelectors}
        selectProps={{
          onChange: event => onChangeSign(event.target.value as string),
        }}
      />

      {(fileForView || initialFileForView) && <ViewImageBase64 height="100%" width="80px" file={fileForView || initialFileForView} />}
    </Stack>
  );
};

export default TemplateSignForm;
