import React from 'react';
import { Box, TextField, Typography, Stack, Icon } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';

type Props = {
  label?: string;
  icon?: string;
  hideLabel?: boolean;
  maxLength?: number;
} & Omit<TextFieldProps, 'variant'>;

const CSDInputStandard = ({ label, icon, hideLabel, maxLength, ...textFieldProps }: Props) => {
  return (
    <Box width="100%">
      {(!hideLabel || !!label) && (
        <Typography
          sx={{
            fontSize: '0.875rem',
            lineHeight: '0.875rem',
            color: ({ palette }) => (textFieldProps?.disabled ? palette.text.t3 : palette.text.t1),
            marginBottom: ({ spacing }) => spacing(1),
          }}
          variant="subtitle2"
        >
          {label}
        </Typography>
      )}
      <Stack direction="row" spacing={1}>
        {icon && <Icon>{icon}</Icon>}
        <TextField
          variant="standard"
          inputProps={{
            maxLength,
          }}
          {...textFieldProps}
        />
      </Stack>
    </Box>
  );
};

export default CSDInputStandard;
