import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, List, ListItem, Typography, Button, IconButton, Link, DialogContent, DialogTitle, Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { ThemeProvider } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';
import { DialogRef } from '@app/core/providers/DialogProvider';
import oldTheme from '@theme/oldTheme';
import licenseConfig from '@app/v2/shared/configs/license';

const LicenseDialog = ({ close }: DialogRef) => {
  const classes = useStyles();
  const { t } = useTranslation('dialogs', { keyPrefix: 'license' });
  const { t: constantsT } = useTranslation('constants');

  const createLicense = (items: StaticConfigs.LicenseItem[]) => {
    return (
      <List>
        {items.map(item => (
          <ListItem dense disableGutters={item.gutters} key={item.label}>
            <Box>
              <Typography variant={item.variant}>{constantsT(item.label)}</Typography>
              {item.text && item.text.length ? <Typography variant="body2">{constantsT(item.text)}</Typography> : null}
              {Object.hasOwnProperty.call(item, 'children') && item.children.length ? createLicense(item.children) : null}
            </Box>
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <ThemeProvider theme={oldTheme}>
      <Dialog open maxWidth="xl" fullWidth scroll="paper" onClose={() => close(null)}>
        <DialogTitle classes={{ root: classes.rootTitle }}>
          <Typography variant="h5">{t('title')}</Typography>
          <IconButton onClick={() => close(null)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body2">{t('message')}</Typography>
          {createLicense(licenseConfig)}
          <Typography>
            {t('devMessage')}
            <Link href="http://www.it-lex.ru/" target="_blank">
              {' it-lex.ru. '}
            </Link>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" startIcon={<CancelIcon />} onClick={() => close(null)}>
            {t('buttons.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

const useStyles = makeStyles(() => ({
  rootTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default LicenseDialog;
