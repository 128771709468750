import icons from '@app/assets/iconFont';
import RoadImage from '@app/assets/precipitation/rain.png';
import SnowImage from '@app/assets/precipitation/snow.png';
import SnowAndRainImage from '@app/assets/precipitation/snow_and_rain.png';

const precipitationType: StaticConfigs.PrecipitationType[] = [
  {
    label: 'precipitationType.withoutPrecipitation',
    iconsName: icons.notRain,
    marker: '',
    color: '',
  },
  {
    label: 'precipitationType.rain',
    iconsName: icons.rain,
    marker: RoadImage,
    color: 'rgb(163, 191, 253)',
  },
  {
    label: 'precipitationType.snow',
    iconsName: icons.snow,
    marker: SnowImage,
    color: 'rgb(70, 130, 180)',
  },
  {
    label: 'precipitationType.snowWithRain',
    iconsName: icons.snowRain,
    marker: SnowAndRainImage,
    color: 'rgb(0, 0, 205)',
  },
  {
    label: 'precipitationType.rainIce',
    iconsName: icons.rainIce,
    marker: SnowAndRainImage,
    color: 'rgb(0, 0, 205)',
  },
  {
    label: 'precipitationType.groats',
    iconsName: icons.groats,
    marker: SnowAndRainImage,
    color: 'rgb(0, 0, 205)',
  },
];

export default precipitationType;
