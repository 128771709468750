import React, { useMemo, memo } from 'react';
import { withTranslation } from 'react-i18next';
import { Stack, Button, ButtonProps, Icon } from '@mui/material';
import { styled } from '@mui/material/styles';
import icons from '@app/assets/iconFont';
import { PlayerTimeRangeActions } from '@app/v2/shared/enums';

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&.MuiButton-root': {
    minWidth: '5.125rem',
    width: 'fit-content',
    fontSize: '0.875rem',
    borderColor: theme.palette.primary.main,
    whiteSpace: 'nowrap',
  },
}));

type Props = {
  rangeType: PlayerTimeRangeActions;
  handleChangeTimesRange: (rangeType: PlayerTimeRangeActions) => void;
} & I18N.TranslationFunction<'map', 'mapCardControl.weatherRadar'>;

const DateTimeActionButtons = ({ rangeType, handleChangeTimesRange, t }: Props) => {
  const buttonsConfiguration = useMemo(
    () => [
      {
        label: t('player.control.hour'),
        type: PlayerTimeRangeActions.HOUR,
      },
      {
        label: t('player.control.twoHours'),
        type: PlayerTimeRangeActions.TWO_HOUR,
      },
      {
        label: t('player.control.today'),
        type: PlayerTimeRangeActions.DAY,
      },
    ],
    [t],
  );

  return (
    <Stack spacing={1} alignItems="center">
      {buttonsConfiguration.map(({ label, type }) => (
        <StyledButton
          key={label}
          variant={rangeType === type ? 'contained' : 'outlined'}
          onClick={() => handleChangeTimesRange(type)}
          startIcon={<Icon>{icons.time}</Icon>}
          sx={{ minWidth: '5.125rem' }}
        >
          {label}
        </StyledButton>
      ))}
    </Stack>
  );
};

export default withTranslation('map', { keyPrefix: 'mapCardControl.weatherRadar' })(memo(DateTimeActionButtons));
