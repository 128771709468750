import React, { useRef, useEffect } from 'react';
import { VirtuosoGrid, VirtuosoGridProps } from 'react-virtuoso';
import { TableContainer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import clsx from 'clsx';
import usePaginationSearch from '@app/core/source/hooks/pagination/usePaginationSearch';
import { LISTING_DEFAULT_PAGE_NUMBER } from '@app/core/constants';

interface Props<T> extends VirtuosoGridProps<T> {
  data: T[];
  render: (index: number, data: T) => JSX.Element;
}

const DataGrid = <T,>({ data, render, itemClassName, listClassName, ...viruosoGridProps }: Props<T>) => {
  const [pagination] = usePaginationSearch();
  const previousPaginationPageRef = useRef<number>(0);
  const gridRef = useRef(null);

  const classes = useStyles();

  useEffect(() => {
    if (gridRef?.current && pagination.page === LISTING_DEFAULT_PAGE_NUMBER && previousPaginationPageRef.current !== pagination.page) {
      gridRef.current.scrollTo(0, 0);
    }
    previousPaginationPageRef.current = pagination.page;
  }, [gridRef, pagination]);

  return (
    <VirtuosoGrid
      style={{ height: 400, width: '100%' }}
      totalCount={data.length}
      data={data}
      components={{ Scroller: TableContainer }}
      itemClassName={clsx(classes.itemContainer, itemClassName)}
      listClassName={clsx(classes.listContainer, listClassName)}
      itemContent={render}
      scrollerRef={scrollerRef => {
        gridRef.current = scrollerRef;
      }}
      {...viruosoGridProps}
    />
  );
};

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  itemContainer: {
    padding: '0.5rem',
    width: '33%',
    display: 'flex',
    flex: 'none',
    alignContent: 'stretch',
    boxSizing: 'border-box',
    [breakpoints.down('md')]: {
      width: '50%',
    },
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  listContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'start',
  },
}));

export default DataGrid;
