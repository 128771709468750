import { useTranslation } from 'react-i18next';
import { TrafficChartType } from '@app/core/types/charts/traffic';
import { prepareSeriesParameters, prepareXAxisParameters, prepareYAxisParameters } from './chartOptionsPreparationFns';

interface Parameters {
  data: Traffic.ChartDirections;
}

export default function useTrafficChartDirectionsOptions({ data }: Parameters) {
  const { t: chartsT } = useTranslation('charts');
  const { t: parametersT } = useTranslation('parameters');
  const { t: commonT } = useTranslation('common');

  return {
    accessibility: {
      enabled: false,
    },
    chart: {
      type: TrafficChartType.Column,
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: chartsT('traffic.trafficByLines'),
      margin: 16,
      style: {
        color: 'black',
        fontSize: '20px',
        fontWeight: '700',
      },
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        borderRadius: 2,
        dataLabels: {
          enabled: true,
          color: 'black',
        },
      },
    },
    yAxis: prepareYAxisParameters(),
    xAxis: prepareXAxisParameters({ data, parametersT }),
    series: prepareSeriesParameters({ data, parametersT, commonT }),
  };
}
