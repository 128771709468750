import React from 'react';
import { LayersControl as LayersControlReact } from 'react-leaflet';
import { useAppSelector } from '@app/v2/shared/hooks';
import YandexTileLayer from '../yandex-tile-layer/YandexTileLayer';
import LayerTypes from '../../../enums/LayerTypes';

const LayersControl = () => {
  const activeLayer = useAppSelector(({ map }) => map.activeLayer);

  return (
    <>
      <LayersControlReact position="topright">
        <LayersControlReact.BaseLayer name="Yandex" checked={activeLayer === LayerTypes.YANDEX_MAP}>
          <YandexTileLayer type="map" />
        </LayersControlReact.BaseLayer>

        <LayersControlReact.BaseLayer name="Yandex satellite" checked={activeLayer === LayerTypes.YANDEX_SATELLITE}>
          <YandexTileLayer type="satellite" />
        </LayersControlReact.BaseLayer>

        <LayersControlReact.BaseLayer name="Yandex hybrid" checked={activeLayer === LayerTypes.YANDEX_HYBRID}>
          <YandexTileLayer type="hybrid" />
        </LayersControlReact.BaseLayer>

        <LayersControlReact.BaseLayer name="Yandex Traffic" checked={activeLayer === LayerTypes.YANDEX_TRAFFIC}>
          <YandexTileLayer type="map" traffic />
        </LayersControlReact.BaseLayer>
      </LayersControlReact>
    </>
  );
};

export default LayersControl;
