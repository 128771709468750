import { KeyPrefix } from 'react-i18next';

const settings: { [key: string]: KeyPrefix<'notification'> } = {
  raFrost: 'subscriptionParameters.raFrost',
  raZeroCrossingToMinus: 'subscriptionParameters.raZeroCrossingToMinus',
  faZeroCrossingToMinus: 'subscriptionParameters.faZeroCrossingToMinus',
  raQuickCooling: 'subscriptionParameters.raQuickCooling',
  raZeroCrossingToPlus: 'subscriptionParameters.raZeroCrossingToPlus',
  faZeroCrossingToPlus: 'subscriptionParameters.faZeroCrossingToPlus',
  raSevereWind: 'subscriptionParameters.raSevereWind',
  raZeroCrossCooling: 'subscriptionParameters.raZeroCrossCooling',
  faQuickCooling: 'subscriptionParameters.faQuickCooling',
  faLongSnow: 'subscriptionParameters.faLongSnow',
  faIceCrustedGround: 'subscriptionParameters.faIceCrustedGround',
  faStrongWind: 'subscriptionParameters.faStrongWind',
  raBlackIce: 'subscriptionParameters.raBlackIce',
  faZeroCrossCooling: 'subscriptionParameters.faZeroCrossCooling',
  faPackedSnow: 'subscriptionParameters.faPackedSnow',
  faLongRain: 'subscriptionParameters.faLongRain',
  raPackedSnow: 'subscriptionParameters.raPackedSnow',
  raIceCrustedGround: 'subscriptionParameters.raIceCrustedGround',
  raExtremeWind: 'subscriptionParameters.raExtremeWind',
  raSevereCooling: 'subscriptionParameters.raSevereCooling',
  raLightLooseSnow: 'subscriptionParameters.raLightLooseSnow',
  faSleet: 'subscriptionParameters.faSleet',
  raSevereRain: 'subscriptionParameters.raSevereRain',
  faLongRainWithSnow: 'subscriptionParameters.faLongRainWithSnow',
  faLightLooseSnow: 'subscriptionParameters.faLightLooseSnow',
  raSleet: 'subscriptionParameters.raSleet',
  raSevereRainWithSnow: 'subscriptionParameters.raSevereRainWithSnow',
  faExtremeRain: 'subscriptionParameters.faExtremeRain',
  raExtremeRain: 'subscriptionParameters.raExtremeRain',
  raExtremeRainWithSnow: 'subscriptionParameters.raExtremeRainWithSnow',
  faExtremeWind: 'subscriptionParameters.faExtremeWind',
  raSevereWarming: 'subscriptionParameters.raSevereWarming',
  raExtremeWarming: 'subscriptionParameters.raExtremeWarming',
  raExtremeCooling: 'subscriptionParameters.raExtremeCooling',
  faHeavyLooseSnow: 'subscriptionParameters.faHeavyLooseSnow',
  raHeavyLooseSnow: 'subscriptionParameters.raHeavyLooseSnow',
  faExtremeLowTemp: 'subscriptionParameters.faExtremeLowTemp',
  faExtremeHighTemp: 'subscriptionParameters.faExtremeHighTemp',
  raExtremeSnow: 'subscriptionParameters.raExtremeSnow',
  faExtremeRainWithSnow: 'subscriptionParameters.faExtremeRainWithSnow',
  faExtremeSnow: 'subscriptionParameters.faExtremeSnow',
  winterSlipperiness: 'subscriptionParameters.winterSlipperiness',
  wind: 'subscriptionParameters.wind',
  temperatureChange: 'subscriptionParameters.temperatureChange',
  solidAndMixedPrecipitation: 'subscriptionParameters.solidAndMixedPrecipitation',
  liquidPrecipitation: 'subscriptionParameters.liquidPrecipitation',
  winterSlipperiness2Days: 'subscriptionParameters.winterSlipperiness2Days',
};

export default settings;
