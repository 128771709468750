import React, { ReactNode } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { makeStyles } from '@mui/styles';

type Props = {
  label?: ReactNode;
  maxLength?: number;
} & TextFieldProps;

const CSDInput = ({ label, maxLength, ...textFieldProps }: Props) => {
  const classes = useStyles();

  return (
    <Box id={textFieldProps?.id} style={{ width: textFieldProps.fullWidth ? '100%' : undefined }}>
      {label && (
        <Typography
          id={textFieldProps?.id}
          sx={{
            fontSize: '0.875rem',
            color: ({ palette }) => textFieldProps?.disabled && palette.text.t3,
            marginBottom: ({ spacing }) => spacing(1),
          }}
          variant="subtitle2"
        >
          {label}
        </Typography>
      )}
      <TextField
        className={classes.input}
        inputProps={{
          maxLength,
        }}
        {...textFieldProps}
      />
    </Box>
  );
};

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});

export default CSDInput;
