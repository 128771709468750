import { MINIMUM_ASPECT_RATIO, LAYOUT_FLEX_GAP } from '../constants';

const calculatePartsSizes = ({
  containerWidth,
  containerHeight,
  snapShotsPartHeight,
  imgRatio,
}: {
  containerWidth: number;
  containerHeight: number;
  snapShotsPartHeight: number;
  imgRatio: number;
}): {
  snapShotsPanelWidth: number;
  mainSnapShotHeight: number;
  camerasBlockHeight: number;
} => {
  const isRatioLessThenMinimum = imgRatio <= MINIMUM_ASPECT_RATIO;
  const heightBlockOverSpanShotsPanel = containerHeight - snapShotsPartHeight - LAYOUT_FLEX_GAP;
  return {
    snapShotsPanelWidth: isRatioLessThenMinimum ? containerWidth - containerHeight * imgRatio - LAYOUT_FLEX_GAP : containerWidth,
    mainSnapShotHeight: isRatioLessThenMinimum ? containerHeight : heightBlockOverSpanShotsPanel,
    camerasBlockHeight: heightBlockOverSpanShotsPanel,
  };
};

export default calculatePartsSizes;
