import React, { FC, MouseEvent, useCallback, useEffect, useRef } from 'react';
import { ButtonProps } from '@mui/material';
import { showError } from '@app/core/utils/notifications';
import ErrorMessages from '@app/core/constants/errorsMessages';

interface Props {
  imageUrl: string;
  AnchorNode: FC<
    ButtonProps & {
      handleClick: (event?: MouseEvent<HTMLElement>) => void;
      loading?: boolean;
    }
  >;
}

const CSDDownloadVideoSnapshot = ({ AnchorNode, imageUrl }: Props) => {
  const linkHref = useRef<string | null>(null);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(linkHref.current);
      linkHref.current = null;
    };
  }, []);

  const handleDownload = useCallback(
    async (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();

      const link = document.createElement('a');

      try {
        const res = await fetch(imageUrl, { cache: 'no-cache' });
        const imageBlob = await res.blob();

        link.href = URL.createObjectURL(imageBlob);
        linkHref.current = link.href;

        link.download = `snapshot_${Date.now()}.jpg`;
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      } catch (_) {
        showError(ErrorMessages.FILE_NOT_DOWNLOADED);
      }
    },

    [imageUrl],
  );

  return <AnchorNode handleClick={handleDownload} />;
};

export default CSDDownloadVideoSnapshot;
