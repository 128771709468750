export const checkboxesSize = `<CSDCheckbox />
<CSDCheckbox size="small" />`;

export const disabledCheckboxes = `<CSDCheckbox disabled />
<CSDCheckbox size="small" disabled />`;

export const checkboxesWithLabel = `<CSDCheckbox label="Label" />
<CSDCheckbox size="small" label="Label" />`;

export const disabledCheckboxesWithLabel = `<CSDCheckbox label="Label" disabled />
<CSDCheckbox size="small" label="Label" disabled />`;
