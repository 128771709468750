import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Divider } from '@mui/material';
import moment from 'moment';
import { CSDCheckbox } from '@app/modules/kit-module/shared/ui';
import { FiltersKeys } from '@app/v2/shared/enums';
import FiltersDates from '../dates/FiltersDates';

const AdditionalFilters = ({ filters, onChange }: Filters.AdditionalFiltersProps) => {
  const { isFullData, isLocator } = filters;
  const { t } = useTranslation('filters');

  return (
    <Box sx={{ display: 'flex', gap: '1.5rem' }}>
      <FiltersDates filters={filters} onChange={onChange} initialDateTo={moment().format()} initialDateFrom={moment().subtract(1, 'day').format()} />

      <Divider flexItem orientation="vertical" />

      <Stack>
        <CSDCheckbox
          size="small"
          checked={isFullData}
          label={t('additional.hideOutdatedData')}
          onChange={(_, checked) => onChange(FiltersKeys.IsFullData, checked)}
        />

        <CSDCheckbox
          size="small"
          checked={isLocator}
          label={t('additional.showDataWithoutLocators')}
          onChange={(_, checked) => onChange(FiltersKeys.IsLocator, checked)}
        />
      </Stack>
    </Box>
  );
};

export default AdditionalFilters;
