import React, { memo, useCallback, useState } from 'react';
import { ArrayHelpers, FieldArray, Form, useFormikContext } from 'formik';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import { Box } from '@mui/material';
import { useQuery } from '@apollo/client';
import { getScrollStyles } from '@theme/themeSettings/componentSettings';
import vmsNewQueries from '@app/clients/apollo/requests/queries/vmsNew';
import { showErrorWithoutCodes } from '@app/core/utils/notifications';
import ErrorMessages from '@app/core/constants/errorsMessages';
import { useCheckAccess } from '@app/v2/shared/hooks';
import { UsersAccess } from '@app/v2/shared/enums';
import PlaylistsMainFormActions from './PlaylistFormActions/PlaylistsMainFormActions';
import PlaylistsFormWithActionPanel from './PlaylistsFormWithActions/PlaylistsFormWithActionPanel';
import CommonPlaylistForm from './CommonForm';
import { PLAYLIST_ITEMS } from '../../common/constants';
import useVMSSelectors from '../../common/hooks/useVMSSelectors';

const PlaylistsMainForm = () => {
  const classes = useStyles();

  const { values, setFieldValue, getFieldProps } = useFormikContext<Scoreboards.PlaylistModel & { deviceViewSize: Common.Size }>();

  const [lastConfigId, setLastConfigId] = useState<number>(values.playlist.at(-1)?.template?.configuration.id || null);

  const { refetch: fetchTemplateContent } = useQuery<Scoreboards.TemplateResponse>(vmsNewQueries.template, { skip: true });

  const { accessChecker } = useCheckAccess();

  const { organizationSelectors, fetchTemplatesSelectors, configurationSelectors } = useVMSSelectors(values.deviceViewSize);

  const handleFetchContent = useCallback(
    (path: string, templateId: string) =>
      fetchTemplateContent({ id: templateId })
        .then(({ data }) => {
          const { id, title, content } = data?.vmsNew?.template ?? {};

          return {
            id: templateId,
            templateContent: {
              id,
              title,
              contents: content?.contents ?? [],
              configuration: content?.configuration,
            },
          };
        })
        .then(async ({ id, templateContent }) => {
          await setFieldValue(`${path}.id`, id);
          await setFieldValue(`${path}`, templateContent);
        })
        .catch(() => showErrorWithoutCodes(ErrorMessages.COMMON_ERROR_MESSAGE)),
    [fetchTemplateContent, setFieldValue],
  );

  const handleChangeConfiguration = useCallback(
    async (path: string, value: string) => {
      await setFieldValue(`${path}.configuration.id`, value);
      await setFieldValue(`${path}.id`, null);
      await setFieldValue(`${path}.contents`, null);
      await setLastConfigId(Number(value));
    },
    [setFieldValue],
  );

  const handleRemove = useCallback(
    (id: number, removeCallback: ArrayHelpers['remove']) => {
      setLastConfigId(values.playlist[id].template.configuration.id);
      removeCallback(id);
    },
    [values.playlist],
  );

  return (
    <Form className={classes.form}>
      <CommonPlaylistForm organizationSelectors={organizationSelectors} />

      <FieldArray name={PLAYLIST_ITEMS}>
        {({ push, remove, name, move }) => (
          <Box className={classes.itemsWrapper}>
            {values.playlist
              .map((item, itemIndex) => ({ ...item, id: itemIndex }))
              .map(({ id }) => (
                <PlaylistsFormWithActionPanel
                  id={id}
                  key={id}
                  path={`${name}[${id}]`}
                  onRemove={() => handleRemove(id, remove)}
                  onSwapToTop={() => move(id, id - 1)}
                  fieldsLength={values.playlist.length}
                  onSwapToBottom={() => move(id, id + 1)}
                  isOnePlaylistItem={values.playlist.length <= 1}
                  template={getFieldProps(`${name}[${id}].template`)?.value}
                  isDisabledConfigurationChange={!accessChecker([UsersAccess.VMS_FULL_EDIT])}
                  selectedConfigurationId={getFieldProps(`${name}[${id}].template.configuration.id`)?.value}
                  fetchTemplateContent={templateId => handleFetchContent(`${name}[${id}].template`, templateId)}
                  onChangeConfiguration={value => handleChangeConfiguration(`${name}[${id}].template`, value)}
                  configurationSelectors={configurationSelectors}
                  fetchTemplateSelectors={fetchTemplatesSelectors}
                />
              ))}

            <PlaylistsMainFormActions push={push} playlist={getFieldProps(name).value} lastConfigId={lastConfigId} />
          </Box>
        )}
      </FieldArray>
    </Form>
  );
};

const useStyles = makeStyles<Theme>(({ palette }) => ({
  form: {
    gap: '1rem',
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
    marginBottom: '1rem',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 12rem)',
  },
  itemsWrapper: {
    gap: '2rem',
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    paddingRight: '0.5rem',
    flexDirection: 'column',
    paddingBottom: '0.5rem',
    ...getScrollStyles(palette.mode, palette.text),
  },
}));

export default memo(PlaylistsMainForm);
