export default function messageAlignment(maxStrLength: number) {
  const setAlignRight = (messageRow: string[]) => {
    return messageRow.length < maxStrLength ? messageRow.join('').padStart(maxStrLength, ' ').split('') : messageRow;
  };

  const setAlignLeft = (messageRow: string[]) => {
    return messageRow.length < maxStrLength ? messageRow.join('').padEnd(maxStrLength, ' ').split('') : messageRow;
  };

  const setAlignCenter = (messageRow: string[]) => {
    const amountSpaces = Math.ceil((maxStrLength - messageRow?.length) / 2);
    const addedArray: string[] = new Array(Math.max(amountSpaces || 0, 0)).fill(' ');
    return messageRow?.length < maxStrLength ? [...addedArray, ...messageRow, ...addedArray] : messageRow;
  };

  const alignSettersMapper: { [key: string]: (messageRow: string[]) => string[] } = {
    center: setAlignCenter,
    right: setAlignRight,
    left: setAlignLeft,
  };

  return (text: string[], alignType: string = 'center') => alignSettersMapper[alignType](text);
}
