export enum MessageType {
  Alert = 'alert',
  Bulletin = 'bulletin',
  Accident = 'accident',
}

export enum Bulletins {
  RGMForecastFirst = 1,
  RGMForecastSecond = 2,
  RGMNotice = 3,
  RGMForecastFourth = 4,
}
