import client from '@app/clients/apollo/client';
import { showApolloErrors } from '@app/core/utils/notifications';
import locatorQueries from '@app/clients/apollo/requests/queries/locators';

type LocatorsResponse = { locators: WeatherLocators.Locator[]; loading: boolean };
type LocatorsTimesResponse = { locators: WeatherLocators.LocatorTimes[]; loading: boolean };
type LocatorsVariables = { locatorIds?: number[]; date?: string; lastLoadTimeIds?: string[] };

const fetchLocators = async ({ locatorIds, date }: LocatorsVariables = {}): Promise<LocatorsResponse> => {
  const { data, errors, loading } = await client.query<LocatorsResponse, LocatorsVariables>({
    query: locatorQueries.locators,
    variables: {
      locatorIds,
      date,
    },
  });

  if (errors?.length) {
    showApolloErrors(errors);
  }
  const result = data?.locators || [];

  return { locators: result, loading };
};

export const fetchLocatorsTimesUpdate = async ({
  locatorIds,
  lastLoadTimeIds,
}: Pick<LocatorsVariables, 'locatorIds' | 'lastLoadTimeIds'> = {}): Promise<LocatorsTimesResponse> => {
  const { data, errors, loading } = await client.query<LocatorsTimesResponse, LocatorsVariables>({
    query: locatorQueries.locatorsTimesUpdate,
    variables: {
      locatorIds,
      lastLoadTimeIds,
    },
  });

  if (errors?.length) {
    showApolloErrors(errors);
  }
  const result = data?.locators || [];

  return { locators: result, loading };
};

export default fetchLocators;
