import React, { PropsWithChildren, useEffect, useState } from 'react';
import { UsersAccess } from '@app/v2/shared/enums';
import { useCheckAccess } from '@app/v2/shared/hooks';
import NotFound from '@app/v2/shared/ui/layout/notFound/NotFound';

type Props = {
  scopes: UsersAccess[];
};

const PermissionGate = ({ scopes, children }: PropsWithChildren<Props>) => {
  const [isAccessState, setIsAccessState] = useState<boolean>(true);
  const { accessChecker } = useCheckAccess();

  useEffect(() => {
    const isAccess = scopes.some(scope => accessChecker([scope]));
    setIsAccessState(isAccess);
  }, [accessChecker, scopes]);

  return isAccessState ? children : <NotFound />;
};

export default PermissionGate;
