import FETCH_VIDEO_STATIONS from './fetchVideoStations.graphql';
import FETCH_MISSING_VIDEO_STATION from './fetchMissingVideoStation.graphql';
import FETCH_VIDEO_MANAGEMENT from './fetchVideoManagement.graphql';
import FETCH_VIDEO_WALL from './fetchVideoWall.graphql';
import FETCH_WIPED_IMAGE from './fetchWipedImage.graphql';
import FETCH_VIDEO_STATION from './fetchVideoStation.graphql';
import FETCH_CURRENT_VIDEO_STATION_SNAPSHOT from './fetchCurrentVideoStationSnapshot.graphql';
import FETCH_VIDEO_STREAM_URI from './fetchVideoStreamUri.graphql';
import FETCH_VIDEO_STATION_DETAILS from './fetchVideoStationDetails.graphql';
import FETCH_VIDEO_STATION_SNAPSHOTS from './fetchVideoStationSnapshots.graphql';
import FETCH_VIDEO_STATIONS_PLACE_AND_ROAD_DATA from './fetchVideoStationsPlaceAndRoadData.graphql';

const videoQueries = {
  videoStations: FETCH_VIDEO_STATIONS,
  missingVideoStation: FETCH_MISSING_VIDEO_STATION,
  videoManagement: FETCH_VIDEO_MANAGEMENT,
  videoStreamUri: FETCH_VIDEO_STREAM_URI,
  videoWall: FETCH_VIDEO_WALL,
  wipedImage: FETCH_WIPED_IMAGE,
  videoStation: FETCH_VIDEO_STATION,
  currentVideoStationSnapshot: FETCH_CURRENT_VIDEO_STATION_SNAPSHOT,
  videoStationDetails: FETCH_VIDEO_STATION_DETAILS,
  videoStationSnapshots: FETCH_VIDEO_STATION_SNAPSHOTS,
  videoStationsPlaceAndRoadData: FETCH_VIDEO_STATIONS_PLACE_AND_ROAD_DATA,
};

export default videoQueries;
