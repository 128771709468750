import { Chart, ChartOptions } from 'highcharts';
import { drawChartFakeHeader, drawYAxisBackground, getChartHeight, getMinHeightOfScrollableArea } from '../../helpers';
import { yAxisWidth } from '../../constants/planChart';

export default function prepareChart(amountPlaces: number, chartHeight: number, chartHeaderId?: string, isRoadChart: boolean = true): ChartOptions {
  let allowChartUpdate: boolean = true;

  return {
    height: isRoadChart ? getChartHeight(amountPlaces, chartHeight) : null,
    spacingBottom: 1,
    animation: false,
    scrollablePlotArea: {
      opacity: 0,
      minHeight: isRoadChart ? getMinHeightOfScrollableArea(amountPlaces) : null,
    },
    marginLeft: yAxisWidth,
    events: {
      render(this: Chart) {
        if (allowChartUpdate) {
          allowChartUpdate = false;
          const amountRows = Object.keys(this.yAxis[0].ticks).map(key => key).length;

          this.update({
            chart: {
              height: isRoadChart ? getChartHeight(amountRows, chartHeight) : null,
              scrollablePlotArea: {
                minHeight: isRoadChart ? getMinHeightOfScrollableArea(amountRows) : null,
              },
            },
          });
          allowChartUpdate = true;
        }
      },
      redraw(this: Chart, { target }) {
        drawYAxisBackground(this);
        if (isRoadChart) {
          drawChartFakeHeader(target.plotSizeX, chartHeaderId);
        }
      },
    },
  };
}
