import React from 'react';
import { Card, CardContent, CardHeader, CardActions, Stack, Button } from '@mui/material';
import { CSDAlert, CSDInput } from '@app/modules/kit-module/shared/ui';
import { cardWithInputs, simpleCard } from './example';
import CSDKitDocBlock from '../../../shared/ui/CSDKitDocBlock/CSDKitDocBlock';

const CSDCards = () => {
  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="Simple card" codeExample={simpleCard}>
        <Card>
          <CardHeader title="Title" />
          <CardContent>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquam at aut commodi, consectetur cum dignissimos dolore dolores
            dolorum, eaque eius esse est, eveniet ex explicabo labore minus quo sunt?
          </CardContent>
          <CardActions>
            <Button>ОК</Button>
          </CardActions>
        </Card>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Card with alert" codeExample={simpleCard}>
        <Card>
          <CardHeader title="Title" />
          <CSDAlert severity="success" contentAlign="center">
            Успех
          </CSDAlert>
          <CardContent>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquam at aut commodi, consectetur cum dignissimos dolore dolores
            dolorum, eaque eius esse est, eveniet ex explicabo labore minus quo sunt?
          </CardContent>
          <CardActions>
            <Button>ОК</Button>
          </CardActions>
        </Card>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Card with inputs" codeExample={cardWithInputs}>
        <Card>
          <CardHeader title="Title" />
          <CardContent>
            <CSDInput label="Lorem ipsum" />
            <CSDInput label="Lorem ipsum" />
          </CardContent>
          <CardActions>
            <Button>ОК</Button>
          </CardActions>
        </Card>
      </CSDKitDocBlock>
    </Stack>
  );
};

export default CSDCards;
