import { Icon, Typography } from '@mui/material';
import icons from '@app/assets/iconFont';
import { VMSTemplatesTypes } from '@app/v2/shared/enums';
import React from 'react';

const valueMapper = {
  isJF2Local: (value: boolean) => (value ? <Icon>{icons.done}</Icon> : <Icon>{icons.close}</Icon>),
  type: (value: VMSTemplatesTypes) => {
    const templatesTypes = {
      [VMSTemplatesTypes.Sign]: <Icon>{icons.zpi}</Icon>,
      [VMSTemplatesTypes.Message]: <Icon>{icons.tpi}</Icon>,
      [VMSTemplatesTypes.Azpi]: <Typography variant="body1">AZPI</Typography>,
    };

    return templatesTypes[value];
  },
};

export default valueMapper;
