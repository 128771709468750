export enum MeteoChartContainerWidth {
  Full = '100%',
  Half = 'calc(50% - 0.5rem)',
}

export enum MeteoChartProfileType {
  Default = 'default',
  Custom = 'custom',
}

export enum MeteoChartViewTypes {
  History = 'history',
  HistoryAndForecast = 'historyAndForecast',
  Forecast = 'forecast',
}
