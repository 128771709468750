import { createContext } from 'react';
import { ProfilesTypes } from '@app/v2/shared/enums';

const ProfileContext = createContext<{
  type: ProfilesTypes;
  profiles: Profiles.CommonProfile[];
  activeProfile: Profiles.CommonProfile;
  saveProfiles: (value: Profiles.CommonProfile[]) => Promise<void>;
  updateActiveProfile: (value: Profiles.CommonProfile) => void;
  toggleProfileSettings;
  currentProfileSettings: string[];
  loading: boolean;
  error: string | null;
  isSaveProfileMode: boolean;
  setIsSaveProfileMode: (nextValue: boolean) => void;
  setError: (message: string | null) => void;
}>({
  type: null,
  error: null,
  profiles: [],
  saveProfiles: null,
  activeProfile: null,
  updateActiveProfile: null,
  toggleProfileSettings: null,
  currentProfileSettings: [],
  loading: false,
  isSaveProfileMode: false,
  setIsSaveProfileMode: () => {},
  setError: () => {},
});

export default ProfileContext;
