import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Box, Slider, Typography, Stack } from '@mui/material';
import { MIN_MAP_ZOOM, MAX_MAP_ZOOM } from '@app/core/constants/map';
import useMapProfileContext from '@app/v2/features/mapProfiles/hooks/useMapProfileContext';
import { isNumber } from '@app/v2/shared/helpers';
import isLightTheme from '@app/core/helpers/isLightTheme';

const marks = [
  { label: MIN_MAP_ZOOM, value: MIN_MAP_ZOOM },
  { label: MAX_MAP_ZOOM, value: MAX_MAP_ZOOM },
];

const StyledSlider = styled(Slider)(({ theme }) => ({
  height: 5,
  padding: '0.938rem 0',
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    top: '50%',
    backgroundColor: '#fff',
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: 'rgba(7, 47, 156, 0.16) 0 0 0 0.25rem',
    },
    '&.Mui-active': {
      boxShadow: 'rgba(7, 47, 156, 0.16) 0 0 0 0.5rem',
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: 44.5,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&::before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: isLightTheme(theme.palette.mode) ? '#000' : '#fff',
    },
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiSlider-mark': {
    background: 'transparent',
  },
}));

export const calculateRange = (start: number, end: number, hasPreviousRange: boolean = false): string => {
  const startValue = hasPreviousRange ? start + 1 : start;
  if (startValue === end) {
    return `${startValue}`;
  }
  return `${startValue} - ${end}`;
};

// minRange - [MIN_MAP_ZOOM, firstLimit], middleRange - (firstLimit, secondLimit], maxRange - (secondLimit, MAX_MAP_ZOOM]
export const getZoomRanges = (firstLimit: number, secondLimit: number) => {
  if (!isNumber(firstLimit) || !isNumber(secondLimit)) return {};

  const minRange = firstLimit !== MIN_MAP_ZOOM ? calculateRange(MIN_MAP_ZOOM, firstLimit) : null;
  const middleRange = firstLimit !== secondLimit ? calculateRange(firstLimit, secondLimit, !!minRange) : null;
  const maxRange = secondLimit !== MAX_MAP_ZOOM ? calculateRange(secondLimit, MAX_MAP_ZOOM, !!middleRange || !!minRange) : null;

  return {
    minRange,
    middleRange,
    maxRange,
  };
};

const ZoomMarkerControl = ({ t }: I18N.TranslationFunction<'map', 'markerZooms'>) => {
  const {
    profileData: {
      markerZooms: { firstLimit, secondLimit },
    },
    handleChangeProfileMarkerZooms,
  } = useMapProfileContext();

  const handleOnChange = (event: Event, values: number | number[]) => {
    if (!Array.isArray(values)) return;
    const [firstLimitValue, secondLimitValue] = values;
    if (firstLimitValue <= secondLimitValue) handleChangeProfileMarkerZooms({ firstLimit: firstLimitValue, secondLimit: secondLimitValue });
  };

  const { minRange, middleRange, maxRange } = useMemo(() => getZoomRanges(firstLimit, secondLimit), [firstLimit, secondLimit]);

  return (
    <Box width="15rem" display="flex" flexDirection="column">
      <StyledSlider
        track={false}
        disableSwap
        valueLabelDisplay="on"
        onChange={handleOnChange}
        value={[firstLimit, secondLimit]}
        marks={marks}
        min={MIN_MAP_ZOOM}
        max={MAX_MAP_ZOOM}
      />
      <Stack spacing={1} direction="column">
        <Typography>{`${t('minimal')}: ${minRange ?? t('value.unselected')}`}</Typography>
        <Typography>{`${t('middle')}: ${middleRange ?? t('value.unselected')}`}</Typography>
        <Typography>{`${t('maximal')}: ${maxRange ?? t('value.unselected')}`}</Typography>
      </Stack>
    </Box>
  );
};

export default withTranslation('map', { keyPrefix: 'markerZooms' })(ZoomMarkerControl);
