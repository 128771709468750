import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { ButtonProps } from '@mui/material';
import videoQueries from '@app/clients/apollo/requests/queries/video';
import { isFunction, isNotNullAndUndefined } from '@app/v2/shared/helpers';
import { showError, showSuccess } from '@app/core/utils/notifications';

interface Props {
  id: number;
  disabled?: boolean;
  AnchorNode: FC<
    ButtonProps & {
      handleClick: Common.VoidCallBack;
      loading?: boolean;
    }
  >;
}

const CSDWipeVideoCamera = ({ AnchorNode, id, disabled }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation('features', { keyPrefix: 'wipeVideoCamera' });

  const { refetch } = useQuery<Video.WipedImageResponse, Video.WipedImageVariables>(videoQueries.wipedImage, { skip: true });

  const handleWipeCamera = () => {
    if (!isNotNullAndUndefined(id) || !isFunction(refetch)) return;

    setLoading(true);

    refetch({ id })
      .then(({ data }) => showSuccess(data.videoStationWiped.message ?? t('success')))
      .catch(() => showError(t('error')))
      .finally(() => setLoading(false));
  };

  return <AnchorNode handleClick={handleWipeCamera} loading={loading} disabled={disabled} />;
};

export default CSDWipeVideoCamera;
