import FETCH_FILTER_SETS from './fetchFilterSets.graphql';
import FETCH_FILTER_ENTITY_LISTS from './fetchFilterEntityLists.graphql';
import FETCH_FILTER_ROADS_ENTITY_LISTS from './fetchFilterRoadsEntityLists.graphql';

const filterQueries = {
  filterSets: FETCH_FILTER_SETS,
  filterEntities: FETCH_FILTER_ENTITY_LISTS,
  filterRoadsEntities: FETCH_FILTER_ROADS_ENTITY_LISTS,
};

export default filterQueries;
