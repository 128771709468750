import { PopoverOrigin } from '@mui/material';

export const anchorOrigin: PopoverOrigin = {
  vertical: 30,
  horizontal: 40,
};

export const transformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};
