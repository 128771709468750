import { TFunction } from 'react-i18next';
import arrowBase64 from '@app/assets/arrowBase64';
import { turnCircleInOppositeDirection } from '@app/core/constants/windDirections';
import statusDampBase64 from '@app/assets/statusDamp/statusDampBase64';
import { isNotNullAndUndefined, isNumber } from '@app/v2/shared/helpers';
import { statusDampYValue, windDirGrYValue, carsYValue } from '@app/v2/entities/CSDCharts/CSDMeteoChart/constants';
import parameters from '@app/core/constants/parameters/parameters';
import SnowPlow from '@app/assets/cars/snowplow.png';
import { PrecipitationTypeConfig } from '@app/v2/shared/configs';

interface SeriesParameters {
  content: Partial<Meteo.ChartContent>;
  dates: string[];
  parameterT: TFunction<'parameters'>;
  commonT: TFunction<'common'>;
  constantsT: TFunction<'constants'>;
  convertDataToMomentWithOffeset: Common.MomentInputCb;
  arrOfSetups: Meteo.ChartsSetupParameter[];
}

export default function prepareSeriesParameters({
  arrOfSetups,
  commonT,
  content,
  dates,
  parameterT,
  constantsT,
  convertDataToMomentWithOffeset,
}: SeriesParameters) {
  if (!content) return [];

  const chartSeries = arrOfSetups.map(setup => {
    const { label, settings } = setup;
    const {
      unitsOptions: { key },
    } = parameters[label];

    return {
      label,
      type: 'spline',
      yAxis: key,
      icon: settings.icon,
      color: settings.color,
      name: parameterT(label),
      data: content[label]?.map((dataValue, index) => ({
        y: dataValue,
        x: convertDataToMomentWithOffeset(dates[index]).valueOf(),
        marker: { enabled: false },
      })),
      tooltip: { valueSuffix: commonT(settings.unit) },
      point: {
        events: {
          click(this: any) {
            this.series.chart.userOptions.chart.events.click.call({
              ...this.series.chart,
              hoverPoint: {
                index: this.index,
                category: this.category,
              },
            });
          },
        },
      },
    };
  });

  const differentCases = {
    tDewPoint: serie => ({
      ...serie,
      data: content[serie.label].map((val, index) => ({
        y: val ? Math.ceil(val * 100) / 100 : null,
        x: serie?.data?.[index]?.x,
        marker: {
          enabled: true,
        },
      })),
    }),
    statusDamp: serie => ({
      ...serie,
      data: serie.data?.map(({ y }, index) => ({
        y: isNotNullAndUndefined(y) ? statusDampYValue : null,
        x: serie?.data?.[index]?.x,
        marker: {
          enabled: true,
          symbol: isNotNullAndUndefined(y) && `url(${statusDampBase64(y)})`,
        },
        tooltipConfig: {
          value: y,
        },
      })),
    }),
    statusDamp2: serie => ({
      ...serie,
      data: serie.data?.map(({ y }, index) => ({
        y: isNotNullAndUndefined(y) ? statusDampYValue : null,
        x: serie?.data?.[index]?.x,
        marker: {
          enabled: true,
          symbol: isNotNullAndUndefined(y) && `url(${statusDampBase64(y)})`,
        },
        tooltipConfig: {
          value: y,
        },
      })),
    }),
    windDirGr: serie => ({
      ...serie,
      data: content[serie.label]?.map((windDirGrValue, index) => {
        const isShowMarker = isNotNullAndUndefined(windDirGrValue) && content.windSpeed?.[index];

        return {
          y: isShowMarker ? windDirGrYValue : null,
          x: serie?.data?.[index]?.x,
          marker: {
            enabled: true,
            symbol: isShowMarker && `url(${arrowBase64(turnCircleInOppositeDirection(windDirGrValue))})`,
          },
          tooltipConfig: {
            value: isShowMarker ? windDirGrValue : null,
          },
        };
      }),
    }),
    windSpeed: serie => ({
      ...serie,
      data: content[serie.label]?.map((windSpeedValue, index) => {
        const isCalm = [windSpeedValue, content.windGusts?.[index]].every(parameter => parameter === 0);
        if (!isCalm)
          return {
            y: windSpeedValue,
            x: serie?.data?.[index]?.x,
            marker: { enabled: false },
          };

        return {
          y: windSpeedValue,
          x: serie?.data?.[index]?.x,
          marker: { enabled: false },
          tooltipConfig: {
            value: constantsT('windDirections.calm'),
          },
        };
      }),
    }),
    precipitationType: serie => ({
      ...serie,
      data: content?.precipitationIntensity?.map((precipitationIntensityValue, index) => {
        const precipitationTypeValueByIndex = PrecipitationTypeConfig[content.precipitationType?.[index]];

        return {
          y:
            isNotNullAndUndefined(precipitationIntensityValue) && isNumber(precipitationIntensityValue)
              ? Math.max(precipitationIntensityValue, Math.max(...content?.precipitationIntensity) / 10)
              : null,
          x: serie?.data?.[index]?.x,
          marker: {
            enabled: true,
            symbol: precipitationTypeValueByIndex ? `url(${precipitationTypeValueByIndex?.marker})` : undefined,
          },
          tooltipConfig: {
            value: content.precipitationType[index] ?? null,
          },
        };
      }),
    }),
    precipitationIntensity: serie => ({
      ...serie,
      type: 'column',
      data: content[serie.label]?.map((precipitationIntensityValue, index) => ({
        y: precipitationIntensityValue,
        x: serie?.data?.[index]?.x,
        color: PrecipitationTypeConfig[content.precipitationType?.[index]]?.color || '',
        marker: {
          enabled: true,
        },
      })),
    }),
    cars: serie => ({
      ...serie,
      data: serie.data?.map(({ y }, index) => ({
        y: isNotNullAndUndefined(y) ? carsYValue : null,
        x: serie?.data?.[index]?.x,
        marker: {
          enabled: true,
          symbol: isNotNullAndUndefined(y) && `url(${SnowPlow})`,
        },
        tooltipConfig: {
          value: y,
        },
      })),
    }),
  };

  const series = chartSeries
    .map(serie => {
      if (!differentCases[serie.label]) return serie;
      return differentCases[serie.label](serie);
    })
    .filter(item => item?.data?.some(dataItem => isNotNullAndUndefined(dataItem.y)));

  return series;
}
