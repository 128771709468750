import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import moment, { Moment } from 'moment';
import { strictlyEqual } from '@app/v2/shared/helpers';
import { ExecutionType, MeteoChartViewTypes } from '@app/v2/shared/enums';
import useActions from '../reactRedux/useActions';
import useAppSelector from '../reactRedux/useAppSelector';

interface MeteoChart {
  chartsSetup: Meteo.ChartsSetup;
  viewMode: MeteoChartViewTypes;
  dateFrom: Moment;
  dateTo: Moment;
  isForecast: boolean;
  amountsOfPoints: number;
  chartsData: Record<string, Omit<Meteo.ChartPointParameters, 'chartId'>>;
  handleChangeDates: ([nextDateFrom, nextDateTo]: [Moment, Moment]) => void;
  handleChangeDateTo: (value: Moment) => void;
  handleSetMeteoChart: (value: Pick<Meteo.ChartPointParameters, 'chartId'>) => void;
  handleSetMeteoChartParameters: (params: Meteo.ChartPointParameters) => void;
  handleChangeIsFixed: (parameters: Pick<Meteo.ChartPointParameters, 'chartId' | 'isFixed'>) => void;
  handleSetViewMode: (nextViewMode: MeteoChartViewTypes) => void;
  handleSetOrClearInitialDates: (nextValue: ExecutionType) => void;
  handleSetMeteoChartsSetup: (value: Meteo.ChartsSetup) => void;
  handleSetAmountsOfPointsMeteoChart: (nextValue: number) => void;
  handleClearMeteoCharts: Common.VoidCallBack;
}

export default function useMeteoChart(): MeteoChart {
  const dateFrom = useAppSelector(state => state.meteoChart.dateFrom);
  const dateTo = useAppSelector(state => state.meteoChart.dateTo);
  const viewMode = useAppSelector(state => state.meteoChart.viewMode);
  const chartsSetup = useAppSelector(state => state.meteoChart.chartsSetup);
  const chartsData = useAppSelector(state => state.meteoChart.chartsData, shallowEqual);
  const amountsOfPoints = useAppSelector(state => state.meteoChart.amountsOfPoints);

  const {
    setDateFrom,
    setDateTo,
    setMeteoChartInitialDates,
    setMeteoCharts,
    setMeteoChartsParameters,
    clearMeteoCharts,
    toggleIsFixed,
    setViewMode,
    setMeteoChartsSetup,
    setAmountsOfPointsMeteoChart,
  } = useActions();

  const handleChangeDateTo = useCallback(
    (value: Moment) => {
      setDateTo({ value });
    },
    [setDateTo],
  );

  const handleChangeDates = useCallback(
    ([nextDateFrom, nextDateTo]: [Moment, Moment]) => {
      setDateFrom({ value: nextDateFrom });

      const isToday = strictlyEqual<string>(nextDateTo.format('yyyyMMDD'), moment().format('yyyyMMDD'));

      setDateTo({ value: isToday ? moment() : nextDateTo.endOf('day') });

      if (!isToday) setViewMode({ value: MeteoChartViewTypes.History });
    },
    [setDateFrom, setDateTo, setViewMode],
  );

  const handleSetViewMode = useCallback(
    (nextViewMode: MeteoChartViewTypes) => {
      if (!strictlyEqual(nextViewMode, MeteoChartViewTypes.History)) setDateTo({ value: moment() });
      setViewMode({ value: nextViewMode });
    },
    [setDateTo, setViewMode],
  );

  const handleSetMeteoChart = useCallback(
    (value: Pick<Meteo.ChartPointParameters, 'chartId'>) => {
      setMeteoCharts(value);
    },
    [setMeteoCharts],
  );

  const handleSetMeteoChartParameters = useCallback(
    (params: Meteo.ChartPointParameters) => {
      setMeteoChartsParameters(params);
    },
    [setMeteoChartsParameters],
  );

  const handleClearMeteoCharts = useCallback(() => {
    clearMeteoCharts();
  }, [clearMeteoCharts]);

  const handleChangeIsFixed = useCallback(
    (parameters: Pick<Meteo.ChartPointParameters, 'chartId' | 'isFixed'>) => {
      toggleIsFixed(parameters);
    },
    [toggleIsFixed],
  );

  const handleSetOrClearInitialDates = useCallback(
    (nextValue: ExecutionType) => {
      const nextValueMapper: Record<ExecutionType, { dateFrom: Moment; dateTo: Moment }> = {
        [ExecutionType.Set]: { dateFrom: moment().add(-1, 'days'), dateTo: moment() },
        [ExecutionType.Clear]: { dateFrom: null, dateTo: null },
      };

      setMeteoChartInitialDates(nextValueMapper[nextValue]);
    },
    [setMeteoChartInitialDates],
  );

  const handleSetMeteoChartsSetup = useCallback(
    (value: Meteo.ChartsSetup) => {
      setMeteoChartsSetup(value);
    },
    [setMeteoChartsSetup],
  );

  const handleSetAmountsOfPointsMeteoChart = useCallback(
    (nextValue: number) => {
      setAmountsOfPointsMeteoChart({ amountsOfPoints: nextValue });
    },
    [setAmountsOfPointsMeteoChart],
  );

  return {
    amountsOfPoints,
    chartsSetup,
    viewMode,
    dateFrom,
    dateTo,
    isForecast: !strictlyEqual(viewMode, MeteoChartViewTypes.History),
    chartsData,
    handleChangeDateTo,
    handleChangeDates,
    handleSetMeteoChart,
    handleSetMeteoChartParameters,
    handleClearMeteoCharts,
    handleChangeIsFixed,
    handleSetViewMode,
    handleSetOrClearInitialDates,
    handleSetMeteoChartsSetup,
    handleSetAmountsOfPointsMeteoChart,
  };
}
