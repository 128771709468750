import { useCallback, useEffect, useState } from 'react';
import ProfilesDataSource from '@app/core/profiles/ProfilesDataSource';
import useDataSource from '@app/core/profiles/useDataSource';
import { ProfilesTypes } from '@app/v2/shared/enums';
import useLoading from '../useLoading';

export default function useProfiles(type: ProfilesTypes) {
  const dataSource: ProfilesDataSource = useDataSource(type);

  const [profiles, setProfiles] = useState<Profiles.CommonProfile[]>([]);
  const [loadingProfiles, setLoadingProfiles] = useState<boolean>(true);

  const [, setLoading] = useLoading();

  useEffect(() => {
    const profilesSubscriber = dataSource.profiles.subscribe(data => {
      setProfiles(data);
    });

    const loadingSubscriber = dataSource.loading.subscribe(data => {
      setLoading(data);
      setLoadingProfiles(data);
    });

    setProfiles(dataSource.profilesValue);
    setLoading(dataSource.loadingValue);

    dataSource.loadProfiles();

    return () => {
      profilesSubscriber.unsubscribe();
      loadingSubscriber.unsubscribe();
    };
  }, [dataSource, setLoading]);

  const saveProfiles = useCallback(
    (newProfiles: Profiles.CommonProfile[]) => {
      return dataSource.updateProfiles(newProfiles);
    },
    [dataSource],
  );

  return { profiles, saveProfiles, loadingProfiles };
}
