import React from 'react';
import { useMutation } from '@apollo/client';
import { CSDSwitcher } from '@app/modules/kit-module/shared/ui';
import vmsNewMutations from '@app/clients/apollo/requests/mutations/vmsNew';
import { addFeatureActions } from '@app/v2/shared/utils';

const CSDChangeTemplateStatus = ({
  id: stationId,
  is_shutdown: status,
  disabled = false,
  ...featureActions
}: Scoreboards.ChangeTemplateStatusProps) => {
  const [powerOff, { loading: powerOffLoading }] = useMutation<Common.MutationResponse, Scoreboards.ManagementVisibilityOfTemplate>(
    vmsNewMutations.management.powerOff,
  );

  const [powerOn, { loading: powerOnLoading }] = useMutation<Common.MutationResponse, Scoreboards.ManagementVisibilityOfTemplate>(
    vmsNewMutations.management.powerOn,
  );

  const handleChange = () => {
    if (!stationId || powerOffLoading || powerOnLoading) return;

    const onUpdate = status ? powerOff : powerOn;

    addFeatureActions(
      onUpdate({
        variables: {
          stationId,
        },
      }),
      featureActions,
    );
  };

  return <CSDSwitcher size="small" checked={status} onChange={handleChange} disabled={disabled || powerOffLoading || powerOnLoading} />;
};

export default CSDChangeTemplateStatus;
