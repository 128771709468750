import React, { memo, useEffect } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { Box } from '@mui/material';
import AuthForm from '@app/modules/auth-module/components/authForm/AuthForm';
import { login as loginAction } from '@store/thunks/auth/auth-thunks';
import { USER_LOGIN } from '@app/core/constants/auth';
import validationSchema from '@app/modules/auth-module/containers/login/validationSchema';
import tokenStorage from '@app/clients/apollo/tokenStorage';
import { useActions, useAppDispatch, useAppSelector, useLocalStorage, useTitle } from '@app/v2/shared/hooks';
import { PublicPath } from '@app/v2/shared/enums';
import styles from './index.module.scss';

const Layout = ({ t }: WithTranslation<'auth', 'login'>) => {
  useTitle(t('moduleTitle'));

  const dispatch = useAppDispatch();
  const { setLoaderStatus } = useActions();
  const isConfirmInProgress = useAppSelector(state => state.auth.isConfirmInProgress);

  const { setItem } = useLocalStorage();
  const { push } = useHistory();

  useEffect(() => {
    dispatch(setLoaderStatus(false));
  });

  useEffect(() => {
    if (isConfirmInProgress && tokenStorage.isExistRefreshToken) {
      push(PublicPath.Confirmation);
    }
  }, [isConfirmInProgress, push]);

  const onHandleSubmit = (authParameters: Auth.AuthParameters): void => {
    dispatch(loginAction(authParameters));
    setItem(USER_LOGIN, authParameters.login);
  };

  return (
    <Box className={styles.authWrapper}>
      <Formik<Auth.AuthParameters> initialValues={{ login: '', password: '' }} validationSchema={validationSchema} onSubmit={onHandleSubmit}>
        <Form>
          <AuthForm />
        </Form>
      </Formik>
    </Box>
  );
};

export default withTranslation('auth', { keyPrefix: 'login' })(memo(Layout));
