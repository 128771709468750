import client from '@app/clients/apollo/client';
import { Notification, Profile } from '@app/v2/features/notifications/types';
import { subscriptionsActions } from '@store/slices/subscriptions';
import { showApolloErrors, showError } from '@app/core/utils/notifications';
import ErrorMessages from '@app/core/constants/errorsMessages';
import { loaderActions } from '@app/store/slices/loader';
import { Bulletin, MeteoBulletinGroup } from '@store/slices/subscriptions/types';
import { AppDispatch } from '@app/store';
import subscriptionQueries from '@app/clients/apollo/requests/queries/subscriptions';
import subscriptionMutations from '@app/clients/apollo/requests/mutations/subscriptions';
import { StationTypes } from '@app/v2/shared/enums';

export const fetchSubscriptions = () => async (dispatch: AppDispatch) => {
  dispatch(loaderActions.setLoaderStatus(true));

  try {
    const response = await client.query({
      query: subscriptionQueries.subscriptions,
      variables: {
        organizationIds: [],
        roadIds: [],
        stationType: StationTypes.Meteo,
      },
    });

    if (response.errors?.length) {
      return showApolloErrors(response.errors);
    }

    if (response.data) {
      const {
        data: { localSubscriptions: locals, ...data },
      } = response;

      dispatch(subscriptionsActions.fetchSubscriptions({ ...data, localSubscriptions: locals }));
    }
  } catch (e) {
    showError(ErrorMessages.COMMON_ERROR_MESSAGE);
  } finally {
    dispatch(loaderActions.setLoaderStatus(false));
  }

  return false;
};

export const mutateSubscriptions = (
  globalSubscriptions: Notification[],
  localSubscriptions: Profile[],
  meteoBulletinGroups: MeteoBulletinGroup[],
) => {
  const $globalSubscriptions = globalSubscriptions.map(({ id, checked }) => ({ id, checked }));
  const $localSubscriptions = localSubscriptions.map(({ id, isActive, roadParts, places, title, notifications }) => ({
    id: id || null,
    isActive,
    roadParts,
    places,
    title,
    notifications: notifications.map(({ id: nid, alias, checked }) => ({ id: nid, alias, checked })),
  }));
  const bulletins = meteoBulletinGroups
    .map(({ bulletinElements }: MeteoBulletinGroup) => bulletinElements)
    .flat()
    .map(({ id, isChecked }: Bulletin) => ({ id, isChecked }));

  return async (dispatch: AppDispatch) => {
    const { data, errors } = await client.mutate({
      mutation: subscriptionMutations.update,
      variables: {
        globalSubscriptions: $globalSubscriptions,
        localSubscriptions: $localSubscriptions,
        bulletinElements: bulletins,
      },
    });

    if (errors?.length) {
      return showApolloErrors(errors);
    }

    if (data) {
      dispatch(subscriptionsActions.mutateSubscriptions());
      await dispatch(fetchSubscriptions());
    }

    return false;
  };
};
