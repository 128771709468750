// icon arrow size in %
export const ARROW_SIZE = 10;

export const MARKER_RATION = 0.05;

export const PADDING_RATION = 0.1;

export const DEFAULT_STATION_SIZE = 72;

export const DEFAULT_STATION_SQUARE_SIZE = 48;

export const DEFAULT_FONT_SIZE = 40;

export const TEXT_FONT_SIZE = 30;

export const LINEAR_GRADIENT_BLACK_ID = 'blackLinearGradientId';

export const LINEAR_GRADIENT_GREEN_ID = 'greenLinearGradientId';

export const LINEAR_GRADIENT_BLUE_ID = 'blueLinearGradientId';

export const IMG_WHITE_NOISE_PATTERN_ID = 'imgWhiteNoisePatternId';

export const ARROW_ELEMENT_ID = 'arrowElementId';

export const COLORS = {
  ACTIVE: '#00A151',
  EXPIRED: '#8D909A',
  BASE: `url(#${LINEAR_GRADIENT_BLUE_ID})`,
};
