import React from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { AZPIMode } from '@app/v2/shared/enums';

interface Props extends I18N.TranslationFunction<'scoreboard'> {
  mode: AZPIMode;
}

const CSDAZPILabel = ({ mode, t }: Props) => {
  const classes = useStyles({ mode });

  return (
    <Box className={classes.azpiLabel}>
      <Tooltip title={t(mode === AZPIMode.Active ? 'singsAndScoreboard.manual.tooltip.active' : 'singsAndScoreboard.manual.tooltip.deactive')}>
        <Box />
      </Tooltip>
    </Box>
  );
};

const useStyles = makeStyles<Theme, Pick<Props, 'mode'>>(({ palette }) => ({
  '@keyframes blinker': {
    '50%': {
      opacity: 0,
    },
  },

  azpiLabel: {
    gap: '0.2rem',
    top: '0.2rem',
    display: 'flex',
    right: '0.2rem',
    padding: '0.1rem',
    position: 'absolute',
    alignItems: 'center',
    borderRadius: '0.2rem',
    flexDirection: 'column',
    backgroundColor: palette.text.primary,

    '& > div': {
      width: '0.5rem',
      height: '0.5rem',
      borderRadius: '50%',
      animation: '$blinker 2s linear infinite',
      backgroundColor: ({ mode }) => (mode === AZPIMode.Active ? 'green' : 'red'),
    },
  },
}));

export default withTranslation('scoreboard')(CSDAZPILabel);
