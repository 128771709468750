import React from 'react';
import { Stack, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Moment } from 'moment';
import { CustomFormat } from 'rc-picker/es/interface';
import FormHelperText from '@mui/material/FormHelperText';
import { getScrollStyles } from '@theme/themeSettings/componentSettings';
import { strictlyEqual } from '@app/v2/shared/helpers';
import { ENTER_TOOLTIP_DELAY, DATE_FORMAT } from '@app/v2/shared/constants';
import CSDDataRangePickerRootAntd from './root/CSDDataRangePickerRootAntd';
import CSDIcons from './IconComponents';
import { RangeProps, StylesRangeProps } from './types';

const CSDDataPickerAntd = ({ label, format, tooltipTitle, labelVariant = 'h3', size, helperText, status, ...other }: RangeProps) => {
  const error = status === 'error';
  const classes = useStyles({ size });

  const customFormat: CustomFormat<Moment> = value => value.format(DATE_FORMAT.FORMAT_RU_POINT_SHORTER_DATE_AND_UNFULL_TIME);

  return (
    <Tooltip title={tooltipTitle} placement="bottom-end" enterDelay={ENTER_TOOLTIP_DELAY} hidden={!tooltipTitle}>
      <Stack className={classes.wrapper}>
        {!!label && <Typography variant={labelVariant}>{label}</Typography>}

        <CSDDataRangePickerRootAntd
          format={format || customFormat}
          className={classes.root}
          popupClassName={classes.dropdown}
          status={status}
          {...other}
          {...CSDIcons}
        />
        {error && (
          <FormHelperText style={{ margin: 0 }} error={error}>
            {error ? helperText : ''}
          </FormHelperText>
        )}
      </Stack>
    </Tooltip>
  );
};

const useStyles = makeStyles<Theme, StylesRangeProps>(({ palette: { common, primary, text, mode } }) => ({
  wrapper: {
    flexDirection: 'column',
    gap: '0.5rem',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  root: {
    border: '1px solid #3245AF',
    padding: '0 0.5rem',
    backgroundColor: 'transparent',
    borderRadius: '0.5rem',
    height: ({ size }) => (strictlyEqual<string>(size, 'large') ? '2.5rem' : '1.75rem'),

    '& .ant-picker-input': {
      height: '100%',

      '& input': {
        height: 'inherit',
        padding: '0 0 0 0.5rem',
      },

      '& input[disabled]:hover': {
        cursor: 'default',
      },

      '&.ant-picker-input-active': {
        '& input:focus::placeholder': {
          color: common.white,
          backgroundColor: '#3245AF',
        },

        '& input:focus': {
          color: common.white,
          backgroundColor: '#3245AF',
        },
      },
    },

    '& .ant-picker-active-bar': {
      display: 'none',
    },

    '& .ant-picker-range-separator': {
      display: 'block',
      height: '100%',
      width: '1px',
      backgroundColor: 'grey',
      padding: 0,

      '& svg': {
        display: 'none',
      },
    },

    '& .ant-picker-suffix': {
      order: -1,
      margin: '0 0.25rem 0 0',
    },

    '& .ant-picker-clear': {
      backgroundColor: 'transparent',
    },
  },
  dropdown: {
    '& .ant-picker-panels': {
      padding: '16px',

      '& .ant-picker-panel': {
        border: 'none',

        '& .ant-picker-datetime-panel': {
          '& .ant-picker-time-panel': {
            '& .ant-picker-content': {
              '& .ant-picker-time-panel-column': {
                ...getScrollStyles(mode, text),
              },
            },
          },
        },
        '& .ant-picker-time-panel': {
          '& .ant-picker-content': {
            '& .ant-picker-time-panel-column': {
              ...getScrollStyles(mode, text),
            },
          },
        },
      },
    },

    '& .ant-picker-footer': {
      '& .ant-picker-ranges': {
        padding: '0 1rem 1rem 1rem',

        '& .ant-picker-ok': {
          '& > button': {
            color: common.white,
            backgroundColor: primary.main,
          },

          '& > button:disabled': {
            color: text.t3,
            backgroundColor: text.t5,
          },
        },
      },
    },

    '& .ant-picker-range-wrapper': {
      border: '1px solid black',
      borderRadius: '0.5rem',
      boxShadow: '0px 5px 5px 0px rgba(52, 52, 52, 0.20)',

      '& .ant-picker-range-arrow': {
        display: 'none',
      },
    },

    '& .ant-picker-header-view': {
      fontSize: '18px',
      fontWeight: 700,
    },

    '& .ant-picker-header': {
      border: 'none',
      padding: 0,
    },

    '& .ant-picker-body': {
      padding: '0.5rem 0 !important',

      '& .ant-picker-content': {
        '& .ant-picker-cell-today': {
          color: '#3245AF',
          fontWeight: 700,
        },

        '& .ant-picker-cell.ant-picker-cell-in-view': {
          '&.ant-picker-cell-in-range': {
            color: '#3245AF',
          },

          '&.ant-picker-cell-range-start::before': {
            backgroundColor: 'rgba(50, 69, 175, 0.30)',
          },
          '&.ant-picker-cell-range-end::before': {
            backgroundColor: 'rgba(50, 69, 175, 0.30)',
          },
          '&.ant-picker-cell-in-range::before': {
            backgroundColor: 'rgba(50, 69, 175, 0.30)',
          },
        },

        '& .ant-picker-cell-today .ant-picker-cell-inner::before': {
          border: 'none',
        },
        '& thead > tr > *': {
          fontSize: '14px',
        },
      },
    },

    '& .ant-picker-panel': {
      padding: 0,

      '& .ant-picker-cell.ant-picker-cell-in-view:hover': {
        '& .ant-picker-cell-inner': {
          backgroundColor: 'rgba(50, 69, 175, 0.30) !important',
        },
      },

      '& .ant-picker-cell-in-view.ant-picker-cell-range-start': {
        '& .ant-picker-cell-inner': {
          backgroundColor: '#3245AF',
        },
      },

      '& .ant-picker-cell-in-view.ant-picker-cell-range-end': {
        '& .ant-picker-cell-inner': {
          backgroundColor: '#3245AF',
        },
      },
    },
  },
}));

export default CSDDataPickerAntd;
