import React, { memo, ReactNode, useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import { COLLAPSED_INFO_BADGE, UNCOLLAPSED_INFO_BADGE } from '@app/core/theme/constants';
import meteoQueries from '@app/clients/apollo/requests/queries/meteo';
import { useShowTime } from '@app/v2/shared/hooks';
import { ALERT_STATUS_POLL_INTERVAL, DATE_PERIOD } from '@app/v2/shared/constants';
import { isNumber } from '@app/v2/shared/helpers';
import Forecast from './Forecast';

interface Props extends I18N.TranslationFunction<'entities'> {
  isViewForecast: boolean;
  isFiltersExist: boolean;
  isHeaderRollUp: boolean;
  clock: ReactNode;
  fetchVariables: AlertIndex.Variables;
}

const Indicator = ({ isViewForecast, isFiltersExist, isHeaderRollUp, clock, fetchVariables, t }: Props) => {
  const interval = useRef(null);

  const classes = useStyles();

  const { now } = useShowTime();

  const [date, setDate] = useState<string>(now({ format: 'DD.MM' }));

  const { data } = useQuery<AlertIndex.Response, AlertIndex.Variables>(meteoQueries.alertIndexes, {
    skip: !isViewForecast || !isFiltersExist,
    variables: fetchVariables,
    fetchPolicy: 'cache-first',
    pollInterval: ALERT_STATUS_POLL_INTERVAL,
  });

  const showMeteoComplexityIndex = isViewForecast && isNumber(data?.alertIndexes.is_10m);

  useEffect(() => {
    interval.current = setInterval(() => {
      setDate(now({ format: 'DD.MM' }));
    }, DATE_PERIOD.HOUR);

    return () => clearInterval(interval.current);
  }, [now]);

  return (
    <Box
      className={clsx(classes.infoBadge, {
        [classes.smallContainer]: isHeaderRollUp,
        [classes.bigContainer]: !isHeaderRollUp,
      })}
    >
      <Tooltip title={showMeteoComplexityIndex && t('alertIndex.tooltip')}>
        <Typography
          className={clsx(classes.typographyValue, {
            [classes.typographyValueSmall]: isHeaderRollUp,
            [classes.typographyValueBig]: !isHeaderRollUp,
          })}
        >
          {showMeteoComplexityIndex ? data.alertIndexes.is_10m : date}
        </Typography>
      </Tooltip>

      {clock && clock}

      {isViewForecast && <Forecast forecast={data?.alertIndexes} />}
    </Box>
  );
};

const useStyles = makeStyles(({ palette, typography }: Theme) => ({
  typographyValue: {
    '&.MuiTypography-root': {
      color: palette.text.t6,
      fontWeight: typography.fontWeightBold,
    },
  },
  typographyValueSmall: {
    '&.MuiTypography-root': {
      fontSize: '2.5rem',
    },
  },
  typographyValueBig: {
    '&.MuiTypography-root': {
      fontSize: '3.125rem',
    },
  },
  smallContainer: {
    flexDirection: 'row',
    padding: '0.625rem 0.5rem',
    width: COLLAPSED_INFO_BADGE,
    alignItems: 'baseline',
  },
  bigContainer: {
    flexDirection: 'column',
    padding: '1rem 1.188rem 1.688rem 1rem',
    width: UNCOLLAPSED_INFO_BADGE,
    alignItems: 'center',
  },
  infoBadge: {
    display: 'flex',
    justifyContent: 'space-evenly',
    position: 'relative',
    background: palette.secondary.main,
    borderRadius: '0rem 0rem 0.5rem 0rem',
    zIndex: 1,

    '& .indicatorsWrapper': {
      position: 'absolute',
      right: 0,
      top: 0,
      height: '100%',
      width: 12,

      '& .indicatorItem': {
        height: '25%',
        '&:hover': {
          opacity: '0.75',
        },
      },
    },
  },
}));

export default withTranslation('entities')(memo(Indicator));
