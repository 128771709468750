import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactComponent as SnowplowTruck } from '@app/assets/snowplowTruck.svg';

const CSDTableSkeleton = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <Box className={classes.main}>
      <Box className={classes.animationContainer}>
        <Box className={classes.container}>
          <Box className={classes.road} />
          <SnowplowTruck className={classes.car} />
        </Box>
        <Typography sx={{ marginTop: '1rem' }} variant="h6">
          {t('notificationMessages.loading')}
        </Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    margin: 'auto',
  },
  animationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    width: 400,
    height: 200,
  },
  container: {
    position: 'relative',
    width: 400,
    height: 50,
    overflow: 'hidden',
  },
  road: {
    position: 'absolute',
    top: '100%',
    width: '100%',
    height: 3,
    backgroundColor: '#a8a8a8',
    transform: 'translateY(-50%)',
  },
  car: {
    transform: 'matrix(-1, 0, 0, 1, 0, 0)',
    width: '6rem',
    height: '5rem',
    position: 'absolute',
    left: '-60px',
    top: 'calc(50% - 39px)',
    animation: '$drive 3s linear infinite',
  },
  '@keyframes drive': {
    '0%': {
      left: '-60px',
    },
    '100%': {
      left: '100%',
    },
  },
}));

export default CSDTableSkeleton;
