import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAccount } from '@app/v2/shared/hooks';

const RedirectToStartPage = () => {
  const { accountStartPage } = useAccount();

  return <Redirect to={accountStartPage} />;
};

export default RedirectToStartPage;
