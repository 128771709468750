import { useContext } from 'react';
import SignContext from '@app/modules/scoreboard-module/contexts/sign/SignContext';
import { Selector } from '@app/modules/scoreboard-module/contexts/types';

export default function useSignContext() {
  const { signsSelectors } = useContext(SignContext);

  const selectHandler = ({ key, value }): Selector => {
    return { label: value, value: key };
  };

  return {
    signsSelectors: signsSelectors.map(selectHandler),
  };
}
