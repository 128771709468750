/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import tokenStorage from '@app/clients/apollo/tokenStorage';
import { ReducersNames } from '@store/constants';
import { PasswordRecoveryStatuses } from '@app/v2/shared/enums';

type AuthState = {
  isAuth: boolean;
  isConfirmInProgress: boolean;
  token: string;
  authErrorStatus: boolean;
  authErrorStatusMessage: string;
  confirmationErrorStatus: boolean;
  confirmationErrorMessage: string;
  passwordRecoveryStatus: PasswordRecoveryStatuses;
};

export const initialState = {
  isAuth: false,
  isConfirmInProgress: false,
  token: null,
  authErrorStatus: false,
  authErrorStatusMessage: null,
  confirmationErrorStatus: false,
  confirmationErrorMessage: null,
  passwordRecoveryStatus: null,
};

const authSlice = createSlice({
  name: ReducersNames.auth,
  initialState,
  reducers: {
    loginSuccess: (state: AuthState, { payload }: PayloadAction<{ token: string }>) => {
      state.isAuth = true;
      state.token = payload.token;
    },
    changeConfirmStatus: (state: AuthState, { payload }: PayloadAction<{ status: boolean }>) => {
      state.isConfirmInProgress = payload.status;
    },
    changePasswordRecoveryStatus: (state: AuthState, { payload }: PayloadAction<PasswordRecoveryStatuses>) => {
      state.passwordRecoveryStatus = payload;
    },
    setAuthErrorMessage: (
      state: AuthState,
      {
        payload,
      }: PayloadAction<{
        status: boolean;
        errorMessage?: string;
      }>,
    ) => {
      state.authErrorStatus = payload.status;

      if (!payload.status) {
        state.authErrorStatusMessage = null;
      } else {
        state.authErrorStatusMessage = payload?.errorMessage;
      }
    },
    setConfirmationError: (
      state: AuthState,
      {
        payload,
      }: PayloadAction<{
        status: boolean;
        errorMessage?: string;
      }>,
    ) => {
      state.confirmationErrorStatus = payload.status;

      if (!payload.status) {
        state.confirmationErrorMessage = null;
      } else {
        state.confirmationErrorMessage = payload?.errorMessage;
      }
    },
    autoLogin: (state: AuthState) => {
      if (tokenStorage.isExistAuthToken) {
        state.isAuth = true;
        state.token = tokenStorage.get();
      }
      return initialState;
    },
    logout: () => initialState,
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
