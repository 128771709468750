import React from 'react';
import { CSDIconPanelPaper } from '@app/modules/kit-module/shared/ui';
import { useCheckAccess } from '@app/v2/shared/hooks';
import useSearchQuery from '@app/core/hooks/useSearchQuery';
import CSDHeaderFastLinkItem from './CSDFastLinkItem';
import headerFastLinks from '../fastLinks';

const CSDFastLinks = () => {
  const { accessChecker } = useCheckAccess();
  const search = useSearchQuery();

  return (
    <CSDIconPanelPaper>
      {headerFastLinks
        .filter(fastLink => accessChecker(fastLink.permissions))
        .map(link => (
          <CSDHeaderFastLinkItem key={link.path} link={link} search={search} />
        ))}
    </CSDIconPanelPaper>
  );
};

export default CSDFastLinks;
