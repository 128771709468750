import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Divider } from '@mui/material';
import { CSDTrafficChart } from '@app/modules/kit-module/shared/ui';
import useTrafficChartOptions from './useTrafficChartOptions';

type Props = {
  data: Traffic.CommonTrafficChart;
  isViewAdditionalChart: boolean;
};

const CSDTrafficChartConnector = ({ data, isViewAdditionalChart }: Props) => {
  const classes = useStyles();

  const options = useTrafficChartOptions({ data, isViewAdditionalChart });

  return (
    <Box className={classes.container}>
      <CSDTrafficChart className={classes.chart} options={options} />
      {!isViewAdditionalChart && <Divider orientation="horizontal" className={classes.legendDivider} />}
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  chart: {
    borderRadius: '0.5rem',
    border: '2px solid black',
    padding: '1rem 1.5rem 0 1.5rem',
  },
  legendDivider: {
    position: 'absolute',
    bottom: 45,
    height: 3,
    left: '3rem',
    width: 'calc(100% - 3rem)',
  },
});

export default CSDTrafficChartConnector;
