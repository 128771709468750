import { PreparedWaterLevelChartData } from '@app/v2/entities/CSDCharts/CSDWaterLevelChart/types';

const prepareResponseData = (data: WLC.ChartDataRes): PreparedWaterLevelChartData => {
  if (!data?.waterLevel?.details) return null;

  const {
    content: { height, ...otherDetailsContent },
    ...otherDetails
  } = data.waterLevel.details;
  return {
    ...otherDetails,
    content: {
      waterLevel: height,
      ...otherDetailsContent,
    },
  };
};

export default prepareResponseData;
