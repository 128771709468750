import React from 'react';
import { Icon } from '@mui/material';
import icons from '@app/assets/iconFont';

interface Props {
  icon: string;
}

export const CSDIconComponent = ({ icon }: Props) => {
  return <Icon sx={{ color: 'black', fontSize: '1rem' }}>{icon}</Icon>;
};

const CSDIcons = {
  nextIcon: <CSDIconComponent icon={icons.right} />,
  superNextIcon: <CSDIconComponent icon={icons.rightright} />,
  prevIcon: <CSDIconComponent icon={icons.left} />,
  superPrevIcon: <CSDIconComponent icon={icons.leftleft} />,
  clearIcon: null,
};

export default CSDIcons;
