import React from 'react';
import { Box, BoxProps, Icon, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)<BoxProps>(() => ({
  display: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0.45rem 0.5rem 0.45rem 0.8rem',
  position: 'absolute',
  borderRadius: '0rem 0.5rem 0.5rem 0rem',
  left: '25px',
  top: 0,
  gap: '0.3rem',
}));

type Props = {
  statusColor: string;
  notifications?: {
    iconName: string;
    tooltip?: string;
  }[];
};

const CSDMapMarkerNotifications = ({ notifications, statusColor }: Props) => {
  return (
    <StyledBox
      sx={{
        background: ({ palette: { common } }) => statusColor || common.white,
        borderTop: !statusColor && '1px solid black',
        borderRight: !statusColor && '1px solid black',
        borderBottom: !statusColor && '1px solid black',
      }}
    >
      {notifications.map(notification => (
        <Tooltip title={notification.tooltip} key={notification.iconName}>
          <Icon
            sx={{
              fontSize: '1rem',
              color: ({ palette: { common } }) => (statusColor ? common.white : common.black),
            }}
          >
            {notification.iconName}
          </Icon>
        </Tooltip>
      ))}
    </StyledBox>
  );
};

export default CSDMapMarkerNotifications;
