import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Paper, PaperProps, Popover, Stack, StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQuery } from '@apollo/client';
import icons from '@app/assets/iconFont';
import { CSDFakeInput, CSDRoadSegment } from '@app/modules/kit-module/shared/ui';
import { boxShadowLight } from '@theme/styles';
import { useNewFilters, usePopover, useFilterStationType } from '@app/v2/shared/hooks';
import { getScrollStyles } from '@theme/themeSettings/componentSettings';
import { ENTER_TOOLTIP_DELAY } from '@app/v2/shared/constants';
import { FiltersKeys, FiltersTypes } from '@app/v2/shared/enums';
import otherQueries from '@app/clients/apollo/requests/queries/others';
import { strictlyEqual } from '@app/v2/shared/helpers';
import CSDRoadSearchEmptyItem from './CSDRoadSearchEmptyItem';
import CSDRoadsSearchInput from './CSDRoadsSearchInput';
import roadsToRoadsSegments from '../helpers/roadsToRoadsSegments';

const CSDRoadsSearch = ({ t }: I18N.TranslationFunction<'filters'>) => {
  const { anchorEl, isOpen, openPopover, closePopover } = usePopover<HTMLDivElement>();
  const { isFiltersExist, filters, setFilter, setFiltersToURL } = useNewFilters();

  const { stationType } = useFilterStationType();
  const [roads, setRoads] = useState<Common.RoadsSegments>([]);
  const [filteredRoads, setFilteredRoads] = useState<Common.RoadsSegments>([]);

  const { data, loading } = useQuery<Common.RoadPartsResponse, Common.RoadPartsVariables>(otherQueries.roads, {
    variables: {
      organizationIds: filters?.organizations,
      roadIds: filters?.roads,
      stationType,
    },
    skip: !isFiltersExist,
  });

  useEffect(() => {
    if (!data?.roads.length) return;

    const preparedSegments = roadsToRoadsSegments(data?.roads);

    setRoads(preparedSegments);
    setFilteredRoads(preparedSegments);
  }, [data]);

  const handleSetRoadFilter = useCallback(
    (roadSegmentId: number): void => {
      let nextValue: number[];

      switch (true) {
        case !Array.isArray(filters?.roads):
          nextValue = [roadSegmentId];
          break;
        case filters?.roads.includes(roadSegmentId):
          nextValue = filters?.roads.filter(road => !strictlyEqual<number>(road, roadSegmentId));
          break;
        default:
          nextValue = [...filters?.roads, roadSegmentId];
      }

      setFilter({ key: FiltersKeys.Roads, payload: { value: nextValue } });
      setFiltersToURL({ [FiltersKeys.Roads]: { type: FiltersTypes.Array, value: nextValue } });
    },
    [filters?.roads, setFilter, setFiltersToURL],
  );

  const listHeight = useMemo<number>(() => {
    const itemHeight = 48;
    const maxHeight = 500;
    const itemsFullList = 13;

    if (filteredRoads.length >= itemsFullList) return maxHeight;
    return filteredRoads.length * itemHeight;
  }, [filteredRoads.length]);

  const isActiveListItem = (roadId: number): boolean => filters?.roads.includes(roadId);

  return (
    <>
      <CSDFakeInput
        icon={icons.search}
        onClick={openPopover}
        disabled={!data?.roads.length}
        isLoading={!data?.roads.length && loading}
        label={t('searchByFoundRoads.label')}
        placeholder={t('searchByFoundRoads.placeholder')}
      />

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{ vertical: -16, horizontal: 150 }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <StyledPaper>
          <CSDRoadsSearchInput
            onChange={value => {
              setFilteredRoads(roads.filter(({ title }) => title.toLocaleLowerCase().includes(value.toLocaleLowerCase())));
            }}
            onClear={() => {
              setFilteredRoads(roads);
            }}
          />

          <>
            {filteredRoads.length ? (
              <StyledStack sx={{ maxHeight: listHeight }}>
                {filteredRoads.map(roadSegment => (
                  <CSDRoadSegment
                    key={roadSegment.id}
                    roadSegment={roadSegment}
                    disabled={!data?.roads.length}
                    onRoadSegmentClick={handleSetRoadFilter}
                    roadSegmentStyles={{
                      marginBottom: '0.5rem',
                      backgroundColor: isActiveListItem(roadSegment.id) && '#3245AF',
                      color: isActiveListItem(roadSegment.id) && 'white',
                      border: isActiveListItem(roadSegment.id) && 'none',
                    }}
                    tooltipProps={{
                      enterDelay: ENTER_TOOLTIP_DELAY,
                      placement: 'right',
                      arrow: true,
                    }}
                  />
                ))}
              </StyledStack>
            ) : (
              <CSDRoadSearchEmptyItem />
            )}
          </>
        </StyledPaper>
      </Popover>
    </>
  );
};

const StyledPaper = styled(Paper)<PaperProps>(({ theme: { palette } }) => ({
  width: '316px',
  padding: '0.5rem',
  borderRadius: '0.5rem',
  backgroundColor: palette.common.white,
  boxShadow: boxShadowLight,
}));

const StyledStack = styled(Stack)<StackProps>(
  ({
    theme: {
      palette: { text, mode },
    },
  }) => ({
    flexDirection: 'column',
    overflow: 'auto',
    marginTop: '0.5rem',
    paddingRight: '0.1rem',
    ...getScrollStyles(mode, text),
  }),
);

export default withTranslation('filters')(memo(CSDRoadsSearch));
