import React from 'react';
import { withTranslation } from 'react-i18next';
import { Stack, IconButton } from '@mui/material';

type Props = {
  tabs;
  disabled: boolean;
  wrapperClass?: string;
  buttonsClass?: string;
} & I18N.TranslationFunction<'map'>;

const SensorButtons = ({ tabs, disabled, wrapperClass, buttonsClass, t }: Props) => {
  return (
    <Stack direction="row" spacing={1} className={wrapperClass}>
      {tabs.map(({ label, color, icon, click, disabled: buttonDisabled }) => (
        <IconButton
          className={buttonsClass}
          disabled={disabled || buttonDisabled}
          aria-label={t(label)}
          style={{ color }}
          key={t(label)}
          onClick={click}
        >
          {icon}
        </IconButton>
      ))}
    </Stack>
  );
};

export default withTranslation('map')(SensorButtons);
