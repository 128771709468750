import React, { useCallback, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import useMapProfileContext from '@app/v2/features/mapProfiles/hooks/useMapProfileContext';
import { ProfileMapSettingLabels } from '@app/v2/shared/enums';
import SettingTypes from '@app/modules/map-module/enums/SettingTypes';
import { CSDSwitcher } from '@app/modules/kit-module/shared/ui';

const RoadsControl = ({ t }: I18N.TranslationFunction<'map', 'mapSettingLabels'>) => {
  const { profileData, handleChangeProfileCheckbox } = useMapProfileContext();

  const isChecked = useMemo(() => profileData.settings[SettingTypes.ROAD_MAPPING].checked, [profileData.settings]);

  const handleChange = useCallback(() => {
    handleChangeProfileCheckbox(ProfileMapSettingLabels.SETTINGS, SettingTypes.ROAD_MAPPING, !isChecked);
  }, [isChecked, handleChangeProfileCheckbox]);

  return <CSDSwitcher label={t('roadView')} checked={isChecked} onChange={handleChange} />;
};

export default withTranslation('map', { keyPrefix: 'mapSettingLabels' })(RoadsControl);
