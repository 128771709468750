import FETCH_TRAFFIC_DIRECTIONS from './fetchTrafficDirections.graphql';
import FETCH_TRAFFIC_GRAPH from './fetchTrafficGraph.graphql';
import FETCH_TRAFFIC_MAGAGEMENT from './fetchTrafficManagement.graphql';
import FETCH_TRAFFIC_STATIONS_PLACE_AND_ROAD_DATA from './fetchTrafficStationsPlaceAndRoadData.graphql';
import FETCH_TRAFFIC_GRAPH_BY_LINES from './fetchTrafficGraphByLines.graphql';
import FETCH_TRAFFIC_GRAPH_DIRECTIONS from './fetchTrafficGraphDirections.graphql';

const trafficQueries = {
  trafficDirections: FETCH_TRAFFIC_DIRECTIONS,
  trafficGraph: FETCH_TRAFFIC_GRAPH,
  trafficGraphByLines: FETCH_TRAFFIC_GRAPH_BY_LINES,
  trafficGraphDirections: FETCH_TRAFFIC_GRAPH_DIRECTIONS,
  trafficManagement: FETCH_TRAFFIC_MAGAGEMENT,
  trafficStationsPlaceAndRoadData: FETCH_TRAFFIC_STATIONS_PLACE_AND_ROAD_DATA,
};

export default trafficQueries;
