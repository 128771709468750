import React from 'react';
import { withTranslation } from 'react-i18next';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)<BoxProps>(({ theme: { palette } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '1rem',
  maxHeight: '40px',
  minHeight: '40px',
  padding: '4px 1rem',
  fontSize: '1rem',
  borderRadius: '0.5rem',
  border: `1px solid ${palette.common.black}`,
}));

const CSDRoadSearchEmptyItem = ({ t }: I18N.TranslationFunction<'filters'>) => {
  return <StyledBox>{t('searchByFoundRoads.noMatches')}</StyledBox>;
};

export default withTranslation('filters')(CSDRoadSearchEmptyItem);
