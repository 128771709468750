import { createContext } from 'react';
import { FiltersKeys } from '@app/v2/shared/enums';

const FilterContext = createContext<{
  filters: Filters.Filter;
  filtersValues: Filters.Values;
  setFilter: <Key extends FiltersKeys>(key: Key, value: Partial<Filters.FiltersValues[Key]>['value']) => void;
  setFilters: (newFilters: Filters.Update) => void;
  syncFilters: (nextFilter: Filters.Filter) => void;
}>({
  filters: {},
  filtersValues: {},
  setFilter: () => {},
  setFilters: () => {},
  syncFilters: () => {},
});

export default FilterContext;
