import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import i18n from 'i18next';
import getCharWidgetIdByLanguage from '@app/core/helpers/getCharWidgetIdByLanguage';
import { showError } from '@app/core/utils/notifications';
import { useAccount, useAppSelector, useChat } from '@app/v2/shared/hooks';
import { Languages } from '@app/v2/shared/enums';
import createChartAttributes from '@app/v2/shared/ui/custom/CSDChat/createChartAttributes';

const Chat = () => {
  const tawkMessengerRef = useRef(null);
  const [isTawkLoaded, setIsTawkLoaded] = useState<boolean>(false);

  const { isChatOpen, handleChangeChatStatus, handleSetAmountOfUnreadMessages } = useChat();
  const { isAccountSettingsExist, settings } = useAccount();

  const isAuth = useAppSelector(state => state.auth.isAuth);

  const currentLocale = useMemo<Languages>(() => {
    if (!isAuth || !settings.locale) return i18n.language as Languages;

    return settings.locale;
  }, [isAuth, settings.locale]);

  useEffect(() => {
    if (isTawkLoaded) tawkMessengerRef.current?.hideWidget();
  }, [isTawkLoaded]);

  useEffect(() => {
    if (isTawkLoaded) {
      if (isChatOpen) tawkMessengerRef.current.maximize();
      else tawkMessengerRef.current.minimize();
    }
  }, [isChatOpen, isTawkLoaded]);

  useEffect(() => {
    if (isTawkLoaded && isAccountSettingsExist) {
      tawkMessengerRef.current?.setAttributes(createChartAttributes(settings), error => showError(error));
    }
  }, [isAccountSettingsExist, isTawkLoaded, settings]);

  return (
    <TawkMessengerReact
      ref={tawkMessengerRef}
      onLoad={() => setIsTawkLoaded(true)}
      widgetId={getCharWidgetIdByLanguage(currentLocale)}
      propertyId={process.env.REACT_APP_CHAT_PROPERTY_ID}
      onUnreadCountChanged={handleSetAmountOfUnreadMessages}
      onChatMinimized={() => handleChangeChatStatus(false)}
    />
  );
};

export default memo(Chat);
