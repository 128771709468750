import { i18n } from '@app/v2/shared/localization';
import getHumanizedAddress from '@app/core/helpers/getHumanizedAddress';
import { isNumber } from '@app/v2/shared/helpers';

const getKmAndMAddressFromPlace = <T extends Common.BasePlaceWithKmAndM>({ kilometer, meter }: T) => {
  if (!isNumber(kilometer) && !isNumber(meter)) return null;
  return getHumanizedAddress(i18n.t('common:units.kilometers'), kilometer, meter);
};

export default getKmAndMAddressFromPlace;
