import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import type { VideoPostState } from '@store/slices/videoPost/videoPostSlice';
import { strictlyEqual } from '@app/v2/shared/helpers';
import { VideoStationMode } from '@app/v2/shared/enums';
import useAppSelector from '../reactRedux/useAppSelector';
import useActions from '../reactRedux/useActions';

interface VideoPost extends VideoPostState {
  handleResetVideoPostState: () => void;
  handleSetDate: (nextValue: Pick<VideoPostState, 'dateFrom' | 'dateTo'>) => void;
  handleSetDefaultPage: Common.VoidCallBack;
  handleSetPage: (nextValue: Pick<VideoPostState, 'page'>) => void;
  handleSetProperties: (nextValue: Pick<VideoPostState, 'stationProperties'>) => void;
  handleAddSnapShots: (nextValue: Pick<VideoPostState, 'snapShots'>) => void;
  handleSetSnapshot: (nextValue: Pick<VideoPostState, 'snapShots'>) => void;
  handleSetActiveSnapshot: (nextValue: Pick<VideoPostState, 'activeSnapShot'>) => void;
  handleToggleAnimation: (nextValue: Pick<VideoPostState, 'isAnimation'>) => void;
  handleChangeLiveSnapShotURL: (nextValue: Pick<VideoPostState, 'liveSnapShotURL'>) => void;
  handleChangeActiveSnapshotIndex: (nextValue: Pick<VideoPostState, 'activeSnapShotIndex'>) => void;
  handleChangeViewMode: Common.VoidCallBack;
  handleSetInitDates: Common.VoidCallBack;
}

export default function useVideoPost(): VideoPost {
  const videoPostData = useAppSelector(state => state.videoPost, shallowEqual);

  const {
    resetVideoPostState,
    setVideoPostDate,
    setVideoPostDefaultPage,
    setVideoPostPage,
    setVideoStationProperties,
    addVideoPostSnapShots,
    setVideoPostSnapshot,
    setVideoPostActiveSnapshot,
    setVideoPostContentMode,
    toggleVideoPostAnimation,
    setVideoPostLiveSnapShot,
    setVideoPostActiveSnapshotIndex,
    setVideoPostInitialDates,
  } = useActions();

  const handleResetVideoPostState = useCallback(() => resetVideoPostState(), [resetVideoPostState]);

  const handleSetDate = useCallback(
    (nextValue: Pick<VideoPostState, 'dateFrom' | 'dateTo'>) => {
      if (strictlyEqual(videoPostData.dateTo, nextValue.dateTo) && strictlyEqual(videoPostData.dateFrom, nextValue.dateFrom)) return;

      resetVideoPostState();
      setVideoPostDate(nextValue);
    },
    [resetVideoPostState, setVideoPostDate, videoPostData.dateFrom, videoPostData.dateTo],
  );

  const handleSetDefaultPage = useCallback(() => {
    setVideoPostDefaultPage();
  }, [setVideoPostDefaultPage]);

  const handleSetInitDates = useCallback(() => {
    setVideoPostInitialDates();
  }, [setVideoPostInitialDates]);

  const handleSetPage = useCallback(
    (nextValue: Pick<VideoPostState, 'page'>) => {
      setVideoPostPage(nextValue);
    },
    [setVideoPostPage],
  );

  const handleSetProperties = useCallback(
    (nextValue: Pick<VideoPostState, 'stationProperties'>) => {
      const {
        stationProperties: { isStream, isWiper, isLive },
      } = nextValue;

      setVideoStationProperties({ stationProperties: { isWiper: isWiper ?? false, isLive: isLive ?? false, isStream: isStream ?? false } });
    },
    [setVideoStationProperties],
  );

  const handleAddSnapShots = useCallback(
    (nextValue: Pick<VideoPostState, 'snapShots'>) => {
      addVideoPostSnapShots(nextValue);
    },
    [addVideoPostSnapShots],
  );

  const handleSetSnapshot = useCallback(
    (nextValue: Pick<VideoPostState, 'snapShots'>) => {
      if (!nextValue) return;

      setVideoPostSnapshot(nextValue);
      setVideoPostActiveSnapshot({ activeSnapShot: nextValue.snapShots[0] });
    },
    [setVideoPostActiveSnapshot, setVideoPostSnapshot],
  );

  const handleSetActiveSnapshot = useCallback(
    (nextValue: Pick<VideoPostState, 'activeSnapShot'>) => {
      const { liveSnapShotURL, snapShots } = videoPostData;

      const nextActiveIndex = snapShots.findIndex(({ url }) => strictlyEqual(url, nextValue.activeSnapShot?.url));

      if (liveSnapShotURL) setVideoPostLiveSnapShot({ liveSnapShotURL: null });

      setVideoPostActiveSnapshotIndex({ activeSnapShotIndex: strictlyEqual(nextActiveIndex, -1) ? null : nextActiveIndex });
      setVideoPostActiveSnapshot(nextValue);
    },
    [setVideoPostActiveSnapshot, setVideoPostActiveSnapshotIndex, setVideoPostLiveSnapShot, videoPostData],
  );

  const handleToggleAnimation = useCallback(
    (nextValue: Pick<VideoPostState, 'isAnimation'>) => {
      toggleVideoPostAnimation(nextValue);
    },
    [toggleVideoPostAnimation],
  );

  const handleChangeLiveSnapShotURL = useCallback(
    (nextValue: Pick<VideoPostState, 'liveSnapShotURL'>) => {
      if (videoPostData.activeSnapShot) setVideoPostActiveSnapshot({ activeSnapShot: null });
      setVideoPostLiveSnapShot(nextValue);
    },
    [setVideoPostActiveSnapshot, setVideoPostLiveSnapShot, videoPostData.activeSnapShot],
  );

  const handleChangeActiveSnapshotIndex = useCallback(
    (nextValue: Pick<VideoPostState, 'activeSnapShotIndex'>) => {
      const { liveSnapShotURL, snapShots } = videoPostData;

      if (liveSnapShotURL) setVideoPostLiveSnapShot({ liveSnapShotURL: null });

      if (strictlyEqual(nextValue.activeSnapShotIndex, snapShots.length)) {
        setVideoPostActiveSnapshotIndex({ activeSnapShotIndex: 0 });
        setVideoPostActiveSnapshot({ activeSnapShot: snapShots[0] });
      } else {
        setVideoPostActiveSnapshotIndex(nextValue);
        setVideoPostActiveSnapshot({ activeSnapShot: snapShots.find((_, index) => strictlyEqual(index, nextValue.activeSnapShotIndex)) });
      }
    },
    [setVideoPostActiveSnapshot, setVideoPostActiveSnapshotIndex, setVideoPostLiveSnapShot, videoPostData],
  );

  const handleChangeViewMode = useCallback(() => {
    const nextValue = videoPostData.contentMode === VideoStationMode.VIDEO_MODE ? VideoStationMode.IMAGE_MODE : VideoStationMode.VIDEO_MODE;

    setVideoPostContentMode(nextValue);
  }, [videoPostData.contentMode, setVideoPostContentMode]);

  return {
    ...videoPostData,
    handleResetVideoPostState,
    handleSetDate,
    handleSetDefaultPage,
    handleSetPage,
    handleSetProperties,
    handleAddSnapShots,
    handleSetSnapshot,
    handleSetActiveSnapshot,
    handleToggleAnimation,
    handleChangeLiveSnapShotURL,
    handleChangeActiveSnapshotIndex,
    handleSetInitDates,
    handleChangeViewMode,
  };
}
