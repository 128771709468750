import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import icons from '@app/assets/iconFont';
import { boxShadowLight, linearGradient } from '@theme/styles';

type Props = {
  handleAdd: () => void;
};

const CSDCreateNoteButton = ({ handleAdd }: Props) => {
  const classes = useStyles();

  const { t } = useTranslation('common', { keyPrefix: 'buttons' });

  const handleCreate = () => {
    handleAdd();
  };

  return (
    <Tooltip title={t('add')}>
      <IconButton className={classes.main} onClick={handleCreate}>
        <Icon className={classes.icon}>{icons.plus}</Icon>
      </IconButton>
    </Tooltip>
  );
};

const useStyles = makeStyles({
  main: {
    '&.MuiButtonBase-root': {
      zIndex: 1,
      position: 'fixed',
      bottom: '3rem',
      right: '3rem',
      borderRadius: '80%',
      padding: '1rem',
      background: linearGradient,
      boxShadow: boxShadowLight,
      opacity: 0.8,
      transition: 'opacity 150ms linear',

      '&:hover': {
        opacity: 1,
      },
    },
  },
  icon: {
    color: 'white',
  },
});

export default CSDCreateNoteButton;
