import React, { useCallback, useEffect, memo } from 'react';
import { Typography } from '@mui/material';
import moment from 'moment';
import { DATE_PERIOD } from '@app/v2/shared/constants';
import useLocalStorage from '@app/core/hooks/useLocalStorage';

type Props = {
  setIsTimeNotOver: (arg: boolean) => void;
  deadline: number;
  setDeadline: (prev: (arg: number) => number) => void;
  localStorageCountdownKey: string;
};

const Countdown = ({ setIsTimeNotOver, deadline, setDeadline, localStorageCountdownKey }: Props) => {
  const { setItem, removeItem } = useLocalStorage();

  const getTimeUntil = useCallback(() => {
    setDeadline(prev => prev - DATE_PERIOD.SECOND);
    setItem(localStorageCountdownKey, String(deadline / 1000));
  }, [deadline, localStorageCountdownKey, setDeadline, setItem]);

  useEffect(() => {
    const interval = setInterval(() => getTimeUntil(), DATE_PERIOD.SECOND);

    if (deadline < 0) {
      setIsTimeNotOver(false);
      removeItem(localStorageCountdownKey);
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [getTimeUntil, setIsTimeNotOver, deadline, localStorageCountdownKey, removeItem]);

  return (
    <Typography color="gray" variant="button">
      {moment(deadline).format('mm:ss')}
    </Typography>
  );
};

export default memo(Countdown);
