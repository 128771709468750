import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Tooltip } from '@mui/material';
import { CSDSwitcher, CSDConfirmDialog } from '@app/modules/kit-module/shared/ui';
import vmsNewMutations from '@app/clients/apollo/requests/mutations/vmsNew';
import { addFeatureActions } from '@app/v2/shared/utils';
import { useDialog } from '@app/v2/shared/hooks';

interface Props extends Common.FeaturesActions {
  isTogetherMode: boolean;
  variables: Pick<Scoreboards.DeviceModelTableData, 'id' | 'connectionType' | 'connectionTime' | 'sendTime' | 'receivedTime' | 'bufSize'>;
}

const CSDUpdateDevice = ({ isTogetherMode, variables: { id, ...variables }, ...featureActions }: Props) => {
  const { t } = useTranslation('scoreboard');
  const openConfirmDialog = useDialog(CSDConfirmDialog);

  const [editTogetherMode, { loading }] = useMutation<Common.MutationResponse>(vmsNewMutations.device.update);

  const { confirmQuestion, tooltipMessage } = useMemo(
    () => ({
      confirmQuestion: `${t('devices.messagePart1')} ${t(isTogetherMode ? 'devices.actions.turnOff' : 'devices.actions.turnOn')} ${t(
        'devices.messagePart2',
      )}`,
      tooltipMessage: `${t(isTogetherMode ? 'devices.actions.turnOff' : 'devices.actions.turnOn')} ${t('devices.partOfTooltip')}`,
    }),
    [isTogetherMode, t],
  );

  const handleUpdate = () => {
    openConfirmDialog({ question: confirmQuestion }).beforeClose.subscribe(answerToQuestion => {
      if (!answerToQuestion) return;

      addFeatureActions(
        editTogetherMode({
          variables: {
            isTogetherMode: !isTogetherMode,
            deviceId: id,
            ...variables,
          },
        }),
        featureActions,
      );
    });
  };

  return (
    <Tooltip title={tooltipMessage}>
      <CSDSwitcher size="small" checked={isTogetherMode} onChange={handleUpdate} disabled={loading} />
    </Tooltip>
  );
};

export default CSDUpdateDevice;
