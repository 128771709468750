import React from 'react';
import { withTranslation } from 'react-i18next';
import { TypographyProps, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCurrentPage } from '@app/v2/shared/hooks';
import { CURRENT_PAGE_LABEL_LENGTH_WITHOUT_TOOLTIP, ENTER_TOOLTIP_DELAY } from '@app/v2/shared/constants';

const CSDHeaderCurrentPage = ({ t, i18n }: I18N.TranslationFunction<'router'>) => {
  const currentPage = useCurrentPage();
  const label = t(currentPage?.label);

  const helperKey = `helper.${currentPage?.label}`;

  const hasHelper = i18n.exists(`router:${helperKey}`);
  const helper = t(helperKey, label);

  return (
    <Tooltip
      placement="bottom-start"
      title={helper}
      enterDelay={ENTER_TOOLTIP_DELAY}
      disableHoverListener={label?.length <= CURRENT_PAGE_LABEL_LENGTH_WITHOUT_TOOLTIP && !hasHelper}
    >
      <StyledTypography variant="h1">{label}</StyledTypography>
    </Tooltip>
  );
};

const StyledTypography = styled(Typography)<TypographyProps>({
  flexGrow: 1,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  cursor: 'default',
});

export default withTranslation('router')(CSDHeaderCurrentPage);
