import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { CSDNoIconData } from '@app/modules/kit-module/shared/ui';
import settings from '@app/core/constants/subscriptionParameters';
import { useDialog } from '@app/v2/shared/hooks';
import PlanDialog from '@app/components/charts/plan-dialog/PlanDialog';
import getKmAndMAddressFromPlace from '@app/v2/shared/helpers/getKmAndMAddressFromPlace';
import { DATE_FORMAT } from '@app/v2/shared/constants';
import useUtcOffset from '@app/core/hooks/useUtcOffset';
import NotificationActions from './NotificationActions';
import { ALERT_NOTIFICATION_RANK } from '../constants';

interface Props {
  notificationItem: Notifications.AlertModel;
}

const CSDNotificationAlertCard = ({ notificationItem }: Props) => {
  const {
    id: notificationId,
    message,
    meta: { endTime, startTime, rankId, alias },
    place,
  } = notificationItem;

  const classes = useStyles({ rank: rankId });

  const utcOffset = useUtcOffset();

  const address = place && getKmAndMAddressFromPlace(place);

  const { endDate, startDate } = useMemo(() => {
    const dateToFormat = (date: string): string => {
      if (!date) return null;

      return moment(date).utcOffset(utcOffset).format(DATE_FORMAT.FORMAT_RU_HOURS);
    };

    return {
      startDate: dateToFormat(startTime),
      endDate: dateToFormat(endTime),
    };
  }, [endTime, startTime, utcOffset]);

  const { t } = useTranslation('notification');
  const openPlanDialog = useDialog<{ placeId: number }>(PlanDialog);

  const openPlanDialogHandler = () => {
    if (!place.id) return;
    openPlanDialog({ placeId: place.id });
  };

  return (
    <Box className={classes.notificationListItem}>
      <Box className={classes.notificationListItemHeader}>
        <Box className={classes.headerWithActions}>
          <Typography onClick={openPlanDialogHandler} sx={{ cursor: 'pointer' }} variant="h2">
            {address ?? <CSDNoIconData />}
          </Typography>
          <NotificationActions notificationId={notificationId} />
        </Box>

        <Typography variant="body2">{place?.road?.title ?? <CSDNoIconData />}</Typography>
      </Box>

      <Box className={classes.notificationListItemContent}>
        <Box className={classes.notificationContentent} sx={{ flex: 0, margin: 'auto 0' }}>
          <Typography variant="body2" sx={{ marginBottom: '0.188rem' }}>
            {startDate ?? <CSDNoIconData />}
          </Typography>

          <Typography variant="h3">{endDate ?? <CSDNoIconData />}</Typography>
        </Box>

        <Divider sx={{ margin: '0 1rem' }} orientation="vertical" variant="middle" flexItem />

        <Box className={classes.notificationContentent}>
          <Typography variant="body1">{t('tableColumns.message')}</Typography>

          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {message ?? <CSDNoIconData />}
          </Typography>
        </Box>

        <Divider sx={{ margin: '0 1rem' }} orientation="vertical" variant="middle" flexItem />

        <Box className={classes.notificationContentent}>
          <Typography variant="body1">{t('filter.notificationType')}</Typography>

          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {settings[alias] ? t(settings[alias]) : <CSDNoIconData />}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme, { rank: number }>(theme => ({
  notificationListItem: {
    borderWidth: '5px 1px 1px 1px',
    borderColor: ({ rank }) => (rank === ALERT_NOTIFICATION_RANK ? theme.palette.technicalColors.red : theme.palette.technicalColors.yellow),
    borderStyle: 'solid',
    borderRadius: '0.5rem',
    padding: '1rem 1.5rem',
    width: 'calc(630px - 3rem)',
    margin: 'auto',
    backgroundColor: theme.palette.background.first,
  },
  notificationListItemHeader: {
    marginBottom: '1rem',
    '& .MuiTypography-body2': {
      color: theme.palette.text.t2,
      fontSize: '0.875rem',
    },
  },
  headerWithActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    cursor: 'pointer',
  },
  notificationListItemContent: {
    display: 'flex',
  },
  notificationContentent: {
    flex: 1,
    '& .MuiTypography-body1': {
      marginBottom: '0.5rem',
      textDecoration: 'underline',
      color: theme.palette.text.t2,
    },
    '& .MuiTypography-body2': {
      lineHeight: '0.75rem',
    },
  },
}));

export default CSDNotificationAlertCard;
