import { logout } from '@store/thunks/auth/auth-thunks';
import client from '@app/clients/apollo/client';
import ErrorMessages from '@app/core/constants/errorsMessages';
import NotificationMessages from '@app/core/constants/notificationMessages';
import getNotifyTranslationMessage from '@app/core/helpers/getNotifyTranslationMessage';
import { showApolloErrors, showError, showSuccess } from '@app/core/utils/notifications';
import { AccountSettingsMutationResponse, AccountSettingsResponse } from '@store/thunks/account/types';
import { loaderActions } from '@app/store/slices/loader';
import { accountActions } from '@app/store/slices/account';
import accountQueries from '@app/clients/apollo/requests/queries/account';
import accountMutations from '@app/clients/apollo/requests/mutations/account';
import { AccountSettings } from '@store/slices/account/types';
import { AutoPaginationPeriodsInSeconds, Languages } from '@app/v2/shared/enums';

export const fetchAccountSettings = () => async dispatch => {
  dispatch(loaderActions.setLoaderStatus(true));

  try {
    const { data, errors } = await client.query<AccountSettingsResponse>({ query: accountQueries.user });

    if (errors?.length) return showApolloErrors(errors);
    if (!data?.accountSettingData) return dispatch(logout());

    const profileParameters = data?.accountSettingData?.profileParameters;

    const accountData: AccountSettings = {
      ...profileParameters,
      locale: profileParameters.locale.toLocaleUpperCase() as Languages,
      userId: data?.accountSettingData.id,
      userOrganizationId: data?.accountSettingData?.organization.id,
      userOrganization: data?.accountSettingData?.organization.name,
      videoWallSetting: {
        ...data?.accountSettingData?.profileParameters.videoWallSetting,
        autoPagination: data?.accountSettingData?.profileParameters?.videoWallSetting?.autoPagination ?? AutoPaginationPeriodsInSeconds.fifteen,
      },
    };

    if (data && profileParameters) {
      dispatch(loaderActions.setLoaderStatus(false));
      dispatch(accountActions.setAccountSettings({ accountData }));
      dispatch(accountActions.toggleIsAccountSettingsExist({ status: true }));
    }
  } catch (error) {
    showError(ErrorMessages.COMMON_ERROR_MESSAGE);
  } finally {
    dispatch(loaderActions.setLoaderStatus(false));
  }

  return false;
};

export const changeAccountSettings = newUserSettings => async dispatch => {
  dispatch(loaderActions.setLoaderStatus(true));

  try {
    const { data, errors } = await client.mutate<AccountSettingsMutationResponse>({
      mutation: accountMutations.update,
      variables: newUserSettings,
    });

    if (errors?.length) return showApolloErrors(errors);

    if (data) {
      dispatch(accountActions.setAccountSettings({ accountData: data?.changedAccountSettingData }));
      dispatch(accountActions.toggleIsAccountSettingsExist({ status: true }));
      dispatch(accountActions.toggleIsAccountTimeSettings({ status: false }));
      showSuccess(getNotifyTranslationMessage(NotificationMessages.SUCCESS_SAVE));
    }
  } catch (error) {
    showError(ErrorMessages.COMMON_ERROR_MESSAGE);
  } finally {
    dispatch(loaderActions.setLoaderStatus(false));
  }

  return false;
};
