import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from 'use-query-params';
import { FiltersKeys, LocalStorageKeys } from '@app/v2/shared/enums';
import { LISTING_DEFAULT_PAGE_NUMBER, PagesWithoutFilters, URLParametersMapper } from '@app/v2/shared/constants';
import useAppSelector from '../reactRedux/useAppSelector';
import useActions from '../reactRedux/useActions';

export default function useFilterMonitor() {
  const history = useHistory();

  const filters = useAppSelector(state => state.newFilters.filters);
  const { setFilter, toggleFiltersEnabledState, setPagination } = useActions();

  const [paramsFilter] = useQueryParams(
    Object.entries(filters).reduce<Record<string, any>>((acc, [filterName, filter]) => {
      acc[filterName] = URLParametersMapper[filter.type];
      return acc;
    }, {}),
  );

  useEffect(() => {
    Object.entries(paramsFilter).forEach(([key, value]) => {
      if (value) setFilter({ key: key as FiltersKeys, payload: { value } });
    });

    setPagination({ page: LISTING_DEFAULT_PAGE_NUMBER });
    toggleFiltersEnabledState(true);
  }, [paramsFilter, setFilter, setPagination, toggleFiltersEnabledState]);

  history.listen(({ pathname, search }) => {
    if (PagesWithoutFilters.includes(pathname) && search) {
      localStorage.setItem(LocalStorageKeys.FilterSearch, search);
      history.replace(pathname);
    }

    const filtersFromLS = localStorage.getItem(LocalStorageKeys.FilterSearch);

    if (!PagesWithoutFilters.includes(pathname) && !search && filtersFromLS) {
      localStorage.removeItem(LocalStorageKeys.FilterSearch);
      history.replace(`${pathname}${filtersFromLS}`);
    }
  });
}
