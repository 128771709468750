import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { FieldProps, useField } from 'formik';
import Autocomplete from '@app/components/common/autocomplete/Autocomplete';
import useScrollEffect from '@app/components/common/custom-form-component/useScrollEffect';

const CustomSelectComponent = ({
  form: { setFieldValue, setFieldTouched },
  field: { name },
  placeholder,
  values,
  onChange,
  onInputChange,
  multiple = false,
  loading = false,
  margin = 'dense',
  disabled = false,
}: FieldProps<string> & {
  placeholder: string;
  values: Array<{ value; label }>;
  multiple?: boolean;
  loading?: boolean;
  onChange;
  onInputChange;
  margin?: 'dense' | 'none' | 'normal';
  disabled?: boolean;
}) => {
  const ref = useRef<HTMLInputElement>();

  useScrollEffect(ref, name);

  const [, meta] = useField(name);

  const { touched, error, value } = meta;

  const isError = touched && Boolean(error);

  useEffect(() => {
    typeof onChange === 'function' && onChange(value);
  }, [onChange, value]);

  const preparedValue = useMemo(() => {
    if (!values) {
      return null;
    }

    if (multiple) {
      return values.filter(item => value?.map(v => +v).includes(item.value));
    }

    return values.find(item => item.value === value) || null;
  }, [multiple, value, values]);

  const setValue = useCallback(
    (id, current) => {
      if (multiple) {
        return setFieldValue(
          name,
          current.map(item => item.value),
        );
      }

      return setFieldValue(name, current?.value);
    },
    [multiple, name, setFieldValue],
  );

  return (
    <Autocomplete
      ref={ref}
      label={placeholder}
      id={name}
      options={values}
      loading={loading}
      value={preparedValue}
      setValue={setValue}
      onBlur={() => setFieldTouched(name, true)}
      helperText={touched ? error : ''}
      error={isError}
      multiple={multiple}
      size="small"
      onInputChange={onInputChange}
      margin={margin}
      disabled={disabled}
    />
  );
};

export default CustomSelectComponent;
