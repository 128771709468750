import { isEqual } from 'lodash';

type AnyObjectWithCommonFilters = Record<string, any> &
  Partial<{
    organizations: number[];
    roads: number[];
    places: number[];
  }>;

export default function compareCommonFilters(obj1: AnyObjectWithCommonFilters, obj2: AnyObjectWithCommonFilters): boolean {
  return isEqual(
    {
      roads: obj1?.roads ?? [],
      organizations: obj1?.organizations ?? [],
      places: obj1?.places ?? [],
    },
    {
      roads: obj2?.roads ?? [],
      organizations: obj2?.organizations ?? [],
      places: obj2?.places ?? [],
    },
  );
}
