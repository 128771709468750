import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CSDInputFastField, CSDSelectFastField } from '@app/modules/kit-module/shared/ui';
import { tpiSelectors } from '@app/v2/shared/constants';

type Props = {
  index: number;
  contentIndex: number;
  numCharactersPerLine: number;
};

const TemplateParagraphForm = ({ index, contentIndex, numCharactersPerLine }: Props) => {
  const classes = useStyles();

  const { t } = useTranslation('scoreboard');

  const PATH = `content.contents[${contentIndex}][${index}]`;

  return (
    <Box className={classes.wrapper}>
      <CSDInputFastField path={`${PATH}.text`} label={t('templates.form.stringValue')} maxLength={numCharactersPerLine} />
      <CSDSelectFastField path={`${PATH}.align`} label={t('templates.form.textPosition')} items={tpiSelectors.configuration.align} />
    </Box>
  );
};

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },
});

export default TemplateParagraphForm;
