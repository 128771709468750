import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import prepareEventsOfPlace from './prepareEventsOfPlace';
import {
  prepareChartForPlanChart,
  prepareSeriesForPlanChart,
  prepareTooltipForPlanChart,
  prepareXAxisForPlanChart,
  prepareYAxisForPlanChart,
} from '../../common/fnForPrepareCharts/planChart';
import PlanChartHeaderId from '../../common/enums';
import type { PlanGraphAlert } from '../types';

export default function usePlacePlanChartOptions(alerts: PlanGraphAlert[], chartHeight: number): Highcharts.Options {
  const { t } = useTranslation('charts');

  const events = useMemo(() => prepareEventsOfPlace(alerts, t), [alerts, t]);

  return {
    chart: prepareChartForPlanChart(alerts.length, chartHeight, PlanChartHeaderId.PLACE, false),
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    subtitle: {
      text: null,
    },
    time: {
      timezoneOffset: new Date().getTimezoneOffset(),
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    tooltip: prepareTooltipForPlanChart(),
    yAxis: prepareYAxisForPlanChart(),
    xAxis: prepareXAxisForPlanChart(),
    series: prepareSeriesForPlanChart([], events),
  };
}
