import React from 'react';
import { Icon, IconButton } from '@mui/material';
import icons from '@app/assets/iconFont';

type Props<T = string> = {
  callback: (data?: T) => void;
  isEdge?: false | 'start' | 'end';
  iconSize?: number;
};

const CloseDialogButton = <T extends unknown = string>({ callback, isEdge = false, iconSize = 24 }: Props<T>) => {
  return (
    <IconButton onClick={() => callback()} edge={isEdge}>
      <Icon sx={{ fontSize: iconSize }}>{icons.close}</Icon>
    </IconButton>
  );
};

export default CloseDialogButton;
