import React from 'react';
import { withTranslation } from 'react-i18next';
import { Box, BoxProps, Stack, StackProps, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQuery } from '@apollo/client';
import queries from '@app/clients/apollo/requests/queries/waterLevelControl';
import { formatEnFull } from '@app/core/helpers/dateFormat';
import { useWaterLevelChart } from '@app/v2/shared/hooks';
import { CSDWaterLevelSnapshot, CSDMeteoChartSkeleton } from '@app/modules/kit-module/shared/ui';
import { CSDWaterLevelChart } from '@app/v2/entities';
import { getScrollStyles } from '@theme/themeSettings/componentSettings';
import prepareResponseData from './helpers/prepareResponseData';

type Props = {
  stationId: number;
  roadTitle: string;
} & I18N.TranslationFunction<'common', 'notificationMessages'>;

const WaterLevelChartTabContent = ({ stationId, roadTitle, t }: Props) => {
  const { dateTo, dateFrom } = useWaterLevelChart();

  const { data, loading } = useQuery<WLC.ChartDataRes, WLC.ChartDataVar>(queries.WLCDitails, {
    variables: {
      stationId,
      dateFrom: formatEnFull(dateFrom),
      dateTo: formatEnFull(dateTo),
    },
  });

  const preparedData = prepareResponseData(data);

  if (!loading && !preparedData) {
    return (
      <StyledEmptyData>
        <Typography>{t('noData')}</Typography>
      </StyledEmptyData>
    );
  }

  return (
    <StyledWrapper direction="row">
      <StyledItemWrapper flex={0.25}>
        {loading ? <CSDMeteoChartSkeleton height="100%" /> : <CSDWaterLevelSnapshot chartData={data.waterLevel.details} roadTitle={roadTitle} />}
      </StyledItemWrapper>
      <Divider orientation="vertical" flexItem />
      <StyledItemWrapper flex={0.75}>
        {loading ? <CSDMeteoChartSkeleton height="100%" /> : <CSDWaterLevelChart dataForChart={preparedData} roadTitle={roadTitle} />}
      </StyledItemWrapper>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Stack)<StackProps>(
  ({
    theme: {
      breakpoints,
      palette: { text, mode },
    },
  }) => ({
    padding: '1rem',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    gap: '1rem',
    [breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
    overflow: 'auto',
    overflowX: 'hidden',
    height: '100%',
    ...getScrollStyles(mode, text),
  }),
);

const StyledItemWrapper = styled(Box)<BoxProps>(() => ({
  height: '100%',
  width: '100%',
  minHeight: '25rem',
  minWidth: '25rem',
}));

const StyledEmptyData = styled(Box)<BoxProps>(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export default withTranslation('common', { keyPrefix: 'notificationMessages' })(WaterLevelChartTabContent);
