import React, { useState, useCallback } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Tabs, Box } from '@mui/material';
import { CSDAutocomplete, CSDTab, CSDInputStandard } from '@app/modules/kit-module/shared/ui';
import { getSplitGlobalSetting } from '@app/v2/features/notifications/helpers';
import { DEFAULT_INPUT_LENGTH } from '@app/v2/shared/constants';
import icons from '@app/assets/iconFont';
import useNotificationProfileContext from '@app/v2/features/notificationProfile/context/useNotificationProfileContext';
import { useAppSelector } from '@app/v2/shared/hooks';
import { GLOBAL_SETTING_TYPES } from '@app/v2/features/notifications/constants';
import PofileManagerActions from './profileManager/PofileManagerActions';
import SubscriptionsCheckbox from '../globalTab/SubscriptionsCheckbox';

const LocalTabForm = ({ t }: WithTranslation<'notification'>) => {
  const classes = useStyles();
  const { currentProfile, isLoading, error, changeFormField, changeTitle, changeCheckbox } = useNotificationProfileContext();
  const [selectedTab, setSelectedTab] = useState<number>(GLOBAL_SETTING_TYPES.NOW.value);
  const { roadParts, places } = useAppSelector(state => state.subscriptions);
  const { forecastSettings, nowSettings } = getSplitGlobalSetting(currentProfile?.notifications || []);

  const getDataByGlobalType = useCallback(
    tab => (tab === GLOBAL_SETTING_TYPES.FORECAST.value ? forecastSettings : nowSettings),
    [forecastSettings, nowSettings],
  );

  return (
    <>
      {!!currentProfile && (
        <Box className={classes.localSubscriptionContainer}>
          <Box className={classes.leftSidePart}>
            <Box>
              <CSDInputStandard
                id="title"
                name="title"
                label={t('profiles.filters.titleLabel')}
                placeholder={t('profiles.filters.enterTitle')}
                maxLength={DEFAULT_INPUT_LENGTH}
                autoComplete="off"
                disabled={isLoading}
                icon={icons.edit}
                error={!!error}
                helperText={error}
                value={currentProfile?.title}
                onChange={e => {
                  changeTitle(currentProfile.id, e.target.value);
                }}
              />
              <CSDAutocomplete
                label={t('profiles.filters.roadsLabel')}
                id="roadParts"
                inputProps={{
                  placeholder: t('profiles.filters.enterRoads'),
                  fullWidth: true,
                }}
                disabled={isLoading}
                autocompleteProps={{
                  options: roadParts,
                  multiple: true,
                  value: roadParts.filter(({ value }) => currentProfile.roadParts.includes(+value)),
                  setValue: (_, newValue) => changeFormField(newValue, 'roadParts'),
                }}
              />
              <CSDAutocomplete
                autocompleteProps={{
                  options: places,
                  multiple: true,
                  value: places.filter(({ value }) => currentProfile.places.includes(+value)),
                  setValue: (_, newValue) => changeFormField(newValue, 'places'),
                }}
                inputProps={{
                  placeholder: t('profiles.filters.enterPlaces'),
                  fullWidth: true,
                }}
                disabled={isLoading}
                label={t('profiles.filters.placesLabel')}
                id="places"
              />
            </Box>
            <PofileManagerActions />
          </Box>
          <Box className={classes.checkboxContainer}>
            <Tabs
              className={classes.tabsElement}
              value={selectedTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              onChange={(_, value) => setSelectedTab(value)}
            >
              {Object.values(GLOBAL_SETTING_TYPES).map(({ label, value }) => (
                <CSDTab key={value} className={classes.tabElement} label={t(label)} value={value} />
              ))}
            </Tabs>
            <SubscriptionsCheckbox data={getDataByGlobalType(selectedTab)} disabled={isLoading} changeCheckbox={changeCheckbox} />
          </Box>
        </Box>
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  localSubscriptionContainer: {
    display: 'flex',
    flex: 1,
  },
  leftSidePart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '16rem',
    marginRight: '1rem',
  },
  checkboxContainer: {
    width: '35rem',
    height: 'calc(100vh - 19rem)',
  },
  tabsElement: {
    borderBottom: '1px solid #000',
    marginBottom: '1rem',
    '& .MuiTab-root:last-child': {
      marginRight: '1px',
    },
  },
  tabElement: {
    flex: 1,
  },
}));

export default withTranslation('notification')(LocalTabForm);
