import { KeyPrefix } from 'react-i18next';
import { ActiveAlerts } from '@app/v2/shared/enums';
import icons from '@app/assets/iconFont';

const activeAlertStatusMapper: Record<ActiveAlerts, { iconName: string; tooltip: KeyPrefix<'constants'> }> = {
  [ActiveAlerts.wind]: {
    iconName: icons.wind,
    tooltip: 'alert.wind',
  },
  [ActiveAlerts.temperatureChange]: {
    iconName: icons.temperatureChange,
    tooltip: 'alert.temperatureChange',
  },
  [ActiveAlerts.liquidPrecipitation]: {
    iconName: icons.liquidPrecipitation,
    tooltip: 'alert.liquidPrecipitation',
  },
  [ActiveAlerts.solidAndMixedPrecipitation]: {
    iconName: icons.mixedRainfall,
    tooltip: 'alert.solidAndMixedPrecipitation',
  },
  [ActiveAlerts.winterSlipperiness]: {
    iconName: icons.slippery,
    tooltip: 'alert.winterSlipperiness',
  },
  [ActiveAlerts.winterSlipperiness2]: {
    iconName: icons.slipperyTwo,
    tooltip: 'alert.winterSlipperiness2',
  },
};

export default activeAlertStatusMapper;
