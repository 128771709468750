import React from 'react';
import { IconButton, IconButtonProps, Icon, IconProps, Tooltip, TooltipProps } from '@mui/material';

interface Props extends IconButtonProps {
  icon: string;
  tooltip?: string;
  isShow?: boolean;
  iconProps?: IconProps;
  tooltipProps?: TooltipProps;
}

const CSDIconButton = ({ icon, tooltip, isShow = true, iconProps, tooltipProps, ...iconButtonProps }: Props) => {
  return (
    isShow && (
      <IconButton {...iconButtonProps}>
        <Tooltip title={!!tooltip && tooltip} {...tooltipProps}>
          <Icon {...iconProps}>{icon}</Icon>
        </Tooltip>
      </IconButton>
    )
  );
};

export default CSDIconButton;
