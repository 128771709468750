import React from 'react';
import { Icon, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CSDIconPanelPaper } from '@app/modules/kit-module/shared/ui';
import { strictlyEqual } from '@app/v2/shared/helpers';
import { favoritesLayouts } from '@app/v2/shared/configs';
import { useCheckAccess } from '@app/v2/shared/hooks';
import icons from '@app/assets/iconFont';
import useFavoritesLayouts from '../../shared/hooks/reduxConnections/useFavoritesLayouts';

const CSDFavoritesLayouts = () => {
  const classes = useStyles();

  const { accessChecker } = useCheckAccess();
  const { activeLayout, setActiveLayout } = useFavoritesLayouts();

  return (
    <CSDIconPanelPaper>
      {favoritesLayouts
        .filter(({ perimeters }) => (Array.isArray(perimeters) ? accessChecker(perimeters) : true))
        .map(({ type, icon, tooltipTitle }) => (
          <Tooltip key={`${icon}${type}`} title={tooltipTitle}>
            <IconButton className={classes.btn} onClick={() => setActiveLayout(type)}>
              <Icon className="favoritesPrefix">{icons.starOutline}</Icon>
              <Icon sx={{ color: ({ palette }) => (strictlyEqual(activeLayout, type) ? palette.primary.main : palette.common.black) }}>{icon}</Icon>
            </IconButton>
          </Tooltip>
        ))}
    </CSDIconPanelPaper>
  );
};

const useStyles = makeStyles({
  btn: {
    position: 'relative',

    '& .favoritesPrefix ': {
      top: -8,
      right: -8,
      position: 'absolute',
      fontSize: '1rem',
      color: '#f57c00',
    },
  },
});

export default CSDFavoritesLayouts;
