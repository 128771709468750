import React, { FC, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Observable, Subject } from 'rxjs';

type DialogContextType = {
  open: <P extends {}, T extends {}, R extends {}>(Dialog: FC<DialogRef<R> & T & P>, data?: T) => DialogRef<R>;
};

export interface DialogRef<R = string> {
  close: (data?: R) => void;
  beforeClose: Observable<R>;
}

export interface Dialog<P, T, R> {
  Dialog: FC<DialogRef<R> & T & P>;
  uuid: string;
  data: T;
  close: (dataClose: R) => void;
  beforeClose: Observable<R>;
  subject?: Subject<R>;
}

export const DialogContext = React.createContext<DialogContextType>(null);

export const DialogProvider = ({ children }: { children }) => {
  const [place, setPlace] = useState<HTMLDivElement>(null);
  const [instances, setInstances] = useState<{ [key: string]: Dialog<any, any, any> }>({});

  const uuid = useRef(0);

  const close = useCallback(<R extends {}>(key: string, data?: R) => {
    setInstances(prev => {
      const [, instance] = Object.entries(prev).find(([index]) => index === key) || [];

      instance?.subject.next(data);
      instance?.subject.complete();

      return { ...Object.fromEntries(Object.entries(prev).filter(([index]) => index !== key)) };
    });
  }, []);

  const open = useCallback(
    <P extends {}, T extends {}, R extends {}>(Dialog: FC<DialogRef<R> & T & P>, data?: T): DialogRef<R> => {
      const beforeCloseSubject = new Subject<R>();

      const key = String(uuid.current);

      uuid.current += 1;

      const closeFactory = (dataClose: R) => {
        close<R>(key, dataClose);
      };

      const beforeClose = beforeCloseSubject.asObservable();

      setInstances(prev => ({ ...prev, [key]: { Dialog, data, uuid: key, subject: beforeCloseSubject, close: closeFactory, beforeClose } }));

      return {
        close: closeFactory,
        beforeClose,
      };
    },
    [close],
  );

  const context = useMemo(
    () => ({
      open,
    }),
    [open],
  );

  const child = useMemo(() => React.Children.only(children), [children]);

  const Components = useMemo(
    () =>
      Object.values(instances).map(({ Dialog, data, uuid: key, close: closeDialog, beforeClose }) => (
        <Dialog key={key} close={closeDialog} beforeClose={beforeClose} {...data} />
      )),
    [instances],
  );

  useLayoutEffect(() => {
    const dialogNode = document.createElement('div');
    document.body.append(dialogNode);
    setPlace(dialogNode);
  }, []);

  return (
    <DialogContext.Provider value={context as DialogContextType}>
      <>
        {child}
        {!!place && createPortal(Components, place)}
      </>
    </DialogContext.Provider>
  );
};
