import React from 'react';
import { Grid, Chip, Divider, Stack, Box } from '@mui/material';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import { dividerWithText, verticalWithText, verticalDivider, verticalMiddleDivider } from './example';
import CSDKitDocBlock from '../../../shared/ui/CSDKitDocBlock/CSDKitDocBlock';

const content = (
  <div>
    {`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus id dignissim justo.
 Nulla ut facilisis ligula. Interdum et malesuada fames ac ante ipsum primis in faucibus.
 Sed malesuada lobortis pretium.`}
  </div>
);

const CsdDividers = () => {
  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="Dividers with text" codeExample={dividerWithText}>
        <Stack direction="column" spacing={3}>
          {content}
          <Divider>CENTER</Divider>
          {content}
          <Divider textAlign="left">LEFT</Divider>
          {content}
          <Divider textAlign="right">RIGHT</Divider>
          {content}
          <Divider>
            <Chip label="CHIP" />
          </Divider>
          {content}
        </Stack>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Vertical with text" codeExample={verticalWithText}>
        <Grid container width="600px">
          <Grid item xl>
            {content}
          </Grid>
          <Divider orientation="vertical" flexItem>
            VERTICAL
          </Divider>
          <Grid item xs>
            {content}
          </Grid>
        </Grid>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Vertical devider" codeExample={verticalDivider}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            border: theme => `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            color: 'text.secondary',
            '& svg': {
              m: 1.5,
            },
            '& hr': {
              mx: 0.5,
            },
          }}
        >
          <FormatAlignLeftIcon />
          <FormatAlignCenterIcon />
          <FormatAlignRightIcon />
          <Divider orientation="vertical" flexItem />
          <FormatBoldIcon />
          <FormatItalicIcon />
        </Box>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Vertical devider (middle variant)" codeExample={verticalMiddleDivider}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            border: theme => `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            color: 'text.secondary',
            '& svg': {
              m: 1.5,
            },
            '& hr': {
              mx: 0.5,
            },
          }}
        >
          <FormatAlignLeftIcon />
          <FormatAlignCenterIcon />
          <FormatAlignRightIcon />
          <Divider orientation="vertical" variant="middle" flexItem />
          <FormatBoldIcon />
          <FormatItalicIcon />
        </Box>
      </CSDKitDocBlock>
    </Stack>
  );
};

export default CsdDividers;
