import React, { CSSProperties, memo, useMemo } from 'react';
import moment from 'moment';
import { Box, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { boxShadowLight } from '@theme/styles';
import { isFunction } from '@app/v2/shared/helpers';
import { EMPTY_STRING, DATE_FORMAT } from '@app/v2/shared/constants';
import { CSDIncidentReport } from '@app/v2/shared/ui/custom';

interface Props {
  stationId: number;
  snapShot: Video.Snapshot;
  isActive: boolean;
  isFirst: boolean;
  isLast: boolean;
  onClick?: (snapShot: Video.Snapshot) => void;
  styles?: CSSProperties;
}

const CSDSnapShot = ({ stationId, snapShot, isActive, onClick, isFirst, isLast, styles = {} }: Props) => {
  const classes = useStyles({ isActive, isFirst, isLast });

  const [date, time] = useMemo(() => {
    const dateTime = moment.parseZone(snapShot?.dateTime, DATE_FORMAT.WITH_TIME_ZONE);

    if (!dateTime.isValid()) {
      return [EMPTY_STRING, EMPTY_STRING];
    }

    return [dateTime.format(DATE_FORMAT.FORMAT_RU_POINT), dateTime.format(DATE_FORMAT.FORMAT_RU_TIME)];
  }, [snapShot]);

  const onClickChecker = (nextActiveSnapShot: Video.Snapshot): void => {
    if (!isFunction(onClick)) return;
    onClick(nextActiveSnapShot);
  };

  return (
    <Box className={classes.containerItem} sx={{ ...styles }}>
      <Box className={classes.outerBlockWithImage}>
        <Divider orientation="vertical" className={classes.divider} />

        <Box sx={{ position: 'relative' }} className={classes.innerBlockWithImage}>
          <CSDIncidentReport
            id={stationId}
            incidents={snapShot?.incidents}
            scale={0.5}
            withAnimation={false}
            onClickWithoutAnimation={() => onClickChecker(snapShot)}
          />
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <img
            src={snapShot?.url.replace('full', 'w150')}
            onClick={() => onClickChecker(snapShot)}
            className={classes.innerBlockWithImage}
            alt={time}
            loading="lazy"
          />
        </Box>
      </Box>

      <Box onClick={() => onClickChecker(snapShot)} className={classes.dateAndTime}>
        {date && <Typography>{date}</Typography>}
        {time && <Typography>{time}</Typography>}
      </Box>
    </Box>
  );
};
const useStyles = makeStyles<Theme, Pick<Props, 'isFirst' | 'isLast' | 'isActive'>>({
  containerItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  divider: {
    '&.MuiDivider-root': {
      position: 'absolute',
      left: ({ isFirst, isLast }) => {
        if (isFirst && isLast) {
          return '50%';
        }

        if (isFirst) {
          return 'calc(50% - 1rem)';
        }

        if (isLast) {
          return 'calc(50% + 1rem)';
        }

        return '50%';
      },
      transform: 'translate(-50%, 0)',
      backgroundColor: 'black',
      height: '1rem',
      bottom: -9,
    },
  },
  outerBlockWithImage: {
    position: 'relative',
    width: '100%',
    borderBottom: '1px solid black',
    padding: '1rem',
    paddingTop: 0,
    paddingLeft: ({ isFirst }) => isFirst && 0,
    paddingRight: ({ isLast }) => isLast && 0,
  },
  innerBlockWithImage: {
    cursor: 'pointer',
    objectFit: 'cover',
    width: '9.375rem',
    height: '6.125rem',
    backgroundSize: 'cover',
    borderRadius: '0.5rem',
    outlineOffset: ({ isActive }) => isActive && '-3px',
    outline: ({ isActive }) => isActive && '3px solid #3245AF',
    boxShadow: ({ isActive }) => isActive && boxShadowLight,
    '&:hover': {
      boxShadow: boxShadowLight,
    },
  },
  dateAndTime: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0.5rem',
    marginRight: ({ isFirst }) => (isFirst ? '2rem' : 0),
    marginLeft: ({ isLast }) => (isLast ? '2rem' : 0),
    '& .MuiTypography-root': {
      fontSize: '0.75rem',
    },
    '&:hover': {
      textShadow: boxShadowLight,
    },
  },
});

export default memo(CSDSnapShot);
