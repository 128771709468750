import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import '../map/Map.scss';
import '../map/notes/form/Notes.scss';
import { useTranslation } from 'react-i18next';
import { Map as MapLeaflet, Viewport } from 'react-leaflet';
import L, { LeafletMouseEvent } from 'leaflet';
import { Icon } from '@mui/material';
import useLoadingMapData from '@app/modules/map-module/hooks/useLoadingMapData';
import useViewportMap from '@app/modules/map-module/hooks/useViewportMap';
import LayersControl from '@app/modules/map-module/components/map/layers-control/LayersControl';
import 'leaflet-geosearch/dist/geosearch.css';
import CustomMarker from '@app/modules/map-module/components/map/markers/CustomMarker';
import BadgeMenuIconMap from '@app/components/common/badge-menu-icon-map/BadgeMenuIconMap';
import RoadMapping from '@app/modules/map-module/components/map/road-mapping/road-mapping';
import { reverseGeocode } from '@app/v2/shared/helpers';
import { useActions, useAppDispatch, useAppSelector } from '@app/v2/shared/hooks';
import './Map.scss';
import MyLocationControl from '@app/modules/map-module/components/map/my-location-control/MyLocationControl';
import icons from '@app/assets/iconFont';

type Props = {
  isPositionLoaded: boolean;
  position: {
    latitude: number;
    longitude: number;
  };
  setPosition: Common.AnyFunction;
};

const ListingMapComponent = ({ isPositionLoaded, position, setPosition }: Props) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation('components');

  const { setViewport } = useActions();

  const [loading, setLoading] = useState<boolean>(true);

  const viewport = useAppSelector(state => state.map.viewport);

  const mapNotesCursor = useAppSelector(state => state.map.mapNotesCursor);

  const map = useRef<L.Map>();

  const isLoadingStartData = useLoadingMapData();

  const [myPosition, setMyPosition] = useState<{ latitude: number; longitude: number }>({ latitude: null, longitude: null });

  useViewportMap(map);

  const setViewportChanged = useCallback(
    (data: Viewport) => {
      if (data.center && data.zoom) {
        dispatch(setViewport(data));
      }
    },
    [dispatch, setViewport],
  );

  useLayoutEffect(() => {
    const addNoteHandler = async (e: LeafletMouseEvent) => {
      const {
        latlng: { lat: latitude, lng: longitude },
      } = e;
      const address = await reverseGeocode(latitude, longitude);
      setPosition({ latitude, longitude, address });
    };

    if (!loading) {
      map?.current?.addEventListener('click', addNoteHandler);
    }
    return () => {
      map?.current?.removeEventListener('click', addNoteHandler);
    };
  }, [position, mapNotesCursor, setPosition, loading]);

  const onDragend = useCallback(
    ({
      target: {
        _latlng: { lat: latitude, lng: longitude },
      },
    }) => {
      setPosition({ latitude, longitude });
    },
    [setPosition],
  );

  const Marker = () =>
    useMemo(
      () =>
        isPositionLoaded && (
          <CustomMarker draggable position={[position?.latitude, position?.longitude]} className="mapNotes" onDragend={onDragend}>
            <BadgeMenuIconMap id="mapNote" title={t('mapNoteIcon')} items={[]} onClick={() => false} />
          </CustomMarker>
        ),
      [],
    );

  const refCallback = ref => {
    if (ref && ref.leafletElement) {
      setLoading(false);
      map.current = ref.leafletElement;
    }
  };

  const MyPositionMarket = useCallback(() => {
    let result = null;

    if (myPosition.latitude && myPosition.longitude) {
      result = (
        <CustomMarker position={[myPosition.latitude, myPosition.longitude]} className="mapNotes">
          <Icon color="info" sx={{ fontSize: '2rem' }}>
            {icons.pin}
          </Icon>
        </CustomMarker>
      );
    }

    return result;
  }, [myPosition]);

  const onReceivedLocation = ({ latitude, longitude }) => {
    setMyPosition({ latitude, longitude });
    map.current.setView({ lat: latitude, lng: longitude }, map.current.getZoom());
  };

  return (
    !isLoadingStartData && (
      <MapLeaflet
        className="listing-map"
        ref={refCallback}
        viewport={viewport}
        onViewportChanged={setViewportChanged}
        style={{ cursor: mapNotesCursor && 'crosshair' }}
        attributionControl={false}
      >
        <MyLocationControl onReceivedLocation={onReceivedLocation} map={map.current} />
        <LayersControl />
        <Marker />
        <MyPositionMarket />
        <RoadMapping />
      </MapLeaflet>
    )
  );
};

export default ListingMapComponent;
