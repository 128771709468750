import React, { memo, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Card } from '@mui/material';
import { styled } from '@mui/styles';
import { EXPIRES_IN_TIMESTAMP, INCOMPLETE_EMAIL, removeConfirmKeys } from '@app/core/constants/auth';
import useCountdownWorker from '@app/core/hooks/workers/useCountdownWorker';
import { REFRESH_TOKEN_KEY } from '@app/clients/apollo/tokenStorage';
import { showError } from '@app/core/utils/notifications';
import ErrorMessages from '@app/core/constants/errorsMessages';
import { useActions, useAppSelector, useLocalStorage } from '@app/v2/shared/hooks';
import { EMPTY_STRING } from '@app/v2/shared/constants';
import { PublicPath } from '@app/v2/shared/enums';
import FormActions from './FormActions/FormActions';
import RecoveryMessage from './RecoveryMessage/RecoveryMessage';
import FormContent from './FormContent/FormContent';
import FormHeader from '../common/FormHeader';

const TwoFactorAuthenticationForm = ({ t }: I18N.TranslationFunction<'auth', 'confirmation.form'>) => {
  const { setConfirmationError, changeConfirmStatus } = useActions();
  const isConfirmationError = useAppSelector(state => state.auth.confirmationErrorStatus);
  const confirmationErrorMessage = useAppSelector(state => state.auth.confirmationErrorMessage);

  const { push } = useHistory();

  const { getItem, removeItems } = useLocalStorage();
  const { sendTimeStamp, addHandler, removeHandler } = useCountdownWorker();

  const [value, setValue] = useState<string>(EMPTY_STRING);

  const resetConfirmationError = useCallback(() => {
    if (isConfirmationError) {
      setConfirmationError({ status: false });
    }
  }, [isConfirmationError, setConfirmationError]);

  const handleChangeCodeValue = useCallback(
    (nextValue: string): void => {
      resetConfirmationError();

      setValue(nextValue);
    },
    [resetConfirmationError],
  );

  const redirectToLoginPage = useCallback(() => {
    changeConfirmStatus({ status: false });
    removeItems([...removeConfirmKeys, REFRESH_TOKEN_KEY]);
    push(PublicPath.Auth);
  }, [changeConfirmStatus, push, removeItems]);

  useEffect(() => {
    const redirectHandler = () => {
      removeItems([...removeConfirmKeys, REFRESH_TOKEN_KEY]);
      showError(ErrorMessages.TIME_ENDED);
      changeConfirmStatus({ status: false });
      push(PublicPath.Auth);
    };

    sendTimeStamp(getItem(EXPIRES_IN_TIMESTAMP));
    addHandler(redirectHandler);

    return () => {
      removeHandler(redirectHandler);
    };
  }, [sendTimeStamp, addHandler, push, removeHandler, removeItems, getItem, changeConfirmStatus]);

  useEffect(() => () => resetConfirmationError(), [resetConfirmationError]);

  return (
    <StyledCard>
      {getItem(INCOMPLETE_EMAIL) ? (
        <>
          <FormHeader redirectToPage={redirectToLoginPage} title={t('headerTitle')} />
          <FormContent
            setValue={handleChangeCodeValue}
            isConfirmationError={isConfirmationError}
            confirmationErrorMessage={confirmationErrorMessage}
          />
          <FormActions value={value} />
        </>
      ) : (
        <RecoveryMessage />
      )}
    </StyledCard>
  );
};

const StyledCard = styled(Card)({
  '&.MuiPaper-root': {
    width: '18.25rem',
    minHeight: '20rem',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '0 !important',

    '& span.MuiCardHeader-title': {
      padding: '1rem',
      textAlign: 'center',
      fontSize: '1.25rem',
      lineHeight: '1.25rem',
    },
  },
});

export default memo(withTranslation('auth', { keyPrefix: 'confirmation.form' })(TwoFactorAuthenticationForm));
