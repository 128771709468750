import useAppSelector from '../reactRedux/useAppSelector';
import useActions from '../reactRedux/useActions';

export default function useFavoritesLayouts() {
  const { setActiveLayout } = useActions();

  return {
    activeLayout: useAppSelector(state => state.favoritesLayouts.activeLayout),
    setActiveLayout,
  };
}
