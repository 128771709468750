import { ThemeOptions } from '@mui/material/styles';

export enum ThemeMode {
  light = 'light',
  dark = 'dark',
}

export type ComponentSettings = ThemeOptions['components'];
export type TypographySettings = ThemeOptions['typography'];
export type PaletteColorsSettings = ThemeOptions['palette'];
