import { createContext } from 'react';
import { Moment } from 'moment';
import { WeatherLayers } from '@app/v2/shared/enums/WeatherLayers';
import LocatorControlService from '@app/modules/map-module/services/LocatorControlService';
import { PlayerTimeRangeActions } from '@app/v2/shared/enums';

const WeatherLocatorsContext = createContext<{
  activeLocatorsIds: number[];
  globalService: LocatorControlService;
  contextValue: WeatherLocators.IWeatherLocatorsContext;
  addLayer: (id: string, layer: WeatherLayers) => void;
  removeLayer: (id: string) => void;
  addService: (id: string, locator: WeatherLocators.Locator) => void;
  removeService: (id: string) => void;
  changeDate: (id: string, date: Moment | string) => void;
  changeTimesRange: (id: string, rangeType: PlayerTimeRangeActions) => void;
  changeIsLayerErrorStatus: (id: string, status: boolean) => void;
  clearLocators: () => void;
  resetLocators: (profileGlobalMode: boolean) => void;
}>({
  activeLocatorsIds: [],
  contextValue: {},
  globalService: null,
  addLayer: () => {},
  removeLayer: () => {},
  addService: () => {},
  removeService: () => {},
  changeDate: () => {},
  changeTimesRange: () => {},
  changeIsLayerErrorStatus: () => {},
  clearLocators: () => {},
  resetLocators: () => {},
});

export default WeatherLocatorsContext;
