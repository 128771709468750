import React from 'react';
import { Avatar, Chip, Stack } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import FaceIcon from '@mui/icons-material/Face';
import {
  basicChip,
  clickableChip,
  deletableChip,
  clickableDeletebleChip,
  clickableLinkChip,
  customDeleteIconChip,
  avatarIconChip,
  sizesChip,
  colorChip,
  disabledChip,
} from './example';
import CSDKitDocBlock from '../../../shared/ui/CSDKitDocBlock/CSDKitDocBlock';

const CsdChip = () => {
  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="Basic chip" codeExample={basicChip}>
        <Stack direction="row" spacing={1}>
          <Chip label="Пулково" />
          <Chip label="Калуга" variant="outlined" />
        </Stack>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Clickable" codeExample={clickableChip}>
        <Stack direction="row" spacing={1}>
          <Chip label="Clickable" onClick={() => {}} />
          <Chip label="Clickable" variant="outlined" onClick={() => {}} />
        </Stack>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Deletable" codeExample={deletableChip}>
        <Stack direction="row" spacing={1}>
          <Chip label="Deletable" onDelete={() => {}} />
          <Chip label="Deletable" variant="outlined" onDelete={() => {}} />
        </Stack>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Clickable and deletable" codeExample={clickableDeletebleChip}>
        <Stack direction="row" spacing={1}>
          <Chip label="Clickable Deletable" onClick={() => {}} onDelete={() => {}} />
          <Chip label="Clickable Deletable" variant="outlined" onClick={() => {}} onDelete={() => {}} />
        </Stack>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Clickable Link" codeExample={clickableLinkChip}>
        <Stack direction="row" spacing={1}>
          <Chip label="Clickable Link" component="a" href="#" clickable />
          <Chip label="Clickable Link" component="a" href="#" variant="outlined" clickable />
        </Stack>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Custom delete icon" codeExample={customDeleteIconChip}>
        <Stack direction="row" spacing={1}>
          <Chip label="Custom delete icon" onClick={() => {}} onDelete={() => {}} deleteIcon={<DoneIcon />} />
          <Chip label="Custom delete icon" onClick={() => {}} onDelete={() => {}} deleteIcon={<DeleteIcon />} variant="outlined" />
        </Stack>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Avatar/Icon Chip" codeExample={avatarIconChip}>
        <Stack direction="row" spacing={1}>
          <Chip avatar={<Avatar>M</Avatar>} label="Avatar" />
          <Chip icon={<FaceIcon />} label="With Icon" variant="outlined" />
        </Stack>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Sizes chip" codeExample={sizesChip}>
        <Stack direction="row" spacing={1}>
          <Chip label="Medium" size="medium" />
          <Chip label="Small" size="small" variant="outlined" />
        </Stack>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Color chip" codeExample={colorChip}>
        <Stack direction="row" spacing={1}>
          <Chip icon={<FaceIcon />} label="primary" color="primary" />
          <Chip label="primary" color="primary" />
          <Chip label="primary" disabled variant="outlined" />
        </Stack>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Disabled chip" codeExample={disabledChip}>
        <Stack direction="row" spacing={1}>
          <Chip label="primary" disabled />
          <Chip label="primary" disabled variant="outlined" />
        </Stack>
      </CSDKitDocBlock>
    </Stack>
  );
};

export default CsdChip;
