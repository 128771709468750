import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton } from '@mui/material';
import { useFormikContext } from 'formik';
import { CSDAutocompleteFastField, CSDScoreboardsViewer, CSDSelectFastField } from '@app/modules/kit-module/shared/ui';
import { durationValues, EMPTY_STRING, singleDurationValue } from '@app/v2/shared/constants';
import { usePrevious } from '@app/v2/shared/hooks';
import { ViewTypes, VMSTemplatesTypes } from '@app/v2/shared/enums';
import PlaylistTimeFormFields from './PlaylistTimeFormFields';

const PlaylistForm = ({
  path,
  template,
  isOnePlaylistItem,
  fetchTemplateSelectors,
  fetchTemplateContent,
  onChangeConfiguration,
  configurationSelectors,
  selectedConfigurationId,
  isDisabledConfigurationChange,
}: Omit<Scoreboards.PlaylistsFormProps, 'onRemove' | 'onSwapToTop' | 'onSwapToBottom'>) => {
  const { getFieldProps, setFieldValue } = useFormikContext<Scoreboards.PlaylistModel>();

  const [templateSelectors, setTemplateSelectors] = useState<Common.SelectorValues | null>(null);

  const [loadingTemplateSelectors, setLoadingTemplateSelectors] = useState<boolean>(false);
  const [loadingTemplate, setLoadingTemplate] = useState<boolean>(false);

  const { t } = useTranslation('scoreboard');

  const handleFetchTemplateSelectors = useCallback(() => {
    setLoadingTemplateSelectors(true);

    const organizationId = getFieldProps(`organizationId`)?.value;
    const configurationId = getFieldProps(`${path}.template.configuration.id`)?.value;

    fetchTemplateSelectors(configurationId, organizationId)
      .then(setTemplateSelectors)
      .finally(() => setLoadingTemplateSelectors(false));
  }, [fetchTemplateSelectors, getFieldProps, path]);

  const syncOrganizationId = useMemo(() => getFieldProps(`organizationId`)?.value, [getFieldProps]);
  const prevSyncOrganizationId = usePrevious(syncOrganizationId);

  useEffect(() => {
    if (loadingTemplateSelectors || syncOrganizationId === prevSyncOrganizationId) return;

    handleFetchTemplateSelectors();
  }, [loadingTemplateSelectors, syncOrganizationId, prevSyncOrganizationId, handleFetchTemplateSelectors]);

  return (
    <>
      <PlaylistTimeFormFields path={path} />

      <CSDSelectFastField
        path={`${path}.duration`}
        items={isOnePlaylistItem ? singleDurationValue : durationValues}
        label={t('playlists.form.interval')}
        selectProps={{
          disabled: isOnePlaylistItem,
        }}
      />

      <CSDAutocompleteFastField
        disabled={isDisabledConfigurationChange}
        path={`${path}.template.configuration.id`}
        autocompleteProps={{
          inputProps: {
            placeholder: t('playlists.form.placeholder.chooseConfiguration'),
            disabled: !configurationSelectors.length,
          },
          label: t('playlists.form.chooseConfiguration'),
          autocompleteProps: {
            multiple: false,
            options: configurationSelectors ?? [],
            setValue: async (_, newValue) => {
              onChangeConfiguration(newValue?.value ?? EMPTY_STRING);
              fetchTemplateSelectors(newValue?.value, syncOrganizationId).then(setTemplateSelectors);
            },
          },
        }}
      />

      {syncOrganizationId && selectedConfigurationId && (
        <CSDAutocompleteFastField
          path={`${path}.template.id`}
          autocompleteProps={{
            inputProps: {
              placeholder: t('playlists.form.placeholder.chooseTemplate'),
            },
            label: t('playlists.form.chooseTemplate'),
            autocompleteProps: {
              multiple: false,
              options: templateSelectors ?? [],
              loading: loadingTemplateSelectors,
              setValue: async (_, newValue) => {
                await setFieldValue(`${path}.template.id`, newValue?.value ?? EMPTY_STRING);
                await setFieldValue(`${path}.template.title`, null);
                await setFieldValue(`${path}.template.contents`, null);

                if (!newValue?.value) return;

                setLoadingTemplate(true);
                fetchTemplateContent(newValue.value as string).finally(() => setLoadingTemplate(false));
              },
            },
          }}
        />
      )}

      {!!template?.contents && !loadingTemplate && (
        <Box className="templateView">
          <CSDScoreboardsViewer template={template} fullWidth viewType={ViewTypes.Img} templateType={VMSTemplatesTypes.Message} />
        </Box>
      )}

      {loadingTemplate && <Skeleton variant="rectangular" width="100%" height="11rem" sx={{ borderRadius: '1rem' }} />}
    </>
  );
};

export default memo(PlaylistForm);
