import React from 'react';
import { withTranslation } from 'react-i18next';
import { IconButton, Icon, Tooltip } from '@mui/material';
import icons from '@app/assets/iconFont';
import { useDialog, useMeteoChartProfiles } from '@app/v2/shared/hooks';
import { CSDConfirmDialog } from '@app/modules/kit-module/shared/ui';
import useMeteoChartProfileActions from '../../hooks/useMeteoChartProfileActions';

interface Props {
  profileId: number;
  icon?: string;
  tooltipTitle?: string;
}

const CSDDeleteMeteoChartProfile = ({
  profileId,
  icon = icons.close,
  tooltipTitle,
  t,
}: Props & I18N.TranslationFunction<'charts', 'meteoConstructor'>) => {
  const openConfirmDialog = useDialog(CSDConfirmDialog);

  const { meteoChartActiveProfile } = useMeteoChartProfiles();
  const { handleDeleteMeteoChartProfile } = useMeteoChartProfileActions();

  const handleDelete = () => {
    openConfirmDialog({
      question: t('confirmationQuestionForDeleteProfile', {
        profileName: meteoChartActiveProfile?.name,
        profileCarts: meteoChartActiveProfile?.items?.map(chart => chart.name).join(' , '),
      }),
    }).beforeClose.subscribe((answerToQuestion: boolean) => {
      if (!answerToQuestion) return;
      handleDeleteMeteoChartProfile(profileId);
    });
  };

  return (
    <Tooltip title={tooltipTitle ?? t('deleteProfile')}>
      <IconButton onClick={handleDelete}>
        <Icon sx={{ fontSize: '1rem' }}>{icon}</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default withTranslation('charts', { keyPrefix: 'meteoConstructor' })(CSDDeleteMeteoChartProfile);
