import React from 'react';
import { makeStyles } from '@mui/styles';
import { CSDTrafficChart } from '@app/modules/kit-module/shared/ui';
import useTrafficChartDirectionsOptions from './useTrafficChartDirectionsOptions';

interface Props {
  data: Traffic.ChartDirections;
}

const CSDTrafficChartDirections = ({ data }: Props) => {
  const classes = useStyles();

  const options = useTrafficChartDirectionsOptions({ data });

  return <CSDTrafficChart className={classes.chart} options={options} />;
};

const useStyles = makeStyles({
  chart: {
    borderRadius: '0.5rem',
    border: '2px solid black',
    padding: '1rem 1.5rem 0 1.5rem',
  },
});

export default CSDTrafficChartDirections;
