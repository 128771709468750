import React, { memo } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import clsx from 'clsx';
import { Stack, Typography } from '@mui/material';
import { isFunction } from '@app/v2/shared/helpers';
import styles from './separateInputStyles.module.scss';

type Props = {
  type?: 'text' | 'number';
  fields?: number;
  loading?: boolean;
  title?: string;
  fieldWidth?: number;
  fieldHeight?: number;
  autoFocus?: boolean;
  className?: string;
  values?: string[];
  disabled?: boolean;
  required?: boolean;
  placeholder?: string[];
  isError?: boolean;
  errorMessage?: string;
  onChange?: (value: string) => void;
  onComplete?: (value: string) => void;
  checker?: (value: string) => boolean;
};

const CodeInputPlaceholder = new Array(6).fill('•');

const CSDSeparateInput = ({
  fieldWidth = 34,
  fieldHeight = 48,
  placeholder = CodeInputPlaceholder,
  onChange,
  isError,
  errorMessage,
  checker,
  ...other
}: Props) => {
  const handleChange = (value: string) => {
    if (isFunction(checker) && !checker(value)) {
      return;
    }

    onChange(value);
  };

  return (
    <Stack direction="column" spacing={1}>
      <ReactCodeInput
        fieldHeight={fieldHeight}
        fieldWidth={fieldWidth}
        placeholder={placeholder}
        className={clsx(styles.separateInput, isError && styles.separateInputError)}
        onChange={handleChange}
        {...other}
      />

      {isError && errorMessage && (
        <Typography fontSize={12} sx={{ color: ({ palette: { error } }) => error.main }}>
          {errorMessage}
        </Typography>
      )}
    </Stack>
  );
};

export default memo(CSDSeparateInput);
