import moment from 'moment/moment';
import strictlyEqual from '../helpers/strictlyEqual';
import { isString } from '../helpers/valueCheckTypes';

export default function isEqualTimeZones(value: string): boolean {
  if (!isString(value)) throw new Error('Invalid value to isEqualTimeZones');

  const inputTimeZone = moment.parseZone(value).format('Z');
  const currentTimeZone = moment.parseZone().format('Z');

  return strictlyEqual<string>(inputTimeZone, currentTimeZone);
}
