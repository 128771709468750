import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { Box, BoxProps, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { EMPTY_STRING } from '@app/v2/shared/constants';

type AdditionalWrapperProps = {
  disabled: boolean;
};

// top block to show tooltip and disable other content
const Wrapper = styled(Box, { shouldForwardProp: prop => prop !== 'disabled' })<BoxProps & AdditionalWrapperProps>(({ disabled }) => ({
  cursor: disabled ? 'not-allowed' : 'auto',
  display: disabled ? 'block' : 'none',
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 999999999999999,
}));

interface DisabledWrapperWithTooltipProps extends I18N.TranslationFunction<'profiles'> {
  children: JSX.Element;
  disabled: boolean;
}

const DisabledWrapperWithTooltip = ({ children, disabled, t }: DisabledWrapperWithTooltipProps) => {
  return (
    <>
      <Tooltip title={disabled ? t('default.profile.disabled.tooltip') : EMPTY_STRING} followCursor={disabled}>
        <span>
          <Wrapper disabled={disabled} />
        </span>
      </Tooltip>
      <div>{children}</div>
    </>
  );
};

export default withTranslation('profiles')(DisabledWrapperWithTooltip);
