import React, { FC, useCallback, useMemo } from 'react';
import { ViewTypes, VMSTemplatesTypes } from '@app/v2/shared/enums';
import useCompressionRatio from '@app/modules/kit-module/shared/ui/CSDScoreboardsViewer/useCompressionRatio';
import { COLOR, COMPRESSION_RATIO, PIXEL_SIZE } from '@app/v2/shared/constants';
import scoreboardItemPreview from '@app/modules/kit-module/shared/ui/CSDScoreboardsViewer/scoreboardItemPreview';
import calculateDataForView from './calculateDataForView';
import CSDScoreboardsViewerCanvas from './CSDScoreboardsViewerCanvas';
import CSDScoreboardsViewerIMG from './CSDScoreboardsViewerIMG';
import CSDScoreboardsViewerSVG from './CSDScoreboardsViewerSVG';

interface Props {
  template: Scoreboards.TemplateContent;
  templateType: VMSTemplatesTypes;
  viewType: ViewTypes;
  isDrawCells?: boolean;
  fullSize?: boolean;
  fullWidth?: boolean;
}

const CSDScoreboardViewerFactory = ({ template, viewType, templateType, isDrawCells = true, fullSize = false, fullWidth = false }: Props) => {
  const renderMapperByType: Record<ViewTypes, FC<Scoreboards.ViewerProps>> = {
    [ViewTypes.Svg]: CSDScoreboardsViewerSVG,
    [ViewTypes.Img]: CSDScoreboardsViewerIMG,
    [ViewTypes.Canvas]: CSDScoreboardsViewerCanvas,
  };

  const ViewComponent = renderMapperByType[viewType];

  const { scoreboardData, signValign, messageValign, intervals, background, configurationTypes, scoreboardWidth, scoreboardHeight, scoreboardSize } =
    useMemo(() => calculateDataForView(template), [template]);

  const compressionRatio = useCompressionRatio({
    scoreboardHeight,
    scoreboardWidth,
    configurationTypes,
    defaultCompressionRatio: COMPRESSION_RATIO,
  });

  const getStartX = useCallback(
    (index: number) => {
      return scoreboardSize
        .map(sizeItem => sizeItem.width)
        .slice(0, index)
        .reduce((acc, sizeItemWidth) => acc + sizeItemWidth, 0);
    },
    [scoreboardSize],
  );

  const prepareRender = useCallback(
    (context: CanvasRenderingContext2D) =>
      scoreboardData.map((itemData, index) =>
        scoreboardItemPreview(context, {
          background,
          content: itemData,
          pixelSize: PIXEL_SIZE,
          signValign: signValign[index],
          itemSize: scoreboardSize[index],
          messageValign: messageValign[index],
          startCoords: { x: getStartX(index), y: 0 },
          charsInterval: intervals[index]?.charsInterval,
          linesInterval: intervals[index]?.linesInterval,
          font: { h: scoreboardSize[index].font.h, w: scoreboardSize[index].font.w, color: COLOR },
        }),
      ),
    [background, getStartX, intervals, messageValign, scoreboardData, scoreboardSize, signValign],
  );

  return (
    <ViewComponent
      fullSize={fullSize}
      fullWidth={fullWidth}
      background={background}
      isDrawCells={isDrawCells}
      templateType={templateType}
      prepareRender={prepareRender}
      scoreboardWidth={scoreboardWidth}
      scoreboardHeight={scoreboardHeight}
      compressionRatio={compressionRatio}
    />
  );
};

export default CSDScoreboardViewerFactory;
