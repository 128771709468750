import React, { useRef } from 'react';
import { withTranslation } from 'react-i18next';
import Highcharts, { ExportingMimeTypeValue } from 'highcharts';
import { Button, Chip, Icon, Popover, Stack, Tooltip } from '@mui/material';
import icons from '@app/assets/iconFont';
import { usePopover } from '@app/v2/shared/hooks';
import { isFunction, strictlyEqual } from '@app/v2/shared/helpers';
import { EMPTY_STRING } from '@app/v2/shared/constants';
import { DataTestIds } from '@app/v2/shared/enums';
import { exportCharts } from './exportConfigurationFunctions';

interface Props {
  title: string;
  infoSetup: { name: string; value: string | number }[];
  styles?: Record<string, unknown>;
  withLabel?: boolean;
  disabled?: boolean;
  configurations: {
    filename: string;
    printed: boolean;
    exportFormats: ExportingMimeTypeValue[];
  };
}

const CSDExportChartBtn = ({
  withLabel = true,
  disabled = false,
  title,
  infoSetup,
  configurations,
  styles,
  t,
}: Props & I18N.TranslationFunction<'charts'>) => {
  const { anchorEl, closePopover, openPopover, isOpen } = usePopover<HTMLButtonElement>();
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handlePrint = () => {
    const [chart] = Highcharts.charts.filter(Boolean);
    if (!isFunction(chart.print)) return;

    chart.print();

    closePopover();
  };

  const handleExport = (type: ExportingMimeTypeValue): void => {
    const charts = Highcharts.charts.filter(Boolean);

    if (!charts.length) {
      closePopover();
      return;
    }

    exportCharts(charts, { type, title, infoSetup, filename: configurations.filename });

    closePopover();
  };

  const actionListConfig = [
    {
      id: 1,
      label: t('menuDefinitions.printChart'),
      onClick: handlePrint,
      isView: configurations.printed,
    },
    {
      id: 2,
      label: t('menuDefinitions.downloadPNG'),
      onClick: () => handleExport('image/png'),
      isView: configurations.exportFormats.find(format => strictlyEqual(format, 'image/png')),
    },
    {
      id: 3,
      label: t('menuDefinitions.downloadJPEG'),
      onClick: () => handleExport('image/jpeg'),
      isView: configurations.exportFormats.find(format => strictlyEqual(format, 'image/jpeg')),
    },
    {
      id: 4,
      label: t('menuDefinitions.downloadPDF'),
      onClick: () => handleExport('application/pdf'),
      isView: configurations.exportFormats.find(format => strictlyEqual(format, 'application/pdf')),
    },
    {
      id: 5,
      label: t('menuDefinitions.downloadSVG'),
      onClick: () => handleExport('image/svg+xml'),
      isView: configurations.exportFormats.find(format => strictlyEqual(format, 'image/svg+xml')),
    },
  ];

  return (
    <>
      {withLabel ? (
        <Tooltip title={disabled ? t('limitIsExceeded') : EMPTY_STRING}>
          <span>
            <Button
              ref={buttonRef}
              sx={styles}
              disabled={disabled}
              startIcon={<Icon sx={{ fontSize: '1.5rem !important' }}>{icons.download}</Icon>}
              onClick={openPopover}
              data-testid={DataTestIds.ExportChartBtnWithLabel}
            >
              {t('export')}
            </Button>
          </span>
        </Tooltip>
      ) : (
        <Tooltip title={disabled ? t('limitIsExceeded') : EMPTY_STRING}>
          <span>
            <Button
              ref={buttonRef}
              sx={styles}
              variant="outlined"
              disabled={disabled}
              onClick={openPopover}
              data-testid={DataTestIds.ExportChartBtnWithoutLabel}
            >
              <Icon sx={{ fontSize: '1.5rem !important' }}>{icons.download}</Icon>
            </Button>
          </span>
        </Tooltip>
      )}

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            background: 'white',
          },
        }}
        marginThreshold={0}
      >
        <Stack direction="column" alignItems="center" gap="1rem" padding="1rem" data-testid={DataTestIds.ExportChartBtns}>
          {actionListConfig.map(({ id, label, isView, onClick }) => (
            <React.Fragment key={id}>{!!isView && <Chip sx={{ width: '100%' }} label={label} variant="outlined" onClick={onClick} />}</React.Fragment>
          ))}
        </Stack>
      </Popover>
    </>
  );
};

export default withTranslation('charts')(CSDExportChartBtn);
