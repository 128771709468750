import * as React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import { ReactComponent as AttentionIcon } from '@app/assets/unpaid/Attention.svg';

type Props = {
  isAnimated?: boolean;
};

const CSDAttentionSvgIcon = ({ isAnimated = false }: Props) => {
  const classes = useStyles({ isAnimated });

  return <AttentionIcon className={clsx({ [classes.animated]: isAnimated })} />;
};

const useStyles = makeStyles<Theme, { isAnimated?: boolean }>(() => ({
  '@keyframes alertAnimation': {
    '0%': {
      transform: 'scale(0.85)',
    },
    '25%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(0.85)',
    },
    '75%': {
      transform: 'scale(0.65)',
    },
    '100%': {
      transform: 'scale(0.85)',
    },
  },
  animated: {
    animation: '$alertAnimation 1s linear infinite',
  },
}));

export default CSDAttentionSvgIcon;
