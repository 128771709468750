import { TFunction } from 'react-i18next';
import moment from 'moment';
import { PlanGraphAlert } from '@app/components/charts/plan-dialog/types';
import {
  createAlertDescription,
  createAlertShortDescription,
  createTimeMessageAboutEvent,
  createTwoColumnsLayoutForYAxis,
} from '@app/components/charts/common/helpers';
import { colors, pointWidth } from '../../common/constants/planChart';
import type { CustomGanttPointOptionsObject } from '../../common/types';

export default function prepareEventsOfPlace(alerts: PlanGraphAlert[], t: TFunction<'charts'>): CustomGanttPointOptionsObject[] {
  const result = [];

  alerts.forEach(({ id, from, to, message, rank, type: { title, alias }, data }) => {
    result.push({
      id: String(id),
      name: createTwoColumnsLayoutForYAxis(
        { data: title, className: 'yaxis-column-text-alert' },
        { data: createTimeMessageAboutEvent(from, to, t), className: 'yaxis-column-text-time' },
      ),
      milestone: moment(from).isSame(to) && true,
      description: createAlertDescription(message, t),
      shortDescription: createAlertShortDescription(data, rank, alias, t),
      start: moment(from).valueOf(),
      end: moment(to).valueOf(),
      color: colors[rank],
      pointWidth,
    });
  });

  return result;
}
