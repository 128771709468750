import React, { FC } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Icon, Stack } from '@mui/material';
import { Form, useFormikContext } from 'formik';
import { makeStyles } from '@mui/styles';
import { CSDInputStandard, CSDSelect } from '@app/modules/kit-module/shared/ui';
import { EMPTY_STRING, NUM_CHARS_PER_LINE, NUM_LINES } from '@app/v2/shared/constants';
import icons from '@app/assets/iconFont';
import { UsersAccess, ViewTypes, VMSTemplatesTypes } from '@app/v2/shared/enums';
import { useCheckAccess } from '@app/v2/shared/hooks';
import CSDTemplateFields from './CSDTemplateFields';

interface Props {
  onCloseDialog: Common.VoidCallBack;
  signsSelectors: Common.SelectorValues;
  configurationsSelectors: Common.SelectorValues;
  configuration: Scoreboards.NestedConfiguration;
  templateViewer: FC<{ template: Scoreboards.TemplateContent; templateType: VMSTemplatesTypes; viewType: ViewTypes }>;
  fetchConfiguration: (
    configurationId: number,
  ) => Promise<{ newConfiguration: Scoreboards.Configuration; newContent: (Scoreboards.Contents | Scoreboards.Contents[])[] }>;
}

const CSDTemplateMainForm = ({
  onCloseDialog,
  configuration,
  signsSelectors,
  fetchConfiguration,
  configurationsSelectors,
  templateViewer: TemplateViewer,
  t,
}: Props & I18N.TranslationFunction<'scoreboard'>) => {
  const classes = useStyles();

  const { accessChecker } = useCheckAccess();

  const { values, touched, errors, handleSubmit, handleChange, setFieldValue, handleBlur } = useFormikContext<Scoreboards.TemplateContent>();

  return (
    <Form onSubmit={handleSubmit} className={classes.wrapper}>
      <CSDInputStandard
        name="title"
        value={values.title}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched?.title ? errors?.title : EMPTY_STRING}
        error={touched?.title && Boolean(errors?.title)}
      />

      <CSDSelect
        label={t('templates.form.configuration')}
        disabled={!accessChecker([UsersAccess.VMS_FULL_EDIT])}
        value={values.configuration.id}
        items={configurationsSelectors}
        handleChange={nextConfigId => {
          fetchConfiguration(Number(nextConfigId)).then(({ newConfiguration, newContent }) => {
            setFieldValue('configuration', newConfiguration);
            setFieldValue('contents', newContent);
          });
        }}
      />

      {!!configuration?.items?.length &&
        configuration.items
          .map((item, index) => ({ ...item, key: index }))
          .map(({ key, ...configItem }, configurationItemIndex) => (
            <CSDTemplateFields
              key={key}
              templateType={configItem.type}
              signsSelectors={signsSelectors}
              fieldsOptions={{
                configurationItemIndex,
                numLines: Number(configItem[NUM_LINES]),
                numCharactersPerLine: Number(configItem[NUM_CHARS_PER_LINE]),
              }}
            />
          ))}

      <TemplateViewer template={values} templateType={VMSTemplatesTypes.Message} viewType={ViewTypes.Img} />

      <Stack direction="row" className={classes.actions}>
        <Button type="submit">
          <Icon>{icons.done}</Icon>
        </Button>
        <Button variant="error" onClick={onCloseDialog}>
          <Icon>{icons.close}</Icon>
        </Button>
      </Stack>
    </Form>
  );
};

const useStyles = makeStyles({
  wrapper: {
    gap: '1rem',
    display: 'flex',
    padding: '0.5rem',
    flexDirection: 'column',
    alignItems: 'center',
  },
  actions: {
    alignSelf: 'flex-start',
    gap: '1rem',
  },
});

export default withTranslation('scoreboard')(CSDTemplateMainForm);
