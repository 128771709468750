import { Theme } from '@mui/material/styles/createTheme';
import { makeStyles } from '@mui/styles';
import { MarkerColors } from '@app/v2/shared/constants';
import { AlertStatuses } from '@app/v2/shared/enums';

type MarkerStylesProps = {
  currentMarkerPosition?: number;
  alertStatus?: AlertStatuses;
  markersLength: number;
  badgeColor: string;
};

const useMarkerStyles = makeStyles<Theme, MarkerStylesProps>(({ palette: { common } }) => {
  const getCirclePartChildrenStyles = ({ markersLength }: MarkerStylesProps) => {
    const rotate = 360 / markersLength;
    const skewY = 90 - rotate;

    if (markersLength === 2) {
      return {
        '&:first-child': {
          transform: `rotate(0deg) translate(50%, 0)`,
          borderTop: 'unset',
          borderBottom: 'unset',
        },
        '&:nth-child(2)': {
          transform: `rotate(${rotate}deg) translate(50%, 0)`,
          borderTop: 'unset',
          borderBottom: 'unset',
        },
      };
    }
    return Array.from({ length: markersLength }).reduce<{ [key: string]: { transform: string } }>((result, _, index) => {
      const resKey = `&:nth-child(${index + 1})`;
      return {
        ...result,
        [resKey]: { transform: `rotate(${index * rotate}deg) skewY(${skewY}deg) translate(50%, 50%)` },
      };
    }, {});
  };

  const getCirclePartContentStyles = ({ markersLength }: MarkerStylesProps) => {
    const skewY = 90 - 360 / markersLength;

    if (markersLength === 2) {
      return {
        transformOrigin: 'center',
        transform: `rotate(-90deg) translate(-197%, 105%)`,
      };
    }

    return {
      transformOrigin: 'top left',
      transform: `skewY(${skewY > 0 ? '-' : ''}${Math.abs(skewY)}deg) rotate(-${360 / markersLength / 2}deg) translate(-50%, 100%)`,
    };
  };

  return {
    wrapper: {
      position: 'relative',
    },
    circle: {
      position: 'relative',
      padding: 0,
      margin: '1em auto',
      width: '5.125rem',
      height: '5.125rem',
      borderRadius: '50%',
      listStyle: 'none',
      overflow: 'hidden',
      transform: ({ currentMarkerPosition }: MarkerStylesProps) => `rotate(${currentMarkerPosition}deg)`,
      transition: 'transform .5s linear',
      border: '0.125rem solid white',
      zIndex: '1',

      '& > li': {
        border: '0.094rem solid white',
        background: 'linear-gradient(94.5deg, #072F9C -10.175%, #0F79DF 54.52%)',
        '&.expired': {
          background: 'linear-gradient(94.5deg, #747474 -10.175%, #B5B8BE 54.52%)',
        },
        '&.active': {
          background: 'white',
          '& .MuiIcon-root': {
            color: '#072F9C',
          },
        },
        '&.active.expired': {
          '& .MuiIcon-root': {
            color: '#747474',
          },
        },
      },
    },
    circlePart: props => ({
      cursor: 'pointer',
      overflow: 'hidden',
      position: 'absolute',
      top: 0,
      right: 0,
      width: '100%',
      height: '100%',
      '&:hover': {
        opacity: '0.8',
      },

      ...getCirclePartChildrenStyles(props),
    }),
    circlePartContent: props => ({
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      ...getCirclePartContentStyles(props),
    }),
    circlePartContentIcon: {
      '& .MuiIcon-root': {
        fontSize: '1rem',
        color: common.white,
      },
    },
    mainIconWrapper: {
      width: '1.688rem',
      height: '1.688rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 'calc((100% - 1.688rem) / 2)',
      left: 'calc((100% - 1.688rem) / 2)',
      backgroundColor: ({ alertStatus }: MarkerStylesProps) => MarkerColors[alertStatus]?.background,
      borderRadius: '50%',
      border: `0.125rem solid ${common.white}`,
      zIndex: 2,
    },
    mainIcon: {
      '&.MuiIcon-root': {
        fontSize: '1rem !important',
        color: ({ alertStatus }: MarkerStylesProps) => MarkerColors[alertStatus]?.text || common.white,
        zIndex: 3,
      },
    },
    mainBadge: {
      '& .MuiBadge-badge': {
        minWidth: '10px',
        height: '10px',
        fontSize: '9px',
        padding: 0,
        top: '3px',
        right: '2px',
        color: common.white,
        background: ({ badgeColor }: MarkerStylesProps) => badgeColor || '#439BFF',
        zIndex: 10,
      },
    },
    pointer: {
      position: 'absolute',
      bottom: 0,
      left: '1.56rem',
      color: ({ alertStatus }: MarkerStylesProps) => MarkerColors[alertStatus]?.background,
      '&.MuiIcon-root': {
        fontSize: '2rem',
      },
    },
    circleCloseBtn: {
      '&.MuiIconButton-root': {
        position: 'absolute',
        right: 0,
        '& .MuiIcon-root': {
          fontSize: '1.2rem',
        },
      },
    },
  };
});

export default useMarkerStyles;
