import React from 'react';
import { useTranslation, KeyPrefix } from 'react-i18next';
import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LayerTypes from '@app/modules/map-module/enums/LayerTypes';
import useMapProfileContext from '@app/v2/features/mapProfiles/hooks/useMapProfileContext';
import { isDefaultMapProfile } from '@app/v2/shared/helpers';

const layers: { label: KeyPrefix<'map'>; value: LayerTypes }[] = [
  { label: 'mapCardControl.layers.map', value: LayerTypes.YANDEX_MAP },
  { label: 'mapCardControl.layers.sputnik', value: LayerTypes.YANDEX_SATELLITE },
  { label: 'mapCardControl.layers.hybrid', value: LayerTypes.YANDEX_HYBRID },
  { label: 'mapCardControl.layers.traffic', value: LayerTypes.YANDEX_TRAFFIC },
];

const MapLayersControl = () => {
  const classes = useStyles();
  const { t } = useTranslation('map');
  const {
    currentActiveProfile,
    profileData: { activeLayer },
    handleChangeProfileActiveLayer,
  } = useMapProfileContext();

  return (
    <>
      {layers.map(layer => (
        <Chip
          className={classes.mapLayerItem}
          key={layer.value}
          label={t(layer.label)}
          disabled={isDefaultMapProfile(currentActiveProfile)}
          variant="outlined"
          {...(activeLayer === layer.value && {
            variant: 'filled',
            color: 'primary',
          })}
          onClick={() => handleChangeProfileActiveLayer(layer.value)}
        />
      ))}
    </>
  );
};

const useStyles = makeStyles(() => ({
  mapLayerItem: {
    '&.MuiChip-root': {
      marginRight: '0.4rem',
      marginBottom: '0.4rem',
      cursor: 'pointer',
    },
  },
}));

export default MapLayersControl;
