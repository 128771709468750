import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { ApolloClientOptions } from '@apollo/client/core/ApolloClient';
import { authMiddleware, httpLink, mockLink } from '@app/clients/apollo/links';
import onError from '@app/clients/apollo/onError';

const clientLinks = [onError, authMiddleware, httpLink];
const clientOptions: ApolloClientOptions<any> = {
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link: from(clientLinks),
  queryDeduplication: false,
};

const client = new ApolloClient(clientOptions);

export const mockApolloClient = new ApolloClient({ ...clientOptions, link: from([...clientLinks.slice(0, -1), mockLink]) });

export default client;
