import React from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, IconButton, Tooltip } from '@mui/material';
import icons from '@app/assets/iconFont';
import { useDialog } from '@app/v2/shared/hooks';
import { CSDConfirmDialog } from '@app/modules/kit-module/shared/ui';
import useMeteoChartProfileActions from '../../hooks/useMeteoChartProfileActions';

interface Props {
  profileId: string;
  setupId: string;
  icon?: string;
  tooltipTitle?: string;
}

const CSDDeleteMeteoChart = ({
  profileId,
  setupId,
  icon = icons.trash,
  tooltipTitle,
  t,
}: Props & I18N.TranslationFunction<'charts', 'meteoConstructor'>) => {
  const openConfirmDialog = useDialog(CSDConfirmDialog);

  const { handleDeleteChart } = useMeteoChartProfileActions();

  const handleDelete = () => {
    openConfirmDialog({ question: t('confirmationQuestionForDeleteChart') }).beforeClose.subscribe((answerToQuestion: boolean) => {
      if (!answerToQuestion) return;
      handleDeleteChart(profileId, setupId);
    });
  };

  return (
    <Tooltip title={tooltipTitle ?? t('deleteChart')}>
      <IconButton onClick={handleDelete}>
        <Icon>{icon}</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default withTranslation('charts', { keyPrefix: 'meteoConstructor' })(CSDDeleteMeteoChart);
