enum RoadAccident {
  dtpU = 'ДТП_У',
  dtpR = 'ДТП_Р',
  dtpDU = 'ДТП_ДУ',
  dtpO = 'ДТП_О',
  dtpZH = 'ДТП_Ж',
  dtpSH = 'ДТП_Ш',
  dtpV = 'ДТП_В',
  dtpP = 'ДТП_П',
}

export default RoadAccident;
