import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import { EMPTY_STRING } from '@app/v2/shared/constants';

type Props = {
  file: string;
  height?: number | string;
  width?: number | string;
  deleteImageHandler?: (file: string) => void;
};

const ViewImageBase64 = ({ file = EMPTY_STRING, height = 50, width = 50, deleteImageHandler }: Props) => {
  const classes = useStyles({ file, height, width });

  return (
    <div className={classes.wrapper}>
      {!!file && <div className={classes.image} />}

      {!!file && deleteImageHandler && (
        <button type="button" onClick={() => deleteImageHandler(file)} className={classes.deleteButton}>
          &times;
        </button>
      )}
    </div>
  );
};

const useStyles = makeStyles<Theme, Omit<Props, 'deleteImageHandler'>>(() => ({
  wrapper: {
    position: 'relative',
  },
  image: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: ({ height }) => height ?? 50,
    minWidth: ({ width }) => width ?? 50,
    backgroundImage: ({ file }) => `url(${file})`,
    transitionProperty: 'background-image',
    transitionDuration: '0.5s',
  },
  deleteButton: {
    width: 20,
    height: 20,
    position: 'absolute',
    right: 0,
    top: 0,
    fontWeight: 'bold',
    background: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    cursor: 'pointer',
    border: 'none',
    borderRadius: 5,
  },
}));

export default ViewImageBase64;
