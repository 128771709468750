import icons from '@app/assets/iconFont';

const statusDumpConfig: StaticConfigs.StatusDumpItem[] = [
  {
    name: 'statusDampTypes.noDataDash',
    iconName: icons.noData,
    value: 0,
  },
  {
    name: 'statusDampTypes.dry',
    iconName: icons.notRain,
    value: 1,
  },
  {
    name: 'statusDampTypes.moistened',
    iconName: icons.humidOne,
    value: 2,
  },
  {
    name: 'statusDampTypes.humid',
    iconName: icons.humidTwo,
    value: 3,
  },
  {
    name: 'statusDampTypes.wet',
    iconName: icons.humidThree,
    value: 4,
  },
  {
    name: 'statusDampTypes.flowingWater',
    iconName: icons.humidFour,
    value: 5,
  },
  {
    name: 'statusDampTypes.ice',
    iconName: icons.ice,
    value: 6,
  },
  {
    iconName: icons.chemicals,
    name: 'statusDampTypes.chemicalResidues',
    value: 7,
  },
  {
    iconName: icons.humidFive,
    name: 'statusDampTypes.water',
    value: 8,
  },
  {
    iconName: icons.waterChemicals,
    name: 'statusDampTypes.waterAndReagents',
    value: 9,
  },
  {
    iconName: icons.nobody,
    name: 'statusDampTypes.statusUndefined',
    value: 10,
  },
];

export default statusDumpConfig;
