import icons from '@app/assets/iconFont';
import { DialogTabsTypes, UsersAccess } from '@app/v2/shared/enums';
import MeteoTab from '../ui/Tabs/MeteoTab/MeteoTab';
import TrafficTab from '../ui/Tabs/TrafficTab/TrafficTab';
import VideoTab from '../ui/Tabs/VideoTab/VideoTab';
import PlaylistTab from '../ui/Tabs/playlist/PlaylistTab';
import WaterLevelTab from '../ui/Tabs/WaterLevelTab/WaterLevelTab';
import type { TabType } from '../types';

export const allTabs: TabType[] = [
  {
    label: 'post.tabs.meteo',
    id: DialogTabsTypes.METEO_CONTROL,
    component: MeteoTab,
    nameInPlace: 'meteoStation',
    view: [UsersAccess.PAGE_METEO_POPUP],
    icon: icons.sky,
  },
  {
    label: 'post.tabs.video',
    id: DialogTabsTypes.VIDEO,
    component: VideoTab,
    nameInPlace: 'videoStations',
    view: [UsersAccess.PAGE_VIDEO_POPUP],
    icon: icons.camera,
  },
  {
    label: 'post.tabs.traffic',
    id: DialogTabsTypes.TRAFFIC,
    component: TrafficTab,
    nameInPlace: 'trafficStations',
    view: [UsersAccess.PAGE_TRAFFIC_POPUP],
    icon: icons.traffic,
  },
  {
    label: 'post.tabs.templates',
    id: DialogTabsTypes.PLAYLIST,
    component: PlaylistTab,
    nameInPlace: 'messageStations_v2',
    view: [UsersAccess.VMS_LIMIT_EDIT, UsersAccess.VMS_FULL_EDIT],
  },
  {
    label: 'post.tabs.waterLevelControl',
    id: DialogTabsTypes.WATER_LEVEL_CONTROL,
    component: WaterLevelTab,
    nameInPlace: 'wlcStations',
    view: [UsersAccess.PAGE_SCLR_POPUP],
    icon: icons.river,
  },
];

export const MISSING = 'Отсутствует';

export const SAVE = 'save';
export const ERROR = 'Error';
export const ON_CLOSE = 'onClose';
