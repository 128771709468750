import React from 'react';
import { IconButton, Icon, Tooltip } from '@mui/material';
import { useMutation } from '@apollo/client';
import { DocumentNode } from 'graphql/language/ast';
import icons from '@app/assets/iconFont';
import { addFeatureActions } from '@app/v2/shared/utils';
import { EMPTY_STRING } from '@app/v2/shared/constants';

interface Props extends Common.FeaturesActions {
  id: number;
  tooltipTitle?: string;
  query: DocumentNode;
  disabled?: boolean;
}

const CSDDeleteScoreboardEntity = ({ id, query, tooltipTitle, disabled, ...featureActions }: Props) => {
  const [onDelete, { loading }] = useMutation<Common.MutationResponse, { id: number }>(query);

  const handleDelete = () => {
    addFeatureActions(onDelete({ variables: { id } }), featureActions);
  };

  return (
    <Tooltip title={tooltipTitle ?? EMPTY_STRING}>
      <IconButton disabled={disabled || loading} onClick={handleDelete}>
        <Icon color={disabled ? 'disabled' : 'error'}>{icons.trash}</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default CSDDeleteScoreboardEntity;
