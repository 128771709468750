import React from 'react';
import { Stack } from '@mui/material';
import CSDKitDocBlock from '@app/modules/kit-module/shared/ui/CSDKitDocBlock/CSDKitDocBlock';
import { CSDAlert } from '@app/modules/kit-module/shared/ui';
import { ErrorAlert, SuccessAlert, WarningAlert, InfoAlert } from './example';

const CSDAlerts = () => {
  const exempleText = 'Какой то блинный текст который будет занимать несколько строчек и читать его будет не быстро';

  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="Error alert" codeExample={ErrorAlert}>
        <CSDAlert severity="error" actionButtonTitle="Действие" isHaveCloseIcon>
          {exempleText}
        </CSDAlert>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Success alert" codeExample={SuccessAlert}>
        <CSDAlert severity="success" actionButtonTitle="Действие" isHaveCloseIcon>
          {exempleText}
        </CSDAlert>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Warning alert" codeExample={WarningAlert}>
        <CSDAlert severity="warning" actionButtonTitle="Действие" isHaveCloseIcon>
          {exempleText}
        </CSDAlert>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Info alert" codeExample={InfoAlert}>
        <CSDAlert severity="info" actionButtonTitle="Действие" isHaveCloseIcon>
          {exempleText}
        </CSDAlert>
      </CSDKitDocBlock>
    </Stack>
  );
};

export default CSDAlerts;
