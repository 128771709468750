import React, { MouseEvent, useCallback } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import usePreparedHeaders from '@app/modules/log-module/common/hooks/usePreparedHeaders';
import { HeaderCellValue } from '@app/modules/log-module/common/types/ListingTypes';

type Props = {
  headers: HeaderCellValue[];
  row: { [key: string]: any };
  setIsOpen?: (prev: (prev: boolean) => boolean) => void;
  isOpen?: boolean;
};

const ListingCard = ({ headers, row, isOpen, setIsOpen }: Props) => {
  const { prepared, getText } = usePreparedHeaders(headers);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setIsOpen && setIsOpen(prev => !prev);
    },
    [setIsOpen],
  );

  return (
    <NoteCard variant="outlined" onClick={handleClick}>
      <NoteContent>
        {prepared.map((item, index) => {
          const content = getText(row, item);
          const key = `${row.id}-${item.label}-${index}`;
          return (
            <Typography key={key} component="div" variant="body2">
              {content}
            </Typography>
          );
        })}
      </NoteContent>
    </NoteCard>
  );
};

const NoteContent = styled(CardContent)(`
  &:last-child {
    padding-bottom: 0rem;
    margin-bottom: 0rem;
    margin-top: 0rem;
  }
`);

const NoteCard = styled(Card)(`
  padding: 1rem;
  padding-bottom: 0.5rem;
  align-content: center;
`);

export default ListingCard;
