import { QueryOptions } from '@apollo/client';
import type { OperationVariables } from '@apollo/client/core/types';
import client from '@app/clients/apollo/client';
import LoadingManager from '@app/core/source/manages/Loading';

export default abstract class BaseDataSource {
  loading = new LoadingManager();

  client = {
    query: <Response, Variables extends OperationVariables = OperationVariables>(options: QueryOptions<Variables, Response>) => {
      this.abort();
      return client.query<Response, Variables>({
        ...options,
        context: {
          fetchOptions: { signal: this.abortController.signal },
        },
      });
    },
  };

  private abortController = new AbortController();

  protected abort() {
    this.abortController.abort();
    this.abortController = new AbortController();
  }
}
