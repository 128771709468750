import React from 'react';
import { useTranslation } from 'react-i18next';
import { CSDChipsBlock } from '@app/modules/kit-module/shared/ui';
import { FilterReactionsConfig } from '@app/v2/shared/configs';
import { FiltersReactions, FiltersKeys } from '@app/v2/shared/enums';
import { strictlyEqual } from '@app/v2/shared/helpers';

const CSDReactionFilter = ({ filters, onChange }: Filters.AdditionalFiltersProps) => {
  const { t } = useTranslation('filters');

  const handleChange = (nextValue: FiltersReactions) => {
    onChange(FiltersKeys.Reaction, strictlyEqual(nextValue, filters.reaction) ? null : nextValue);
  };

  return (
    <CSDChipsBlock
      size="small"
      titleVariant="subtitle2"
      title={t('reaction.label')}
      withEndDivider
      chipsConfig={FilterReactionsConfig.map(({ label, value, dataTestId }) => ({
        id: label,
        label,
        onClick: () => handleChange(value),
        isActive: strictlyEqual(value, filters.reaction),
        dataTestId,
      }))}
    />
  );
};

export default CSDReactionFilter;
