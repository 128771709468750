import React from 'react';
import { Stack } from '@mui/material';
import { DataTestIds } from '@app/v2/shared/enums';
import { createEmptyArray } from '@app/v2/shared/utils';
import TemplateParagraphForm from './TemplateParagraphForm';

const TemplateParagraphsForm = ({ numLines, contentIndex, numCharactersPerLine }: Scoreboards.Options) => {
  return (
    <Stack sx={{ width: '100%', gap: '1rem' }} data-testid={DataTestIds.TemplateMessageFields}>
      {createEmptyArray(numLines)
        .map((_, index) => ({ field: TemplateParagraphForm, key: index }))
        .map(({ field: Field, key }, fieldIndex) => (
          <Field key={key} index={key} contentIndex={contentIndex} numCharactersPerLine={numCharactersPerLine} />
        ))}
    </Stack>
  );
};

export default TemplateParagraphsForm;
