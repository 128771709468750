export enum ProfilesTypes {
  meteo = 'meteo',
  traffic = 'traffic',
  scoreboard = 'scoreboard',
  planGraph = 'meteoPlanGraph',
  linearGraph = 'meteoLinearGraph',
  sops = 'sops',
  adaptationToClimateChange = 'dpgt',
  stationDetails = 'sd',
}

export enum ChartsProfilesTypes {
  meteo = 'meteo',
}

export enum ProfileActions {
  SetActive = 'setActive',
  AddNewProfile = 'addNewProfile',
  ActivateSaveMod = 'activateSaveMod',
  DeactivateSaveMod = 'deactivateSaveMod',
}
