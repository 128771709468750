import React, { useState, memo } from 'react';
import { Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import { useQuery } from '@apollo/client';
import { CSDSelect } from '@app/modules/kit-module/shared/ui';
import vmsNewQueries from '@app/clients/apollo/requests/queries/vmsNew';
import { EMPTY_STRING } from '@app/v2/shared/constants';
import ViewImageBase64 from '@app/components/view-image-base64/ViewImageBase64';
import { isFunction } from '@app/v2/shared/helpers';
import { DataTestIds } from '@app/v2/shared/enums';

interface Props extends Scoreboards.TemplateFieldsOptions {
  signsSelectors: Common.SelectorValues;
}

const CSDSignField = ({ configurationItemIndex, signsSelectors }: Props) => {
  const [fileForView, setFileForView] = useState<string>(EMPTY_STRING);

  const { values, setFieldValue } = useFormikContext<Scoreboards.TemplateContent>();

  const { data: initialFileForViewData, refetch: fetchSignById } = useQuery<Scoreboards.SignResponse, Scoreboards.SignVariables>(vmsNewQueries.sign, {
    skip: Boolean(fileForView),
    variables: {
      id: values.contents?.[configurationItemIndex]?.signId,
    },
  });

  const onChangeSign = (nextSignId: string) => {
    if (!isFunction(fetchSignById)) return;

    fetchSignById({ id: Number(nextSignId) }).then(({ data }) => {
      if (!data) return;

      const { signFile } = data?.vmsNew?.templates?.sign?.content ?? {};
      setFileForView(signFile);
      setFieldValue(`contents[${configurationItemIndex}]`, { signFile, signId: nextSignId });
    });
  };

  const initialFileForView = initialFileForViewData?.vmsNew?.templates?.sign?.content?.signFile;

  return (
    <Stack direction="row" gap="0.5rem" width="100%" data-testid={DataTestIds.TemplateSignField}>
      <CSDSelect
        name={`contents[${configurationItemIndex}].signId`}
        value={values.contents[configurationItemIndex]?.signId}
        items={signsSelectors}
        handleChange={onChangeSign}
      />

      {(fileForView || initialFileForView) && <ViewImageBase64 height="100%" width="40px" file={fileForView || initialFileForView} />}
    </Stack>
  );
};

export default memo(CSDSignField);
