import { createContext } from 'react';
import { ProfileMapSettingLabels } from '@app/v2/shared/enums';
import { State } from '@store/slices/map/MapState';
import { initialState } from '@store/slices/map';
import { MapProfilesData, MapProfilesStateValue, MapProfilesContext } from '../types';

const MapProfileContext = createContext<MapProfilesContext>({
  isEdited: false,
  isCreateProfileMode: false,
  activeProfile: null,
  currentActiveProfile: null,
  profiles: null,
  profileData: Object.fromEntries(
    Object.values(ProfileMapSettingLabels).map<[keyof State, MapProfilesStateValue]>(key => [key, initialState[key]]),
  ) as MapProfilesData,
  handleChangeProfileCheckbox: () => {},
  handleSelectAllSettingByKeys: () => {},
  handleChangeProfileActiveLayer: () => {},
  handleChangeLocators: () => {},
  handleChangeProfile: () => {},
  handleSaveCoordinates: () => {},
  handleCancelProfileCreation: () => {},
  handleSetIsDefault: () => {},
  handleSetNewProfile: () => {},
  handleChangeProfileLabel: () => {},
  handleApply: () => {},
  handleSaveChanges: () => {},
  handleRemove: () => {},
  handleReset: () => {},
  handleChangeProfileMarkerZooms: () => {},
});

export default MapProfileContext;
