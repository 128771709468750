import React, { ReactNode } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/ru';
import { useAppSelector } from '@app/v2/shared/hooks';
import { Languages } from '@app/v2/shared/enums';

type Props = {
  Component: ReactNode;
};

const CSDRootDatePicker = ({ Component }: Props) => {
  const { locale } = useAppSelector(state => state.account.settings);

  const adapterLocale = locale?.toLocaleLowerCase() || Languages.RU.toLocaleLowerCase();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={adapterLocale}>
      {Component}
    </LocalizationProvider>
  );
};

export default CSDRootDatePicker;
