import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { useFormikContext } from 'formik';
import Alert from '@mui/lab/Alert';
import { Icon, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Field from '@app/modules/map-module/components/map/notes/form/Field';
import CustomSelectComponent from '@app/components/common/custom-form-component/CustomSelectComponent';
import { MapNoteInput, RoadAddressType } from '@app/modules/map-module/components/map/notes/types';
import client from '@app/clients/apollo/client';
import { showError } from '@app/core/utils/notifications';
import ErrorMessages from '@app/core/constants/errorsMessages';
import mapNoteQueries from '@app/clients/apollo/requests/queries/notes';
import roadQueries from '@app/clients/apollo/requests/queries/roads';
import icons from '@app/assets/iconFont';
import { meterZeroes } from '@app/v2/shared/helpers';

export type Values = {
  roadId: number;
  latitude: number;
  longitude: number;
  kilometer: number;
  meter: number;
};

type roadAddressResponse = {
  note: {
    roadAddress: RoadAddressType[];
  };
};

type Props = {
  values: Values;
};

type Roads = {
  label: string;
  value: number;
};

type RoadsResponse = {
  roads: [Roads] | [];
  loading: boolean;
};

type RoadAddressListType = {
  items: RoadAddressType[];
  loading: boolean;
};

const initRoadAddress: RoadAddressType = {
  road: {
    id: null,
    title: null,
  },
  kilometer: null,
  meter: null,
};

const RoadAddress = ({ values: { roadId: selectedRoadId, latitude, longitude, kilometer, meter } }: Props) => {
  const { setFieldValue } = useFormikContext<MapNoteInput & { roadAddress?: any }>();
  const { t } = useTranslation('map', { keyPrefix: 'notes.form' });
  const { t: unitsT } = useTranslation('common', { keyPrefix: 'units' });

  const classes = useStyles();

  const [roadAddressState, setRoadAddress] = useState<RoadAddressType>({ road: { id: selectedRoadId }, kilometer, meter });
  const [roadAddressList, setRoadAddressList] = useState<RoadAddressListType>({ items: [], loading: true });
  const [roadsState, setRoadsState] = useState<RoadsResponse>({ roads: [], loading: true });
  const [roadTitle, setRoadTitle] = useState<string>('');
  const [isRoadFieldDisabled, setIsRoadFieldDisabled] = useState<boolean>(false);

  const roadInformation = roadsState.loading
    ? t('roadLoading')
    : roadTitle + (kilometer != null && meter != null ? ` ${unitsT('kilometers')} ${kilometer}+${meterZeroes(meter)}` : '');

  const roadId = roadAddressState?.road?.id;
  const { loading: listLoading, items: addressList } = roadAddressList;

  const addressListIsEmpty = useMemo(() => Boolean(!listLoading && (!addressList || !addressList?.length)), [addressList, listLoading]);
  const [showFillingFieldsAlert, setShowFillingFieldsAlert] = useState<boolean>(false);

  const normalizeRoadAddressList = (addresses: RoadAddressType[]): { value: number; label: string }[] =>
    addresses.map(({ road: { title }, kilometer: km, meter: m }, i) => ({ value: i, label: `${title} ${km} + ${meterZeroes(m)}` }));

  const updateRoadAddress = useCallback(
    (address: RoadAddressType) => {
      setRoadAddress(address);
      setFieldValue('roadId', address.road.id);
      setFieldValue('place.kilometer', address.kilometer);
      setFieldValue('place.meter', address.meter);
    },
    [setFieldValue],
  );

  const fetchRoads = useCallback(() => {
    client
      .query<RoadsResponse>({ query: roadQueries.roads, variables: { roadIds: roadId ? [roadId] : [] } })
      .then(({ data: { roads, loading } }) => {
        if (roads?.length) {
          setRoadsState({ roads, loading });
        }
      })
      .catch(() => {
        showError(ErrorMessages.COMMON_ERROR_MESSAGE);
      });
  }, [roadId]);

  const onChangeRoadHandler = useCallback(
    (v: number | null) => {
      if (v === null) {
        setFieldValue('roadId', null);
      } else {
        const selectedAddress = addressList[v];
        if (selectedAddress) {
          updateRoadAddress(selectedAddress);
          setShowFillingFieldsAlert(false);
          setFieldValue('roadId', selectedAddress.road.id);
        }
      }
    },
    [addressList, setFieldValue, updateRoadAddress],
  );

  const onInputChangeHandler = useCallback(
    (reason: string) => {
      if (reason === 'clear') {
        updateRoadAddress(initRoadAddress);
      }
      if (reason === 'clear' || reason === 'reset') {
        setShowFillingFieldsAlert(false);
      }
    },
    [updateRoadAddress],
  );

  useEffect(() => {
    fetchRoads();
  }, [fetchRoads]);

  const [roadAddressDataResponse, setRoadAddressDataResponse] = useState<RoadAddressType[]>(null);

  useEffect(() => {
    if (roadAddressDataResponse && roadAddressDataResponse.length > 0) {
      setRoadAddressList({ items: roadAddressDataResponse, loading: false });
      if (!selectedRoadId && roadAddressDataResponse.length === 1) {
        const firstRoadAddress = roadAddressDataResponse[0];
        updateRoadAddress(firstRoadAddress);
        setShowFillingFieldsAlert(true);
      } else {
        const roadAddressIndex = roadAddressDataResponse.findIndex(({ road: { id } }) => id === selectedRoadId);
        if (roadAddressIndex !== -1) {
          setShowFillingFieldsAlert(false);
        }
      }
    }
  }, [roadAddressDataResponse, selectedRoadId, updateRoadAddress]);

  useEffect(() => {
    const fetchRoadAddress = () => {
      client
        .query<roadAddressResponse>({
          query: mapNoteQueries.noteRoadAdress,
          variables: {
            longitude: Number(longitude),
            latitude: Number(latitude),
          },
        })
        .then(({ data }) => {
          setRoadAddressDataResponse(data?.note?.roadAddress);
        })
        .catch(() => {
          showError(ErrorMessages.COMMON_ERROR_MESSAGE);
        });
    };

    fetchRoadAddress();
  }, [latitude, longitude]);

  useEffect(() => {
    if (selectedRoadId !== roadId && selectedRoadId !== null) {
      setFieldValue('roadAddress', null);
      setFieldValue('place.kilometer', null);
      setFieldValue('place.meter', null);
    }
  }, [selectedRoadId, roadId, setFieldValue]);

  useEffect(() => {
    const roadFieldCompleteness = roadsState.roads.find(r => r.value === selectedRoadId);
    setRoadTitle(roadFieldCompleteness ? roadFieldCompleteness.label : t('roadNotFound'));
  }, [selectedRoadId, roadsState.roads, t]);

  useEffect(() => {
    setIsRoadFieldDisabled(roadAddressDataResponse && roadAddressDataResponse.length > 0);
  }, [roadAddressDataResponse]);

  return (
    <>
      <Grid item xs justifyContent="center">
        <Typography variant="body1" className={classes.roadAddressFont}>
          <Icon className={classes.addressIcon}>{icons.road}</Icon>
          {roadInformation}
        </Typography>
      </Grid>

      <Grid item xs>
        <Field name="roadId" placeholder={t('road')} component={CustomSelectComponent} values={roadsState.roads} disabled={isRoadFieldDisabled} />
      </Grid>

      <Grid item xs>
        <Field
          name="roadAddress"
          placeholder={t('roadAddress')}
          component={CustomSelectComponent}
          values={normalizeRoadAddressList(addressList)}
          onChange={onChangeRoadHandler}
          onInputChange={onInputChangeHandler}
        />
      </Grid>

      {addressListIsEmpty && (
        <Grid item xs={8}>
          <Alert severity="warning">{t('addressAlert')}</Alert>
        </Grid>
      )}

      {showFillingFieldsAlert && (
        <Grid item xs={8}>
          <Alert severity="warning">{t('fillingFieldsAlert')}</Alert>
        </Grid>
      )}
    </>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  roadAddressFont: {
    '&&': {
      color: theme.palette.info.main,
      lineHeight: '1.5rem',
    },
  },
  addressIcon: {
    '&&': {
      marginRight: '0.2rem',
      verticalAlign: 'middle',
      color: theme.palette.primary.main,
    },
  },
}));

export default React.memo(RoadAddress);
