import { useMemo } from 'react';
import { MISSING } from '../constants';

export default function useDialogViewParams(params) {
  const { activeTab, placeData, activeStationID } = params;

  const stationsPlaceData = useMemo<{ id: number; owner: string } | { id: number; owner: string }[]>(() => {
    if (activeTab && placeData && placeData[activeTab.nameInPlace]) {
      return placeData[activeTab.nameInPlace];
    }

    return null;
  }, [activeTab, placeData]);

  const listOfStationsIdOfActiveTab = useMemo(() => {
    if (stationsPlaceData) {
      return Array.isArray(stationsPlaceData) ? stationsPlaceData.map(({ id }) => +id) : [+stationsPlaceData.id];
    }
    return [];
  }, [stationsPlaceData]);

  const owner = useMemo<string>(() => {
    if (stationsPlaceData) {
      if (Array.isArray(stationsPlaceData)) {
        return stationsPlaceData.find(item => item.id === activeStationID)?.owner || MISSING;
      }
      return stationsPlaceData?.owner || MISSING;
    }

    return null;
  }, [stationsPlaceData, activeStationID]);

  return { listOfStationsIdOfActiveTab, owner };
}
