export enum MeterLength {
  OneDigit = 1,
  TwoDigits = 2,
  ThreeDigits = 3,
  FourDigits = 4,
}

export enum AddressType {
  Coordinates = 'coordinates',
  RoadAddress = 'roadAddress',
}
