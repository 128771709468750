import CREATE_METEO_CHART_PROFILE from './createMeteoChartProfile.graphql';
import UPDATE_METEO_CHART_PROFILE from './updateMeteoChartProfile.graphql';
import DELETE_METEO_CHART_PROFILE from './deleteMeteoChartProfile.graphql';
import UPDATE_METEO_CHART_SETTINGS from './updateMeteoGraphSettings.graphql';

const meteoMutations = {
  createMeteoChartProfile: CREATE_METEO_CHART_PROFILE,
  updateMeteoChartProfile: UPDATE_METEO_CHART_PROFILE,
  deleteMeteoChartProfile: DELETE_METEO_CHART_PROFILE,
  updateMeteoChartSettings: UPDATE_METEO_CHART_SETTINGS,
};

export default meteoMutations;
