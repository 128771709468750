import React, { useCallback } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Stack, Box, Icon, IconButton, IconButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import icons from '@app/assets/iconFont';
import useNotificationProfileContext from '@app/v2/features/notificationProfile/context/useNotificationProfileContext';
import useDialog from '@app/core/hooks/useDialog';
import ConfirmDialog from '@app/components/dialogs/confirm-dialog/ConfirmDialog';

const PofileManagerActions = ({ t }: WithTranslation<'profiles'>) => {
  const { currentProfile, isEdit, isLoading, resetChanges, removeProfile, saveProfile } = useNotificationProfileContext();

  const openConfirmDialog = useDialog(ConfirmDialog);

  const handleRomoveProfile = useCallback(() => {
    openConfirmDialog({ question: t('questions.deleteProfile') }).beforeClose.subscribe((answerToQuestion: boolean): void => {
      if (!answerToQuestion) return;

      removeProfile(currentProfile.id);
    });
  }, [currentProfile, removeProfile, openConfirmDialog, t]);

  const handleResetProfile = useCallback(() => {
    openConfirmDialog({ question: t('questions.resetProfile') }).beforeClose.subscribe((answerToQuestion: boolean): void => {
      if (!answerToQuestion) return;

      resetChanges(currentProfile.id);
    });
  }, [currentProfile, resetChanges, openConfirmDialog, t]);

  return (
    <Box display="flex" justifyContent="space-between">
      <Stack direction="row" spacing={1}>
        <StyledIconButton disabled={!isEdit || isLoading} onClick={() => saveProfile(currentProfile)}>
          <Icon>{icons.save}</Icon>
        </StyledIconButton>
        <StyledIconButton disabled={!isEdit || isLoading} onClick={handleResetProfile}>
          <Icon>{icons.refresh}</Icon>
        </StyledIconButton>
      </Stack>
      <StyledIconButton disabled={isLoading} onClick={handleRomoveProfile}>
        <Icon>{icons.trash}</Icon>
      </StyledIconButton>
    </Box>
  );
};

const StyledIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  padding: '0.5rem',
  border: `1px solid ${theme.palette.text.t1}`,
  borderRadius: '0.5rem',
  '&.Mui-disabled': {
    border: `1px solid ${theme.palette.text.t5}`,
  },
}));

export default withTranslation('profiles')(PofileManagerActions);
