import React from 'react';
import { makeStyles } from '@mui/styles';
import { UsersAccess } from '@app/v2/shared/enums';
import AddFormButton from '@app/modules/scoreboard-module/playlists/form/PlaylistFormActions/AddFormButton';
import CreateTemplateButton from '@app/modules/scoreboard-module/playlists/form/PlaylistFormActions/CreateTemplateButton';
import { useCheckAccess } from '@app/v2/shared/hooks';

type Props = {
  push: Common.AnyFunction;
  playlist: Scoreboards.Template[];
  lastConfigId: number | null;
};

const PlaylistsMainFormActions = ({ push, playlist, lastConfigId }: Props) => {
  const classes = useStyles();
  const { accessChecker } = useCheckAccess();

  return (
    <div className={classes.actionsButtonsWrapper}>
      {accessChecker([UsersAccess.VMS_LIMIT_EDIT, UsersAccess.VMS_FULL_EDIT]) && (
        <AddFormButton push={push} playlist={playlist} lastConfigId={lastConfigId} />
      )}
      {accessChecker([UsersAccess.VMS_FULL_EDIT]) && <CreateTemplateButton />}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  actionsButtonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,

    '& button': {
      marginRight: 15,
    },
  },
}));

export default PlaylistsMainFormActions;
