import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableVirtuosoProps } from 'react-virtuoso';
import { Icon, LinearProgress, TableProps } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import get from 'lodash/get';
import { typedMemo } from '@app/v2/shared/utils';
import { isFunction, strictlyEqual } from '@app/v2/shared/helpers';
import icons from '@app/assets/iconFont';
import usePaginationSearch from '@app/core/source/hooks/pagination/usePaginationSearch';
import { LISTING_DEFAULT_PAGE_NUMBER } from '@app/core/constants';
import { DataGridVirtualizedContentMode, SortStatus, TableHighlightMode } from '@app/v2/shared/enums';
import { useCheckAccess } from '@app/v2/shared/hooks';
import { CSDTableSkeleton } from '@app/v2/shared/ui/skeletons';
import DataGridVirtualized from './DataGridVirtualized';
import DataGridCSDTable from './DataGridCSDTable';
import { TableData, Header, Row, TableDataGroup } from './types';
import FlatAdapter from './adapters/FlatAdapter';
import GroupAdapter from './adapters/GroupAdapter';
import useTableHeader from './hooks/useTableHeader';
import CSDGroupCell from '../../CSDTableCells/CSDGroupCell';
import CSDTableCell from '../../CSDTableCells/CSDTableCell';

interface Props<TableDataItem extends TableData, Station> {
  columns: Header<TableDataItem>[];
  data: Station[] | Station;
  isDataLoading?: boolean;
  nextPageLoading?: boolean;
  isVirtualize?: boolean;
  tableProps?: Table.CustomTableProps | TableVirtuosoProps<Row<TableDataItem>, unknown>;
  adapter: GroupAdapter<TableDataItem, Station> | FlatAdapter<TableDataItem, Station>;
  contentMode?: DataGridVirtualizedContentMode;
  highlightMode?: TableHighlightMode;
  scrollToRowCondition?: (row: Row<TableDataItem>) => boolean;
  onSorting?: (key: string, value: SortStatus) => void;
  sortingValue?: Common.Sorting;
  isWrapHeaderText?: boolean;
}

const converToFlat = <Data extends TableData>(data: TableDataGroup<Data>[]): Row<Data>[] =>
  data.reduce((result, current, index, array) => {
    return [
      ...result,
      ...current.items,
      array[index + 1]
        ? {
            top: { show: true, text: array[index + 1]?.header },
            bottom: { show: true, text: current?.header },
          }
        : {
            bottom: { show: true, text: current?.header },
          },
    ];
  }, []);

function isGroup<TableDataItem extends TableData, Station>(
  adapter: GroupAdapter<TableDataItem, Station> | FlatAdapter<TableDataItem, Station>,
): adapter is GroupAdapter<TableDataItem, Station> {
  return adapter instanceof GroupAdapter;
}

const CSDDataGrid = <TableDataItem extends TableData, Station = TableDataItem>({
  columns,
  adapter,
  isVirtualize = false,
  data,
  tableProps,
  isDataLoading,
  nextPageLoading,
  scrollToRowCondition,
  onSorting,
  sortingValue,
  isWrapHeaderText = false,
  ...other
}: Props<TableDataItem, Station>) => {
  const { t: notificationT } = useTranslation('common', { keyPrefix: 'notificationMessages' });
  const { accessChecker } = useCheckAccess();
  const [pagination] = usePaginationSearch();
  const tableRef = React.useRef(null);
  const previousPaginationPageRef = React.useRef<number>(0);

  const columnsWithAccess = useMemo(
    () => columns.filter(({ permissions }) => (Array.isArray(permissions) ? accessChecker(permissions) : true)),
    [accessChecker, columns],
  );
  const { headerRows, headerColumns } = useTableHeader(columnsWithAccess);

  React.useEffect(() => {
    if (
      isVirtualize &&
      tableRef?.current &&
      pagination.page === LISTING_DEFAULT_PAGE_NUMBER &&
      previousPaginationPageRef.current !== pagination.page
    ) {
      tableRef.current.scrollTo(0, 0);
    }

    if (!isVirtualize && tableRef?.current) {
      tableRef.current.scrollTo(0, 0);
    }
    previousPaginationPageRef.current = pagination.page;
  }, [tableRef, pagination, isVirtualize]);

  if (isDataLoading) {
    return <CSDTableSkeleton />;
  }

  const adaptedRowData = isGroup<TableDataItem, Station>(adapter) ? converToFlat<TableDataItem>(adapter.transform(data)) : adapter.transform(data);

  const getRowCells = (rowIndex: number, rowData: TableDataItem) => {
    try {
      if (rowData?.top || rowData?.bottom) {
        return (
          <CSDGroupCell
            colSpan={headerColumns.length}
            topHeaderSettings={rowData.top}
            bottomHeaderSettings={rowData.bottom}
            isLastGroupCellOnTable={strictlyEqual<number>(rowIndex, adaptedRowData.length - 1)}
          />
        );
      }

      return headerColumns.map(({ field, renderCell, onClick, tdProps, formatter, columnIndex }) => {
        if (renderCell) {
          return renderCell(rowData as TableDataItem, rowIndex, columnIndex);
        }

        const cellData = formatter && isFunction(formatter) ? formatter(rowData as TableDataItem) : get(rowData, field);

        return (
          <CSDTableCell
            key={`${field}-${rowIndex}`}
            columnIndex={columnIndex}
            rowIndex={rowIndex}
            {...tdProps}
            onClick={() => typeof onClick === 'function' && onClick(rowData as TableDataItem)}
            data-testid={`data-grid-table-row-${rowIndex}-cell-${columnIndex}`}
          >
            {(cellData as string) ?? <Icon>{icons.noData}</Icon>}
          </CSDTableCell>
        );
      });
    } catch {
      return (
        <TableCell sx={{ background: 'red', color: 'white', paddingLeft: 2 }} colSpan={headerColumns.length}>
          {notificationT('noData')}
        </TableCell>
      );
    }
  };

  return isVirtualize ? (
    <>
      <DataGridVirtualized
        columns={headerColumns}
        headerRows={headerRows}
        tableData={adaptedRowData}
        getRowCells={getRowCells}
        scrollerRef={scrollerRef => {
          tableRef.current = scrollerRef;
        }}
        {...(tableProps as TableVirtuosoProps<Row<TableDataItem>, unknown>)}
        {...other}
      />

      {nextPageLoading && <LinearProgress sx={{ width: '100%' }} />}
    </>
  ) : (
    <DataGridCSDTable<TableDataItem>
      ref={tableRef}
      onSorting={onSorting}
      headerRows={headerRows}
      getRowCells={getRowCells}
      tableData={adaptedRowData}
      sortingValue={sortingValue}
      scrollToRowCondition={scrollToRowCondition}
      isWrapHeaderText={isWrapHeaderText}
      {...(tableProps as TableProps)}
    />
  );
};

export default typedMemo(CSDDataGrid);
