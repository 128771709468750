import groupBy from 'lodash/groupBy';

const transformToFlatData = (columns, depth = 0) => {
  return columns.reduce((acc, column) => {
    const col = { ...column, depth };
    acc.push(col);

    if (Object.prototype.hasOwnProperty.call(column, 'children')) {
      return [...acc, ...transformToFlatData(column.children, depth + 1)];
    }

    return acc;
  }, []);
};

export default function useTableHeader(data) {
  const flatHeaderData = transformToFlatData(data);

  const columnsWithData = flatHeaderData.reduce((acc, currentColumn) => {
    if (!Object.prototype.hasOwnProperty.call(currentColumn, 'children')) {
      const columnWithIndex = { ...currentColumn, columnIndex: acc.length };
      acc.push(columnWithIndex);
    }

    return acc;
  }, []);

  let columnIndex = 0;
  const allColumns = flatHeaderData.map(column => {
    if (column.children?.length) {
      return column;
    }
    const result = { ...column, columnIndex };
    columnIndex += 1;
    return result;
  });

  const headerRows = groupBy(allColumns, 'depth');

  return {
    headerRows,
    headerColumns: columnsWithData,
  };
}
