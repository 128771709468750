import React, { useEffect } from 'react';
import { Events } from '@app/v2/shared/enums';
import { isNumber } from '@app/v2/shared/helpers';
import type { DataGridVirtualizedProps, TableData } from '../types';

/**
 * This is necessary to solve the problem of rendering a virtualized table.
 * This logic to artificially slow down the browsers scroll.
 */

const DECELERATION_FACTOR = (window as any)?.chrome ? 1 : 5;
const VIRTUALIZE_TABLE_SCROLLER_SELECTOR = '[data-virtuoso-scroller]';

export default function withScrollSpeedLimit(WrappedComponent) {
  return <Data extends TableData>(props: DataGridVirtualizedProps<Data>) => {
    useEffect(() => {
      const scroller = document.querySelector(VIRTUALIZE_TABLE_SCROLLER_SELECTOR);

      const slowDownScrollingHandler = event => {
        event.preventDefault();
        const deltaY = event?.deltaY;
        if (!isNumber(deltaY)) return;

        const speed = deltaY / DECELERATION_FACTOR;
        scroller.scrollTop += speed;
      };

      if (scroller) scroller.addEventListener(Events.Wheel, slowDownScrollingHandler);

      return () => {
        if (!scroller) return;
        scroller.removeEventListener(Events.Wheel, slowDownScrollingHandler);
      };
    }, []);

    return <WrappedComponent {...props} />;
  };
}
