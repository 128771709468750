import React, { useCallback, useMemo, memo } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Box, Stack, Theme } from '@mui/material';
import set from 'lodash/set';
import icons from '@app/assets/iconFont';
import { convertPxToRem, isJSON } from '@app/v2/shared/helpers';
import { useCheckAccess } from '@app/v2/shared/hooks';
import { VideoActionButtons, UsersAccess } from '@app/v2/shared/enums';
import CSDIconButton from '../CSDIconButton/CSDIconButton';

type AdditionalStyleProps = {
  linksSize: number;
  isShowLinks: boolean;
};

type Props = {
  zoom: boolean;
  onZoomChange: (zoom: boolean) => void;
  isSimpleGrid: boolean;
  placeId: number;
  links: Record<string, number[]>;
  linksSize: number;
  isShowLinks: boolean;
  openMeteoDialog: Common.OpenPostDialogType;
  openTrafficDialog: Common.OpenPostDialogType;
  openVideoDialog: Common.OpenPostDialogType;
  cardIndex?: number;
  openWaterLevelDialog?: Common.OpenPostDialogType;
  wlStationId?: number;
};

const CSDGridCardActions = ({
  zoom,
  links: { meteo = [], traffic = [], video = [] },
  linksSize,
  isShowLinks,
  onZoomChange,
  isSimpleGrid,
  placeId,
  openMeteoDialog,
  openTrafficDialog,
  openVideoDialog,
  cardIndex,
  openWaterLevelDialog,
  wlStationId,
}: Props) => {
  const { accessChecker } = useCheckAccess();

  const isDisabledPlaceId = useMemo(
    () =>
      Boolean(
        isJSON(process.env.REACT_APP_DISABLED_STATION_PLACE_IDS) && JSON.parse(process.env.REACT_APP_DISABLED_STATION_PLACE_IDS)?.includes(placeId),
      ),
    [placeId],
  );

  const classes = useStyles({ isShowLinks, linksSize: convertPxToRem(linksSize) });

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, stationId: number, fn: Common.AnyFunction) => {
      event.stopPropagation();

      fn(placeId, stationId);
    },
    [placeId],
  );

  const popupButtons = useMemo(() => {
    const buttons = {
      [VideoActionButtons.METEO]: {
        onClick: e => handleClick(e, meteo[0], openMeteoDialog),
        icon: icons.sky,
        isShow: !!meteo && !!meteo.length,
        disabled: !accessChecker([UsersAccess.PAGE_METEO_POPUP]),
      },
      [VideoActionButtons.VIDEO]: {
        onClick: e => handleClick(e, video[0], openVideoDialog),
        icon: icons.camera,
        isShow: true,
        // Fixme: PAGE_SCLR_POPUP temporary solution until we don't have sclr in links
        disabled: !accessChecker([UsersAccess.PAGE_VIDEO_POPUP, UsersAccess.PAGE_SCLR_POPUP]),
      },
      [VideoActionButtons.TRAFFIC]: {
        onClick: e => handleClick(e, traffic[0], openTrafficDialog),
        icon: icons.traffic,
        isShow: !!traffic && !!traffic.length && !isDisabledPlaceId,
        disabled: !accessChecker([UsersAccess.PAGE_TRAFFIC_POPUP]),
      },
    };

    if (wlStationId) {
      set(buttons, VideoActionButtons.RIVER_LEVEL, {
        onClick: e => handleClick(e, wlStationId, openWaterLevelDialog),
        icon: icons.river,
        isShow: true,
        // Fixme: PAGE_SCLR_POPUP temporary solution until we don't have sclr in links
        disabled: !accessChecker([UsersAccess.PAGE_VIDEO_POPUP, UsersAccess.PAGE_SCLR_POPUP]),
      });
    }

    return buttons;
  }, [
    meteo,
    traffic,
    video,
    isDisabledPlaceId,
    handleClick,
    openMeteoDialog,
    openVideoDialog,
    openTrafficDialog,
    accessChecker,
    openWaterLevelDialog,
    wlStationId,
  ]);

  return (
    <Box className={classes.cellActions}>
      {isShowLinks && (
        <>
          <Box className={clsx(classes.cellActionsBar, classes.cellActionsBarLeft)}>
            <Stack spacing={1} direction="row" data-testid={`video-fast-link-block-${cardIndex}`}>
              {Object.entries(popupButtons).map(([buttonKey, buttonSettings]) => (
                <CSDIconButton key={buttonKey} {...buttonSettings} />
              ))}
            </Stack>
          </Box>

          {!isSimpleGrid && (
            <Box className={clsx(classes.cellActionsBar, classes.cellActionsBarRight)}>
              <CSDIconButton icon={zoom ? icons.collapse : icons.resize} aria-label="image-zoom" onClick={() => onZoomChange(!zoom)} />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles<Theme, AdditionalStyleProps>((theme: Theme) => ({
  cellActions: {
    display: 'flex',
    justifyContent: ({ isShowLinks }) => (isShowLinks ? 'space-between' : 'flex-end'),
    alignItems: 'end',
    zIndex: 2,
  },
  cellActionsBar: {
    display: 'flex',
    height: ({ linksSize }) => `${linksSize}rem`,
    background: theme.palette.text.t2,
    padding: '0.563rem 1.063rem',
    color: theme.palette.text.t6,
    '& .MuiIcon-root': {
      fontSize: ({ linksSize }) => `${linksSize / 2}rem`,
    },
  },
  cellActionsBarLeft: {
    borderRadius: '0 0.5rem 0 0',
  },
  cellActionsBarRight: {
    borderRadius: '0.5rem 0 0 0',
    padding: '0.563rem 0.656rem',
  },
}));

export default memo(CSDGridCardActions);
