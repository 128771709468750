import React from 'react';
import CSDStation from './CSDStation';
import { CSDStationItem } from '../../types';
import { DEFAULT_STATION_SIZE } from '../../constants';

type Props = {
  validStation: CSDStationItem[];
  wrongStations: CSDStationItem[];
  activeStationId: number | string;
};

const CSDStationsLists = ({ validStation = [], wrongStations = [], activeStationId }: Props) => {
  const hasWrongStations = !!wrongStations?.length;

  return (
    <>
      {validStation.map(({ position, direction, stationId, hasNeighbor, isDuplicate, isExpired, onChange }) => (
        <CSDStation
          key={stationId}
          stationId={stationId}
          position={position}
          direction={direction}
          hasWrongStations={hasWrongStations}
          isActive={activeStationId === stationId}
          isExpired={isExpired}
          isDuplicate={isDuplicate}
          hasNeighbor={hasNeighbor}
          onChange={onChange}
        />
      ))}
      {hasWrongStations && (
        <foreignObject width="100%" height={DEFAULT_STATION_SIZE} y={`calc(100% - ${DEFAULT_STATION_SIZE}px)`}>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            {wrongStations.map(({ position, direction, stationId, hasNeighbor, isDuplicate, isExpired, onChange }) => (
              <CSDStation
                key={stationId}
                stationId={stationId}
                position={position}
                direction={direction}
                isActive={activeStationId === stationId}
                isDuplicate={isDuplicate}
                hasNeighbor={hasNeighbor}
                isExpired={isExpired}
                onChange={onChange}
              />
            ))}
          </div>
        </foreignObject>
      )}
    </>
  );
};

export default CSDStationsLists;
