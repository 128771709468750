enum DataTestIds {
  UniversalReportDirectionSelect = 'universalReportDirectionSelect',
  UniversalReportIntervalSelect = 'universalReportIntervalSelect',

  VideoWallSwitcher = 'videoWallSwitcher',
  FilterSwitcher = 'filterSwitcher',

  OpenSidebarBtn = 'openSidebarBtn',

  ReportFormatTypeInput = 'reportFormatTypeInput',
  ReportMainTypeInput = 'reportMainTypeInput',
  ReportStationInput = 'reportStationInput',
  ReportDatePikerFrom = 'reportDatePikerFrom',
  ReportDatePikerTo = 'reportDatePikerTo',

  FiltersAutocompleteOrganizations = 'filtersAutocompleteOrganizations',
  FiltersAutocompleteOrganization = 'filtersAutocompleteOrganization',
  FiltersAutocompleteRoads = 'filtersAutocompleteRoads',
  FiltersAutocompletePlaces = 'filtersAutocompletePlaces',
  FiltersAutocompleteSets = 'filtersAutocompleteSets',
  FiltersIsFullDataCheck = 'filtersIsFullDataCheck',

  SidebarServices = 'sidebarServices',
  SidebarServiceMeteo = 'sidebarServiceMeteo',
  SidebarServiceMeteoTable = 'sidebarServiceMeteoTable',
  SidebarServiceMeteoReports = 'sidebarServiceMeteoReports',
  SidebarMeteoExtremeValuesReport = 'sidebarMeteoExtremeValuesReport',
  SidebarMeteoObservationsHistoryReport = 'sidebarMeteoObservationsHistoryReport',
  SidebarServiceMeteoMalfunctionsByOrganizationsReport = 'sidebarServiceMeteoMalfunctionsByOrganizationsReport',
  SidebarServiceMeteoMalfunctionsByOrganizationsFDAReport = 'sidebarServiceMeteoMalfunctionsByOrganizationsFDAReport',
  SidebarServiceMeteoROCalendarReport = 'sidebarServiceMeteoROCalendarReport',
  SidebarServiceMeteoDiagnosticSensorsReport = 'sidebarServiceMeteoDiagnosticSensorsReport',

  SidebarServiceVideo = 'sidebarServiceVideo',
  SidebarServiceVideoTable = 'sidebarServiceVideoTable',
  SidebarServiceVideoReports = 'sidebarServiceVideoReports',
  SidebarServiceVideoMalfunctionsByOrganizationsReport = 'sidebarServiceVideoMalfunctionsByOrganizationsReport',
  SidebarServiceVideoMalfunctionsByOrganizationsFDAReport = 'sidebarServiceVideoMalfunctionsByOrganizationsFDAReport',
  SidebarServiceVideoROCalendarReport = 'sidebarServiceVideoROCalendarReport',

  SidebarServiceTraffic = 'sidebarServiceTraffic',
  SidebarServiceTrafficTable = 'sidebarServiceTrafficTable',
  SidebarServiceTrafficReports = 'sidebarServiceTrafficReports',
  SidebarServiceTrafficMalfunctionsByOrganizationReport = 'sidebarServiceTrafficMalfunctionsByOrganizationReport',
  SidebarServiceTrafficMalfunctionsByOrganizationFDAReport = 'sidebarServiceTrafficMalfunctionsByOrganizationFDAReport',
  SidebarServiceTrafficROCalendarReport = 'sidebarServiceTrafficROCalendarReport',

  SidebarLocatorAnalyze = 'sidebarLocatorAnalyze',
  SidebarLocatorAnalyzeTable = 'sidebarLocatorAnalyzeTable',
  SidebarLocatorAnalyzeReports = 'sidebarLocatorAnalyzeReports',
  SidebarLocatorAnalyzePeriodReport = 'sidebarLocatorAnalyzePeriodReport',
  SidebarLocatorAnalyzeMonthReport = 'sidebarLocatorAnalyzeMonthReport',
  SidebarLocatorAnalyzeYearReport = 'sidebarLocatorAnalyzeYearReport',

  MeteoChartContainer = 'meteoChartContainer',
  MeteoChartTooltip = 'meteoChartTooltip',
  MeteoChartTooltipCameraBtn = 'meteoChartTooltipCameraBtn',
  MeteoChartTooltipCloseBtn = 'meteoChartTooltipCloseBtn',

  OpenFilterButton = 'OpenFilterButton',
  FiltersContainer = 'FiltersContainer',
  FilterButtonBadge = 'FilterButtonBadge',
  FilterSetsActions = 'FilterSetsActions',
  FilterSection = 'FilterSection',

  MapMarkerWrapperOpenDialogButton = 'mapMarkerWrapperOpenDialogButton',
  MapMarkerWrapperDate = 'mapMarkerWrapperDate',
  MapMarkerWrapperNoData = 'mapMarkerWrapperNoData',
  MapMarkerWrapperSideMenuButton = 'mapMarkerWrapperSideMenuButton',

  MapMarkerCardOpenDialogButton = 'mapMarkerCardOpenDialogButton',
  MapMarkerCardSnapshotSwitchButton = 'mapMarkerCardSnapshotSwitchButton',

  MapMarkerBasicSimpleActionButton = 'mapMarkerBasicSimpleActionButton',
  MapMarkerBasicAdvanceActionButton = 'mapMarkerBasicAdvanceActionButton',
  MapMarkerNormalActionButton = 'mapMarkerNormalActionButton',

  MapTpiModal = 'MapTpiModal',
  MapTpiHeader = 'MapTpiHeader',
  MapTpiContent = 'MapTpiContent',
  MapTpiEmptyContent = 'MapTpiEmptyContent',
  MapTpiHeaderCoordsSkeleton = 'MapTpiHeaderCoordsSkeleton',
  MapTpiHeaderOrgSkeleton = 'MapTpiHeaderOrgSkeleton',

  StationsSwitcher = 'stationsSwitcher',
  StationsSwitcherChangeActiveStationBtn = 'stationsSwitcher',
  StationsSwitcherChangeDirectionBtn = 'stationsSwitcherChangeDirectionBtn',

  VMSCards = 'VMSCards',
  VMSCardsBackwardBtn = 'VMSCardsBackwardBtn',
  VMSCardsForwardBtn = 'VMSCardsForwardBtn',
  VMSCard = 'VMSCard',
  VMSCardEditButton = 'VMSCardEditButton',
  VMSCardSkeleton = 'VMSCardSkeleton',
  VMSCardEditActionPanel = 'VMSCardEditActionPanel',
  VMSCardEditActionPanelClose = 'VMSCardEditActionPanelClose',

  UpdatePlaylistBtn = 'UpdatePlaylistBtn',
  ChangePlaylistForDevice = 'ChangePlaylistForDevice',
  DeletePlaylistBtn = 'DeletePlaylistBtn',
  DetachPlaylistFromDeviceBtn = 'DetachPlaylistFromDeviceBtn',
  DeleteTemplateFromPlaylistBtn = 'DeleteTemplateFromPlaylistBtn',

  TemplateSignField = 'TemplateSignField',
  TemplateMessageField = 'TemplateMessageField',
  TemplateMessageFields = 'TemplateMessageFields',

  ExportChartBtnWithLabel = 'exportChartBtnWithLabel',
  ExportChartBtnWithoutLabel = 'exportChartBtnWithoutLabel',
  ExportChartBtns = 'exportChartBtns',

  /** Reports start */

  ReportsModuleContainer = 'reportsModuleContainer',
  ReportsContainer = 'reportsContainer',
  ReportsGroupsContainer = 'reportsGroupsContainer',
  ReportsGroupsList = 'reportsGroupsList',
  ReportsActionsContainer = 'reportsActionsContainer',
  ReportsLocationContainer = 'reportsLocationContainer',
  ReportsMainSetupContainer = 'reportsMainSetupContainer',

  ReportsTypes = 'reportsTypes',
  ReportsTypesSubgroupsList = 'reportsTypesSubgroupsList',
  ReportTypesList = 'reportTypesList',
  ReportAdditionalSetup = 'reportAdditionalSetup',
  ReportOpenCloseAdditional = 'reportOpenCloseAdditional',
  ReportAdditionalSetupGroup = 'reportAdditionalSetupGroup',

  ReportNotificationBlock = 'reportNotificationBlock',
  ReportPreviewDialog = 'reportPreviewDialog',

  ReportSetupComponentButton = 'reportSetupComponentButton',
  ReportSetupComponentIconButton = 'reportSetupComponentIconButton',
  ReportSetupComponentCheckBox = 'reportSetupComponentCheckBox',

  ReportSetupFormatExcelBtn = 'reportSetupFormatExcelBtn',
  ReportSetupFormatPDFBtn = 'reportSetupFormatPDFBtn',

  ReportPreviewBtn = 'previewReportBtn',
  ReportCreateBtn = 'reportCreateBtn',
  ReportListingLinkBtn = 'reportListingLinkBtn',
  ReportClosePreviewDialog = 'reportClosePreviewDialog',

  ReportRoadsAutocomplete = 'reportRoadsAutocomplete',
  ReportOrgsAutocomplete = 'reportOrgsAutocomplete',
  ReportPlacesAutocomplete = 'reportPlacesAutocomplete',

  ReportsSubscriptionContainer = 'reportsSubscriptionContainer',
  ReportsSubscriptionHead = 'reportsSubscriptionHead',
  ReportsSubscriptionBody = 'reportsSubscriptionBody',

  /** Reports end */

  /** Scoreboards configuration start */

  BackgroundColor = 'backgroundColor',
  Visible = 'visible',
  Edit = 'edit',
  EditList = 'editList',
  NoData = 'noData',

  PlaylistsListCell = 'playlistsListCell',
  PlaylistsListCellContent = 'playlistsListCellContent',
  EmptyPlaylistsListCell = 'emptyPlaylistsListCell',
  SignCell = 'signCell',

  /** Scoreboards configuration end */

  /** Filters start */

  FiltersApplyBtn = 'filtersApplyBtn',
  FiltersResetBtn = 'filtersResetBtn',

  FiltersWithoutSavedBtn = 'filtersWithoutSavedBtn',

  FiltersOrgsAutocomplete = 'filtersOrgsAutocomplete',
  FiltersRoadsAutocomplete = 'filtersRoadsAutocomplete',
  FiltersStationsAutocomplete = 'filtersStationsAutocomplete',

  FiltersAlertStatusDangerBtn = 'filtersAlertStatusDangerBtn',
  FiltersAlertStatusAttentionBtn = 'filtersAlertStatusAttentionBtn',
  FiltersAlertStatusNoWarningBtn = 'filtersAlertStatusNoWarningBtn',

  FiltersReactionStatusNoneBtn = 'filtersReactionStatusNoneBtn',
  FiltersReactionStatusAllBtn = 'filtersReactionStatusAllBtn',
  FiltersReactionStatusAcceptBtn = 'filtersReactionStatusAcceptBtn',
  FiltersReactionStatusRejectBtn = 'filtersReactionStatusRejectBtn',

  /** Filters end */
}

export default DataTestIds;
