import { Viewport } from 'react-leaflet';
import { Settings } from '@app/core/types/profiles/map';
import { DEFAULT_VIEWPORT } from '@app/v2/shared/constants';

const isViewportType = (data: Settings): data is Viewport => (data as Viewport)?.center !== undefined && (data as Viewport)?.zoom !== undefined;

const getViewportData = (data: Settings) => {
  if (!isViewportType(data) || !data.center || !data.zoom) {
    return DEFAULT_VIEWPORT;
  }

  return data;
};

export default getViewportData;
