import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import valueMapper from '../../config/valueMapper';

interface Props {
  activeId: number;
  setActiveId: Dispatch<number>;
  devices: {
    stationId: number;
    settings: Scoreboards.DeviceSettings;
  }[];
}

const CSDViewDeviceSettingsDialogPanel = ({ activeId, setActiveId, devices }: Props) => {
  const classes = useStyles();

  const { t } = useTranslation('scoreboard');

  return (
    <Box className={classes.settingsPanel}>
      <Box className="panel">
        <Typography variant="h3">{t('devices.devices')}</Typography>

        <Box className="items">
          {devices.map(({ stationId, settings }, index) => (
            <Chip
              key={stationId}
              size="small"
              variant="outlined"
              icon={valueMapper.type[settings?.type]}
              onClick={() => setActiveId(stationId)}
              color={stationId === activeId ? 'primary' : 'default'}
              label={t('devices.device.withNumber', { number: index + 1 })}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  settingsPanel: {
    gap: '1rem',
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: '1rem',
    justifyContent: 'flex-end',

    '& .panel': {
      gap: '0.5rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',

      '& .items': {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '1rem',
      },
    },
  },
});

export default CSDViewDeviceSettingsDialogPanel;
