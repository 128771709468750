import { chartsColors } from '@theme/constants';
import Parameter from './types';

const parameters: { [key: string]: Parameter } = {
  tAir: {
    label: 'tAir',
    shortLabel: 'tAirShort',
    order: 1,
    icon: 'tempAir',
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(168, 68, 69)',
      visible: true,
      legendIndex: 1,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 100,
    },
  },
  tRoad: {
    label: 'tRoad',
    shortLabel: 'tRoadShort',
    order: 2,
    icon: 'tempSurface',
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(251, 3, 35)',
      visible: true,
      legendIndex: 2,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 100,
    },
  },
  tRoad2: {
    label: 'tRoad2',
    shortLabel: 'tRoad2Short',
    order: 2,
    icon: 'tempSurfaceTwo',
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(251, 3, 35)',
      visible: true,
      legendIndex: 2,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 100,
    },
  },
  tUnderroad: {
    label: 'tUnderroad',
    shortLabel: 'tUnderroadShort',
    icon: 'tempBelowSurface',
    order: 3,
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(10, 98, 12)',
      visible: false,
      legendIndex: 3,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 95,
    },
  },
  tUnderroad2: {
    label: 'tUnderroad2',
    shortLabel: 'tUnderroad2Short',
    order: 3,
    icon: 'tempBelowSurfaceTwo',
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(10, 98, 12)',
      visible: true,
      legendIndex: 3,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 95,
    },
  },
  tDewPoint: {
    label: 'tDewPoint',
    shortLabel: 'tDewPointShort',
    order: 4,
    icon: 'tempDewPoint',
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(169, 97, 252)',
      visible: false,
      legendIndex: 4,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 100,
    },
  },
  humidity: {
    label: 'humidity',
    order: 5,
    icon: 'humidity',
    unitsOptions: {
      key: 'humidity',
      label: 'humidity',
      value: 'units.interest',
    },
    chartOptions: {
      type: '',
      lineColor: 'rgb(253, 138, 36)',
      axisColor: 'rgb(253, 138, 36)',
      tickPositions: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      visible: false,
      legendIndex: 5,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 100,
    },
  },
  precipitationType: {
    label: 'precipitationType',
    order: 6,
    icon: 'typeRain',
    unitsOptions: {
      key: 'precipitationType',
      label: 'precipitationType',
      value: null,
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'transparent',
      axisColor: 'transparent',
      visible: true,
      legendIndex: 6,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  precipitationIntensity: {
    label: 'precipitationIntensity',
    shortLabel: 'precipitationIntensityShort',
    icon: 'precipitationIntensity',
    order: 7,
    unitsOptions: {
      key: 'precipitationIntensity',
      label: 'precipitationIntensity',
      value: 'units.millimetersPerHour',
    },
    chartOptions: {
      type: 'column',
      lineColor: 'rgb(163, 191, 253)',
      axisColor: 'rgb(163, 191, 253)',
      tickPositions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      visible: true,
      opposite: true,
      legendIndex: 7,
      zIndex: 0,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  precipitationInstant: {
    label: 'precipitationInstant',
    order: 8,
    icon: 'sumRain',
    unitsOptions: {
      key: 'precipitationInstant',
      label: 'precipitationInstant',
      value: 'units.millimeters',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(180, 100, 30)',
      axisColor: 'rgb(180, 100, 30)',
      visible: false,
      legendIndex: 8,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  rainIntensity: {
    label: 'rainIntensity',
    shortLabel: 'rainIntensity',
    order: 7,
    unitsOptions: {
      key: 'rainIntensity',
      label: 'rainIntensity',
      value: 'units.millimetersPerHour',
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  snowIntensity: {
    label: 'snowIntensity',
    shortLabel: 'snowIntensity',
    order: 7,
    unitsOptions: {
      key: 'snowIntensity',
      label: 'snowIntensity',
      value: 'units.millimetersPerHour',
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  statusDamp: {
    label: 'statusDamp',
    order: 9,
    icon: 'surfaceConditionOne',
    shortLabel: 'statusDampShort',
    unitsOptions: {
      key: 'statusDamp',
      label: 'precipitationType',
      value: null,
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'transparent',
      axisColor: 'transparent',
      visible: true,
      legendIndex: 9,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  statusDamp2: {
    label: 'statusDamp2',
    order: 9,
    icon: 'surfaceConditionTwo',
    shortLabel: 'statusDamp2Short',
    unitsOptions: {
      key: 'statusDamp2',
      label: 'precipitationType',
      value: null,
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'transparent',
      axisColor: 'transparent',
      visible: true,
      legendIndex: 9,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  windDirection: {
    label: 'windDirection',
    order: 10,
    icon: 'windDirection',
    unitsOptions: {
      key: 'windDirection',
      label: 'windDirection',
      value: null,
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'transparent',
      axisColor: 'transparent',
      visible: false,
      legendIndex: 10,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 130,
    },
  },
  windDirGr: {
    label: 'windDirGr',
    order: 10,
    icon: 'windDirectionDeg',
    unitsOptions: {
      key: 'windDirGr',
      label: 'windDirGr',
      value: 'units.something',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'transparent',
      axisColor: 'transparent',
      visible: true,
      legendIndex: 19,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 160,
    },
  },
  windSpeed: {
    label: 'windSpeed',
    order: 11,
    icon: 'windSpeed',
    unitsOptions: {
      key: 'speed',
      label: 'windSpeedGusts',
      value: 'units.metersPerSecond',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(151, 153, 241)',
      visible: false,
      legendIndex: 11,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 100,
    },
  },
  windGusts: {
    label: 'windGusts',
    order: 12,
    icon: 'windGusts',
    unitsOptions: {
      key: 'speed',
      label: 'windSpeedGusts',
      value: 'units.metersPerSecond',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(133, 126, 153)',
      visible: false,
      legendIndex: 12,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 100,
    },
  },
  pressure: {
    label: 'pressure',
    order: 13,
    icon: 'pressure',
    shortLabel: 'pressureShort',
    unitsOptions: {
      key: 'pressure',
      label: 'pressure',
      value: 'units.millimetersOfMercury',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(63, 81, 181)',
      axisColor: 'rgb(63, 81, 181)',
      tickAmount: 5,
      tickInterval: 20,
      visible: false,
      legendIndex: 13,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 100,
    },
  },
  salineConcentration: {
    label: 'salineConcentration',
    order: 14,
    icon: 'deicingAgentsOne',
    shortLabel: 'salineConcentrationShort',
    unitsOptions: {
      key: 'salineConcentration',
      label: 'salineConcentration',
      value: 'units.interest',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(181,172,10)',
      axisColor: 'rgb(181,172,10)',
      visible: false,
      legendIndex: 14,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 90,
    },
  },
  salineConcentration2: {
    label: 'salineConcentration2',
    order: 14,
    icon: 'deicingAgentsTwo',
    shortLabel: 'salineConcentration2Short',
    unitsOptions: {
      key: 'salineConcentration',
      label: 'salineConcentration2',
      value: 'units.interest',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(181,172,10)',
      axisColor: 'rgb(181,172,10)',
      visible: true,
      legendIndex: 14,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 90,
    },
  },
  waterHeight: {
    label: 'waterHeight',
    order: 15,
    icon: 'layerThickness',
    shortLabel: 'waterHeightShort',
    unitsOptions: {
      key: 'waterHeight',
      label: 'waterHeight',
      value: 'units.millimeters',
    },
    chartOptions: {
      type: '',
      visible: false,
      legendIndex: 15,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  tFreezingPoint: {
    label: 'tFreezingPoint',
    order: 16,
    icon: 'tempFreezing',
    shortLabel: 'tFreezingPointShort',
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(253, 137, 169)',
      visible: false,
      legendIndex: 16,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 100,
    },
  },
  tFreezingPoint2: {
    label: 'tFreezingPoint2',
    icon: 'tempFreezingTwo',
    order: 16,
    shortLabel: 'tFreezingPoint2Short',
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(253, 137, 169)',
      visible: true,
      legendIndex: 16,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 100,
    },
  },
  visibility: {
    label: 'visibility',
    order: 17,
    icon: 'visibility',
    unitsOptions: {
      key: 'visibility',
      label: 'visibility',
      value: 'units.meters',
    },
    chartOptions: {
      type: 'spline',
      visible: false,
      legendIndex: 17,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 140,
    },
  },
  cloudiness: {
    label: 'cloudiness',
    shortLabel: 'cloudinessShort',
    icon: 'sky',
    order: 18,
    unitsOptions: {
      key: 'cloudiness',
      label: 'cloudiness',
      value: 'units.score',
    },
    chartOptions: {
      type: 'spline',
      visible: false,
      legendIndex: 18,
      zIndex: 1,
    },
    tableOptions: {
      columnWidth: 100,
    },
  },
  progRecCnctr: {
    label: 'progRecCnctr',
    order: 19,
    icon: 'deicingAgentsOne',
    unitsOptions: {
      key: 'progRecCnctr',
      label: 'progRecCnctr',
      value: 'units.gramsPerSquareMeter',
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  trTotalVehicles: {
    label: 'trTotalVehicles',
    shortLabel: 'trTotalVehiclesShort',
    icon: 'traffic',
    unitsOptions: {
      key: 'quantity',
      label: 'numberOfPieces',
      value: 'units.units',
    },
    chartOptions: {
      type: 'column',
      lineColor: 'rgb(47, 79, 79)',
      visible: false,
    },
    tableOptions: {
      columnWidth: 130,
    },
  },
  trCars: {
    label: 'trCars',
    shortLabel: 'trCarsShort',
    unitsOptions: {
      key: 'quantity',
      label: 'numberOfPieces',
      value: 'units.units',
    },
    chartOptions: {
      type: 'column',
      lineColor: chartsColors.c8,
      visible: false,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  trSmallTrucks: {
    label: 'trSmallTrucks',
    shortLabel: 'trSmallTrucksShort',
    unitsOptions: {
      key: 'quantity',
      label: 'numberOfPieces',
      value: 'units.units',
    },
    chartOptions: {
      type: 'column',
      lineColor: chartsColors.c4,
      visible: false,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  trTrucks: {
    label: 'trTrucks',
    shortLabel: 'trTrucksShort',
    unitsOptions: {
      key: 'quantity',
      label: 'numberOfPieces',
      value: 'units.units',
    },
    chartOptions: {
      type: 'column',
      lineColor: chartsColors.c3,
      visible: false,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  trBigTrucks: {
    label: 'trBigTrucks',
    shortLabel: 'trBigTrucksShort',
    unitsOptions: {
      key: 'quantity',
      label: 'numberOfPieces',
      value: 'units.units',
    },
    chartOptions: {
      type: 'column',
      lineColor: chartsColors.c5,
      visible: false,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  trRoadTrains: {
    label: 'trRoadTrains',
    shortLabel: 'trRoadTrainsShort',
    unitsOptions: {
      key: 'quantity',
      label: 'numberOfPieces',
      value: 'units.units',
    },
    chartOptions: {
      type: 'column',
      lineColor: chartsColors.c6,
      visible: false,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  trBuses: {
    label: 'trBuses',
    unitsOptions: {
      key: 'quantity',
      label: 'numberOfPieces',
      value: 'units.units',
    },
    chartOptions: {
      type: 'column',
      lineColor: chartsColors.c7,
      visible: false,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  trMotorcycles: {
    label: 'trMotorcycles',
    unitsOptions: {
      key: 'quantity',
      label: 'numberOfPieces',
      value: 'units.units',
    },
    chartOptions: {
      type: 'column',
      lineColor: chartsColors.c9,
      visible: false,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  trSpeed: {
    label: 'trSpeed',
    unitsOptions: {
      key: 'trSpeed',
      label: 'trSpeed',
      value: 'units.kilometersPerHour',
    },
    chartOptions: {
      type: 'spline',
      visible: false,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  trOccupancy: {
    label: 'trOccupancy',
    unitsOptions: {
      key: 'trOccupancy',
      label: 'trOccupancy',
      value: 'units.interest',
    },
    chartOptions: {
      type: 'spline',
      visible: false,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  trGap: {
    label: 'trGap',
    unitsOptions: {
      key: 'trGap',
      label: 'trGap',
      value: 'units.meters',
    },
    chartOptions: {
      type: 'spline',
      visible: false,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  backward: {
    label: 'backward',
    unitsOptions: {
      key: 'quantity',
      label: 'numberOfPieces',
      value: 'units.units',
    },
    chartOptions: {
      type: 'column',
      lineColor: chartsColors.c8,
      visible: false,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  forward: {
    label: 'forward',
    unitsOptions: {
      key: 'quantity',
      label: 'numberOfPieces',
      value: 'units.units',
    },
    chartOptions: {
      type: 'column',
      lineColor: chartsColors.c4,
      visible: false,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  dpgT01: {
    label: 'dpgT01',
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(168, 68, 69)',
      visible: true,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  dpgT02: {
    label: 'dpgT02',
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(168, 68, 69)',
      visible: true,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  dpgT03: {
    label: 'dpgT03',
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(168, 68, 69)',
      visible: true,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  dpgT04: {
    label: 'dpgT04',
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(168, 68, 69)',
      visible: true,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  dpgT05: {
    label: 'dpgT05',
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(168, 68, 69)',
      visible: true,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  dpgT06: {
    label: 'dpgT06',
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(168, 68, 69)',
      visible: true,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  dpgT07: {
    label: 'dpgT07',
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(168, 68, 69)',
      visible: true,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  dpgT08: {
    label: 'dpgT08',
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(168, 68, 69)',
      visible: true,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  dpgT09: {
    label: 'dpgT09',
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(168, 68, 69)',
      visible: true,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  dpgT10: {
    label: 'dpgT10',
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(168, 68, 69)',
      visible: true,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  dpgT11: {
    label: 'dpgT11',
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(168, 68, 69)',
      visible: true,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  dpgT12: {
    label: 'dpgT12',
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(168, 68, 69)',
      visible: true,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  dpgT13: {
    label: 'dpgT13',
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(168, 68, 69)',
      visible: true,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  dpgT14: {
    label: 'dpgT14',
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(168, 68, 69)',
      visible: true,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  dpgT15: {
    label: 'dpgT15',
    unitsOptions: {
      key: 'temperature',
      label: 'temperature',
      value: 'units.degreesCelsius',
    },
    chartOptions: {
      type: 'spline',
      lineColor: 'rgb(168, 68, 69)',
      visible: true,
    },
    tableOptions: {
      columnWidth: 120,
    },
  },
  waterLevel: {
    label: 'waterLevel',
    icon: 'river',
    unitsOptions: {
      key: 'waterLevel',
      label: 'waterLevel',
      value: 'units.meters',
    },
    chartOptions: {
      type: 'spline',
      visible: false,
      zIndex: 1,
      lineColor: '#0DBF67',
    },
    tableOptions: {
      columnWidth: 140,
    },
  },
  cars: {
    label: 'cars',
    icon: 'cargo',
    unitsOptions: {
      key: 'cars',
      label: 'cars',
      value: 'units.units',
    },
    chartOptions: {
      type: 'spline',
      visible: false,
      zIndex: 2,
      lineColor: 'rgb(253, 138, 36)',
    },
    tableOptions: {
      columnWidth: 140,
    },
  },
};

export default parameters;
