import React from 'react';
import { DialogRef } from '@app/core/providers/DialogProvider';
import { DialogTabsTypes } from '@app/v2/shared/enums';
import DialogData from './DialogData';
import DialogView from './DialogView/DialogView';

type Props = {
  placeId: number;
  stationId?: number;
  stationType: DialogTabsTypes;
  video: number[];
} & DialogRef;

const PostDialog = ({ stationId, placeId, stationType, close, video = [] }: Props) => {
  return (
    <DialogData placeId={placeId} video={video} close={close}>
      {data => <DialogView data={data} stationId={stationId} stationType={stationType} close={close} />}
    </DialogData>
  );
};

export default PostDialog;
