import React from 'react';
import { Stack } from '@mui/material';
import { CSDPieDiagram } from '@app/modules/kit-module/shared/ui';
import { PieChartColors } from '@app/v2/shared/enums';
import { pieDiagramExample, pieDiagramWithOuterAndLabelsExample } from './example';
import CSDKitDocBlock from '../../../shared/ui/CSDKitDocBlock/CSDKitDocBlock';

const testOuterData = [
  {
    color: PieChartColors.SKY_BLUE,
    value: 13000,
    name: 'test1',
  },
  {
    color: PieChartColors.GREEN,
    value: 13000,
    name: 'test2',
  },
  {
    color: PieChartColors.YELLOW,
    value: 13000,
    name: 'test3',
  },
  {
    color: PieChartColors.RED,
    value: 13000,
    name: 'test4',
  },
  {
    color: PieChartColors.GREY,
    value: 11500,
    name: 'test5',
  },
];

const testInnerData = [
  {
    color: PieChartColors.BLUE,
    value: 52000,
    name: 'test6',
  },
  {
    color: PieChartColors.GREY,
    value: 11500,
    name: 'test7',
  },
];

const CSDDatePickersMUI = () => {
  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="PieDiagram" codeExample={pieDiagramExample}>
        <CSDPieDiagram data={testInnerData} title="Single pie" />
      </CSDKitDocBlock>
      <CSDKitDocBlock title="PieDiagram with inner data and labels" codeExample={pieDiagramWithOuterAndLabelsExample}>
        <CSDPieDiagram
          title="Double pie"
          lineWidth={15}
          data={testOuterData}
          innerData={testInnerData}
          rightSideNumber={testOuterData[0]}
          leftSideNumber={testOuterData[4]}
          topSideNumber={testOuterData[3]}
        />
      </CSDKitDocBlock>
    </Stack>
  );
};

export default CSDDatePickersMUI;
