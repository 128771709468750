// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ABJkR71zzss4OGKHY8gl{width:244px !important}.ABJkR71zzss4OGKHY8gl>div{display:flex;align-items:center;justify-content:space-between;gap:.5rem}.ABJkR71zzss4OGKHY8gl>div>input{font-family:\"Roboto\",sans-serif;font-size:1rem;border:1px solid #8d909a !important;border-radius:.5rem !important}.ABJkR71zzss4OGKHY8gl>div>input:focus{border-color:#222631 !important}.jZyidDLUoI78gmVcvGW9>div>input{border-color:red !important}.jZyidDLUoI78gmVcvGW9>div>input:focus{border-color:red !important}", "",{"version":3,"sources":["webpack://./src/modules/kit-module/shared/ui/CSDSeparateInput/separateInputStyles.module.scss"],"names":[],"mappings":"AAAA,sBACE,sBAAA,CAEA,0BACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,SAAA,CAEA,gCACE,+BAAA,CACA,cAAA,CACA,mCAAA,CACA,8BAAA,CAGF,sCACE,+BAAA,CAQF,gCACE,2BAAA,CAGF,sCACE,2BAAA","sourcesContent":[".separateInput {\n  width: 244px !important;\n\n  & > div {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    gap: 0.5rem;\n\n    & > input {\n      font-family: 'Roboto', sans-serif;\n      font-size: 1rem;\n      border: 1px solid #8D909A !important;\n      border-radius: 0.5rem !important;\n    }\n\n    & > input:focus {\n      border-color: #222631 !important;\n    }\n  }\n}\n\n.separateInputError {\n  & > div {\n\n    & > input {\n      border-color: red !important;\n    }\n\n    & > input:focus {\n      border-color: red !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"separateInput": "ABJkR71zzss4OGKHY8gl",
	"separateInputError": "jZyidDLUoI78gmVcvGW9"
};
export default ___CSS_LOADER_EXPORT___;
