import { KeyPrefix } from 'react-i18next';

type Params = {
  data: { [key: string]: { text: KeyPrefix<'charts'> } };
  t: (key: KeyPrefix<'charts'>) => string;
};

export default function preparedMenuItemDefinitions({ data, t }: Params) {
  return Object.entries(data).reduce((acc, [key, value]) => {
    acc[key] = {
      text: t(value.text) as string,
    };

    return acc;
  }, {});
}
