import CREATE_MAP_NOTE from './createMapNote.graphql';
import DELETE_MAP_NOTE from './deleteMapNote.graphql';
import UPDATE_MAP_NOTE from './updateMapNote.graphql';

const mapNoteMutations = {
  create: CREATE_MAP_NOTE,
  delete: DELETE_MAP_NOTE,
  update: UPDATE_MAP_NOTE,
};

export default mapNoteMutations;
