import { Theme } from '@mui/material/styles/createTheme';
import { makeStyles } from '@mui/styles';
import { linearGradient } from '@app/core/theme/styles';

const useAngleSliderStyles = makeStyles<Theme>(({ palette: { text } }) => ({
  slider: {
    margin: '0 0.5rem',
    '& .MuiSlider-rail': {
      height: '1rem',
      background: '#DBD2FF',
    },
    '& .MuiSlider-track': {
      position: 'relative',
      height: '1rem',
      background: linearGradient,
      borderRadius: 'unset',
      '&:after': {
        position: 'absolute',
        display: 'block',
        width: '11rem',
        height: '100%',
        content: '""',
        background: text.t6,
        transform: 'rotate(-4deg)',
        top: '-10px',
        left: '-1px',
      },
    },
    '& .MuiSlider-thumb': {
      borderRadius: 'unset',
      height: '1.5rem',
      width: '0.5rem',
      top: '10px',
    },
    '& .MuiSlider-valueLabel': {
      top: '5px',
      color: text.t1,
      borderRadius: '10px',
      zIndex: 1,
      '& > span': {
        width: 'initial',
        height: 'initial',
        padding: '5px 10px',
        transform: 'initial',
        borderRadius: '10px',
        fontSize: '0.85rem',
        '& span': {
          transform: 'initial',
        },
      },
    },
    '&.Mui-disabled .MuiSlider-track, &.Mui-disabled .MuiSlider-thumb': {
      background: 'linear-gradient(94.5deg, #8D909A -20.35%, #B5B8BE 109.04%)',
    },
  },
}));

export default useAngleSliderStyles;
