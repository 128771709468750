import React, { SyntheticEvent } from 'react';
import { useProfileColumns, useTemporarySetTableMode } from '@app/v2/shared/hooks';
import { TableHighlightMode } from '@app/v2/shared/enums';
import { FlatAdapter, GroupAdapter } from '../CSDDataGrid';
import CSDDataGridTable from '../CSDDataGrid/DataGridTable';

interface Props<Station, TableData extends Table.TableData> {
  listingData: Station[];
  handleScroll: (e: SyntheticEvent) => void;
  configureTable: () => Table.ConfigurableTableColumns<TableData>;
  adapter: GroupAdapter<TableData, Station> | FlatAdapter<TableData, Station>;
  withProfile?: boolean;
  loading?: boolean;
  highlightMode?: TableHighlightMode;
}

const CSDVirtualizeListing = <Station, TableData extends Table.TableData>({
  listingData,
  handleScroll,
  configureTable,
  adapter,
  highlightMode,
  loading,
  withProfile = false,
}: Props<Station, TableData>) => {
  useTemporarySetTableMode();

  const { baseColumns, customizableColumns } = configureTable();
  const profileColumns = useProfileColumns(customizableColumns);

  const otherColumns = withProfile ? profileColumns : customizableColumns;

  return (
    <CSDDataGridTable<TableData, Station>
      columns={[...baseColumns, ...(otherColumns ?? [])]}
      isDataLoading={loading}
      adapter={adapter}
      data={listingData}
      highlightMode={highlightMode}
      tableProps={{
        style: { height: '100%' },
        onScroll: handleScroll,
      }}
      isVirtualize
    />
  );
};

export default CSDVirtualizeListing;
