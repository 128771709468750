import React, { useEffect } from 'react';
import Prism from 'prismjs';
import { Paper, Stack, Typography } from '@mui/material';

type Props = {
  title: string;
  language?: string;
  codeExample: string;
  children: JSX.Element;
};

const CSDKitDocBlock = ({ title, language = 'jsx', children: Component, codeExample }: Props) => {
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
    <Stack direction="column" spacing={3} style={{ marginBottom: '1rem' }}>
      <Typography variant="h6">{title}</Typography>
      <div className="Code">
        <pre className="line-numbers">
          <code className={`language-${language}`}>{codeExample}</code>
        </pre>
      </div>
      <Paper
        sx={{
          backgroundColor: ({ palette }) => palette.background.default,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '0.625rem',
          padding: '1rem',
        }}
      >
        {Component}
      </Paper>
    </Stack>
  );
};

export default CSDKitDocBlock;
