import React from 'react';
import { Stack, Box, Typography, Theme } from '@mui/material';
import { upperFirst } from 'lodash';
import { makeStyles } from '@mui/styles';
import { useActions } from '@app/v2/shared/hooks';
import { CSDSwitcher } from '@app/modules/kit-module/shared/ui';
import { Bulletin, MeteoBulletinGroup } from '@store/slices/subscriptions/types';
import { getScrollStyles } from '@theme/themeSettings/componentSettings';
import useNotificationProfileContext from '@app/v2/features/notificationProfile/context/useNotificationProfileContext';

type Props = {
  data: MeteoBulletinGroup[];
};

const BulletinsTab = ({ data }: Props) => {
  const classes = useStyles();
  const { changeBulletinGroupCheckbox, changeBulletinCheckbox } = useActions();
  const { isLoading } = useNotificationProfileContext();

  const isCheckedGroup = React.useCallback(
    (id: number): boolean => {
      return data.find(({ id: groupId }: MeteoBulletinGroup) => groupId === id).bulletinElements.some(({ isChecked }) => isChecked);
    },
    [data],
  );

  const createLabel = (label: string): JSX.Element => (
    <Box className={classes.itemLabel}>
      <Typography fontSize="0.75rem">{upperFirst(label)}</Typography>
    </Box>
  );

  return (
    <Box className={classes.bulletinsTabContainer}>
      <Stack direction="column" spacing={1.5}>
        {data.map(({ groupTitle, id, bulletinElements }: MeteoBulletinGroup) => (
          <Stack key={id} direction="column" spacing={1}>
            <CSDSwitcher
              disabled={isLoading}
              checked={isCheckedGroup(id)}
              onChange={e => changeBulletinGroupCheckbox({ checked: e.target.checked, id })}
              label={groupTitle}
            />
            <Stack direction="column" spacing={1} sx={{ paddingLeft: '2px' }}>
              {bulletinElements.map(({ id: bulletinId, isChecked, title }: Bulletin) => (
                <CSDSwitcher
                  disabled={isLoading}
                  key={bulletinId}
                  checked={isChecked}
                  size="small"
                  color="primary"
                  label={createLabel(title)}
                  onChange={e => changeBulletinCheckbox({ checked: e.target.checked, groupId: id, elementId: bulletinId })}
                />
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

const useStyles = makeStyles<Theme>(({ palette: { text, mode } }) => ({
  bulletinsTabContainer: {
    marginTop: '1rem',
    width: '35rem',
    height: 'calc(100vh - 14rem)',
    overflow: 'auto',
    overflowX: 'hidden',
    ...getScrollStyles(mode, text),
  },
  itemLabel: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default BulletinsTab;
