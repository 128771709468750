import moment, { Moment } from 'moment/moment';
import { decodeDelimitedArray, decodeDelimitedNumericArray, encodeDelimitedArray, encodeDelimitedNumericArray } from 'use-query-params';
import { decodeString, encodeString } from 'serialize-query-params';
import { DATE_FORMAT } from './date';

function isNullable(val: any): val is null | undefined {
  return val === null || val === undefined;
}

export const CommaArrayParam = {
  encode: (array: number[] | null | undefined): string | undefined => encodeDelimitedNumericArray(array, ','),
  decode: (arrayStr: string | string[] | null | undefined): number[] | undefined => decodeDelimitedNumericArray(arrayStr, ','),
};

export const OrganizationTypeParam = {
  encode: (str: string | null | undefined): string | null | undefined => encodeString(str),
  decode: (input: string | (string | null)[] | null | undefined): string | null | undefined => decodeString(input),
};

export const DateParam = {
  encode: (date: Moment | null | undefined): string | null | undefined => date && date.format(DATE_FORMAT.FORMAT_RU),
  decode: (input: string | (string | null)[] | null | undefined): Moment | null | undefined => {
    const dateString = Array.isArray(input) ? input[0] : input;
    if (isNullable(dateString)) {
      return dateString;
    }
    const date = moment(dateString, DATE_FORMAT.FORMAT_RU);

    if (date.isValid()) {
      return date;
    }

    return null;
  },
};

export const PlaylistParams = {
  encode: (date: null | undefined): string | null | undefined => date,
  decode: (input: string | (string | null)[] | null | undefined | number[]): string | (string | null)[] | number[] => input,
};

export const IdsParams = {
  encode: (array: string[] | null | undefined) => encodeDelimitedArray(array, ','),
  decode: (arrayStr: string | string[] | null | undefined) => decodeDelimitedArray(arrayStr, ',')?.map(i => Number(i)),
};
