import React from 'react';
import { Icon } from '@mui/material';
import { ComponentSettings } from '@theme/types';
import isLightTheme from '@app/core/helpers/isLightTheme';
import icons from '@app/assets/iconFont';
import { linearGradient, boxShadowLight, boxShadowDark } from '../styles';

export const getScrollStyles = (mode, text) => {
  return {
    '&::-webkit-scrollbar': {
      width: '0.470rem',
      height: '0.470rem',
    },
    '&::-webkit-scrollbar-track': {
      background: isLightTheme(mode) ? text.t4 : text.t6,
      border: `0.105rem  solid ${isLightTheme(mode) ? text.t7 : text.t1}`,
      borderRadius: '0.625rem',
    },
    '&::-webkit-scrollbar-thumb': {
      background: `0.315rem ${isLightTheme(mode) ? text.t1 : text.t6}`,
      borderRadius: '0.625rem',
    },
  };
};

const componentSettings: ComponentSettings = {
  MuiStack: {
    defaultProps: {
      useFlexGap: true,
    },
  },
  MuiCssBaseline: {
    styleOverrides: ({ palette: { mode, text } }) => ({
      ...getScrollStyles(mode, text),
    }),
  },
  MuiGrid: {
    styleOverrides: {
      root: {
        marginTop: 0,
      },
    },
  },
  MuiDialog: {
    defaultProps: {
      fullWidth: true,
      maxWidth: 'md',
    },
    styleOverrides: {
      paper: ({
        theme: {
          palette: { background },
        },
      }) => ({
        background: background.first,
        padding: '1.5rem',
        borderRadius: '1rem',
      }),
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0rem 0rem 1.875rem 0rem',

        '& .MuiIcon-root': {
          fontSize: '2.25rem',
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: ({
        theme: {
          palette: { mode, text },
        },
      }) => ({
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '0rem',
        color: isLightTheme(mode) ? text.t1 : text.t6,
      }),
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: () => ({
        padding: '0rem',
      }),
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: ({
        theme: {
          palette: { background },
        },
      }) => ({
        backgroundColor: background.default,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexShrink: 1,
        padding: '0rem 1.5rem 0rem 0rem',
        maxWidth: '100vw',
      }),
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: ({
        theme: {
          palette: { mode, text },
        },
      }) => ({
        padding: '1rem',
        fontSize: '0.875rem',
        borderRadius: '0.315rem',
        color: isLightTheme(mode) ? text.t1 : text.t6,
      }),
      icon: ({
        theme: {
          palette: { success, primary, error, text },
        },
        ownerState: { severity },
      }) => {
        let color = text.t1;

        switch (severity) {
          case 'success':
            color = success.main;
            break;
          case 'error':
            color = error.main;
            break;
          case 'info':
            color = primary.main;
            break;
        }

        return {
          padding: '0rem',
          margin: '0rem',
          '& .MuiIcon-root': {
            color,
          },
        };
      },
      standardError: {
        backgroundColor: 'rgba(206, 46, 46, 0.2)',
      },
      standardSuccess: {
        backgroundColor: 'rgba(0, 161, 81, 0.3)',
      },
      standardWarning: {
        backgroundColor: 'rgba(255, 219, 91, 0.2)',
      },
      standardInfo: {
        backgroundColor: 'rgba(57, 113, 255, 0.2)',
      },
      action: {
        padding: '0rem',
        margin: '0rem',
      },
      message: {
        padding: '0rem 1rem',
      },
    },
  },
  MuiIconButton: {
    defaultProps: {
      disableRipple: true,
      disableFocusRipple: true,
    },
    styleOverrides: {
      root: {
        padding: '0rem',
        color: 'inherit',
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableRipple: true,
      variant: 'contained',
    },
    variants: [
      {
        props: { variant: 'error', disableRipple: true },
        style: ({
          theme: {
            palette: { mode, text },
          },
        }) => ({
          border: `1px solid red`,
          color: 'red',
          '& .MuiButton-startIcon .MuiIcon-root': {
            color: 'red',
          },
          ':hover': {
            backgroundColor: 'transparent',
            boxShadow: isLightTheme(mode) ? boxShadowLight : boxShadowDark,
          },

          ':disabled': {
            color: isLightTheme(mode) ? text.t5 : text.t3,
            border: `0.065rem solid ${isLightTheme(mode) ? text.t5 : text.t3}`,

            '& .MuiButton-startIcon .MuiIcon-root': {
              color: isLightTheme(mode) ? text.t5 : text.t3,
            },
          },
        }),
      },
    ],
    styleOverrides: {
      root: ({ theme: { typography } }) => ({
        borderRadius: '0.5rem',
        fontSize: typography.fontSize,
        fontWeight: typography.fontWeightRegular,
        lineHeight: '1rem',
        textTransform: 'initial',

        ':hover': {
          boxShadowLight,
        },
      }),
      contained: ({
        theme: {
          palette: { common, text, mode, background },
        },
      }) => ({
        color: common.white,
        background: linearGradient,

        ':hover': {
          boxShadow: isLightTheme(mode) ? boxShadowLight : boxShadowDark,
        },

        ':disabled': {
          color: isLightTheme(mode) ? text.t6 : text.t3,
          background: isLightTheme(mode) ? text.t5 : background.second,
        },
      }),
      outlined: ({
        theme: {
          palette: { mode, text },
        },
      }) => ({
        color: isLightTheme(mode) ? text.t1 : text.t6,
        background: 'transparent',
        border: `0.065rem solid ${isLightTheme(mode) ? text.t1 : text.t6}`,

        ':hover': {
          background: 'transparent',
          boxShadow: isLightTheme(mode) ? boxShadowLight : boxShadowDark,
          border: `0.065rem solid ${isLightTheme(mode) ? text.t1 : text.t6}`,
        },

        ':disabled': {
          color: isLightTheme(mode) ? text.t5 : text.t3,
          border: `0.065rem solid ${isLightTheme(mode) ? text.t5 : text.t3}`,

          '& .MuiButton-startIcon .MuiIcon-root': {
            color: isLightTheme(mode) ? text.t5 : text.t3,
          },
        },
      }),
      startIcon: ({
        theme: {
          palette: { text },
        },
        ownerState: { variant },
      }) => ({
        '& .MuiIcon-root': {
          color: variant === 'contained' ? text.t6 : text.t1,
        },

        '& svg': {
          fontSize: '1.5rem !important',
        },
      }),
      endIcon: ({
        theme: {
          palette: { text },
        },
        ownerState: { variant },
      }) => ({
        '& .MuiIcon-root': {
          color: variant === 'contained' ? text.t6 : text.t1,
        },

        '& svg': {
          fontSize: '1.5rem !important',
        },
      }),
      sizeLarge: {
        minWidth: '10rem',
        height: '3.125rem',
      },
      sizeMedium: {
        minWidth: '8.75rem',
        height: '2.5rem',
      },
      sizeSmall: {
        minWidth: '8.75rem',
        height: '2rem',
        padding: '0 1rem',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        minHeight: '1.875rem',
        width: 'inherit',
      },
      indicator: {
        display: 'none',
      },
      flexContainer: {
        gap: '0.625rem',
      },
    },
  },
  MuiTab: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: ({
        theme: {
          typography,
          palette: { mode, text, common, background },
        },
      }) => ({
        fontSize: '0.875rem',
        fontWeight: typography.fontWeightBold,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '1.875rem',
        minHeight: '1.875rem',
        borderRadius: '0.5rem 0.5rem 0rem 0rem',
        border: `0.063rem solid ${text.secondary}`,
        borderBottom: 'none',
        color: text.secondary,
        backgroundColor: isLightTheme(mode) ? background.default : background.paper,

        '& .MuiTab-iconWrapper': {
          margin: '0.065rem',
          stroke: text.secondary,
        },

        '&.Mui-selected': {
          backgroundColor: background.default,
          color: isLightTheme(mode) ? text.primary : common.white,
          borderColor: text.primary,

          '& .MuiTab-iconWrapper': {
            stroke: isLightTheme(mode) ? text.primary : common.white,
          },
        },
      }),
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: ({
        theme: {
          palette: { mode, text },
        },
      }) => ({
        color: isLightTheme(mode) ? text.t1 : text.t6,
      }),
      body1: ({
        theme: {
          typography: { fontSize },
        },
      }) => ({
        fontSize,
      }),
      h6: ({ theme }) => ({
        fontWeight: theme.typography.fontWeightBold,
      }),
    },
  },
  MuiCheckbox: {
    defaultProps: {
      disableRipple: true,
      icon: <Icon sx={{ fontSize: '2rem' }}>{icons.checkboxOff}</Icon>,
      checkedIcon: <Icon sx={{ fontSize: '2rem' }}>{icons.checkboxOn}</Icon>,
    },
    styleOverrides: {
      root: ({
        theme: {
          palette: { mode, primary, text },
        },
        ownerState: { size },
      }) => ({
        '& span': {
          color: isLightTheme(mode) ? primary.main : text.t6,
          fontSize: size === 'small' && '1.5rem',
          overflow: 'inherit',
        },
        ':hover': {
          backgroundColor: 'transparent',
        },
        '&.Mui-disabled span': {
          color: text.t2,
        },
      }),
    },
  },
  MuiSlider: {
    styleOverrides: {
      root: {
        height: '0.185rem',
      },
      rail: ({
        theme: {
          palette: { text },
        },
      }) => ({
        borderRadius: '0rem',
        opacity: 1,
        color: text.t4,
      }),
      thumb: {
        top: '43%',
        width: '0.625rem',
        height: '0.625rem',
        boxShadow: 'none',
        background: 'linear-gradient(94.5deg, #072F9C -20.35%, #0F79DF 109.04%)',

        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
          boxShadow: 'inherit',
        },
      },
      track: ({
        theme: {
          palette: { primary },
        },
      }) => ({
        border: 'none',
        color: primary.main,
      }),
      valueLabel: ({
        theme: {
          palette: { mode, text },
          typography: { fontWeightRegular, fontSize },
        },
      }) => ({
        fontSize,
        fontWeight: fontWeightRegular,
        color: isLightTheme(mode) ? text.t1 : text.t6,
        backgroundColor: 'transparent',
      }),
    },
  },
  MuiSwitch: {
    defaultProps: {
      size: 'medium',
      disableRipple: true,
      disableFocusRipple: true,
      disableTouchRipple: true,
    },
    styleOverrides: {
      root: ({
        theme: {
          palette: { mode, text },
        },
        ownerState: { size },
      }) => ({
        padding: size === 'small' ? '0rem' : '0.065rem',

        '&.MuiSwitch-root .MuiSwitch-switchBase': {
          padding: size === 'small' ? '0.25rem' : '0.375rem',
        },

        '&.MuiSwitch-root .MuiSwitch-thumb': {
          color: `transparent`,
          border: `0.065rem solid ${isLightTheme(mode) ? text.t2 : text.t5}`,
        },
      }),
      track: ({
        theme: {
          palette: { text, mode },
        },
      }) => ({
        boxSizing: 'border-box',
        borderRadius: '1rem',
        opacity: '1 !important',
        background: 'transparent',
        border: `0.065rem solid ${isLightTheme(mode) ? text.t2 : text.t5}`,
      }),
      switchBase: ({
        theme: {
          palette: { text, mode, common },
        },
      }) => ({
        '&.Mui-checked+.MuiSwitch-track': {
          border: `unset`,
          background: linearGradient,
        },

        '&.Mui-checked .MuiSwitch-thumb': {
          border: `0.065rem solid ${isLightTheme(mode) ? text.t6 : text.t5} !important`,
          color: `${common.white} !important`,
        },
      }),
      sizeSmall: {
        height: '1rem',
        width: '1.5rem',
        overflow: 'visible',

        '& .MuiSwitch-switchBase.Mui-checked': {
          transform: 'translateX(9px)',
        },

        '&.MuiSwitch-root .MuiSwitch-thumb': {
          boxShadow: 'none',
          height: '0.435rem',
          width: '0.435rem',
        },
      },
      sizeMedium: {
        height: '1.690rem',
        width: '2.940rem',

        '&.MuiSwitch-root .MuiSwitch-thumb': {
          boxShadow: 'none',
          height: '0.875rem',
          width: '0.875rem',
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        margin: 0,
      },
      label: ({ theme: { palette } }) => ({
        '&.MuiFormControlLabel-label.Mui-disabled': {
          color: palette.text.t2,
        },
      }),
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        '&.Mui-error': {
          marginTop: '0.5rem',
          marginLeft: '0rem',
          fontSize: '0.75rem',
        },
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      fullWidth: true,
      size: 'small',
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        WebkitBackgroundClip: 'text',
      },
    },
  },
  MuiInput: {
    defaultProps: {
      fullWidth: true,
    },
    styleOverrides: {
      root: {
        fontSize: '0.875rem',
      },
      input: ({
        theme: {
          palette: { text },
        },
      }) => ({
        padding: '4px 0 0px',
        '&.Mui-disabled': {
          WebkitTextFillColor: text.t3,
          borderBottom: `0.065rem solid ${text.t3}`,
        },
      }),
    },
  },
  MuiOutlinedInput: {
    defaultProps: {
      fullWidth: true,
    },
    styleOverrides: {
      root: ({
        theme: {
          palette: { mode, primary, text, background },
        },
        ownerState: { fullWidth },
      }) => ({
        borderRadius: '0.5rem',
        width: !fullWidth && '18.75rem',
        fontSize: '0.875rem',
        paddingTop: '0rem',
        paddingRight: '0rem',
        paddingBottom: '0rem',
        paddingLeft: '0rem',
        backgroundColor: !isLightTheme(mode) && background.second,
        minHeight: '2.5rem',

        fieldset: {
          borderColor: primary.main,

          legend: {
            fontSize: '1em',
          },
        },

        '&:hover fieldset': {
          border: `0.125rem solid ${primary.main} !important`,
        },

        '&.Mui-disabled fieldset': {
          borderColor: `${text.t3} !important`,
          borderWidth: '0.065rem !important',
        },

        '&.Mui-focused fieldset': {
          borderWidth: '0.065rem !important',
        },
      }),
      input: ({
        theme: {
          palette: { mode, text },
        },
      }) => ({
        color: isLightTheme(mode) ? text.t1 : text.t5,

        '&.Mui-disabled': {
          WebkitTextFillColor: text.t3,
        },
      }),
      inputSizeSmall: ({ ownerState: { startAdornment, endAdornment } }) => ({
        paddingTop: '0.5rem',
        paddingRight: endAdornment ? '0rem' : '1rem',
        paddingBottom: '0.5rem',
        paddingLeft: startAdornment ? '0rem' : '1rem',
      }),
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: ({
        theme: {
          palette: { mode, text },
        },
      }) => ({
        '& .MuiIcon-root': {
          color: isLightTheme(mode) ? text.t3 : text.t6,
        },

        margin: '1rem',
      }),
    },
  },
  MuiAutocomplete: {
    defaultProps: {
      fullWidth: true,
      size: 'small',
      popupIcon: <Icon>{icons.down}</Icon>,
      clearIcon: <Icon sx={{ fontSize: '1.25rem' }}>{icons.close}</Icon>,
    },
    styleOverrides: {
      inputRoot: {
        padding: '0rem',
      },
      input: ({
        theme: {
          palette: { mode, text, common },
        },
      }) => ({
        padding: '0.25rem 0rem 0.25rem 0.625rem !important',
        fontSize: '0.875rem',
        color: isLightTheme(mode) ? common.black : text.t5,

        '&.Mui-disabled': {
          WebkitTextFillColor: text.t1,
        },
      }),
      paper: {
        margin: '0.5rem 0rem',
        boxShadow: '0rem 0.315rem 0.315rem rgba(52, 52, 52, 0.2)',
      },
      listbox: ({
        theme: {
          palette: { mode, text },
        },
      }) => ({
        backgroundColor: isLightTheme(mode) ? text.t7 : text.t1,

        ...getScrollStyles(mode, text),
      }),
      option: ({
        theme: {
          palette: { mode, text },
        },
      }) => ({
        color: isLightTheme(mode) ? text.t1 : text.t5,
        fontSize: '1rem',
        padding: '0.469rem 1rem',
        lineHeight: '0.894rem',

        ':hover': {
          backgroundColor: isLightTheme(mode) ? text.t5 : text.t8,
        },
      }),
      popupIndicator: ({
        theme: {
          palette: { mode, text },
        },
      }) => ({
        color: isLightTheme(mode) ? text.t1 : text.t6,
      }),
      clearIndicator: ({
        theme: {
          palette: { mode, text },
        },
      }) => ({
        color: isLightTheme(mode) ? text.t1 : text.t6,
      }),
      endAdornment: {
        padding: '0rem',
        top: 'calc(50% - 0.813rem)',
      },
      noOptions: ({
        theme: {
          palette: { mode, text },
        },
      }) => ({
        color: isLightTheme(mode) ? text.t1 : text.t5,
        fontSize: '1rem',
        padding: '0.435rem 1rem',
        lineHeight: '0.813rem',
      }),
      tag: ({
        theme: {
          palette: { common },
        },
      }) => ({
        border: `1px solid ${common.black}`,
        height: '1.75rem',
        padding: '0.5rem',
        backgroundColor: 'transparent',
        fontSize: '0.75rem',
        maxWidth: '7.25rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }),
    },
  },
  MuiSelect: {
    defaultProps: {
      fullWidth: true,
      IconComponent: props => <Icon {...props}>{icons.down}</Icon>,
      size: 'small',
    },
    styleOverrides: {
      outlined: {
        paddingRight: '2.5rem !important',
      },
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      icon: ({
        theme: {
          palette: { mode, text },
        },
        ownerState: { variant } = {},
      }) => ({
        color: isLightTheme(mode) ? text.t1 : text.t6,
        right: variant === 'outlined' && '0.5rem',
      }),
    },
  },
  MuiIcon: {
    defaultProps: {
      baseClassName: 'custom-icon',
    },
    styleOverrides: {
      root: () => ({
        fontSize: '1.5rem',
      }),
      fontSizeLarge: {
        fontSize: '2rem',
      },
      fontSizeSmall: {
        fontSize: '1rem',
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: ({
        theme: {
          palette: { text, mode },
        },
      }) => ({
        backgroundColor: isLightTheme(mode) ? text.t7 : text.t1,
        backgroundImage: 'none',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',

        ...getScrollStyles(mode, text),
      }),
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({
        theme: {
          palette: { mode, text },
        },
      }) => ({
        color: isLightTheme(mode) ? text.t1 : text.t5,
        fontSize: '0.875rem',
        padding: '0.435rem 1rem',
        lineHeight: '0.894rem',
      }),
    },
  },
  MuiTooltip: {
    defaultProps: {
      disableInteractive: true,
    },
    styleOverrides: {
      tooltip: ({
        theme: {
          typography,
          palette: { secondary },
        },
      }) => ({
        backgroundColor: secondary.main,
        padding: '1rem 0.875rem 0.875rem 0.875rem',
        fontWeight: typography.fontWeightRegular,
        borderRadius: '0.5rem',
        fontSize: '1rem',
        textAlign: 'center',
      }),
      arrow: ({
        theme: {
          palette: { secondary },
        },
      }) => ({
        color: secondary.main,
        fontSize: '1.56rem',
      }),
    },
  },
  MuiChip: {
    styleOverrides: {
      root: ({ theme, ownerState }) => {
        const heightMapper = {
          small: '1.75rem',
          medium: '2.5rem',
        };

        return {
          color: isLightTheme(theme.palette.mode) ? theme.palette.common.black : theme.palette.common.white,
          padding: '0.5rem 0.25rem',
          borderRadius: '0.5rem',
          height: heightMapper[ownerState.size] ?? '2.5rem',
          '&.Mui-disabled': {
            backgroundColor: 'none',
          },
          '&.Mui-deletable': {
            paddingRight: '0',
          },
          '&.Mui-sizeSmall': {
            height: '1.75rem',
          },
        };
      },
      colorPrimary: ({ theme }) => ({
        color: theme.palette.common.white,
        background: 'linear-gradient(94.5deg, #072F9C -20.35%, #0F79DF 109.04%)',
      }),
    },
  },
  MuiBadge: {
    styleOverrides: {
      standard: ({ theme }) => ({
        color: theme.palette.common.white,
      }),
    },
  },
  MuiTable: {
    styleOverrides: {
      root: ({
        theme: {
          palette: { mode, common, background },
        },
      }) => ({
        color: isLightTheme(mode) ? common.black : common.white,
        backgroundColor: isLightTheme(mode) ? background.default : background.paper,
        borderCollapse: 'unset',
      }),
    },
  },
  MuiTableContainer: {
    styleOverrides: {
      root: ({
        theme: {
          palette: { text, mode },
        },
      }) => ({
        ...getScrollStyles(mode, text),
      }),
    },
  },
  MuiTableBody: {
    styleOverrides: {
      root: ({
        theme: {
          palette: { mode, common, background },
        },
      }) => ({
        color: isLightTheme(mode) ? common.black : common.white,
        backgroundColor: isLightTheme(mode) ? background.default : background.paper,
      }),
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: ({
        theme: {
          palette: { text, tableColors },
        },
      }) => ({
        '&.success': {
          background: tableColors.ok,
        },
        '&.error': {
          background: tableColors.error,
        },
        '&.warning': {
          background: tableColors.warning,
        },
        '&.notEnoughData': {
          background: tableColors.notEnoughData,
        },
        '&.unavailable': {
          background: tableColors.unavailable,
          color: text.t4,
        },
        '&.unpaid': {
          background: tableColors.unpaid,

          '& .MuiTableCell-root': {
            color: text.unpaid,
          },
        },
        '&.selected': {
          animation: 'fade 3s forwards',

          '@keyframes fade': {
            from: {
              background: `${tableColors.selected}50`,
            },
            to: {
              background: `${tableColors.selected}20`,
            },
          },
        },
      }),
    },
  },
  MuiTableCell: {
    styleOverrides: {
      head: ({
        theme: {
          palette: { mode, common, background, text, primary },
        },
      }) => {
        return {
          /**
           * TODO: The position: 'relative' affects the sticky header functionality, causing it to not work as expected.
           *       Double-check the impact of `position: 'relative'` on table cells.
           *       It might affect the layout or alignment of the cells.
           *       Ensure everything looks correct and functions as expected, and remove comment property
           *       if it's unnecessary or causing issues once confirmed.
           */
          background: background.third,
          color: isLightTheme(mode) ? common.black : common.white,
          borderBottom: 'unset',
          borderStyle: 'solid',
          borderColor: text.t1,
          borderBottomWidth: '2px',
          borderLeftWidth: 0,
          borderTopWidth: 0,
          borderRightWidth: 0,
          lineHeight: 1.2,
          height: '2.5rem',
          verticalAlign: 'middle',
          textWrap: 'nowrap',
          '&.hightlight': {
            background: `${primary.main} !important`,
            color: common.white,
          },
        };
      },
      body: ({
        theme: {
          palette: { common, primary },
        },
      }) => ({
        position: 'relative',
        lineHeight: 1.2,
        fontSize: '14px',
        padding: 0,
        height: '2.5rem',
        borderColor: '#747474',
        '&.groupHeaderCell': {
          pointerEvents: 'none',
          height: '2.2rem',
        },
        ':hover': {
          '&.hightlight': {
            background: primary.main,
            color: common.white,
          },

          '&.hightlight p': {
            color: common.white,
          },

          '&.hightlight a': {
            color: common.white,
          },
        },

        '&.hightlight': {
          background: '#c0ddff !important',
        },

        '&.error': {
          background: '#CE2E2E66 !important',
          '&:hover': {
            background: '#CE2E2E !important',
            color: common.white,
            '&:after': {
              display: 'none',
            },
          },
        },
      }),
    },
  },
  MuiSnackbar: {
    variants: [
      {
        props: { variant: 'success' },
        style: {},
      },
      {
        props: { variant: 'info' },
        style: {},
      },
      {
        props: { variant: 'error' },
        style: {},
      },
    ],
    styleOverrides: {
      root: ({
        theme: {
          palette: { error, primary, success, text },
        },
        ownerState: { variant },
      }) => {
        let backgroundColor = error.main;

        switch (variant) {
          case 'success':
            backgroundColor = success.main;
            break;
          case 'info':
            backgroundColor = primary.main;
            break;
        }

        return {
          width: '25rem',

          '& .MuiAlert-root': {
            minHeight: '3.375rem',
            display: 'flex',
            alignItems: 'center',
            backgroundColor,

            '& .MuiAlert-icon': {
              '& .MuiIcon-root': {
                color: text.t6,
              },
            },

            '& .MuiAlert-message': {
              paddingRight: '0.685rem',
              paddingLeft: '0.5rem',
              overflow: 'hidden',
            },

            '& .MuiAlert-action': {
              marginTop: 0,

              '& .MuiIconButton-root': {
                color: text.t6,
              },
            },
          },
        };
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: ({
        theme: {
          palette: { mode, text },
        },
      }) => ({
        boxShadow: '0rem 0.313rem 0.313rem rgba(52, 52, 52, 0.2)',
        borderRadius: '0.5rem',
        padding: '1.5rem',
        width: '18.75rem',
        backgroundColor: isLightTheme(mode) ? text.t6 : text.t4,
      }),
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '1.5rem',
        padding: '0rem',
      },
      title: ({
        theme: {
          palette: { mode, text },
        },
      }) => ({
        fontSize: '1rem',
        color: isLightTheme(mode) ? text.t1 : text.t6,
      }),
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: '0rem',
        marginTop: '1.5rem',
        marginBottom: '1.5rem',
      },
    },
  },
  MuiCardActions: {
    styleOverrides: {
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0rem',
        marginTop: '1.5rem',
      },
    },
  },
  MuiCircularProgress: {
    styleOverrides: {
      root: ({
        theme: {
          palette: { common, mode },
        },
      }) => ({
        color: isLightTheme(mode) ? common.black : common.white,
      }),
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: ({
        theme: {
          palette: { mode, text },
        },
      }) => ({
        ...getScrollStyles(mode, text),
      }),
    },
  },
};

export default componentSettings;
