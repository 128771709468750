export const OPEN_HEADER_HEIGHT = '8.25rem';
export const CLOSE_HEADER_HEIGHT = '3.5rem';
export const COLLAPSED_INFO_BADGE = '15rem';
export const UNCOLLAPSED_INFO_BADGE = '10rem';

export const COLORS = {
  white: '#ffffff',
  black: '#000000',
  primaryMain: '#072F9C',
  secondaryMain: '#3245AF',
  secondaryDark: '#3245AF',
};

export const chartsColors: Record<string, string> = {
  c1: '#7A52CC',
  c2: '#F76707',
  c3: '#4C6EF4',
  c4: '#EE5557',
  c5: '#0DBF67',
  c6: '#99D430',
  c7: '#22B8D0',
  c8: '#FCC419',
  c9: '#C2255C',
};
