import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps, Icon } from '@mui/material';
import icons from '@app/assets/iconFont';
import { isFunction } from '@app/v2/shared/helpers';

interface Props extends ButtonProps {
  handleClick: Common.VoidCallBack;
  loading?: boolean;
}

const CSDWipeVideoCameraBtn = ({ loading, handleClick, ...other }: Props) => {
  const { t } = useTranslation('features', { keyPrefix: 'wipeVideoCamera' });

  const handleClickChecker = () => {
    if (!isFunction(handleClick) || loading) return;
    handleClick();
  };

  return (
    <Button size="large" variant="outlined" startIcon={<Icon>{icons.wiper}</Icon>} onClick={handleClickChecker} {...other}>
      {loading ? t('inProgress') : t('button')}
    </Button>
  );
};

export default CSDWipeVideoCameraBtn;
