import FETCH_VMS_NEW_CONFIGURATION from './fetchConfiguration.graphql';
import FETCH_VMS_NEW_CONFIGURATIONS from './fetchConfigurations.graphql';
import FETCH_VMS_NEW_DEVICE from './fetchDevice.graphql';
import FETCH_VMS_NEW_DEVICES from './fetchDevices.graphql';
import FETCH_VMS_NEW_PLAYLIST from './fetchPlaylist.graphql';
import FETCH_VMS_NEW_PLAYLISTS from './fetchPlaylists.graphql';
import FETCH_VMS_NEW_SIGN from './fetchSign.graphql';
import FETCH_VMS_NEW_TEMPLATE from './fetchTemplate.graphql';
import FETCH_VMS_NEW_TEMPLATE_MESSAGES from './fetchTemplateMessages.graphql';
import FETCH_VMS_NEW_TEMPLATE_SIGNS from './fetchTemplateSigns.graphql';
import FETCH_VMS_NEW_STATIONS from './fetchVmsNewStations.graphql';
import FETCH_OPI_HISTORY from './fetchOPIHistory.graphql';
import FETCH_VMS_NEW_SIGNS_SELECTOR from './fetchSignsSelector.graphql';
import FETCH_VMS_NEW_TEMPLATES_SELECTORS from './fetchTemplatesSelectors.graphql';
import FETCH_VMS_NEW_PLAYLISTS_SELECTORS from './fetchPlaylistsSelectors.graphql';
import FETCH_VMS_NEW_CONFIGURATION_SELECTORS from './fetchConfigurationSelectors.graphql';
import FETCH_VMS_NEW_CONFIGURATION_COLORS_SELECTORS from './fetchConfigurationColorsSelectors.graphql';
import FETCH_VMS_NEW_CONFIGURATION_SIZES_SELECTORS from './fetchConfigurationSizesSelectors.graphql';
import FETCH_VMS_NEW_CONFIGURATION_TYPES_SELECTORS from './fetchConfigurationTypesSelectors.graphql';
import FETCH_VMS_NEW_CONFIGURATION_ALL_SELECTORS from './fetchConfigurationAllSelectors.graphql';

const vmsNewQueries = {
  configuration: FETCH_VMS_NEW_CONFIGURATION,
  configurations: FETCH_VMS_NEW_CONFIGURATIONS,
  device: FETCH_VMS_NEW_DEVICE,
  devices: FETCH_VMS_NEW_DEVICES,
  playlist: FETCH_VMS_NEW_PLAYLIST,
  playlists: FETCH_VMS_NEW_PLAYLISTS,
  sign: FETCH_VMS_NEW_SIGN,
  template: FETCH_VMS_NEW_TEMPLATE,
  templateMessages: FETCH_VMS_NEW_TEMPLATE_MESSAGES,
  templateSigns: FETCH_VMS_NEW_TEMPLATE_SIGNS,
  stations: FETCH_VMS_NEW_STATIONS,
  history: FETCH_OPI_HISTORY,
  signsSelector: FETCH_VMS_NEW_SIGNS_SELECTOR,
  playlistsSelectors: FETCH_VMS_NEW_PLAYLISTS_SELECTORS,
  templatesSelectors: FETCH_VMS_NEW_TEMPLATES_SELECTORS,
  configurationSelectors: FETCH_VMS_NEW_CONFIGURATION_SELECTORS,
  configurationColorsSelectors: FETCH_VMS_NEW_CONFIGURATION_COLORS_SELECTORS,
  configurationSizesSelectors: FETCH_VMS_NEW_CONFIGURATION_SIZES_SELECTORS,
  configurationTypesSelectors: FETCH_VMS_NEW_CONFIGURATION_TYPES_SELECTORS,
  configurationSelectorsAll: FETCH_VMS_NEW_CONFIGURATION_ALL_SELECTORS,
};

export default vmsNewQueries;
