import React from 'react';
import { Tooltip, Button, Stack, Box, Grid } from '@mui/material';
import { tooltip, arrowTooltip, placement } from './example';
import CSDKitDocBlock from '../../../shared/ui/CSDKitDocBlock/CSDKitDocBlock';

const CsdTooltips = () => {
  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="Tooltip" codeExample={tooltip}>
        <Tooltip title="Температура поверхности">
          <Button>Hover to tooltip</Button>
        </Tooltip>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Arrow tooltip" codeExample={arrowTooltip}>
        <Tooltip title="Температура поверхности" arrow>
          <Button>Hover to tooltip</Button>
        </Tooltip>
      </CSDKitDocBlock>
      <CSDKitDocBlock title="Tooltip placements" codeExample={placement}>
        <Box sx={{ width: 500, minHeight: 250 }}>
          <Grid container justifyContent="center">
            <Grid item>
              <Tooltip title="Add" placement="top-start">
                <Button variant="text">top-start</Button>
              </Tooltip>
              <Tooltip title="Add" placement="top" arrow>
                <Button variant="text">top</Button>
              </Tooltip>
              <Tooltip title="Add" placement="top-end">
                <Button variant="text">top-end</Button>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item xs={6}>
              <Tooltip title="Add" placement="left-start">
                <Button variant="text">left-start</Button>
              </Tooltip>
              <br />
              <Tooltip title="Add" placement="left" arrow>
                <Button variant="text">left</Button>
              </Tooltip>
              <br />
              <Tooltip title="Add" placement="left-end">
                <Button variant="text">left-end</Button>
              </Tooltip>
            </Grid>
            <Grid item container xs={6} alignItems="flex-end" direction="column">
              <Grid item>
                <Tooltip title="Add" placement="right-start">
                  <Button variant="text">right-start</Button>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Add" placement="right" arrow>
                  <Button variant="text">right</Button>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Add" placement="right-end">
                  <Button variant="text">right-end</Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item>
              <Tooltip title="Add" placement="bottom-start">
                <Button variant="text">bottom-start</Button>
              </Tooltip>
              <Tooltip title="Add" placement="bottom" arrow>
                <Button variant="text">bottom</Button>
              </Tooltip>
              <Tooltip title="Add" placement="bottom-end">
                <Button variant="text">bottom-end</Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </CSDKitDocBlock>
    </Stack>
  );
};

export default CsdTooltips;
