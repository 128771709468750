import { KeyPrefix } from 'react-i18next';

export const CHART_EXPORT_HEIGHT = 1080;
export const CHART_EXPORT_WIDTH = 1920;
export const STEP_OFFSET = 32;

export const CHART_TYPES: { [key: string]: KeyPrefix<'charts'> } = {
  METEO: 'type.meteo',
  LINEAR: 'type.line',
  TRAFFIC: 'type.traffic',
  PLAN: 'type.plan',
  ROAD_PLAN: 'type.roadPlan',
};

export const menuItemDefinitions: { [key: string]: { text: KeyPrefix<'charts'> } } = {
  viewFullscreen: {
    text: 'menuDefinitions.viewFullscreen',
  },
  printChart: {
    text: 'menuDefinitions.printChart',
  },
  downloadPNG: {
    text: 'menuDefinitions.downloadPNG',
  },
  downloadJPEG: {
    text: 'menuDefinitions.downloadJPEG',
  },
  downloadPDF: {
    text: 'menuDefinitions.downloadPDF',
  },
  downloadSVG: {
    text: 'menuDefinitions.downloadSVG',
  },
};

export const parametersWithoutLabels: string[] = ['windDirGr', 'precipitationType', 'statusDamp2', 'statusDamp', 'cars'];
export const offsetKeys: KeyPrefix<'parameters'>[] = ['windDirGr', 'statusDamp', 'statusDamp2', 'cars'];
export const statusDampYValue = 1.4;
export const carsYValue = 1.4;
export const windDirGrYValue = 1;
