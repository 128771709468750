import { Profile, Notification } from '@app/v2/features/notifications/types';

export const INITIAL_NOTIFICATION_PROFILE_ID = 0;
export const MAX_NOTIFICATION_PROFILES_AMOUNT = 6;

export const NOTIFICATION_SETTINGS: Notification[] = [
  {
    id: 1,
    type: 'winterSlipperiness',
    rank: 'warning',
    alias: 'raFrost',
    checked: false,
  },
  {
    id: 2,
    type: 'temperatureChange',
    rank: 'warning',
    alias: 'raZeroCrossingToMinus',
    checked: false,
  },
  {
    id: 3,
    type: 'temperatureChange',
    rank: 'warning',
    alias: 'faZeroCrossingToMinus',
    checked: false,
  },
  {
    id: 4,
    type: 'temperatureChange',
    rank: 'warning',
    alias: 'raQuickCooling',
    checked: false,
  },
  {
    id: 5,
    type: 'temperatureChange',
    rank: 'warning',
    alias: 'raZeroCrossingToPlus',
    checked: false,
  },
  {
    id: 6,
    type: 'temperatureChange',
    rank: 'warning',
    alias: 'faZeroCrossingToPlus',
    checked: false,
  },
  {
    id: 7,
    type: 'wind',
    rank: 'warning',
    alias: 'raSevereWind',
    checked: false,
  },
  {
    id: 8,
    type: 'temperatureChange',
    rank: 'alert',
    alias: 'raZeroCrossCooling',
    checked: false,
  },
  {
    id: 9,
    type: 'temperatureChange',
    rank: 'warning',
    alias: 'faQuickCooling',
    checked: false,
  },
  {
    id: 10,
    type: 'solidAndMixedPrecipitation',
    rank: 'alert',
    alias: 'faLongSnow',
    checked: false,
  },
  {
    id: 11,
    type: 'winterSlipperiness2Days',
    rank: 'alert',
    alias: 'faIceCrustedGround',
    checked: false,
  },
  {
    id: 12,
    type: 'wind',
    rank: 'warning',
    alias: 'faStrongWind',
    checked: false,
  },
  {
    id: 13,
    type: 'winterSlipperiness',
    rank: 'alert',
    alias: 'raBlackIce',
    checked: false,
  },
  {
    id: 14,
    type: 'temperatureChange',
    rank: 'warning',
    alias: 'faZeroCrossCooling',
    checked: false,
  },
  {
    id: 15,
    type: 'winterSlipperiness2Days',
    rank: 'alert',
    alias: 'faPackedSnow',
    checked: false,
  },
  {
    id: 16,
    type: 'liquidPrecipitation',
    rank: 'warning',
    alias: 'faLongRain',
    checked: false,
  },
  {
    id: 17,
    type: 'winterSlipperiness',
    rank: 'alert',
    alias: 'raPackedSnow',
    checked: false,
  },
  {
    id: 18,
    type: 'winterSlipperiness',
    rank: 'alert',
    alias: 'raIceCrustedGround',
    checked: false,
  },
  {
    id: 19,
    type: 'wind',
    rank: 'alert',
    alias: 'raExtremeWind',
    checked: false,
  },
  {
    id: 20,
    type: 'temperatureChange',
    rank: 'alert',
    alias: 'raSevereCooling',
    checked: false,
  },
  {
    id: 21,
    type: 'winterSlipperiness',
    rank: 'warning',
    alias: 'raLightLooseSnow',
    checked: false,
  },
  {
    id: 22,
    type: 'winterSlipperiness2Days',
    rank: 'alert',
    alias: 'faSleet',
    checked: false,
  },
  {
    id: 23,
    type: 'liquidPrecipitation',
    rank: 'warning',
    alias: 'raSevereRain',
    checked: false,
  },
  {
    id: 24,
    type: 'solidAndMixedPrecipitation',
    rank: 'warning',
    alias: 'faLongRainWithSnow',
    checked: false,
  },
  {
    id: 25,
    type: 'solidAndMixedPrecipitation',
    rank: 'alert',
    alias: 'faLightLooseSnow',
    checked: false,
  },
  {
    id: 26,
    type: 'winterSlipperiness',
    rank: 'alert',
    alias: 'raSleet',
    checked: false,
  },
  {
    id: 27,
    type: 'solidAndMixedPrecipitation',
    rank: 'alert',
    alias: 'raSevereRainWithSnow',
    checked: false,
  },
  {
    id: 28,
    type: 'liquidPrecipitation',
    rank: 'warning',
    alias: 'faExtremeRain',
    checked: false,
  },
  {
    id: 29,
    type: 'liquidPrecipitation',
    rank: 'warning',
    alias: 'raExtremeRain',
    checked: false,
  },
  {
    id: 30,
    type: 'solidAndMixedPrecipitation',
    rank: 'alert',
    alias: 'raExtremeRainWithSnow',
    checked: false,
  },
  {
    id: 31,
    type: 'wind',
    rank: 'warning',
    alias: 'faExtremeWind',
    checked: false,
  },
  {
    id: 32,
    type: 'temperatureChange',
    rank: 'warning',
    alias: 'raSevereWarming',
    checked: false,
  },
  {
    id: 33,
    type: 'temperatureChange',
    rank: 'alert',
    alias: 'raExtremeWarming',
    checked: false,
  },
  {
    id: 34,
    type: 'temperatureChange',
    rank: 'alert',
    alias: 'raExtremeCooling',
    checked: false,
  },
  {
    id: 35,
    type: 'solidAndMixedPrecipitation',
    rank: 'alert',
    alias: 'faHeavyLooseSnow',
    checked: false,
  },
  {
    id: 36,
    type: 'solidAndMixedPrecipitation',
    rank: 'alert',
    alias: 'raHeavyLooseSnow',
    checked: false,
  },
  {
    id: 37,
    type: 'temperatureChange',
    rank: 'alert',
    alias: 'faExtremeLowTemp',
    checked: false,
  },
  {
    id: 38,
    type: 'temperatureChange',
    rank: 'alert',
    alias: 'faExtremeHighTemp',
    checked: false,
  },
  {
    id: 39,
    type: 'solidAndMixedPrecipitation',
    rank: 'alert',
    alias: 'raExtremeSnow',
    checked: false,
  },
  {
    id: 40,
    type: 'solidAndMixedPrecipitation',
    rank: 'alert',
    alias: 'faExtremeRainWithSnow',
    checked: false,
  },
  {
    id: 41,
    type: 'solidAndMixedPrecipitation',
    rank: 'alert',
    alias: 'faExtremeSnow',
    checked: false,
  },
];

export const INITIAL_NOTIFICATION_PROFILE: Profile = {
  id: INITIAL_NOTIFICATION_PROFILE_ID,
  title: '',
  isActive: true,
  notifications: NOTIFICATION_SETTINGS,
  places: [],
  roadParts: [],
};
