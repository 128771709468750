import CREATE_CONFIGURATION from './configuration/createConfiguration.graphql';
import DELETE_CONFIGURATION from './configuration/deleteConfiguration.graphql';
import UPDATE_CONFIGURATION from './configuration/updateConfiguration.graphql';
import UPDATE_DEVICE from './device/updateDevice.graphql';
import ADD_PLAYLIST_TO_VMS_STATION from './playlist/addPlaylist.graphql';
import CREATE_PLAYLIST from './playlist/createPlaylist.graphql';
import DELETE_PLAYLIST from './playlist/deletePlaylist.graphql';
import UPDATE_PLAYLIST from './playlist/updatePlaylist.graphql';
import CREATE_SIGN from './sign/createSign.graphql';
import DELETE_SIGN from './sign/deleteSign.graphql';
import UPDATE_SIGN from './sign/updateSign.graphql';
import CREATE_TEMPLATE from './template/createTemplate.graphql';
import DELETE_TEMPLATE from './template/deleteTemplate.graphql';
import UPDATE_TEMPLATE from './template/updateTemplate.graphql';
import CREATE_STATION_LOG_REPORT from './stationLogReport/createReport.graphql';
import POWER_OFF_TEMPLATE from './management/powerOffTemplate.graphql';
import POWER_ON_TEMPLATE from './management/powerOnTemplate.graphql';
import ADD_PLAYLIST_TO_STATION_BY_ID from './management/addPlaylistToStationById.graphql';
import FORCE_UPDATE_PLAYLIST_ON_STATION from './management/forceUpdatePlaylistOnStation.graphql';

const configuration = {
  create: CREATE_CONFIGURATION,
  delete: DELETE_CONFIGURATION,
  update: UPDATE_CONFIGURATION,
};

const device = {
  update: UPDATE_DEVICE,
};

const playlist = {
  add: ADD_PLAYLIST_TO_VMS_STATION,
  create: CREATE_PLAYLIST,
  delete: DELETE_PLAYLIST,
  update: UPDATE_PLAYLIST,
};

const sign = {
  create: CREATE_SIGN,
  delete: DELETE_SIGN,
  update: UPDATE_SIGN,
};

const template = {
  create: CREATE_TEMPLATE,
  delete: DELETE_TEMPLATE,
  update: UPDATE_TEMPLATE,
};

const stationLogReport = {
  create: CREATE_STATION_LOG_REPORT,
};

const management = {
  powerOff: POWER_OFF_TEMPLATE,
  powerOn: POWER_ON_TEMPLATE,
  addPlaylistToStation: ADD_PLAYLIST_TO_STATION_BY_ID,
  forceUpdatePlaylistOnStations: FORCE_UPDATE_PLAYLIST_ON_STATION,
};

const vmsNewMutations = {
  configuration,
  device,
  playlist,
  sign,
  template,
  stationLogReport,
  management,
};

export default vmsNewMutations;
