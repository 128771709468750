import React, { useCallback, useMemo } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Button, Stack, Select, MenuItem } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ProfileSettingsDialog from '@app/components/dialogs/profile-settings-dialog/ProfileSettingsDialog';
import { ProfilesTypes } from '@app/v2/shared/enums';
import { useDialog } from '@app/v2/shared/hooks';

type Props = {
  activeProfile: Profiles.CommonProfile;
  profiles: Profiles.CommonProfile[];
  saveProfiles: (profiles: Profiles.CommonProfile[]) => Promise<void>;
  onChangeProfile: (profile: Profiles.CommonProfile) => void;
} & WithTranslation<'common'>;

const LinearChartSettingsPanel = ({ activeProfile, profiles, saveProfiles, onChangeProfile, t }: Props) => {
  const classes = useStyles();
  const openProfileSettingsDialog = useDialog(ProfileSettingsDialog);

  const handleChangeProfile = useCallback(
    activeId => {
      const profile = profiles.find(({ id }) => id === activeId);
      onChangeProfile(profile);
    },
    [profiles, onChangeProfile],
  );

  const handleOpenProfileSettings = useCallback(() => {
    openProfileSettingsDialog({ profiles, onSave: saveProfiles, type: ProfilesTypes.linearGraph });
  }, [openProfileSettingsDialog, profiles, saveProfiles]);

  const selectData = useMemo(() => {
    return profiles.map(({ id, label }) => ({ value: id, label }));
  }, [profiles]);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="flex-start" className={classes.settingsPanel}>
      <Select label={`${t('formFieldsLabel.profile')}:`} value={activeProfile?.id || null} onChange={handleChangeProfile}>
        {selectData.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <Button variant="outlined" color="primary" startIcon={<SettingsIcon />} onClick={handleOpenProfileSettings}>
        {t('buttons.settings')}
      </Button>
    </Stack>
  );
};

const useStyles = makeStyles(() => ({
  settingsPanel: {
    height: '48px',
    marginBottom: '16px',
  },
}));

export default withTranslation('common')(LinearChartSettingsPanel);
