import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Stack, Typography, TypographyProps, CardContent, CardContentProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSDInput } from '@app/modules/kit-module/shared/ui';
import { useChat, useAppSelector } from '@app/v2/shared/hooks';

const FormContent = ({ t }: I18N.TranslationFunction<'auth', 'passwordRecovery.cardContent'>) => {
  const { isChatOpen, handleChangeChatStatus } = useChat();
  const { values, handleChange, touched, errors } = useFormikContext<Auth.PasswordRecoveryStepOneParams>();
  const passwordRecoveryStatus = useAppSelector(state => state.auth.passwordRecoveryStatus);

  return (
    <StyledCardContent>
      <Stack direction="column" spacing="1.5rem">
        <Typography align="center" variant="body2">
          {!passwordRecoveryStatus ? t('cardSupportMessage') : t('cardSupportMessageProgress')}
        </Typography>

        <StyledTechSupportTypography align="center" variant="body2" onClick={() => handleChangeChatStatus(!isChatOpen)}>
          {t('techSupportLink')}
        </StyledTechSupportTypography>

        {!passwordRecoveryStatus && (
          <CSDInput
            name="recovery-login"
            value={values.login}
            onChange={handleChange('login')}
            helperText={touched.login ? errors.login : ''}
            error={touched.login && Boolean(errors.login)}
            placeholder={t('login.placeholder')}
          />
        )}
      </Stack>
    </StyledCardContent>
  );
};

const StyledCardContent = styled(CardContent)<CardContentProps>({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  padding: '0 1.5rem !important',
});

const StyledTechSupportTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.text.t2,
  margin: '0 auto',
  textAlign: 'center',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export default withTranslation('auth', { keyPrefix: 'passwordRecovery.cardContent' })(memo(FormContent));
