const main = {
  autocompleteOptionsLimit: process.env.OPTIONS_LIMIT ? Number(process.env.OPTIONS_LIMIT) : 100,
  develop: process.env.NODE_ENV === 'development',
  production: process.env.NODE_ENV === 'production',
  api: {
    host: process.env.REACT_APP_API_HOST,
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    socketUrl: process.env.REACT_APP_API_SOCKET_URL,
    nominatim: process.env.REACT_APP_NOMINATIM_REVERSE_GEOCODING,
  },
  links: {
    videoImagesHost: process.env.REACT_APP_VIDEO_IMAGES_HOST,
    downloadHost: process.env.REACT_APP_DOWNLOAD_HOST,
  },
};

export default main;
