import { LeafletContext, MapLayer, withLeaflet } from 'react-leaflet';
import type { GridLayerProps } from 'react-leaflet/lib/types';
import L from 'leaflet';
import '@app/libs/Yandex';

type Props = { type: string; options?: Object; leaflet: LeafletContext; traffic?: boolean } & GridLayerProps;

class YandexTileLayer extends MapLayer<Props> {
  private _yandex: any;

  createLeafletElement(props: Props) {
    return this.getLeafletElement(props);
  }

  private getLeafletElement(props: Props) {
    const { type, options, traffic } = props;
    const yandex = L.yandex(type, options);

    return traffic ? yandex.on('load', this.traffic) : yandex;
  }

  private traffic() {
    const { ymaps } = window;
    const actualProvider = new ymaps.traffic.provider.Actual({}, { infoLayerShown: true });
    // eslint-disable-next-line no-underscore-dangle
    actualProvider.setMap(this._yandex);
  }
}

export default withLeaflet(YandexTileLayer);
