const icons = {
  appstore: 'appstore',
  google: 'google',

  checkboxOff: 'checkbox-off',
  checkboxOn: 'checkbox-on',

  menu: 'menu',

  adult: 'Adult',
  affected: 'Affected',
  child: 'Child',
  person: 'Person',
  walker: 'walker',

  archive: 'Archive',
  attention: 'Attention',
  bell: 'Bell',
  book: 'Book',
  both: 'Both',
  brand: 'Brand',
  burger: 'Burger',
  calender: 'Calender',
  camera: 'Camera',
  cameraRoad: 'Camera-road',
  chart: 'Chart',
  city: 'City',
  clip: 'Clip',
  close: 'Close',
  columns: 'Columns',
  comment: 'Comment',
  damage: 'Damage',
  data: 'Data',
  document: 'Document',
  doneSuccess: 'DoneSuccess',
  done: 'Done',
  dot: 'Dot',
  dotEmpty: 'Dot-2',
  download: 'Download',
  edit: 'Edit',
  empty: 'Empty',
  exit: 'Exit',
  eye: 'Eye',
  filter: 'Filter',
  finish: 'Finish',
  gear: 'Gear',
  groats: 'Groats',
  home: 'Home',
  interval: 'Interval',
  journal: 'Journal',
  key: 'Key',
  latitude: 'Latitude',
  latitudeLongitude: 'Latitude-longitude',
  layerThickness: 'Layer-thickness',
  lineGraph: 'Line-graph',
  longitude: 'Longitude',
  mail: 'Mail',
  map: 'Map',
  humidity: 'Humidity',
  messages: 'Messages',
  minus: 'Minus',
  mobile: 'Mobile',
  noeye: 'Noeye',
  notebook: 'Notebook',
  organization: 'Organization',
  phone: 'Phone',
  photo: 'Photo',
  pin: 'Pin',
  plane: 'Plane',
  play: 'Play',
  pause: 'Pause',
  player: 'Player',
  playFast: 'Play-fast',
  playlist: 'Playlist',
  playNext: 'Play-next',
  plus: 'Plus',
  plusSmall: 'plus-small',
  question: 'Question',
  radar: 'Radar-1',
  refresh: 'Refresh',
  road: 'Road',
  rows: 'Rows',
  save: 'Save',
  schedule: 'Schedule',
  search: 'Search',
  slippery: 'Slippery',
  slipperyTwo: 'Slippery-2',
  speed: 'Speed',
  starFill: 'Star-fill',
  starOutline: 'Star-outline',
  start: 'Start',
  time: 'Time',
  timer: 'Timer',
  timeRepeat: 'Time-repeat',
  tpi: 'TPI',
  traffic: 'Traffic',
  trash: 'Trash',
  typeRain: 'Type-rain',
  video: 'Video',
  visibility: 'Visibility',
  web: 'Web',
  wiper: 'Wiper',
  workload: 'Workload',
  zpi: 'ZPI',
  carNumber: 'ar-number',
  lock: 'lock',
  upZero: 'up-zero',
  downZero: 'down-zero',
  infMrl: 'inf-mrl',
  infAdmc: 'inf-admc',
  infCloud: 'inf-cloud',
  sumMrl: 'sum-mrl',
  sumAdmc: 'sum-admc',
  sum: 'sum',
  river: 'River',
  timeRainLok: 'time-rain-lok',
  timeRainMet: 'time-rain-met',
  timeAlarm: 'time-alarm',
  climateAdaptation: 'Climate-adaptation',
  eco: 'Eco',
  notification: 'Notification',

  alignCenter: 'Align-center',
  alignRight: 'Align-right',
  alignLeft: 'Align-left',

  // arrows

  manuTop: '---',
  leftleft: 'Leftleft',
  straight: 'Straight',
  rightright: 'Rightright',
  resize: 'Resize',
  collapse: 'collapse1',
  reverse: 'Reverse',
  down: 'Down',
  downArrow: 'Down-arrow',
  left: 'Left',
  leftArrow: 'Left-arrow',
  right: 'Right',
  up: 'Up',
  upArrow: 'Up-arrow',

  // cars

  apsizing: 'apsizing',
  roadTrain: 'Road-train',
  passenger: 'Passenger',
  motorbike: 'Motorbike',
  factor: 'Factor',
  distance: 'Distance',
  bigCargo: 'Big-cargo',
  bus: 'Bus',
  car: 'Car',
  cargo: 'Cargo',
  smallCargo: 'Small-cargo',
  smallTruck: 'small-truck',
  kickScooter: 'kick-scooter',
  cyclist: 'cyclist',
  carTrailer: 'car-trailer1',
  carLoader: 'car-loader',

  // crashes

  crashOne: 'Crash-1',
  crashTwo: 'Crash-2',
  crashThree: 'Crash-3',
  crashFour: 'Crash-4',
  crashFive: 'Crash-5',

  // deicing agents

  surfaceCondition: 'Surface-condition',
  surfaceConditionOne: 'Surface-condition-01',
  surfaceConditionTwo: 'Surface-condition-02',
  surfaceConditionThree: 'Surface-condition-03',

  // deicing agents

  deicingAgents: 'Deicing-agents',
  deicingAgentsOne: 'Deicing-agents-01',
  deicingAgentsTwo: 'Deicing-agents-02',
  deicingAgentsThree: 'Deicing-agents-03',

  dry: 'Dry',

  // sky

  sumRain: 'Sum-rain',
  snow: 'Snow',
  skid: 'Skid',
  sky: 'Sky',
  clearly: 'Clearly',
  rain: 'Rain',
  rainfall: 'Rainfall',
  rainfallOne: 'Rainfall-1',
  rainIce: 'Rain-ice',
  snowRain: 'Snow-rain',
  pressure: 'Pressure',
  liquidPrecipitation: 'Liquid-precipitation',
  mixedRainfall: 'Mixed-rainfall',

  // cloud

  cloudOne: 'Cloud-1',
  cloudTwo: 'Cloud-2',
  cloudThree: 'Cloud-3',
  cloudFour: 'Cloud-4',

  // precipitation intensity

  precipitationIntensity: 'Precipitation-intensity',
  precipitationIntensityRain: 'Precipitation-intensity-rain',
  precipitationIntensitySnow: 'Precipitation-intensity-snow',
  precipitationIntensityRainSquare: 'Precipitation-intensity-rain-square',
  precipitationIntensitySnowSquare: 'Precipitation-intensity-snow-square',
  deviationRain: 'deviation-rain',
  deviationSnow: 'deviation-snow',

  // road condition

  notRain: 'Not-rain',
  humidOne: 'Humid-1',
  humidTwo: 'Humid-2',
  humidThree: 'Humid-3',
  humidFour: 'Humid-4',
  humidFive: 'Humid-5',
  ice: 'ice',
  chemicals: 'Chemicals',
  waterChemicals: 'Water-chemicals',
  nobody: 'nobody',
  errorOne: 'error-1',
  error: 'error',

  // wind

  wind: 'Wind',
  windDirection: 'Wind-direction',
  windDirectionDeg: 'Wind-direction-deg',
  windGusts: 'Wind-gusts',
  windSpeed: 'Wind-speed',

  // wind directions

  northern: 'NN',
  western: 'WW',
  southwestern: 'WS',
  eastern: 'EE',
  northeastern: 'EN',
  southeastern: 'ES',
  northwestern: 'NW',
  south: 'SS',

  // temp Air
  temperature: 'Temperature',
  temperatureChange: 'Temperature-change',

  tempAir: 'Temp-air',
  tempAirOne: 'Temp-air-01',
  tempAirTwo: 'Temp-air-02',
  tempAirThree: 'Temp-air-03',
  tempAirFour: 'Temp-air-04',

  // temp Surface

  tempGround: 'Temp-ground',
  tempSurface: 'Temp-surface',
  tempSurfaceOne: 'Temp-surface-01',
  tempSurfaceTwo: 'Temp-surface-02',
  tempSurfaceThree: 'Temp-surface-03',
  tempSurfaceFour: 'Temp-surface-04',
  tempSurfaceUp05: 'Temp-surface-up05',
  tempSurfaceDown05: 'Temp-surface-down05',
  tempSurfaceSred: 'Temp-surface-sred',

  // temp Freezing

  tempFreezing: 'Temp-freezing',
  tempFreezingOne: 'Temp-freezing-01',
  tempFreezingTwo: 'Temp-freezing-02',
  tempFreezingThree: 'Temp-freezing-03',
  tempFreezingFour: 'Temp-freezing-04',

  // temp DewPoint

  tempDewPoint: 'Temp-dew-point',
  tempDewPointOne: 'Temp-dew-point-01',
  tempDewPointTwo: 'Temp-dew-point-02',
  tempDewPointThree: 'Temp-dew-point-03',
  tempDewPointFour: 'Temp-dew-point-04',

  // temp BelowSurface

  tempBelowSurface: 'Temp-below-surface',
  tempBelowSurfaceOne: 'Temp-below-surface-01',
  tempBelowSurfaceTwo: 'Temp-below-surface-02',
  tempBelowSurfaceThree: 'Temp-below-surface-03',
  tempBelowSurfaceFour: 'Temp-below-surface-04',

  noData: 'no-date',
  noIce: 'no-ice',
  noForecast: 'no-forecast',

  weatherStation: 'weather-station',
  pinTPIDef: 'pin-tpi-def',
  pinTPIChoose: 'pin-tpi-choose',
  pinErr: 'pin-err',
  pinDone: 'Pin-done',
  pinChoosen: 'pin-full-tpi-choosen',
  news: 'news',
  mapDot: 'map-dot',
  duplicate: 'duplicate',
  bill: 'bill1',
  stop: 'Stop',
  loaderMini: 'Loader-mini',
  loaderMax: 'Loader-max',
  chekDone: 'Chek-done',

  // Socials
  telegram: 'Telegram',
  whatsApp: 'Whatsapp',
};

export default icons;
