import React, { useState, useMemo, useCallback, useRef, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Moment } from 'moment';
import { CSDSwitcher, CSDAlert } from '@app/modules/kit-module/shared/ui';
import useWeatherLocatorContext from '@app/modules/map-module/context/WeatherLocators/useWeatherLocatorContext';
import { WeatherLayers } from '@app/v2/shared/enums/WeatherLayers';
import { useActions, useAppSelector } from '@app/v2/shared/hooks';
import SettingTypes from '@app/modules/map-module/enums/SettingTypes';
import useMapProfileContext from '@app/v2/features/mapProfiles/hooks/useMapProfileContext';
import { PlayerTimeRangeActions, ProfileMapSettingLabels } from '@app/v2/shared/enums';
import { isDefaultMapProfile } from '@app/v2/shared/helpers';
import LocatorButtonWithActions from './LocatorButtonWithActions';
import WeatherLocatorsControl from './WeatherLocatorsControl/WeatherLocatorsControl';
import MapProfilesManager from '../../components/MapProfilesManager';
import DisabledWrapperWithTooltip from '../../components/DisabledWrapperWithTooltip';

const MeteoLocatorsPanel = ({
  hideProfileActions,
  recalculateContainerHeight,
}: {
  hideProfileActions: boolean;
  recalculateContainerHeight: () => void;
}) => {
  const classes = useStyles();
  const locatorsContainerRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation('map');
  const { t: tNotification } = useTranslation('common', { keyPrefix: 'notificationMessages' });
  const { currentActiveProfile, handleChangeProfileCheckbox } = useMapProfileContext();
  const [selectedLocator, setSelectedLocator] = useState<WeatherLocators.Locator>(null);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const locators: WeatherLocators.Locator[] = useAppSelector(state => Object.values(state.map.locators), isEqual);
  const isSyncMode = useAppSelector(state => state.map.settings[SettingTypes.LOCATOR_SYNC].checked);
  const { changeMapSettingCheckbox } = useActions();
  const { contextValue, allContext, globalService, changeDate, changeTimesRange } = useWeatherLocatorContext(String(selectedLocator?.id));
  const locatorControlService = useMemo(
    () => (isSyncMode ? globalService : contextValue?.service || undefined),
    [contextValue, globalService, isSyncMode],
  );
  const layer = useMemo(() => (contextValue?.layer ? contextValue.layer : WeatherLayers.NONE), [contextValue]);

  useLayoutEffect(() => {
    if (locatorsContainerRef?.current && locatorsContainerRef?.current?.offsetHeight !== containerHeight) {
      setContainerHeight(locatorsContainerRef?.current?.offsetHeight);
      recalculateContainerHeight();
    }
  }, [locatorsContainerRef, locatorsContainerRef?.current?.offsetHeight, containerHeight, selectedLocator?.id, recalculateContainerHeight]);

  const handleSelectLocator = (id: number) => {
    setSelectedLocator(locators.find(({ id: locatorId }) => locatorId === id));
  };

  const handleSetSyncMode = (checked: boolean) => {
    handleChangeProfileCheckbox(ProfileMapSettingLabels.SETTINGS, SettingTypes.LOCATOR_SYNC, checked);
    changeMapSettingCheckbox({ key: SettingTypes.LOCATOR_SYNC, checked });
  };

  const handleChangeDate = useCallback(
    (newDate: Moment | string) => {
      changeDate(String(selectedLocator?.id), newDate);
    },
    [selectedLocator?.id, changeDate],
  );

  const handleChangeTimesRange = useCallback(
    (rangeType: PlayerTimeRangeActions) => {
      changeTimesRange(String(selectedLocator?.id), rangeType);
    },
    [selectedLocator?.id, changeTimesRange],
  );

  const hasActiveLayer = useMemo(
    () => (isSyncMode ? Object.entries(allContext).some(([_, value]) => !!value.layer) : !!layer),
    [isSyncMode, layer, allContext],
  );

  const showPlayer = useMemo(
    () => (!!locatorControlService && isSyncMode ? hasActiveLayer : !!selectedLocator && !!layer),
    [isSyncMode, layer, selectedLocator, hasActiveLayer, locatorControlService],
  );

  const getNotification = useCallback(() => {
    if (contextValue?.isWarning) {
      return <CSDAlert severity="warning">{tNotification('notGlobalModeLocator')}</CSDAlert>;
    }
    if (!selectedLocator?.id && !showPlayer) {
      return (
        <CSDAlert sx={{ alignItems: 'center' }} severity="info">
          {tNotification('locatorSelect')}
        </CSDAlert>
      );
    }
    if (!hasActiveLayer) {
      return <CSDAlert severity="info">{tNotification('layerSelect')}</CSDAlert>;
    }
    return null;
  }, [contextValue?.isWarning, selectedLocator?.id, hasActiveLayer, showPlayer, tNotification]);

  const activeLocators = useMemo(() => locators.filter(locator => Boolean(locator?.maxDate)), [locators]);

  return (
    <>
      <MapProfilesManager hideProfileActions={hideProfileActions} />
      <DisabledWrapperWithTooltip disabled={isDefaultMapProfile(currentActiveProfile)}>
        <Box ref={locatorsContainerRef} className={classes.meteoContainer} flexDirection={{ sx: 'column' }}>
          <Box sx={{ width: '100%', marginBottom: '1rem' }}>
            <CSDSwitcher
              checked={isSyncMode}
              label={t('mapCardControl.commonSettings.synchronizeLocators')}
              onChange={(_, checked) => {
                handleSetSyncMode(checked);
              }}
            />
          </Box>
          <Box className={classes.locatorsContainer}>
            {activeLocators.map(locator => (
              <Box className={classes.locatorItem} key={locator.id}>
                <LocatorButtonWithActions locator={locator} onClick={handleSelectLocator} isActive={selectedLocator?.id === locator.id} />
              </Box>
            ))}
          </Box>
          <Box className={classes.locatorsControlContainer}>
            {getNotification()}
            {showPlayer && !contextValue?.isWarning && (
              <WeatherLocatorsControl
                handleChangeDate={handleChangeDate}
                handleChangeTimesRange={handleChangeTimesRange}
                locatorControlService={locatorControlService}
              />
            )}
          </Box>
        </Box>
      </DisabledWrapperWithTooltip>
    </>
  );
};

const useStyles = makeStyles(() => ({
  meteoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    height: '100%',
  },
  locatorsContainer: {
    display: 'flex',
    maxWidth: '50rem',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
  },
  locatorsControlContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  locatorItem: {
    marginRight: '0.2rem',
    marginBottom: '0.2rem',
  },
}));

export default MeteoLocatorsPanel;
