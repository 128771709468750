import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DATE_FORMAT } from '@app/v2/shared/constants';
import { useAppSelector, useSystem } from '@app/v2/shared/hooks';

type Props = {
  color?: string;
  fontSize?: number;
  textAlign?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
};

const CSDClocks = ({ color = 'white', fontSize = 25, textAlign = 'center' }: Props) => {
  const utc = useAppSelector(state => state.account.settings.utc);
  const isWithTimeZoneOutput = useAppSelector(state => state.account.settings.timezoneInsteadCheckbox);
  const { isHeaderRollUp } = useSystem();
  const classes = useStyles({ utc, isHeaderExpanded: isHeaderRollUp });
  const [time, setTime] = useState<string>(moment().format(DATE_FORMAT.FORMAT_RU_TIME));

  useEffect(() => {
    const interval = setInterval(() => {
      const t = moment().format(DATE_FORMAT.FORMAT_RU_TIME);
      setTime(t);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Typography textAlign={textAlign} color={color} fontSize={fontSize} className={clsx(classes.clock, isWithTimeZoneOutput && classes.utcData)}>
      {time}
    </Typography>
  );
};

const useStyles = makeStyles<Theme, { utc: string; isHeaderExpanded: boolean }>(() => ({
  clock: {
    position: 'relative',
    textAlign: 'center',
    width: 125,
    height: 27,
  },
  utcData: {
    '&:after': {
      content: ({ utc }) => `"UTC ${utc}"`,
      position: 'absolute',
      fontSize: 10,
      bottom: ({ isHeaderExpanded }) => (isHeaderExpanded ? -10 : ''),
      top: ({ isHeaderExpanded }) => (!isHeaderExpanded ? -10 : ''),
      right: 10,
    },
  },
}));

export default CSDClocks;
