import React, { memo } from 'react';
import { KeyPrefix, withTranslation } from 'react-i18next';
import sortBy from 'lodash/sortBy';
import { Stack, StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSDSwitcher } from '@app/modules/kit-module/shared/ui';

interface Props {
  setup: Meteo.ChartsSetup;
  meteoChartSetupParameters: string[];
  onChangeOne: (key: string, value: boolean) => void;
  onChangeGroup: (groupKey: string, value: boolean, elementsShouldNotBeInSetup?: string[]) => void;
  elementsShouldNotBeInSetup?: KeyPrefix<'parameters'>[];
  setIsValid: React.Dispatch<React.SetStateAction<{ input: boolean; setup: boolean }>>;
}

const CSDConstructorSetup = ({
  setup = [],
  onChangeOne,
  onChangeGroup,
  meteoChartSetupParameters = [],
  elementsShouldNotBeInSetup = [],
  setIsValid,
  t,
}: Props & I18N.TranslationFunction<'parameters'>) => {
  const prepareParameters = (parameters: Meteo.ChartsSetupParameter[]): KeyPrefix<'parameters'>[] => {
    return sortBy(parameters, setupItem => setupItem.order)
      .map(({ label }) => label)
      .filter(label => !elementsShouldNotBeInSetup.includes(label));
  };

  return (
    <Wrapper>
      {sortBy(setup, setupItem => setupItem.order).map(({ label, params }) => (
        <Group key={label}>
          <CSDSwitcher
            size="small"
            labelStyles={{ fontWeight: 700 }}
            checked={prepareParameters(params).every(groupLabel => meteoChartSetupParameters.includes(groupLabel))}
            label={t(label as KeyPrefix<'parameters'>)}
            onChange={(_, checked) => {
              setIsValid(prev => ({ ...prev, setup: true }));
              onChangeGroup(label, checked, elementsShouldNotBeInSetup);
            }}
          />

          <GroupItems>
            {prepareParameters(params).map(paramLabel => (
              <CSDSwitcher
                key={paramLabel}
                size="small"
                checked={meteoChartSetupParameters.includes(paramLabel)}
                label={t(paramLabel)}
                onChange={(_, checked) => {
                  setIsValid(prev => ({ ...prev, setup: true }));
                  onChangeOne(paramLabel, checked);
                }}
              />
            ))}
          </GroupItems>
        </Group>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled(Stack)<StackProps>({
  gap: '1rem',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
});

const Group = styled(Stack)<StackProps>({
  gap: '1rem',
});

const GroupItems = styled(Stack)<StackProps>({
  gap: '0.5rem',
});

export default withTranslation('parameters')(memo(CSDConstructorSetup));
