import icons from '@app/assets/iconFont';
import { MainPaths } from '@app/v2/shared/enums/MainPaths';
import { UsersAccess } from '@app/v2/shared/enums';
import { HeaderFastLinksItem } from '../../widgets/header/types';

const fastLinks: HeaderFastLinksItem[] = [
  {
    icon: icons.home,
    path: MainPaths.Index,
    tooltip: 'links.home',
    permissions: [UsersAccess.PAGE_HOME],
  },
  {
    icon: icons.map,
    path: MainPaths.Map,
    tooltip: 'links.map',
    permissions: [UsersAccess.PAGE_MAP],
  },
  {
    icon: icons.sky,
    path: MainPaths.Meteo,
    tooltip: 'links.meteo',
    permissions: [UsersAccess.PAGE_METEO],
  },
  {
    icon: icons.traffic,
    path: MainPaths.Traffic,
    tooltip: 'links.traffic',
    permissions: [UsersAccess.PAGE_TRAFFIC],
  },
  {
    icon: icons.photo,
    path: MainPaths.Video,
    tooltip: 'links.video',
    permissions: [UsersAccess.PAGE_VIDEO],
  },
  {
    icon: icons.tpi,
    path: MainPaths.ScoreboardsAndSigns,
    tooltip: 'links.scoreboard',
    permissions: [UsersAccess.VMS_FULL_EDIT, UsersAccess.VMS_LIMIT_EDIT, UsersAccess.VMS_VIEW_ONLY],
  },
  {
    icon: icons.journal,
    path: MainPaths.MapNotes,
    tooltip: 'links.notes',
    permissions: [UsersAccess.PAGE_NOTES],
  },
  {
    icon: icons.news,
    path: MainPaths.AccidentNews,
    tooltip: 'links.accidentNews',
    permissions: [UsersAccess.PAGE_NEWS],
  },
  {
    icon: icons.chart,
    path: MainPaths.Dashboard,
    tooltip: 'links.dashboard',
    permissions: [UsersAccess.PAGE_DASHBOARD],
  },
];

export default fastLinks;
