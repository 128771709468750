import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import { mainConfig } from '@app/v2/shared/configs';
import CSDEmptySearch from '../CSDEmptySearch/CSDEmptySearch';

interface Props {
  activeSnapshot: Video.Snapshot | null;
}

const CSDSnapshotDialogContent = ({ activeSnapshot }: Props) => {
  const { t } = useTranslation('video');

  const classes = useStyles({ snapshotURL: activeSnapshot?.url });

  return <>{activeSnapshot ? <Box className={classes.snapshot} /> : <CSDEmptySearch message={t('noData.emptySnapshot')} />}</>;
};
const useStyles = makeStyles<Theme, { snapshotURL: string }>({
  snapshot: {
    width: '100%',
    height: '37rem',
    display: 'flex',
    backgroundSize: 'cover',
    backgroundImage: ({ snapshotURL }) => `URL(${mainConfig.links.videoImagesHost + snapshotURL})`,
  },
  empty: {
    height: '37rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default CSDSnapshotDialogContent;
