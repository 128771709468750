/* eslint-disable class-methods-use-this */
import client from '@app/clients/apollo/client';
import meteoMutations from '@app/clients/apollo/requests/mutations/meteo';
import meteoQueries from '@app/clients/apollo/requests/queries/meteo';
import { strictlyEqual } from '@app/v2/shared/helpers';
import { MeteoChartProfileType } from '@app/v2/shared/enums';
import CSDMeteoChartConstructorAdapterBase from './CSDMeteoChartConstructorAdapterBase';

class CSDMeteoChartConstructorAdapter implements CSDMeteoChartConstructorAdapterBase {
  private profiles: Meteo.ChartProfile[] = [];

  public getProfiles(): Meteo.ChartProfile[] {
    return this.profiles;
  }

  public setProfiles(nextProfiles: Meteo.ChartProfile[]): void {
    this.profiles = nextProfiles;
  }

  public getProfileById(profileId: string): Meteo.ChartProfile {
    return this.profiles.find(({ id }) => strictlyEqual(id, Number(profileId)));
  }

  public async getValues(): Promise<Meteo.ChartProfile[]> {
    const { data, error } = await client.query<Meteo.ChartProfileResponse>({
      query: meteoQueries.meteoGraphSettings,
    });

    if (!data?.meteoGraphSettings?.length || error) return [];

    this.setProfiles(
      data?.meteoGraphSettings.map(profile => ({
        ...profile,
        type: MeteoChartProfileType.Custom,
        items: profile.items.map(this.profileParametersToObject),
      })),
    );

    return this.profiles;
  }

  public async createProfile(name: string) {
    await client.mutate<Common.MutationResponse, Meteo.CreateChartProfileVariables>({
      mutation: meteoMutations.createMeteoChartProfile,
      variables: {
        profile: {
          name,
          items: [],
        },
      },
    });
  }

  public async deleteProfile(profileId: number) {
    await client.mutate<Common.MutationResponse, Meteo.DeleteChartProfileVariables>({
      mutation: meteoMutations.deleteMeteoChartProfile,
      variables: {
        id: profileId,
      },
    });
  }

  public async createValue(profileId: string, newProfileItem: Meteo.ChartProfileItem<string[]>) {
    const profile = this.getProfileById(profileId);

    await client.mutate<Common.MutationResponse, Meteo.UpdateChartProfileVariables>({
      mutation: meteoMutations.updateMeteoChartProfile,
      variables: {
        id: Number(profileId),
        profile: {
          name: profile.name,
          items: this.addOrderToCharts([...profile.items, this.profileParametersToObject(newProfileItem)]),
        },
      },
    });
  }

  public async updateValue(profileId: string, nextProfileItem: Meteo.ChartProfileItem<string[]>) {
    const profile = this.getProfileById(profileId);

    await client.mutate<Common.MutationResponse, Meteo.UpdateChartProfileVariables>({
      mutation: meteoMutations.updateMeteoChartProfile,
      variables: {
        id: Number(profileId),
        profile: {
          name: profile.name,
          items: this.addOrderToCharts(
            profile.items.map(item => (strictlyEqual(item.id, nextProfileItem.id) ? this.profileParametersToObject(nextProfileItem) : item)),
          ),
        },
      },
    });
  }

  public async removeValue(profileId: string, deleteItemId: string) {
    const profile = this.getProfileById(profileId);

    await client.mutate<Common.MutationResponse, Meteo.UpdateChartProfileVariables>({
      mutation: meteoMutations.updateMeteoChartProfile,
      variables: {
        id: Number(profileId),
        profile: {
          name: profile.name,
          items: this.addOrderToCharts(profile.items.filter(item => !strictlyEqual(item.id, Number(deleteItemId)))),
        },
      },
    });
  }

  private profileParametersToObject(profile: Meteo.ChartProfileItem<string[]>): Meteo.ChartProfileItem {
    return {
      ...profile,
      parameters: profile.parameters.reduce((acc, parameter) => {
        acc[parameter] = true;
        return acc;
      }, {} as Record<string, boolean>),
    };
  }

  private addOrderToCharts(profileCharts: Meteo.ChartProfileItem[]): Meteo.ChartProfileItem[] {
    return profileCharts.map((chart, index) => ({ ...chart, order: index }));
  }
}

const adapter = new CSDMeteoChartConstructorAdapter();

export default adapter;
