import FETCH_ACCOUNT_SETTINGS from './fetchAccountSettings.graphql';
import FETCH_BILLING_DOCUMENTS from './fetchBillingDocuments.graphql';
import FETCH_ACCOUNT_UNPAID_STATUS from './fetchAccountUnpaidStatus.graphql';

const accountQueries = {
  user: FETCH_ACCOUNT_SETTINGS,
  billing: FETCH_BILLING_DOCUMENTS,
  unpaid: FETCH_ACCOUNT_UNPAID_STATUS,
};

export default accountQueries;
