import React, { memo, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment/moment';
import { CSDApolloQuery } from '@app/v2/shared/ui/api';
import { CSDTrafficChart, CSDTrafficChartByLines, CSDTrafficChartDirections } from '@app/v2/entities';
import { getScrollStyles } from '@theme/themeSettings/componentSettings';
import { useTrafficChart } from '@app/v2/shared/hooks';
import trafficQueries from '@app/clients/apollo/requests/queries/traffic';
import { formatEnFull } from '@app/core/helpers/dateFormat';
import { TrafficViewType } from '@app/core/types/charts/traffic';
import { DATE_FORMAT } from '@app/v2/shared/constants';
import { CSDExportChartBtn } from '@app/modules/kit-module/shared/ui';

interface Props {
  activeStationId: number | null;
  startActiveId: number;
  title: string;
  infoSetup: { name: string; value: string | number }[];
}

const TrafficTabContent = ({ title, infoSetup, activeStationId, startActiveId }: Props) => {
  const { t: tCommon } = useTranslation('common');
  const {
    trafficChartData: { dateTo, dateFrom, direction, interval: period, typeView, isViewAdditionalChart },
  } = useTrafficChart();

  const chartVariables = useMemo(
    () => ({
      period,
      direction,
      stationId: activeStationId || startActiveId,
      dateFrom: dateFrom ? formatEnFull(dateFrom) : null,
      dateTo: dateTo ? formatEnFull(dateTo) : null,
    }),
    [activeStationId, dateFrom, dateTo, direction, period, startActiveId],
  );

  const additionChartMapper: Record<TrafficViewType, ReactNode> = {
    [TrafficViewType.BY_DIRECTIONS]: (
      <CSDApolloQuery<Traffic.ChartDirectionsResponse, Traffic.ChartVariables>
        query={trafficQueries.trafficGraphDirections}
        variables={chartVariables}
        options={{ skip: !chartVariables.dateTo && !chartVariables.dateFrom }}
      >
        {({ data, loading }) => <CSDTrafficChartDirections loading={loading} data={data?.trafficGraphDirections} />}
      </CSDApolloQuery>
    ),
    [TrafficViewType.BY_CLASS]: (
      <CSDApolloQuery<Traffic.ChartByLinesResponse, Traffic.ChartVariables>
        query={trafficQueries.trafficGraphByLines}
        variables={chartVariables}
        options={{ skip: !chartVariables.dateTo && !chartVariables.dateFrom }}
      >
        {({ data, loading }) => <CSDTrafficChartByLines loading={loading} data={data?.trafficByLinesGraph} />}
      </CSDApolloQuery>
    ),
  };

  return (
    <StyledWrapper position="relative">
      <CSDApolloQuery<Traffic.CommonChartResponse, Traffic.ChartVariables>
        query={trafficQueries.trafficGraph}
        variables={chartVariables}
        options={{ skip: !chartVariables.dateTo && !chartVariables.dateFrom }}
      >
        {({ data, loading }) =>
          !!data?.trafficGraph &&
          !loading && (
            <>
              <CSDExportChartBtn
                title={title}
                infoSetup={[
                  ...infoSetup,
                  { name: tCommon('dates.periods.dateFrom'), value: dateFrom?.format(DATE_FORMAT.FORMAT_RU_FULL) },
                  { name: tCommon('dates.periods.dateTo'), value: dateTo?.format(DATE_FORMAT.FORMAT_RU_FULL) },
                ]}
                configurations={{
                  filename: `traffic-charts-${moment().format(DATE_FORMAT.FORMAT_RU_FULL)}`,
                  printed: false,
                  exportFormats: ['image/png', 'image/jpeg', 'image/svg+xml', 'application/pdf'],
                }}
                withLabel={false}
                styles={{
                  position: 'absolute',
                  right: '2rem',
                  top: '2rem',
                  zIndex: 100000,
                  width: '3rem',
                  minWidth: '3rem',
                  backgroundColor: 'white',
                  '&:hover': {
                    backgroundColor: 'white',
                  },
                  '&:active': {
                    backgroundColor: 'white',
                  },
                }}
              />
              <CSDTrafficChart data={data?.trafficGraph} isViewAdditionalChart={isViewAdditionalChart} />
              {isViewAdditionalChart && additionChartMapper[typeView]}
            </>
          )
        }
      </CSDApolloQuery>
    </StyledWrapper>
  );
};

export default memo(TrafficTabContent);

const StyledWrapper = styled(Stack)<StackProps>(
  ({
    theme: {
      palette: { text, mode },
    },
  }) => ({
    flexDirection: 'column',
    padding: '1rem',
    width: '100%',
    height: '100%',
    gap: '1rem',
    overflow: 'auto',
    ...getScrollStyles(mode, text),
  }),
);
