import React from 'react';
import { Stack } from '@mui/material';
import { CSDSwitcher } from '@app/modules/kit-module/shared/ui';
import { switchersSize, switchersWithLabel } from './example';
import CSDKitDocBlock from '../../../shared/ui/CSDKitDocBlock/CSDKitDocBlock';

const CSDSwitches = () => {
  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="Switches" codeExample={switchersSize}>
        <>
          <CSDSwitcher />
          <CSDSwitcher size="small" />
        </>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Switches with label" codeExample={switchersWithLabel}>
        <>
          <CSDSwitcher label="Switcher" />
          <CSDSwitcher label="Switcher" size="small" />
        </>
      </CSDKitDocBlock>
    </Stack>
  );
};

export default CSDSwitches;
