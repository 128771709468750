import React from 'react';
import { withTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import configureChartOptions from './configureChartOptions';
import { PieData, PieCenterNumber } from './types';

export type PieDiagramProps = {
  diagramId?: string;
  chartWidth?: number;
  lineWidth?: number;
  data: PieData[];
  innerData?: PieData[];
  rightSideNumber?: PieCenterNumber;
  leftSideNumber?: PieCenterNumber;
  topSideNumber?: PieCenterNumber;
  title?: string;
  seriesLegendShow?: boolean[];
  totalValueSuffix?: string;
};

const CSDPieDiagram = ({
  diagramId,
  chartWidth = 500,
  lineWidth = 30,
  data = [],
  innerData = [],
  rightSideNumber,
  leftSideNumber,
  topSideNumber,
  title,
  seriesLegendShow = [true, false],
  totalValueSuffix,
  t,
}: PieDiagramProps & I18N.TranslationFunction<'dashboard'>) => {
  const isDoubleDonut = !!data.length && !!innerData.length;

  const donutData = isDoubleDonut ? [data, innerData] : [data];

  const totalElements = data.reduce((res, item) => item.value + res, 0);

  // from calculate innerSize
  const radiusOffset = lineWidth * 2;

  const chartOptions = configureChartOptions({
    chartWidth,
    radiusOffset,
    data: donutData,
    rightSideNumber,
    leftSideNumber,
    topSideNumber,
    title,
    seriesLegendShow,
    totalElements,
    totalValueSuffix: totalValueSuffix ?? t('total.suffix.default'),
  });

  return <HighchartsReact id={diagramId} highcharts={Highcharts} constructorType="ganttChart" options={chartOptions} />;
};

export default withTranslation('dashboard')(CSDPieDiagram);
