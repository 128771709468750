import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { getAlertIndexConfig } from '@app/v2/shared/configs';

interface Props {
  forecast: AlertIndex.Data;
}

const Forecast = ({ forecast }: Props) => {
  const config = getAlertIndexConfig(forecast);

  return (
    <>
      {config && !!config.length && (
        <Box className="indicatorsWrapper">
          {config.map(({ id, backgroundColor, borderRadius, amountHours, tooltip, alertIndexValue }) => (
            <Tooltip key={id} placement="right" title={tooltip}>
              <Box className="indicatorItem" sx={{ backgroundColor, borderRadius }} />
            </Tooltip>
          ))}
        </Box>
      )}
    </>
  );
};

export default Forecast;
