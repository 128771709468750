import * as React from 'react';
import { useCallback, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import { Icon, IconButton, Tooltip } from '@mui/material';
import { TypedDocumentNode } from '@apollo/client/core/types';
import icons from '@app/assets/iconFont';
import useDialog from '@app/core/hooks/useDialog';
import { DialogData } from '@app/components/common/dialog/types/DialogData';
import { DialogRef } from '@app/core/providers/DialogProvider';
import { showError } from '@app/core/utils/notifications';
import normalizeMapNoteInput from '@app/modules/map-module/components/map/notes/helpers';
import { MapNoteInput, MapNoteType } from '@app/modules/map-module/components/map/notes/types';

type Props<T> = {
  id: number;
  dialogData: DialogData<T>;
  query: TypedDocumentNode;
  dialog: React.FC<{ item: T; dialogData: DialogData<T> } & DialogRef<string>>;
  onUpdate?: () => void;
} & WithTranslation<'common'>;

const UpdateNoteButton = ({ id, dialogData, query, dialog, onUpdate, t }: Props<MapNoteType>) => {
  const openUpdateDialog = useDialog<{ item: MapNoteInput; dialogData: DialogData<MapNoteType> }>(dialog);
  const [loading, setLoading] = useState<boolean>(false);

  const { refetch } = useQuery(query, { skip: true });

  const editHandler = useCallback(() => {
    setLoading(true);
    refetch({ id })
      .then(
        ({
          data: {
            note: { note: item },
          },
        }) => {
          openUpdateDialog({ item: normalizeMapNoteInput(item), dialogData }).beforeClose.subscribe(() => {
            typeof onUpdate === 'function' && onUpdate();
          });
        },
      )
      .catch(error => {
        showError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, onUpdate, refetch, openUpdateDialog, dialogData]);

  return (
    <Tooltip title={t('buttons.edit')}>
      <IconButton data-testid="update" onClick={() => editHandler()}>
        {loading ? (
          <CircularProgress data-testid="loading" color="inherit" size={20} />
        ) : (
          <Icon color="action" sx={{ '&:hover': { color: '#3245af' } }}>
            {icons.edit}
          </Icon>
        )}
      </IconButton>
    </Tooltip>
  );
};
export default withTranslation('common')(UpdateNoteButton);
