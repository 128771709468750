import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { useQuery } from '@apollo/client';
import { Dialog, DialogContent, DialogContentProps, DialogProps, DialogTitle, DialogTitleProps, Stack } from '@mui/material';
import PlanChart from '@app/components/charts/plan-dialog/plan-chart/PlanChart';
import { DialogRef } from '@app/core/providers/DialogProvider';
import WrapperProgress from '@app/components/common/wrapper-progress/WrapperProgress';
import CloseDialogButton from '@app/components/close-dialog-button/CloseDialogButton';
import meteoQueries from '@app/clients/apollo/requests/queries/meteo';
import PlanDialogHeader from './PlanDialogHeader';
import ExportChartBtn from './ExportChartBtn';
import type { PlanGraphPlaceResponse, PlanGraphPlaceVariables } from './types';
import { formPlaceName } from '../common/helpers';

export type Props = {
  placeId: number;
} & DialogRef;

const PlanDialog = ({ placeId, close }: Props) => {
  const { t: chartsT } = useTranslation('charts');
  const { t } = useTranslation('common', { keyPrefix: 'units' });

  const contentDialogRef = useRef<HTMLDivElement | null>(null);

  const { data: { planGraph } = {}, loading } = useQuery<PlanGraphPlaceResponse, PlanGraphPlaceVariables>(meteoQueries.meteoPlanGraph, {
    variables: {
      placeId,
    },
  });

  const nameOfPlace = useMemo(
    () => !loading && formPlaceName(planGraph.place.address, planGraph.place.meters, t('kilometers')),
    [loading, planGraph, t],
  );

  return (
    <StyledDialog fullWidth open fullScreen onClose={() => close('close')}>
      <StyledDialogTitle>
        <PlanDialogHeader planGraph={planGraph} loading={loading} nameOfPlace={nameOfPlace} />

        <Stack direction="row" alignItems="flex-start" gap="1.5rem">
          <ExportChartBtn styles={{ height: '50px', padding: '17px 32.5px' }} planGraph={planGraph} nameOfPlace={nameOfPlace} />
          <CloseDialogButton callback={close} />
        </Stack>
      </StyledDialogTitle>
      <StyledDialogContent ref={contentDialogRef}>
        <WrapperProgress loading={loading} message={chartsT('message')}>
          {!!planGraph && <PlanChart planGraph={planGraph} chartHeight={contentDialogRef.current?.clientHeight} />}
        </WrapperProgress>
      </StyledDialogContent>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)<DialogProps>({
  height: '100%',
  top: 0,

  '& .MuiDialog-paper': {
    borderRadius: '0rem 0rem 0.5rem 0.5rem',
    padding: '0rem',
  },
});

const StyledDialogTitle = styled(DialogTitle)<DialogTitleProps>({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0px 5px 5px rgba(52, 52, 52, 0.2)',
  padding: '1.5rem 1.5rem 1rem 1.5rem',
});

const StyledDialogContent = styled(DialogContent)<DialogContentProps>({
  height: '34rem',
  overflow: 'auto',
  marginTop: '1.5rem',
});

export default PlanDialog;
