import { DATE_PERIOD } from '@app/v2/shared/constants';

export default function preparePlotOptionsParameters() {
  return {
    column: {
      stacking: 'normal',
    },
    series: {
      gapSize: 4 * DATE_PERIOD.HOUR,
      gapUnit: 'value',
      events: {
        legendItemClick() {
          return false;
        },
      },
      states: {
        inactive: {
          opacity: 1,
        },
        hover: {
          enabled: false,
        },
      },
      turboThreshold: 0,
    },
  };
}
