import React, { useCallback, useMemo, memo } from 'react';
import { Stack } from '@mui/material';
import moment, { Moment } from 'moment';
import { DATE_FORMAT } from '@app/v2/shared/constants';
import { CSDTimeRangePickerAntd, CSDDataPickerAntd } from '@app/modules/kit-module/shared/ui';
import { PlayerStatus } from '@app/v2/shared/enums';
import LocatorControlService from '@app/modules/map-module/services/LocatorControlService';
import { strictlyEqual } from '@app/v2/shared/helpers';

type Props = {
  control?: WeatherLocators.LocatorControl;
  playerState?: PlayerStatus;
  locatorControlService: LocatorControlService;
  handleChangeDate: (newDate: Moment | string) => void;
};

const hoursArray = Array.from({ length: 24 }, (_, index) => index + 1);
const minutesArray = Array.from({ length: 60 }, (_, index) => index + 1);

const getDisabledTimes = (times: string[]) => {
  const timesMap = times.reduce<Map<number, Set<number>>>((res, item) => {
    const [hour, minute] = item.split(':');

    res.set(+hour, (res.get(+hour) || new Set()).add(+minute));

    return res;
  }, new Map());

  return {
    disabledHours: () => hoursArray.filter(item => !timesMap.has(item)),
    disabledMinutes: (selectedHour: number) => minutesArray.filter(item => !timesMap.get(selectedHour)?.has(item)),
  };
};

const DateTimeControl = ({ control, playerState, locatorControlService, handleChangeDate }: Props) => {
  const handleChangeTime = useCallback(
    ([start, end]: [Moment, Moment]) => {
      locatorControlService.setStartEnd([moment(start).format(DATE_FORMAT.FORMAT_RU_HOURS), moment(end).format(DATE_FORMAT.FORMAT_RU_HOURS)]);
    },
    [locatorControlService],
  );

  const { isDisableDateFrom, isDisableDateTo } = useMemo(
    () => ({
      isDisableDateFrom: !control?.times.length || strictlyEqual(PlayerStatus.PLAY, control.playerState),
      isDisableDateTo: !control?.times.length || strictlyEqual(PlayerStatus.PLAY, control.playerState),
    }),
    [control.playerState, control?.times.length],
  );

  return (
    <Stack direction="row" spacing={1}>
      <CSDDataPickerAntd
        disabled={playerState === PlayerStatus.PLAY}
        labelVariant="subtitle2"
        value={moment(control.date)}
        style={{ fontSize: '0.875rem' }}
        onChange={handleChangeDate}
        disabledDate={dateValue => moment(control.maxDate).isBefore(dateValue)}
        format={DATE_FORMAT.FORMAT_RU_POINT_SHORT_DATE}
        size="large"
      />
      <CSDTimeRangePickerAntd
        disabled={[isDisableDateFrom, isDisableDateTo]}
        allowEmpty={[true, true]}
        value={
          control.startTime && control.endTime
            ? [moment(control.startTime, DATE_FORMAT.FORMAT_RU_HOURS), moment(control.endTime, DATE_FORMAT.FORMAT_RU_HOURS)]
            : [null, null]
        }
        onChange={handleChangeTime}
        disabledTime={() => getDisabledTimes(control?.times)}
        hideDisabledOptions
        format={DATE_FORMAT.FORMAT_RU_HOURS}
        size="large"
      />
    </Stack>
  );
};

export default memo(DateTimeControl);
