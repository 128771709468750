/* eslint-disable */
import React, { memo } from 'react';
import clsx from 'clsx';
import { Icon, Badge, BadgeProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ClassNameMap } from '@mui/styles';
import { isFunction } from '@app/v2/shared/helpers';
import { RoadObjectsTypes } from '@app/v2/shared/enums';

type Props = {
  icon: string;
  anchorEl: HTMLDivElement;
  degrees: number;
  count: number;
  currentMarkerPosition: number;
  isExistPopoverContent: boolean;
  isExpired?: boolean;
  itemMarkerKey: RoadObjectsTypes;
  handleRotateMarker: (degrees: number, markerType: RoadObjectsTypes) => void;
  classes: ClassNameMap<string>;
  onClick?: () => {};
};

const CSDMapMarkerItem = ({
  currentMarkerPosition,
  anchorEl,
  degrees,
  count,
  icon,
  isExpired = false,
  handleRotateMarker,
  isExistPopoverContent,
  onClick,
  classes,
  itemMarkerKey,
}: Props) => {
  const isSelectedMarket = (): boolean => Boolean(anchorEl) && degrees === currentMarkerPosition;

  const handleClick = () => {
    handleRotateMarker(degrees, itemMarkerKey);
    if (isFunction(onClick)) onClick();
  };

  return (
    <li className={clsx(classes.circlePart, isExpired && 'expired', isSelectedMarket() && isExistPopoverContent && 'active')} onClick={handleClick}>
      <div className={classes.circlePartContent}>
        <StyledBadge
          badgeContent={count}
          className={classes.circlePartContentIcon}
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: '#439BFF',
              zIndex: 10,
            },
          }}
        >
          <Icon>{icon}</Icon>
        </StyledBadge>
      </div>
    </li>
  );
};

const StyledBadge = styled(Badge)<BadgeProps>({
  '& .MuiBadge-badge': {
    minWidth: '9px',
    height: '9px',
    fontSize: '8px',
    padding: 0,
    top: '7%',
    right: '10%',
  },
});

export default memo(CSDMapMarkerItem);
