import { TFunction } from 'react-i18next';
import { createEmptyArray } from '@app/v2/shared/utils';
import { COLORS } from '@theme/constants';

const colorsMapper: Record<string, string> = {
  0: 'rgba(74, 232, 61, 0.19)',
  1: 'rgba(252, 196, 25, 0.16)',
  2: 'rgba(255, 0, 0, 0.20)',
};

const OFFSET_BY_X = 0.5;
const OFFSET_BY_X_START_LINES_DIVIDER = 0.48;
const OFFSET_BY_X_END_LINES_DIVIDER = 0.43;

const commonPlotLinesOptions = {
  dashStyle: 'Solid',
  color: COLORS.black,
  width: 3,
  zIndex: 6,
};

function createCategories(
  parametersT: TFunction<'parameters'>,
  lines: ({ occupancy: number } & Omit<Traffic.ChartData<number>, 'forward' | 'backward'>)[],
): string[] {
  return lines.map((_, index) => `${parametersT('line')}${index + 1}`);
}

export default function prepareXAxisParameters({ data, parametersT }: { data: Traffic.ChartByLines; parametersT: TFunction<'parameters'> }) {
  const forwardLines = data?.lines?.content?.forward ?? [];
  const backwardLines = data?.lines?.content?.backward ?? [];

  const amountOfForwardLines = forwardLines.length;
  const amountOfBackwardLines = backwardLines.length;

  const congestionLines = [...forwardLines, ...backwardLines].map(line => line?.occupancy ?? null);

  const categories = [...createCategories(parametersT, forwardLines), ...createCategories(parametersT, backwardLines)];

  const plotBands = [...createEmptyArray(amountOfForwardLines), ...createEmptyArray(amountOfBackwardLines)].map((_, index) => ({
    color: colorsMapper[Math.min(Math.floor(congestionLines[index] / 30), 3)] ?? COLORS.white,
    from: index - OFFSET_BY_X,
    to: index + OFFSET_BY_X,
  }));

  const plotLines = [...createEmptyArray(amountOfForwardLines), ...createEmptyArray(amountOfBackwardLines + 1)].map((_, index, arr) => ({
    ...commonPlotLinesOptions,
    dashStyle: !index || index + 1 === arr.length ? 'Solid' : 'LongDash',
    value: index - OFFSET_BY_X,
  }));

  plotLines.splice(
    amountOfForwardLines,
    1,
    {
      value: amountOfForwardLines - OFFSET_BY_X_START_LINES_DIVIDER,
      ...commonPlotLinesOptions,
    },
    {
      value: amountOfForwardLines - OFFSET_BY_X_END_LINES_DIVIDER,
      ...commonPlotLinesOptions,
    },
  );

  return {
    labels: {
      enabled: false,
    },
    plotBands,
    plotLines,
    categories,
  };
}
