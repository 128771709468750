export function isFunction(value: Common.AnyFunction): boolean {
  return value && typeof value === 'function';
}

export function isString(value: unknown): boolean {
  return typeof value === 'string';
}

export function isNumber(value: unknown): boolean {
  return typeof value === 'number' && isFinite(value);
}

export function isBoolean(value: unknown): boolean {
  return typeof value === 'boolean';
}

export function isJSON(value: string): boolean {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
}

export function isNotNullAndUndefined<T>(value: T): boolean {
  return value !== null && value !== undefined;
}
