import { TFunction } from 'react-i18next';
import groupBy from 'lodash/groupBy';
import Highcharts from 'highcharts';
import parameters from '@app/core/constants/parameters/parameters';
import Parameter from '@app/core/constants/parameters/types';

interface YAxisParameters {
  keys: string[];
  commonT: TFunction<'common'>;
}

export default function prepareYAxisParameters({ keys, commonT }: YAxisParameters): Highcharts.YAxisOptions[] {
  const currentParameters = keys.map(key => parameters[key]);

  const axisGroups: Record<keyof Parameter, Parameter> = groupBy(currentParameters, ({ unitsOptions: { key } }) => key);

  const result = Object.keys(axisGroups).map((groupKey, groupIndex) => {
    const {
      unitsOptions: { value },
    } = axisGroups[groupKey][0];

    return {
      height: '100%',
      showFirstLabel: true,
      showLastLabel: true,
      labels: {
        useHTML: true,
        formatter(formatterObject) {
          if (formatterObject.isLast) {
            return `<span style="
                     font-size: 12px; 
                     font-style: normal; 
                     font-weight: 400;
                     color: black;
                     position: absolute;
                     top: 0;
                   ">${formatterObject.value}${commonT(value)}
                 </span>`;
          }

          if (formatterObject.isFirst) {
            return `<span style="
                     font-size: 12px; 
                     font-style: normal; 
                     font-weight: 400;
                     color: black;
                     position: absolute;
                     bottom: 0;
                   ">${formatterObject.value}${commonT(value)}
                 </span>`;
          }

          return null;
        },
      },
      title: null,
      id: groupKey,
    };
  });

  return result;
}
