import * as React from 'react';
import client from '@app/clients/apollo/client';
import accountQueries from '@app/clients/apollo/requests/queries/account';
import { DATE_PERIOD } from '@app/v2/shared/constants';

const REQUEST_TIME = 54 * DATE_PERIOD.MINUTE;

const fetchAccountUnpaidStatus = async (): Promise<boolean> => {
  const { data } = await client.query<{ billing: { isUnpaid: boolean } }>({ query: accountQueries.unpaid });

  return !!data?.billing.isUnpaid;
};

export default function useAccountUnpaidStatus() {
  const [isUnpaid, setIsUnpaid] = React.useState<boolean>(false);

  React.useEffect(() => {
    fetchAccountUnpaidStatus().then(data => setIsUnpaid(data));

    const interval = setInterval(() => {
      fetchAccountUnpaidStatus().then(data => setIsUnpaid(data));
    }, REQUEST_TIME);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return isUnpaid;
}
