/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReducersNames } from '@store/constants';

type State = {
  currentElementsIdsInViewPort: { id: string }[];
  allElementsWithTime: { id: number; time: number }[];
};

export const initialState: State = {
  currentElementsIdsInViewPort: [],
  allElementsWithTime: [],
};

const viewTableElementsSlice = createSlice({
  name: ReducersNames.viewTableElements,
  initialState,
  reducers: {
    setAllElementWithTime(state: State, { payload }: PayloadAction<{ id: number; time: number }[]>) {
      state.allElementsWithTime = payload;
    },
    setCurrentElementsIdsInViewPort(state: State, { payload }: PayloadAction<{ id: string }[]>) {
      state.currentElementsIdsInViewPort = payload;
    },
  },
});

export const viewTableElementsActions = viewTableElementsSlice.actions;

export default viewTableElementsSlice.reducer;
