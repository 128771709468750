import { FC } from 'react';
import { Pages } from '@app/v2/shared/enums';
import FilterDates from './dates/FiltersDates';
import Notification from './notifications/AdditionalNotificationFilter';
import Templates from './templates/TemplatesFilter';
import Locators from './locators/AdditionalFilters';
import CSDFilterTypeOfWarning from './alertStatus/CSDFilterTypeOfWarning';

const AdditionalComponents: Partial<Record<Pages, FC<Filters.AdditionalFiltersProps>>> = {
  [Pages.Map]: CSDFilterTypeOfWarning,
  [Pages.Video]: CSDFilterTypeOfWarning,
  [Pages.Meteo]: CSDFilterTypeOfWarning,
  [Pages.LogAccident]: FilterDates,
  [Pages.LogHazard]: FilterDates,
  [Pages.Incidents]: FilterDates,
  [Pages.LogDuty]: FilterDates,
  [Pages.LogBreaks]: FilterDates,
  [Pages.LogFloodEffects]: FilterDates,
  [Pages.LogForcesAttracting]: FilterDates,
  [Pages.LogTrafficRestriction]: FilterDates,
  [Pages.LogEmergencySituation]: FilterDates,
  [Pages.LogEmergencySituationBuilding]: FilterDates,
  [Pages.LogEmergencySituationFacility]: FilterDates,
  [Pages.MapNotes]: FilterDates,
  [Pages.Notifications]: Notification,
  [Pages.LocatorAnalyze]: Locators,
  [Pages.ScoreboardTemplates]: Templates,
  [Pages.ScoreboardPlaylists]: Templates,
};

export default AdditionalComponents;
