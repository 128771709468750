import { useState, useEffect } from 'react';

export default function useImageLoad(src: string) {
  const [loading, setLoading] = useState<boolean>(false);
  const [currentSrc, updateSrc] = useState<string>(null);

  useEffect(() => {
    const fetchImage = async () => {
      if (!src) return;
      setLoading(true);
      try {
        const res = await fetch(src);
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        updateSrc(imageObjectURL);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchImage();
  }, [src]);

  useEffect(() => {
    return () => {
      if (currentSrc) {
        URL.revokeObjectURL(currentSrc);
      }
    };
  }, [currentSrc]);

  return { src: currentSrc, loading };
}
