import { BehaviorSubject } from 'rxjs';
import client from '@app/clients/apollo/client';
import profileQueries from '@app/clients/apollo/requests/queries/profiles';
import profileMutations from '@app/clients/apollo/requests/mutations/profiles';
import { ProfilesTypes } from '@app/v2/shared/enums';
import { INITIAL_PROFILE_ID, INITIAL_PROFILES } from '@app/v2/shared/constants';

class ProfilesDataSource {
  private profilesSubject = new BehaviorSubject<Profiles.CommonProfile[]>([]);

  private activeProfileSubject = new BehaviorSubject<Profiles.CommonProfile>(null);

  private loadingSubject = new BehaviorSubject(false);

  public loading = this.loadingSubject.asObservable();

  public profiles = this.profilesSubject.asObservable();

  public active = this.activeProfileSubject.asObservable();

  constructor(public type: ProfilesTypes) {
    this.profiles.subscribe(() => {
      this.setDefaultProfile();
    });
  }

  get profilesValue() {
    return this.profilesSubject.getValue();
  }

  get loadingValue() {
    return this.loadingSubject.getValue();
  }

  get activeValue() {
    return this.activeProfileSubject.getValue();
  }

  updateProfiles(newProfiles: Profiles.CommonProfile[]): Promise<void> {
    return this.handleSaveProfileChanges(newProfiles);
  }

  loadProfiles() {
    this.fetchProfiles();
  }

  setActiveProfile(newProfile: Profiles.CommonProfile) {
    this.activeProfileSubject.next(newProfile);
  }

  private fetchProfiles() {
    this.loadingSubject.next(true);

    client
      .query<{ profiles: Profiles.CommonProfile[] }>({
        query: profileQueries.profilesByType,
        variables: {
          type: this.type,
        },
      })
      .then(({ data }) => {
        if (data) {
          const { profiles } = data;

          this.loadingSubject.next(false);

          if (Array.isArray(profiles)) {
            const prepareProfiles: Profiles.CommonProfile[] = [
              { ...INITIAL_PROFILES[this.type], isDefault: !profiles.find(({ isDefault }) => isDefault) },
              ...profiles.map(({ items, ...profile }) => ({
                ...profile,
                items: items.map(item => (['windDirection', 'windDirGr'].includes(item.key) ? { ...item, dependencies: ['windSpeed'] } : item)),
              })),
            ];

            this.profilesSubject.next(prepareProfiles);
          }
        }
      })
      .catch(this.handlerError.bind(this));
  }

  private handleSaveProfileChanges(newProfiles: Profiles.CommonProfile[]): Promise<void> {
    const mappedProfiles = newProfiles
      .filter(({ id }) => id !== INITIAL_PROFILE_ID)
      .map(profile => {
        const { description, items, label, isDefault } = profile;

        if (profile.id > INITIAL_PROFILE_ID) {
          return profile;
        }

        return { description, items, label, isDefault };
      })
      .map(({ items, ...profile }) => ({
        ...profile,
        items: items.map(({ key, checked }) => ({ key, checked })),
      }));

    return client
      .mutate({
        mutation: profileMutations.updateProfilesByType,
        variables: {
          type: this.type,
          userProfiles: mappedProfiles,
        },
      })
      .then(({ data }) => {
        if (data) {
          const { userDataProfilesMutation } = data;

          this.profilesSubject.next(userDataProfilesMutation);

          this.fetchProfiles();
        }
      })
      .catch(this.handlerError.bind(this));
  }

  private setDefaultProfile() {
    const activeProfile = this.profilesSubject.value.find(({ isDefault }) => isDefault);

    if (activeProfile) {
      this.activeProfileSubject.next(activeProfile);
    } else {
      this.activeProfileSubject.next(this.profilesSubject.value[0]);
    }
  }

  private handlerError() {
    this.loadingSubject.next(false);
    this.profilesSubject.next([]);
  }
}

export default ProfilesDataSource;
