import React from 'react';
import { Stack } from '@mui/material';
import { CSDCheckbox } from '@app/modules/kit-module/shared/ui';
import { checkboxesSize, disabledCheckboxes, checkboxesWithLabel, disabledCheckboxesWithLabel } from './example';
import CSDKitDocBlock from '../../../shared/ui/CSDKitDocBlock/CSDKitDocBlock';

const CsdCheckboxes = () => {
  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="Checkboxes" codeExample={checkboxesSize}>
        <>
          <CSDCheckbox />
          <CSDCheckbox size="small" />
        </>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Checkboxes disabled" codeExample={disabledCheckboxes}>
        <>
          <CSDCheckbox disabled />
          <CSDCheckbox size="small" disabled />
        </>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Checkboxes with label" codeExample={checkboxesWithLabel}>
        <>
          <CSDCheckbox label="Label" />
          <CSDCheckbox size="small" label="Label" />
        </>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Disabled checkboxes with label" codeExample={disabledCheckboxesWithLabel}>
        <>
          <CSDCheckbox label="Label" disabled />
          <CSDCheckbox size="small" label="Label" disabled />
        </>
      </CSDKitDocBlock>
    </Stack>
  );
};

export default CsdCheckboxes;
