import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Tooltip, IconButton, Icon, Box, Stack } from '@mui/material';
import { useMutation } from '@apollo/client';
import useDialog from '@app/core/hooks/useDialog';
import NotificationRejectDialog from '@app/components/dialogs/notification-reject-dialog/NotificationRejectDialog';
import { showError } from '@app/core/utils/notifications';
import ErrorMessages from '@app/core/constants/errorsMessages';
import notificationMutations from '@app/clients/apollo/requests/mutations/notifications';
import icons from '@app/assets/iconFont';
import { useWSContext } from '@app/v2/shared/contexts';

type Props = {
  notificationId: number;
  className?: string;
} & WithTranslation<'notification', 'actions'>;

const NotificationActions = ({ notificationId, className, t }: Props) => {
  const openRejectDialog = useDialog<{ id: number }>(NotificationRejectDialog);
  const { hideNotification } = useWSContext();
  const [acceptNotification, { loading }] = useMutation(notificationMutations.accept);

  const acceptHandler = async (id: number): Promise<void> => {
    await acceptNotification({
      variables: {
        id,
      },
    })
      .then(() => {
        hideNotification(id);
      })
      .catch(() => showError(ErrorMessages.COMMON_ERROR_MESSAGE));
  };

  return (
    <Box className={className}>
      <Stack spacing={1} direction="row">
        <Tooltip title={t('accept')}>
          <IconButton disabled={loading} onClick={() => acceptHandler(notificationId)}>
            <Icon fontSize="large" color="success">
              {icons.done}
            </Icon>
          </IconButton>
        </Tooltip>
        <Tooltip title={t('reject')}>
          <IconButton disabled={loading} onClick={() => openRejectDialog({ id: notificationId })}>
            <Icon fontSize="large" color="error">
              {icons.trash}
            </Icon>
          </IconButton>
        </Tooltip>
      </Stack>
    </Box>
  );
};

export default withTranslation('notification', { keyPrefix: 'actions' })(NotificationActions);
