import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CSDTrafficChartByLines from './CSDTrafficChartByLines';

interface Props {
  data: Traffic.ChartByLines | undefined;
  loading: boolean;
}

const CSDTrafficChartByLinesConnector = ({ data, loading }: Props) => {
  const classes = useStyles();

  return <Box className={classes.container}>{!loading && !!data && <CSDTrafficChartByLines data={data} />}</Box>;
};

const useStyles = makeStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    '& > label': {
      position: 'absolute',
      top: '1rem',
      right: '2rem',
    },
  },
});

export default CSDTrafficChartByLinesConnector;
