import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Icon } from '@mui/material';
import { CSDButtonSkeleton, CSDInputStandard, CSDProfileActionPanel } from '@app/modules/kit-module/shared/ui';
import { Profile } from '@app/core/types/profiles/map';
import icons from '@app/assets/iconFont';
import useMapProfileContext from '@app/v2/features/mapProfiles/hooks/useMapProfileContext';
import useDialog from '@app/core/hooks/useDialog';
import { isDefaultMapProfile, strictlyEqual } from '@app/v2/shared/helpers';
import ConfirmDialog from '@app/components/dialogs/confirm-dialog/ConfirmDialog';
import { DEFAULT_INPUT_LENGTH, EMPTY_STRING } from '@app/v2/shared/constants';
import useMapDataLoading from '@app/core/hooks/useMapDataLoading';
import { useActions, useAppSelector } from '@app/v2/shared/hooks';
import { FooterTabs } from '@app/v2/shared/enums';

const MapProfilesManager = ({ hideProfileActions }: { hideProfileActions: boolean }) => {
  const [loadings] = useMapDataLoading();

  const { t } = useTranslation('map');
  const { t: tProfile } = useTranslation('profiles');

  const currentFooterTab = useAppSelector(state => state.map.currentFooterTab);
  const { setIsFooterOpen } = useActions();

  const [error, setError] = useState<string>(null);
  const {
    isEdited,
    currentActiveProfile,
    profiles,
    isCreateProfileMode,
    handleChangeProfile,
    handleSetNewProfile,
    handleCancelProfileCreation,
    handleChangeProfileLabel,
    handleApply,
    handleRemove,
    handleReset,
    handleSaveChanges,
    handleSetIsDefault,
  } = useMapProfileContext();

  const openConfirmDialog = useDialog(ConfirmDialog);
  const isProfileNameExist = Boolean(
    profiles?.find(({ label, id }) => strictlyEqual<string>(label, currentActiveProfile?.label) && id !== currentActiveProfile?.id),
  );

  useEffect(() => {
    if (isEdited && !isCreateProfileMode && !currentActiveProfile?.label) {
      setError(tProfile('errors.requiredInput'));
      return;
    }
    if (isEdited && isProfileNameExist) {
      setError(tProfile('notifications.profileIsExist'));
      return;
    }

    if ((error && currentActiveProfile?.label && !isProfileNameExist) || (!isEdited && !isCreateProfileMode)) {
      setError(null);
    }
  }, [currentActiveProfile, isCreateProfileMode, isEdited, isProfileNameExist, error, setError, tProfile]);

  const onChangeLabel = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    handleChangeProfileLabel(value);
  };

  const handleDeactivateSaveMod = useCallback(() => {
    openConfirmDialog({ question: tProfile('questions.cancelSaveProfileMode') }).beforeClose.subscribe((answerToQuestion: boolean): void => {
      if (!answerToQuestion) return;

      handleCancelProfileCreation();
    });
  }, [openConfirmDialog, tProfile, handleCancelProfileCreation]);

  const handleResetProfileChanges = useCallback(() => {
    openConfirmDialog({ question: tProfile('questions.resetProfile') }).beforeClose.subscribe((answerToQuestion: boolean): void => {
      if (!answerToQuestion) return;

      handleReset();
    });
  }, [openConfirmDialog, tProfile, handleReset]);

  const handleRemoveProfile = useCallback(
    async (profile: Profile): Promise<void> => {
      openConfirmDialog({ question: tProfile('questions.deleteProfile') }).beforeClose.subscribe(async (answerToQuestion: boolean): Promise<void> => {
        if (!answerToQuestion) return;

        await handleRemove(profile);
      });
    },
    [openConfirmDialog, handleRemove, tProfile],
  );

  const handleSaveProfile = useCallback(
    async (profile: Profile) => {
      setError(null);
      if (profile.label && isCreateProfileMode && isProfileNameExist) {
        setError(tProfile('notifications.profileIsExist'));
        return;
      }

      if (!profile.label) {
        setError(tProfile('errors.requiredInput'));
        return;
      }

      await handleSaveChanges(profile);
    },
    [setError, isCreateProfileMode, isProfileNameExist, tProfile, handleSaveChanges],
  );

  const handleSetActive = useCallback(
    (profile: Profile) => {
      if (isCreateProfileMode) {
        openConfirmDialog({ question: tProfile('questions.cancelSaveProfileMode') }).beforeClose.subscribe((answerToQuestion: boolean): void => {
          if (!answerToQuestion) return;

          handleCancelProfileCreation();
        });
      } else {
        handleChangeProfile(profile.id);
      }
    },
    [isCreateProfileMode, handleCancelProfileCreation, tProfile, handleChangeProfile, openConfirmDialog],
  );

  const handleApplyChanges = useCallback(
    async (profile: Profile) => {
      await handleApply(profile);
      setIsFooterOpen(false);
    },
    [handleApply, setIsFooterOpen],
  );

  return (
    <>
      {!!currentActiveProfile && (
        <Box position="relative">
          <Box sx={{ marginBottom: '1rem', minHeight: '40px' }}>
            <CSDProfileActionPanel<Profile>
              activeProfile={currentActiveProfile}
              isSaveProfileMode={isCreateProfileMode}
              profiles={profiles}
              skeletonSettings={{
                loading: loadings.PROFILES.load,
                skeletonsAmount: profiles.length + 1,
                component: <CSDButtonSkeleton />,
              }}
              profileActions={{
                setActive: {
                  action: profile => handleSetActive(profile),
                  actionName: tProfile('buttons.setToActive'),
                },
                addNewProfile: {
                  action: profile => handleSaveProfile(profile),
                  actionName: tProfile('buttons.save'),
                  tooltipTitle: tProfile('tooltips.saveNewProfile'),
                },
                activateSaveMod: {
                  action: profile => handleSetNewProfile(profile),
                  actionName: tProfile('buttons.save'),
                  tooltipTitle: tProfile('tooltips.addNewProfile'),
                },
                deactivateSaveMod: {
                  action: handleDeactivateSaveMod,
                  actionName: tProfile('buttons.cancel'),
                  tooltipTitle: tProfile('tooltips.cancelSaveMode'),
                },
              }}
              hideProfileActions={hideProfileActions}
              panelActions={[
                {
                  actionName: tProfile('buttons.default'),
                  action: profile => handleSetIsDefault(profile),
                  isView: !isDefaultMapProfile(currentActiveProfile) && !currentActiveProfile?.isDefault,
                },
                {
                  actionName: t('mapCardControl.buttons.saveChanges'),
                  actionType: 'outlined',
                  action: handleSaveProfile,
                  isView: !!currentActiveProfile?.id || (currentActiveProfile?.id === null && isCreateProfileMode),
                  Icon: <Icon>{icons.save}</Icon>,
                  isDisabled: !isEdited,
                },
                {
                  actionName: t('mapCardControl.buttons.reset'),
                  action: handleResetProfileChanges,
                  actionType: 'error',
                  Icon: <Icon>{icons.refresh}</Icon>,
                  isView: isEdited,
                },
                {
                  actionName: t('mapCardControl.buttons.remove'),
                  actionType: 'error',
                  action: handleRemoveProfile,
                  Icon: <Icon>{icons.trash}</Icon>,
                  isView: !!currentActiveProfile?.id && profiles.length > 1,
                },
                {
                  actionName: t('mapCardControl.buttons.apply'),
                  action: handleApplyChanges,
                  isView: currentFooterTab !== FooterTabs.MeteoLocators,
                  isDisabled: !isEdited,
                },
              ]}
            />
            <Divider />
          </Box>
          <Box sx={{ marginBottom: '1rem' }}>
            {(!isDefaultMapProfile(currentActiveProfile) || isCreateProfileMode || !!currentActiveProfile?.id) && (
              <CSDInputStandard
                label={tProfile('title')}
                maxLength={DEFAULT_INPUT_LENGTH}
                icon={icons.edit}
                error={!!error}
                helperText={!!error && error}
                placeholder={isCreateProfileMode ? tProfile('placeholder.typeNewProfile') : EMPTY_STRING}
                value={currentActiveProfile?.label || EMPTY_STRING}
                onChange={onChangeLabel}
                sx={{ maxWidth: '450px' }}
              />
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default MapProfilesManager;
