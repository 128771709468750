import React, { CSSProperties } from 'react';
import { Stack } from '@mui/material';
import { boxShadowLight } from '@theme/styles';

type Props = {
  children: React.ReactNode;
  wrapperCSSProperty?: CSSProperties;
};

const CSDIconPanelPaper = ({ children, wrapperCSSProperty }: Props) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        textAlign: 'center',
        gap: '1rem',
        padding: '0.5rem 1rem',
        borderRadius: '0.5rem',
        minHeight: '2.5rem',
        backgroundColor: ({ palette: { background } }) => background.third,
        transition: 'all .3s ease-in-out',

        '&:hover': {
          boxShadow: boxShadowLight,
        },

        ...wrapperCSSProperty,
      }}
    >
      {children}
    </Stack>
  );
};

export default CSDIconPanelPaper;
