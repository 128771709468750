import React, { Dispatch, ReactNode, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import clsx from 'clsx';
import { CSDScoreboardsViewer } from '@app/modules/kit-module/shared/ui';
import { AZPIMode, UsersAccess, ViewTypes, VMSTemplatesTypes } from '@app/v2/shared/enums';
import { isAZPITemplateType, strictlyEqual } from '@app/v2/shared/helpers';
import { DATE_PERIOD, DEFAULT_TEMPLATE_CHANGE_DURATION } from '@app/v2/shared/constants';
import { useCheckAccess } from '@app/v2/shared/hooks';
import CSDAZPILabel from './CSDAZPILabel';

interface Props {
  isActive: boolean;
  isChangeable?: boolean;
  withBlur?: boolean;
  azpiMode?: AZPIMode;
  station: Scoreboards.VMSStation;
  updateTemplateStatus: ReactNode;
  setManualMode?: ReactNode;
  setActive: Dispatch<Scoreboards.VMSStation>;
}

const CSDTPIDeviceScreen = ({
  isActive,
  station,
  updateTemplateStatus,
  setManualMode,
  azpiMode,
  isChangeable = false,
  withBlur = true,
  setActive,
}: Props) => {
  const { accessChecker } = useCheckAccess();

  const { playlist, settings } = station.device;
  const templatesLength = playlist?.playlist?.length ?? 0;

  const classes = useStyles({ type: settings?.type });

  const [activeTemplateIndex, setActiveTemplateIndex] = useState<number>(0);

  useEffect(() => {
    if (!isChangeable || !templatesLength) return undefined;

    const interval = setInterval(() => {
      setActiveTemplateIndex(prev => {
        if (strictlyEqual(templatesLength, prev + 1)) return 0;
        return prev + 1;
      });
    }, (playlist.playlist[activeTemplateIndex]?.duration ?? DEFAULT_TEMPLATE_CHANGE_DURATION) * DATE_PERIOD.SECOND);

    return () => {
      clearInterval(interval);
      return undefined;
    };
  }, [activeTemplateIndex, isChangeable, playlist?.playlist, templatesLength]);

  useEffect(() => () => setActiveTemplateIndex(0), [playlist]);

  const isViewTemplate = !!playlist?.playlist?.[activeTemplateIndex]?.template;

  const additionalConditionsRenderTemplateByType: Record<VMSTemplatesTypes, boolean> = {
    [VMSTemplatesTypes.Azpi]: azpiMode === AZPIMode.Active && isViewTemplate,
    [VMSTemplatesTypes.Sign]: isViewTemplate,
    [VMSTemplatesTypes.Message]: isViewTemplate,
  };

  return (
    <Stack
      direction="column"
      className={clsx(classes.wrapper, { unActive: !isActive && withBlur })}
      onClick={() => {
        if (!isActive) setActive(station);
      }}
    >
      {accessChecker([UsersAccess.VMS_VIEW_ONLY, UsersAccess.VMS_LIMIT_EDIT, UsersAccess.VMS_FULL_EDIT]) ? (
        updateTemplateStatus
      ) : (
        <Box sx={{ height: '1rem' }} />
      )}
      {setManualMode}

      <Box className={classes.templateViewerContainer}>
        {additionalConditionsRenderTemplateByType[station.type] && (
          <CSDScoreboardsViewer
            fullSize
            isDrawCells={false}
            viewType={ViewTypes.Canvas}
            templateType={VMSTemplatesTypes.Message}
            template={playlist.playlist[activeTemplateIndex]?.template}
          />
        )}
      </Box>

      {isAZPITemplateType(station.type) && <CSDAZPILabel mode={azpiMode} />}
    </Stack>
  );
};

const useStyles = makeStyles<Theme, Pick<Scoreboards.VMSStation, 'type'>>(({ palette }) => ({
  wrapper: {
    zIndex: 1,
    gap: '0.233rem',
    cursor: 'default',
    padding: '0.467rem',
    position: 'relative',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: '0.5rem',
    justifyContent: 'space-between',
    backgroundColor: palette.text.primary,
    maxWidth: ({ type }) => (type === VMSTemplatesTypes.Message ? '8.5rem' : '4rem'),
    minWidth: ({ type }) => (type === VMSTemplatesTypes.Message ? '8.5rem' : '4rem'),

    '&.unActive': {
      filter: 'blur(1px)',
      cursor: 'pointer',
    },
  },
  templateViewerContainer: {
    overflow: 'hidden',
    outline: `1px solid ${palette.common.white}`,
    '&.MuiBox-root': {
      height: '2.913rem',
      width: ({ type }) => (type === VMSTemplatesTypes.Message ? '7.524rem' : '2.913rem'),
    },
  },
  actions: {
    gap: '0.3rem',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.common.white,
  },
}));

export default CSDTPIDeviceScreen;
