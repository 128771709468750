export const colors = ['#8D909A', '#CE2E2E', '#FFDB5B'];
export const labelsColors = ['#ffffff', '#ffffff', '#000000'];

export const pointWidth = 40;
export const nestedPointWidth = 20;
export const pointTextColor = '#000000';

export const rowHeight = 50;
export const headerHeight = 78;

export const chartHeight = 750;
export const chartPaddingTop = 24;
export const chartInfoBoxOffsetForExporting = 96;

export const yAxisBackgroundId = '#yAxisBackground';
export const yAxisWidth = 320;
export const yAxisColumnWidth = 160;
export const yAxisLabelBorderColor = 'rgba(181, 184, 190, 1)';
export const yAxisRowBorderColor = 'rgba(214, 218, 231, 1)';
export const yAxisSelectedRowColor = '#979fe5';
export const yAxisBgColor = '#d6dae7';

export const xAxisBorderColor = 'rgba(181, 184, 190, 1)';
export const xAxisLabelsColor = '#000000';

export const recommendationAlertType = 'recommendation';
