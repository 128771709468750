import Yup from '@app/core/validation/locale';
import { i18n } from '@app/v2/shared/localization';

const userPresetValidationSchema = (presets: Filters.Set[]) =>
  Yup.object().shape<Pick<Filters.Set, 'label'>>({
    label: Yup.string()
      .notOneOf(
        presets.map(({ label }) => label?.toLowerCase()),
        i18n.t('filters:notifications.presetTitleExist'),
      )
      .min(3)
      .max(20)
      .required(i18n.t('filters:notifications.requiredField')),
  });
export default userPresetValidationSchema;
