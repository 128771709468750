import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import useAppDispatch from '@app/core/hooks/customReduxHooks/useAppDispatch';
import { mapActions } from '@app/store/slices/map';
import { authActions } from '@app/store/slices/auth';
import { accountActions } from '@app/store/slices/account';
import { chatActions } from '@app/store/slices/chat';
import { filterActions } from '@app/store/slices/filters';
import { loaderActions } from '@app/store/slices/loader';
import { subscriptionsActions } from '@app/store/slices/subscriptions';
import { viewTableElementsActions } from '@app/store/slices/viewTableElements';
import { systemActions } from '@app/store/slices/system';

const allActions = {
  ...authActions,
  ...accountActions,
  ...chatActions,
  ...filterActions,
  ...loaderActions,
  ...mapActions,
  ...subscriptionsActions,
  ...systemActions,
  ...viewTableElementsActions,
};

const useActions = () => {
  const dispatch = useAppDispatch();

  return useMemo(() => {
    return bindActionCreators(allActions, dispatch);
  }, [dispatch]);
};

export default useActions;
