import React from 'react';
import Grid from '@mui/material/Grid';
import { LOGS_PHOTO_LIMIT, LOGS_PHOTO_MAX_SIZE } from '@app/core/constants/files';
import CustomFileComponentRedesign from '@app/components/common/custom-form-component/CustomFileComponentRedesign';
import Field from '../../../modules/map-module/components/map/notes/form/Field';

type Props = {
  uploadPath: string;
  photoField: any;
};

const NoteFiles = ({ uploadPath, photoField }: Props) => {
  return (
    <Grid item xs={8}>
      <Field
        name="photos"
        uploadPath={uploadPath}
        filesLimit={LOGS_PHOTO_LIMIT}
        maxFileSize={LOGS_PHOTO_MAX_SIZE}
        component={CustomFileComponentRedesign}
        initialImages={photoField}
      />
    </Grid>
  );
};

export default NoteFiles;
