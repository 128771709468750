import { NO_DATA } from '@app/core/constants';

const noDataFilter = (strings, value, ...other) => {
  if (other && other.length > 0) {
    throw new Error('`noDataFilter` must have only one parameter');
  }
  if (value === null || value === undefined) {
    return NO_DATA;
  }

  return `${strings[0]}${value}${strings[1]}`;
};

export default noDataFilter;
