import * as React from 'react';
import { FixedSizeList as List } from 'react-window';
import { makeStyles } from '@mui/styles';
import { HeaderCell } from '@app/modules/log-module/common/types/ListingTypes';
import { useCarouselSize, useTemporarySetTableMode } from '@app/v2/shared/hooks';
import { CARD_HEIGHT, CARD_IN_CAROUSEL_SIZE, CAROUSEL_PADDING_RIGHT } from '@app/v2/shared/constants';
import ListingCard from './listing/ListingCard';

type Props<T> = {
  headers: HeaderCell[];
  listingData: T[];
  handleVirtualizedScroll: (index: number) => void;
  loading: boolean;
};

const NotesCarousel = <T,>({ listingData, headers, handleVirtualizedScroll, loading }: Props<T>) => {
  const { width } = useCarouselSize();
  const classes = useStyles();
  useTemporarySetTableMode();

  const Row: React.FC<{ index: number; style: React.CSSProperties }> = ({ index, style }) => (
    <div className={classes.row} style={style}>
      <ListingCard headers={headers} row={listingData[index]} />
    </div>
  );

  return (
    <List
      height={CARD_HEIGHT}
      itemCount={listingData.length}
      itemSize={CARD_IN_CAROUSEL_SIZE}
      width={width - CAROUSEL_PADDING_RIGHT}
      layout="horizontal"
      onItemsRendered={({ visibleStartIndex }) => handleVirtualizedScroll(visibleStartIndex)}
    >
      {Row}
    </List>
  );
};

const useStyles = makeStyles({
  row: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: '0.625rem',
  },
});

export default NotesCarousel;
