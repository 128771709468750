import { TFunction } from 'react-i18next';
import { TrafficChartDirection } from '@app/core/types/charts/traffic';
import { COLORS } from '@theme/constants';

const colorsMapper: Record<string, string> = {
  0: 'rgba(74, 232, 61, 0.19)',
  1: 'rgba(252, 196, 25, 0.16)',
  2: 'rgba(255, 0, 0, 0.20)',
};

export default function prepareXAxisParameters({ data, parametersT }: { data: Traffic.ChartDirections; parametersT: TFunction<'parameters'> }) {
  return {
    labels: {
      enabled: false,
    },
    plotBands: [
      {
        color: colorsMapper[Math.min(Math.floor(data.directions.content.forward[0]?.occupancy / 30), 3)] ?? COLORS.white,
        from: -0.5,
        to: 0.54,
      },
      {
        color: 'white',
        from: 0.54,
        to: 0.56,
      },
      {
        color: colorsMapper[Math.min(Math.floor(data.directions.content.backward[0]?.occupancy / 30), 3)] ?? COLORS.white,
        from: 0.56,
        to: 1.5,
      },
    ],
    plotLines: [
      {
        dashStyle: 'Solid',
        color: 'black',
        value: -0.5,
        width: 3,
        zIndex: 6,
      },
      {
        dashStyle: 'Solid',
        color: 'black',
        value: 0.54,
        width: 3,
        zIndex: 6,
      },
      {
        dashStyle: 'Solid',
        color: 'black',
        value: 0.56,
        width: 3,
        zIndex: 6,
      },
      {
        dashStyle: 'Solid',
        color: 'black',
        value: 1.5,
        width: 3,
        zIndex: 6,
      },
    ],
    categories: [parametersT(TrafficChartDirection.Forward), parametersT(TrafficChartDirection.Backward)],
  };
}
