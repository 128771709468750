import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Card } from '@mui/material';
import { styled } from '@mui/styles';
import { useActions, useAppSelector, useAppDispatch, useLocalStorage } from '@app/v2/shared/hooks';
import { PASSWORD_RECOVERY_COUNTDOWN_VALUE } from '@app/core/constants/auth';
import { passwordRecoveryStepOne } from '@store/thunks/auth/auth-thunks';
import validationSchema from '@app/modules/auth-module/components/passwordRecoveryForm/validationSchema';
import { PublicPath } from '@app/v2/shared/enums';
import FormContent from './FormContent/FormContent';
import FormActions from './FormAction/FormActions';
import FormHeader from '../common/FormHeader';

const EmailRecovery = ({ t }: I18N.TranslationFunction<'auth', 'passwordRecovery'>) => {
  const { removeItem } = useLocalStorage();
  const { push } = useHistory();
  const passwordRecoveryStatus = useAppSelector(state => state.auth.passwordRecoveryStatus);
  const { changePasswordRecoveryStatus } = useActions();

  const dispatch = useAppDispatch();

  const onHandleSubmit = (authParameters: Auth.PasswordRecoveryStepOneParams): void => {
    dispatch(passwordRecoveryStepOne(authParameters));
  };

  const handleRedirectToPage = useCallback(() => {
    removeItem(PASSWORD_RECOVERY_COUNTDOWN_VALUE);
    changePasswordRecoveryStatus(null);
    if (!passwordRecoveryStatus) {
      push(PublicPath.Auth);
    }
  }, [passwordRecoveryStatus, changePasswordRecoveryStatus, push, removeItem]);

  return (
    <Formik<Auth.PasswordRecoveryStepOneParams> initialValues={{ login: '' }} validationSchema={validationSchema} onSubmit={onHandleSubmit}>
      <Form>
        <StyledCard elevation={3}>
          <FormHeader title={t('cardTitle')} redirectToPage={handleRedirectToPage} />
          <FormContent />
          <FormActions />
        </StyledCard>
      </Form>
    </Formik>
  );
};

const StyledCard = styled(Card)({
  '&.MuiPaper-root': {
    width: '18.25rem',
    minHeight: '17rem',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '0 !important',

    '& span.MuiCardHeader-title': {
      textAlign: 'center',
      fontSize: '1.25rem',
      lineHeight: '1.25rem',
      padding: '1rem',
    },
  },
});

export default withTranslation('auth', { keyPrefix: 'passwordRecovery' })(memo(EmailRecovery));
