import React, { useCallback, useEffect, useMemo } from 'react';
import { KeyPrefix, useTranslation } from 'react-i18next';
import moment from 'moment';
import { Box, Chip, Icon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import WWOWeatherTypes from '@app/modules/map-module/enums/WeatherTypes';
import useSliderValue from '@app/modules/map-module/hooks/useSliderValue';
import { PlayerStatus } from '@app/v2/shared/enums';
import icons from '@app/assets/iconFont';
import { WEATHER_MAP_MAX_DATE, WEATHER_MAP_STEP_DATE } from '@app/core/constants/map';
import { useActions, useAppSelector, usePrevious } from '@app/v2/shared/hooks';
import { CSDSwitcher } from '@app/modules/kit-module/shared/ui';
import { isDefaultMapProfile } from '@app/v2/shared/helpers';
import useMapProfileContext from '@app/v2/features/mapProfiles/hooks/useMapProfileContext';
import WeatherSlide from './WeatherSlide';
import SensorButtons from '../../components/SensorButtons';
import MapProfilesManager from '../../components/MapProfilesManager';
import DisabledWrapperWithTooltip from '../../components/DisabledWrapperWithTooltip';

const weatherTypes: { type: KeyPrefix<'map'>; value: WWOWeatherTypes | ''; icon?: string }[] = [
  { type: 'mapCardControl.WWOWeatherTypes.notChoose', value: '' },
  { type: 'mapCardControl.WWOWeatherTypes.temperature', value: WWOWeatherTypes.Temperature, icon: icons.tempAir },
  { type: 'mapCardControl.WWOWeatherTypes.cloud', value: WWOWeatherTypes.Cloud, icon: icons.cloudFour },
  { type: 'mapCardControl.WWOWeatherTypes.wind', value: WWOWeatherTypes.Wind, icon: icons.wind },
  { type: 'mapCardControl.WWOWeatherTypes.humidity', value: WWOWeatherTypes.Humidity, icon: icons.liquidPrecipitation },
  { type: 'mapCardControl.WWOWeatherTypes.pressure', value: WWOWeatherTypes.Pressure, icon: icons.pressure },
  { type: 'mapCardControl.WWOWeatherTypes.precipitations', value: WWOWeatherTypes.Precipitations, icon: icons.precipitationIntensity },
  { type: 'mapCardControl.WWOWeatherTypes.visibility', value: WWOWeatherTypes.Visibility, icon: icons.visibility },
];

const WeatherPanel = ({ hideProfileActions }: { hideProfileActions: boolean }) => {
  const classes = useStyles();
  const { t } = useTranslation('map');
  const { setSliderStateAction, setWeatherType: setWeatherTypeAction, toggleIsDisplayWindOnMap } = useActions();
  const { currentActiveProfile } = useMapProfileContext();

  const activeWeatherType = useAppSelector(({ map }) => map.weather.activeWeatherType);
  const currentSliderState = useAppSelector(({ map }) => map.weather.currentSliderState);
  const isDisplayWindOnMap = useAppSelector(({ map }) => map.weather.isDisplayWindOnMap);
  const prevActiveWeatherType = usePrevious(activeWeatherType);
  const [currentSliderValue, setSliderValue] = useSliderValue();

  const setWeatherType = useCallback(
    (type: WWOWeatherTypes | '') => {
      setWeatherTypeAction(type);
    },
    [setWeatherTypeAction],
  );

  const setSliderState = useCallback(
    (state: PlayerStatus) => {
      setSliderStateAction(state);
    },
    [setSliderStateAction],
  );

  const isActive = useMemo(() => !!activeWeatherType, [activeWeatherType]);

  const onChange = useCallback(newValue => setSliderValue(newValue), [setSliderValue]);

  const onState = useCallback(newValue => setSliderState(newValue), [setSliderState]);

  const currentDate = useMemo(() => moment().startOf('hour').unix(), []);
  const maxDate = currentDate + WEATHER_MAP_MAX_DATE;
  const valueDate = useMemo(() => currentSliderValue || currentDate, [currentDate, currentSliderValue]);

  const tabs: { label: KeyPrefix<'map'>; icon; color: string; disabled?: boolean; click?: () => void }[] = useMemo(() => {
    return [
      {
        label: 'mapCardControl.weatherSensor.toStart',
        icon: <Icon sx={{ transform: 'rotate(0.5turn)' }}>{icons.playFast}</Icon>,
        color: '#222631',
        disabled: currentSliderState === PlayerStatus.PLAY,
        click: () => onChange(currentDate),
      },
      {
        label: 'mapCardControl.weatherSensor.backward',
        icon: <Icon sx={{ transform: 'rotate(0.5turn)' }}>{icons.playNext}</Icon>,
        color: '#222631',
        disabled: currentSliderState === PlayerStatus.PLAY,
        click: () => onChange(Math.max(valueDate - WEATHER_MAP_STEP_DATE, currentDate)),
      },
      {
        label: 'mapCardControl.weatherSensor.start',
        icon:
          currentSliderState === PlayerStatus.PLAY ? (
            <Icon sx={{ fontSize: '3rem !important' }}>{icons.pause}</Icon>
          ) : (
            <Icon sx={{ fontSize: '3rem !important' }}>{icons.play}</Icon>
          ),
        color: currentSliderState === PlayerStatus.PLAY ? '#1976d2' : '#222631',
        click: () => onState(currentSliderState === PlayerStatus.PLAY ? PlayerStatus.STOP : PlayerStatus.PLAY),
      },
      {
        label: 'mapCardControl.weatherSensor.forward',
        icon: <Icon>{icons.playNext}</Icon>,
        color: '#222631',
        disabled: currentSliderState === PlayerStatus.PLAY,
        click: () => onChange(Math.min(valueDate + WEATHER_MAP_STEP_DATE, maxDate)),
      },
      {
        label: 'mapCardControl.weatherSensor.toEnd',
        icon: <Icon>{icons.playFast}</Icon>,
        color: '#222631',
        disabled: currentSliderState === PlayerStatus.PLAY,
        click: () => onChange(maxDate),
      },
    ];
  }, [currentSliderState, onState, onChange, currentDate, valueDate, maxDate]);

  useEffect(() => {
    setSliderValue(valueDate);
  }, [valueDate, setSliderValue]);

  useEffect(() => {
    if (currentSliderValue === null) {
      onChange(currentDate);
    }
  }, [currentDate, onChange, currentSliderValue]);

  useEffect(() => {
    if (currentSliderValue < currentDate || currentSliderValue > maxDate) {
      onChange(currentDate);
    }
  }, [currentSliderValue, onChange, currentDate, maxDate]);

  useEffect(() => {
    if (!activeWeatherType && activeWeatherType !== prevActiveWeatherType) {
      onState(PlayerStatus.STOP);
      onChange(currentDate);
    }
  }, [activeWeatherType, prevActiveWeatherType, currentDate, onChange, onState]);

  return (
    <>
      <MapProfilesManager hideProfileActions={hideProfileActions} />
      <DisabledWrapperWithTooltip disabled={isDefaultMapProfile(currentActiveProfile)}>
        <Box className={classes.weatherContainer}>
          <CSDSwitcher
            defaultChecked={isDisplayWindOnMap}
            label={t('mapSettingLabels.isViewWindOnMap')}
            onChange={(_, checked) => {
              toggleIsDisplayWindOnMap({ status: checked });
            }}
          />
          <Box className={classes.headContainer}>
            <Box className={classes.weatherTypes}>
              {weatherTypes.map(({ type, value, icon }) => (
                <Chip
                  key={type}
                  label={t(type)}
                  variant="outlined"
                  icon={icon && <Icon>{icon}</Icon>}
                  {...(activeWeatherType === value && {
                    variant: 'filled',
                    color: 'primary',
                  })}
                  onClick={() => setWeatherType(value)}
                />
              ))}
            </Box>
            <SensorButtons buttonsClass={classes.buttonStyles} disabled={!isActive} tabs={tabs} />
          </Box>
          <WeatherSlide />
        </Box>
      </DisabledWrapperWithTooltip>
    </>
  );
};

const useStyles = makeStyles(() => ({
  weatherContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '11rem',
  },
  headContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: '1rem',
    marginBottom: '1.4rem',
  },
  weatherTypes: {
    display: 'flex',
    flexWrap: 'wrap',
    '& .MuiChip-root': {
      '&:not(:last-child)': {
        marginRight: '0.5rem',
      },
      marginBottom: '0.5rem',
    },
  },
  buttonStyles: {
    '& .MuiIcon-root': {
      fontSize: '2rem',
    },
  },
}));

export default WeatherPanel;
