/** Delay */
import { DATE_PERIOD } from '@app/v2/shared/constants/date';

export const ENTER_TOOLTIP_DELAY = 500;
export const DEBOUNCE_INTERVAL = 700;

/** Common */

export const NO_DATA = '---';
export const EMPTY_STRING = '';
export const DEFAULT_INPUT_LENGTH = 20;
export const METEO_CHART_PROFILE_INPUT_LENGTH = 10;
export const CURRENT_PAGE_LABEL_LENGTH_WITHOUT_TOOLTIP = 30;
export const USER_LABEL_LENGTH_WITHOUT_TOOLTIP = 14;
export const LISTING_DEFAULT_PAGE_NUMBER = 1;

/** Slectors */

export const hoursSelectorsValue = new Array(24).fill('').map((_, i) => ({ label: String(i), value: i }));

export const VIDEO_WALL_MAX_ROWS = process.env.REACT_APP_VIDEO_WALL_MAX_ROWS ?? 10;
export const VIDEO_WALL_MAX_COLS = process.env.REACT_APP_VIDEO_WALL_MAX_COLS ?? 10;

export const DEFAULT_PAGE_FOR_REDIRECT = 'index';

export const REDIRECT_AWAIT_TIME_VALUE = 5000;

export const ALERT_STATUS_POLL_INTERVAL = DATE_PERIOD.MINUTE * 30;
