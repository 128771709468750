export const autocompleteCodeExample = `<CSDAutocomplete
  label="Вопрос"
  inputProps={{
    placeholder: '2-я бауманская д5',
  }}
  autocompleteProps={{
    options: top100Films,
  }}
/>`;

export const fullWidthAutocompleteCodeExample = `<CSDAutocomplete
  label="Вопрос"
  inputProps={{
    placeholder: '2-я бауманская д5',
  }}
  autocompleteProps={{
    options: top100Films,
  }}
/>`;

export const disabledAutocompleteCodeExample = `<CSDAutocomplete
  label="Вопрос"
  disabled
  inputProps={{
    placeholder: '2-я бауманская д5',
    sx: { width: '450px' },
  }}
  autocompleteProps={{
    options: top100Films,
  }}
/>`;

export const multipleAutocomplete = `<CSDAutocomplete
  label="Вопрос"
  inputProps={{
    placeholder: '2-я бауманская д5',
    sx: { width: '450px' },
  }}
  autocompleteProps={{
    options: top100Films,
    multiple: true,
  }}
/>`;
