import { i18n } from '@app/v2/shared/localization';
import isFunction from '@app/core/helpers/isFunction';
import { showErrorWithoutCodes } from '@app/core/utils/notifications';
import { isString } from '@app/v2/shared/helpers';

export default function addFeatureActions<PromiseReturnType>(
  promise: Promise<PromiseReturnType>,
  { success, reject, after }: Common.FeaturesActions,
) {
  promise
    .then((data: PromiseReturnType) => isFunction(success) && success(data))
    .catch(error => {
      if (error?.message && isString(error.message)) showErrorWithoutCodes(error.message);
      else showErrorWithoutCodes(i18n.t('common:errors.badAction'));
    })
    .then(() => isFunction(reject) && reject())
    .finally(() => isFunction(after) && after());
}
