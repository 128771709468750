/* eslint-disable no-param-reassign */
import { Viewport } from 'react-leaflet';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LatLngTuple } from 'leaflet';
import moment from 'moment';
import { RoadObjectsTypes, VMSTemplatesTypes, FooterTabs, PlayerStatus, AdditionalRoadObjectActions } from '@app/v2/shared/enums';
import SettingTypes from '@app/modules/map-module/enums/SettingTypes';
import { changeCheckboxState, changeLoadingMapState, getInitialLoadingStateByTypes } from '@app/core/helpers/changeMapState';
import LayerTypes from '@app/modules/map-module/enums/LayerTypes';
import StaticObjectTypes from '@app/modules/map-module/enums/StaticObjectTypes';
import ZoomMarker from '@app/modules/map-module/interfaces/ZoomMarker';
import makeActiveState from '@app/core/helpers/makeActiveState';
import DataLoadingTypes from '@app/modules/map-module/enums/DataLoadingTypes';
import NotesTypes from '@app/modules/map-module/enums/NotesTypes';
import WWOWeatherTypes from '@app/modules/map-module/enums/WeatherTypes';
import { Profile } from '@app/core/types/profiles/map';
import { State, StateMapDataObjectKeys } from '@store/slices/map/MapState';
import { ReducersNames } from '@store/constants';
import MapCardControlTabs from '@app/modules/map-module/enums/MapCardControlTabs';
import { DEFAULT_VIEWPORT, INITIAL_MAP_PROFILE, DEFAULT_MARKER_ZOOMS, EMPTY_STRING } from '@app/v2/shared/constants';

export type ObjectMapState = { key: StaticObjectTypes; checked: boolean };
export type NotesMapState = { key: NotesTypes; checked: boolean };

const getInitialStateByTypes = <T>(types: T): StateMapDataObjectKeys<T> => {
  return Object.fromEntries(Object.values(types).map(item => [item, { checked: false }]));
};

export const initialState: State = {
  settings: getInitialStateByTypes(SettingTypes),

  /** Common */
  currentTab: MapCardControlTabs.Settings,

  currentFooterTab: false,
  isFooterOpen: false,

  /** Weather */
  weather: {
    activeWeatherType: '',
    currentSliderValue: null,
    currentSliderState: PlayerStatus.STOP,
    windDataURL: null,
    isDisplayWindOnMap: false,
  },

  roadObjects: {
    ...getInitialStateByTypes(RoadObjectsTypes),
  },

  locatorImages: {
    controls: {},
  },

  gis: {
    ...getInitialStateByTypes(StaticObjectTypes),
  },

  /** Notes */
  notes: getInitialStateByTypes(NotesTypes),
  mapNotesCursor: false,
  isReloadNoteListing: false,

  /** layers  */
  activeLayer: LayerTypes.YANDEX_MAP,

  /** Profiles * */
  profiles: [],

  /** Locators */
  locators: {},

  /** Active profile */
  activeProfile: null,

  /** Leaflet Element */
  viewport: DEFAULT_VIEWPORT,

  /** Zoom ranges for map markers */
  markerZooms: DEFAULT_MARKER_ZOOMS,

  /** Coordinates */
  coordinates: {
    roadSegments: [],
    roadObjects: [],
    staticObjects: [],
    weatherRadarsImages: [],
    notes: [],
  },

  /** Loadings */
  loadings: {
    ...getInitialLoadingStateByTypes(DataLoadingTypes),
  },

  /** Other */
  dataForVMSStationModal: { placeId: null, type: null, organizationName: EMPTY_STRING },

  currentRoadObjectController: null,
  additionalRoadOjectControl: null,
};

const mapSlice = createSlice({
  name: ReducersNames.map,
  initialState,
  reducers: {
    changeTab(state, { payload }: PayloadAction<MapCardControlTabs>) {
      state.currentTab = payload;
    },
    changeFooterTab(state, { payload }: PayloadAction<FooterTabs | false>) {
      state.currentFooterTab = payload;
    },
    setIsFooterOpen(state, { payload }: PayloadAction<boolean>) {
      state.isFooterOpen = payload;
    },
    changeMapSettingCheckbox(state, { payload }: PayloadAction<any>) {
      state.settings = changeCheckboxState(state.settings, payload);
    },
    setMapRoadSegmentsCoordinates(state, { payload }: PayloadAction<LatLngTuple[]>) {
      state.coordinates.roadSegments = payload;
    },
    setWeatherType(state, { payload }: PayloadAction<WWOWeatherTypes | ''>) {
      state.weather.activeWeatherType = payload;
    },
    setSliderValue(state, { payload }: PayloadAction<any>) {
      state.weather.currentSliderValue = payload;
    },
    setSliderStateAction(state, { payload }: PayloadAction<any>) {
      state.weather.currentSliderState = payload;
    },
    setWindDataURL(state, { payload }: PayloadAction<string>) {
      state.weather.windDataURL = payload;
    },
    toggleIsDisplayWindOnMap(state, { payload }: PayloadAction<{ status: boolean }>) {
      state.weather.isDisplayWindOnMap = payload.status;
    },
    changeMapRoadObjectCheckbox(state, { payload }: PayloadAction<any>) {
      state.roadObjects = changeCheckboxState(state.roadObjects, payload);
    },
    setMapRoadObjectsCoordinates(state, { payload }: PayloadAction<LatLngTuple[]>) {
      state.coordinates.roadObjects = payload;
    },
    setControl(state: State, { payload }: PayloadAction<{ id: number; control: WeatherLocators.LocatorControl }>) {
      const locatorForControl = state.locators[payload.id];
      const id = String(payload.id);

      if (locatorForControl) {
        const { controls } = state.locatorImages;

        controls[id] = { ...(controls[id] || {}), ...payload.control };
      }
    },
    setCurrentTime(state: State, { payload }: PayloadAction<{ id: number; currentTime: string }>) {
      const locatorForControl = state.locators[payload.id];
      const id = String(payload.id);

      if (locatorForControl) {
        const { controls } = state.locatorImages;

        controls[id] = { ...controls[id], currentTime: payload.currentTime };
      }
    },
    removeControl: (state: State, action: PayloadAction<number>) => {
      delete state.locatorImages.controls[action.payload];
    },
    setMapWeatherRadarsImagesCoordinates(state, { payload }: PayloadAction<LatLngTuple[]>) {
      state.coordinates.weatherRadarsImages = payload;
    },
    setLocators(state, { payload }: PayloadAction<WeatherLocators.Locator[]>) {
      state.locators = Object.fromEntries(payload.map(locator => [locator.id, locator]));
    },
    changeLocator(state, { payload }: PayloadAction<WeatherLocators.Locator>) {
      state.locators[payload.id] = {
        ...payload,
        id: Number(payload.id),
        maxDate: moment(payload.date).isAfter(state.locators[payload.id].maxDate) ? payload.date : state.locators[payload.id].maxDate,
        times: payload.times && Array.isArray(payload.times) ? payload.times : [],
      };
    },
    changeLocators(state, { payload }: PayloadAction<WeatherLocators.Locator[]>) {
      payload.forEach(newLocator => {
        state.locators[newLocator.id] = {
          ...newLocator,
          id: Number(newLocator.id),
          maxDate: moment(newLocator.date).isAfter(state.locators[newLocator.id].maxDate) ? newLocator.date : state.locators[newLocator.id].maxDate,
          times: newLocator.times && Array.isArray(newLocator.times) ? newLocator.times : [],
        };
      });
    },
    changeMapGisCheckbox(state, { payload }: PayloadAction<ObjectMapState>) {
      state.gis = changeCheckboxState(state.gis, payload);
    },
    setMapStaticObjectsCoordinates(state, { payload }: PayloadAction<LatLngTuple[]>) {
      state.coordinates.staticObjects = payload;
    },
    setActiveMapProfile(state, { payload }: PayloadAction<Profile>) {
      return makeActiveState(state, payload || INITIAL_MAP_PROFILE);
    },
    fetchMapProfilesSuccess(state, { payload }: PayloadAction<Profile[]>) {
      state.profiles = payload;
    },
    mutateMapProfiles(state, { payload }: PayloadAction<Profile[]>) {
      state.profiles = payload;
    },
    changeMapNotesCheckbox(state, { payload }: PayloadAction<any>) {
      state.notes = changeCheckboxState(state.notes, payload);
    },
    setMapNotesCoordinates(state, { payload }: PayloadAction<LatLngTuple[]>) {
      state.coordinates.notes = payload;
    },
    setMapNotesCursor(state, { payload }: PayloadAction<any>) {
      state.mapNotesCursor = payload;
    },
    changeLayer(state, { payload }: PayloadAction<LayerTypes>) {
      state.activeLayer = payload;
    },
    setViewport(state, { payload }: PayloadAction<Viewport>) {
      state.viewport = payload;
    },
    setMarkerZooms(state, { payload }: PayloadAction<ZoomMarker>) {
      state.markerZooms = payload;
    },
    setMapDataLoader(state, { payload }: PayloadAction<{ type: DataLoadingTypes; status: boolean }>) {
      state.loadings = changeLoadingMapState(state.loadings, payload);
    },
    setDataForVMSStationModal(
      state,
      {
        payload,
      }: PayloadAction<{
        data: {
          placeId: number;
          type: VMSTemplatesTypes;
          organizationName: string;
        };
      }>,
    ) {
      state.dataForVMSStationModal = payload.data;
    },
    clearDataForVMSStationModal(state) {
      state.dataForVMSStationModal = { placeId: null, type: null, organizationName: EMPTY_STRING };
    },
    changeControllerValue(state, { payload }: PayloadAction<RoadObjectsTypes>) {
      state.currentRoadObjectController = payload;
    },
    changeAdditionalControllerValue(state, { payload }: PayloadAction<AdditionalRoadObjectActions>) {
      state.additionalRoadOjectControl = payload;
    },
    setIsReloadNoteListing(state, { payload }: PayloadAction<boolean>) {
      state.isReloadNoteListing = payload;
    },
  },
});

export const mapActions = mapSlice.actions;

export default mapSlice.reducer;
