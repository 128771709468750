import React, { ReactNode } from 'react';
import { ConfigurationTypes } from '@app/v2/shared/enums';
import CSDMessageFields from './CSDFields/CSDMessageFields';
import CSDSignField from './CSDFields/CSDSignField';

interface Props {
  templateType: ConfigurationTypes;
  fieldsOptions: Scoreboards.TemplateFieldsOptions;
  signsSelectors: Common.SelectorValues;
}

const CSDTemplateFields = ({ templateType, fieldsOptions, signsSelectors }: Props) => {
  const formMapper: Record<ConfigurationTypes, ReactNode> = {
    [ConfigurationTypes.Txtbox]: <CSDMessageFields {...fieldsOptions} />,
    [ConfigurationTypes.Autobox]: <CSDMessageFields {...fieldsOptions} />,
    [ConfigurationTypes.Sgnlst]: <CSDSignField signsSelectors={signsSelectors} {...fieldsOptions} />,
  };

  return <>{formMapper[templateType]}</>;
};

export default CSDTemplateFields;
