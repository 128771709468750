import React, { useEffect, useRef, useState } from 'react';
import { EMPTY_STRING, GRID_LINE_COLOR, PIXEL_SIZE } from '@app/v2/shared/constants';
import DrawScoreboard from '@app/modules/kit-module/shared/ui/CSDScoreboardsViewer/DrawScoreboard';

const CSDScoreboardsViewerImg = ({
  fullSize,
  fullWidth,
  background,
  isDrawCells,
  scoreboardWidth,
  compressionRatio,
  scoreboardHeight,
  prepareRender,
}: Scoreboards.ViewerProps) => {
  const [imageDataURL, setImageDataURL] = useState<string>(EMPTY_STRING);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const drawScoreboard = new DrawScoreboard({ x: 0, y: 0 });

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    canvas.height = scoreboardHeight * PIXEL_SIZE;
    canvas.width = scoreboardWidth * PIXEL_SIZE;

    drawScoreboard.reset(context, { background });

    canvas.style.height = fullSize ? '100%' : `${canvas.height * compressionRatio}px`;
    canvas.style.width = fullSize ? '100%' : `${canvas.width * compressionRatio}px`;

    Promise.all(prepareRender(context)).then(() => {
      if (isDrawCells) drawScoreboard.drawCells(context, { size: PIXEL_SIZE / compressionRatio, color: GRID_LINE_COLOR });
      setImageDataURL(canvas.toDataURL());
    });
  }, [background, compressionRatio, fullSize, prepareRender, isDrawCells, scoreboardHeight, scoreboardWidth]);

  return (
    <>
      <canvas style={{ display: 'none' }} ref={canvasRef} />
      <img alt="ScoreboardImage" src={imageDataURL} width={fullWidth ? '100%' : 'auto'} height="100%" />
    </>
  );
};

export default CSDScoreboardsViewerImg;
