import { useMemo } from 'react';
import { DEFAULT_SIGN_HEIGHT, DEFAULT_TEMPLATE_HEIGHT, DEFAULT_TEMPLATE_WIDTH } from '@app/v2/shared/constants';
import { ConfigurationTypes } from '@app/v2/shared/enums';

interface Params {
  scoreboardWidth: number;
  scoreboardHeight: number;
  configurationTypes: ConfigurationTypes[];
  defaultCompressionRatio: number;
}

export default function useCompressionRatio({ scoreboardWidth, scoreboardHeight, configurationTypes, defaultCompressionRatio }: Params): number {
  const scoreboardType = configurationTypes[0];

  const { isSign, isLargeTemplate, isSmallTemplate } = useMemo(
    () => ({
      isSign: scoreboardType === ConfigurationTypes.Sgnlst && scoreboardHeight >= DEFAULT_SIGN_HEIGHT && configurationTypes.length === 1,
      isLargeTemplate: scoreboardWidth > DEFAULT_TEMPLATE_WIDTH && scoreboardHeight > DEFAULT_TEMPLATE_HEIGHT,
      isSmallTemplate: scoreboardWidth < DEFAULT_TEMPLATE_WIDTH && scoreboardHeight < DEFAULT_TEMPLATE_HEIGHT,
    }),
    [configurationTypes.length, scoreboardHeight, scoreboardType, scoreboardWidth],
  );

  if (isSign) return defaultCompressionRatio * (DEFAULT_SIGN_HEIGHT / scoreboardWidth);
  if (isLargeTemplate && !isSign) return defaultCompressionRatio * (DEFAULT_TEMPLATE_WIDTH / scoreboardWidth);
  if (isSmallTemplate && !isSign) return Math.floor(scoreboardWidth / defaultCompressionRatio / DEFAULT_TEMPLATE_WIDTH);

  return defaultCompressionRatio;
}
