import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { FiltersKeys } from '@app/v2/shared/enums';
import { useFiltersDictionary, useFilterStationType, useNewFilters, useNewUserSetsManager } from '@app/v2/shared/hooks';
import { AllFilters, FiltersInitValues } from '@app/v2/shared/constants';
import FilterContext from './FilterContext';
import useAppSelector from '../../hooks/reactRedux/useAppSelector';

const FilterContextProvider = ({ children }: PropsWithChildren) => {
  const { addFilters, setFiltersToURL, setFilters: setFiltersToRedux } = useNewFilters();
  const { fetchUserSets } = useNewUserSetsManager();

  const [filters, setFiltersContext] = useState<Filters.Filter>({});

  const filtersRedux = useAppSelector(state => state.newFilters.filters);

  const initializeFilter = useCallback(() => {
    const hasReduxFilters = Object.keys(filtersRedux).length;

    if (!hasReduxFilters) addFilters(FiltersInitValues);
  }, [filtersRedux, addFilters]);

  const fetchFilterUserSets = useCallback(() => {
    fetchUserSets().then(({ nextFiltersValues, isActive }) => {
      if (!isActive) {
        initializeFilter();
        return;
      }

      setFiltersToURL(nextFiltersValues);
      setFiltersToRedux(nextFiltersValues);
    });
  }, [initializeFilter, setFiltersToURL, setFiltersToRedux, fetchUserSets]);

  useEffect(() => {
    fetchFilterUserSets();
  }, [fetchFilterUserSets]);

  const { stationType } = useFilterStationType();
  const { debounceFetchDictionary } = useFiltersDictionary();

  const setFilter = useCallback(<Key extends FiltersKeys>(key: Key, value: Partial<Filters.FiltersValues[Key]>['value']) => {
    setFiltersContext(prev => ({
      ...prev,
      [key]: {
        ...prev[key],
        value,
      },
    }));
  }, []);

  const setFilters = useCallback((newFilters: Filters.Update) => {
    setFiltersContext(prev => ({
      ...prev,
      ...Object.entries(newFilters).reduce<Filters.Filter>((acc, [filterName, value]) => {
        acc[filterName] = {
          ...prev[filterName],
          value,
        };

        return acc;
      }, {}),
    }));
  }, []);

  const syncFilters = useCallback((filtersValue: Filters.Filter) => {
    setFiltersContext(prev => {
      if (isEqual(prev, filtersValue)) return prev;

      return filtersValue;
    });
  }, []);

  const filtersValues = useMemo(
    () =>
      Object.entries(filters).reduce<Filters.Values>((acc, [filterName, filter]) => {
        acc[filterName] = filter?.value ?? AllFilters[filterName].value;
        return acc;
      }, {}),
    [filters],
  );

  useEffect(() => {
    syncFilters(filtersRedux);
  }, [filtersRedux, syncFilters]);

  useEffect(() => {
    const { organizations, places, roads } = filtersValues;
    debounceFetchDictionary({ organizations, places, roads }, stationType);
  }, [debounceFetchDictionary, filtersValues, stationType]);

  return (
    <FilterContext.Provider
      value={{
        filters,
        setFilter,
        setFilters,
        syncFilters,
        filtersValues,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterContextProvider;
