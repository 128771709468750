import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Icon, IconButton, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import icons from '@app/assets/iconFont';
import { FULL_WIDTH } from '@app/v2/shared/constants';
import { getBackgroundColorForListing } from '@app/v2/shared/helpers';
import NoteImagesViewer from '@app/components/notes/NoteImagesViewer';

const NodeInfoDialog = (props: Notes.NodeInfoDialogProps) => {
  const {
    title,
    address,
    roadAddress,
    isAddress,
    date,
    time,
    photo,
    organization,
    user,
    addressIcon,
    roadIcon,
    dateIcon,
    timeIcon,
    visibilityBox,
    UpdateNoteButtonComponent,
    updateNoteButtonProps,
    message,
    close,
  } = props;

  const { t } = useTranslation('map', { keyPrefix: 'notes' });
  const classes = useStyles();

  return (
    <Dialog open onClose={close} aria-labelledby="read-more-dialog-title" fullWidth maxWidth="xs" data-testid="node-info-dialog">
      <DialogTitle mb={2} id="read-more-dialog-title" className={classes.titleStyle}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={10.4}>
            {title}
          </Grid>
          <Grid item xs={1.6}>
            {UpdateNoteButtonComponent && <UpdateNoteButtonComponent {...updateNoteButtonProps} />}
            <IconButton aria-label="close" onClick={() => close()}>
              <Icon color="action" sx={{ '&:hover': { color: '#3245af' } }}>
                {icons.close}
              </Icon>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ overflow: 'hidden' }}>
        <Grid container>
          <Grid item xs={12} pb={2}>
            <Typography className={classes.messageText}>{message}</Typography>
          </Grid>
          {!isAddress ? (
            <Grid item xs={12} pb={2} className={classes.iconWithText}>
              <Icon className={classes.icon}>{roadIcon}</Icon>
              <Typography className={classes.blueText}>{roadAddress}</Typography>
            </Grid>
          ) : (
            <Grid item xs={12} pb={2} className={classes.iconWithText}>
              <Icon className={classes.icon}>{addressIcon}</Icon>
              <Typography className={classes.blueText}>{address}</Typography>
            </Grid>
          )}
          <Grid item pb={2} className={classes.iconWithText}>
            <Icon className={classes.icon}>{dateIcon}</Icon>
            <Typography>{date}</Typography>
          </Grid>
          <Grid item pb={2} pl={1} className={classes.iconWithText}>
            <Icon className={classes.icon}>{timeIcon}</Icon>
            <Typography>{time}</Typography>
          </Grid>
          <Grid item xs={12} pb={2}>
            <NoteImagesViewer images={photo} isWrapping />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.greyText}>{user}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.greyText}>{organization}</Typography>
          </Grid>
          <Grid container pt={2}>
            <Tooltip title={`${t('form.noteVisibility')}: ${t(`form.visibilitySelect.${visibilityBox}` as any)}`} placement="top">
              <Box width={FULL_WIDTH} sx={{ bgcolor: getBackgroundColorForListing(visibilityBox) }} className={classes.visibilityBox} />
            </Tooltip>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  icon: {
    '&&': {
      verticalAlign: 'text-top',
      marginRight: '0.1rem',
      color: theme.palette?.primary.main,
      fontSize: '1rem',
    },
  },
  visibilityBox: {
    '&&': {
      paddingTop: '0.5rem',
      borderRadius: '0.375rem',
    },
  },

  messageText: {
    '&&': {
      overflowWrap: 'break-word',
      lineHeight: '1.3rem',
      color: '#0C0C0E',
    },
  },
  greyText: {
    '&&': {
      color: theme.palette?.grey[600],
    },
  },
  blueText: {
    '&&': {
      color: theme.palette?.info.main,
    },
  },
  iconWithText: {
    '&&': {
      display: 'flex',
      alignItems: 'top',
    },
  },
  titleStyle: {
    '&&': {
      padding: 0,
      lineHeight: '1.5rem',
    },
  },
}));

export default NodeInfoDialog;
