import React from 'react';
import { Box } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

interface Props<Options extends Record<string, unknown>> {
  options: Options;
  className?: string;
  classes?: Record<string, unknown>;
}

const CSDTrafficChart = <Options extends Record<string, unknown>>({ options, classes, className }: Props<Options>) => {
  const isShowChartData = Array.isArray(options?.series) && options?.series?.length;

  return (
    <Box
      className={className}
      sx={{
        height: '100%',
        width: '100%',
        position: 'relative',
        ...classes,
      }}
    >
      {isShowChartData && (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          immutable
          containerProps={{
            style: {
              height: '100%',
            },
          }}
        />
      )}
    </Box>
  );
};

export default CSDTrafficChart;
