import React, { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Popover, Stack, StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMeteoChartProfiles, usePopover } from '@app/v2/shared/hooks';
import icons from '@app/assets/iconFont';
import { getScrollStyles } from '@theme/themeSettings/componentSettings';
import { CSDAlert } from '@app/modules/kit-module/shared/ui';
import CSDConstructorTitle from './CSDConstructorTitle';
import CSDConstructorSetup from './CSDConstructorSetup';
import ConstructorActions from './CSDConstructorActions';
import ConstructorMode from '../../enums';
import useMeteoChartConstructor from '../../hooks/useMeteoChartConstructor';
import useMeteoChartProfileActions from '../../hooks/useMeteoChartProfileActions';

interface Props<AnchorType> {
  profileId?: string;
  chartId?: string;
  disabled?: boolean;
  isProfilesExist?: boolean;
  chartsSetup?: Meteo.ChartsSetup;
  AnchorNode: FC<{
    isOpen: boolean;
    disabled?: boolean;
    onClick: (event: MouseEvent<AnchorType>) => void;
    isProfilesExist: boolean;
  }>;
}

const CSDConstructorPopover = <AnchorType extends HTMLElement>({
  AnchorNode,
  profileId,
  chartId,
  chartsSetup,
  isProfilesExist,
  disabled,
}: Props<AnchorType>) => {
  const [isValid, setIsValid] = useState<{ input: boolean; setup: boolean }>({ input: true, setup: true });

  const { t } = useTranslation('charts', { keyPrefix: 'meteoConstructor' });
  const { isOpen, openPopover, closePopover, anchorEl } = usePopover();

  const { handleGetMeteoChartById } = useMeteoChartProfiles();

  const {
    currentSetup,
    handleUpdateCurrentSetup,
    handleChangeCurrentSetupTitle,
    handleChangeCurrentSetupParameter,
    handleChangeCurrentSetupParameters,
    handleClearCurrentSetup,
  } = useMeteoChartConstructor();

  const { handleCreateChart, handleUpdateChart } = useMeteoChartProfileActions();

  useEffect(() => {
    if (!profileId || !chartId) return;

    const result = handleGetMeteoChartById(chartId);

    if (!result) return;

    handleUpdateCurrentSetup({
      ...result,
      parameters: Object.keys(result.parameters),
    });
  }, [handleUpdateCurrentSetup, profileId, chartId, isOpen, handleGetMeteoChartById]);

  const currentConstructorMode = !chartId ? ConstructorMode.Create : ConstructorMode.Update;

  const submitHandlersMapper: Record<ConstructorMode, Common.VoidCallBack> = {
    [ConstructorMode.Create]: () => handleCreateChart(profileId, currentSetup),
    [ConstructorMode.Update]: () => handleUpdateChart(profileId, currentSetup),
  };

  const onSubmit = useCallback(
    (submitHandler: Common.VoidCallBack) => {
      if (!currentSetup.name.length || !currentSetup.parameters.length) {
        setIsValid({
          setup: !!currentSetup.parameters.length,
          input: !!currentSetup.name.length,
        });
      } else {
        setIsValid({ setup: true, input: true });

        closePopover();
        handleClearCurrentSetup();
        submitHandler();
      }
    },
    [closePopover, currentSetup.name.length, currentSetup.parameters.length, handleClearCurrentSetup],
  );

  return (
    <>
      <AnchorNode isOpen={isOpen} disabled={disabled} onClick={openPopover} isProfilesExist={isProfilesExist} />

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => {
          closePopover();
          handleClearCurrentSetup();
          setIsValid({ setup: true, input: true });
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          paper: { style: { backgroundColor: 'white' } },
        }}
        marginThreshold={0}
      >
        <Wrapper>
          <StyledStack overflow="hidden" maxHeight="50rem">
            <CSDConstructorTitle value={currentSetup.name} onChange={handleChangeCurrentSetupTitle} setIsValid={setIsValid} isValid={isValid} />

            <StyledStack overflow="auto">
              {!isValid.setup && (
                <CSDAlert severity="error" sx={{ width: '18.75rem' }}>
                  {t('requiredSetup')}
                </CSDAlert>
              )}

              <CSDConstructorSetup
                setIsValid={setIsValid}
                setup={chartsSetup}
                meteoChartSetupParameters={currentSetup?.parameters}
                onChangeOne={handleChangeCurrentSetupParameter}
                onChangeGroup={handleChangeCurrentSetupParameters}
                elementsShouldNotBeInSetup={['windDirection']}
              />
            </StyledStack>

            <ConstructorActions
              actions={[
                {
                  contentIcon: icons.done,
                  action: () => onSubmit(submitHandlersMapper[currentConstructorMode]),
                  variant: 'contained',
                },
                {
                  contentIcon: icons.close,
                  action: closePopover,
                  variant: 'outlined',
                },
              ]}
            />
          </StyledStack>
        </Wrapper>
      </Popover>
    </>
  );
};

export default CSDConstructorPopover;

const Wrapper = styled(Box)<BoxProps>({
  minWidth: '168px',
  padding: '1.5rem',
});

const StyledStack = styled(Stack)<StackProps>(
  ({
    theme: {
      palette: { text, mode },
    },
  }) => ({
    flexDirection: 'column',
    gap: '1rem',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    ...getScrollStyles(mode, text),
  }),
);
