import { Settings } from '@app/core/types/profiles/map';
import { DEFAULT_MARKER_ZOOMS } from '@app/v2/shared/constants';
import ZoomMarker from '@app/modules/map-module/interfaces/ZoomMarker';
import { isNumber } from '@app/v2/shared/helpers';

const isZoomMarkerType = (data: Settings): data is ZoomMarker => typeof data === 'object' && 'firstLimit' in data && 'secondLimit' in data;

const getMarkerZooms = (data: Settings) => {
  if (!isZoomMarkerType(data) || !isNumber(data.secondLimit) || !isNumber(data.firstLimit)) {
    return DEFAULT_MARKER_ZOOMS;
  }

  return data;
};

export default getMarkerZooms;
