import React, { memo, useCallback, useMemo } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Stack, Icon, Typography, Box, Theme } from '@mui/material';
import groupBy from 'lodash/groupBy';
import { makeStyles } from '@mui/styles';
import { CSDSwitcher } from '@app/modules/kit-module/shared/ui';
import settings from '@app/core/constants/subscriptionParameters';
import type { AlertGroupLabel, AlertGroups, Notification, Rank } from '@app/v2/features/notifications/types';
import { colorIndicators } from '@app/core/constants/notification';
import icons from '@app/assets/iconFont';
import { getScrollStyles } from '@theme/themeSettings/componentSettings';

type Props = {
  data: Notification[];
  disabled?: boolean;
  changeCheckbox: (args: { key: keyof Notification; keyValue: Notification[keyof Notification]; checked: boolean }) => void;
} & WithTranslation<'notification'>;

const SubscriptionsCheckbox = ({ data, disabled = false, changeCheckbox, t }: Props) => {
  const classes = useStyles();

  const alertGroups = useMemo<AlertGroups>(() => groupBy(data, alert => alert.type), [data]);

  const createLabel = (alias: string, rank: Rank): JSX.Element => (
    <Box className={classes.itemLabel}>
      <Icon sx={{ color: `${colorIndicators[rank]} !important`, marginRight: '0.5rem' }}>{icons.dot}</Icon>
      <Typography fontSize="0.75rem">{t(settings[alias])}</Typography>
    </Box>
  );

  const isCheckedGroup = useCallback(
    (typeGroup: AlertGroupLabel): boolean => {
      return alertGroups[typeGroup].some(alert => alert.checked);
    },
    [alertGroups],
  );

  return (
    <Box className={classes.container}>
      <Stack direction="column" spacing={1.5}>
        {Object.keys(alertGroups).map(groupKey => (
          <Stack key={groupKey} direction="column" spacing={1}>
            <CSDSwitcher
              disabled={disabled}
              label={t(settings[groupKey])}
              checked={isCheckedGroup(groupKey)}
              onChange={e => changeCheckbox({ key: 'type', checked: e.target.checked, keyValue: groupKey })}
            />
            <Stack direction="column" sx={{ paddingLeft: '2px' }}>
              {alertGroups[groupKey].map(({ id, checked, alias, rank }) => (
                <CSDSwitcher
                  key={id}
                  disabled={disabled}
                  label={createLabel(alias, rank)}
                  checked={checked}
                  size="small"
                  onChange={e => changeCheckbox({ checked: e.target.checked, key: 'id', keyValue: id })}
                />
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

const useStyles = makeStyles<Theme>(({ palette: { text, mode } }) => ({
  container: {
    height: '100%',
    overflow: 'auto',
    overflowX: 'hidden',
    minWidth: '25rem',
    ...getScrollStyles(mode, text),
  },
  itemLabel: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default withTranslation('notification')(memo(SubscriptionsCheckbox));
