import React, { useCallback, useEffect, useMemo, memo } from 'react';
import { Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import { CSDDeleteMeteoChartBtn, CSDResizeMeteoChartBtn, CSDUpdateMeteoChartBtn } from '@app/v2/features';
import { CSDMeteoChart } from '@app/modules/kit-module/shared/ui';
import { useDialog, useMeteoChart } from '@app/v2/shared/hooks';
import { MeteoChartContainerWidth, MeteoChartViewTypes } from '@app/v2/shared/enums';
import { strictlyEqual } from '@app/v2/shared/helpers';
import { CSDSnapshotDialog } from '@app/v2/shared/ui/custom';
import useMeteoChartOptions from './useMeteoChartOptions';
import type { MeteoChartConnectorProps, PreparedContentAndDates } from './types';

const CSDMeteoChartConnector = ({
  activeProfileId,
  dataForChart,
  meteoChartSetupId,
  stationId,
  meteoChartSetup,
  isActiveProfileCustom,
  roadTitle,
}: MeteoChartConnectorProps) => {
  const openSnapshotDialog = useDialog(CSDSnapshotDialog);

  const { chartsData, viewMode, chartsSetup, handleSetMeteoChartParameters, handleChangeIsFixed, handleSetAmountsOfPointsMeteoChart } =
    useMeteoChart();

  const classes = useStyles({ containerWidth: meteoChartSetup.containerWidth });

  const { preparedContent, preparedDates, mainChartDataLength } = useMemo<PreparedContentAndDates>(() => {
    const { content, datePrognosisType, dates } = dataForChart;

    const setUpKeys = chartsSetup.map(({ params }) => params.map(({ label }) => label)).flat();

    const sortedContent = setUpKeys.reduce<Partial<Meteo.ChartContent>>((acc, key) => {
      acc[key] = content[key];
      return acc;
    }, {});

    const filterAccordingToSettings = ([id, _]: [string, number[]]): boolean => meteoChartSetup.parameters[id];

    const filteredContent = Object.entries(sortedContent).filter(filterAccordingToSettings);

    if (strictlyEqual(viewMode, MeteoChartViewTypes.Forecast)) {
      const firstForecastDate = (datePrognosisType?.roadcast[0] || datePrognosisType?.forecast[0]) ?? null;
      const firstForecastDateIndex = dates.findIndex(date => strictlyEqual(date, firstForecastDate));
      const removeHistoryFromDate = ([key, value]: [string, number[]]): [string, number[]] => [key, value.slice(firstForecastDateIndex)];

      return {
        preparedContent: Object.fromEntries(filteredContent.map(removeHistoryFromDate)),
        preparedDates: dates.slice(firstForecastDateIndex),
        mainChartDataLength: 0,
      };
    }

    return {
      preparedContent: Object.fromEntries(filteredContent),
      preparedDates: dates,
      mainChartDataLength: dates.length - (datePrognosisType.forecast.length + datePrognosisType.roadcast.length),
    };
  }, [chartsSetup, dataForChart, meteoChartSetup.parameters, viewMode]);

  const fixTooltipPosition = useCallback(
    ({ date, coords, index, currentPointData }: Meteo.ChartPointData) => {
      handleSetMeteoChartParameters({
        date,
        coords,
        chartId: meteoChartSetupId,
        isFixed: true,
        isFixedOnForecast: index < mainChartDataLength,
        currentValueIndex: index,
        data: Object.entries(preparedContent).map(([key, value]) => ({
          key,
          ...currentPointData[key],
          value: value[index],
        })),
        dialogData: {
          meteoStationId: stationId,
          kilometer: dataForChart.place.address,
          meter: dataForChart.place.meters,
          title: roadTitle,
          dateTime: preparedDates[index],
        },
      });
    },
    [roadTitle, dataForChart, handleSetMeteoChartParameters, meteoChartSetupId, preparedContent, preparedDates, stationId, mainChartDataLength],
  );

  const { options } = useMeteoChartOptions({
    content: preparedContent,
    title: meteoChartSetup.name,
    contentKeys: Object.keys(preparedContent),
    datePrognosisType: dataForChart.datePrognosisType,
    dates: preparedDates,
    fixTooltipPosition,
    isFixed: chartsData[meteoChartSetupId]?.isFixed ?? true,
    currentIndex: chartsData[meteoChartSetupId]?.currentValueIndex,
    chartsSetup,
    setAmountsOfPoints: handleSetAmountsOfPointsMeteoChart,
  });

  useEffect(() => () => handleChangeIsFixed({ chartId: meteoChartSetupId, isFixed: false }), [handleChangeIsFixed, meteoChartSetupId]);

  return (
    <Stack sx={{ width: meteoChartSetup.containerWidth ?? MeteoChartContainerWidth.Full }} className={classes.wrapper}>
      {isActiveProfileCustom && (
        <Stack direction="row" className={classes.actions}>
          <CSDUpdateMeteoChartBtn profileId={activeProfileId} chartId={meteoChartSetupId} chartsSetup={chartsSetup} />
          <CSDDeleteMeteoChartBtn profileId={activeProfileId} setupId={meteoChartSetupId} />
          <CSDResizeMeteoChartBtn
            profileId={activeProfileId}
            setupId={meteoChartSetupId}
            currentValue={meteoChartSetup.containerWidth ?? MeteoChartContainerWidth.Full}
          />
        </Stack>
      )}

      <CSDMeteoChart
        options={{ ...options }}
        className={classes.chart}
        chartsData={chartsData[meteoChartSetupId]}
        onCloseTooltip={() => handleChangeIsFixed({ chartId: meteoChartSetupId, isFixed: false })}
        openDialog={() => openSnapshotDialog({ ...chartsData[meteoChartSetupId].dialogData, title: roadTitle })}
      />
    </Stack>
  );
};

const useStyles = makeStyles<Theme, { containerWidth: MeteoChartContainerWidth }>({
  wrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    height: 'auto',
  },
  actions: {
    gap: '1rem',
    position: 'absolute',
    zIndex: 1,
    top: '1rem',
    right: '2rem',
  },
  chart: {
    borderRadius: '0.5rem',
    border: '2px solid black',
    height: 425,
    padding: '1rem 1.5rem',
  },
});

export default memo(CSDMeteoChartConnector);
