import { useEffect, useCallback } from 'react';
import DataLoadingTypes from '@app/modules/map-module/enums/DataLoadingTypes';
import useMapDataLoading from '@app/core/hooks/useMapDataLoading';
import { useActions } from '@app/v2/shared/hooks';
import fetch from '@app/modules/map-module/data/LocatorFetcher';

const useLoadingLocators = () => {
  const { setLocators } = useActions();
  const [, setDataMapLoading] = useMapDataLoading();

  const prepareLocators = (data: WeatherLocators.Locator[]) =>
    data.map(locator => ({
      ...locator,
      maxDate: locator.date,
      id: Number(locator.id),
    }));

  const initializeLocators = useCallback(async (): Promise<void> => {
    setDataMapLoading(DataLoadingTypes.WEATHER_RADARS, true);
    const { locators: fetchedLocators } = await fetch();
    if (fetchedLocators && fetchedLocators.length) {
      setLocators(prepareLocators(fetchedLocators));
    }
    setDataMapLoading(DataLoadingTypes.WEATHER_RADARS, false);
  }, [setLocators, setDataMapLoading]);

  useEffect(() => {
    initializeLocators();
  }, [initializeLocators]);
};
export default useLoadingLocators;
