import * as React from 'react';
import { ReactNode } from 'react';
import { Table, TableProps } from '@mui/material';
import { TableHighlightMode } from '@app/v2/shared/enums';

interface TableHighlightContextProps {
  row: number | null;
  column: number | null;
  setHighlightedCell: (data: Omit<TableHighlightContextProps, 'setHighlightedCell'>) => void;
}

export const UNHIGHLIGHTED_ROW_INDEX = -1;
export const UNHIGHLIGHTED_COLUMN_INDEX = -1;

export const TableHighlightContext = React.createContext<TableHighlightContextProps>({
  row: null,
  column: null,
  setHighlightedCell: () => {},
});

const CSDTable = (
  { children, highlightMode = TableHighlightMode.WithHighlight, ...tableProps }: TableProps & { highlightMode?: TableHighlightMode },
  ref,
) => {
  const [highlightedCell, setHighlightedCell] = React.useState<Pick<TableHighlightContextProps, 'row' | 'column'>>({
    column: null,
    row: null,
  });

  const render: Record<TableHighlightMode, ReactNode> = {
    [TableHighlightMode.WithHighlight]: (
      <TableHighlightContext.Provider value={{ ...highlightedCell, setHighlightedCell }}>
        <Table ref={ref} sx={{ minWidth: 700 }} size="small" aria-label="csd-table" {...tableProps}>
          {children}
        </Table>
      </TableHighlightContext.Provider>
    ),
    [TableHighlightMode.WithoutHighlight]: (
      <Table ref={ref} sx={{ minWidth: 700 }} size="small" aria-label="csd-table" {...tableProps}>
        {children}
      </Table>
    ),
  };

  return <>{render[highlightMode]}</>;
};

export default React.forwardRef(CSDTable);
