import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import preparePlaces from './preparePlaces';
import prepareAlerts from './prepareAlerts';
import {
  prepareYAxisForPlanChart,
  prepareXAxisForPlanChart,
  prepareTooltipForPlanChart,
  prepareChartForPlanChart,
  prepareSeriesForPlanChart,
} from '../../common/fnForPrepareCharts/planChart';
import PlanChartHeaderId from '../../common/enums';
import type { RoadPlan } from '../types';

export default function useRoadPlanChartOptions({ alerts, places }: RoadPlan, chartHeight: number): Highcharts.Options {
  const { t } = useTranslation('common', { keyPrefix: 'units' });
  const { t: chartsT } = useTranslation('charts');

  const placesForChart = useMemo(() => preparePlaces(places, alerts, t, chartsT), [alerts, places, t, chartsT]);
  const alertsForChart = useMemo(() => prepareAlerts(alerts, chartsT), [alerts, chartsT]);

  return {
    chart: prepareChartForPlanChart(places.length, chartHeight, PlanChartHeaderId.ROAD),
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    subtitle: {
      text: null,
    },
    time: {
      timezoneOffset: new Date().getTimezoneOffset(),
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    tooltip: prepareTooltipForPlanChart(),
    yAxis: prepareYAxisForPlanChart(),
    xAxis: prepareXAxisForPlanChart(),
    series: prepareSeriesForPlanChart(placesForChart, alertsForChart),
  };
}
