import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, IconButton, Dialog, DialogTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Formik } from 'formik';
import { DialogRef } from '@app/core/providers/DialogProvider';
import Form from '@app/components/dialogs/create-profile-dialog/Form';
import validationSchema from '@app/components/dialogs/create-profile-dialog/formValidation';
import getRandomInteger from '@app/core/helpers/getRandomInteger';
import { ProfilesTypes } from '@app/v2/shared/enums';
import { getDefaultProfileSettings } from '@app/v2/shared/helpers';

type Props = {
  type: ProfilesTypes;
} & DialogRef<Profiles.CommonProfile>;

const CreateProfileDialog = ({ type, close }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('dialogs', { keyPrefix: 'createProfile' });
  const handleSubmit = useCallback(
    (value: { label: string; description: string }) => {
      const newProfile = {
        id: -getRandomInteger(),
        label: value.label,
        description: value.description || t('withoutDescription'),
        isDefault: false,
        items: getDefaultProfileSettings(type),
      };
      close(newProfile);
    },
    [t, type, close],
  );

  return (
    <Dialog open maxWidth="sm" fullWidth onClose={() => close(null)}>
      <DialogTitle className={classes.title}>
        <Typography variant="h6">{t('title')}</Typography>
        <IconButton onClick={() => close(null)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Formik
        initialValues={{
          label: '',
          description: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {attrs => <Form {...attrs} onClose={() => close(null)} />}
      </Formik>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default CreateProfileDialog;
