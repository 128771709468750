import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Box } from '@mui/material';
import { CSDCheckbox } from '@app/modules/kit-module/shared/ui';
import { FiltersKeys } from '@app/v2/shared/enums';
import FilterDates from '../dates/FiltersDates';
import CSDFilterTypeOfWarning from '../alertStatus/CSDFilterTypeOfWarning';
import CSDReactionFilter from '../reaction/CSDReactionFilter';

const AdditionalNotificationFilter = (props: Filters.AdditionalFiltersProps) => {
  const { t } = useTranslation('notification');

  const {
    filters: { isRoadcast },
    onChange,
  } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}>
      <FilterDates {...props} />

      <Divider orientation="vertical" flexItem />

      <CSDFilterTypeOfWarning {...props} />

      <Divider orientation="vertical" flexItem />

      <CSDReactionFilter {...props} />

      <CSDCheckbox
        size="small"
        checked={!!isRoadcast}
        label={t('filter.isRoadcast')}
        onChange={(_, checked) => onChange(FiltersKeys.IsRoadcast, checked)}
      />
    </Box>
  );
};

export default AdditionalNotificationFilter;
