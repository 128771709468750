import { useEffect, useState } from 'react';

export default function useCarouselSize() {
  const [size, setSize] = useState<{ width: number }>({ width: window.innerWidth });
  useEffect(() => {
    const handleResize = () => {
      setSize({ width: window.innerWidth });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return size;
}
