type StorageActions = {
  getItem: (key: string) => string | null | undefined;
  setItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
  removeItems: (keys: string[]) => void;
  clear: () => void;
};

export default function useLocalStorage(): StorageActions {
  const getItem = (key: string): string | null | undefined => {
    const item = localStorage.getItem(key);

    if (item === 'null') return null;
    if (item === 'undefined') return undefined;

    return item;
  };

  const setItem = (key: string, value: string): void => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const removeItem = (key: string): void => {
    localStorage.removeItem(key);
  };

  const removeItems = (keys: string[]): void => {
    keys.forEach(key => localStorage.removeItem(key));
  };

  const clear = (): void => {
    localStorage.clear();
  };

  return { getItem, setItem, removeItem, removeItems, clear };
}
