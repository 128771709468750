import { MouseEvent, useCallback, useRef, useState } from 'react';

export default function usePopoverM<AnchorType extends HTMLElement>(): {
  anchorEl: AnchorType;
  isOpen: boolean;
  openPopover: (event: MouseEvent<AnchorType>) => void;
  closePopover: () => void;
} {
  const [anchorEl, setAnchorEl] = useState<AnchorType | null>(null);

  const open = useRef<boolean>(false);

  const openPopover = useCallback((event: MouseEvent<AnchorType>) => {
    open.current = true;
    setAnchorEl(event.currentTarget);
  }, []);

  const closePopover = useCallback(() => {
    open.current = false;
    setAnchorEl(null);
  }, []);

  return { anchorEl, isOpen: open.current, openPopover, closePopover };
}
