import React, { useEffect, memo } from 'react';
import { withTranslation } from 'react-i18next';
import { Moment } from 'moment';
import { Stack, Divider, Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LocatorControlService from '@app/modules/map-module/services/LocatorControlService';
import useSubscribeLocatorControl from '@app/modules/map-module/hooks/useSubscribeLocatorControl';
import { PlayerStatus, PlayerTimeRangeActions } from '@app/v2/shared/enums';
import LocatorPlayer from './LocatorPlayer';
import DateTimeActionButtons from './DateTimeActionButtons';
import DateTimeControl from './DateTimeControl';

type Props = {
  handleChangeDate: (newDate: Moment | string) => void;
  handleChangeTimesRange: (rangeType: PlayerTimeRangeActions) => void;
  locatorControlService: LocatorControlService;
} & I18N.TranslationFunction<'common', 'notificationMessages'>;

const WeatherLocatorsControl = ({ locatorControlService, handleChangeTimesRange, handleChangeDate, t }: Props) => {
  const classes = useStyles();

  const { playerState, control, currentTime } = useSubscribeLocatorControl(locatorControlService);

  useEffect(() => {
    locatorControlService.init();
  }, [locatorControlService]);

  return (
    <Stack direction="row" spacing={1} className={classes.weatherLocatorContainer}>
      <Stack spacing={1} className={classes.timeDateContainer}>
        <LocatorPlayer locatorControlService={locatorControlService} control={control} playerState={playerState} currentTime={currentTime} />
        <Tooltip title={playerState === PlayerStatus.PLAY ? t('changeLocatorTimes') : ''}>
          <span>
            <DateTimeControl
              control={control}
              playerState={playerState}
              handleChangeDate={handleChangeDate}
              locatorControlService={locatorControlService}
            />
          </span>
        </Tooltip>
      </Stack>
      <Divider className={classes.divider} orientation="vertical" variant="middle" flexItem />
      <DateTimeActionButtons rangeType={control.rangeType} handleChangeTimesRange={handleChangeTimesRange} />
    </Stack>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  weatherLocatorContainer: {
    width: '26.125rem',
    padding: '0.5rem',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '0.625rem',
    background: theme.palette.background.default,
  },
  timeDateContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    '& .ant-picker-input': {
      '& > input': {
        fontSize: '0.875rem',
      },
    },
  },
  divider: {
    background: theme.palette.primary.main,
  },
}));

export default withTranslation('common', { keyPrefix: 'notificationMessages' })(memo(WeatherLocatorsControl));
