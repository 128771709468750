import FETCH_ALERT_INDEXES from './fetchAlertIndexes.graphql';
import FETCH_METEO_GRAPH from './fetchMeteoGraph.graphql';
import FETCH_METEO_LINEAR_GRAPH from './fetchMeteoLinearGraph.graphql';
import FETCH_METEO_MANAGEMENT from './fetchMeteoManagement.graphql';
import FETCH_METEO_PLAN_GRAPH from './fetchMeteoPlanGraph.graphql';
import FETCH_METEO_ROAD_PLAN_GRAPH from './fetchMeteoRoadPlanGraph.graphql';
import FETCH_METEO_STATION_PLAN_GRAPH_EXIST from './fetchMeteoStationPlanGraphExist.graphql';
import FETCH_METEO_STATIONS_PLACE_AND_ROAD_DATA from './fetchMeteoStationsPlaceAndRoadData.graphql';
import FETCH_METEO_GRAPH_SETTINGS from './fetchMeteoGraphSettings.graphql';
import FETCH_METEO_GRAPH_SETTINGS_GROUP from './fetchMeteoGraphSettingsGroup.graphql';

const meteoQueries = {
  alertIndexes: FETCH_ALERT_INDEXES,
  meteoGraph: FETCH_METEO_GRAPH,
  meteoLinearGraph: FETCH_METEO_LINEAR_GRAPH,
  meteoManagement: FETCH_METEO_MANAGEMENT,
  meteoPlanGraph: FETCH_METEO_PLAN_GRAPH,
  meteoRoadPlanGraph: FETCH_METEO_ROAD_PLAN_GRAPH,
  meteoStationPlanGraphExist: FETCH_METEO_STATION_PLAN_GRAPH_EXIST,
  meteoStationsPlaceAndRoadData: FETCH_METEO_STATIONS_PLACE_AND_ROAD_DATA,
  meteoGraphSettings: FETCH_METEO_GRAPH_SETTINGS,
  meteoGraphSettingsGroup: FETCH_METEO_GRAPH_SETTINGS_GROUP,
};

export default meteoQueries;
