/** Status codes */

enum StatusCodes {
  SUCCESSFUL_200 = 200,
  CREATE_SUCCESSFUL_201 = 201,
  UPDATE_SUCCESSFUL_204 = 204,
  UNAUTHORIZED_401 = 401,
  NOT_FOUND = 404,
  TOO_MANY_REQUEST_429 = 429,
  INTERNAL_SERVER_ERROR_500 = 500,
}

export default StatusCodes;
