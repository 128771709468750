import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Icon, IconButton, Stack, StackProps } from '@mui/material';
import clsx from 'clsx';
import icons from '@app/assets/iconFont';
import { linearGradient } from '@app/core/theme/styles';
import { VideoWallSettings, WaterLevelControlSettings } from '@store/slices/account/types';
import { MODE } from '@app/modules/video-wall-module/constants/constants';
import { VIDEO_WALL_MAX_COLS, VIDEO_WALL_MAX_ROWS } from '@app/v2/shared/constants';
import { ArrowButtonType, SettingsProperty } from '../constants';

type Props = {
  settings: VideoWallSettings | WaterLevelControlSettings;
  onChangeRowsColumns: (type: ArrowButtonType, property: SettingsProperty) => () => void;
  onChangeMode: (mode: string) => () => void;
};

const CSDGridController = ({ settings, onChangeRowsColumns, onChangeMode }: Props) => (
  <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between" data-testid="rows-columns-setting-block">
    <StyledStack
      direction="row"
      alignItems="center"
      className={clsx({ active: settings.mode === MODE.rows }, { disabled: settings.mode === MODE.cols })}
      onClick={onChangeMode(MODE.rows)}
      data-testid="rows-setting-block"
    >
      <Icon sx={{ marginRight: '0.3rem' }}>{icons.rows}</Icon>
      <IconButton disabled={settings.rows === 1} onClick={onChangeRowsColumns(ArrowButtonType.DECREASE, SettingsProperty.ROWS)}>
        <Icon>{icons.left}</Icon>
      </IconButton>
      <Typography>{settings.rows}</Typography>
      <IconButton
        disabled={settings.rows >= Number(VIDEO_WALL_MAX_ROWS)}
        onClick={onChangeRowsColumns(ArrowButtonType.INCREASE, SettingsProperty.ROWS)}
      >
        <Icon>{icons.right}</Icon>
      </IconButton>
    </StyledStack>

    <StyledStack
      direction="row"
      alignItems="center"
      className={clsx({ active: settings.mode === MODE.cols }, { disabled: settings.mode === MODE.rows })}
      onClick={onChangeMode(MODE.cols)}
      data-testid="columns-setting-block"
    >
      <Icon sx={{ marginRight: '0.5rem' }}>{icons.columns}</Icon>
      <IconButton disabled={settings.cols === 1} onClick={onChangeRowsColumns(ArrowButtonType.DECREASE, SettingsProperty.COLUMNS)}>
        <Icon>{icons.left}</Icon>
      </IconButton>
      <Typography>{settings.cols}</Typography>
      <IconButton
        disabled={settings.cols >= Number(VIDEO_WALL_MAX_COLS)}
        onClick={onChangeRowsColumns(ArrowButtonType.INCREASE, SettingsProperty.COLUMNS)}
      >
        <Icon>{icons.right}</Icon>
      </IconButton>
    </StyledStack>
  </Stack>
);

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  cursor: 'default',
  height: '1.75rem',
  padding: '0 0.625rem',
  border: `1px solid ${theme.palette.text.t1}`,
  borderRadius: '0.5rem',
  '& .MuiIcon-root, & .MuiTypography-root': {
    fontSize: '0.75rem',
  },
  '& .MuiTypography-root': {
    margin: '0 0.3rem',
  },
  '&.active': {
    border: 'unset',
    background: linearGradient,
    color: theme.palette.text.t6,
    '& .MuiTypography-root': {
      color: theme.palette.text.t6,
    },
    '& .Mui-disabled': {
      color: theme.palette.text.t3,
    },
  },
  '&.disabled .MuiIconButton-root': {
    display: 'none',
  },
}));

export default CSDGridController;
