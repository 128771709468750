import React, { useEffect } from 'react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { fetchSubscriptions } from '@store/thunks/subscriptions/subscriptions-thunks';
import { useAppSelector, useAppDispatch } from '@app/v2/shared/hooks';
import NotificationProfileProvider from '@app/v2/features/notificationProfile/context/NotificationProfileProvider';
import CSDNotificationSettingsContent from './CSDNotificationSettingsContent';
import CSDNotificationSettingsTitle from './CSDNotificationSettingsTitle';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const CSDNotificationSettings = ({ isOpen = false, onClose }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { globalSubscriptions, meteoBulletinGroups } = useAppSelector(state => state.subscriptions);

  useEffect(() => {
    dispatch(fetchSubscriptions());
  }, [dispatch]);

  return (
    isOpen && (
      <Box className={classes.notificationSettingsContainer}>
        <NotificationProfileProvider>
          <CSDNotificationSettingsTitle onClose={onClose} />
          <CSDNotificationSettingsContent global={globalSubscriptions} bulletins={meteoBulletinGroups} />
        </NotificationProfileProvider>
      </Box>
    )
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  notificationSettingsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem',
    background: theme.palette.background.default,
  },
}));

export default CSDNotificationSettings;
