import { strictlyEqual } from '@app/v2/shared/helpers';
import { ViewMode } from '@app/v2/shared/enums';
import useAppSelector from '../reactRedux/useAppSelector';
import useActions from '../reactRedux/useActions';

export default function useNewUserSetsViewManager() {
  const mode = useAppSelector(state => state.newUserSets.mode);
  const { setUserSetMode } = useActions();

  return {
    mode,
    isCreateMode: strictlyEqual(mode, ViewMode.Create),
    isDefaultMode: strictlyEqual(mode, ViewMode.Default),
    setUserSetMode,
  };
}
