import React, { useState, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { Tabs, Box, Theme, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Notification } from '@app/v2/features/notifications/types';
import tabs from '@app/core/constants/subscriptions';
import { MeteoBulletinGroup } from '@store/slices/subscriptions/types';
import { CSDTab } from '@app/modules/kit-module/shared/ui';
import { NOTIFICATION_SETTINGS_TABS } from '@app/v2/features/notifications/constants';
import GlobalTab from './subscriptionsTabs/globalTab/GlobalTab';
import LocalTab from './subscriptionsTabs/localTab/LocalTab';
import BulletinsTab from './subscriptionsTabs/BulletinsTab';
import CSDNotificationSettingsActions from './CSDNotificationSettingsActions';

type Props = {
  global: Notification[];
  bulletins: MeteoBulletinGroup[];
} & I18N.TranslationFunction<'dialogs', 'alertSubscriptions.content'>;

const CSDNotificationSettingsContent = ({ global = [], bulletins = [], t }: Props) => {
  const classes = useStyles();

  const [currentTab, setCurrentTab] = useState<keyof typeof NOTIFICATION_SETTINGS_TABS>(NOTIFICATION_SETTINGS_TABS.GLOBAL);

  const TabMapper = useMemo(
    () => ({
      [NOTIFICATION_SETTINGS_TABS.GLOBAL]: <GlobalTab data={global} />,
      [NOTIFICATION_SETTINGS_TABS.LOCAL]: <LocalTab />,
      [NOTIFICATION_SETTINGS_TABS.BULLETINS]: <BulletinsTab data={bulletins} />,
    }),
    [global, bulletins],
  );

  return (
    <Box className={classes.notificationSettingsContainer}>
      <Tabs
        className={classes.tabs}
        value={currentTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        onChange={(_, value) => setCurrentTab(value)}
      >
        {tabs.map(({ value, label }) => (
          <CSDTab key={value} className={classes.tabItem} label={label(t)} value={value} />
        ))}
      </Tabs>
      <Box flex={1}>{TabMapper[currentTab]}</Box>
      {currentTab !== NOTIFICATION_SETTINGS_TABS.LOCAL && (
        <>
          <Divider sx={{ margin: '1rem 0' }} />
          <CSDNotificationSettingsActions />
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles<Theme>(({ palette: { text } }) => ({
  notificationSettingsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'min-content',
    flex: 1,
  },
  tabs: {
    borderBottom: `1px solid ${text.t1}`,
    '& .MuiTab-root:last-child': {
      marginRight: 0,
    },
  },
  tabItem: {
    flex: 1,
  },
}));

export default withTranslation('dialogs', { keyPrefix: 'alertSubscriptions.content' })(CSDNotificationSettingsContent);
