import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { SwitchProps } from '@mui/material/Switch/Switch';
import { TypographyProps } from '@mui/material/Typography';
import { DataTestIds } from '@app/v2/shared/enums';

type Props = {
  label?: React.ReactNode;
  dataTestId?: DataTestIds;
  labelStyles?: TypographyProps;
} & SwitchProps;

const CSDSwitcher = ({ label, labelStyles, dataTestId, ...switchProps }: Props) => {
  return (
    <FormControlLabel
      sx={{ gap: '0.5rem' }}
      data-testid={dataTestId}
      componentsProps={{
        typography: {
          fontSize: switchProps.size === 'small' ? '0.75rem' : '0.875rem',
          ...labelStyles,
        },
      }}
      control={<Switch {...switchProps} />}
      label={label}
    />
  );
};

export default CSDSwitcher;
