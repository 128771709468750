import React, { Dispatch, useMemo, memo } from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, IconButton, Stack, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles/createTheme';
import icons from '@app/assets/iconFont';
import { directionBtnContent } from '@app/v2/shared/constants';
import { isBackwardDirection, isUnknownDirection, strictlyEqual } from '@app/v2/shared/helpers';
import { DataTestIds, VMSDeviceDirections, VMSTemplatesTypes } from '@app/v2/shared/enums';

interface Props extends I18N.TranslationFunction<'map', 'tpiDialog'> {
  activeId: number;
  stations: Scoreboards.VMSStation[];
  currentDirection: VMSDeviceDirections;
  setActive: Dispatch<Scoreboards.VMSStation>;
  setDirection: Dispatch<VMSDeviceDirections>;
}

const CSDStationsSwitcher = ({ stations = [], activeId, setActive, setDirection, currentDirection, t }: Props) => {
  const classes = useStyles();

  const stationsData = useMemo(
    () =>
      stations.map(({ id, type }, index) => ({
        id,
        icon: strictlyEqual(type, VMSTemplatesTypes.Message) ? icons.tpi : icons.zpi,
        tooltipTitle: t('station', { number: index + 1 }),
      })),
    [stations, t],
  );

  const handleSetActiveStation = (id: number) => {
    if (strictlyEqual(activeId, id)) return;

    const nextStation = stations.find(station => strictlyEqual(station.id, id));
    setActive(nextStation);
  };

  const handleChangeActiveDirection = () => {
    if (isUnknownDirection(currentDirection)) return;

    if (isBackwardDirection(currentDirection)) setDirection(VMSDeviceDirections.Forward);
    else setDirection(VMSDeviceDirections.Backward);
  };

  return (
    <>
      <Tooltip title={directionBtnContent[currentDirection].tooltip}>
        <IconButton onClick={handleChangeActiveDirection} data-testid={DataTestIds.StationsSwitcherChangeDirectionBtn}>
          <Icon>{directionBtnContent[currentDirection].icon}</Icon>
        </IconButton>
      </Tooltip>

      {stations.length > 1 && (
        <Stack direction="row" className={classes.wrapper} data-testid={DataTestIds.StationsSwitcher}>
          {stationsData.map(({ id, tooltipTitle, icon }) => (
            <Tooltip key={id} title={tooltipTitle}>
              <IconButton onClick={() => handleSetActiveStation(id)} data-testid={`${DataTestIds.StationsSwitcher}-${id}`}>
                <Icon
                  className={clsx({
                    [classes.activeIcon]: strictlyEqual(activeId, id),
                  })}
                >
                  {icon}
                </Icon>
              </IconButton>
            </Tooltip>
          ))}
        </Stack>
      )}
    </>
  );
};

const useStyles = makeStyles<Theme>(({ palette }) => ({
  wrapper: {
    gap: '0.5rem',
    justifyContent: 'flex-start',
  },
  activeIcon: {
    '&.custom-icon': {
      color: palette.primary.main,
    },
  },
}));

export default withTranslation('map', { keyPrefix: 'tpiDialog' })(memo(CSDStationsSwitcher));
