import { PointLabelObject, SeriesGanttOptions } from 'highcharts';
import type { CustomGanttPointOptionsObject } from '../../types';

export default function prepareSeriesPlanChart(
  places: CustomGanttPointOptionsObject[],
  alerts: CustomGanttPointOptionsObject[],
): SeriesGanttOptions[] {
  return [
    {
      type: 'gantt',
      data: [...places, ...alerts],
      dataLabels: {
        allowOverlap: true,
        formatter(this: PointLabelObject) {
          const textWidth = (this.point as any).dataLabel?.bBox?.width;
          const shapeWidth = this.point.shapeArgs.width;
          const description = (this.point as any).options.shortDescription;

          return textWidth > shapeWidth ? `<p style="display: none">${description}</p>` : `<p>${description}</p>`;
        },
        useHTML: false,
        style: {
          fontWeight: 'normal',
          fontSize: '12px',
          textOutline: 'none',
        },
      },
    },
  ];
}
