import moment from 'moment';
import 'moment-timezone';
import useAppSelector from './reactRedux/useAppSelector';

export default function useConsiderUTC() {
  const utc = useAppSelector(state => state.account.settings.utc);
  const isUTCTurnOn = useAppSelector(state => state.account.settings.timezoneInsteadCheckbox);

  if (!isUTCTurnOn) {
    moment.tz.setDefault();
  } else {
    const tz = moment.tz.names().find(timezoneName => utc === moment.tz(timezoneName).format('Z'));

    moment.tz.setDefault(tz);
  }
}
