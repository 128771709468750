import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { allReports } from '@app/v2/shared/constants';
import { MainPaths, ReportsSections, ReportType, StationTypes } from '@app/v2/shared/enums';
import useFavoritesLayouts from '../reduxConnections/useFavoritesLayouts';

const getReportsPaths = (reports: Reports.Model[]) => reports.map(report => report.url);

const configuration: Record<StationTypes, string[]> = {
  [StationTypes.Meteo]: [
    MainPaths.Meteo,
    MainPaths.Notifications,
    MainPaths.StationDetails,
    MainPaths.LocatorAnalysis,
    MainPaths.MeteoManagement,
    ...getReportsPaths(allReports[ReportsSections.Common][ReportType.Meteo]),
    ...getReportsPaths(allReports[ReportsSections.Services][ReportType.ServiceMeteo]),
  ],
  [StationTypes.Traffic]: [
    MainPaths.Traffic,
    MainPaths.TrafficManagement,
    ...getReportsPaths(allReports[ReportsSections.Common][ReportType.Traffic]),
    ...getReportsPaths(allReports[ReportsSections.Services][ReportType.ServiceTraffic]),
  ],
  [StationTypes.Video]: [
    MainPaths.Video,
    MainPaths.VideoManagement,
    MainPaths.Incidents,
    ...getReportsPaths(allReports[ReportsSections.Services][ReportType.ServiceVideo]),
  ],
  [StationTypes.Map]: [MainPaths.Map],
  [StationTypes.Vms]: [MainPaths.ScoreboardDevices, MainPaths.ScoreboardTemplates, MainPaths.ScoreboardPlaylists, MainPaths.ScoreboardsAndSigns],
  [StationTypes.DPGT]: [MainPaths.AdaptationToClimateChange],
  [StationTypes.Sops]: [MainPaths.SOPS],
  [StationTypes.Eco]: [MainPaths.ECOMonitoring],
  [StationTypes.VWLC]: [MainPaths.VideoWaterLevelControl],
};

export default function useFilterStationType() {
  const { pathname } = useLocation();
  const { activeLayout } = useFavoritesLayouts();

  const stationType = useMemo<StationTypes>(() => {
    if (pathname === MainPaths.Favorites) return activeLayout;

    return Object.keys(configuration).find(type => configuration[type].includes(pathname)) as StationTypes;
  }, [activeLayout, pathname]);

  return {
    stationType,
    isPlaces: Boolean(stationType),
  };
}
