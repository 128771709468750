export const basicTable = `<CSDTable>
  <TableHead>
    <TableRow>
      ...
      <CSDTableCell align="center" rowIndex={rowIndex} columnIndex={columnIndex}>{content}</CSDTableCell>
      ...
    </TableRow>
  </TableHead>
  <TableBody>
    ...
    <TableRow>
      ...
      <CSDTableCell align="center" rowIndex={rowIndex} columnIndex={columnIndex}>{content}</CSDTableCell>
      ...
    </TableRow>
    ...
  </TableBody>
</CSDTable>`;
export const openCloseCell = `...
  <TableRow>
    ...
    <CSDOpenCloseTableCell label={value} rowIndex={rowIndex} columnIndex={columnIndex}>
      // Place for component
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <IconButton color="inherit">
          <Icon>star-outline</Icon>
        </IconButton>
        <span>{rowData.pin}</span>
        <IconButton color="inherit">
          <Icon>camera</Icon>
        </IconButton>
        <IconButton color="inherit">
          <Icon>traffic</Icon>
        </IconButton>
        <IconButton color="inherit">
          <Icon>schedule</Icon>
        </IconButton>
      </Stack>
    </CSDOpenCloseTableCell>
    <CSDTableCell colSpan={1} align="center" rowIndex={rowIndex} columnIndex={columnIndex}>{content}</CSDTableCell>
    ...
  </TableRow>
...`;
export const errorCell = `<CSDTableCell className="error" align="center" rowIndex={rowIndex} columnIndex={columnIndex}>{content}</CSDTableCell>`;
export const rowColors = `<TableRow className={'success' | 'error' | 'notEnoughData' | 'unavailable'}/>`;
export const tableColumnsTooltip = `<TableHead>
  <TableRow>
    ...
    <CSDTableCell align="center" label={value} rowIndex={-1} columnIndex={columnIndex}>
      <Icon>pin</Icon>
    </CSDTableCell>
    ...
  </TableRow>
</TableHead>`;
export const stickyHeader = `<Table stickyHeader>{content}</Table>`;
export const headerGrouping = `...
  <TableRow/>
    ...
    <CSDGroupRow
      topHeaderSettings={{
        show?: boolean;
        text?: string;
        Icon?: React.ReactNode;
        ...BoxProps
      }} 
      bottomHeaderSettings={{
        show?: boolean;
        text?: string;
        Icon?: React.ReactNode;
        ...BoxProps
      }} 
      colSpan={number}
    />
    ...
  <TableRow>
...`;
export const virtualizedTable = `<TableVirtuoso
  style={{ height }}
  totalCount={data.length}
  data={data}
  fixedItemHeight={elementHeight}
  components={{
    Table: props => <CSDTable {...props} style={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />,
    Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
    TableHead,
    TableRow: ({ item: _item, ...props }: any) => <TableRow {...props} />,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  }}
  fixedHeaderContent={getTableColumns}
  itemContent={(index, data) => (
    <>
      ...
      <CSDTableCell colSpan={1} align="center" rowIndex={rowIndex} columnIndex={columnIndex}>
        {data.workload}
      </CSDTableCell>
      ...
    </> 
  )}
/>`;
export const dataGridTableBacis = `<CSDDataGrid
  adapter={GroupAdapter<Data> | FlatAdapter<Data>}
  columns={
    [
      {
        field: string;
        headerName?: string;
        renderHead?: JSX.Element;
        renderCell?: (rowIndex: number, columnIndex: number) => JSX.Element;
        cellProps?: TableCellProps;
      }
    ]
  } 
  data={data}
  tableProps?: TableProps | TableVirtuosoProps;
/>`;
export const dataGridTableVirtualizedAndGrouping = `<CSDDataGrid
  adapter={GroupAdapter<Data> | FlatAdapter<Data>}
  columns={
    [
      {
        field: string;
        headerName?: string;
        renderHead?: JSX.Element;
        renderCell?: (params: Data, rowIndex: number, columnIndex: number) => JSX.Element;
        cellProps?: TableCellProps;
      }
    ]
  } 
  }
  data={data}
  isVirtualize={true}
  tableProps?: TableProps | TableVirtuosoProps;
/>`;
export const dataGridVirtualized = `<CSDDataGrid
  render={(index: number, data: T) => JSX.Element}
  data={data}
  {...VirtuosoGridProps}
/>`;
