import React, { ReactNode, SyntheticEvent } from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, IconButton, Stack, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import icons from '@app/assets/iconFont';
import { DataTestIds } from '@app/v2/shared/enums';

interface Props {
  updatePlaylist: ReactNode;
  deleteTemplate: ReactNode;
  onClose: Common.VoidCallBack;
}

const CSDTPICardActionPanel = ({ onClose, updatePlaylist, deleteTemplate, t }: Props & I18N.TranslationFunction<'scoreboard'>) => {
  const classes = useStyles();

  return (
    <Stack className={classes.wrapper} data-testid={DataTestIds.VMSCardEditActionPanel}>
      {updatePlaylist}
      {deleteTemplate}

      <Tooltip title={t('playlists.actions.close')} data-testid={DataTestIds.VMSCardEditActionPanelClose}>
        <IconButton
          onClick={(event: SyntheticEvent) => {
            event.stopPropagation();
            onClose();
          }}
        >
          <Icon>{icons.close}</Icon>
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

const useStyles = makeStyles<Theme>({
  wrapper: {
    '&.MuiStack-root': {
      zIndex: 20,
      top: '-1px',
      gap: '0.5rem',
      padding: '0.5rem',
      right: 'calc(-2rem - 2px)',
      position: 'absolute',
      borderRadius: '0 0.5rem 0.5rem 0',
      backgroundColor: 'white',
      border: `1px solid black`,

      '& .custom-icon': {
        fontSize: '1rem',
      },
    },
  },
});

export default withTranslation('scoreboard')(CSDTPICardActionPanel);
