import * as React from 'react';
import { Box, Link } from '@mui/material';
import { DISPLAYED_IMAGES_SLICE_END, FULL_WIDTH } from '@app/v2/shared/constants';
import NoteImagesViewer from '@app/components/notes/NoteImagesViewer';
import useDialog from '@app/core/hooks/useDialog';
import NodeInfoDialog from '@app/components/notes/dialogs/NodeInfoDialog';

interface Props extends Omit<Notes.NodeInfoDialogProps, 'close' | 'beforeClose'> {
  images: string[];
  className: string;
}

const NoteImagesViewerWithAdditionalImagesAmount = (props: Props) => {
  const { className, images, ...dialogProps } = props;
  const morePictureAmount = images.length - DISPLAYED_IMAGES_SLICE_END;

  const openNodeInfoDialog = useDialog(NodeInfoDialog);
  const handleOpenNodeInfoDialog = () => {
    openNodeInfoDialog({
      ...dialogProps,
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      width={FULL_WIDTH}
      data-testid="note-images-viewer-with-additional-images-amount"
    >
      <NoteImagesViewer sliced images={images} />
      {morePictureAmount > 0 && (
        <Link sx={{ fontSize: '1rem', margin: 'auto' }} component="button" className={className} onClick={() => handleOpenNodeInfoDialog()}>
          {`+${morePictureAmount}`}
        </Link>
      )}
    </Box>
  );
};

export default NoteImagesViewerWithAdditionalImagesAmount;
