import { mapActions } from '@app/store/slices/map';
import client from '@app/clients/apollo/client';
import { Profile } from '@app/core/types/profiles/map';
import { loaderActions } from '@app/store/slices/loader';
import profileMutations from '@app/clients/apollo/requests/mutations/profiles';
import { AppDispatch } from '@app/store';

const mutateMapSettingProfiles = (profiles: Profile[], isUpdate: boolean) => {
  return async (dispatch: AppDispatch): Promise<void> => {
    const mapSettingsProfiles = profiles.map(newProfile => ({ ...newProfile }));
    dispatch(loaderActions.setLoaderStatus(true));
    const isProfileCreating = profiles.map(({ id }) => id === null);

    try {
      const { data } = await client.mutate<{ user: { upsertMapSettingProfiles } }>({
        mutation: profileMutations.updateMapProfiles,
        variables: { mapSettingsProfiles },
      });

      if (data?.user) {
        const newProfiles = data.user.upsertMapSettingProfiles;
        dispatch(mapActions.mutateMapProfiles(newProfiles));

        if (isUpdate) {
          const defaultProfile = newProfiles.find(({ isDefault }) => isDefault);
          dispatch(mapActions.setActiveMapProfile(defaultProfile || null));
        }

        if (!isUpdate && isProfileCreating) {
          const existingProfilesIds = profiles.filter(({ id }) => !!id).map(({ id }) => id);
          const createdProfile = newProfiles.find(({ id }) => !existingProfilesIds.includes(id));

          dispatch(mapActions.setActiveMapProfile(createdProfile));
        }
      }
    } catch (e) {
      dispatch(mapActions.mutateMapProfiles(mapSettingsProfiles));
    } finally {
      dispatch(loaderActions.setLoaderStatus(false));
    }
  };
};

export default mutateMapSettingProfiles;
