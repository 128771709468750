/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReducersNames } from '@store/constants';
import { StationTypes } from '@app/v2/shared/enums';

interface State {
  activeLayout: StationTypes;
}

export const initialState: State = {
  activeLayout: StationTypes.Meteo,
};

const favoritesLayoutsSlice = createSlice({
  name: ReducersNames.favoritesLayouts,
  initialState,
  reducers: {
    setActiveLayout(state: State, { payload }: PayloadAction<StationTypes>) {
      state.activeLayout = payload;
    },
  },
});

export const favoritesLayoutsActions = favoritesLayoutsSlice.actions;

export default favoritesLayoutsSlice.reducer;
