import { KeyPrefix } from 'react-i18next';
import { i18n } from '@app/v2/shared/localization';

export const lang = {
  contextButtonTitle: i18n.t('charts:lang.contextButtonTitle'),
  rangeSelectorZoom: i18n.t('charts:lang.period'),
  exitFullscreen: i18n.t('charts:lang.exitFullScreen'),
  months: [
    'charts:lang.months.january',
    'charts:lang.months.february',
    'charts:lang.months.march',
    'charts:lang.months.april',
    'charts:lang.months.may',
    'charts:lang.months.june',
    'charts:lang.months.july',
    'charts:lang.months.august',
    'charts:lang.months.september',
    'charts:lang.months.october',
    'charts:lang.months.november',
    'charts:lang.months.december',
  ].map(i => i18n.t(i)),
  shortMonths: [
    'charts:lang.shortMonths.januaryShort',
    'charts:lang.shortMonths.februaryShort',
    'charts:lang.shortMonths.marchShort',
    'charts:lang.shortMonths.aprilShort',
    'charts:lang.shortMonths.mayShort',
    'charts:lang.shortMonths.juneShort',
    'charts:lang.shortMonths.julyShort',
    'charts:lang.shortMonths.augustShort',
    'charts:lang.shortMonths.septemberShort.',
    'charts:lang.shortMonths.octoberShort',
    'charts:lang.shortMonths.novemberShort',
    'charts:lang.shortMonths.decemberShort',
  ].map(i => i18n.t(i)),
  weekdays: [
    'charts:lang.weekdays.sunday',
    'charts:lang.weekdays.monday',
    'charts:lang.weekdays.tuesday',
    'charts:lang.weekdays.wednesday',
    'charts:lang.weekdays.thursday',
    'charts:lang.weekdays.friday',
    'charts:lang.weekdays.saturday',
  ].map(i => i18n.t(i)),
  shortWeekdays: [
    'charts:lang.shortWeekdays.sundayShort',
    'charts:lang.shortWeekdays.mondayShort',
    'charts:lang.shortWeekdays.tuesdayShort',
    'charts:lang.shortWeekdays.wednesdayShort',
    'charts:lang.shortWeekdays.thursdayShort',
    'charts:lang.shortWeekdays.fridayShort',
    'charts:lang.shortWeekdays.saturdayShort',
  ].map(i => i18n.t(i)),
};

export const menuItemDefinitions: { [key: string]: { text: KeyPrefix<'charts'> } } = {
  viewFullscreen: {
    text: 'menuDefinitions.viewFullscreen',
  },
  printChart: {
    text: 'menuDefinitions.printChart',
  },
  downloadPNG: {
    text: 'menuDefinitions.downloadPNG',
  },
  downloadJPEG: {
    text: 'menuDefinitions.downloadJPEG',
  },
  downloadPDF: {
    text: 'menuDefinitions.downloadPDF',
  },
  downloadSVG: {
    text: 'menuDefinitions.downloadSVG',
  },
};

export const CHART_EXPORT_HEIGHT = 1080;
export const CHART_EXPORT_WIDTH = 1920;

export const CHART_TYPES: { [key: string]: KeyPrefix<'charts'> } = {
  METEO: 'type.meteo',
  LINEAR: 'type.line',
  TRAFFIC: 'type.traffic',
  PLAN: 'type.plan',
  ROAD_PLAN: 'type.roadPlan',
};
