import { Visibility } from './noteVisibility';

const PRIVACY: { value: keyof typeof Visibility; label: keyof typeof Visibility }[] = [
  { value: Visibility.ALL, label: Visibility.ALL },
  { value: Visibility.ORGANIZATION, label: Visibility.ORGANIZATION },
  { value: Visibility.MAIN_ORGANIZATION, label: Visibility.MAIN_ORGANIZATION },
  { value: Visibility.PRIVATE, label: Visibility.PRIVATE },
];

export default PRIVACY;
