export const ErrorAlert = `<CSDAlert 
  severity="error" 
  actionButtonTitle="Действие" 
  isHaveCloseIcon
  >
  Some text
</CSDAlert>`;

export const SuccessAlert = `<CSDAlert 
  severity="success" 
  actionButtonTitle="Действие" 
  isHaveCloseIcon
  >
  Some text
</CSDAlert>`;

export const WarningAlert = `<CSDAlert 
  severity="warning" 
  actionButtonTitle="Действие" 
  isHaveCloseIcon
  >
  Some text
</CSDAlert>`;

export const InfoAlert = `<CSDAlert 
  severity="info" 
  actionButtonTitle="Действие" 
  isHaveCloseIcon
  >
  Some text
</CSDAlert>`;
