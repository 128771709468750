import mainConfig from '../configs/main';

const reverseGeocode = async (latitude: number, longitude: number) => {
  const url = `${mainConfig.api.nominatim}?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`;
  try {
    const response = await fetch(url);
    const data = await response.json();
    return data?.display_name;
  } catch (error) {
    return null;
  }
};

export default reverseGeocode;
