import React, { useMemo, useEffect } from 'react';
import WaterLevelChartSettingsPanel from './WaterLevelChartSettingsPanel';
import WaterLevelChartTabContent from './WaterLevelChartTabContent';
import type { StationsProps } from '../../../types';

const WaterLevelTab = ({ activeStationId, listOfStationsIdOfActiveTab, setActiveStationID, roadTitle }: StationsProps) => {
  const stationId = useMemo(() => activeStationId || listOfStationsIdOfActiveTab[0], [activeStationId, listOfStationsIdOfActiveTab]);

  useEffect(() => {
    setActiveStationID(stationId);
  }, [setActiveStationID, stationId]);

  return (
    <>
      <WaterLevelChartSettingsPanel />
      <WaterLevelChartTabContent stationId={stationId} roadTitle={roadTitle} />
    </>
  );
};

export default WaterLevelTab;
