/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReducersNames } from '@store/constants';

type State = {
  isChatOpen: boolean;
  amountOfUnreadMessages: number;
};

export const initialState: State = {
  isChatOpen: false,
  amountOfUnreadMessages: 0,
};

const chatSlice = createSlice({
  name: ReducersNames.chat,
  initialState,
  reducers: {
    toggleChatStatus: (state: State, { payload }: PayloadAction<{ isChatOpen: boolean }>) => {
      state.isChatOpen = payload.isChatOpen;
    },
    setAmountOfUnreadMessages: (state: State, { payload }: PayloadAction<{ amount: number }>) => {
      state.amountOfUnreadMessages = payload.amount;
    },
  },
});

export const chatActions = chatSlice.actions;

export default chatSlice.reducer;
