/* eslint-disable camelcase */

// --> Cyrillic letters А-Я <--

const CyrillicLetter_A = [
  [0, 0, 1, 1, 1],
  [0, 1, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 1, 1, 1, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_B = [
  [1, 1, 1, 1, 1],
  [1, 0, 0, 0, 0],
  [1, 1, 1, 1, 0],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 1, 1, 1, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_V = [
  [1, 1, 1, 1, 0],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 1, 1, 1, 0],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 1, 1, 1, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_G = [
  [1, 1, 1, 1, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 0],
  [1, 0, 0, 0, 0],
  [1, 0, 0, 0, 0],
  [1, 0, 0, 0, 0],
  [1, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_D = [
  [0, 0, 1, 1, 0],
  [0, 1, 0, 1, 0],
  [0, 1, 0, 1, 0],
  [0, 1, 0, 1, 0],
  [0, 1, 0, 1, 0],
  [0, 1, 0, 1, 0],
  [1, 1, 1, 1, 1],
  [1, 0, 0, 0, 1],
];

const CyrillicLetter_E = [
  [1, 1, 1, 1, 1],
  [1, 0, 0, 0, 0],
  [1, 0, 0, 0, 0],
  [1, 1, 1, 1, 0],
  [1, 0, 0, 0, 0],
  [1, 0, 0, 0, 0],
  [1, 1, 1, 1, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_YO = [
  [0, 1, 0, 1, 0],
  [0, 0, 0, 0, 0],
  [1, 1, 1, 1, 1],
  [1, 0, 0, 0, 0],
  [1, 1, 1, 1, 0],
  [1, 0, 0, 0, 0],
  [1, 1, 1, 1, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_ZH = [
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [0, 1, 1, 1, 0],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_Z = [
  [1, 1, 1, 1, 0],
  [0, 0, 0, 0, 1],
  [0, 0, 0, 0, 1],
  [0, 1, 1, 1, 0],
  [0, 0, 0, 0, 1],
  [0, 0, 0, 0, 1],
  [1, 1, 1, 1, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_I = [
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 1, 1],
  [1, 0, 1, 0, 1],
  [1, 1, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_TH = [
  [0, 0, 1, 0, 0],
  [1, 0, 1, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 1, 1],
  [1, 0, 1, 0, 1],
  [1, 1, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_K = [
  [1, 0, 0, 0, 1],
  [1, 0, 0, 1, 0],
  [1, 0, 1, 0, 0],
  [1, 1, 0, 0, 0],
  [1, 0, 1, 0, 0],
  [1, 0, 0, 1, 0],
  [1, 0, 0, 0, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_L = [
  [0, 0, 0, 1, 1],
  [0, 0, 1, 0, 1],
  [0, 1, 0, 0, 1],
  [0, 1, 0, 0, 1],
  [0, 1, 0, 0, 1],
  [0, 1, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_M = [
  [1, 0, 0, 0, 1],
  [1, 1, 0, 1, 1],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_N = [
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 1, 1, 1, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_O = [
  [0, 1, 1, 1, 0],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 1, 1, 1, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_P = [
  [1, 1, 1, 1, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_R = [
  [1, 1, 1, 1, 0],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 1, 1, 1, 0],
  [1, 0, 0, 0, 0],
  [1, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_S = [
  [0, 1, 1, 1, 0],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 0],
  [1, 0, 0, 0, 0],
  [1, 0, 0, 0, 0],
  [1, 0, 0, 0, 1],
  [0, 1, 1, 1, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_T = [
  [1, 1, 1, 1, 1],
  [0, 0, 1, 0, 0],
  [0, 0, 1, 0, 0],
  [0, 0, 1, 0, 0],
  [0, 0, 1, 0, 0],
  [0, 0, 1, 0, 0],
  [0, 0, 1, 0, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_U = [
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 1, 1, 1, 1],
  [0, 0, 0, 0, 1],
  [0, 1, 1, 1, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_F = [
  [0, 1, 1, 1, 0],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [0, 1, 1, 1, 0],
  [0, 0, 1, 0, 0],
  [0, 0, 1, 0, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_X = [
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 1, 0, 1, 0],
  [0, 0, 1, 0, 0],
  [0, 1, 0, 1, 0],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_C = [
  [1, 0, 0, 1, 0],
  [1, 0, 0, 1, 0],
  [1, 0, 0, 1, 0],
  [1, 0, 0, 1, 0],
  [1, 0, 0, 1, 0],
  [1, 0, 0, 1, 0],
  [1, 1, 1, 1, 1],
  [0, 0, 0, 0, 1],
];

const CyrillicLetter_CH = [
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 1, 1, 1, 1],
  [0, 0, 0, 0, 1],
  [0, 0, 0, 0, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_SH = [
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 1, 1, 1, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_SCH = [
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 1, 1, 1, 1],
  [0, 0, 0, 0, 1],
];

const CyrillicLetter_SolidMark = [
  [1, 1, 0, 0, 0],
  [0, 1, 0, 0, 0],
  [0, 1, 0, 0, 0],
  [0, 1, 1, 1, 0],
  [0, 1, 0, 0, 1],
  [0, 1, 0, 0, 1],
  [0, 1, 1, 1, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_bI = [
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 1, 0, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 1, 0, 0, 1],
  [0, 0, 0, 0, 0],
];
const CyrillicLetter_SoftSign = [
  [1, 0, 0, 0, 0],
  [1, 0, 0, 0, 0],
  [1, 0, 0, 0, 0],
  [1, 1, 1, 0, 0],
  [1, 0, 0, 1, 0],
  [1, 0, 0, 1, 0],
  [1, 1, 1, 0, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_3 = [
  [0, 1, 1, 1, 0],
  [1, 0, 0, 0, 1],
  [0, 0, 0, 0, 1],
  [0, 1, 1, 1, 1],
  [0, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 1, 1, 1, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_Y = [
  [1, 0, 0, 1, 0],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 1, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 0, 0, 1, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_YA = [
  [0, 1, 1, 1, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 1, 1, 1, 1],
  [0, 0, 1, 0, 1],
  [0, 1, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 0, 0, 0, 0],
];

// --> Cyrillic letters а-я <--

const CyrillicLetter_a = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 1, 1, 1, 0],
  [0, 0, 0, 0, 1],
  [0, 1, 1, 1, 1],
  [1, 0, 0, 0, 1],
  [0, 1, 1, 1, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_b = [
  [0, 1, 1, 1, 1],
  [1, 0, 0, 0, 0],
  [0, 1, 1, 1, 0],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 1, 1, 1, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_v = [
  [0, 1, 1, 0, 0],
  [1, 0, 0, 1, 0],
  [1, 0, 1, 0, 0],
  [1, 1, 1, 1, 0],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 1, 1, 1, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_g = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 1, 1, 1, 0],
  [1, 0, 0, 1, 0],
  [1, 0, 0, 0, 0],
  [1, 0, 0, 0, 0],
  [1, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_d = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 1, 1, 0],
  [0, 1, 0, 1, 0],
  [0, 1, 0, 1, 0],
  [0, 1, 0, 1, 0],
  [1, 1, 1, 1, 1],
  [1, 0, 0, 0, 1],
];

const CyrillicLetter_e = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 1, 1, 1, 0],
  [1, 0, 0, 0, 1],
  [1, 1, 1, 1, 1],
  [1, 0, 0, 0, 0],
  [0, 1, 1, 1, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_yo = [
  [0, 1, 0, 1, 0],
  [0, 0, 0, 0, 0],
  [0, 1, 1, 1, 0],
  [1, 0, 0, 0, 1],
  [1, 1, 1, 1, 1],
  [1, 0, 0, 0, 0],
  [0, 1, 1, 1, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_zh = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [0, 1, 1, 1, 0],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_z = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 1, 1, 0, 0],
  [0, 0, 0, 1, 0],
  [0, 1, 1, 0, 0],
  [0, 0, 0, 1, 0],
  [1, 1, 1, 0, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_i = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 1, 1],
  [1, 0, 1, 0, 1],
  [1, 1, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_th = [
  [0, 0, 0, 0, 0],
  [0, 1, 0, 1, 0],
  [0, 0, 1, 0, 0],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 1, 1],
  [1, 0, 1, 0, 1],
  [1, 1, 0, 0, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_k = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 0, 0, 1, 0],
  [1, 0, 1, 0, 0],
  [1, 1, 0, 0, 0],
  [1, 0, 1, 0, 0],
  [1, 0, 0, 1, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_l = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 1, 1, 0],
  [0, 1, 0, 1, 0],
  [1, 0, 0, 1, 0],
  [1, 0, 0, 1, 0],
  [1, 0, 0, 1, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_m = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 0, 0, 0, 1],
  [1, 1, 0, 1, 1],
  [1, 0, 1, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_n = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 1, 1, 1, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_o = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 1, 1, 1, 0],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 1, 1, 1, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_p = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 1, 1, 1, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_r = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 1, 1, 1, 0],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 1, 1, 1, 0],
  [1, 0, 0, 0, 0],
  [1, 0, 0, 0, 0],
];

const CyrillicLetter_s = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 1, 1, 1, 0],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 0],
  [1, 0, 0, 0, 1],
  [0, 1, 1, 1, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_t = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 1, 1, 1, 1],
  [0, 0, 1, 0, 0],
  [0, 0, 1, 0, 0],
  [0, 0, 1, 0, 0],
  [0, 0, 1, 0, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_u = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [0, 1, 1, 1, 1],
  [0, 0, 0, 0, 1],
  [0, 1, 1, 1, 0],
];

const CyrillicLetter_f = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 1, 1, 1, 0],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [0, 1, 1, 1, 0],
  [0, 0, 1, 0, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_x = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 0, 0, 0, 1],
  [0, 1, 0, 1, 0],
  [0, 0, 1, 0, 0],
  [0, 1, 0, 1, 0],
  [1, 0, 0, 0, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_c = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 0, 0, 1, 0],
  [1, 0, 0, 1, 0],
  [1, 0, 0, 1, 0],
  [1, 0, 0, 1, 0],
  [1, 1, 1, 1, 1],
  [0, 0, 0, 0, 1],
];

const CyrillicLetter_ch = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 0, 0, 1, 0],
  [1, 0, 0, 1, 0],
  [0, 1, 1, 1, 0],
  [0, 0, 0, 1, 0],
  [0, 0, 0, 1, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_h = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 1, 1, 1, 1],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_sch = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 1, 1, 1, 1],
  [0, 0, 0, 0, 1],
];

const CyrillicLetter_Solid_mark = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 1, 0, 0, 0],
  [0, 1, 0, 0, 0],
  [0, 1, 1, 1, 0],
  [0, 1, 0, 0, 1],
  [0, 1, 1, 1, 0],
  [0, 0, 0, 0, 0],
];

const CyrillicLetter_bi = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 0, 1],
  [1, 1, 0, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 1, 0, 0, 1],
  [0, 0, 0, 0, 0],
];
const CyrillicLetter_Soft_sign = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 0, 0, 0, 0],
  [1, 0, 0, 0, 0],
  [1, 1, 1, 0, 0],
  [1, 0, 0, 1, 0],
  [1, 1, 1, 0, 0],
  [0, 0, 0, 0, 0],
];
const CyrillicLetter_3_little = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 1, 1, 1, 0],
  [1, 0, 0, 0, 1],
  [0, 0, 1, 1, 1],
  [1, 0, 0, 0, 1],
  [0, 1, 1, 1, 0],
  [0, 0, 0, 0, 0],
];
const CyrillicLetter_y = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 0, 0, 1, 0],
  [1, 0, 1, 0, 1],
  [1, 1, 1, 0, 1],
  [1, 0, 1, 0, 1],
  [1, 0, 0, 1, 0],
  [0, 0, 0, 0, 0],
];
const CyrillicLetter_ya = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 1, 1, 1, 1],
  [1, 0, 0, 0, 1],
  [0, 1, 1, 1, 1],
  [0, 0, 1, 0, 1],
  [0, 1, 0, 0, 1],
  [0, 0, 0, 0, 0],
];

const scoreboardCyrillicLettersMapper = {
  1040: CyrillicLetter_A,
  1041: CyrillicLetter_B,
  1042: CyrillicLetter_V,
  1043: CyrillicLetter_G,
  1044: CyrillicLetter_D,
  1045: CyrillicLetter_E,
  1025: CyrillicLetter_YO,
  1046: CyrillicLetter_ZH,
  1047: CyrillicLetter_Z,
  1048: CyrillicLetter_I,
  1049: CyrillicLetter_TH,
  1050: CyrillicLetter_K,
  1051: CyrillicLetter_L,
  1052: CyrillicLetter_M,
  1053: CyrillicLetter_N,
  1054: CyrillicLetter_O,
  1055: CyrillicLetter_P,
  1056: CyrillicLetter_R,
  1057: CyrillicLetter_S,
  1058: CyrillicLetter_T,
  1059: CyrillicLetter_U,
  1060: CyrillicLetter_F,
  1061: CyrillicLetter_X,
  1062: CyrillicLetter_C,
  1063: CyrillicLetter_CH,
  1064: CyrillicLetter_SH,
  1065: CyrillicLetter_SCH,
  1066: CyrillicLetter_SolidMark,
  1067: CyrillicLetter_bI,
  1068: CyrillicLetter_SoftSign,
  1069: CyrillicLetter_3,
  1070: CyrillicLetter_Y,
  1071: CyrillicLetter_YA,
  1072: CyrillicLetter_a,
  1073: CyrillicLetter_b,
  1074: CyrillicLetter_v,
  1075: CyrillicLetter_g,
  1076: CyrillicLetter_d,
  1077: CyrillicLetter_e,
  1105: CyrillicLetter_yo,
  1078: CyrillicLetter_zh,
  1079: CyrillicLetter_z,
  1080: CyrillicLetter_i,
  1081: CyrillicLetter_th,
  1082: CyrillicLetter_k,
  1083: CyrillicLetter_l,
  1084: CyrillicLetter_m,
  1085: CyrillicLetter_n,
  1086: CyrillicLetter_o,
  1087: CyrillicLetter_p,
  1088: CyrillicLetter_r,
  1089: CyrillicLetter_s,
  1090: CyrillicLetter_t,
  1091: CyrillicLetter_u,
  1092: CyrillicLetter_f,
  1093: CyrillicLetter_x,
  1094: CyrillicLetter_c,
  1095: CyrillicLetter_ch,
  1096: CyrillicLetter_h,
  1097: CyrillicLetter_sch,
  1098: CyrillicLetter_Solid_mark,
  1099: CyrillicLetter_bi,
  1100: CyrillicLetter_Soft_sign,
  1101: CyrillicLetter_3_little,
  1102: CyrillicLetter_y,
  1103: CyrillicLetter_ya,
};

export default scoreboardCyrillicLettersMapper;
