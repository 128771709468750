import React from 'react';
import { NavLink } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { KitSidebarItem } from '../../configs/kitSidebarConfig/types';

type Props = {
  items: KitSidebarItem[];
};

const CSDKitSidebar = ({ items }: Props) => {
  const classes = useStyles();

  return (
    <List component="nav" className={classes.list}>
      {items.map(({ path, label, isGroupTitle }) => (
        <ListItem key={label} disabled={isGroupTitle} button>
          <NavLink to={path} exact className={classes.listItem} activeClassName={classes.activeItem}>
            <ListItemText primary={label} />
          </NavLink>
        </ListItem>
      ))}
    </List>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.contrastText,
  },
  listItem: {
    width: '100%',
    color: 'rgba(0, 0, 0, .87)',
    textDecoration: 'none',
  },
  activeItem: {
    '& > div > span': {
      fontWeight: 'bold',
    },
  },
}));

export default CSDKitSidebar;
