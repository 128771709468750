import React, { ReactNode } from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import icons from '@app/assets/iconFont';
import { DataTestIds } from '@app/v2/shared/enums';

interface Props extends I18N.TranslationFunction<'map', 'tpiDialog'> {
  coords: string;
  loading?: boolean;
  organizationTitle: string;
  stationsSwitcher?: ReactNode;
  onClose: Common.VoidCallBack;
}

const CSDMapTpiHeader = ({
  title,
  coords,
  onClose,
  loading = false,
  stationsSwitcher,
  organizationTitle,
  t,
}: Props & Pick<Common.BaseRoad, 'title'>) => {
  const classes = useStyles();

  return (
    <Stack className={classes.wrapper} data-testid={DataTestIds.MapTpiHeader}>
      <Stack>
        <Stack direction="row" gap="0.5rem">
          <Typography variant="h6">{t('road')}</Typography>
          {stationsSwitcher}
        </Stack>

        <Stack className={classes.infoBlock}>
          <Icon>{icons.car}</Icon>
          {loading ? (
            <Skeleton variant="rectangular" height={16} width={100} data-testid={DataTestIds.MapTpiHeaderCoordsSkeleton} />
          ) : (
            <Typography>
              {title || <Icon>{icons.noData}</Icon>}, {coords || <Icon>{icons.noData}</Icon>}
            </Typography>
          )}
        </Stack>
      </Stack>

      <Stack>
        <Typography variant="h6">{t('organization')}</Typography>

        <Stack className={classes.infoBlock}>
          <Icon>{icons.car}</Icon>
          {loading ? (
            <Skeleton variant="rectangular" height={16} width={100} data-testid={DataTestIds.MapTpiHeaderOrgSkeleton} />
          ) : (
            <Typography>{organizationTitle || <Icon>{icons.noData}</Icon>}</Typography>
          )}
        </Stack>
      </Stack>

      <IconButton onClick={onClose}>
        <Icon>{icons.close}</Icon>
      </IconButton>
    </Stack>
  );
};

const useStyles = makeStyles<Theme>(({ palette }) => ({
  wrapper: {
    '&.MuiStack-root': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '0 2rem',

      '& > button': {
        alignSelf: 'flex-start',

        '& > span': {
          fontSize: '1.5rem',
        },
      },
    },
  },
  infoBlock: {
    '&.MuiStack-root': {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-start',

      '& > span.custom-icon': {
        fontSize: '1rem',
        marginRight: '0.25rem',
        color: palette.primary.main,
      },

      '& > p': {
        fontSize: '0.75rem',
      },

      '& > p > span.custom-icon': {
        fontSize: '1rem',
      },
    },
  },
}));

export default withTranslation('map', { keyPrefix: 'tpiDialog' })(CSDMapTpiHeader);
