import React from 'react';
import { Button, Stack, Icon } from '@mui/material';
import { isFunction } from '@app/v2/shared/helpers';

interface Props {
  actions: {
    action: () => void;
    variant: 'text' | 'outlined' | 'contained';
    name?: string;
    isDisabled?: boolean;
    startIcon?: string;
    endIcon?: string;
    contentIcon?: string;
  }[];
}

const ConstructorActions = ({ actions }: Props) => {
  const handleClick = (actionCb: () => void): void => {
    if (!isFunction(actionCb)) return;

    actionCb();
  };

  return (
    <Stack direction="row" gap="1rem" alignItems="center" justifyContent="space-between" flexWrap="wrap" width="100%">
      {actions.map(({ isDisabled, action, variant, name, startIcon, endIcon, contentIcon }) => (
        <Button
          key={contentIcon}
          startIcon={<Icon>{startIcon}</Icon>}
          endIcon={<Icon>{endIcon}</Icon>}
          disabled={isDisabled}
          variant={variant}
          onClick={() => handleClick(action)}
        >
          <Icon>{contentIcon}</Icon>
          {name}
        </Button>
      ))}
    </Stack>
  );
};

export default ConstructorActions;
