export const dividerWithText = `<Stack direction="column" spacing={3}>
  {content}
  <Divider>CENTER</Divider>
  {content}
  <Divider textAlign="left">LEFT</Divider>
  {content}
  <Divider textAlign="right">RIGHT</Divider>
  {content}
  <Divider>
    <Chip label="CHIP" />
  </Divider>
  {content}
</Stack>`;
export const verticalWithText = `<Divider orientation="vertical" flexItem>VERTICAL</Divider>`;
export const verticalDivider = `<Divider orientation="vertical" flexItem />`;
export const verticalMiddleDivider = `<Divider orientation="vertical" variant="middle" flexItem />`;
