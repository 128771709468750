import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';

interface Props extends I18N.TranslationFunction<'filters'> {
  hidden: boolean;
  disabledSave: boolean;
  disabledRemove: boolean;
  disabledReset: boolean;
  onSaveChanges: Common.VoidCallBack;
  onRemove: Common.VoidCallBack;
  onReset: Common.VoidCallBack;
}

const CSDPresetActions = ({ hidden, onSaveChanges, disabledSave, disabledRemove, disabledReset, onRemove, onReset, t }: Props) => {
  return (
    <>
      {hidden && (
        <Box className="presetActions">
          <Button variant="outlined" disabled={disabledSave} onClick={onSaveChanges}>
            {t('actions.savePresetChanges')}
          </Button>

          <Button variant="error" disabled={disabledRemove} onClick={onRemove}>
            {t('actions.remove')}
          </Button>

          <Button variant="error" disabled={disabledReset} onClick={onReset}>
            {t('actions.reset')}
          </Button>
        </Box>
      )}
    </>
  );
};

export default withTranslation('filters')(memo(CSDPresetActions));
