import Yup from '@app/core/validation/locale';
import { i18n } from '@app/v2/shared/localization';

const validationSchema = Yup.object().shape<Auth.AccountSettings>({
  userName: Yup.string().min(3).max(100).required(),
  userEmail: Yup.string().email().required(),
  userPhone: Yup.string()
    .nullable()
    .test('userPhone', i18n.t('validation:wrongPhoneNumber'), value => {
      if (!value) return true;

      const onlyDigitsValue = value.replace(/[^0-9]/g, '');

      return onlyDigitsValue.length === 11 || onlyDigitsValue.length === 1;
    }),
});

export default validationSchema;
