import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Icon, IconButton, Tooltip } from '@mui/material';
import { addIdsToTemplates, strictlyEqual } from '@app/v2/shared/helpers';
import vmsNewMutations from '@app/clients/apollo/requests/mutations/vmsNew';
import icons from '@app/assets/iconFont';
import { addFeatureActions } from '@app/v2/shared/utils';
import { DataTestIds } from '@app/v2/shared/enums';

interface Props extends Common.FeaturesActions {
  playlist: Scoreboards.PlaylistModel;
  template: Scoreboards.TemplateWithModeAndId;
}

const CSDUpdatePlaylist = ({ playlist, template: nextTemplate, ...featuresCbs }: Props) => {
  const { t } = useTranslation('scoreboard');

  const [handleChangePlaylist, { loading }] = useMutation<Common.MutationResponse, Scoreboards.UpdatePlaylistVariables>(
    vmsNewMutations.playlist.update,
  );

  const handleChange = () => {
    const nextTemplates = addIdsToTemplates(playlist.playlist).map(({ id, ...oldTemplate }) => {
      const { template, duration, endTime, dateTo, startTime, dateFrom } = nextTemplate;

      if (!strictlyEqual(id, nextTemplate.id)) return oldTemplate;

      return {
        template,
        duration,
        endTime,
        dateTo,
        startTime,
        dateFrom,
      };
    });

    addFeatureActions(
      handleChangePlaylist({
        variables: {
          id: playlist.id,
          organizationId: playlist.organizationId,
          title: playlist.title,
          playlist: {
            content: {
              playlist: nextTemplates,
            },
          },
        },
      }),
      featuresCbs,
    );
  };

  return (
    <Tooltip title={t('common.tooltips.saveChanges')}>
      <IconButton onClick={handleChange} disabled={loading} data-testid={DataTestIds.UpdatePlaylistBtn}>
        <Icon>{icons.done}</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default memo(CSDUpdatePlaylist);
