import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Icon, Tooltip } from '@mui/material';
import { useMutation } from '@apollo/client';
import icons from '@app/assets/iconFont';
import vmsNewMutations from '@app/clients/apollo/requests/mutations/vmsNew';
import { addIdsToTemplates, strictlyEqual } from '@app/v2/shared/helpers';
import { addFeatureActions } from '@app/v2/shared/utils';
import { useDialog } from '@app/v2/shared/hooks';
import { CSDConfirmDialog } from '@app/modules/kit-module/shared/ui';
import { DataTestIds } from '@app/v2/shared/enums';

interface Props extends Common.FeaturesActions {
  playlist: Scoreboards.PlaylistModel;
  template: Scoreboards.TemplateWithModeAndId;
}

const CSDDeleteTemplateFromPlaylistBtn = ({ playlist, template: removedTemplate, ...featuresCbs }: Props) => {
  const openConfirmDialog = useDialog(CSDConfirmDialog);

  const { t } = useTranslation('scoreboard');

  const [deleteTemplateFromPlaylist, { loading }] = useMutation<Common.MutationResponse, Scoreboards.UpdatePlaylistVariables>(
    vmsNewMutations.playlist.update,
  );

  const handleDelete = () => {
    openConfirmDialog({ question: t('playlists.actions.confirmationQuestionOnDeleteTemplateFromPlaylist') }).beforeClose.subscribe(
      (answerToQuestion: boolean) => {
        if (!answerToQuestion) return;

        const nextTemplates: Scoreboards.Template[] = addIdsToTemplates(playlist.playlist)
          .filter(({ id }) => !strictlyEqual(id, removedTemplate.id))
          .map(({ id, ...other }) => ({ ...other }));

        addFeatureActions(
          deleteTemplateFromPlaylist({
            variables: {
              id: playlist.id,
              organizationId: playlist.organizationId,
              title: playlist.title,
              playlist: {
                content: {
                  playlist: nextTemplates,
                },
              },
            },
          }),
          featuresCbs,
        );
      },
    );
  };

  return (
    <Tooltip title={t('templates.actions.deleteFromPlaylist')}>
      <IconButton onClick={handleDelete} disabled={loading} data-testid={DataTestIds.DeleteTemplateFromPlaylistBtn}>
        <Icon>{icons.trash}</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default memo(CSDDeleteTemplateFromPlaylistBtn);
