import React from 'react';
import { Box, Typography } from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { makeStyles } from '@mui/styles';

interface Props {
  message: string;
}

const CSDEmptySearch = ({ message }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.noData}>
      <SearchOffIcon sx={{ fontSize: '8rem', color: 'lightgrey' }} />

      <Typography variant="h3" sx={{ color: 'lightgrey' }}>
        {message}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles({
  noData: {
    gap: '1rem',
    display: 'flex',
    minHeight: '40rem',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

export default CSDEmptySearch;
