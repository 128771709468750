import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import client from '@app/clients/apollo/client';
import { DialogProvider } from '@app/core/providers/DialogProvider';
import RouterConfig from '@app/v2/shared/configs/router';
import { useAccount } from '@app/v2/shared/hooks';
import { WebSocketProvider } from '@app/v2/shared/contexts';
import { MainLayout } from '@app/v2/shared/ui/layout';
import RedirectToStartPage from '@app/v2/app/ui/redirectToStartPage/RedirectFromStartPage';

const Main = () => {
  const { isAccountSettingsExist } = useAccount();

  return (
    <>
      {!!isAccountSettingsExist && (
        <WebSocketProvider>
          <ApolloProvider client={client}>
            <DialogProvider>
              <MainLayout>
                <Switch>
                  {RouterConfig.map(({ path, component: Component, name }) => (
                    <Route exact key={name} path={path} component={Component} />
                  ))}
                  <Route component={RedirectToStartPage} />
                </Switch>
              </MainLayout>
            </DialogProvider>
          </ApolloProvider>
        </WebSocketProvider>
      )}
    </>
  );
};

export default Main;
