import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FooterStylesParameters } from '../../types/footerStylesParameters';

const PROPS_TO_EXCLUDE = ['isFooterTab', 'open'];

const StyledFooterTabsContainer = styled(Box, {
  shouldForwardProp: propName => !PROPS_TO_EXCLUDE.includes(propName as string),
})<BoxProps & Pick<FooterStylesParameters, 'isFooterTab' | 'open'>>(({ theme, isFooterTab, open }) => ({
  marginLeft: isFooterTab && !open ? '6.875rem' : '1.25rem',
  transition: open
    ? theme.transitions.create(['margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      })
    : theme.transitions.create(['margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
  height: '0px',
}));

export default StyledFooterTabsContainer;
