import React from 'react';
import { CheckboxProps } from '@mui/material';
import { FastField } from 'formik';
import CSDCheckbox from '../CSDCheckbox/CSDCheckbox';

interface Props {
  path: string;
  label?: string;
  checkboxProps?: CheckboxProps;
}

const CSDCheckboxFastField = ({ path, label, checkboxProps }: Props) => {
  return (
    <FastField name={path} shouldUpdate={(current, next) => current?.disabled === next?.disabled}>
      {({ field }) => <CSDCheckbox label={label} checked={field.value} onChange={field.onChange} {...checkboxProps} />}
    </FastField>
  );
};

export default CSDCheckboxFastField;
