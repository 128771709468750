import React, { useState } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { useQuery } from '@apollo/client';
import moment from 'moment/moment';
import { DataTestIds, ReportsPreviewMod } from '@app/v2/shared/enums';
import reportQueries from '@app/clients/apollo/requests/queries/reports';
import { addFeatureActions, arrayBufferToDataURL } from '@app/v2/shared/utils';
import axios from '@app/clients/axios/client';
import tokenStorage from '@app/clients/apollo/tokenStorage';
import { useAccount } from '@app/v2/shared/hooks';
import { i18n } from '@app/v2/shared/localization';

interface Props extends Common.FeaturesActions {
  btnProps?: ButtonProps;
  options: Reports.Options;
  previewMode?: ReportsPreviewMod;
  openPreviewDialog: (data?: Reports.PreviewDialogProps) => void;
}

const previewModeNormalizer: Record<
  ReportsPreviewMod,
  {
    normalizer: (reportProperties: Reports.Properties) => Reports.Properties;
    btnTitle: string;
  }
> = {
  [ReportsPreviewMod.Default]: {
    btnTitle: i18n.t('reports:actions.previewReport'),
    normalizer: reportProperties => reportProperties,
  },
  [ReportsPreviewMod.Last24Hours]: {
    btnTitle: i18n.t('reports:actions.dailyReport'),
    normalizer: reportProperties => ({
      ...reportProperties,
      isDataPerDay: true,
      dateFrom: moment().subtract(1, 'day').utcOffset(0, true).unix(),
      dateTo: moment().utcOffset(0, true).unix(),
    }),
  },
};

const CSDPreviewReportBtn = ({
  options,
  btnProps: { disabled, ...other },
  openPreviewDialog,
  previewMode = ReportsPreviewMod.Default,
  ...featuresActions
}: Props) => {
  const { isAccountHaveEmailAndPhone } = useAccount();
  const [loading, setLoading] = useState<boolean>(false);

  const { refetch: getReportPDFFileURL } = useQuery<Reports.ReportFileResponse, Reports.Options>(reportQueries.reportPDF, { skip: true });

  const handleGetReportPDFFileURL = () => {
    setLoading(true);

    addFeatureActions(
      getReportPDFFileURL({
        report: {
          type: options.report.type,
          properties: previewModeNormalizer[previewMode].normalizer(options.report.properties),
        },
      })
        .then(({ data }) => data?.reportsPDF?.url)
        .then(fileURL =>
          axios.get<ArrayBuffer>(fileURL, {
            responseType: 'arraybuffer',
            headers: {
              authorization: `Bearer ${tokenStorage.get()}`,
              Accept: 'application/pdf',
            },
          }),
        )
        .then(({ data }) =>
          openPreviewDialog({
            fileURL: arrayBufferToDataURL(data),
            isAccountHaveEmailAndPhone,
          }),
        )
        .finally(() => setLoading(false)),
      featuresActions,
    );
  };

  return (
    <Button onClick={handleGetReportPDFFileURL} disabled={loading || disabled} {...other} data-testid={DataTestIds.ReportPreviewBtn}>
      {previewModeNormalizer[previewMode].btnTitle}
    </Button>
  );
};

export default CSDPreviewReportBtn;
