import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isDefaultMapProfile } from '@app/v2/shared/helpers';
import useMapProfileContext from '@app/v2/features/mapProfiles/hooks/useMapProfileContext';
import MapLayersControl from './MapLayersControl';
import GisControl from './GisControl';
import Viewport from './Viewport';
import ZoomMarkerControl from './ZoomMarkerControl';
import RoadsControl from './RoadsControl';
import MapProfilesManager from '../../components/MapProfilesManager';
import DisabledWrapperWithTooltip from '../../components/DisabledWrapperWithTooltip';

const MapSymbolsPanel = ({ hideProfileActions }: { hideProfileActions: boolean }) => {
  const classes = useStyles();
  const { currentActiveProfile } = useMapProfileContext();
  const { t } = useTranslation('footer', { keyPrefix: 'panels' });

  return (
    <>
      <MapProfilesManager hideProfileActions={hideProfileActions} />
      <DisabledWrapperWithTooltip disabled={isDefaultMapProfile(currentActiveProfile)}>
        <Box className={classes.mapSymbolsContainer}>
          <Box className={classes.columnContainer}>
            <Typography className={classes.settingsTypographyHeader} variant="h4">
              {t('mapSymbols.map')}
            </Typography>
            <Box className={classes.roadsControl}>
              <RoadsControl />
            </Box>
            <Box className={classes.mapLayerContent}>
              <MapLayersControl />
            </Box>
            <Viewport />
          </Box>
          <Box className={classes.columnContainer}>
            <Typography className={classes.settingsTypographyHeader} variant="h4">
              {t('mapSymbols.designations')}
            </Typography>
            <Box className={classes.mapGisContent}>
              <GisControl />
            </Box>
          </Box>
          <Box className={classes.columnContainer}>
            <Typography className={classes.settingsTypographyHeader} variant="h4">
              {t('mapSymbols.markerZoomControl')}
            </Typography>
            <Box className={classes.mapGisContent}>
              <Box className={classes.mapMarkerZoomControl}>
                <ZoomMarkerControl />
              </Box>
            </Box>
          </Box>
        </Box>
      </DisabledWrapperWithTooltip>
    </>
  );
};

const useStyles = makeStyles(() => ({
  mapSymbolsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  mapLayerContent: {
    display: 'flex',
    maxWidth: '30rem',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
  },
  mapGisContent: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '30rem',
  },
  mapMarkerZoomControl: {
    maxWidth: '30rem',
  },
  roadsControl: {
    marginBottom: '1rem',
  },
  settingsTypographyHeader: {
    '&.MuiTypography-root': {
      marginBottom: '1rem',
      alignItems: 'flex-start',
    },
  },
}));

export default MapSymbolsPanel;
