import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

interface Props {
  title: string;
  hide: ReactNode;
  addToFavorites: ReactNode;
  removeFromFavorites: ReactNode;
}

const CSDAccidentNewsCardHeader = ({ title, hide, removeFromFavorites, addToFavorites }: Props) => {
  return (
    <Box className="cardHeader">
      <Typography variant="h5">{title}</Typography>

      <Box className="cardHeaderActions">
        {hide && hide}

        {addToFavorites && addToFavorites}

        {removeFromFavorites && removeFromFavorites}
      </Box>
    </Box>
  );
};

export default CSDAccidentNewsCardHeader;
