import React, { SyntheticEvent, useEffect, useMemo, useRef } from 'react';
import DataSource from '@app/core/source/DataSource';
import useDataSource from '@app/core/source/hooks/useDataSource';
import useListingPage from '@app/components/listing-page/hooks/useListingPage';
import { LISTING_PAGE_LIMITS_DEFAULT } from '@app/core/constants';
import useURLPaginationParams from '@app/core/source/hooks/useURLPaginationParams';
import getPageLayoutSxStyles from '@app/core/helpers/getPageLayoutSxStyles';
import { useNewFilters, useSorting, useSystem } from '@app/v2/shared/hooks';
import { SortStatus } from '@app/v2/shared/enums';

export type Options<T> = {
  tableMode?: string;
  listingData: T[];
  loading?: boolean;
  nextPageLoading?: boolean;
  pagination?: Common.Pagination;
  handleChangeTableMode?: (value: string) => void;
  handleUpdatePagination?: (value: number, field: keyof Common.Pagination) => void;
  handleNextPage?: () => void;
  handleScroll?: (e: SyntheticEvent) => void;
  listingPageLimits?: number[];
  handleVirtualizedScroll?: (index: number) => void;
  isWithOldPanel?: boolean;
  onSorting?: (key: string, value: SortStatus) => void;
  sortingValue?: Common.Sorting;
};

type Props<T extends Common.BaseStation> = {
  moduleType?: string;
  dataSource: DataSource<T>;
  settingsPanel?: (option: Options<T>) => React.ReactNode;
  dataTable: (option: Options<T>) => React.ReactNode;
  onScroll?: (e: SyntheticEvent) => void;
  listingPageLimits?: number[];
  style?: React.CSSProperties;
  isFullHeight?: boolean;
};

export default function ListingPage<T extends Common.BaseStation>(props: Props<T>) {
  const {
    moduleType,
    settingsPanel,
    dataTable,
    dataSource,
    onScroll,
    listingPageLimits = LISTING_PAGE_LIMITS_DEFAULT,
    style,
    isFullHeight = true,
  } = props;
  const { isHeaderRollUp } = useSystem();
  const { filters } = useNewFilters();
  const { sortingValue, onSorting } = useSorting();

  const pageLayoutSxStyles = getPageLayoutSxStyles({ isHeaderRollUp, isFullHeight });
  const el = useRef<HTMLDivElement>();
  const { data: listingData, loading, nextPageLoading } = useDataSource(dataSource);
  const { tableMode, pagination, handleUpdatePagination, handleChangeTableMode, handleScroll, handleVirtualizedScroll } = useListingPage(
    loading || nextPageLoading,
  );

  useURLPaginationParams();

  useEffect(() => {
    dataSource.init(filters);
  }, [dataSource, filters]);

  const options: Options<T> = useMemo(
    () => ({
      sortingValue,
      onSorting,
      pagination,
      tableMode,
      listingData,
      loading,
      nextPageLoading,
      handleUpdatePagination,
      handleChangeTableMode,
      handleScroll,
      listingPageLimits,
      handleVirtualizedScroll,
    }),
    [
      sortingValue,
      onSorting,
      pagination,
      listingPageLimits,
      tableMode,
      listingData,
      loading,
      nextPageLoading,
      handleUpdatePagination,
      handleChangeTableMode,
      handleScroll,
      handleVirtualizedScroll,
    ],
  );

  const content = useMemo(() => {
    const listingSettingsPanel = settingsPanel && settingsPanel(options);
    const listing = dataTable(options);
    return (
      <>
        {listingSettingsPanel}
        {listing}
      </>
    );
  }, [settingsPanel, dataTable, options]);

  return (
    <div
      ref={el}
      style={{ ...(moduleType ? pageLayoutSxStyles.pageFavouriteContent : pageLayoutSxStyles.pageContent), ...style }}
      onScroll={onScroll}
    >
      {content}
    </div>
  );
}
