import { PieChartColors } from '@app/v2/shared/enums';

export const gradientColorMapper = {
  [PieChartColors.BLUE]: ['#072F9C', '#0F79DF'],
  [PieChartColors.SKY_BLUE]: ['#1D4CCC', '#F8F9FB'],
  [PieChartColors.GREEN]: ['#079C30', '#45DF0F'],
  [PieChartColors.YELLOW]: ['#FFF61B', '#E6DE0E'],
  [PieChartColors.RED]: ['#C70707', '#FD0000'],
  [PieChartColors.GREY]: ['#97989B', '#E0E0E0'],
  [PieChartColors.SAND]: ['#CD8240', '#FFC781'],
};

export const textColorMapper = {
  [PieChartColors.BLUE]: '#072F9C',
  [PieChartColors.SKY_BLUE]: '#1D4CCC',
  [PieChartColors.GREEN]: '#079C30',
  [PieChartColors.YELLOW]: '#E6DE0E',
  [PieChartColors.RED]: '#FD0000',
  [PieChartColors.GREY]: '#97989B',
  [PieChartColors.SAND]: '#CD8240',
};
