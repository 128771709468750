import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

interface Props {
  url: string;
}

const MAX_HEIGHT = 416;
const MAX_WIDTH = 640;

const CSDSnapshot = ({ url }: Props) => {
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({ width: MAX_WIDTH, height: MAX_HEIGHT });

  useEffect(() => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      const newWidth = MAX_HEIGHT / (img.height / img.width);
      const newHeight = MAX_WIDTH / (img.width / img.height);

      setDimensions({ width: Math.min(MAX_WIDTH, newWidth), height: Math.min(newHeight, MAX_HEIGHT) });
    };
  }, [url]);

  const classes = useStyles({
    imgUrl: url,
    width: dimensions.width,
    height: dimensions.height,
  });

  return <Box className={classes.image} data-testid="snapshot-image" />;
};

const useStyles = makeStyles<Theme, { imgUrl: string; width: number; height: number }>(() => ({
  image: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: ({ imgUrl }) => `URL(${imgUrl})`,
    width: ({ width }) => `${width}px`,
    height: ({ height }) => `${height}px`,
    borderRadius: '0.5rem',
  },
}));

export default CSDSnapshot;
