enum DialogTabsTypes {
  METEO_CONTROL = 'METEO_CONTROL',
  VIDEO = 'VIDEO',
  TRAFFIC = 'TRAFFIC',
  PLAYLIST = 'PLAYLIST',
  MESSAGE_STATIONS = 'MESSAGE_STATIONS',
  SIGN_STATIONS = 'SIGN_STATIONS',
  WATER_LEVEL_CONTROL = 'WATER_LEVEL_CONTROL',
}

export default DialogTabsTypes;
