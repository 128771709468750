import React, { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Stack, StackProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQuery } from '@apollo/client';
import { DOMNodesIds } from '@app/v2/shared/enums';
import meteoQueries from '@app/clients/apollo/requests/queries/meteo';
import { formatEnFull } from '@app/core/helpers/dateFormat';
import { getSkeletonItems } from '@app/v2/shared/helpers';
import { CSDCenteredBox, CSDMeteoChartSkeleton } from '@app/modules/kit-module/shared/ui';
import { CSDMeteoChart } from '@app/v2/entities';
import { getScrollStyles } from '@theme/themeSettings/componentSettings';
import { useMeteoChart, useMeteoChartProfiles } from '@app/v2/shared/hooks';
import CSDCreateMeteoChart from '@app/v2/features/meteoChartConstructor/ui/CSDCreateMeteoChart/CSDCreateMeteoChart';

interface Props {
  stationId: number;
  roadTitle: string;
}

const MeteoChartTabContent = ({ stationId, roadTitle, t }: Props & I18N.TranslationFunction<'charts', 'meteoConstructor'>) => {
  const scrollPosition = useRef<number>(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { chartsSetup, dateTo, dateFrom, isForecast, handleSetMeteoChart, chartsData } = useMeteoChart();
  const { isActiveProfileCustom, meteoChartActiveProfileId, meteoChartsIds, amountOfCharts, handleGetMeteoChartById } = useMeteoChartProfiles();

  useEffect(() => {
    if (containerRef?.current) {
      containerRef.current.scrollTop = scrollPosition.current;
    }
  }, [chartsData]);

  const { data, loading } = useQuery<Meteo.ChartDataResponse, Meteo.ChartDataVariables>(meteoQueries.meteoGraph, {
    skip: !dateFrom && !dateTo,
    variables: {
      stationId,
      isForecast,
      dateFrom: formatEnFull(dateFrom),
      dateTo: formatEnFull(dateTo),
    },
  });

  useEffect(() => {
    if (!data) return;

    meteoChartsIds.forEach(id => {
      handleSetMeteoChart({ chartId: id });
    });
  }, [data, handleSetMeteoChart, meteoChartsIds]);

  if (!amountOfCharts) {
    return (
      <CSDCenteredBox sx={{ flexDirection: 'column', gap: '2rem' }}>
        <Typography variant="h2">{t('noCharts')}</Typography>
        <CSDCreateMeteoChart profileId={meteoChartActiveProfileId} chartsSetup={chartsSetup} />
      </CSDCenteredBox>
    );
  }

  const isShowContent = data?.meteoGraph && !!meteoChartsIds.length && !loading;

  return (
    <StyledWrapper
      ref={containerRef}
      direction="row"
      overflow="auto"
      id={DOMNodesIds.MeteoChartsContainer}
      onScroll={({ currentTarget }) => {
        scrollPosition.current = currentTarget.scrollTop;
      }}
    >
      {loading && (
        <>
          {getSkeletonItems(2).map(id => (
            <CSDMeteoChartSkeleton key={id} />
          ))}
        </>
      )}

      <>
        {isShowContent &&
          meteoChartsIds.map(id => (
            <CSDMeteoChart
              key={id}
              stationId={stationId}
              isActiveProfileCustom={isActiveProfileCustom}
              activeProfileId={meteoChartActiveProfileId}
              dataForChart={data.meteoGraph}
              roadTitle={roadTitle}
              meteoChartSetupId={id}
              meteoChartSetup={handleGetMeteoChartById(id)}
            />
          ))}
      </>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Stack)<StackProps>(({ theme: { palette } }) => ({
  flexWrap: 'wrap',
  padding: '1rem',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  gap: '1rem',
  overflow: 'auto',
  ...getScrollStyles(palette.mode, palette.text),
}));

export default withTranslation('charts', { keyPrefix: 'meteoConstructor' })(MeteoChartTabContent);
