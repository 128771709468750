import React from 'react';
import { Box, Icon, IconButton, Stack } from '@mui/material';
import CSDKitDocBlock from '@app/modules/kit-module/shared/ui/CSDKitDocBlock/CSDKitDocBlock';
import { CSDIconPanelPaper, CSDSwitcher } from '@app/modules/kit-module/shared/ui';
import { IconPanelPaperSwitcher, IconPanelPaperWithOneIcon, IconPanelPaperWithSomeIcons } from './examples';

const CSDAppBar = () => {
  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="Icon panel paper with one icon" codeExample={IconPanelPaperWithOneIcon}>
        <CSDIconPanelPaper>
          <IconButton>
            <Icon>save</Icon>
          </IconButton>
        </CSDIconPanelPaper>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Icon panel paper with some icons" codeExample={IconPanelPaperWithSomeIcons}>
        <CSDIconPanelPaper>
          {['save', 'done', 'affected', 'archive', 'bell', 'book', 'map'].map(iconName => (
            <IconButton key={iconName}>
              <Icon>{iconName}</Icon>
            </IconButton>
          ))}
        </CSDIconPanelPaper>
      </CSDKitDocBlock>

      <CSDKitDocBlock title="Icon panel paper with switchers" codeExample={IconPanelPaperSwitcher}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <CSDIconPanelPaper>
            <CSDSwitcher size="small" label="Видеостена" />
          </CSDIconPanelPaper>
          <CSDIconPanelPaper>
            <CSDSwitcher size="small" label="Фильтры" />
          </CSDIconPanelPaper>
        </Box>
      </CSDKitDocBlock>
    </Stack>
  );
};

export default CSDAppBar;
