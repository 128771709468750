import { useCallback, useRef } from 'react';
import debounce from 'lodash/debounce';
import { fetchFilters } from '@store/thunks/filters/filters-thunks';
import { DEBOUNCE_FILTER_INTERVAL } from '@app/v2/shared/constants';
import { StationTypes } from '@app/v2/shared/enums';
import useAppSelector from '../reactRedux/useAppSelector';
import useAppDispatch from '../reactRedux/useAppDispatch';

export default function useFiltersDictionary() {
  const dispatch = useAppDispatch();

  const fetchDictionary = useCallback(
    async (filters: Filters.Values, stationType?: StationTypes) => {
      await dispatch(fetchFilters(filters, stationType));
    },
    [dispatch],
  );

  const debounceFetchDictionary = useRef<(value: Filters.Values, stationType?: StationTypes) => void>(
    debounce(async (value: Filters.Values, stationType?: StationTypes) => {
      await fetchDictionary(value, stationType);
    }, DEBOUNCE_FILTER_INTERVAL),
  );

  return {
    ...useAppSelector(state => ({
      filterDictionary: state.filters.filterDictionary,
      isDictionaryLoading: state.filters.isDictionaryLoading,
    })),
    fetchDictionary,
    debounceFetchDictionary: debounceFetchDictionary.current,
  };
}
