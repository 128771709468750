import React from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, IconButton, Tooltip } from '@mui/material';
import { useMutation } from '@apollo/client';
import icons from '@app/assets/iconFont';
import { showSuccess } from '@app/core/utils/notifications';
import vmsNewMutations from '@app/clients/apollo/requests/mutations/vmsNew';
import { useDialog } from '@app/v2/shared/hooks';
import { CSDConfirmDialog } from '@app/modules/kit-module/shared/ui';
import { addFeatureActions } from '@app/v2/shared/utils';
import { DataTestIds } from '@app/v2/shared/enums';

const CSDDeletePlaylistBtn = ({
  id,
  t,
  ...featuresCbs
}: Pick<Scoreboards.Playlist, 'id'> & Common.FeaturesActions & I18N.TranslationFunction<'scoreboard'>) => {
  const openConfirmDialog = useDialog(CSDConfirmDialog);

  const [deletePlaylist, { loading }] = useMutation<Common.MutationResponse, Pick<Scoreboards.Playlist, 'id'>>(vmsNewMutations.playlist.delete);

  const handleDelete = () => {
    if (!id) return;

    openConfirmDialog({ question: t('playlists.actions.confirmationQuestionOnDelete') }).beforeClose.subscribe((answerToQuestion: boolean) => {
      if (!answerToQuestion) return;

      addFeatureActions(
        deletePlaylist({ variables: { id } }).then(({ data: { message } }) => showSuccess(message)),
        featuresCbs,
      );
    });
  };

  return (
    <Tooltip title={t('playlists.actions.delete')}>
      <IconButton onClick={handleDelete} disabled={loading} data-testid={DataTestIds.DeletePlaylistBtn}>
        <Icon>{icons.trash}</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default withTranslation('scoreboard')(CSDDeletePlaylistBtn);
