import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import RU from './sources/ru/translation.json';
import EN from './sources/en/translation.json';
import ES from './sources/es/translation.json';
import { DEFAULT_PROFILE_LANGUAGE } from '../enums/Languages';

export const defaultNS = 'index';
export const resources = { RU, EN, ES };

const languageDetector = new LanguageDetector(undefined, {
  order: ['localStorage', 'customNavigation'],
  caches: ['localStorage'],
});

languageDetector.addDetector({
  name: 'customNavigation',
  cacheUserLanguage: (lng, options) => {
    localStorage.setItem(options.lookupLocalStorage, lng);
  },
  lookup: () => {
    if (typeof window !== 'undefined') {
      const language = window?.navigator?.language;
      return language.slice(0, 2).toUpperCase();
    }
    return DEFAULT_PROFILE_LANGUAGE;
  },
});

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    fallbackLng: DEFAULT_PROFILE_LANGUAGE,
    ns: [
      'index',
      'favorites',
      'gidromed',
      'guide',
      'common',
      'kit',
      'log',
      'map',
      'meteo',
      'notification',
      'scoreboard',
      'services',
      'sops',
      'traffic',
      'video',
      'auth',
      'reports',
      'filters',
      'charts',
      'sidebar',
      'components',
      'constants',
      'dialogs',
      'router',
      'validation',
      'footer',
      'incidents',
      'features',
    ],
    resources,
    defaultNS,
  });

export default i18n;
