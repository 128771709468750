import { createContext } from 'react';
import { MarkerOptions, Marker, LatLngExpression } from 'leaflet';

interface CustomMarkerContext {
  latLng: LatLngExpression;
  options: MarkerOptions;
  setZIndexOffset: (offset: number) => Marker;
}

const useCustomMarkerContext = createContext<CustomMarkerContext>({
  latLng: [0, 0],
  options: null,
  setZIndexOffset: (offset: number) => null,
});

export default useCustomMarkerContext;
