import * as React from 'react';
import { Box, BoxProps } from '@mui/material';
import { FooterTabs } from '@app/v2/shared/enums';

export interface Props extends BoxProps {
  children?: React.ReactNode;
  name: FooterTabs;
  value: FooterTabs | false;
}

const TabPanelWrapper = ({ children, value, name, ...other }: Props, ref) => {
  return (
    <Box ref={ref} role="tabpanel" hidden={value !== name} id={`footer-panel-id-${name}`} aria-labelledby={`footer-panel-tab-${name}`} {...other}>
      {value === name && children}
    </Box>
  );
};

export default React.forwardRef(TabPanelWrapper);
