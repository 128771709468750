import React from 'react';
import { Badge, Icon, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import icons from '@app/assets/iconFont';
import { useChat } from '@app/v2/shared/hooks';
import { linearGradient } from '@theme/styles';
import CSDIconPanelPaper from '../CSDIconPanelpaper/CSDIconPanelPaper';

const CSDOpenChatBtn = () => {
  const classes = useStyles();

  const { isChatOpen, handleChangeChatStatus, amountOfUnreadMessages } = useChat();

  const getCurrentWidgetStyles = () => {
    if (isChatOpen) {
      return {
        background: linearGradient,
        padding: '0.5rem',

        '& .MuiIcon-root': {
          color: 'white',
        },
      };
    }

    return {
      padding: '0.5rem',
    };
  };

  return (
    <CSDIconPanelPaper wrapperCSSProperty={getCurrentWidgetStyles()}>
      <Badge className={classes.badge} badgeContent={amountOfUnreadMessages}>
        <IconButton onClick={() => handleChangeChatStatus(!isChatOpen)}>
          <Icon>{icons.messages}</Icon>
        </IconButton>
      </Badge>
    </CSDIconPanelPaper>
  );
};

const useStyles = makeStyles<Theme>({
  badge: {
    '& .MuiBadge-badge': {
      top: '3px',
      right: '3px',
      padding: 0,
      height: '10px',
      minWidth: '10px',
      background: '#439BFF',
      fontSize: '0.5rem',
    },
  },
});

export default CSDOpenChatBtn;
