const UTC = [
  { value: '-12:00', label: 'UTC-12' },
  { value: '-11:00', label: 'UTC-11' },
  { value: '-10:00', label: 'UTC-10' },
  { value: '-09:00', label: 'UTC-9' },
  { value: '-08:00', label: 'UTC-8' },
  { value: '-07:00', label: 'UTC-7' },
  { value: '-06:00', label: 'UTC-6' },
  { value: '-05:00', label: 'UTC-5' },
  { value: '-04:00', label: 'UTC-4' },
  { value: '-03:00', label: 'UTC-3' },
  { value: '-02:00', label: 'UTC-2' },
  { value: '-01:00', label: 'UTC-1' },
  { value: '+00:00', label: 'UTC-0' },
  { value: '+01:00', label: 'UTC+1' },
  { value: '+02:00', label: 'UTC+2' },
  { value: '+03:00', label: 'UTC+3' },
  { value: '+04:00', label: 'UTC+4' },
  { value: '+05:00', label: 'UTC+5' },
  { value: '+06:00', label: 'UTC+6' },
  { value: '+07:00', label: 'UTC+7' },
  { value: '+08:00', label: 'UTC+8' },
  { value: '+09:00', label: 'UTC+9' },
  { value: '+10:00', label: 'UTC+10' },
  { value: '+11:00', label: 'UTC+11' },
  { value: '+12:00', label: 'UTC+12' },
];

export default UTC;
