import React from 'react';
import { Box } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import CSDWaterLevelTooltip from '@app/modules/kit-module/shared/ui/CSDWaterLevelTooltip/CSDWaterLevelTooltip';

interface Props<Options extends Record<string, unknown>> {
  options: Options;
  className?: string;
  classes?: Record<string, unknown>;
  chartsData?: WLC.ChartPointParameters;
  onCloseTooltip?: Common.VoidCallBack;
  openDialog?: Common.VoidCallBack;
}

const CSDWaterLevelChart = <Options extends Record<string, unknown>>({
  options,
  classes,
  className,
  chartsData,
  onCloseTooltip,
  openDialog,
}: Props<Options>) => {
  return (
    <Box
      className={className}
      id="testsssss"
      sx={{
        height: '100%',
        width: '100%',
        position: 'relative',
        ...classes,
      }}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        immutable
        containerProps={{
          style: {
            height: '100%',
            width: '100%',
          },
        }}
      />
      {chartsData?.isFixed && <CSDWaterLevelTooltip {...chartsData} onClose={onCloseTooltip} openDialog={openDialog} />}
    </Box>
  );
};

export default CSDWaterLevelChart;
