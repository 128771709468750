import { ProfileMapSettingLabels } from '@app/v2/shared/enums';
import { CheckBoxes, Item, Profile } from '@app/core/types/profiles/map';
import LayerTypes from '@app/modules/map-module/enums/LayerTypes';
import DataLoadingTypes from '@app/modules/map-module/enums/DataLoadingTypes';
import { getInitialLoadingStateByTypes } from '@app/core/helpers/changeMapState';
import getViewportData from '@app/core/helpers/getViewportData';
import getMarkerZooms from '@app/core/helpers/getMarkerZooms';
import { showError } from '@app/core/utils/notifications';
import { State } from '@store/slices/map/MapState';

const makeActiveState = (state, activeProfile: Profile): State => {
  try {
    return {
      ...state,
      activeProfile,
      ...getCheckBoxesNewMapSettingByKey(state, ProfileMapSettingLabels.SETTINGS, activeProfile),
      ...getCheckBoxesNewMapSettingByKey(state, ProfileMapSettingLabels.ROAD_OBJECTS, activeProfile),
      ...getCheckBoxesNewMapSettingByKey(state, ProfileMapSettingLabels.GIS, activeProfile),
      ...getCheckBoxesNewMapSettingByKey(state, ProfileMapSettingLabels.NOTES, activeProfile),
      [ProfileMapSettingLabels.ACTIVE_LAYER.toString()]: activeProfile
        ? activeProfile.items[ProfileMapSettingLabels.ACTIVE_LAYER].settings
        : LayerTypes.YANDEX_MAP,
      ...getWeatherRadars(state, activeProfile as Profile<{ [key: number]: WeatherLocators.LocatorControl }>),
      coordinates: {
        staticObjects: [],
        roadObjects: [],
        roadSegments: [],
        weatherRadarsImages: [],
        notes: [],
      },
      loadings: {
        ...getInitialLoadingStateByTypes(DataLoadingTypes),
        WEATHER_RADARS_IMAGES: { load: false },
      },
      viewport: getViewportData(activeProfile?.items[ProfileMapSettingLabels.MAP_LOCATION]?.settings),
      markerZooms: getMarkerZooms(activeProfile?.items[ProfileMapSettingLabels.MARKER_ZOOMS]?.settings),
    };
  } catch (e) {
    if (e instanceof Error) {
      showError(e.message);
    } else {
      throw e;
    }
    return state;
  }
};

type Setting<T> = {
  [key in ProfileMapSettingLabels]?: T;
};

// todo change activeProfile.items?.locatorImages?.settings from [] to {} !!!!!!!!

const getWeatherRadars = (
  state: State,
  activeProfile: Profile<{ [key: number]: WeatherLocators.LocatorControl } | WeatherLocators.LocatorControl[]>,
): Setting<{ controls: { [key: number]: WeatherLocators.LocatorControl } }> => {
  const locatorImagesSettings = activeProfile?.items.locatorImages.settings;

  if (Array.isArray(locatorImagesSettings) && locatorImagesSettings.length === 0) {
    return {
      [ProfileMapSettingLabels.WEATHER_RADARS]: {
        controls: {},
      },
    };
  }

  if (Array.isArray(locatorImagesSettings) && locatorImagesSettings.length !== 0) {
    return {
      [ProfileMapSettingLabels.WEATHER_RADARS]: {
        controls: locatorImagesSettings.map(item => ({ [item.id]: item })),
      },
    };
  }

  return {
    [ProfileMapSettingLabels.WEATHER_RADARS]: {
      controls: locatorImagesSettings,
    },
  };
};

const getCheckBoxesNewMapSettingByKey = (state: State, label: keyof State, activeProfile: Profile) => {
  return {
    [label]: {
      ...Object.fromEntries(Object.entries(state[label]).map(([key]) => [key, { checked: false }])),
      ...(activeProfile && isCheckBoxes(activeProfile.items, label, activeProfile.items[label]?.settings) ? activeProfile.items[label].settings : {}),
    },
  };
};

const isCheckBoxes = (items: { [key in ProfileMapSettingLabels]: Item }, label: keyof State, setting: any): setting is CheckBoxes => {
  return !!Object.hasOwnProperty.call(items, label);
};

export default makeActiveState;
