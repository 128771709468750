import { useCallback } from 'react';
import DataLoadingTypes from '@app/modules/map-module/enums/DataLoadingTypes';
import { StateMapLoadingObject } from '@store/slices/map/MapState';
import { useActions, useAppSelector } from '@app/v2/shared/hooks';

const useMapDataLoading = (): [StateMapLoadingObject<DataLoadingTypes>, (type: DataLoadingTypes, status: boolean) => void] => {
  const { setMapDataLoader } = useActions();

  const setDataMapLoading = useCallback(
    (type: DataLoadingTypes, status: boolean) => {
      setMapDataLoader({ type, status });
    },
    [setMapDataLoader],
  );

  const loadings = useAppSelector(state => state.map.loadings);

  return [loadings, setDataMapLoading];
};

export default useMapDataLoading;
