import { useEffect, useState } from 'react';
import intersectionBy from 'lodash/intersectionBy';
import difference from 'lodash/difference';
import differenceBy from 'lodash/differenceBy';
import DataSource from '@app/core/source/DataSource';
import useTableMode from '@app/core/source/hooks/useTableMode';
import usePaginationSearch from '@app/core/source/hooks/pagination/usePaginationSearch';
import { DEFAULT_UPDATE_PERIOD, LISTING_DEFAULT_PAGE_NUMBER } from '@app/core/constants';
import { useAccount, useAppSelector, useNewFilters, useSorting, useSystem } from '@app/v2/shared/hooks';
import usePagination from '@app/core/source/hooks/pagination/usePagination';
import { TableMode } from '@app/v2/shared/enums';

export default function useDataSource<T>(dataSource: DataSource<T>) {
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [nextPageLoading, setNextPageLoading] = useState<boolean>(false);

  const { filters, isEnabled, toggleFiltersEnabledState } = useNewFilters();
  const { sortingValue } = useSorting();
  const [paginationSearch, setPaginationSearch] = usePaginationSearch();
  const [, setPagination] = usePagination();
  const [tableMode] = useTableMode();
  const { toggleIsListingLoaded } = useSystem();
  const { isAccountTimeSettingsUpdated } = useAccount();
  const [data, setData] = useState<T[]>([]);

  const allElementsWithTime = useAppSelector(state => state.viewTableElements.allElementsWithTime);
  const currentElementsIdsInViewPort = useAppSelector(state => state.viewTableElements.currentElementsIdsInViewPort);

  useEffect(() => {
    const loadingObserver = dataSource.loading.subscribe((value: boolean) => {
      if (!data.length) setPageLoading(value);
      else setNextPageLoading(value);
    });

    return () => {
      loadingObserver.unsubscribe();
    };
  }, [data.length, dataSource.loading]);

  useEffect(() => {
    const addNecessaryDataElement = (currentData: T[], responseData: T[]): T[] => {
      const updateDataArr: T[] = intersectionBy(responseData, currentData, 'id');
      const pushDataArr: T[] = differenceBy(responseData, updateDataArr, 'id');

      if (tableMode === TableMode.Scroll && paginationSearch.page > LISTING_DEFAULT_PAGE_NUMBER) {
        return [...currentData, ...pushDataArr];
      }

      return [...updateDataArr, ...pushDataArr];
    };

    const dataObserver = dataSource.data.subscribe(({ data: result, total }) => {
      const prepareData = (storedData): T[] => {
        if (result.length === paginationSearch.limit) return storedData;

        return storedData.map(item => {
          const dataElement = result.find(({ id }) => id === item.id);
          return dataElement || item;
        });
      };

      if (tableMode === TableMode.Pagination) {
        setPagination({ total });
        setData(result);

        return;
      }

      setData(prev => addNecessaryDataElement(prepareData(prev), result));
      setPagination({ total });

      if (total && Math.ceil(total / paginationSearch.limit) < paginationSearch.page) {
        setPaginationSearch(prev => ({
          ...prev,
          page: LISTING_DEFAULT_PAGE_NUMBER,
        }));
      }
    });

    return () => {
      dataObserver.unsubscribe();
    };
  }, [dataSource.data, paginationSearch, setPagination, setPaginationSearch, tableMode]);

  useEffect(() => {
    // TODO should change behavior
    toggleIsListingLoaded({ status: !pageLoading && !!data.length });
  }, [data, pageLoading, toggleIsListingLoaded]);

  useEffect(() => {
    if (tableMode === TableMode.Pagination && data.length > paginationSearch.limit) {
      dataSource.data.next({ data: data.slice(-paginationSearch.limit), total: paginationSearch.total });
    }
  }, [data, dataSource, paginationSearch, tableMode]);

  useEffect(() => {
    dataSource.pagination.next({ ...paginationSearch, tableMode });
  }, [paginationSearch, dataSource.pagination, tableMode]);

  useEffect(() => {
    const ids = Object.values(allElementsWithTime)
      .filter(({ time }) => Date.now() - time > DEFAULT_UPDATE_PERIOD)
      .map(({ id }) => +id);

    const viewportIds = ids.filter(item => currentElementsIdsInViewPort.map(i => +i.id).includes(item));

    if (difference(viewportIds, dataSource.currentTableElementsIds.value).length && !dataSource.filters.value?.alertStatus?.length) {
      dataSource.currentTableElementsIds.next(viewportIds);
    }
  }, [
    tableMode,
    allElementsWithTime,
    currentElementsIdsInViewPort,
    dataSource.currentTableElementsIds,
    dataSource.filters.value?.alertStatus?.length,
  ]);

  useEffect(() => {
    if (!isEnabled) return;
    dataSource?.filters?.next(filters);
    toggleFiltersEnabledState(false);
  }, [filters, dataSource?.filters, isEnabled, toggleFiltersEnabledState]);

  useEffect(() => {
    if (!isAccountTimeSettingsUpdated) return;
    dataSource.load();
  }, [dataSource, isAccountTimeSettingsUpdated]);

  useEffect(() => {
    dataSource.sorting.next(sortingValue);
  }, [dataSource, sortingValue]);

  return {
    data,
    loading: pageLoading,
    nextPageLoading,
  };
}
