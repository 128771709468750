import { DATE_PERIOD, DATE_PERIOD_IN_SECONDS } from '@app/v2/shared/constants';
import WWOWeatherTypes from '@app/modules/map-module/enums/WeatherTypes';

export const WEATHER_MAP_STEP_COUNT = 1;
export const WEATHER_MAP_STEP_DATE = WEATHER_MAP_STEP_COUNT * DATE_PERIOD_IN_SECONDS.HOUR;
export const WEATHER_MAP_MAX_DATE = 104 * DATE_PERIOD_IN_SECONDS.HOUR;
export const WEATHER_MAP_PLAY_INTERVAL = 3 * DATE_PERIOD.SECOND;
export const AUTO_UPDATE_MAP_INTERVAL = 15 * DATE_PERIOD.MINUTE;
export const MIN_MAP_ZOOM = 4;
export const MAX_MAP_ZOOM = 19;
export const MAX_BOUNDS_VISCOSITY = 1.0;
export const MAX_MAP_BOUNDS = [
  [-84, -180],
  [84, 180],
];

export const LegendsProperties = {
  [WWOWeatherTypes.Temperature]: {
    values: ['°C', 50, 40, 30, 25, 20, 15, 10, 5, 0, -5, -10, -15, -20, -30, -40],
    gradient:
      'linear-gradient(90deg,#000,#664159 11.1%,#bc613d 22.2%,#c6b64f 33.3%,#5a9935 44.4%,#4d85c3 55.5%,#6dc8c2 66.6%,#8e76ff 77.7%,#5b63e8 88.8%,#4633f4),#4f4f4f',
  },
  [WWOWeatherTypes.Precipitations]: {
    values: ['mm', 0, 10, 20, 30],
    gradient: 'linear-gradient(90deg,#000,#828282 25%,#459bb5 50%,#93b645 65%,#b64648 75%,#b645ae),#4f4f4f',
  },
  [WWOWeatherTypes.Humidity]: {
    values: ['%', 0, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    gradient: 'linear-gradient(90deg,#000,red 12.5%,orange 25%,#ffff3c 37.5%,#7fff00 50%,#32cd32 62.5%,#228b22 75%,#006400 87.5%,#0000cd),#4f4f4f',
  },
  [WWOWeatherTypes.Pressure]: {
    values: ['mBar', 950, 990, 1030, 1070, 1110],
    gradient: 'linear-gradient(90deg,#000,red 20%,#ff0 40%,#f0f 60%,#0ff),#4f4f4f',
  },
  [WWOWeatherTypes.Cloud]: {
    values: ['%', 0, 30, 50, 70, 100],
    gradient: 'linear-gradient(90deg,#fff,#5479a4 20%,#8ca1ba 40%,#b7bfc8 60%,#ddd 80%,#fff),#4f4f4f',
  },
  [WWOWeatherTypes.Visibility]: {
    values: ['km', 0, 2, 4, 6, 8, 10, 12, 14, 16, 20],
    gradient:
      'linear-gradient(90deg,#000,#dcdcdc 10%,#d3d3d3 20%,silver 30%,#a9a9a9 40%,gray 50%,#696969 60%,#789 70%,#708090 80%,#2f4f4f 90%,#000),#4f4f4f',
  },
};

export const PANE_NAME_FOR_WIND_LAYER = 'shadow';

export const MAP_ICON_ROUNDED_SIGN = 1;

export const velocityLayerOptions = {
  displayValues: false,
  displayOptions: {
    velocityType: 'Global Wind',
    position: 'bottomleft',
    emptyString: 'No water data',
  },
  colorScale: ['#000'],
  maxVelocity: 15,
  paneName: PANE_NAME_FOR_WIND_LAYER,
};
