import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DialogRef } from '@app/core/providers/DialogProvider';
import AccountTabs from './tabs/AccountTabs';

const AccountSettingsDialog = ({ close }: DialogRef) => {
  const classes = useStyles();

  const handleClose = React.useCallback(() => {
    close();
  }, [close]);

  return (
    <Dialog open maxWidth="md" fullWidth scroll="paper" onClose={handleClose}>
      <DialogContent classes={{ root: classes.rootMuiDialogContent }}>
        <AccountTabs close={handleClose} />
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  rootMuiDialogContent: {
    height: '90vh',
  },
}));

export default AccountSettingsDialog;
