import { KitComponentsConfigItem } from './types';
import CSDAutocompletes from '../../ui/CSDAutocompletes/CSDAutocompletes';
import CSDButtons from '../../ui/CSDButtons/CSDButtons';
import CSDDefaultKit from '../../ui/CSDDefaultKit/CSDDefaultKit';
import CSDCheckboxes from '../../ui/CSDCheckboxes/CSDCheckboxes';
import CSDSliders from '../../ui/CSDSliders/CSDSliders';
import CSDSwitches from '../../ui/CSDSwitches/CSDSwitches';
import CSDSelects from '../../ui/CSDSelects/CSDSelects';
import CSDTextFields from '../../ui/CSDTextFields/CSDTextFields';
import CsdTooltips from '../../ui/CSDTooltips/CSDTooltips';
import CSDTypographies from '../../ui/CSDTypographies/CSDTypographies';
import CSDAvatars from '../../ui/CSDAvatars/CSDAvatars';
import CSDChips from '../../ui/CSDChips/CSDChips';
import CSDDividers from '../../ui/CSDDividers/CSDDividers';
import CSDBadges from '../../ui/CSDBadges/CSDBadges';
import CSDTables from '../../ui/CSDTables/CSDTables';
import CSDAlerts from '../../ui/CSDAlerts/CSDAlerts';
import CSDSnackbars from '../../ui/CSDSnackbars/CSDSnackbars';
import CSDDialogs from '../../ui/CSDDialogs/CSDDialogs';
import CSDCards from '../../ui/CSDCards/CSDCards';
import CSDAppBar from '../../ui/CSDAppBar/CSDAppBar';
import CSDNotFromMUIDocs from '../../ui/CSDNotFromMUIDocs/CSDNotFromMUIDocs';
import CSDIcons from '../../ui/CSDIcons/CSDIcons';
import CSDTabs from '../../ui/CSDTabs/CSDTabs';
import CSDDatePickersMUI from '../../ui/CSDDatePickersMUI/CSDDatePickersMUI';
import CSDDatePickersAntd from '../../ui/CSDDatePikersAntd/CSDDatePickersAntd';
import CSDPieDiagram from '../../ui/CSDPieDiagram/CSDPieDiagram';
import CSDDynamicRoadInterface from '../../ui/CSDDynamicRoadInterface/CSDDynamicRoadInterface';
import { KitComponents, KitRoutes } from '../../types/kitComponents';

const KitRouterConfig: KitComponentsConfigItem[] = [
  { path: KitRoutes.default, label: KitComponents.Default, component: CSDDefaultKit },
  { path: KitRoutes.buttons, label: KitComponents.Buttons, component: CSDButtons },
  { path: KitRoutes.checkboxes, label: KitComponents.Buttons, component: CSDCheckboxes },
  { path: KitRoutes.sliders, label: KitComponents.Sliders, component: CSDSliders },
  { path: KitRoutes.switches, label: KitComponents.Switches, component: CSDSwitches },
  { path: KitRoutes.autocompletes, label: KitComponents.Autocompletes, component: CSDAutocompletes },
  { path: KitRoutes.selects, label: KitComponents.Selects, component: CSDSelects },
  { path: KitRoutes.textFields, label: KitComponents.TextFields, component: CSDTextFields },
  { path: KitRoutes.avatars, label: KitComponents.Tooltips, component: CSDAvatars },
  { path: KitRoutes.badges, label: KitComponents.Tooltips, component: CSDBadges },
  { path: KitRoutes.chips, label: KitComponents.Chips, component: CSDChips },
  { path: KitRoutes.dividers, label: KitComponents.Chips, component: CSDDividers },
  { path: KitRoutes.tables, label: KitComponents.Tooltips, component: CSDTables },
  { path: KitRoutes.tooltips, label: KitComponents.Tooltips, component: CsdTooltips },
  { path: KitRoutes.typographies, label: KitComponents.Tooltips, component: CSDTypographies },
  { path: KitRoutes.alerts, label: KitComponents.Alerts, component: CSDAlerts },
  { path: KitRoutes.snackbar, label: KitComponents.Snackbars, component: CSDSnackbars },
  { path: KitRoutes.dialogs, label: KitComponents.Dialogs, component: CSDDialogs },
  { path: KitRoutes.cards, label: KitComponents.Cards, component: CSDCards },
  { path: KitRoutes.appBar, label: KitComponents.AppBar, component: CSDAppBar },
  { path: KitRoutes.tabs, label: KitComponents.Tabs, component: CSDTabs },
  { path: KitRoutes.notFromMUIDocs, label: KitComponents.NotFromMUIDocs, component: CSDNotFromMUIDocs },
  { path: KitRoutes.icons, label: KitComponents.Icons, component: CSDIcons },
  { path: KitRoutes.datePickersMUI, label: KitComponents.DatePickersMUI, component: CSDDatePickersMUI },
  { path: KitRoutes.datePickersAntd, label: KitComponents.DatePickersAntd, component: CSDDatePickersAntd },
  { path: KitRoutes.pieCharts, label: KitComponents.PieCharts, component: CSDPieDiagram },
  { path: KitRoutes.dynamicRoadInterface, label: KitComponents.DynamicRoadInterface, component: CSDDynamicRoadInterface },
];

export default KitRouterConfig;
