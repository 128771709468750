import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import client from '@app/clients/apollo/client';
import tokenStorage from '@app/clients/apollo/tokenStorage';
import AuthModule from '@app/modules/auth-module';
import { DialogProvider } from '@app/core/providers/DialogProvider';
import { PublicPath } from '@app/v2/shared/enums';

const Authorization = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();

  useEffect(() => {
    if (pathname.includes(PublicPath.Confirmation) && !tokenStorage.isExistRefreshToken) {
      push(PublicPath.Auth);
    }
  }, [pathname, push]);

  return (
    <DialogProvider>
      <>
        <Switch>
          <Route path={PublicPath.Auth} exact render={() => AuthModule.login(client)} />
          <Route path={PublicPath.Confirmation} exact render={() => tokenStorage.isExistRefreshToken && AuthModule.confirmation()} />
          <Route path={PublicPath.Recovery} exact render={() => AuthModule.passwordRecovery()} />
          <Route path={PublicPath.PasswordRecovery} render={() => AuthModule.passwordRecoveryLink()} />
          <Redirect to={PublicPath.Auth} />
        </Switch>
      </>
    </DialogProvider>
  );
};

export default Authorization;
