import { createTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles/createTheme';
import paletteColorsSettings from '@theme/themeSettings/paletteColorsSettings';
import typographySettings from '@theme/themeSettings/typographySettings';
import componentSettings from '@theme/themeSettings/componentSettings';
import { ThemeMode } from '@theme/types';
import isLightTheme from '@app/core/helpers/isLightTheme';

const themeCreator = (themeMode: ThemeMode = ThemeMode.light): Theme => {
  return createTheme({
    breakpoints: {
      values: {
        xs: 600,
        sm: 900,
        md: 1200,
        lg: 1536,
        xl: 1921,
      },
    },
    palette: {
      mode: themeMode,
      primary: {
        main: '#072F9C',
        contrastText: isLightTheme(themeMode) ? '#000' : '#fff',
      },
      secondary: {
        main: '#3245AF',
        dark: '#3245AF',
        contrastText: isLightTheme(themeMode) ? '#000' : '#fff',
      },
      ...paletteColorsSettings[themeMode],
      error: {
        main: '#CE2E2E',
      },
      warning: {
        main: '#FFDB5B',
      },
      success: {
        main: '#00A151',
      },
      tableColors: {
        ok: '#4AE83D30',
        error: '#CD001529',
        warning: '#F7F5CF',
        notEnoughData: '#92929230',
        unavailable: '#F5F9FF0D',
        unpaid: '#FFF',
        selected: '#6fbf73',
      },
      forecastColors: {
        primary: '#0043B1',
        secondary: '#0BBCFF',
      },
      technicalColors: {
        yellow: '#FFDB5B',
        red: '#CE2E2E',
        green: '#00A151',
      },
      chartsColors: {
        c1: '#7A52CC',
        c2: '#F76707',
        c3: '#4C6EF4',
        c4: '#EE5557',
        c5: '#0DBF67',
        c6: '#99D430',
        c7: '#22B8D0',
        c8: '#FCC419',
        c9: '#C2255C',
      },
      common: {
        black: '#000',
        white: '#fff',
      },
    },
    typography: typographySettings,
    components: componentSettings,
  });
};

export default themeCreator;
