import React, { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useVideoPost } from '@app/v2/shared/hooks';
import { VideoStationMode } from '@app/v2/shared/enums';
import { CSDRevertTabs } from '@app/modules/kit-module/shared/ui';
import { prepareStationsTabs } from '@app/v2/shared/helpers';
import VideoTabSettingsPanel from './VideoTabSettingsPanel';
import VideoTabContent from './VideoTabContent';
import type { StationsProps } from '../../../types';

const VideoTab = ({
  activeStationId,
  listOfStationsIdOfActiveTab: videoIds,
  roadLines,
  videoStations,
  directionTitles,
  setActiveStationID,
  activeStationID,
}: StationsProps) => {
  const { t } = useTranslation('dialogs', { keyPrefix: 'post' });

  const { contentMode } = useVideoPost();

  const stationId = useMemo(() => activeStationId || videoIds[0], [activeStationId, videoIds]);
  const stationTabs = prepareStationsTabs({ ids: videoIds, prefixTabLabel: t('camera') });

  useEffect(() => {
    setActiveStationID(stationId);
  }, [setActiveStationID, stationId]);

  return (
    <>
      <VideoTabSettingsPanel />

      {contentMode === VideoStationMode.VIDEO_MODE && stationTabs.length > 1 && (
        <CSDRevertTabs
          tabs={stationTabs}
          activeTabId={activeStationID}
          onChange={id => {
            setActiveStationID(Number(id));
          }}
        />
      )}

      <VideoTabContent
        stationId={activeStationID}
        roadLines={roadLines}
        directionTitles={directionTitles}
        videoStations={videoStations}
        setActiveStationID={setActiveStationID}
      />
    </>
  );
};

export default memo(VideoTab);
