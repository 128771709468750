import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FooterStylesParameters } from '../../types/footerStylesParameters';

const PROPS_TO_EXCLUDE = ['containerHeight', 'hasPreview', 'isHeaderRollUp', 'open', 'isTabSelected'];

const StyledFooterPanelContainer = styled(Box, {
  shouldForwardProp: propName => !PROPS_TO_EXCLUDE.includes(propName as string),
})<BoxProps & Pick<FooterStylesParameters, 'containerHeight' | 'hasPreview' | 'isHeaderRollUp' | 'open' | 'isTabSelected'>>(
  ({ theme, containerHeight, hasPreview, isHeaderRollUp, open, isTabSelected }) => ({
    boxSizing: 'border-box',
    background: theme.palette.background.default,
    height: open ? `${containerHeight}px` : '0',
    minHeight: hasPreview && isTabSelected && isHeaderRollUp ? '72px' : '0',
    transition: open
      ? theme.transitions.create(['height'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        })
      : theme.transitions.create(['height'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
  }),
);

export default StyledFooterPanelContainer;
