export const InfoBadge = `<CSDInfoBadge isViewInfoBadgeWithForecast={false} alertIndexValue={10.12} loading={false} />
<CSDInfoBadgeWidthIndicators isViewInfoBadgeWithForecast alertIndexValue={4.5} alertIndexForecast={alertIndexForecast} loading={false} />`;

export const IconPanelPaperWithOneIcon = `<CSDIconPanelPaper>
  <IconButton>
    <Icon>save</Icon>
  </IconButton>
</CSDIconPanelPaper>`;

export const IconPanelPaperWithSomeIcons = `<CSDIconPanelPaper>
  {['save', 'done', 'affected', 'archive', 'bell', 'book', 'map'].map(iconName => (
    <IconButton key={iconName}>
      <Icon>{iconName}</Icon>
    </IconButton>
  ))}
</CSDIconPanelPaper>`;

export const IconPanelPaperSwitcher = `<CSDIconPanelPaper>
  <CSDSwitcher size="small" label="Видеостена" />
</CSDIconPanelPaper>
<CSDIconPanelPaper>
  <CSDSwitcher size="small" label="Фильтры" />
</CSDIconPanelPaper>`;
