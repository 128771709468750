import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import { useFormikContext } from 'formik';
import { CSDAutocompleteFastField, CSDInputFastField } from '@app/modules/kit-module/shared/ui';
import { boxShadowLight } from '@theme/styles';
import { formInitialValue } from '@app/modules/scoreboard-module/playlists/form/values';
import { UsersAccess } from '@app/v2/shared/enums';
import { useCheckAccess } from '@app/v2/shared/hooks';

interface Props {
  organizationSelectors: Common.SelectorValues;
}

const CSDCommonForm = ({ organizationSelectors }: Props) => {
  const classes = useStyles();

  const { values, resetForm } = useFormikContext<Scoreboards.PlaylistModel>();

  const { t } = useTranslation('scoreboard');
  const { accessChecker } = useCheckAccess();

  return (
    <Box className={classes.wrapper}>
      <CSDInputFastField
        path="title"
        textFieldProps={{
          placeholder: t('playlists.form.placeholder.chooseMessageTitle'),
          label: t('playlists.form.chooseMessageTitle'),
        }}
      />

      <CSDAutocompleteFastField
        path="organizationId"
        disabled={!accessChecker([UsersAccess.ROOT])}
        autocompleteProps={{
          inputProps: {
            placeholder: t('playlists.form.placeholder.chooseOrganization'),
          },
          label: t('playlists.form.chooseOrganization'),
          autocompleteProps: {
            multiple: false,
            options: organizationSelectors ?? [],
            setValue: (_, newValue) => {
              resetForm({
                values: {
                  ...values,
                  organizationId: newValue?.value,
                  playlist: values.playlist.map(item => ({
                    ...item,
                    template: {
                      ...item.template,
                      ...formInitialValue.template,
                      configuration: item.template.configuration,
                    },
                  })),
                },
              });
            },
          },
        }}
      />
    </Box>
  );
};

const useStyles = makeStyles<Theme>(({ palette }) => ({
  wrapper: {
    gap: '1rem',
    display: 'flex',
    padding: '1rem',
    borderRadius: '1rem',
    flexDirection: 'column',
    boxShadow: boxShadowLight,
    border: `1px solid ${palette.text.t5}`,
  },
}));

export default CSDCommonForm;
