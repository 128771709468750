import React from 'react';
import CircularProgressMaterial from '@mui/material/CircularProgress';

type Props = {
  color?: 'inherit' | 'primary' | 'secondary';
  size?: number;
};

const CircularProgress = ({ color = 'inherit', size }: Props) => (
  <CircularProgressMaterial style={{ marginRight: 2 }} size={size} color={color} disableShrink />
);

export default CircularProgress;
