import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import { Formik, FormikProps } from 'formik';
import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { showError, showSuccess } from '@app/core/utils/notifications';
import Schema from '@app/modules/scoreboard-module/playlists/data/Schema';
import PlaylistsMainForm from '@app/modules/scoreboard-module/playlists/form/PlaylistsMainForm';
import StatusCodes from '@app/core/constants/statusCodes';
import vmsNewQueries from '@app/clients/apollo/requests/queries/vmsNew';
import vmsNewMutations from '@app/clients/apollo/requests/mutations/vmsNew';
import { CSDDialogActions, CSDRevertTabs } from '@app/modules/kit-module/shared/ui';
import { CSDEmptySearch } from '@app/v2/shared/ui/custom';
import type { StationsProps, StationsTabsType } from '../../../types';

const PlaylistTab = ({ playlistsIds, close, setActiveStationID, listOfStationsIdOfActiveTab, activeStationId }: StationsProps) => {
  const classes = useStyles();
  const formik = useRef<FormikProps<any>>();
  const { t } = useTranslation('scoreboard');
  const { t: commonT } = useTranslation('common', { keyPrefix: 'errors' });

  const listPlaylists = useMemo(
    () =>
      listOfStationsIdOfActiveTab.map((id, index) => ({
        playlistId: playlistsIds[index],
        id,
        label: `${t('singsAndScoreboard.headers.playlist')} ${index + 1}`,
      })),
    [listOfStationsIdOfActiveTab, playlistsIds, t],
  );

  const initialActivePlaylistTab = useMemo(
    () => listPlaylists.find(({ id }) => id === activeStationId) || listPlaylists[0],
    [activeStationId, listPlaylists],
  );

  const [activePlaylistTab, setActivePlaylistTab] = useState<StationsTabsType>(initialActivePlaylistTab);
  const [activePlaylistData, setActivePlaylistData] = useState<Scoreboards.PlaylistModel>(null);

  useEffect(() => {
    setActiveStationID(activePlaylistTab.id);
  }, [setActiveStationID, activePlaylistTab.id]);

  const skipRequestCondition = !activePlaylistTab.playlistId;

  const handleQueryCompleted = data => {
    setActivePlaylistData(data?.vmsNew?.playlist);
  };

  const handleQueryError = () => {
    showError(commonT('commonError'));
  };

  const { loading } = useQuery<Scoreboards.PlaylistModelResponse, Scoreboards.PlaylistVariables>(vmsNewQueries.playlist, {
    variables: {
      id: activePlaylistTab?.playlistId,
    },
    skip: skipRequestCondition,
    onCompleted: handleQueryCompleted,
    onError: handleQueryError,
  });

  const [updatePlaylist] = useMutation(vmsNewMutations.playlist.update);

  const onSubmit = async (formValue: Scoreboards.PlaylistModel): Promise<void> => {
    const { id, title: playlistTitle, playlist, organizationId }: any = Schema.validateSync(formValue);

    const preparedPlaylistData = playlist.map(playlistItem => ({ ...playlistItem, template: { id: playlistItem?.template?.id } }));

    try {
      const { data: mutationData } = await updatePlaylist({
        variables: {
          id,
          title: playlistTitle,
          organizationId,
          playlist: {
            content: { playlist: preparedPlaylistData },
          },
        },
      });

      const { status, message } = mutationData?.vmsNew?.playlist?.update || {};

      if (status === StatusCodes.UPDATE_SUCCESSFUL_204) {
        showSuccess(message);
      }
      close('onClose');
    } catch (e) {
      if (e instanceof Error) {
        showError(e.message);
      } else {
        throw e;
      }
    }
  };

  if (skipRequestCondition) return <CSDEmptySearch message={commonT('NOT_FOUND')} />;

  return (
    <Box className={classes.wrapper}>
      {listPlaylists.length > 1 && (
        <CSDRevertTabs
          tabs={listPlaylists}
          activeTabId={activePlaylistTab.id}
          onChange={nextId => {
            setActivePlaylistTab(listPlaylists.find(({ id }) => id === Number(nextId)));
          }}
        />
      )}

      {!loading && (
        <Box className={classes.content}>
          {activePlaylistData && !loading && (
            <Formik
              innerRef={formik}
              onSubmit={onSubmit}
              initialValues={activePlaylistData}
              validationSchema={Schema}
              component={PlaylistsMainForm}
            />
          )}

          {!activePlaylistData && !loading && <div className={classes.noDataContent}>{t('singsAndScoreboard.headers.playlistNotAdded')}</div>}

          <CSDDialogActions
            doneHandler={() => {
              formik.current.handleSubmit();
            }}
            closeHandler={() => {
              close();
            }}
          />
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles<Theme>({
  wrapper: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    maxHeight: '100%',
    overflow: 'hidden',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',

    '& form': {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      width: '100%',
    },
  },
  noDataContent: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '1.5rem',
  },
  emptyMessage: {
    fontSize: '2rem',
    padding: '27%',
  },
  formWrapper: {
    padding: 15,
    marginBottom: 10,
  },
});

export default PlaylistTab;
