/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import { LISTING_DEFAULT_PAGE_LIMIT } from '@app/core/constants';
import { ReducersNames } from '@store/constants';
import { TableMode } from '@app/v2/shared/enums';

type State = {
  isDictionaryLoading: boolean;
  mode: TableMode;
  pagination: Common.Pagination;
  filterDictionary: Filters.Dictionary;
};

export const initialState: State = {
  filterDictionary: {
    organizations: [],
    roads: [],
    places: [],
  },
  isDictionaryLoading: false,
  pagination: {
    total: 0,
    limit: LISTING_DEFAULT_PAGE_LIMIT,
    page: 1,
  },
  mode: TableMode.Scroll,
};

const filtersSlice = createSlice({
  name: ReducersNames.filters,
  initialState,
  reducers: {
    setFilterDictionary(state: State, { payload }: PayloadAction<Filters.Dictionary>) {
      state.filterDictionary = payload;
    },
    setQueryPaginationParams(state: State, { payload }: PayloadAction<Partial<Common.Pagination>>) {
      if (state.pagination.page !== payload.page || state.pagination.limit !== payload.limit) {
        state.pagination = { ...state.pagination, page: payload.page, limit: payload.limit };
      }
    },
    setPagination(state: State, { payload }: PayloadAction<Partial<Common.Pagination>>) {
      if (Object.keys(payload).some(key => !isEqual(state.pagination[key], payload[key]))) {
        state.pagination = { ...state.pagination, ...payload };
      }
    },
    setTableMode(state: State, { payload }: PayloadAction<TableMode>) {
      if (state.mode !== payload) {
        state.mode = payload;
      }
    },
    setDictionaryLoaderStatus(state: State, { payload }: PayloadAction<boolean>) {
      if (state.isDictionaryLoading !== payload) {
        state.isDictionaryLoading = payload;
      }
    },
  },
});

export const filterActions = filtersSlice.actions;

export default filtersSlice.reducer;
