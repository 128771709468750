import * as React from 'react';
import { Tab, TabProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTab = styled(Tab)<TabProps>(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: theme.typography.fontWeightRegular,
  height: '2.5rem',
  color: theme.palette.text.primary,
  borderColor: theme.palette.text.primary,

  '&.Mui-selected': {
    background: 'linear-gradient(94.5deg, #072F9C -20.35%, #0F79DF 109.04%)',
    color: theme.palette.common.white,
    border: 'none',
  },
}));

const CSDTab: React.FC<TabProps> = props => {
  return <StyledTab {...props} />;
};

export default CSDTab;
