import React from 'react';
import { Skeleton } from '@mui/material';

interface Props {
  height?: string;
  width?: string;
}

const CSDMeteoChartSkeleton = ({ height = '425px', width = '100%' }: Props) => {
  return (
    <Skeleton
      variant="rectangular"
      sx={{
        display: 'block',
        height,
        width,
        borderRadius: '0.5rem',
      }}
    />
  );
};

export default CSDMeteoChartSkeleton;
