import { useCallback, useState } from 'react';
import { useMeteoChart } from '@app/v2/shared/hooks';
import { strictlyEqual } from '@app/v2/shared/helpers';
import { MeteoChartContainerWidth } from '@app/v2/shared/enums';

const initialMeteoChartSetupValue: Meteo.ChartProfileItem<string[]> = {
  name: '',
  parameters: [],
  containerWidth: MeteoChartContainerWidth.Full,
  order: 0,
  id: null,
};

interface MeteoChartConstructor {
  currentSetup: Meteo.ChartProfileItem<string[]>;
  handleChangeCurrentSetupTitle: (nexValue: string) => void;
  handleChangeCurrentSetupParameter: (key: string, value: boolean) => void;
  handleChangeCurrentSetupParameters: (groupKey: string, value: boolean, elementsShouldNotBeInSetup?: string[]) => void;
  handleUpdateCurrentSetup: (nextValues: Meteo.ChartProfileItem<string[]>) => void;
  handleClearCurrentSetup: Common.VoidCallBack;
}

export default function useMeteoChartConstructor(): MeteoChartConstructor {
  const [currentSetup, setCurrentSetup] = useState<Meteo.ChartProfileItem<string[]>>(initialMeteoChartSetupValue);

  const { chartsSetup } = useMeteoChart();

  const handleChangeCurrentSetupTitle = useCallback((nexValue: string) => {
    setCurrentSetup(prev => ({ ...prev, name: nexValue }));
  }, []);

  const handleChangeCurrentSetupParameter = useCallback((key: string, value: boolean) => {
    if (value) {
      setCurrentSetup(prev => ({
        ...prev,
        parameters: [...prev.parameters, key],
      }));
    } else {
      setCurrentSetup(prev => ({
        ...prev,
        parameters: prev.parameters.filter(parameter => !strictlyEqual(parameter, key)),
      }));
    }
  }, []);

  const handleChangeCurrentSetupParameters = useCallback(
    (groupKey: string, value: boolean, elementsShouldNotBeInSetup?: string[]) => {
      const group = chartsSetup.find(({ label }) => strictlyEqual(label, groupKey));
      const keysByGroup: string[] = group.params.map(({ label }) => label).filter(parameter => !elementsShouldNotBeInSetup.includes(parameter));

      if (value) {
        setCurrentSetup(prev => ({
          ...prev,
          parameters: [...prev.parameters, ...keysByGroup],
        }));
      } else {
        setCurrentSetup(prev => ({
          ...prev,
          parameters: prev.parameters.filter(parameter => !keysByGroup.includes(parameter)),
        }));
      }
    },
    [chartsSetup],
  );

  const handleUpdateCurrentSetup = useCallback((value: Meteo.ChartProfileItem<string[]>): void => {
    setCurrentSetup(value);
  }, []);

  const handleClearCurrentSetup = useCallback((): void => {
    setCurrentSetup(initialMeteoChartSetupValue);
  }, []);

  return {
    currentSetup,
    handleChangeCurrentSetupTitle,
    handleChangeCurrentSetupParameter,
    handleChangeCurrentSetupParameters,
    handleUpdateCurrentSetup,
    handleClearCurrentSetup,
  };
}
