import React, { ReactNode } from 'react';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/styles';
import { LAYOUT_FLEX_GAP } from './constants';

type Props = {
  isRatioLessThenMinimum: boolean;
  children: ReactNode;
};

const DynamicLayoutManager = ({ isRatioLessThenMinimum, children }: Props) => {
  return isRatioLessThenMinimum ? <StyledAdditionalPart>{children}</StyledAdditionalPart> : children;
};

export default DynamicLayoutManager;

const StyledAdditionalPart = styled(Box)<BoxProps>({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: LAYOUT_FLEX_GAP,
});
