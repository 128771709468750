import React, { useState } from 'react';
import { Stack, Icon, IconButton, Typography } from '@mui/material';
import CSDTub from './CSDTub';

type Props = {
  iconName?: string;
  size?: 'small' | 'normal';
};

const CSDTubCounter = ({ iconName, size = 'normal' }: Props) => {
  const [count, setCount] = useState<number>(0);

  const handleAdd = () => {
    setCount(prev => prev + 1);
  };

  const handleSubtract = () => {
    setCount(prev => {
      if (count > 0) return prev - 1;

      return prev;
    });
  };

  return (
    <CSDTub size={size}>
      <Stack direction="row" spacing={1}>
        {iconName && <Icon>{iconName}</Icon>}
        <Stack direction="row" spacing={1} alignItems="center">
          <IconButton onClick={handleSubtract}>
            <Icon>left</Icon>
          </IconButton>
          <Typography textAlign="center" color={({ palette: { common } }) => common.white}>
            {count}
          </Typography>
          <IconButton onClick={handleAdd}>
            <Icon>right</Icon>
          </IconButton>
        </Stack>
      </Stack>
    </CSDTub>
  );
};

export default CSDTubCounter;
