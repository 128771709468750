import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Tooltip } from '@mui/material';
import { EMPTY_STRING } from '@app/v2/shared/constants';
import icons from '@app/assets/iconFont';

interface Props<AnchorType> {
  isOpen: boolean;
  disabled?: boolean;
  onClick: (event: MouseEvent<AnchorType>) => void;
}

const CSDCreateMeteoChartBtn = <AnchorType extends HTMLButtonElement>({ isOpen, disabled = false, onClick }: Props<AnchorType>) => {
  const { t } = useTranslation('charts', { keyPrefix: 'meteoConstructor' });

  return (
    <Tooltip title={disabled ? t('defaultProfileDisabledTooltip') : EMPTY_STRING}>
      <span style={{ cursor: disabled ? 'not-allowed' : 'auto' }}>
        <Button disabled={disabled} variant={!isOpen ? 'outlined' : 'contained'} startIcon={<Icon>{icons.plus}</Icon>} onClick={onClick}>
          {t('newChart')}
        </Button>
      </span>
    </Tooltip>
  );
};

export default CSDCreateMeteoChartBtn;
