import { EMPTY_STRING } from '@app/v2/shared/constants';

const values: Omit<Scoreboards.TemplateModel, 'id'> = {
  title: EMPTY_STRING,
  organizationId: EMPTY_STRING,
  isCommon: false,
  content: {
    contents: [],
    configuration: {
      id: null,
      title: EMPTY_STRING,
      color: EMPTY_STRING,
      type: EMPTY_STRING,
      size: { height: 0, width: 0 },
    },
  },
};

export default values;
