import { mainConfig } from '@app/v2/shared/configs';

const prepareResponseData = (data: Video.VideoStationDetailsResponse): Video.VideoStationDetailModel => {
  if (!data) return null;

  return {
    ...data.videoStationDetails,
    snapShots: data.snapShots.map(snapShot => ({
      ...snapShot,
      url: snapShot?.url ? `${mainConfig.links.videoImagesHost}${snapShot.url}` : null,
    })),
  };
};

export default prepareResponseData;
