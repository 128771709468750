/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Moment } from 'moment/moment';
import moment from 'moment';
import { ReducersNames } from '@store/constants';
import { VideoStationMode } from '@app/v2/shared/enums';

export interface VideoPostState {
  dateFrom: Moment;
  dateTo: Moment;
  page: number;
  stationProperties: {
    isLive: boolean;
    isStream: boolean;
    isWiper: boolean;
  };
  snapShots: Video.Snapshot[];
  liveSnapShotURL: string;
  activeSnapShot: Video.Snapshot;
  activeSnapShotIndex: number;
  contentMode: VideoStationMode;
  isAnimation: boolean;
}

// TODO check interface !!!

const initialState: VideoPostState = {
  dateFrom: moment().subtract(1, 'days'),
  dateTo: moment(),
  page: 1,
  stationProperties: {
    isLive: false,
    isStream: false,
    isWiper: false,
  },
  snapShots: [],
  liveSnapShotURL: null,
  activeSnapShot: null,
  activeSnapShotIndex: null,
  contentMode: VideoStationMode.IMAGE_MODE,
  isAnimation: false,
};

const videoPostSlice = createSlice({
  name: ReducersNames.videoPostTab,
  initialState,
  reducers: {
    resetVideoPostState(state: VideoPostState) {
      state.snapShots = [];
      state.activeSnapShot = null;
      state.liveSnapShotURL = null;
      state.activeSnapShotIndex = null;
      state.isAnimation = false;
    },
    setVideoPostDate(state: VideoPostState, { payload }: PayloadAction<Pick<VideoPostState, 'dateFrom' | 'dateTo'>>) {
      state.dateFrom = payload.dateFrom;
      state.dateTo = payload.dateTo;
    },
    setVideoPostInitialDates(state: VideoPostState) {
      state.dateFrom = moment().subtract(1, 'days');
      state.dateTo = moment();
    },
    setVideoPostDefaultPage(state: VideoPostState) {
      state.page = initialState.page;
    },
    setVideoPostPage(state: VideoPostState, { payload }: PayloadAction<Pick<VideoPostState, 'page'>>) {
      state.page = payload.page;
    },
    setVideoStationProperties(state: VideoPostState, { payload }: PayloadAction<Pick<VideoPostState, 'stationProperties'>>) {
      state.stationProperties = payload.stationProperties;
    },
    addVideoPostSnapShots(state: VideoPostState, { payload }: PayloadAction<Pick<VideoPostState, 'snapShots'>>) {
      state.snapShots = [...state.snapShots, ...payload.snapShots];
    },
    setVideoPostSnapshot(state: VideoPostState, { payload }: PayloadAction<Pick<VideoPostState, 'snapShots'>>) {
      state.snapShots = payload.snapShots;
    },
    setVideoPostActiveSnapshot(state: VideoPostState, { payload }: PayloadAction<Pick<VideoPostState, 'activeSnapShot'>>) {
      state.activeSnapShot = payload.activeSnapShot;
    },
    setVideoPostActiveSnapshotIndex(state: VideoPostState, { payload }: PayloadAction<Pick<VideoPostState, 'activeSnapShotIndex'>>) {
      state.activeSnapShotIndex = payload.activeSnapShotIndex;
    },
    setVideoPostContentMode(state: VideoPostState, { payload }: PayloadAction<VideoStationMode>) {
      state.contentMode = payload;
    },
    toggleVideoPostAnimation(state: VideoPostState, { payload }: PayloadAction<Pick<VideoPostState, 'isAnimation'>>) {
      state.isAnimation = payload.isAnimation;
    },
    setVideoPostLiveSnapShot(state: VideoPostState, { payload }: PayloadAction<Pick<VideoPostState, 'liveSnapShotURL'>>) {
      state.liveSnapShotURL = payload.liveSnapShotURL;
    },
  },
});

export const videoPostActions = videoPostSlice.actions;

export default videoPostSlice.reducer;
