import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import tokenStorage from '@app/clients/apollo/tokenStorage';
import useAppSelector from '@app/core/hooks/customReduxHooks/useAppSelector';
import { PublicPath, AppTypes } from '@app/v2/shared/enums';

export default function useAppType(): AppTypes {
  const { pathname } = useLocation();
  const { isAuth } = useAppSelector(state => state.auth);

  const appType = useRef<AppTypes>(null);

  const isKitMode = process.env.REACT_APP_IS_SHOW_REDESIGN === 'true' && pathname.includes('/kit');
  const isMainMode = isAuth && tokenStorage.isExistAuthToken && !pathname.includes(PublicPath.Recovery);

  switch (true) {
    case isKitMode:
      appType.current = AppTypes.KIT;
      break;
    case isMainMode:
      appType.current = AppTypes.MAIN;
      break;
    default:
      appType.current = AppTypes.AUTHORIZATION;
  }

  return appType.current;
}
