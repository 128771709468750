import { TFunction } from 'react-i18next';
import moment from 'moment';
import { Chart } from 'highcharts';
import { isNumber } from '@app/v2/shared/helpers';
import {
  chartPaddingTop,
  headerHeight,
  labelsColors,
  nestedPointWidth,
  pointWidth,
  rowHeight,
  yAxisBackgroundId,
  yAxisBgColor,
  yAxisWidth,
  recommendationAlertType,
} from '../constants/planChart';
import type { AlertRoadPlan, PlaceRoadAlert } from '../../road-plan-dialog/types';
import type { CustomGanttPointOptionsObject, YAxisColumnOptions } from '../types';
import { PlanGraphAlertData, PlanGraphExportingInfo } from '../../plan-dialog/types';

export const alertIsExist = (alert: PlaceRoadAlert | AlertRoadPlan): boolean => Boolean(alert);

export const formPlaceName = (address: number, meters: number, units: string): string => {
  const metersToFormat = meters.toString().padStart(3, '0');

  return `${units} ${address}+${metersToFormat}`;
};

export const createAlertDescription = (message: string, t: TFunction<'charts'>): string => {
  if (!message.length) return t('eventMessageMissing');

  return `<span style="color: black">${message}</span>`;
};

export const createAlertShortDescription = (data: PlanGraphAlertData, rank: number = 0, alias: string, t: TFunction<'charts'>): string => {
  const value = isNumber(data?.value) ? data.value : '';
  const unit = data?.unit || '';
  const duration = data?.duration ? `за ${data.duration}` : '';
  const message = data?.message || '';
  const useMessageExceptValue = !!message && !value && !unit && !duration;

  if (alias === recommendationAlertType) {
    return `<span style="color: ${labelsColors[rank]}"></span>`;
  }

  if (useMessageExceptValue) {
    return `<span style="color: ${labelsColors[rank]}">${message}</span>`;
  }

  if (!value && !unit && !duration) return t('eventMessageMissing');

  return `<span style="color: ${labelsColors[rank]}">${value} ${unit} ${duration}</span>`;
};

export const isExistEventOnPlace = (places: CustomGanttPointOptionsObject[], alert: AlertRoadPlan): boolean => {
  const { from: alertStartTime, to: alertEndTime } = alert;

  const result = places.find(({ start, end }) => {
    const isTheSameStartTime = start === moment(alertStartTime).valueOf();
    const isTheSameEndTime = end === moment(alertEndTime).valueOf();

    return isTheSameStartTime && isTheSameEndTime;
  });

  return Boolean(result);
};

export const getEventWidth = (
  mainEvents: CustomGanttPointOptionsObject[],
  additionalEventStartTime: number,
  additionalEventEndTime: number,
  placeId: number,
): number => {
  const isIntersectionsExist = mainEvents
    .filter(mainEvent => mainEvent.placeId === placeId)
    .find(mainEvent => {
      const isIntersectStartTime = moment(additionalEventStartTime).isBetween(mainEvent.start, mainEvent.end);
      const isIntersectEndTime = moment(additionalEventEndTime).isBetween(mainEvent.start, mainEvent.end);

      if (isIntersectStartTime || isIntersectEndTime) {
        return mainEvent;
      }

      return false;
    });

  return isIntersectionsExist ? nestedPointWidth : pointWidth;
};

export const getChartHeight = (amountRows: number, baseChartHeight: number): number =>
  amountRows * rowHeight > baseChartHeight ? baseChartHeight : amountRows * rowHeight + headerHeight;

export const getMinHeightOfScrollableArea = (amountRows: number): number => amountRows * rowHeight + headerHeight + 1;

export const getChartScrollHeight = (chart: Chart, amountRows: number): number => {
  return amountRows * rowHeight + chart.plotTop + chartPaddingTop;
};

export const drawYAxisBackground = (chart): void => {
  const background = document.getElementById(yAxisBackgroundId);

  if (background) {
    background.remove();
  }

  const yAxis = chart.yAxis[0];
  const bgWidth = yAxisWidth;
  const bgHeight = Object.keys(yAxis.ticks).length * rowHeight;
  const bgLeft = 0;
  const bgTop = yAxis.top;
  const bgColor = yAxisBgColor;

  chart.renderer
    .rect(bgLeft, bgTop, bgWidth || 0, bgHeight)
    .attr({
      fill: bgColor,
      zIndex: 0,
      id: yAxisBackgroundId,
    })
    .add();
};

export const drawInfoBoxForExporting = (chart, info: PlanGraphExportingInfo, t: TFunction<'charts'>): void => {
  const { address, id, road, owner } = info;
  const addressCss = {
    fontFamily: '"Roboto","Helvetica","Arial", sans-serif',
    fontWeight: 'bold',
    fontSize: '2.5rem',
  };

  const infoCss = {
    fontFamily: '"Roboto","Helvetica","Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '1.5rem',
  };

  const startPositionX = 16;
  const startPositionY = 40;
  const margin = 16;

  const addressText = chart.renderer.text(address, startPositionX, startPositionY).css(addressCss).add();
  const addressTextBox = addressText.getBBox();

  const idText = chart.renderer
    .text(`ID: ${id}`, startPositionX, addressTextBox.y + addressTextBox.height + margin)
    .css(infoCss)
    .add();
  const idTextBox = idText.getBBox();

  const roadText = chart.renderer
    .text(`${t('road')}: ${road}`, idTextBox.x + idTextBox.width + margin, addressTextBox.y + addressTextBox.height + margin)
    .css(infoCss)
    .add();
  const roadTextBox = roadText.getBBox();

  chart.renderer
    .text(`${t('owner')}: ${owner}`, roadTextBox.x + roadTextBox.width + margin, addressTextBox.y + addressTextBox.height + margin)
    .css(infoCss)
    .add();
};

export const drawChartFakeHeader = (width, chartHeaderId: string): void => {
  const chartWrapper = document.getElementById(chartHeaderId);

  const firstPartNode = document.getElementById('fakeHeaderBackgroundFirstPart');
  const secondPartNode = document.getElementById('fakeHeaderBackgroundSecondPart');

  if (secondPartNode) {
    secondPartNode.style.width = `${width}px`;
  }

  if (chartWrapper && !firstPartNode && !secondPartNode) {
    const fakeHeaderBackgroundFirstPart = document.createElement('div');
    const fakeHeaderBackgroundSecondPart = document.createElement('div');

    fakeHeaderBackgroundFirstPart.className = 'chart-header-first';
    fakeHeaderBackgroundFirstPart.id = 'fakeHeaderBackgroundFirstPart';

    fakeHeaderBackgroundSecondPart.style.width = `${width}px`;
    fakeHeaderBackgroundSecondPart.className = 'chart-header-second';
    fakeHeaderBackgroundSecondPart.id = 'fakeHeaderBackgroundSecondPart';

    chartWrapper.appendChild(fakeHeaderBackgroundFirstPart);
    chartWrapper.appendChild(fakeHeaderBackgroundSecondPart);
  }
};

export const getMaxDate = (xData: CustomGanttPointOptionsObject[], yData: CustomGanttPointOptionsObject[]): number => {
  return Math.max(...[...xData, ...yData].map(({ end }) => end).filter(Boolean));
};

export const createTwoColumnsLayoutForYAxis = (firstColumnOptions: YAxisColumnOptions, secondColumnOptions: YAxisColumnOptions) => {
  return `
		<span class="yaxis-row">
			<span class="yaxis-column">
				<span class=${firstColumnOptions?.className || 'yaxis-column-text'}>${firstColumnOptions.data}</span>
			</span>
			<span class="yaxis-column">
				<span class=${secondColumnOptions?.className || 'yaxis-column-text'}>${secondColumnOptions.data}</span>
				<span class="yaxis-column-screen"/>
			</span>
		</span>
	`;
};

export const createTimeMessageAboutEvent = (startEventTime: string | number, endEventTime: string | number, t: TFunction<'charts'>) => {
  if (!startEventTime || !endEventTime) return '';

  const isEventStarted = moment(startEventTime).isBefore(moment());

  if (isEventStarted) {
    const remainingTimeUntilEndOfEvent = moment(endEventTime).diff(moment());
    const value = transformDuration(remainingTimeUntilEndOfEvent, t);

    return `<b>${value}</b></br>${t('untilEndOfEvent')}`;
  }

  const diffBetweenPresentAndStartTimeOfEvent = moment(startEventTime).diff(moment());
  const duration = transformDuration(diffBetweenPresentAndStartTimeOfEvent, t);

  return `<b>${duration}</b></br>${t('untilStartOfEvent')}`;
};

export const createTimeMessageAboutClosestEvent = (events: CustomGanttPointOptionsObject[], t: TFunction<'charts'>) => {
  if (events.length > 1) {
    events.sort((a, b) => a.start - b.start);
    const closest = events[0];

    return createTimeMessageAboutEvent(closest.start, closest.end, t);
  }

  return '';
};

const transformDuration = (duration: number, t: TFunction<'charts'>): string => {
  const days = moment.duration(duration).days();
  const hours = moment.duration(duration).hours();
  const minutes = moment.duration(duration).minutes();

  const daysPart = days ? `${days}${t('day')}` : '';
  const hoursPart = hours ? `${hours}${t('hour')}` : '';
  const minutesPart = minutes ? ` ${minutes}${t('minute')}` : '';

  return `${daysPart} ${hoursPart} ${minutesPart}`;
};
