export const select = `<CSDSelect label="Вопрос" items={[
  { label: 'Ten', value: '10' },
  { label: 'Twenty', value: '20' },
  { label: 'Thirty', value: '30' },
]} />`;

export const selectWithError = `<CSDSelect label="Вопрос" error helperText="Что-то пошло не так" items={[
  { label: 'Ten', value: '10' },
  { label: 'Twenty', value: '20' },
  { label: 'Thirty', value: '30' },
]} />`;

export const disabledSelect = `<CSDSelect label="Вопрос" disabled items={[
  { label: 'Ten', value: '10' },
  { label: 'Twenty', value: '20' },
  { label: 'Thirty', value: '30' },
]} />`;

export const standardSelect = `<CSDSelectsStandard label="Вопрос">
  {selectItems.map(({ label, value }) => (
    <MenuItem key={value} value={value}>
      {label}
    </MenuItem>
  ))}
</CSDSelectsStandard>`;

export const standardSelectWithIcon = `<CSDSelectsStandard label="Вопрос" icon="search">
  {selectItems.map(({ label, value }) => (
    <MenuItem key={value} value={value}>
      {label}
    </MenuItem>
  ))}
</CSDSelectsStandard>`;
