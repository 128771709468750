import { KeyPrefix } from 'react-i18next';
import { i18n } from '@app/v2/shared/localization';
import {
  ConfigurationTypes,
  MainPaths,
  MessageStringAlign,
  TPIConfigurationConnectionType,
  TPIConfigurationFonts,
  TPIConfigurationVolumeLabelValues,
  TPIConfigurationWordWrapValues,
  UsersAccess,
  VMSDeviceDirections,
  VMSTemplatesTypes,
} from '@app/v2/shared/enums';
import icons from '@app/assets/iconFont';
import scoreboardNumbersMapper from './ScoreboardNumbers';
import scoreboardSymbolsMapper from './ScoreboardSymbols';
import scoreboardLatinLettersMapper from './ScoreboardLatinLetters';
import scoreboardCyrillicLettersMapper from './ScoreboardCyrillicLetters';

/** Selectors start */

const volumeLabel: Common.SelectorValues = [
  {
    value: TPIConfigurationVolumeLabelValues.C,
    label: i18n.t('scoreboard:configuration.form.tom', { type: 'C' }),
  },
  {
    value: TPIConfigurationVolumeLabelValues.D,
    label: i18n.t('scoreboard:configuration.form.tom', { type: 'D' }),
  },
  {
    value: TPIConfigurationVolumeLabelValues.E,
    label: i18n.t('scoreboard:configuration.form.tom', { type: 'E' }),
  },
];

const elementValign: Common.SelectorValues = [
  {
    value: 'top',
    label: i18n.t('scoreboard:configuration.constants.up'),
  },
  {
    value: 'middle',
    label: i18n.t('scoreboard:configuration.constants.center'),
  },
  {
    value: 'bottom',
    label: i18n.t('scoreboard:configuration.constants.atBottom'),
  },
];

const elementHalign: Common.SelectorValues = [
  {
    value: 'left',
    label: i18n.t('scoreboard:configuration.constants.left'),
  },
  {
    value: 'center',
    label: i18n.t('scoreboard:configuration.constants.center'),
  },
  {
    value: 'right',
    label: i18n.t('scoreboard:configuration.constants.onRight'),
  },
];

const wordWrap: Common.SelectorValues = [
  {
    value: TPIConfigurationWordWrapValues.Nowrap,
    label: i18n.t('scoreboard:configuration.constants.notTransfer'),
  },
  {
    value: TPIConfigurationWordWrapValues.Normal,
    label: i18n.t('scoreboard:configuration.constants.wholeWordWrap'),
  },
  {
    value: TPIConfigurationWordWrapValues.WordBreak,
    label: i18n.t('scoreboard:configuration.constants.wrapFromDash'),
  },
];

const fontName: Common.SelectorValues = [
  {
    value: TPIConfigurationFonts.Small,
    label: TPIConfigurationFonts.Small,
  },
  {
    value: TPIConfigurationFonts.Medium,
    label: TPIConfigurationFonts.Medium,
  },
  {
    value: TPIConfigurationFonts.Large,
    label: TPIConfigurationFonts.Large,
  },
];

const singsSizeValue: Common.SelectorValues = [
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
];

const types: Common.SelectorValues = [
  {
    value: ConfigurationTypes.Txtbox,
    label: i18n.t('scoreboard:common.constants.txtbox'),
  },
  {
    value: ConfigurationTypes.Sgnlst,
    label: i18n.t('scoreboard:common.constants.sgnlst'),
  },
  {
    value: ConfigurationTypes.Autobox,
    label: i18n.t('scoreboard:common.constants.autoBox'),
  },
];

const connectionType: Common.SelectorValues = [
  {
    label: TPIConfigurationConnectionType.TCP,
    value: TPIConfigurationConnectionType.TCP,
  },
  {
    label: TPIConfigurationConnectionType.UDP,
    value: TPIConfigurationConnectionType.UDP,
  },
];

const isJF2Local: Common.SelectorValues = [
  {
    value: 'true',
    label: 'common.constants.yes',
  },
  {
    value: 'false',
    label: 'common.constants.no',
  },
];

const align: Common.SelectorValues = [
  {
    value: 'left',
    label: i18n.t('scoreboard:configuration.constants.left'),
  },
  {
    value: 'center',
    label: i18n.t('scoreboard:configuration.constants.center'),
  },
  {
    value: 'right',
    label: i18n.t('scoreboard:configuration.constants.onRight'),
  },
];

const numLines: Common.SelectorValues = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
];

export const tpiSelectors = {
  configuration: {
    numLines,
    align,
    isJF2Local,
    types,
    connectionType,
    singsSizeValue,
    volumeLabel,
    elementValign,
    elementHalign,
    wordWrap,
    fontName,
  },
};

/** Selectors end */

/** Configuration item fields */

export const NUM_LINES = 'num-lines';
export const NUM_CHARS_PER_LINE = 'num-chars-per-line';
export const CHAR_SPACING = 'char-spacing';
export const LINE_SPACING = 'line-spacing';
export const MARGIN_RIGHT = 'margin-right';
export const MARGIN_LEFT = 'margin-left';
export const MARGIN_TOP = 'margin-top';
export const MARGIN_BOTTOM = 'margin-bottom';
export const FONT_NAME = 'font-name';
export const VALIGN = 'valign';

export const durationValues = [
  {
    value: 5,
    amount: 5,
    unit: 'shortSeconds',
  },
  {
    value: 10,
    amount: 10,
    unit: 'shortSeconds',
  },
  {
    value: 20,
    amount: 20,
    unit: 'shortSeconds',
  },
  {
    value: 30,
    amount: 30,
    unit: 'shortSeconds',
  },
  {
    value: 40,
    amount: 40,
    unit: 'shortSeconds',
  },
  {
    value: 50,
    amount: 50,
    unit: 'shortSeconds',
  },
  {
    value: 60,
    amount: 1,
    unit: 'shortMinute',
  },
  {
    value: 120,
    amount: 2,
    unit: 'shortMinute',
  },
  {
    value: 180,
    amount: 3,
    unit: 'shortMinute',
  },
  {
    value: 240,
    amount: 4,
    unit: 'shortMinute',
  },
  {
    value: 300,
    amount: 5,
    unit: 'shortMinute',
  },
  {
    value: 600,
    amount: 10,
    unit: 'shortMinute',
  },
  {
    value: 1800,
    amount: 30,
    unit: 'shortMinute',
  },
  {
    value: 3600,
    amount: 60,
    unit: 'shortMinute',
  },
].map(({ amount, unit, ...item }) => ({
  ...item,
  label: i18n.t(`common:dates.periods.${unit}`, {
    amount,
  }),
}));

export const singleDurationValue: Common.SelectorValues = [{ value: 60, label: i18n.t('scoreboard:playlists.previewRowData.notChanged') }];

/** Preview */

export const DEFAULT_MARGIN = 0;
export const DEFAULT_SIGN_SIZE = 0;
export const DEFAULT_INTERVAL = 0;
export const DEFAULT_FONT_NAME = TPIConfigurationFonts.Small;
export const DEFAULT_TEMPLATE_WIDTH = 256;
export const DEFAULT_TEMPLATE_HEIGHT = 96;
export const DEFAULT_SIGN_HEIGHT = 48;
export const COMPRESSION_RATIO = 0.6;
export const PIXEL_SIZE = 3;
export const DEFAULT_BACKGROUND = 'rgb(0, 0, 0)';
export const COLOR = 'rgb(255, 255, 255)';
export const GRID_LINE_COLOR = '#263238';
export const DEFAULT_ALIGNMENT = 'middle';

/** Default autoBox values */

const autoBoxContent: { text: string; align: MessageStringAlign.Center }[] = [
  {
    text: i18n.t('scoreboard:common.constants.autoBoxContent1'),
    align: MessageStringAlign.Center,
  },
  {
    text: i18n.t('scoreboard:common.constants.autoBoxContent2'),
    align: MessageStringAlign.Center,
  },
  {
    text: i18n.t('scoreboard:common.constants.autoBoxContent3'),
    align: MessageStringAlign.Center,
  },
];

export const emptyTemplatesValues: Record<
  ConfigurationTypes,
  (configItem: Scoreboards.ConfigurationItem) => Scoreboards.Contents | Scoreboards.Contents[]
> = {
  [ConfigurationTypes.Txtbox]: item => new Array(Number(item[NUM_LINES])).fill({ text: '', align: 'center' }),
  [ConfigurationTypes.Autobox]: () => autoBoxContent,
  [ConfigurationTypes.Sgnlst]: () => ({ signId: null }),
};

/** Common */

export const stationTypeTranslation: { [key: string]: KeyPrefix<'scoreboard'> } = {
  message: 'common.constants.txtbox',
  sign: 'common.constants.sgnlst',
  azpi: 'common.constants.autoBox',
};

export const DEFAULT_TEMPLATE_CHANGE_DURATION = 60;

export const alignmentConfig: StaticConfigs.ScoreboardAlignmentConfigItem[] = [
  {
    key: 0,
    icon: icons.alignLeft,
    isActive: (value: MessageStringAlign) => value === MessageStringAlign.Left,
    direction: MessageStringAlign.Left,
  },
  {
    key: 1,
    icon: icons.alignCenter,
    isActive: (value: MessageStringAlign) => value === MessageStringAlign.Center,
    direction: MessageStringAlign.Center,
  },
  {
    key: 2,
    icon: icons.alignRight,
    isActive: (value: MessageStringAlign) => value === MessageStringAlign.Right,
    direction: MessageStringAlign.Right,
  },
];

export const directionBtnContent: Record<
  VMSDeviceDirections,
  {
    icon: string;
    tooltip: string;
  }
> = {
  [VMSDeviceDirections.Forward]: {
    icon: icons.upArrow,
    tooltip: i18n.t('reports:select.forwardDirection'),
  },
  [VMSDeviceDirections.Backward]: {
    icon: icons.downArrow,
    tooltip: i18n.t('reports:select.backwardDirection'),
  },
  [VMSDeviceDirections.Unknown]: {
    icon: icons.both,
    tooltip: i18n.t('reports:select.unknownDirection'),
  },
};

/** Config SignsAndScoreboards menu */

export const configSettingsButtonArr: StaticConfigs.ScoreboardFastLinkItem[] = [
  {
    perimeters: [UsersAccess.ROOT],
    title: i18n.t('scoreboard:common.constants.configurations'),
    path: MainPaths.ScoreboardConfiguration,
    icon: icons.document,
  },
  {
    perimeters: [UsersAccess.ROOT, UsersAccess.SERVICE_ENGINEER],
    title: i18n.t('scoreboard:common.constants.devices'),
    path: MainPaths.ScoreboardDevices,
    icon: icons.road,
  },
  {
    perimeters: [UsersAccess.ROOT],
    title: i18n.t('scoreboard:common.constants.templates'),
    path: MainPaths.ScoreboardTemplates,
    icon: icons.web,
  },
  {
    perimeters: [UsersAccess.ROOT],
    title: i18n.t('scoreboard:common.constants.signs'),
    path: MainPaths.ScoreboardSigns,
    icon: icons.zpi,
  },
  {
    perimeters: [UsersAccess.ROOT],
    title: i18n.t('scoreboard:common.constants.playlists'),
    path: MainPaths.ScoreboardPlaylists,
    icon: icons.mail,
  },
];

export const configurationForm = {
  type: {
    label: i18n.t('scoreboard:configuration.form.type'),
    items: tpiSelectors.configuration.types,
  },
  displayArea: {
    title: i18n.t('scoreboard:configuration.form.displayArea'),
    xField: {
      label: i18n.t('scoreboard:configuration.form.xCoordinate'),
      placeholder: i18n.t('scoreboard:configuration.form.input.placeholder'),
    },
    yField: {
      label: i18n.t('scoreboard:configuration.form.yCoordinate'),
      placeholder: i18n.t('scoreboard:configuration.form.input.placeholder'),
    },
    widthField: {
      label: i18n.t('scoreboard:configuration.form.displayWidth'),
      placeholder: i18n.t('scoreboard:configuration.form.input.placeholder'),
    },
    heightField: {
      label: i18n.t('scoreboard:configuration.form.displayHeight'),
      placeholder: i18n.t('scoreboard:configuration.form.input.placeholder'),
    },
  },
  paddingFields: {
    title: i18n.t('scoreboard:configuration.form.gaps'),
    leftMarginField: {
      label: i18n.t('scoreboard:configuration.form.marginLeft'),
      placeholder: i18n.t('scoreboard:configuration.form.input.placeholder'),
    },
    topMarginField: {
      label: i18n.t('scoreboard:configuration.form.marginTop'),
      placeholder: i18n.t('scoreboard:configuration.form.input.placeholder'),
    },
    rightMarginField: {
      label: i18n.t('scoreboard:configuration.form.marginRight'),
      placeholder: i18n.t('scoreboard:configuration.form.input.placeholder'),
    },
    bottomMarginField: {
      label: i18n.t('scoreboard:configuration.form.marginBottom'),
      placeholder: i18n.t('scoreboard:configuration.form.input.placeholder'),
    },
  },
  location: {
    title: i18n.t('scoreboard:configuration.form.location'),
    valignField: {
      items: tpiSelectors.configuration.elementValign,
      label: i18n.t('scoreboard:configuration.form.verticalAlignment'),
    },
    halignField: {
      items: tpiSelectors.configuration.elementHalign,
      label: i18n.t('scoreboard:configuration.form.horizontalAlignment'),
    },
  },
  string: {
    title: i18n.t('scoreboard:configuration.form.strings'),
    numberOfLinesField: {
      label: i18n.t('scoreboard:configuration.form.numberOfLines'),
      items: tpiSelectors.configuration.numLines,
    },
    numberOfCharactersPerLineField: {
      label: i18n.t('scoreboard:configuration.form.numberOfCharactersPerLine'),
      placeholder: i18n.t('scoreboard:configuration.form.input.placeholder'),
    },
    lineSpacingField: {
      label: i18n.t('scoreboard:configuration.form.lineSpacing'),
      placeholder: i18n.t('scoreboard:configuration.form.input.placeholder'),
    },
    intercharacterRaceField: {
      label: i18n.t('scoreboard:configuration.form.intercharacterRace'),
      placeholder: i18n.t('scoreboard:configuration.form.input.placeholder'),
    },
    hyphenation: {
      label: i18n.t('scoreboard:configuration.form.hyphenation'),
      items: tpiSelectors.configuration.wordWrap,
    },
  },
  fonts: {
    title: i18n.t('scoreboard:configuration.form.font'),
    fontNameField: {
      label: i18n.t('scoreboard:configuration.form.fontName'),
      items: tpiSelectors.configuration.fontName,
    },
    forcedDetectionField: {
      label: i18n.t('scoreboard:configuration.form.forcedDetection'),
    },
  },
  disposition: {
    title: i18n.t('scoreboard:configuration.form.disposition'),
    valignField: {
      label: i18n.t('scoreboard:configuration.form.verticalElementExtrusion'),
      items: tpiSelectors.configuration.elementValign,
    },
    halignField: {
      label: i18n.t('scoreboard:configuration.form.horizontalElementExtrusion'),
      items: tpiSelectors.configuration.elementHalign,
    },
    signSpacingField: {
      label: i18n.t('scoreboard:configuration.form.characterSpacing'),
      placeholder: i18n.t('scoreboard:configuration.form.input.placeholder'),
    },
    alignField: {
      label: i18n.t('scoreboard:configuration.form.verticalTextExtrusion'),
      items: tpiSelectors.configuration.align,
    },
  },
  size: {
    title: i18n.t('scoreboard:configuration.form.size'),
    sizeField: {
      label: i18n.t('scoreboard:configuration.form.signSize'),
      items: singsSizeValue,
    },
  },
  addBtn: i18n.t('scoreboard:configuration.actions.addForm'),
};

export const MAX_SECONDS_OF_MANUAL_MODE_AZPI = process.env.REACT_APP_MAX_SECONDS_OF_MANUAL_MODE_AZPI
  ? Number(process.env.REACT_APP_MAX_SECONDS_OF_MANUAL_MODE_AZPI)
  : 12 * 60 * 60; // 12 hours in seconds;

export const iconsMapperByStationType: Record<VMSTemplatesTypes, string> = {
  [VMSTemplatesTypes.Sign]: icons.zpi,
  [VMSTemplatesTypes.Message]: icons.tpi,
  [VMSTemplatesTypes.Azpi]: 'AZPI',
};

export const SymbolsMapper = {
  ...scoreboardNumbersMapper,
  ...scoreboardSymbolsMapper,
  ...scoreboardLatinLettersMapper,
  ...scoreboardCyrillicLettersMapper,
};
