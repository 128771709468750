import { useCallback, useEffect } from 'react';
import { useActions, useAppSelector } from '@app/v2/shared/hooks';

export default function useLoading(): [boolean, (loading: boolean) => void] {
  const { setLoaderStatus } = useActions();
  const setLoading = useCallback(
    (loading: boolean) => {
      setLoaderStatus(loading);
    },
    [setLoaderStatus],
  );

  const loading = useAppSelector(state => state.loader);

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, [setLoading]);

  return [loading, setLoading];
}
