import React, { memo, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useMeteoChart, useMeteoChartProfiles } from '@app/v2/shared/hooks';
import meteoQueries from '@app/clients/apollo/requests/queries/meteo';
import { MeteoChartProfileType } from '@app/v2/shared/enums';
import MeteoChartTabContent from './MeteoChartTabContent';
import MeteoChartSettingsPanel from './MeteoChartSettingsPanel';
import type { StationsProps } from '../../../types';

const MeteoTab = ({ activeStationId, listOfStationsIdOfActiveTab, setActiveStationID, title, infoSetup, roadTitle }: StationsProps) => {
  const stationId = useMemo(() => activeStationId || listOfStationsIdOfActiveTab[0], [activeStationId, listOfStationsIdOfActiveTab]);

  const { handleSetMeteoChartsSetup } = useMeteoChart();
  const { handleSetMeteoChartProfiles } = useMeteoChartProfiles();

  useEffect(() => {
    setActiveStationID(stationId);
  }, [setActiveStationID, stationId]);

  const { data: meteoSettingsGroupData, loading: settingsGroupLoading } = useQuery<Meteo.ChartsSetupResponse>(meteoQueries.meteoGraphSettingsGroup);
  const { data: meteoSettingsData } = useQuery<Meteo.ChartProfileResponse>(meteoQueries.meteoGraphSettings);

  useEffect(() => {
    if (!meteoSettingsData?.meteoGraphSettings) return;

    handleSetMeteoChartProfiles(
      meteoSettingsData.meteoGraphSettings.map(profile => ({
        ...profile,
        type: MeteoChartProfileType.Custom,
        items: profile.items.map(item => ({
          ...item,
          parameters: item.parameters.reduce((acc, parameter) => {
            acc[parameter] = true;
            return acc;
          }, {} as Record<string, boolean>),
        })),
      })),
    );
  }, [handleSetMeteoChartProfiles, meteoSettingsData]);

  useEffect(() => {
    if (!meteoSettingsGroupData?.meteoGraphSettingSensors || settingsGroupLoading) return;
    handleSetMeteoChartsSetup(meteoSettingsGroupData.meteoGraphSettingSensors);
  }, [meteoSettingsGroupData, settingsGroupLoading, handleSetMeteoChartsSetup]);

  return (
    <>
      <MeteoChartSettingsPanel title={title} infoSetup={infoSetup} />
      <MeteoChartTabContent stationId={stationId} roadTitle={roadTitle} />
    </>
  );
};

export default memo(MeteoTab);
