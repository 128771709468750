import { useMemo } from 'react';
import {
  COMPRESSION_RATIO,
  DEFAULT_SIGN_HEIGHT,
  DEFAULT_TEMPLATE_HEIGHT,
  DEFAULT_TEMPLATE_WIDTH,
} from '@app/modules/scoreboard-module/common/constants';
import { ConfigurationTypes } from '@app/v2/shared/enums';

type Params = {
  scoreboardWidth: number;
  scoreboardHeight: number;
  configurationTypes: string[];
};

export default function useCompressionRatio({ scoreboardWidth, scoreboardHeight, configurationTypes }: Params): number {
  const scoreboardType = useMemo(() => configurationTypes[0], [configurationTypes]);

  const { isSign, isLargeTemplate, isSmallTemplate } = useMemo(
    () => ({
      isSign: scoreboardType === ConfigurationTypes.Sgnlst && scoreboardHeight >= DEFAULT_SIGN_HEIGHT && configurationTypes.length === 1,
      isLargeTemplate: scoreboardWidth > DEFAULT_TEMPLATE_WIDTH && scoreboardHeight > DEFAULT_TEMPLATE_HEIGHT,
      isSmallTemplate: scoreboardWidth < DEFAULT_TEMPLATE_WIDTH && scoreboardHeight < DEFAULT_TEMPLATE_HEIGHT,
    }),
    [configurationTypes.length, scoreboardHeight, scoreboardType, scoreboardWidth],
  );

  if (isSign) {
    return COMPRESSION_RATIO * (DEFAULT_SIGN_HEIGHT / scoreboardWidth);
  }

  if (isLargeTemplate && !isSign) {
    return COMPRESSION_RATIO * (DEFAULT_TEMPLATE_WIDTH / scoreboardWidth);
  }

  if (isSmallTemplate && !isSign) {
    return Math.floor(scoreboardWidth / COMPRESSION_RATIO / DEFAULT_TEMPLATE_WIDTH);
  }

  return COMPRESSION_RATIO;
}
