import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import messageAlignment from '@app/modules/scoreboard-module/common/helpers/messageAlignment';
import scoreboardItemPreview from '@app/modules/scoreboard-module/common/components/preview/scoreboardItemPreview';
import DrawScoreboard from '@app/modules/scoreboard-module/common/classes/DrawScoreboard';
import useCompressionRatio from '@app/modules/scoreboard-module/common/hooks/useCompressionRatio';
import { ConfigurationTypes } from '@app/v2/shared/enums';
import {
  DEFAULT_FONT_NAME,
  DEFAULT_BACKGROUND,
  DEFAULT_INTERVAL,
  DEFAULT_MARGIN,
  DEFAULT_SIGN_SIZE,
  PIXEL_SIZE,
  COLOR,
  GRID_LINE_COLOR,
  CHAR_SPACING,
  MARGIN_RIGHT,
  MARGIN_LEFT,
  MARGIN_TOP,
  MARGIN_BOTTOM,
  LINE_SPACING,
  FONT_NAME,
  NUM_LINES,
  DEFAULT_ALIGNMENT,
  VALIGN,
  SymbolsMapper,
} from '@app/v2/shared/constants';

type Props = {
  template: any;
  isListing?: boolean;
};

const Scoreboard = ({ template, isListing = true }: Props) => {
  const configuration = useMemo(() => template?.configuration?.configuration, [template?.configuration?.configuration]);

  const BACKGROUND = useMemo(() => configuration?.backgroundColor || DEFAULT_BACKGROUND, [configuration]);

  const configurationItems = useMemo(() => configuration?.items || [], [configuration]);
  const configurationTypes = useMemo(() => configuration?.items.map(i => i?.type) || [], [configuration]);

  const scoreboardData = useMemo(
    () =>
      template?.contents.map((content, index) => {
        if (Array.isArray(content) && content?.length) {
          const width = Number(configurationItems[index]?.width) || DEFAULT_SIGN_SIZE;
          const charsInterval = Number(configurationItems[index]?.[CHAR_SPACING]) || DEFAULT_INTERVAL;
          const font = configurationItems[index]?.[FONT_NAME] || DEFAULT_FONT_NAME;

          const [w] = font.split('x').map(Number);

          const maxLength = Math.floor(
            (width - Number(configurationItems[index]?.[MARGIN_RIGHT]) - Number(configurationItems[index]?.[MARGIN_LEFT]) + charsInterval) /
              (w + charsInterval),
          );

          const setMessageAlignment = messageAlignment(maxLength);

          return content
            .slice(0, configurationItems[index]?.[NUM_LINES])
            .map(row => setMessageAlignment(row.text.split(''), row.align))
            .map(row => row.map(symbol => SymbolsMapper[symbol?.charCodeAt(0)]));
        }

        return content;
      }),
    [template?.contents, configurationItems],
  );

  const intervals = scoreboardData.map((_, index) => {
    return {
      charsInterval: Number(configurationItems[index]?.[CHAR_SPACING]) || DEFAULT_INTERVAL,
      linesInterval: Number(configurationItems[index]?.[LINE_SPACING]) || DEFAULT_INTERVAL,
    };
  });

  const signValign = scoreboardData.map((_, index) => {
    if (configurationItems[index]?.type === ConfigurationTypes.Sgnlst) {
      return {
        signValign: configurationItems[index]?.[VALIGN] || DEFAULT_ALIGNMENT,
      };
    }

    return {
      signValign: null,
    };
  });

  const messageValign = scoreboardData.map((_, index) => {
    if ([ConfigurationTypes.Txtbox, ConfigurationTypes.Autobox].includes(configurationItems[index]?.type)) {
      return {
        messageValign: configurationItems[index]?.[VALIGN] || DEFAULT_ALIGNMENT,
      };
    }

    return {
      messageValign: null,
    };
  });

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const scoreboardSize = scoreboardData.map((content, index) => {
    const margins = {
      right: Number(configurationItems[index]?.[MARGIN_RIGHT]) || DEFAULT_MARGIN,
      left: Number(configurationItems[index]?.[MARGIN_LEFT]) || DEFAULT_MARGIN,
      top: Number(configurationItems[index]?.[MARGIN_TOP]) || DEFAULT_MARGIN,
      bottom: Number(configurationItems[index]?.[MARGIN_BOTTOM]) || DEFAULT_MARGIN,
    };

    const width = Number(configurationItems[index]?.width) || DEFAULT_SIGN_SIZE;
    const height = Number(configurationItems[index]?.height) || DEFAULT_SIGN_SIZE;

    const font = configurationItems[index]?.[FONT_NAME] || DEFAULT_FONT_NAME;
    const [w, h] = font.split('x').map(Number);

    return {
      width,
      height,
      margins,
      font: {
        h,
        w,
      },
    };
  });

  const scoreboardWidth = useMemo(() => scoreboardSize.map(item => item.width).reduce((acc, item) => item + acc, 0), [scoreboardSize]);
  const scoreboardHeight = useMemo(() => Math.max(...scoreboardSize.map(item => item.height)), [scoreboardSize]);

  const compressionRatio = useCompressionRatio({ scoreboardHeight, scoreboardWidth, configurationTypes });

  const getStartX = useCallback(
    (index: number) => {
      return scoreboardSize
        .map(sizeItem => sizeItem.width)
        .slice(0, index)
        .reduce((acc, sizeItemWidth) => acc + sizeItemWidth, 0);
    },
    [scoreboardSize],
  );

  const drawScoreboard = useMemo(() => new DrawScoreboard({ x: 0, y: 0 }), []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    canvas.height = scoreboardHeight * PIXEL_SIZE;
    canvas.width = scoreboardWidth * PIXEL_SIZE;

    drawScoreboard.reset(context, { background: BACKGROUND });
    canvas.style.height = `${canvas.height * compressionRatio}px`;
    canvas.style.width = `${canvas.width * compressionRatio}px`;

    const reqs = scoreboardData.map((itemData, index) => {
      return scoreboardItemPreview(context, {
        signValign: signValign[index]?.signValign,
        messageValign: messageValign[index]?.messageValign,
        content: itemData,
        charsInterval: intervals[index]?.charsInterval,
        linesInterval: intervals[index]?.linesInterval,
        pixelSize: PIXEL_SIZE,
        startCoords: { x: getStartX(index), y: 0 },
        font: { h: scoreboardSize[index].font.h, w: scoreboardSize[index].font.w, color: COLOR },
        background: BACKGROUND,
        itemSize: scoreboardSize[index],
      });
    });

    Promise.all(reqs).then(() => drawScoreboard.drawCells(context, { size: PIXEL_SIZE / compressionRatio, color: GRID_LINE_COLOR }));
  }, [
    BACKGROUND,
    compressionRatio,
    drawScoreboard,
    getStartX,
    intervals,
    intervals.charsInterval,
    intervals.linesInterval,
    isListing,
    messageValign,
    scoreboardData,
    scoreboardHeight,
    scoreboardSize,
    scoreboardWidth,
    signValign,
  ]);

  return (
    <>
      <canvas ref={canvasRef} />
    </>
  );
};

export default React.memo(Scoreboard);
