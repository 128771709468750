import ProfilesDataSource from '@app/core/profiles/ProfilesDataSource';
import { ProfilesTypes } from '@app/v2/shared/enums';

const sources: Partial<Record<ProfilesTypes, ProfilesDataSource>> = {};

export default function useDataSource(type: ProfilesTypes) {
  if (!sources[type]) {
    sources[type] = new ProfilesDataSource(type);
  }

  return sources[type];
}
