import { useTranslation } from 'react-i18next';
import { useTrafficChart } from '@app/v2/shared/hooks';
import { TrafficChartType, TrafficViewType } from '@app/core/types/charts/traffic';
import { strictlyEqual } from '@app/v2/shared/helpers';
import { COLORS } from '@theme/constants';
import { prepareSeriesParameters, prepareXAxisParameters, prepareYAxisParameters } from './chartOptionsPreparationFns';

const keysSetup: Record<TrafficViewType, string[]> = {
  [TrafficViewType.BY_CLASS]: ['trCars', 'trTrucks', 'trBigTrucks', 'trRoadTrains', 'trSmallTrucks', 'trBuses', 'trMotorcycles'],
  [TrafficViewType.BY_DIRECTIONS]: ['forward', 'backward'],
};

interface Parameters {
  data: Traffic.CommonTrafficChart;
  isViewAdditionalChart: boolean;
}

export default function useTrafficChartOptions({ data, isViewAdditionalChart }: Parameters) {
  const { dates = [], content } = data;

  const {
    trafficChartData: { typeView, typeChart: type },
  } = useTrafficChart();

  const { t: commonT } = useTranslation('common');
  const { t: parametersT } = useTranslation('parameters');

  const isViewLegend = isViewAdditionalChart && strictlyEqual(typeView, TrafficViewType.BY_DIRECTIONS);

  return {
    accessibility: {
      enabled: false,
    },
    chart: {
      type,
      zoomType: 'x',
    },
    title: {
      text: null,
    },
    lang: {
      noData: commonT('notificationMessages.noDataForPeriod'),
    },
    noData: {
      style: {
        fontWeight: '700',
        fontSize: '24px',
        color: '#222631',
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      outside: true,
      shared: true,
      distance: 32,
      backgroundColor: COLORS.white,
    },
    legend: {
      enabled: !isViewLegend,
      margin: 10,
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        borderRadius: 2,
        states: {
          hover: strictlyEqual(type, TrafficChartType.Spline),
        },
      },
      series: {
        stacking: strictlyEqual(type, TrafficChartType.Spline) ? undefined : 'normal',
        marker: {
          enabled: strictlyEqual(type, TrafficChartType.Spline),
          states: {
            hover: {
              enabled: strictlyEqual(type, TrafficChartType.Spline),
            },
          },
        },
      },
    },
    exporting: {
      enabled: false,
    },
    yAxis: prepareYAxisParameters({ type }),
    xAxis: prepareXAxisParameters({ dates, type }),
    series: prepareSeriesParameters({
      content,
      keysSetup,
      parametersT,
      commonT,
      typeView,
    }),
  };
}
