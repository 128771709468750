/* eslint-disable react/jsx-no-duplicate-props */
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { KeyPrefix, useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogProps, Stack, StackProps } from '@mui/material';
import { Subject } from 'rxjs';
import { styled } from '@mui/styles';
import { CSDTabsBar } from '@app/modules/kit-module/shared/ui';
import { useCheckAccess } from '@app/v2/shared/hooks';
import { DialogTabsTypes } from '@app/v2/shared/enums';
import DialogViewTitle from './DialogViewTitle';
import { allTabs, ERROR, ON_CLOSE } from '../../constants';
import useDialogViewParams from '../../hooks/useDialogViewParams';
import type { EventType, TabType, ViewData } from '../../types';

type Props = {
  stationId?: number;
  close: (data?: string) => void;
  data: ViewData;
  stationType: DialogTabsTypes;
};

const DialogView = ({ stationId, stationType, close, data: { placeData, dialogTitle, loading } }: Props) => {
  const { t } = useTranslation('dialogs');

  const [activeTab, setActiveTab] = useState<TabType>(null);
  const [events] = useState<Subject<EventType>>(new Subject<EventType>());
  const [activeStationID, setActiveStationID] = useState<number>(stationId);
  const activeStation = useRef<number>(stationId);

  const { accessChecker } = useCheckAccess();

  const listTabs = useMemo(() => {
    // TODO needs to refactor the logic below. Wrong: using activeStation ref, array method chaining, let variable

    if (placeData) {
      const newTabs = allTabs
        .filter(tab => (tab.view ? accessChecker(tab.view) : true))
        .map(tab => ({ ...tab, label: t(tab.label as KeyPrefix<'dialogs'>) as string }))
        .filter(({ nameInPlace }) => {
          const el = placeData[nameInPlace];
          if (Array.isArray(el)) {
            return el.length;
          }
          return el;
        });

      let newActiveTab = newTabs.find(({ id }) => id === stationType);
      if (!newActiveTab) {
        [newActiveTab] = newTabs;
        activeStation.current = null;
      }
      if (activeStationID === stationId) {
        newActiveTab ? setActiveTab(newActiveTab) : close(ERROR);
      }
      return newTabs;
    }
    return [];
  }, [accessChecker, activeStationID, close, placeData, stationId, stationType, t]);

  const handleChangeTab = useCallback((item: TabType) => {
    setActiveTab(item);
    activeStation.current = null;
  }, []);

  const { playlistsIds } = useMemo(
    () => ({
      meteoStationId: placeData?.meteoStation?.id,
      playlistsIds: placeData?.messageStations_v2?.map(item => item?.playlistId).filter(Boolean),
    }),
    [placeData],
  );
  const show = useMemo(() => Boolean(Boolean(placeData) && Boolean(activeTab) && !loading), [placeData, activeTab, loading]);

  const { listOfStationsIdOfActiveTab, owner } = useDialogViewParams({ activeTab, placeData, activeStationID });

  const { classesTab, classesTabs } = useMemo(
    () => ({
      classesTab: {
        minWidth: '250px',
        '& > span.MuiIcon-root': {
          fontSize: '1.5rem',
          marginRight: '0.5rem',
        },
      },
      classesTabs: {
        borderBottom: '1px solid black',
        '&:last-child': {
          marginRight: 0,
        },
      },
    }),
    [],
  );

  return (
    <StyledDialog open fullScreen fullWidth scroll="paper" onClose={() => close(ON_CLOSE)}>
      {show && (
        <>
          <DialogViewTitle
            title={dialogTitle}
            infoSetup={[
              { name: 'ID', value: activeStationID },
              { name: t('post.road'), value: placeData?.road?.title },
              { name: t('post.owner'), value: owner },
            ]}
            TabsComponent={
              <StyledTabsBar>
                <CSDTabsBar
                  value={activeTab}
                  items={listTabs}
                  tabProps={({ label }) => ({ label })}
                  classesTab={classesTab}
                  classesTabs={classesTabs}
                  onChange={handleChangeTab}
                />
              </StyledTabsBar>
            }
            close={close}
          />
          <DialogContent sx={{ justifyContent: 'flex-start', overflow: 'hidden' }}>
            <activeTab.component
              title={dialogTitle}
              infoSetup={[
                { name: 'ID', value: activeStationID },
                { name: t('post.road'), value: placeData?.road?.title },
                { name: t('post.owner'), value: owner },
              ]}
              playlistsIds={playlistsIds}
              stationId={stationId}
              listOfStationsIdOfActiveTab={listOfStationsIdOfActiveTab}
              roadTitle={placeData?.road?.title}
              activeStationId={activeStation.current}
              activeStationID={activeStationID}
              setActiveStationID={setActiveStationID}
              events={events}
              roadLines={placeData?.roadPointConfig?.lines}
              directionTitles={placeData?.road?.directionTitles}
              videoStations={placeData?.videoStations}
              close={close}
            />
          </DialogContent>
        </>
      )}
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)<DialogProps>({
  '& .MuiDialog-paper': {
    height: '100%',
    maxHeight: '100vh',
    width: '100%',
    borderRadius: '0.5rem 0.5rem 0 0',
    padding: 0,
  },
});

const StyledTabsBar = styled(Stack)<StackProps>({
  padding: '0 1rem',
});

export default DialogView;
