import LOGOUT from '@app/clients/apollo/requests/queries/auth/logout.graphql';
import LOGOUT_ALL_SESSIONS from '@app/clients/apollo/requests/queries/auth/logoutAllOtherSessions.graphql';
import RESEND_CONFIRM_CODE from '@app/clients/apollo/requests/queries/auth/resendConfirmCode.graphql';
import CONFIRM_LOGIN from '@app/clients/apollo/requests/queries/auth/confirmLogin.graphql';
import GET_CURRENT_SESSION_REMEMBER_TOKEN from '@app/clients/apollo/requests/queries/auth/getCurrentSessionRememberToken.graphql';
import LOGIN from '@app/clients/apollo/requests/queries/auth/login.graphql';
import LOGIN_BY_REMEMBER_TOKEN from '@app/clients/apollo/requests/queries/auth/loginByRememberToken.graphql';
import PASSWORD_RECOVERY_STEP_ONE from '@app/clients/apollo/requests/queries/auth/passwordRecoveryStepOne.graphql';
import PASSWORD_RECOVERY_STEP_TWO from '@app/clients/apollo/requests/queries/auth/passwordRecoveryStepTwo.graphql';

const authQueries = {
  login: LOGIN,
  loginByRememberToken: LOGIN_BY_REMEMBER_TOKEN,
  confirmLogin: CONFIRM_LOGIN,
  resendConfirmCode: RESEND_CONFIRM_CODE,
  logout: LOGOUT,
  logoutAllSessions: LOGOUT_ALL_SESSIONS,
  getCurrentSessionRememberToken: GET_CURRENT_SESSION_REMEMBER_TOKEN,
  passwordRecoveryStepOne: PASSWORD_RECOVERY_STEP_ONE,
  passwordRecoveryStepTwo: PASSWORD_RECOVERY_STEP_TWO,
};

export default authQueries;
