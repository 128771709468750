import React, { HTMLAttributes, useMemo } from 'react';
import { CircularProgress, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MuiAutocomplete from '@mui/lab/Autocomplete';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';

function Autocomplete(props: any, ref) {
  const {
    id,
    loading,
    label,
    placeholder,
    className,
    helperText,
    error,
    filterSelectedOptions = true,
    variant = 'outlined',
    margin = 'dense',
    getOptionLabel = option => option.label,
    isOptionEqualToValue = (a, b) => a.value === b.value,
    setValue = () => {},
    onInputChange,
    renderOption,
    width,
    dataTestId,
    ...other
  } = props;

  const classes = useStyles();

  const onHandleChange = (event, newValue): void => {
    if (setValue && typeof setValue === 'function') {
      setValue(id, newValue, event.isTrusted);
    }
  };

  const renderInput = useMemo(
    () =>
      ({ InputProps, ...params }: AutocompleteRenderInputParams) =>
        (
          <TextField
            {...params}
            size="small"
            variant={variant}
            margin={margin}
            placeholder={placeholder}
            helperText={helperText}
            error={error}
            label={label}
            InputLabelProps={{
              className: classes.rootTextControl,
            }}
            InputProps={{
              ...InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {InputProps.endAdornment}
                </>
              ),
            }}
          />
        ),
    [classes.rootTextControl, error, helperText, label, loading, margin, placeholder, variant],
  );

  const defaultRenderOptions = (renderProps: HTMLAttributes<HTMLElement>, renderOptions: { value; label: string }) => (
    <li {...renderProps} key={renderOptions.value}>
      {renderOptions.label}
    </li>
  );

  return (
    <MuiAutocomplete
      data-testid={dataTestId}
      ref={ref}
      loading={loading}
      className={className}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      filterSelectedOptions={filterSelectedOptions}
      onChange={onHandleChange}
      onInputChange={onInputChange}
      renderInput={renderInput}
      renderOption={renderOption || defaultRenderOptions}
      sx={{ width }}
      {...other}
    />
  );
}

const useStyles = makeStyles(theme => ({
  rootTextControl: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 'calc(100% - 40px)',
  },
}));

export default React.forwardRef(Autocomplete) as typeof Autocomplete;
