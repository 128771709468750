import React from 'react';
import { withTranslation } from 'react-i18next';
import { CardHeader, Icon, Link, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import icons from '@app/assets/iconFont';
import { isFunction } from '@app/v2/shared/helpers';

type Props = {
  title: string;
  redirectToPage?: () => void;
};

const FormHeader = ({ redirectToPage, title }: Props) => {
  const classes = useStyles();

  return (
    <CardHeader
      className={classes.cardHeader}
      title={
        <Stack direction="row" alignItems="center" justifyContent="center" width="100%" position="relative">
          {isFunction(redirectToPage) && (
            <Link onClick={redirectToPage} color="primary" underline="none" className={classes.buttonToLogin}>
              <Icon sx={{ fontSize: '1.25rem' }}>{icons.leftArrow}</Icon>
            </Link>
          )}
          <Typography>{title}</Typography>
        </Stack>
      }
    />
  );
};

const useStyles = makeStyles({
  cardHeader: {
    '&.MuiCardHeader-root': {
      boxShadow: '0rem 0.313rem 0.313rem rgba(52, 52, 52, 0.2)',
      width: '100%',
      margin: 0,

      '& .MuiTypography-root': {
        textAlign: 'center',
        fontSize: '1.25rem',
        lineHeight: '1.25rem',
        fontWeight: 700,
      },
    },
  },
  buttonToLogin: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: '#000000 !important',
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
    left: '0.1rem',
  },
});

export default withTranslation('auth', { keyPrefix: 'confirmation.form' })(FormHeader);
