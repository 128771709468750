import React from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, Tooltip } from '@mui/material';
import icons from '@app/assets/iconFont';

interface Props {
  tooltipTitle?: string;
}

const CSDNoIconData = ({ tooltipTitle, t }: Props & I18N.TranslationFunction<'common', 'errors'>) => {
  return (
    <Tooltip title={tooltipTitle || t('NOT_FOUND')}>
      <Icon>{icons.noData}</Icon>
    </Tooltip>
  );
};

export default withTranslation('common', { keyPrefix: 'errors' })(CSDNoIconData);
