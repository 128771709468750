import React, { useState, memo, useCallback } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Box, Button, Typography, Divider } from '@mui/material';
import { Notification } from '@app/v2/features/notifications/types';
import { getSplitGlobalSetting } from '@app/v2/features/notifications/helpers';
import { useActions } from '@app/v2/shared/hooks';
import useNotificationProfileContext from '@app/v2/features/notificationProfile/context/useNotificationProfileContext';
import { GLOBAL_SETTING_TYPES } from '@app/v2/features/notifications/constants';
import SubscriptionsCheckbox from './SubscriptionsCheckbox';

type Props = {
  data: Notification[];
} & WithTranslation<'notification'>;

const GlobalTab = ({ data, t }: Props) => {
  const classes = useStyles();
  const { isLoading } = useNotificationProfileContext();
  const [selectedTab, setSelectedTab] = useState<number>(GLOBAL_SETTING_TYPES.NOW.value);
  const { changeGlobalSubscriptionCheckbox: changeGlobalCheckbox } = useActions();

  const { forecastSettings, nowSettings } = getSplitGlobalSetting(data);

  const getDataByGlobalType = useCallback(
    tab => (tab === GLOBAL_SETTING_TYPES.FORECAST.value ? forecastSettings : nowSettings),
    [forecastSettings, nowSettings],
  );

  return (
    <>
      <Box className={classes.notificationTypesHeader}>
        <Typography variant="h4">{t('filter.notificationType')}</Typography>
        <Box className={classes.notificationTypes}>
          {Object.values(GLOBAL_SETTING_TYPES).map(({ label, value }) => (
            <Button
              key={value}
              className={classes.notificationTypeButton}
              variant={selectedTab === value ? 'contained' : 'outlined'}
              onClick={() => setSelectedTab(value)}
            >
              {t(label)}
            </Button>
          ))}
        </Box>
      </Box>
      <Divider className={classes.devider} />
      <Box className={classes.checkboxContainer}>
        <SubscriptionsCheckbox data={getDataByGlobalType(selectedTab)} disabled={isLoading} changeCheckbox={changeGlobalCheckbox} />
      </Box>
    </>
  );
};

const useStyles = makeStyles(() => ({
  notificationTypes: {
    display: 'flex',
    marginTop: '0.5rem',
  },
  checkboxContainer: {
    height: 'calc(100vh - 20rem)',
  },
  notificationTypeButton: {
    '&.MuiButton-root': {
      minWidth: 'auto',
      padding: '0.5rem',
      fontSize: '0.875rem',
      height: '1.75rem',
      marginRight: '8px',
    },
    '& .MuiButton-startIcon': {
      marginRight: '4px',
    },
  },
  devider: {
    '&.MuiDivider-root': {
      margin: '1rem 0',
    },
  },
  notificationTypesHeader: {
    marginTop: '1rem',
  },
}));

export default withTranslation('notification')(memo(GlobalTab));
