import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles/createTheme';
import { useChat } from '@app/v2/shared/hooks';

type Props = {
  textDecoration?: string;
  color?: string;
  variant?: 'caption' | 'body1' | 'body2' | 'subtitle1';
  textAlign?: 'center' | 'right' | 'left';
  fontSize?: string;
  generalContent: string;
  supportLink: string;
};

const SupportMessage = ({ variant = 'body1', textAlign = 'center', fontSize, textDecoration, color, generalContent, supportLink }: Props) => {
  const classes = useStyles({ textDecoration, color });

  const { isChatOpen, handleChangeChatStatus } = useChat();

  const handleSupportLinkClick = () => {
    handleChangeChatStatus(!isChatOpen);
  };

  return (
    <Typography variant={variant} textAlign={textAlign} fontSize={fontSize}>
      {generalContent}
      <button type="button" className={classes.technicalSupport} onClick={handleSupportLinkClick}>
        {supportLink}
      </button>
    </Typography>
  );
};

const useStyles = makeStyles<Theme, { textDecoration?: string; color?: string }>({
  technicalSupport: {
    backgroundColor: 'transparent',
    border: 'none',
    color: ({ color }) => color || '#000000',
    textDecoration: ({ textDecoration }) => textDecoration || 'underline',
    cursor: 'pointer',
    padding: 0,
  },
});

export default SupportMessage;
