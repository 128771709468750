import { useEffect, useMemo, useRef } from 'react';
import useMapDataLoading from '@app/core/hooks/useMapDataLoading';
import useLoading from '@app/core/hooks/useLoading';
import useLoadingYandexMap from '@app/modules/map-module/hooks/useLoadingYandexMap';
import useLoadingProfiles from '@app/modules/map-module/hooks/useLoadingProfiles';
import useLoadingLocators from '@app/modules/map-module/hooks/useLoadingLocators';

const useLoadingMapData = (): boolean => {
  const [, setLoading] = useLoading();

  useLoadingLocators();
  const [loadings] = useMapDataLoading();
  const isYandexMapLoad = useLoadingYandexMap();
  const isProfilesLoading = useLoadingProfiles();
  const isLoadingStartData = useRef<boolean>(true);

  const isLoadingData = useMemo(() => isYandexMapLoad || isProfilesLoading, [isProfilesLoading, isYandexMapLoad]);

  useEffect(() => {
    if (isLoadingStartData.current) {
      isLoadingStartData.current = isLoadingData;
    }
  }, [isYandexMapLoad, isProfilesLoading, isLoadingData]);

  useEffect(() => {
    setLoading(isLoadingData || Object.values(loadings).some(item => item.load));
  }, [isLoadingData, loadings, setLoading]);

  return isLoadingStartData.current;
};
export default useLoadingMapData;
