import { TFunction } from 'react-i18next';
import parameters from '@app/core/constants/parameters/parameters';
import { TrafficChartDirection } from '@app/core/types/charts/traffic';

export default function prepareSeriesParameters({
  data,
  parametersT,
  commonT,
}: {
  data: Traffic.ChartDirections;
  parametersT: TFunction<'parameters'>;
  commonT: TFunction<'common'>;
}) {
  if (!data) return [];

  const series = [...Object.entries(data.directions.content.forward?.[0] ?? {}), ...Object.entries(data.directions.content.backward?.[0] ?? {})]
    .filter(([key]) => key !== 'occupancy')
    .reduce((acc, [key, value]) => {
      if (!acc[key]) acc[key] = [value];
      else acc[key].push(value);
      return acc;
    }, {} as Record<TrafficChartDirection, number>);

  return Object.entries(series).map(([key, dataValue]) => {
    const {
      label: name,
      chartOptions: { lineColor: color },
      unitsOptions: { value },
    } = parameters[key];

    return {
      data: dataValue,
      name: parametersT(name),
      tooltip: { valueSuffix: ` ${commonT(value)}` },
      color,
    };
  });
}
