import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton, Button, Tooltip } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { makeStyles } from '@mui/styles';
import cloneDeep from 'lodash/cloneDeep';
import CreateProfileDialog from '@app/components/dialogs/create-profile-dialog/CreateProfileDialog';
import { DialogRef } from '@app/core/providers/DialogProvider';
import ButtonLoadingIcon from '@app/components/common/button-loading-icon/ButtonLoadingIcon';
import WrapperProgress from '@app/components/common/wrapper-progress/WrapperProgress';
import CloseDialogButton from '@app/components/close-dialog-button/CloseDialogButton';
import { ProfilesTypes } from '@app/v2/shared/enums';
import { useDialog } from '@app/v2/shared/hooks';
import ProfileTabs from './ProfileTabs';

type Props = {
  profiles: Profiles.CommonProfile[];
  type: ProfilesTypes;
  onSave: (profiles: Profiles.CommonProfile[]) => Promise<void>;
} & DialogRef;

const ProfileSettingsDialog = ({ profiles, onSave, type, close }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('dialogs');

  const [localProfiles, setLocalProfiles] = useState<Profiles.CommonProfile[]>(cloneDeep(profiles));
  const [indexActiveProfile, setIndexActiveProfile] = useState<number>(() => {
    const indexDefaultProfile = localProfiles.findIndex(profile => profile.isDefault);
    return indexDefaultProfile === -1 ? 0 : indexDefaultProfile;
  });
  const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);

  const openCreateProfileDialog = useDialog(CreateProfileDialog);

  const handleChangeCheckbox = useCallback(
    (key: string, checked: boolean) => {
      setLocalProfiles(prevProfiles => {
        const newLocalProfiles = [...prevProfiles];
        newLocalProfiles[indexActiveProfile].items = newLocalProfiles[indexActiveProfile].items.map(item =>
          item.key === key ? { ...item, checked } : { ...item },
        );
        return newLocalProfiles;
      });
    },
    [indexActiveProfile],
  );

  const handleChangeGroupCheckbox = useCallback(
    (checked: boolean) => {
      setLocalProfiles(prevProfiles => {
        const newLocalProfiles = [...prevProfiles];
        newLocalProfiles[indexActiveProfile].items = newLocalProfiles[indexActiveProfile].items.map(item => {
          return { ...item, checked };
        });

        return newLocalProfiles;
      });
    },
    [indexActiveProfile],
  );

  const handleSetDefaultProfile = useCallback(() => {
    setLocalProfiles(prevProfiles => {
      const newLocalProfiles = prevProfiles.map(item => ({
        ...item,
        isDefault: false,
      }));
      newLocalProfiles[indexActiveProfile].isDefault = true;

      return newLocalProfiles;
    });
  }, [indexActiveProfile]);

  const handleRemoveProfile = useCallback(() => {
    setLocalProfiles(prevProfiles => {
      const activeProfile = prevProfiles[indexActiveProfile];
      const newLocalProfiles = [...prevProfiles];
      newLocalProfiles.splice(indexActiveProfile, 1);

      if (activeProfile.isDefault) {
        newLocalProfiles[0].isDefault = true;
        setIndexActiveProfile(0);
      } else {
        const newIndexActiveProfile = Math.max(0, indexActiveProfile - 1);
        setIndexActiveProfile(newIndexActiveProfile);
      }
      return newLocalProfiles;
    });
  }, [indexActiveProfile]);

  const toggleCreateProfileDialog = useCallback(() => {
    const createProfile = (profile: Profiles.CommonProfile) => {
      setLocalProfiles(prevProfiles => {
        return [...prevProfiles, profile];
      });
    };

    openCreateProfileDialog({ type }).beforeClose.subscribe(newProfile => {
      !!newProfile && createProfile(newProfile);
    });
  }, [openCreateProfileDialog, type]);

  const handleSaveProfiles = useCallback(
    (allProfiles: Profiles.CommonProfile[]) => {
      setIsLoadingSave(true);
      onSave(allProfiles)
        .then(() => close('Save'))
        .finally(() => setIsLoadingSave(false));
    },
    [close, setIsLoadingSave, onSave],
  );

  return (
    <>
      <Dialog open scroll="paper" fullWidth maxWidth="lg" onClose={() => close('onClose')}>
        <DialogTitle classes={{ root: classes.rootDialogTitle }}>
          <div className={classes.title}>
            <Typography variant="h6">{t('profileSettings.title')}</Typography>
            <Tooltip title={t('profileSettings.tooltip')}>
              <IconButton color="primary" classes={{ root: classes.rootIconButton }} onClick={toggleCreateProfileDialog}>
                <NoteAddIcon />
              </IconButton>
            </Tooltip>
          </div>
          <CloseDialogButton callback={close} />
        </DialogTitle>
        <DialogContent dividers>
          <WrapperProgress loading={isLoadingSave} height="auto">
            <ProfileTabs
              profiles={localProfiles}
              indexActiveProfile={indexActiveProfile}
              onChangeIndexActiveProfile={setIndexActiveProfile}
              onChangeGroupCheckbox={handleChangeGroupCheckbox}
              onChangeCheckbox={handleChangeCheckbox}
              onSetDefaultProfile={handleSetDefaultProfile}
              onRemoveProfile={handleRemoveProfile}
            />
          </WrapperProgress>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ButtonLoadingIcon loading={isLoadingSave} icon={<SaveIcon />} />}
            onClick={() => handleSaveProfiles(localProfiles)}
            disabled={isLoadingSave}
          >
            {t('common.buttons.save')}
          </Button>
          <Button variant="outlined" color="secondary" startIcon={<CancelIcon />} onClick={() => close('button')}>
            {t('common.buttons.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles(() => ({
  rootDialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rootIconButton: {
    margin: '0 4px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  topActionsPanel: {
    marginBottom: '8px',
  },
}));

export default ProfileSettingsDialog;
