import React from 'react';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Box } from '@mui/material';
import NoChartData from '../../common/components/NoChartData';
import usePlacePlanChartOptions from '../hooks/usePlacePlanChartOptions';
import PlanChartHeaderId from '../../common/enums';
import type { PlanGraphAlert, PlanGraphData } from '../types';
import { recommendationAlertType } from '../../common/constants/planChart';

type Props = {
  planGraph: PlanGraphData;
  chartHeight: number;
};

const PlanChart = ({ planGraph, chartHeight }: Props) => {
  const {
    content: { alerts, recommendations },
  } = planGraph;

  const mergeAlertsAndRecommendations: PlanGraphAlert[] = recommendations.map(({ alertId, from, to, message }) => ({
    id: alertId,
    rank: 0,
    from,
    to,
    message,
    type: {
      alias: recommendationAlertType,
      title: 'Рекомендации',
    },
  }));

  alerts.push(...mergeAlertsAndRecommendations);
  alerts.sort((a, b) => moment(a.from).valueOf() - moment(b.from).valueOf());

  const chartOptions = usePlacePlanChartOptions(alerts, chartHeight);

  return (
    <Box id={PlanChartHeaderId.PLACE} position="relative" overflow="auto">
      {[...alerts, ...recommendations].length ? (
        <HighchartsReact
          highcharts={Highcharts}
          constructorType="ganttChart"
          options={chartOptions}
          containerProps={{
            style: {
              padding: '0 1rem',
            },
          }}
        />
      ) : (
        <NoChartData />
      )}
    </Box>
  );
};

export default PlanChart;
