import {
  CHAR_SPACING,
  DEFAULT_ALIGNMENT,
  DEFAULT_FONT_NAME,
  DEFAULT_INTERVAL,
  DEFAULT_MARGIN,
  DEFAULT_SIGN_SIZE,
  FONT_NAME,
  LINE_SPACING,
  MARGIN_BOTTOM,
  MARGIN_LEFT,
  MARGIN_RIGHT,
  MARGIN_TOP,
  NUM_LINES,
  SymbolsMapper,
  VALIGN,
} from '@app/v2/shared/constants';
import messageAlignment from '@app/modules/kit-module/shared/ui/CSDScoreboardsViewer/messageAlignment';
import { ConfigurationTypes } from '@app/v2/shared/enums';

export default function calculateDataForView(template: Scoreboards.TemplateContent) {
  const configuration = template?.configuration?.configuration;

  const configurationItems = configuration?.items || [];

  const configurationTypes = configuration?.items.map(configurationItem => configurationItem?.type);

  const background = configuration?.backgroundColor;

  const scoreboardData = template?.contents.map((content, index) => {
    if (Array.isArray(content) && content?.length) {
      const width = Number(configurationItems[index]?.width) || DEFAULT_SIGN_SIZE;
      const charsInterval = Number(configurationItems[index]?.[CHAR_SPACING]) || DEFAULT_INTERVAL;
      const font = configurationItems[index]?.[FONT_NAME] || DEFAULT_FONT_NAME;

      const [w] = font.split('x').map(Number);

      const maxLength = Math.floor(
        (width - Number(configurationItems[index]?.[MARGIN_RIGHT]) - Number(configurationItems[index]?.[MARGIN_LEFT]) + charsInterval) /
          (w + charsInterval),
      );

      const setMessageAlignment = messageAlignment(maxLength);

      return content
        .slice(0, configurationItems[index]?.[NUM_LINES])
        .map(row => setMessageAlignment(row.text.split(''), row.align))
        .map(row => row.map(symbol => SymbolsMapper[symbol?.charCodeAt(0)]));
    }

    return content;
  });

  const intervals = scoreboardData.map((_, index) => ({
    charsInterval: Number(configurationItems[index]?.[CHAR_SPACING]) || DEFAULT_INTERVAL,
    linesInterval: Number(configurationItems[index]?.[LINE_SPACING]) || DEFAULT_INTERVAL,
  }));

  const signValign = scoreboardData.map((_, index) => {
    if (configurationItems[index]?.type === ConfigurationTypes.Sgnlst) {
      return configurationItems[index]?.[VALIGN] || DEFAULT_ALIGNMENT;
    }

    return null;
  });

  const messageValign = scoreboardData.map((_, index) => {
    if ([ConfigurationTypes.Txtbox, ConfigurationTypes.Autobox].includes(configurationItems[index]?.type)) {
      return configurationItems[index]?.[VALIGN] || DEFAULT_ALIGNMENT;
    }

    return null;
  });

  const scoreboardSize = scoreboardData.map((_, index) => {
    const margins = {
      right: Number(configurationItems[index]?.[MARGIN_RIGHT]) || DEFAULT_MARGIN,
      left: Number(configurationItems[index]?.[MARGIN_LEFT]) || DEFAULT_MARGIN,
      top: Number(configurationItems[index]?.[MARGIN_TOP]) || DEFAULT_MARGIN,
      bottom: Number(configurationItems[index]?.[MARGIN_BOTTOM]) || DEFAULT_MARGIN,
    };

    const width = Number(configurationItems[index]?.width) || DEFAULT_SIGN_SIZE;
    const height = Number(configurationItems[index]?.height) || DEFAULT_SIGN_SIZE;

    const font = configurationItems[index]?.[FONT_NAME] || DEFAULT_FONT_NAME;
    const [w, h] = font.split('x').map(Number);

    return {
      width,
      height,
      margins,
      font: {
        h,
        w,
      },
    };
  });

  const scoreboardWidth = scoreboardSize.map(item => item.width).reduce((acc, item) => item + acc, 0);
  const scoreboardHeight = Math.max(...scoreboardSize.map(item => item.height));

  return {
    configuration,
    configurationItems,
    configurationTypes,
    background,
    scoreboardData,
    intervals,
    signValign,
    messageValign,
    scoreboardSize,
    scoreboardWidth,
    scoreboardHeight,
  };
}
