import { Subject } from 'rxjs';
import BaseManager from '@app/core/source/BaseManager';
import { LISTING_DEFAULT_PAGE_LIMIT, LISTING_DEFAULT_PAGE_NUMBER } from '@app/core/constants';

// eslint-disable-next-line import/prefer-default-export
export class PaginationManager extends BaseManager<Common.Pagination> {
  private changeSubject = new Subject();

  change = this.changeSubject.asObservable();

  constructor() {
    super({
      page: LISTING_DEFAULT_PAGE_NUMBER,
      limit: LISTING_DEFAULT_PAGE_LIMIT,
      total: 0,
    });
  }

  next(data: Common.Pagination) {
    const { page, limit } = this.value;

    if (data.page !== page || data.limit !== limit) {
      super.next(data);

      this.changeSubject.next(data?.tableMode);
    }
  }
}
