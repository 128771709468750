export type TrafficChartPlace = {
  id: number;
  address: number;
  meters: number;
};

export enum TrafficChartDirection {
  Backward = 'backward',
  Forward = 'forward',
  Both = 'both',
}

export enum TrafficChartInterval {
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export enum TrafficChartType {
  Column = 'column',
  Spline = 'spline',
}

export enum TrafficViewType {
  BY_CLASS = 'BY_CLASS',
  BY_DIRECTIONS = 'BY_DIRECTIONS',
}
