import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { showError } from '@app/core/utils/notifications';
import axios from '@app/clients/axios/client';
import { mainConfig } from '@app/v2/shared/configs';

const URL_REPORT_PLACE = 'report/mmPlaces/';

const EXTENSION = 'xlsx';
const BLOB_TYPE = 'application/vnd.ms-excel;charset=UTF-8';

enum PlaceReportTypes {
  ALL = 'all',
  ACTIVE = 'active',
}

const PlaceReportActions = ({ t }: I18N.TranslationFunction<'dialogs', 'accountSettings.tabs.profile'>) => {
  const [loadingStatuses, setLoadingStatuses] = useState<Record<PlaceReportTypes, boolean>>({
    [PlaceReportTypes.ALL]: false,
    [PlaceReportTypes.ACTIVE]: false,
  });

  const linkHref = useRef<string | null>(null);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(linkHref.current);
      linkHref.current = null;
    };
  }, []);

  const getReport = (reportType: PlaceReportTypes) => {
    setLoadingStatuses(prev => ({ ...prev, [reportType]: true }));

    axios
      .get(`${mainConfig.links.downloadHost + URL_REPORT_PLACE}`, {
        responseType: 'blob',
        params: {
          no_mm_filters: 'yes',
          only_active: reportType === PlaceReportTypes.ACTIVE ? 'yes' : null,
        },
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: BLOB_TYPE }));
        linkHref.current = url;

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `places_report_${reportType}.${EXTENSION}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(error => showError(error.message))
      .finally(() => {
        setLoadingStatuses(prev => ({ ...prev, [reportType]: false }));
      });
  };

  return (
    <>
      <Grid item xs={6}>
        <LoadingButton variant="outlined" fullWidth loading={loadingStatuses.all} onClick={() => getReport(PlaceReportTypes.ALL)}>
          {t('buttons.downloadAllLocations')}
        </LoadingButton>
      </Grid>
      <Grid item xs={6}>
        <LoadingButton variant="outlined" fullWidth loading={loadingStatuses.active} onClick={() => getReport(PlaceReportTypes.ACTIVE)}>
          {t('buttons.downloadActiveLocations')}
        </LoadingButton>
      </Grid>
    </>
  );
};

export default withTranslation('dialogs', { keyPrefix: 'accountSettings.tabs.profile' })(PlaceReportActions);
