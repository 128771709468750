import { Chart, YAxisOptions } from 'highcharts';
import { yAxisRowBorderColor, yAxisLabelBorderColor, rowHeight, yAxisColumnWidth } from '../../constants/planChart';
import type { ExtendedYAxisLabelOptions } from '../../types';

export default function prepareYAxisForPlanChart(): YAxisOptions {
  const handleYAxisLabelClick = (chart: Chart, target) => {
    const $targetParent = target.closest('.yaxis-row');

    if (!$targetParent) return;

    const $screenElement = $targetParent.querySelector('.yaxis-column-screen');

    if (!$screenElement) return;

    $screenElement.setAttribute('style', `width: ${chart.plotWidth + yAxisColumnWidth}px; height: ${rowHeight}px`);
  };

  return {
    uniqueNames: true,
    gridLineColor: yAxisRowBorderColor,
    tickWidth: 1,
    grid: {
      cellHeight: 50,
      borderColor: yAxisLabelBorderColor,
    },
    labels: {
      useHTML: true,
      align: 'left',
      symbol: {
        height: 0,
        width: 0,
      },
      style: {
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        color: 'black',
      },
      events: {
        click(event) {
          // @ts-ignore
          handleYAxisLabelClick(this.chart, event.target);
        },
      },
    } as ExtendedYAxisLabelOptions,
  };
}
