import React, { ReactNode } from 'react';
import { Alert, IconButton, Icon, Button, Typography } from '@mui/material';
import { AlertProps } from '@mui/material/Alert/Alert';
import icons from '@app/assets/iconFont';

type Props = {
  children: ReactNode;
  actionButtonTitle?: string;
  isHaveCloseIcon?: boolean;
  contentAlign?: 'center' | 'flex-start';
  size?: 'small' | 'normal';
  cb?: Common.AnyFunction;
} & AlertProps;

const CSDAlert = ({ actionButtonTitle, cb, size = 'normal', contentAlign, isHaveCloseIcon = false, children, ...alertProps }: Props) => {
  return (
    <Alert
      {...alertProps}
      icon={<Icon>{icons.attention}</Icon>}
      action={
        isHaveCloseIcon && (
          <IconButton>
            <Icon>{icons.close}</Icon>
          </IconButton>
        )
      }
      sx={{
        alignItems: contentAlign,
        maxWidth: size === 'small' ? '23.438rem' : '29.688rem',
        ...(alertProps.sx || {}),
      }}
    >
      <Typography fontSize={14} lineHeight="1.038rem">
        {children}
      </Typography>
      {actionButtonTitle && (
        <Button
          size="small"
          sx={{
            marginTop: '1rem',
            fontSize: '0.875rem',
          }}
          variant="outlined"
          onClick={() => {
            if (cb && typeof cb === 'function') {
              cb();
            }
          }}
        >
          {actionButtonTitle}
        </Button>
      )}
    </Alert>
  );
};

export default CSDAlert;
