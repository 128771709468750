import { CSSProperties } from 'react';
import { BooleanParam, StringParam } from 'use-query-params';
import { PopoverOrigin } from '@mui/material';
import { FiltersAlertStatus, FiltersKeys, FiltersTypes } from '@app/v2/shared/enums';
import { CommaArrayParam } from '@app/v2/shared/constants/params';

export const FiltersInitValues: Filters.Filter = {
  [FiltersKeys.Roads]: {
    type: FiltersTypes.Array,
    value: [],
  },
  [FiltersKeys.Organizations]: {
    type: FiltersTypes.Array,
    value: [],
  },
  [FiltersKeys.Places]: {
    type: FiltersTypes.Array,
    value: [],
  },
};

export const DateFromFilter: Filters.Filter = {
  [FiltersKeys.DateFrom]: {
    type: FiltersTypes.Sting,
    value: null,
  },
};

export const DateToFilter: Filters.Filter = {
  [FiltersKeys.DateTo]: {
    type: FiltersTypes.Sting,
    value: null,
  },
};

export const IsRoadcastFilter: Filters.Filter = {
  [FiltersKeys.IsRoadcast]: {
    type: FiltersTypes.Boolean,
    value: false,
  },
};

export const IsFullDataFilter: Filters.Filter = {
  [FiltersKeys.IsFullData]: {
    type: FiltersTypes.Boolean,
    value: true,
  },
};

export const IsLocatorFilter: Filters.Filter = {
  [FiltersKeys.IsLocator]: {
    type: FiltersTypes.Boolean,
    value: false,
  },
};

export const PlaylistTitleFilter: Filters.Filter = {
  [FiltersKeys.PlaylistTitle]: {
    type: FiltersTypes.Sting,
    value: '',
  },
};

export const PlaylistHeightFilter: Filters.Filter = {
  [FiltersKeys.PlaylistHeight]: {
    type: FiltersTypes.Array,
    value: [0, 200],
  },
};

export const PlaylistWidthFilter: Filters.Filter = {
  [FiltersKeys.PlaylistWidth]: {
    type: FiltersTypes.Array,
    value: [0, 500],
  },
};

export const OrganizationTypeFilter: Filters.Filter = {
  [FiltersKeys.OrganizationType]: {
    type: FiltersTypes.Array,
    value: '',
  },
};

export const AlertStatusFilter: Filters.Filter = {
  [FiltersKeys.AlertStatus]: {
    type: FiltersTypes.Array,
    value: [],
  },
};

export const ReactionFilter: Filters.Filter = {
  [FiltersKeys.Reaction]: {
    type: FiltersTypes.Sting,
    value: null,
  },
};

export const AllFilters: Filters.Filter = {
  ...FiltersInitValues,
  ...DateFromFilter,
  ...DateToFilter,
  ...IsRoadcastFilter,
  ...IsFullDataFilter,
  ...IsLocatorFilter,
  ...PlaylistTitleFilter,
  ...PlaylistHeightFilter,
  ...PlaylistWidthFilter,
  ...OrganizationTypeFilter,
  ...AlertStatusFilter,
  ...ReactionFilter,
};

export const URLParametersMapper: Record<FiltersTypes, any> = {
  [FiltersTypes.Array]: CommaArrayParam,
  [FiltersTypes.Sting]: StringParam,
  [FiltersTypes.Boolean]: BooleanParam,
};

export const URLSetterChecker: Record<FiltersTypes, Common.AnyFunction> = {
  [FiltersTypes.Array]: (value: any[]) => Array.isArray(value),
  [FiltersTypes.Sting]: (value: string) => Boolean(value),
  [FiltersTypes.Boolean]: (value: boolean) => typeof value === 'boolean',
};

export const AmountCounters: Record<FiltersTypes, Common.AnyFunction> = {
  [FiltersTypes.Array]: (value: any[]) => value.length,
  [FiltersTypes.Sting]: (value: string) => Boolean(value),
  [FiltersTypes.Boolean]: (value: string) => typeof value === 'boolean',
};

export const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

export const transformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

export const paperProps: CSSProperties = {
  minWidth: '100%',
  marginTop: '0.5rem',
  position: 'relative',
  borderRadius: 0,
  backgroundColor: 'white',
};

export const DEBOUNCE_FILTER_INTERVAL = 50;

export const playlistsSizes = {
  height: {
    initialValue: [0, 200],
    maxValue: 200,
  },
  width: {
    initialValue: [0, 500],
    maxValue: 500,
  },
};

export const alertStatusApiFormats: Record<FiltersAlertStatus, string> = {
  [FiltersAlertStatus.Alert]: 'alert',
  [FiltersAlertStatus.Warning]: 'warning',
  [FiltersAlertStatus.Notice]: 'notice',
};
