import React from 'react';
import { Stack } from '@mui/material';
import { CSDDatePickerMUI } from '@app/modules/kit-module/shared/ui';
import datePickerExample from './example';
import CSDKitDocBlock from '../../../shared/ui/CSDKitDocBlock/CSDKitDocBlock';

const CSDDatePickersMUI = () => {
  return (
    <Stack direction="column" spacing={3}>
      <CSDKitDocBlock title="Datepicker" codeExample={datePickerExample}>
        <CSDDatePickerMUI />
      </CSDKitDocBlock>
    </Stack>
  );
};

export default CSDDatePickersMUI;
