import React, { useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { withTranslation, useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Box, IconButton, Avatar, Typography, Theme, Icon } from '@mui/material';
import { useFormikContext, FieldProps } from 'formik';
import icons from '@app/assets/iconFont';
import { useFileHandler } from '@app/v2/shared/hooks';

type Props = {
  showTitle?: boolean;
  title?: string;
  uploadPath: string;
  initialImages: string[];
} & FieldProps<string>;

const CustomFileComponentRedesign = ({ field: { name }, uploadPath, initialImages = [], showTitle = true, title }: Props) => {
  const { t } = useTranslation('components', { keyPrefix: 'customFileComponent' });
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();

  const setFieldNameValue = useCallback((value: string[]) => setFieldValue(name, value), [name, setFieldValue]);

  const { uploadedImages, selectedFiles, setSelectedFiles, removeUploadedFile, removeSelectedFile } = useFileHandler(
    uploadPath,
    initialImages,
    setFieldNameValue,
  );
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ multiple: true });

  useEffect(() => {
    setSelectedFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
  }, [acceptedFiles, setSelectedFiles]);

  const [files, setFiles] = useState<any[]>([]);

  useEffect(() => {
    const newFiles = selectedFiles.map(file => ({
      file,
      url: URL.createObjectURL(file),
    }));
    setFiles(newFiles);
    return () => {
      newFiles.forEach(({ url }) => URL.revokeObjectURL(url));
    };
  }, [selectedFiles]);

  return (
    <div>
      {showTitle && (
        <Typography variant="h6" gutterBottom className={classes.title}>
          {title || t('defaultDialogTitle')}
        </Typography>
      )}
      <Box className={classes.containerCords}>
        <Box className={classes.previews}>
          {uploadedImages.map(url => (
            <Box key={url} className={classes.preview}>
              <Avatar src={url} alt="Uploaded image" className={classes.avatarStyle} />
              <IconButton onClick={() => removeUploadedFile(url)} className={classes.closeButton}>
                <Icon className={classes.closeIconStyle}>{icons.close}</Icon>
              </IconButton>
            </Box>
          ))}
          {files.map(({ file, url }) => (
            <Box key={file.name} className={classes.preview}>
              <Avatar src={url} alt="Selected file" className={classes.avatarStyle} />
              <IconButton onClick={() => removeSelectedFile(file)} className={classes.closeButton}>
                <Icon className={classes.closeIconStyle}>{icons.close}</Icon>
              </IconButton>
            </Box>
          ))}
          <div {...getRootProps()} className={classes.dropzoneButton}>
            <input {...getInputProps()} />
            <IconButton component="span" className={classes.iconButtonStyle}>
              <Icon>{icons.plus}</Icon>
            </IconButton>
          </div>
        </Box>
      </Box>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 'bold',
  },
  containerCords: {
    flexWrap: 'wrap',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  dropzoneButton: {
    '&&': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: theme.spacing(0.5),
      width: '6rem',
      height: '6rem',
      border: `0.125rem dashed ${theme.palette.primary.main}`,
      borderRadius: '0.625rem',
    },
  },
  previews: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    position: 'relative',
  },
  preview: {
    position: 'relative',
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: theme.spacing(0.5),
  },
  avatarStyle: {
    '&&': {
      width: '6rem',
      height: '6rem',
      marginRight: '0.625rem',
      borderRadius: '0.625rem',
      border: '0.1rem solid',
    },
  },
  closeButton: {
    '&&': {
      color: theme.typography.button,
      backgroundColor: 'white',
      position: 'absolute',
      top: '2%',
      left: '98%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '1rem',
      border: `0.1rem solid ${theme.palette.grey[600]}`,
      width: '1.5rem',
      height: '1.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  closeIconStyle: {
    '&&': {
      fontSize: '1rem',
    },
  },
  iconButtonStyle: {
    ...theme.typography.button,
  },
}));

export default withTranslation('components')(CustomFileComponentRedesign);
