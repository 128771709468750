import { MAX_DEGREES, MAX_RHUMBS, RHUMB_DEGREES } from '../constants/wind';
import { NO_DATA } from '../constants/common';
import WindDirectionsConfig from '../configs/windDirections';

export const degrees2Rhumbs = (degrees: number): number => {
  if (typeof degrees === 'number' && degrees >= 0 && !Number.isNaN(degrees)) {
    return (Number(degrees.toFixed(1)) * MAX_RHUMBS) / MAX_DEGREES;
  }

  return null;
};

export const rhumb2Degrees = (rhumbs: number): number | string => {
  if (rhumbs === null || rhumbs === undefined) {
    return NO_DATA;
  }

  const degrees = Math.round(rhumbs * RHUMB_DEGREES);

  return degrees > MAX_DEGREES ? MAX_DEGREES : degrees;
};

const determineDirection = (rhumbs: number): StaticConfigs.WindDirectionItem | undefined => {
  const degrees = rhumb2Degrees(rhumbs);

  return WindDirectionsConfig.find(({ start, end }) => Number(degrees) >= Number(start) && Number(degrees) <= Number(end));
};

export const getWindDirectionConfigItem = (rhumbs: number): StaticConfigs.WindDirectionItem => {
  return determineDirection(rhumbs);
};

export const rotateCircleByDegree = (direction: number, angle: number): number => (direction + angle) % 360;

export const turnCircleInOppositeDirection = (direction: number): number => rotateCircleByDegree(direction, 180);
