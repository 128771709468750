import { TFunction } from 'react-i18next';
import parameters from '@app/core/constants/parameters/parameters';
import { isNotNullAndUndefined } from '@app/v2/shared/helpers';
import type { PreparedWaterLevelContent } from '../types';

interface SeriesParameters {
  content: Partial<PreparedWaterLevelContent>;
  keys: string[];
  dates: string[];
  parameterT: TFunction<'parameters'>;
  commonT: TFunction<'common'>;
  convertDataToMomentWithOffeset: (date: moment.MomentInput) => moment.Moment;
}

export default function prepareSeriesParameters({ content, dates, parameterT, keys, commonT, convertDataToMomentWithOffeset }: SeriesParameters) {
  if (!content) return [];

  const chartSeries = keys.map(key => {
    const {
      icon,
      label: name,
      unitsOptions: { key: yAxis, value },
      chartOptions: { type, lineColor: color, legendIndex, zIndex },
    } = parameters[key];

    return {
      icon,
      label: key,
      yAxis,
      legendIndex,
      zIndex,
      type,
      color,
      name: parameterT(name),
      data: content[key].map((dataValue, index) => ({
        y: dataValue,
        x: convertDataToMomentWithOffeset(dates[index]).valueOf(),
        marker: { enabled: false },
      })),
      tooltip: { valueSuffix: ` ${commonT(value)}` },
      point: {
        events: {
          click(this: any) {
            this.series.chart.userOptions.chart.events.click.call({
              ...this.series.chart,
              hoverPoint: {
                index: this.index,
                category: this.category,
              },
            });
          },
        },
      },
    };
  });

  return chartSeries.filter(item => keys.includes(item.label)).filter(item => item?.data?.some(dataItem => isNotNullAndUndefined(dataItem.y)));
}
